import { createRouter, createWebHistory } from "vue-router";
import periodontalChart from "../../modules/periodontalChart/view/periodontalChartView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/periodontal-chart",
    name: "periodontalChart",
    component: periodontalChart,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
