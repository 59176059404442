import { reactive } from "vue";

export const userInfo = reactive({
  passaportNo: "",
  yupassNo: "",
  tcNo: "",
  userName: "",
  userSurname: "",
  nameOfMother: "",
  nameOfFather: "",
  dateOfBirth: "",
  maritalStatus: "",
  adress: "",
  email: "",
  phoneNumber: "",
  homeNumber: "",
  workNumber: "",
  kpsYabanciUyrukluButtonDropDown: false,
  kpsYabanciUyrukluButtonDropDownSelectedValue: "",
  kpsYabanciUyrukluButtonDropDownValues: [
    "Arnavutluk",
    "Avusturya",
    "Fransa",
    "Almanya",
    "Ingiltere",
    "Italya",
    "Ispanya",
    "Rusya",
    "ABD",
  ],
  gender: "",
  ilButtonDropDown: false,
  ilButtonDropDownSelectedValue: "",

  ilButtonDropDownValues: [
    "Adana",
    "Adıyaman",
    "Afyonkarahisar",
    "Ağrı",
    "Aksaray",
    "Amasya",
    "Ankara",
    "Antalya",
    "Ardahan",
    "Artvin",
    "Aydın",
    "Balıkesir",
    "Bartın",
    "Batman",
    "Bayburt",
    "Bilecik",
    "Bingöl",
    "Bitlis",
    "Bolu",
    "Burdur",
    "Bursa",
    "Çanakkale",
    "Çankırı",
    "Çorum",
    "Denizli",
    "Diyarbakır",
    "Düzce",
    "Edirne",
    "Elazığ",
    "Erzincan",
    "Erzurum",
    "Eskişehir",
    "Gaziantep",
    "Giresun",
    "Gümüşhane",
    "Hakkâri",
    "Hatay",
    "Iğdır",
    "Isparta",
    "İstanbul",
    "İzmir",
    "Kahramanmaraş",
    "Karabük",
    "Karaman",
    "Kars",
    "Kastamonu",
    "Kayseri",
    "Kilis",
    "Kırıkkale",
    "Kırklareli",
    "Kırşehir",
    "Kocaeli",
    "Konya",
    "Kütahya",
    "Malatya",
    "Manisa",
    "Mardin",
    "Mersin",
    "Muğla",
    "Muş",
    "Nevşehir",
    "Niğde",
    "Ordu",
    "Osmaniye",
    "Rize",
    "Sakarya",
    "Samsun",
    "Şanlıurfa",
    "Siirt",
    "Sinop",
    "Sivas",
    "Şırnak",
    "Tekirdağ",
    "Tokat",
    "Trabzon",
    "Tunceli",
    "Uşak",
    "Van",
    "Yalova",
    "Yozgat",
    "Zonguldak",
  ],
  ilceButtonDropDown: false,
  ilceButtonDropDownSelectedValue: "",
  ilceButtonDropDownValues: null,

  adresIlButtonDropDown: false,
  adresIlButtonDropDownSelectedValue: "",
  // Adres il button dropdown values will be fetched from the server
  adresIlButtonDropDownValues: ["Üsküdar", "Kadıköy", "Beşiktaş", "Beyoğlu"],
  adresIlceButtonDropDown: false,
  adresIlceButtonDropDownSelectedValue: "",
  // Adres ilce button dropdown values will be fetched from the server
  adresIlceButtonDropDownValues: null,
  sdgButtonDropDown: false,
  sdgDropDownSelectedValue: "",
  sdgDropDownValues: [
    "GSS",
    "Bağkur",
    "Emekli Sandığı",
    "Özel Sigorta",
    "YOK",
    "Diğer",
    "Belirtilmedi",
  ],
  sigortaTuruButtonDropDown: false,
  sigortaTuruSelectedValue: "",
  sigortaTuruDropDownValues: [
    "Çalışan",
    "Emekli",
    "SSK Kurum Personeli",
    "Diğer",
  ],
  oncelikSirasiButtonDropDown: false,
  oncelikSirasiSelectedValue: "",
  oncelikSirasiDropDownValues: [
    "Çalışan",
    "Emekli",
    "SSK Kurum Personeli",
    "Diğer",
  ],
});
