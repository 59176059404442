export const toplamBirimPuaniGeneralData = {
  labels: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  datasets: [
    {
      label: "Endodonti",
      backgroundColor: "#F0AD4E",
      data: [
        500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000,
      ],
    },
    {
      label: "Periodontoloji",
      data: [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000,
      ],
      backgroundColor: "#5BC0DE",
    },
    {
      label: "Pedodonti",
      data: [
        2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000,
      ],
      backgroundColor: "#DE5BC1",
    },
    {
      label: "Radyoloji",
      data: [
        3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000,
      ],
      backgroundColor: "#F65D58",
    },
    {
      label: "Restoratif",
      data: [
        4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000, 5000,
      ],
      backgroundColor: "#6498FD",
    },
    {
      label: "Oral Diagnoz",
      data: [
        5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000, 5000, 6000,
      ],
      backgroundColor: "#A9CFC8",
    },
  ],
};

export const toplamBirimPuani = {
  labels: [
    "Endodonti",
    "Periodontoloji",
    "Pedodonti",
    "Restoratif Diş Kliniği",
    "Radyoloji",
    "Oral Diagnoz",
  ],
  datasets: [
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [500, 1000, 2000, 3000, 4000, 5000],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [400, 40, 40, 40, 40, 40],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [550, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [310, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [260, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [310, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [260, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Toplam Birim Puanı",
      backgroundColor: "#526581",
      data: [550, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
  ],
};
export const genelKazancData = {
  labels: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  datasets: [
    {
      label: "Ek Ödeme",
      backgroundColor: "#456563",
      data: [
        500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000,
      ],
    },
  ],
};

// Mesai Ici birim Toplam Fiyat
export const genelMesaiIciBirimToplamFiyatData = {
  labels: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  datasets: [
    {
      label: "Endodonti",
      backgroundColor: "#F0AD4E",
      data: [
        500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000,
      ],
    },
    {
      label: "Periodontoloji",
      data: [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000,
      ],
      backgroundColor: "#5BC0DE",
    },
    {
      label: "Pedodonti",
      data: [
        2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000,
      ],
      backgroundColor: "#DE5BC1",
    },
    {
      label: "Radyoloji",
      data: [
        3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000,
      ],
      backgroundColor: "#F65D58",
    },
    {
      label: "Restoratif",
      data: [
        4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000, 5000,
      ],
      backgroundColor: "#6498FD",
    },
    {
      label: "Oral Diagnoz",
      data: [
        5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000, 5000, 6000,
      ],
      backgroundColor: "#A9CFC8",
    },
  ],
};

export const mesaiIciBirimToplamFiyatData = {
  labels: [
    "Endodonti",
    "Periodontoloji",
    "Pedodonti",
    "Restoratif Diş Kliniği",
    "Radyoloji",
    "Oral Diagnoz",
  ],
  datasets: [
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 1000, 2000, 3000, 4000, 5000],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [400, 40, 40, 40, 40, 40],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [550, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [310, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [260, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [310, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [260, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai İçi Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [550, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
  ],
};
// Mesai Ici birim Toplam Fiyat
export const genelMesaiDisiBirimToplamFiyat = {
  labels: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  datasets: [
    {
      label: "Endodonti",
      backgroundColor: "#F0AD4E",
      data: [
        500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000,
      ],
    },
    {
      label: "Periodontoloji",
      data: [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000,
      ],
      backgroundColor: "#5BC0DE",
    },
    {
      label: "Pedodonti",
      data: [
        2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000,
      ],
      backgroundColor: "#DE5BC1",
    },
    {
      label: "Radyoloji",
      data: [
        3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000,
      ],
      backgroundColor: "#F65D58",
    },
    {
      label: "Restoratif",
      data: [
        4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000, 5000,
      ],
      backgroundColor: "#6498FD",
    },
    {
      label: "Oral Diagnoz",
      data: [
        5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000, 5000, 6000,
      ],
      backgroundColor: "#A9CFC8",
    },
  ],
};

export const mesaiDisiBirimToplamFiyat = {
  labels: [
    "Endodonti",
    "Periodontoloji",
    "Pedodonti",
    "Restoratif Diş Kliniği",
    "Radyoloji",
    "Oral Diagnoz",
  ],
  datasets: [
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 1000, 2000, 3000, 4000, 5000],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [400, 40, 40, 40, 40, 40],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [550, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [310, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [260, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [500, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [310, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [260, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
    {
      label: "Mesai Dışı Birim Toplam Fiyat",
      backgroundColor: "#526581",
      data: [550, 40, 50, 55, 30, 55],
      borderRadius: 20,
      barPercentage: 0.3,
    },
  ],
};
