<template>
  <div
    class="absolute flex flex-col p-3 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-[#fbfbfb] w-[1600px] shadow-2xl rounded text-base mx-auto"
  >
    <div
      class="w-full flex items-center justify-between bg-white text-black px-3 py-1 mb-3 border shadow rounded"
    >
      <div class="flex items-center gap-14">
        <div>
          <div class="flex items-center gap-2">
            <div class="w-2 h-2 bg-[#5CB85C] rounded-full"></div>
            <p>Randevu Tipi</p>
          </div>
          <p class="text-[#a0a3a6] ml-3">
            {{
              doctorCalendarStore().appointmentType ==
              "mesai_disi_ogretim_uyesi"
                ? "Mesai Dışı Öğretim Üyesi Randevu"
                : doctorCalendarStore().appointmentType ==
                  "implant_estetik_dis_klinigi"
                ? "İmplant Estetik Diş Kliniği"
                : doctorCalendarStore().appointmentType == "normal_randevu"
                ? "Normal Randevu"
                : ""
            }}
          </p>
        </div>
        <div>
          <div class="flex items-center gap-2">
            <div class="w-2 h-2 bg-[#5CB85C] rounded-full"></div>
            <p>Çalışma Aralığı</p>
          </div>
          <p class="text-[#a0a3a6] ml-3">
            {{
              moment(doctorCalendarStore().selectedDays[0].date).format(
                "DD MMMM  dddd",
                "tr"
              )
            }}
            -
            {{
              moment(
                doctorCalendarStore().selectedDays[
                  doctorCalendarStore().selectedDays.length - 1
                ].date
              ).format("DD MMMM  dddd", "tr")
            }}
          </p>
        </div>
      </div>
      <button
        v-if="doctorCalendarStore().updatingSetting"
        @click="handleDeleteSetting"
        class="bg-[#F94144] text-white py-2 px-3 rounded-3xl"
      >
        Çizelgeyi sil
      </button>
    </div>
    <div class="flex gap-1">
      <div class="bg-white border p-1 shadow rounded">
        <div class="flex justify-center items-center bg-[#D5DBE1] p-3">
          <h3 class="w-[150px]">Tarih</h3>
          <h3 class="w-[200px] text-center pr-3">Çalışma Aralıkları</h3>
          <h3 class="w-[200px] text-center">Overbooking</h3>
          <h3 class="w-[200px] text-center">Muayene süresi</h3>
          <h3 class="w-[100px] text-center"></h3>
        </div>
        <div class="overflow-y-auto max-h-[530px]">
          <singleDayToAdd
            v-for="(selectedDay, index) in doctorCalendarStore().selectedDays"
            :selectedDay="selectedDay"
            :key="index"
            :index="index"
          />
        </div>
      </div>
      <button
        @click="
          openAddToDrCalendarFalse();
          doctorCalendarStore().appointmentType = '';
        "
        :disabled="
          globalStore.createSettingFail ||
          globalStore.createSettingSuccessful ||
          globalStore.isCreating
        "
        class="absolute lg:-right-3 lg:-top-3 top-0 right-0 flex justify-center items-center bg-[#D9534F] text-white rounded-full w-10 h-10 text-2xl"
        :class="
          globalStore.createSettingFail ||
          globalStore.createSettingSuccessful ||
          globalStore.isCreating
            ? 'opacity-50 cursor-not-allowed'
            : 'cursor-pointer'
        "
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L9 7.58579L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L10.4142 9L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L9 10.4142L1.70711 17.7071C1.31658 18.0976 0.683417 18.0976 0.292893 17.7071C-0.0976311 17.3166 -0.0976311 16.6834 0.292893 16.2929L7.58579 9L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
            fill="white"
          />
        </svg>
      </button>

      <div class="p-2 border flex flex-col shadow rounded w-full">
        <!-- <singleDayToAdd
          :selectedDay="selectedDay"
          :key="index"
          :index="index"
        /> -->
        <div
          v-for="(selectedDay, index) in doctorCalendarStore().selectedDays"
          :key="index"
        >
          <!-- SaatSecim bileşenini, sadece aktif gün için göster -->
          <saatSecim
            v-if="
              doctorCalendarStore().selectedDays.length > 0 &&
              doctorCalendarStore().activeIndex === index
            "
            :key="index"
            :index="index"
          />
        </div>
        <button
          v-if="showSaatSecim || doctorCalendarStore().selectedDays.length > 0"
          @click="applyAll"
          class="text-[#277dd2] mt-auto gap-2 w-full rounded-[30px] py-2 bg-[#eaf2fb] flex items-center justify-center"
        >
          <img :src="applyAllDays" alt="" />
          <p>Tüm günlere uygula</p>
        </button>
        <!-- :class="isButtonDisabled() ? 'opacity-50 cursor-not-allowed' : ''"
          :disabled="isButtonDisabled()" -->
      </div>
    </div>
    <div class="mx-auto mt-6 flex gap-6">
      <button
        @click="
          openAddToDrCalendarFalse();
          doctorCalendarStore().appointmentType = '';
        "
        class="bg-[#D9D9D9] text-[#464E5F] px-4 py-2 rounded-[30px]"
      >
        Vazgeç
      </button>
      <button
        @click="handleCreateSetting"
        class="bg-[#277DD2] text-white px-4 py-2 rounded-[30px]"
        v-if="
          doctorCalendarStore().selectedDays.length > 0 &&
          !doctorCalendarStore().updatingSetting
        "
      >
        Kaydet
      </button>
      <button
        @click="updateSetting"
        class="bg-[#277DD2] text-white px-4 py-2 rounded-[30px]"
        v-if="
          doctorCalendarStore().selectedDays.length > 0 &&
          doctorCalendarStore().updatingSetting
        "
      >
        Güncelle
      </button>
    </div>
  </div>
</template>

<script setup>
import { selectedDTypes } from "./selectedDTypes.ts";
import { globalStore } from "../../../store/modules/globalStore.ts";
import { ref, onMounted } from "vue";
import { useAddDoctorSetting } from "../../../store/modules/operationsStore/doctorSettings/useAddDoctorSetting.ts";
import moment from "moment";
import singleDayToAdd from "./singleDayToAdd.vue";
import { randevuTipi } from "./selectedDTypes.ts";
import saatSecim from "./saatSecim.vue";
import applyAllDays from "@/modules/doctorSlots/assets/applyAllDays.svg";
import { doctorCalendarStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorCalendarStore";
import { doctorInfo } from "@/modules/doctorSlots/components/doctorInfoReactiveTypes";
import { useCancelDoctorSetting } from "@/store/modules/operationsStore/doctorSettings/useCancelDoctorSetting";
const cancelDoctorSettingStore = useCancelDoctorSetting();

moment.locale("tr");
const props = defineProps({
  openAddToDrCalendarFalse: Function,
});
import { searchSlotsBetweenDate } from "../../../store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
const addDoctorSettingsStore = useAddDoctorSetting();

const applyAll = () => {
  const activeDay =
    doctorCalendarStore().selectedDays[doctorCalendarStore().activeIndex];
  doctorCalendarStore().selectedDays.forEach((day, index) => {
    if (index !== doctorCalendarStore().activeIndex) {
      day.workingHours = [...activeDay.workingHours];
      day.workingMinutes = activeDay.workingMinutes.map((minute) => ({
        ...minute,
      }));
      day.overbookAllowed = activeDay.overbookAllowed;
      day.overbookLimit = activeDay.overbookLimit;
      day.examinationDuration = activeDay.examinationDuration;
    }
  });
};

const handleCreateSetting = async () => {
  globalStore.isCreating = true;
  const body = {
    DoctorSettings: [],
    DoctorID: JSON.parse(localStorage.getItem("personelStoreData")).staffId,
    SlotStartDate: moment(doctorCalendarStore().selectedDays[0].date)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]"),
    SlotEndDate: moment(
      doctorCalendarStore().selectedDays[
        doctorCalendarStore().selectedDays.length - 1
      ].date
    )
      .add(1, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]"),
  };

  doctorCalendarStore().selectedDays.forEach((day) => {
    const workingHoursArray = day.workingHours;
    console.log("overbooklimit", day.overbookLimit);
    // Her bir günün çalışma saatleri için döngü
    workingHoursArray.forEach((workingHours, index) => {
      const newSetting = {
        WorkingHours: `${workingHours.start}:${day.workingMinutes[index].start}-${workingHours.end}:${day.workingMinutes[index].end}`,
        OverbookAllowed: day.overbookAllowed,
        OverbookLimit: {
          Int32: parseInt(day.overbookLimit) || 0,
          Valid: true,
        },
        AppointmentTypes: doctorCalendarStore().appointmentType,
        SlotDuration: parseInt(day.examinationDuration),
        SlotDate: moment(day.date).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      };
      // Yeni ayarları DoctorSettings array'ine ekleyin
      body.DoctorSettings.push(newSetting);
    });
  });

  await addDoctorSettingsStore.addDoctorSetting(body);

  setTimeout(() => {
    props.openAddToDrCalendarFalse();
    globalStore.createSettingFail = false;
    globalStore.createSettingSuccessful = false;
  }, 2000);
};
const showSaatSecim = ref(false);

const handleDeleteSetting = async () => {
  globalStore.isCreating = true;
  console.log("ce", doctorCalendarStore().updatingSetting);
  await cancelDoctorSettingStore.cancelDoctorSetting(
    doctorCalendarStore().updatingSetting.id
  );
  setTimeout(() => {
    props.openAddToDrCalendarFalse();
    globalStore.createSettingFail = false;
    globalStore.createSettingSuccessful = false;
  }, 2000);
};

const updateSetting = async () => {
  globalStore.isCreating = true;
  const body = {
    DoctorSettings: [],
    DoctorID: JSON.parse(localStorage.getItem("personelStoreData")).staffId,
    SlotStartDate: moment(doctorCalendarStore().selectedDays[0].date)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]"),
    SlotEndDate: moment(
      doctorCalendarStore().selectedDays[
        doctorCalendarStore().selectedDays.length - 1
      ].date
    )
      .add(1, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]"),
  };

  doctorCalendarStore().selectedDays.forEach((day) => {
    const workingHoursArray = day.workingHours;
    console.log("overbooklimit", day.overbookLimit);
    // Her bir günün çalışma saatleri için döngü
    workingHoursArray.forEach((workingHours, index) => {
      const newSetting = {
        WorkingHours: `${workingHours.start}:${day.workingMinutes[index].start}-${workingHours.end}:${day.workingMinutes[index].end}`,
        OverbookAllowed: day.overbookAllowed,
        OverbookLimit: {
          Int32: parseInt(day.overbookLimit) || 0,
          Valid: true,
        },
        AppointmentTypes: doctorCalendarStore().appointmentType,
        SlotDuration: parseInt(day.examinationDuration),
        SlotDate: moment(day.date).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      };
      // Yeni ayarları DoctorSettings array'ine ekleyin
      body.DoctorSettings.push(newSetting);
    });
  });

  console.log(body);

  setTimeout(() => {
    props.openAddToDrCalendarFalse();
    globalStore.createSettingFail = false;
    globalStore.createSettingSuccessful = false;
  }, 2000);
};

// const isButtonDisabled = () => {
//   for (const day of selectedDTypes.days) {
//     if (
//       (day.workingHours && day.workingHours[0].length !== 2) ||
//       !day.muayeneSuresi
//     ) {
//       return true; // Eğer bir günün workingHours[0] uzunluğu 2 değilse, butonu devre dışı bırak
//     }
//   }

//   return false; // Eğer hiçbir günün workingHours[0] uzunluğu 2 değilse, butonu etkinleştir
// };
</script>

<style scoped></style>
