<script>
// Import navbar
import { defineComponent } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { searchSlotsBetweenDate } from "../../../../store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
import Select2 from "vue3-select2-component";
import { mapStores } from "pinia";
import { ref, watch } from "vue";

// Detail Hasta
// References
import {
  INITIAL_EVENTS,
  fetchAppointments,
  initalBackgroundMonthlyEvents,
} from "../../../../components/asistant/CalendarEvent.ts";
import { globalStore } from "@/store/modules/globalStore";
import {
  calendarStoreCalendarFilter,
  returnCalendarSlotClinicName,
  boolCountSearchSlotsForClinic,
} from "@/modules/asistanEkrani/asistanCalendar/store/calendarStore.js";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic";
import moment from "moment";
import { useDeleteVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useDeleteVEMAppointment";
export default defineComponent({
  components: {
    searchSlotsBetweenDate,
    FullCalendar,
    Select2,
    useDeleteVEMAppointment,
  },
  computed: {
    propertyAAndPropertyB() {
      return `${this.calendarStoreCalendarFilter}|${globalStore.appointmentStartTime}`;
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        customButtons: {
          create: {
            text: "time",
            //icon: "fa-regular fa-user",
            click: this.handleRequestApproval,
          },
          requestApproval: {
            text: "Request Approval",
            //icon: "fa-regular fa-user",
            click: this.handleRequestApproval,
          },
          refresh: {
            text: "Refresh",
            click: this.handleRefresh,
          },
          save: {
            text: "Save",
            click: this.handleSave,
          },
          filter: {
            text: "Filter",
            click: this.handleFilter,
          },
          scheduleSettings: {
            text: "Schedule Settings",
            click: this.handleScheduleSettings,
          },
        },
        themeSystem: "bootstrap4",
        themeButtonIcons: true,
        headerToolbar: {
          start: "create",
          left: "prev,title,next",
          center: "",
          right: "",
        },
        timeZone: "UTC",
        dayHeaderFormat: { weekday: "long" },
        eventLimit: 3,
        firstDay: 1,
        dayHeaderContent: function (arg) {
          return {
            html: `<span class="custom-day-header">${arg.text}</span>`,
            classNames: ["custom-day-header"],
          };
        },
        datesSet: async function (info) {
          console.log("datesSet changed", info.view.calendar);
          globalStore.appointmentStartTime = info.start; // Görüntülenen tarih aralığının başlangıç tarihi-globalstore'a gerek kalmayabilir
          globalStore.appointmentEndTime = info.end; // Görüntülenen tarih aralığının bitiş tarihi
          console.log(
            "globalStore.appointmentStartTimeglobalStore.appointmentStartTime",
            globalStore.appointmentStartTime
          );

          await fetchAppointments();
          await searchSlotsBetweenDate().slotsBetweenDate(
            moment(globalStore.appointmentStartTime).format("YYYY-MM-DD"), //saat dakika saniye bilgisini kaldırdım
            moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
            "calendarMonthlySlots",
            returnCalendarSlotClinicName()
          );
          // console.log(
          //   "initalBackgroundMonthlyEventsinitalBackgroundMonthlyEventsinitalBackgroundMonthlyEvents watcher filter",
          //   initalBackgroundMonthlyEvents
          // );
        },
        // initialEvents: initalBackgroundMonthlyEvents,
        events: [...initalBackgroundMonthlyEvents, ...INITIAL_EVENTS],
        
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
      },
      toggleShowHover: false,
      currentEvents: [],
      title: "",
      globalStore,
      calendarStoreCalendarFilter,
      boolCountSearchSlotsForClinic,
      calenderObject: ref("calendar"),
      calenderStartTime: ref(globalStore.appointmentStartTime),
    };
  },
  watch: {
    // whenever question changes, this function will run
    async propertyAAndPropertyB(newVal, oldQuestion) {
      this.calendarOptions.events = [];
      console.log("assistant calendar", newVal);
      this.handleResetTheEvents();
      await fetchAppointments();
      await searchSlotsBetweenDate().slotsBetweenDate(
        moment(globalStore.appointmentStartTime).format("YYYY-MM-DD HH:MM:SS"), //2023-06-18 03:06:00" foormatına dönüştür- dünya saatine ayarlı
        moment(globalStore.appointmentEndTime).format("YYYY-MM-DD HH:MM:SS"),
        "calendarMonthlySlots",
        returnCalendarSlotClinicName()
      );
      console.log(
        "initalBackgroundMonthlyEventsinitalBackgroundMonthlyEventsinitalBackgroundMonthlyEvents watcher filter",
        initalBackgroundMonthlyEvents
      );
      this.calendarOptions.events = [
        ...initalBackgroundMonthlyEvents,
        ...INITIAL_EVENTS,
      ];
    },
  },

  async mounted() {
    // this.getCalender();
    // Fetching Data for fullcalendar rendered for the first time
    await fetchAppointments();
    await searchSlotsBetweenDate().slotsBetweenDate(
      moment(globalStore.appointmentStartTime).format("YYYY-MM-DD HH:MM:SS"), //2023-06-18 03:06:00" foormatına dönüştür- dünya saatine ayarlı
      moment(globalStore.appointmentEndTime).format("YYYY-MM-DD HH:MM:SS"),
      "calendarMonthlySlots",
      returnCalendarSlotClinicName()
    );
    this.calendarOptions.events = [
      ...initalBackgroundMonthlyEvents,
      ...INITIAL_EVENTS,
    ];
    globalStore.isEventsLoading = true;
    globalStore.isAppointmentCreateSuccesful = false;
    // this.calendarOptions.initialEvents = initalBackgroundMonthlyEvents;

    this.setTurkishLocale(); // Türkçe dil ayarlarını ayarla
    const titleElement = document.querySelector(".fc-toolbar-title");
    const requestApproval = document.querySelector(".fc-requestApproval");
    const refresh = document.querySelector(".fc-refresh");
    const save = document.querySelector(".fc-save");
    const filter = document.querySelector(".fc-filter");
    const scheduleSettings = document.querySelector(".fc-scheduleSettings");

    if (requestApproval) {
      requestApproval.addEventListener("click", this.handleRequestApproval);
    }
    if (refresh) {
      refresh.addEventListener("click", this.handleRefresh);
    }
    if (save) {
      save.addEventListener("click", this.handleSave);
    }
    if (filter) {
      save.addEventListener("click", this.handleFilter);
    }
    if (scheduleSettings) {
      scheduleSettings.addEventListener("click", this.handleScheduleSettings);
    }
    titleElement.addEventListener("click", this.handleTitleClick);
  },

  methods: {
    handleRequestApproval() {
      console.log("handleRequestApproval");
    },
    handleRefresh() {
      window.location.reload();
    },
    handleSave() {
      console.log("handleSave");
    },
    handleScheduleSettings() {
      console.log("handleScheduleSettings");
    },
    handleFilter() {
      console.log("handlefilter");
    },
    handleTitleClick() {
      globalStore.isCreateAppointmentSideBarOpen = false;

      const calendarApi = this.$refs.calendar.getApi();
      calendarApi.today(); // Takvimi bugüne getirin
    },

    setTurkishLocale() {
      this.calendarOptions.locale = "tr"; // Dil ayarını Türkçe olarak belirle
      this.calendarOptions.slotLabelFormat = {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      }; // Saat formatını Türkçe ayarla
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    async handleDateSelect(selectInfo) {
      globalStore.isCreateAppointmentSideBarOpen = false;
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
      console.log("countSearchSlots", boolCountSearchSlotsForClinic.value);
      let calendarApi = selectInfo.view.calendar;
      globalStore.calendarSideBarType = "";
      globalStore.isCreateAppointmentSideBarOpen = true; //when an cell clicked, open the side bar
      SearchFreeSlotsforClinic().$state.calendarSelectedStartDate =
        selectInfo.startStr; //send start en end days to side bar component
      SearchFreeSlotsforClinic().$state.calendarSelectedEndDate =
        selectInfo.endStr;
      calendarApi.unselect(); // clear date selection

      // if (title) {
      //   calendarApi.addEvent({
      //     id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay,
      //   });
      // }
    },
    async handleEventClick(clickInfo) {
      globalStore.isCreateAppointmentSideBarOpen = false;
      const deleteVEMAppointmentStore = useDeleteVEMAppointment();
      if (
        confirm(
          `Are you sure you want to delete the event '${clickInfo.event.title}'`
        )
      ) {
        await deleteVEMAppointmentStore.deleteVEMAppointment(
          clickInfo.event._def.extendedProps.patientApponintmentCode
        );
        clickInfo.event.remove();
      }
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleEventsConsoleLog(events) {
      console.log("events", events);
    },
    handleResetTheEvents() {
      this.currentEvents = [];
      this.initalBackgroundMonthlyEvents = [];
    },
    handleSetCalendarData() {
      this.calendarOptions.events = [
        ...initalBackgroundMonthlyEvents,
        ...INITIAL_EVENTS,
      ];
      console.log("handleSetCalendarData");
    },
    // getCalender() {
    //   let calendarApi = this.$refs.fullCalendar.getApi();
    //   console.log("calendarApi", calendarApi);
    //   return calendarApi;
    // },
  },
});
</script>

<template>
  <div
    v-if="globalStore.isAppointmentCreateSuccesful"
    class="bg-green-50 border rounded-lg w-2/5 h-10 pop-up success"
  >
    Randevu başarı ile oluşturuldu.
  </div>
  <div
    v-if="globalStore.isAppointmentCreateFail"
    class="bg-red-300 border rounded-lg w-2/5 h-11 pop-up success"
  >
    Randevu oluşturulurken bir hata meydana geldi.
  </div>
  <div
    @click="globalStore.isCreateAppointmentSideBarOpen = false"
    class="calendar-app"
  >
    <div class="relative">
      <div class="absolute top-[43px] left-[400px]">
        <Select2
          class="rounded align-middle justify-between p-1 flex h-[31px] sm:w-[160px] 2xl:w-[300px] border-[#D9D9D9] cursor-pointer"
          :options="[
            'Ağız,Diş ve Çene Radyolojisi',
            'Ağız,Diş ve Çene Cerrahisi',
            'Restoratif Diş Tedavisi',
            'Endodonti',
            'Pedodonti',
            'Periodontoloji',
            'Protetik Diş Tedavisi',
            'Birim1',
          ]"
          @select="
            ($event) => {
              calendarStoreCalendarFilter = $event.id;
              globalStore.calendarSideBarType = '';
            }
          "
          placeholder="Bir Bolum Seciniz"
          :class="'text'"
          v-model="calendarStoreCalendarFilter"
        />
      </div>
    </div>
    <div class="calendar-app-main">
      <FullCalendar
        ref="fullCalendar"
        class="calendar-app-calendar"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <div
            @mouseover="arg.onHover = true"
            @mouseleave="arg.onHover = false"
            @click="handleEventsConsoleLog(arg.event)"
            class="w-full"
          >
            <div
              class="calendar-monthly"
              v-if="arg.event.title !== '' && !arg.onHover"
            >
              <b>{{ arg.event.title }}</b>
              <div class="clinic">
                <span class="clinicText">{{
                  arg.event.extendedProps.patientStatus
                }}</span>
              </div>
            </div>
            <div v-if="arg.onHover" class="w-full">
              <div
                class="calendar-weekly calendar-monthly-weekly-view-card"
                v-if="arg.event.title !== ''"
              >
                <div class="w-full flex justify-between items-center">
                  <b class="calendar-weekly-title ml-1 mt-[2px]">{{
                    arg.event.title
                  }}</b>
                  <img
                    @click="handleEventClick(arg.event)"
                    src="@/modules/asistanEkrani/assets/menu-dots-vertical.png"
                    class="weekly-dots mr-1"
                  />
                </div>
                <div>
                  <b class="calendar-weekly-text ml-1">{{
                    arg.event.extendedProps.detail
                  }}</b>
                </div>
                <div class="-ml-[6px] flex">
                  <b class="calendar-weekly-time">
                    {{ arg.event.start.getUTCHours().toString() }}:{{
                      arg.event.start.getUTCMinutes().toString()
                    }}
                    - {{ arg.event.end.getUTCHours().toString() }}:{{
                      arg.event.end.getUTCMinutes().toString()
                    }}
                  </b>
                  <div class="clinic ml-auto mr-2 mb-2 -mt-1">
                    <span class="clinicText">{{
                      arg.event.extendedProps.patientStatus
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<style lang="css" scoped>
@import "@/modules/asistanEkrani/asistanCalendar/calendarStyle.css";
.pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
}

.pop-up.success {
  opacity: 1;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
