<template>
  <div class="ml-[10px] flex h-[40px] bg-[#F1F1F1]">
    <button @click="toggleNavbar(1)" class="ml-[900px]">
      <img :src="hideGray" alt="hideGray" />
    </button>
    <studentAssistantFilterButtonHideButton />

    <button @click="toggleNavbar(2)" class="ml-4">
      <img :src="filterGray" alt="filterImg" />
    </button>
    <studentAssistantFilterButton />
    <button @click="toggleNavbar(3)" class="ml-4">
      <img :src="filterFile" alt="filterFileImg" />
    </button>
    <studentAssistantFilterFile/>
    <studentAssistantFilterFileFlow/>
    <studentAssistantFilteredDataShow/>
    <div
      class="my-auto ml-[68px] h-[25px] rounded-[10px] border-2 border-[#9D9D9D]"
    >
      <input
        class="my-auto rounded-[10px] pl-2 focus:outline-none"
        type="text"
        placeholder="Ara"
        :name="store.search"
        :id="store.search"
        v-model="store.search"
        />
    </div>
    <div 
    v-if="store.search"
    class="absolute right-0 h-screen w-[292px] bg-white">
      <input
        class="flex mx-auto mt-5 rounded-[10px] pl-2 focus:outline-none border-2 border-[#9D9D9D] w-[206px] "
        type="text"
        placeholder="Ara"
        :name="store.search"
        :id="store.search"
        v-model="store.search"
        />
    </div>
  </div>
</template>


<script setup>
import hideGray from "../assets/hideGray.svg";
import filterGray from "../assets/filterGray.svg";
import filterFile from "../assets/filterFile.svg";
import studentAssistantFilterButton from "./studentAssistantFilterButton.vue";
import studentAssistantFilterButtonHideButton from "./studentAssistantFilterButtonHideButton.vue";
import studentAssistantFilterFile from "./studentAssistantFilterFile.vue";
import studentAssistantFilterFileFlow from "./studentAssistantFilterFileFlow.vue";
import studentAssistantFilteredDataShow from "./studentAssistantFilteredDataShow.vue";
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();

const toggleNavbar = (num) => {
  if (store.studentAssistantFilter === num) {
    store.studentAssistantFilter = 0;
  } else {
    store.studentAssistantFilter = num;
  }
};
</script>