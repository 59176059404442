<template>
  <div class="mt-20 flex flex-row justify-center mt-28">
    <img :src="sskImg" class="mt-52 max-h-[310px]" alt="" />

    <div class="ml-10 flex flex-row justify-center">
      <div class="flex w-full flex-col">
        <p class="flex text-xl font-semibold">Sıkça Sorulan Sorular</p>
        <!-- <div
                    class="mx-auto h-10 w-full max-w-[666px] border-t-2 border-[#D9D9D9]"
                  ></div> -->
        <button
          ref="soru"
          @click="showSorulanSorular(1)"
          class="mx-auto mt-10 flex h-10 w-full max-w-[645px] flex-row justify-between"
        >
          <p
            :class="
              sorulanSorularRef == '1'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            Randevuyu Nasıl Alabilirim?
          </p>
          <img
            :src="sorulanSorularRef == '1' ? upArrowBlue : downArrowBlack"
            alt=""
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
        ></div>
        <div
          :class="
            sorulanSorularRef == '1'
              ? 'flex max-h-[580px]  flex-col'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p class="mt-3 max-w-[645px]">
            Randevu sistemi üzerinden ilk muayene için randevu alınabilmektedir.
            Randevular; Yetişkin İlk Muayene (15 yaş ve üstü), Çocuk İlk Muayene
            (15 yaş altı) randevuları şeklinde iki türde verilmektedir.
          </p>
          <p class="mt-3 max-w-[645px] font-semibold">
            Randevularımız yetişkin hastalar için her gün saat 15:00’te bir
            sonraki iş günü için web sitemiz üzerinden açılmaktadır.
          </p>
          <p class="mt-3 max-w-[645px]">
            Randevular; Yetişkin İlk Muayene (15 yaş ve üzeri), Çocuk İlk
            Muayene Pedodonti (0-15 yaş arası) randevuları şeklinde
            verilmektedir. Randevularınızı belirlenen yaş gruplarına göre; 0-15
            yaş için her hafta
            <span class="font-medium text-[#4198F7]"
              >Salı-Çarşamba-Perşembe-Cuma</span
            >
            günleri olmak üzere saat
            <span class="font-medium text-[#4198F7]">15:00</span>
            ‘te web sitemiz üzerinden veya üzerinden alabilirsiniz
          </p>
          <p class="mt-3 max-w-[645px] font-semibold">
            18 yaşından küçük hastalarımızın muayene ve tedavilerine gelirken
            ebeveynleriyle birlikte gelmesi gerekmektedir.
          </p>
          <p class="mt-3 max-w-[645px]">
            Hafta Sonları ve Resmi Tatil günlerine randevu verilmemektedir.
          </p>
          <p class="mt-3 max-w-[645px]">
            Merkezimize muayene ve tedavi amaçlı başvuran
            <span class="font-medium text-[#4198F7]">YEŞİLKART</span>

            hastalarımızın her gelişlerinden önce Ağız ve Diş Sağlığı
            Merkezi/Hastanesi gibi 2.Basamak sağlık kuruluşlarından
            <span class="font-medium text-[#4198F7]">SEVK</span>
            almaları gerekmektedir.
          </p>
          <p class="mt-3 max-w-[645px]">
            Hastanemize başvuran kurum sandık hastalarının, kurum sevki, kurum
            kimlik kartı ve kimlik ibrazı yapmaları gerekmektedir.
          </p>
          <p class="mt-3 max-w-[645px]">
            Her Anabilim Dalı Kliniğinin günlük hasta kontenjan sayısı (kabul
            edilen hasta sayısı) değişiklik göstermektedir. Mevcut tedavi
            edilecek hasta sayıları; ilgili Anabilim Dalı uzman hekim sayısı ve
            tedavi süreleri göz önüne alınarak ilgili Anabilim Dalı Başkanları
            tarafından belirlenmektedir.
          </p>
          <p class="mt-3 max-w-[645px]">
            Randevu günü verilen randevu saatinde Fakülteye gelen hastaların,
            ilk olarak hasta kabul birimine başvurarak giriş yaptırması gerekir.
          </p>
          <p class="mt-3 max-w-[645px]">
            İlk muayene sonrası hekimin yönlendirme yaptığı teşhis planlama
            formu ile hastalarımız hasta kabul birimine başvurarak
            yönlendirmeleri yapılmaktadır.
          </p>
        </div>
        <button
          @click="showSorulanSorular(2)"
          ref="soru"
          class="mx-auto mt-10 flex h-10 w-full max-w-[645px] flex-row justify-between"
        >
          <p
            :class="
              sorulanSorularRef == '2'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            Randevular Neden İnternet Üzerinden Alınıyor?
          </p>
          <img
            :src="sorulanSorularRef == '2' ? upArrowBlue : downArrowBlack"
            alt=""
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
        ></div>
        <div
          :class="
            sorulanSorularRef == '2'
              ? 'flex max-h-[100px]'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p class="mx-auto mt-3 flex w-full max-w-[645px]">
            Sağlık Bakanlığı, yayınladığı Covid-19 pandemisinde çalışma
            rehberine* göre; hastalara randevu verilerek tedavi ve muayene
            işlemlerinin yürütülmesini, iki hasta arasında temizlik ve
            havalandırmaya yeterli zaman ayrılmasını (30 dk) ve hasta trafiğinin
            kontrol altında tutulmasını istemektedir. Bu nedenle ilk muayene
            randevularımız internet üzerinden verilmektedir.
          </p>
        </div>
        <button
          @click="showSorulanSorular(3)"
          ref="soru"
          class="mx-auto mt-7 flex h-10 w-full max-w-[645px] flex-row justify-between"
        >
          <p
            :class="
              sorulanSorularRef == '3'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            Tüm Bölümlerden İnternet Randevusu Alınabiliyor mu?
          </p>
          <img
            :src="sorulanSorularRef == '3' ? upArrowBlue : downArrowBlack"
            alt=""
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
        ></div>
        <div
          :class="
            sorulanSorularRef == '3'
              ? 'flex max-h-[100px]'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p class="mx-auto mt-3 flex w-full max-w-[645px]">
            Sağlık Bakanlığı’nın yayınladığı Covid-19 pandemisinde çalışma
            rehberine göre; randevulu hastalara randevu verilerek tedavi
            verilmesi ve hasta trafiğin kontrol altında tutulması istenmektedir.
            Bu sebeple pandemi sürecinde randevusuz hasta bakılamamaktadır.
          </p>
        </div>
        <button
          @click="showSorulanSorular(4)"
          ref="soru"
          class="mx-auto mt-7 flex h-10 w-full max-w-[645px] flex-row justify-between"
        >
          <p
            :class="
              sorulanSorularRef == '4'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            182 MHRS’den Randevu Alabilir miyim?
          </p>
          <img
            :src="sorulanSorularRef == '4' ? upArrowBlue : downArrowBlack"
            alt=""
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
        ></div>
        <div
          :class="
            sorulanSorularRef == '4'
              ? 'flex max-h-[100px]'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p class="mx-auto mt-3 flex w-full max-w-[645px]">
            Sağlık Bakanlığı’nın yayınladığı Covid-19 pandemisinde çalışma
            rehberine göre; randevulu hastalara randevu verilerek tedavi
            verilmesi ve hasta trafiğin kontrol altında tutulması istenmektedir.
            Bu sebeple pandemi sürecinde randevusuz hasta bakılamamaktadır.
          </p>
        </div>
        <button
          @click="showSorulanSorular(5)"
          ref="soru"
          class="mx-auto mt-7 flex h-10 w-full max-w-[645px] flex-row justify-between"
        >
          <p
            :class="
              sorulanSorularRef == '5'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            Randevusuz Hasta Alınıyor mu?
          </p>
          <img
            :src="sorulanSorularRef == '5' ? upArrowBlue : downArrowBlack"
            alt=""
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
        ></div>
        <div
          :class="
            sorulanSorularRef == '5'
              ? 'flex max-h-[100px]'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p class="mx-auto mt-3 flex w-full max-w-[645px]">
            Sağlık Bakanlığı’nın yayınladığı Covid-19 pandemisinde çalışma
            rehberine göre; randevulu hastalara randevu verilerek tedavi
            verilmesi ve hasta trafiğin kontrol altında tutulması istenmektedir.
            Bu sebeple pandemi sürecinde randevusuz hasta bakılamamaktadır.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import sskImg from "../assets/sikca-sorulan-sorular-img.svg";
import upArrowBlue from "../assets/upArrowBlue.svg";
import downArrowBlack from "../assets/downArrowBlack.svg";
import { ref } from "vue";
let sorulanSorularRef = ref(1);

const showSorulanSorular = (valueOfRef) => {
  sorulanSorularRef.value = valueOfRef;
};
</script>
  
  
  <style scoped>
.sorulanSorularParagrafHideText {
  display: none;
}
.sorulanSorularActiveButtonText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #4198f7;
}
.sorulanSorularNormalButtonText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
</style>
  
