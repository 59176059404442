<template>
  <div class="flex items-center gap-3 my-4 bg-[#F3F6F9] py-2 px-4 rounded">
    <img :src="profileCircle" alt="profile photo" />
    <h2
      class="font-medium text-xl"
      v-if="registeredPatientListStore.provisionsPatient"
    >
      {{
        registeredPatientListStore.provisionsPatient?.attributes.name +
        " " +
        registeredPatientListStore.provisionsPatient?.attributes.surname
      }}<span
        class="ml-2"
        :class="
          registeredPatientListStore.provisionsPatient?.attributes?.gender ===
          'erkek'
            ? 'text-[#0457F8]'
            : registeredPatientListStore.provisionsPatient?.attributes
                ?.gender === 'kadın'
            ? 'text-[#DE3163]'
            : ''
        "
        >{{
          registeredPatientListStore.provisionsPatient?.attributes.gender ===
          "erkek"
            ? "(E)"
            : registeredPatientListStore.provisionsPatient?.attributes
                .gender === "kadın"
            ? "(K)"
            : ""
        }}</span
      >
    </h2>
    <div class="ml-2">
      <div class="flex">
        <div class="min-w-[362px] flex">
          <p class="min-w-[132px]">TC No</p>
          <p class="font-medium">
            {{
              registeredPatientListStore.provisionsPatient?.attributes
                .tc_identity_number
            }}
          </p>
        </div>
        <div class="min-w-[245px] flex">
          <p class="min-w-[95px]">Takip No</p>
          <p class="font-medium">
            {{ registeredPatientListStore.provisionsDatas.trackingNo }}
          </p>
        </div>
        <div class="flex">
          <p class="min-w-[80px]">Kurum</p>
          <p class="font-medium">
            {{ registeredPatientListStore.provisionsDatas.corporation }}
          </p>
        </div>
      </div>
      <div class="flex mt-1">
        <div class="min-w-[362px] flex">
          <p class="min-w-[132px]">Doğum Tarihi</p>
          <p
            v-if="registeredPatientListStore.provisionsPatient?.attributes?.dob"
            class="font-medium"
          >
            {{
              formatDateFromISO8601(
                registeredPatientListStore.provisionsPatient?.attributes?.dob
              )
            }}<span class="font-light ml-1 text-sm"
              >({{
                formatAgeString(
                  registeredPatientListStore.provisionsPatient?.attributes?.dob
                )
              }})</span
            >
          </p>
        </div>
        <div class="min-w-[245px] flex">
          <p class="min-w-[95px]">Kabul No</p>
          <p class="font-medium">
            {{ registeredPatientListStore.provisionsDatas.acceptanceNo }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
const registeredPatientListStore = useRegisteredPatientListStore();

function formatDateFromISO8601(isoDateString) {
  const dateObject = new Date(isoDateString);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Ay indeksi 0'dan başlar
  const year = dateObject.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;
  return formattedDate;
}

function formatAgeString(dob) {
  const birthDate = new Date(dob);
  const today = new Date();

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();
  let ageDays = today.getDate() - birthDate.getDate();

  if (ageDays < 0) {
    ageMonths--;
    const tempDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      birthDate.getDate()
    );
    ageDays = Math.floor((today - tempDate) / (1000 * 60 * 60 * 24));
  }

  if (ageMonths < 0) {
    ageYears--;
    ageMonths += 12;
  }

  const ageString = `${ageYears} yıl ${ageMonths} ay ${ageDays} gün`;
  return ageString;
}
</script>
