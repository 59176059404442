import { createApp, h } from "vue";
import { createPinia } from "pinia";
import "@vuepic/vue-datepicker/dist/main.css";
import "./assets/tailwind.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import App from "../src/common/App.vue";
import axios from "axios";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";

import "./common/style.css";
import router from "./router";

function showLoadingIndicator() {
  document.body.style.cursor = "progress"; // Mouse imleci yükleniyor işareti
  const buttons = document.querySelectorAll("button");
  buttons.forEach((button) => {
    button.classList.add("cursor-progress");
  });
}

function hideLoadingIndicator() {
  document.body.style.cursor = "auto"; // Normal mouse imleci
  //   const buttons = document.querySelectorAll("button");

  const buttons = document.querySelectorAll("button");
  buttons.forEach((button) => {
    button.classList.remove("cursor-progress");
  });
}

axios.interceptors.request.use(
  function (config) {
    // İstek başladığında yapılacak işlemler (örneğin yükleniyor göstergesi gösterme)
    showLoadingIndicator();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // İstek tamamlandığında yapılacak işlemler (örneğin yükleniyor göstergesi gizleme)
    hideLoadingIndicator();
    return response;
  },
  function (error) {
    hideLoadingIndicator();
    // if (error.response.status === 401) {
    //   // aşağıdaki yorum kısmından örnek alarak user tipine göre patient veya personel logine gönder

    //   //      // Kullanıcının türünü belirle (örneğin, localStorage'dan veya Vuex store'dan alabilirsin)
    //   //   const userType = localStorage.getItem('userType'); // Örnek olarak localStorage kullanıyoruz

    //   //   // Kullanıcı türüne göre yönlendirme yap
    //   //   if (userType === 'personel') {
    //   //     router.push('/personel-login'); // Personel giriş sayfasına yönlendir
    //   //   } else if (userType === 'hasta') {
    //   //     router.push('/hasta-login'); // Hasta giriş sayfasına yönlendir

    //   // 401 hatası alındığında kullanıcıyı yönlendirme işlemi
    //   router.push("/personel/loginPage"); // Kullanıcıyı giriş sayfasına yönlendir
    // }
    return Promise.reject(error);
  }
);

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);
app.use(VueAwesomePaginate);
app.use(Vue3Lottie, { name: "LottieAnimation" });

app.mount("#app");
app.component("VueDatePicker", VueDatePicker);
// app.component("Select-2", Select2);
