<template>
  <div
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[768px] bg-[#F8F8F8] flex flex-col rounded-[20px] shadow-xl border-[#D9D9D940] border-2 z-50"
  >
    <button
      class="border-[1px] border-[#277DD2] text-[#277DD2] w-[200px] h-[30px] rounded-[5px] ml-auto mt-2 mr-2"
    >
      Yeşil kart bilgisi sorgula
    </button>

    <div
      class="bg-white p-3 flex flex-col w-[741px] mx-auto rounded-[10px] border-[0.5px] border-[#00000033] mt-3"
    >
      <div class="flex">
        <div class="flex flex-col">
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light">Adı :</span>
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{ patient.HastaKabulReturn.HastaBilgileri.Ad || "" }}</span
            >
          </div>
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light">Soyadı :</span>
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{ patient.HastaKabulReturn.HastaBilgileri.Soyad || "" }}</span
            >
          </div>

          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light">T.C No :</span>
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{
                patient.HastaKabulReturn.HastaBilgileri.TcKimlikNo || ""
              }}</span
            >
          </div>
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light">Takip No :</span>
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{ patient.HastaKabulReturn.TakipNo || "" }}</span
            >
          </div>
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light"
              >Sigortalı Türü :</span
            >
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
            >
              {{
                patient.HastaKabulReturn.HastaBilgileri.SigortaliTuru === "1"
                  ? "Çalışan"
                  : patient.HastaKabulReturn.HastaBilgileri.SigortaliTuru ===
                    "2"
                  ? "Emekli"
                  : patient.HastaKabulReturn.HastaBilgileri.SigortaliTuru ===
                    "3"
                  ? "SSK Kurum Personeli"
                  : patient.HastaKabulReturn.HastaBilgileri.SigortaliTuru ===
                    "4"
                  ? "Diğer"
                  : ""
              }}
            </span>
          </div>
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light"
              >Katılım Payı :</span
            >
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{
                patient.HastaKabulReturn.HastaBilgileri.KatilimPayindanMuaf ===
                "H"
                  ? "Muaf Değil"
                  : patient.HastaKabulReturn.HastaBilgileri
                      .KatilimPayindanMuaf === "E"
                  ? "Muaf"
                  : ""
              }}</span
            >
          </div>
        </div>
        <div class="flex flex-col ml-[200px]">
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light">Cinsiyeti :</span>
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{
                patient.HastaKabulReturn.HastaBilgileri.Cinsiyet || ""
              }}</span
            >
          </div>
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light"
              >Doğum Tarihi :</span
            >
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{
                patient.HastaKabulReturn.HastaBilgileri.DogumTarihi || ""
              }}</span
            >
          </div>
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light">Başvuru No :</span>
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
              >{{ patient.HastaKabulReturn.HastaBasvuruNo || "" }}</span
            >
          </div>
          <div class="flex-row flex justify-start my-1 ml-1">
            <span class="text-[#333333] text-xl font-light">
              Devredilen Kurum Bilgisi :</span
            >
            <span
              v-if="patient.HastaKabulReturn"
              class="text-[#333333] text-xl font-medium"
            >
              {{
                kurumTypes.find(
                  (item) =>
                    item.id ===
                    patient.HastaKabulReturn.HastaBilgileri.DevredilenKurum
                )?.text || ""
              }}</span
            >
          </div>
          <div
            class="flex-row flex justify-start my-1 ml-1"
            v-if="medulaStore.bagliTakip"
          >
            <span class="text-[#333333] text-xl font-light">
              Bağlantılı Olduğu Takip Numarası :</span
            >
            <span class="text-[#333333] text-xl font-medium">{{
              medulaStore.bagliTakipEdilecekTakipNo
            }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="patient.HastaKabulReturn"
        class="w-full p-2 mx-auto flex items-center justify-center text-xl font-semibold border-[#277DD2] border-[1px] rounded-xl mt-8"
        :class="
          patient.HastaKabulReturn.SonucKodu === '1163'
            ? 'bg-[#fceeee] text-[#d9534f]'
            : ' text-[#277DD2] '
        "
      >
        <span class="text-center">{{
          patient.HastaKabulReturn.SonucKodu === "0000"
            ? "Kapsam Adı: " + patient.HastaKabulReturn.HastaBilgileri.KapsamAdi
            : patient.HastaKabulReturn.SonucMesaji
        }}</span>
      </div>
      <!-- <div
        v-if="patient.HastaKabulReturn.SonucKodu === '1163'"
        class="bg-[#E8F6EE] text-lg text-black p-3 mt-2 w-full rounded-full flex items-center justify-between"
      >
        <div class="flex gap-3">
          <p class="opacity-60">Bağlı Takip:</p>
          <p>{{ patient.HastaKabulReturn.TakipNo }}</p>
        </div>
        <div class="flex gap-3">
          <p class="opacity-60">Birim:</p>
          <p>{{ patient.HastaKabulReturn.HastaBilgileri.BirimAdi }}</p>
        </div>
        <div class="flex gap-3">
          <p class="opacity-60">Provizyon tipi:</p>
          <p>{{ patient.HastaKabulReturn.HastaBilgileri.ProvizyonTipi }}</p>
        </div>
      </div> -->
      <div
        class="flex items-center mt-6"
        :class="patient.HastaKabulReturn ? 'ml-auto' : 'ml-0'"
      >
        <p
          v-if="patient.HastaKabulReturn"
          class="text-[#333333] text-xl min-w-[140px]"
        >
          Sonuç Mesajı :
        </p>
        <img
          v-if="patient.HastaKabulReturn"
          :src="success_green"
          alt="green_success"
          class="ml-2"
        />
        <p
          v-if="!patient.HastaKabulReturn"
          class="text-red-600 mt-1 w-full text-xl"
        >
          <span class="text-[#333333]">Sonuç Mesajı:</span>
          {{ patient }}
        </p>
      </div>
    </div>
    <div class="flex flex-row justify-center my-auto gap-5 py-2 px-4">
      <button
        @click="printBarcode"
        class="w-[112px] h-[50px] bg-[#E3E3E3] border-[1px] border-[#00000080] rounded-[25px]"
      >
        Yazdır
      </button>

      <button
        class="py-2 px-4 bg-[#277DD2] text-white rounded-[25px]"
        @click="closePopUp()"
      >
        Tamam
      </button>
      <button
        v-if="!patient.HastaKabulReturn && checkPattern(patient)"
        class="py-2 px-4 bg-[#277DD2] text-white rounded-[25px]"
        @click="bagliTakipAl()"
      >
        Bağlı takip al
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { globalStore } from "@/store/modules/globalStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import router from "@/router/index.ts";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore.ts";
import { provisionBarcodeActions } from "@/store/modules/operationsStore/application/provision/applicationProvisionBarcodeActions";
import {
  AddPatientApplicationRequest,
  addPatientApplicationRequest,
} from "@/types/operations/patientApplications/addPatientApplicationRequest";
import { kurumTypes } from "@/modules/medula/provision/components/dropDownTypes";

import success_green from "../assets/success_green.svg";
const applicationProvisionBarcodeStore = provisionBarcodeActions();
const medulaStore = useMedula();

onMounted(() => {
  console.log(patient);
});

let patient = useApplicationProvisionStore().applicationProvisionResponse;
const imageRef = ref();
const closePopUp = () => {
  globalStore.isMedulaCardSuccess = false;
  // medulaStore.medulaSearch = "";
  medulaStore.bagliTakip = false;
};

function checkPattern(text) {
  const pattern = /\[(.*?)\]/;
  const match = text.match(pattern);
  return match ? match[0] : null;
}
const bagliTakipAl = () => {
  globalStore.isMedulaCardSuccess = false;
  // medulaStore.bagliTakip = true;
  // getPatientApplicationByTakipNoStore.getPatientApplicationByTakipNo(
  //   patient.HastaKabulReturn.TakipNo
  // );
  // medulaStore.bagliTakipEdilecekTakipNo
  // try provision with those two
};
function printBarcode() {
  printZpl(applicationProvisionBarcodeStore.getApplicationProvisionBarcode());
}
function printZpl(zpl) {
  var canvas = document.createElement("div");
  canvas.style.width = "500px";
  canvas.style.height = "500px";
  canvas.style.background = "red";

  console.log(zpl);
  var printWindow = window.open();
  printWindow.document.open("image/png");
  printWindow.document.document.appendChild(canvas);
  var barcodeImg = new SimpleImage(zpl);
  barcodeImg.drawTo(canvas);

  // printWindow.document.write(zpl);
  // printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.close();
}
</script>
