<template>
  <div class="ml-[10px] flex h-[27px]">
    <div v-if="store.studentAssistantFilterFlow===null&&store.studentAssistantFilter === 3">
      <div
        class="absolute top-11 right-10 h-[300px] w-[340px] rounded-[10px] border-2 border-[#D9D9D9] bg-white"
      >
        <p class="ml-[18px] mt-[17px] text-[#999999]">Bir alan seç</p>
        <div class="mx-5 w-[300px] border-t-[1px]"></div>

        <button
          @click="toggleFilterFileRef(1)"
          class="ml-[18px] mt-[22px] flex"
        >
          <p class="ml-[12px] font-light">Hasta Dosya No</p>
        </button>
        <button
          @click="toggleFilterFileRef(2)"
          class="ml-[18px] mt-[22px] flex"
        >
          <p class="ml-[12px] font-light">Dönem</p>
        </button>
        <button
          @click="toggleFilterFileRef(3)"
          class="ml-[18px] mt-[22px] flex"
        >
          <p class="ml-[12px] font-light">Grup</p>
        </button>
        <button
          @click="toggleFilterFileRef(4)"
          class="ml-[18px] mt-[22px] flex"
        >
          <p class="ml-[12px] font-light">Doktor Endikasyon</p>
        </button>
        <button
          @click="toggleFilterFileRef(5)"
          class="ml-[18px] mt-[22px] flex"
        >
          <p class="ml-[12px] font-light">Öğrenci Numarası</p>
        </button>
        <button
          @click="toggleFilterFileRef(6)"
          class="ml-[18px] mt-[22px] flex"
        >
          <p class="ml-[12px] font-light">Öğrenci Ad/Soyad</p>
        </button>
      </div>
    </div>
  </div>
</template>
    
    
    <script setup>
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();

const toggleFilterFileRef = (num) => {
  store.studentAssistantFilterFlow = num;
};
</script>