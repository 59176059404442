<template>
  <div class="flex items-center justify-between mb-2 text-[#40464C]">
    <button
      class="flex gap-3 items-center font-medium"
      @click="
        router.push('/polyclinic/anamneses');
        anamnesisStore.anamnesesUnitScreenUnitDatas = null;
      "
    >
      <img :src="leftArrow" alt="" />Geri
    </button>
    <h2 class="text-center text-xl font-medium uppercase">
      {{ anamnesisStore.anamnesesUnitScreenUnitDatas?.birim_adi }}
    </h2>
    <button
      @click="deleteUnitsAllAnamneses"
      class="bg-[#F94144] text-white py-2 px-4 rounded-3xl"
    >
      Tüm anamnezleri sil
    </button>
  </div>

  <div class="border rounded-t mx-auto flex text-[#40464C] -translate-x-14">
    <button
      @click="anamnesisStore.anamnesesNavbar = 1"
      class="w-[200px] py-2 font-medium rounded-t"
      :class="
        anamnesisStore.anamnesesNavbar === 1
          ? 'bg-[#277dd2] text-white'
          : 'bg-white text-[#a0a3a6]'
      "
    >
      Anamnez Listesi
    </button>
    <button
      @click="anamnesisStore.anamnesesNavbar = 2"
      class="w-[200px] py-2 font-medium rounded-t"
      :class="
        anamnesisStore.anamnesesNavbar === 2
          ? 'bg-[#277dd2] text-white'
          : 'bg-white text-[#a0a3a6]'
      "
    >
      Favori Anamnezler
    </button>
  </div>
</template>

<script setup>
import leftArrow from "@/modules/personel/assets/leftArrow.svg";
import { useRouter } from "vue-router";

const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;

import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();

const deleteUnitsAllAnamneses = async () => {
  await anamnesisTemplateStore.deleteUnitsAllAnamneses(unitID);
};
</script>
