import { defineStore } from "pinia";
import { addPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/addPatientApplicationStore.ts";
import { useGetProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/getProvisionSettings";
import { useGetClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/getClinicalSettings";

export const useMedula = defineStore("medulaStore", {
  id: "medulaStore",
  state: () => ({
    // Medula Store
    filteredDoctors: null,
    searchPatientSearchBarFocus: false,
    searchPatientButtonFocus: false,
    searchPatientInput: "",
    searchResultsSelectedIndex: 0,
    medulaSearchPatientListArray: [],
    selectedPatientID: "",
    selectedPatient: {},
    appointmentsApplication: "",
    appointmentsApplicationDatas: null,
    sideAppointmentInformations: null,

    //----------------------------
    medulaProvizyon: {
      ProvizyonTipi: "",
      KayitTarihi: "",
      TakipNo: "",
      TakipTipi: "",
      TedaviTipi: "",
      TedaviTuru: "",
      UnitAdı: "",
      BirimKodu: "",
      BirimAdı: "",
      DoktorAdı: "",
      OncelikSirasi: "",
      GuvenceDurumu: "",
      SigortaliTuru: "",
      BransKodu: "",
      BransAdı: "",
    },
    medulaKlinik: ["", "", "Endodonti", "Suna GEZER", "5550", ""],

    medulaSearch: "",

    // Medula button
    medulaProvisionModule: false,
    activitiesNavigation: 1,
    medulaNavbarButtons: 0,
    medulaKabulSearchShowInfoOnClick: false,
    medulaSearchFilterButton: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    medulaPatientHastaKodu: "",
    // Medula Randevu Bilgileri/Hasta Başvuruları/Geçmiş İşlemler

    hastaBasvurulari: {
      applications: {
        data: [],
      },
      count: 0,
    },
    randevuBilgileri: {
      appointments: {
        data: [],
      },
      count: 0,
    },
    appointmentStartTime: "",
    appointmentEndTime: "",
    appointmentArray: ["", ""],
    applicationDateRange: ["", ""],
    hastaHeader: {},
    updatingHasta: {
      Hastatelefon: {
        String: "",
        Valid: true,
      },
      EvTelefonu: {
        String: "",
        Valid: true,
      },

      DogumYeri: {
        String: "",
        Valid: true,
      },
      IlKodu: {
        String: "",
        Valid: false,
      },
      IlceKodu: {
        String: "",
        Valid: false,
      },
      DogumYeriIlKodu: {
        String: "",
        Valid: false,
      },
      DogumYeriIlceKodu: {
        String: "",
        Valid: false,
      },
      Hastaadres: {
        String: "",
        Valid: true,
      },
      Email: {
        String: "",
        Valid: true,
      },
      MedeniHali: {
        String: "",
        Valid: true,
      },
      DogumTarihi: {
        Time: "",
        Valid: true,
      },
      HastaKodu: "",
    },
    medulaProvizyonButtonVModel: {
      // provizyonTipi: { id: "N", text: "re" },
      provizyonTipi: "",
      istisnaiHal: "",
      takipTipi: "",
      tedaviTuru: "",
      tedaviTipi: "",
      vakaIl: "",
      vakaIlce: "",
      vakaTarih: "",
      plakaNo: "",
      branchNameText: "",
      branchCode: "",
    },

    bagliTakip: false,
    bagliTakipEdilecekBasvuruKodu: "",
    bagliTakipEdilecekTakipNo: "",
    bagliTakipAliniyor: false,
    editleniyor: false,
    ilceler: [],
    ilcelerSidePanel: [],
    randevuProvizyonBasvuruNo: "",
    randevuProvizyonHastaTc: "",
    alinamamisBasvuruNo: "",
    alinamamisBasvuruHastaTc: "",
  }),
  actions: {
    resetDropdowns() {
      const provisionSettingTypes = [
        "provizyon_tipi",
        "istisnai_hal",
        "tedavi_turu",
        "takip_tipi",
        "tedavi_tipi",
      ];

      provisionSettingTypes.forEach((type) => {
        const provisionSettingResponse =
          useGetProvisionSettings().getProvisionSettingsResponse;

        if (
          provisionSettingResponse &&
          (provisionSettingResponse[type] === "" ||
            provisionSettingResponse[type] === "-")
        ) {
          switch (type) {
            case "provizyon_tipi":
              useMedula().medulaProvizyonButtonVModel.provizyonTipi = "";
              addPatientApplicationStore().addPatientApplicationRequest.provision_type =
                "";
              break;
            case "istisnai_hal":
              useMedula().medulaProvizyonButtonVModel.istisnaiHal = "";

              break;
            case "tedavi_turu":
              useMedula().medulaProvizyonButtonVModel.tedaviTuru = "";
              addPatientApplicationStore().addPatientApplicationRequest.treatment_type =
                "";
              addPatientApplicationStore().addPatientApplicationRequest.tedavi_turu_medula =
                "";
              break;
            case "takip_tipi":
              useMedula().medulaProvizyonButtonVModel.takipTipi = "";
              addPatientApplicationStore().addPatientApplicationRequest.follow_type =
                "";
              break;
            case "tedavi_tipi":
              useMedula().medulaProvizyonButtonVModel.tedaviTipi = "";
              addPatientApplicationStore().addPatientApplicationRequest.tedavi_tipi =
                "";
              break;
            default:
              break;
          }
        }
      });

      const clinicalSettingTypes = ["klinik_adi", "doktor_adi"];

      clinicalSettingTypes.forEach((type) => {
        const clinicalSettingResponse =
          useGetClinicalSettings().getClinicalSettingsResponse;

        if (
          clinicalSettingResponse &&
          (clinicalSettingResponse[type] === "" ||
            clinicalSettingResponse[type] === "-")
        ) {
          switch (type) {
            case "klinik_adi":
              useMedula().medulaProvizyon.BirimAdı = "";
              useMedula().medulaProvizyonButtonVModel.branchNameText = "";
              useMedula().medulaProvizyonButtonVModel.branchCode = "";

              addPatientApplicationStore().addPatientApplicationRequest.unit_code =
                "";
              addPatientApplicationStore().addPatientApplicationRequest.branch_code =
                "";
              break;
            case "doktor_adi":
              useMedula().medulaProvizyon.DoktorAdı = "";
              addPatientApplicationStore().addPatientApplicationRequest.doctor_code =
                "";
              break;
            default:
              break;
          }
        }
      });
    },
  },
});
