import { createRouter, createWebHistory } from "vue-router";
import protezHomeView from "@/modules/protez/view/protezHomeView.vue";
import protezLabView from "@/modules/protez/view/protezLabView.vue";
import protezMaterialsView from "@/modules/protez/view/protezMaterialsView.vue";
import protezOrdersView from "@/modules/protez/view/protezOrdersView.vue";
import protezTechniciansView from "@/modules/protez/view/protezTechniciansView.vue";
import protezWorkOrdersView from "@/modules/protez/view/protezWorkOrdersView.vue";
import protezCreateOrdersView from "@/modules/protez/view/protezCreateOrdersView.vue";
import protezCreatedOrdersView from "@/modules/protez/view/protezCreatedOrdersView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/protez",
    name: "protezHomeView",
    component: protezHomeView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/protezLab",
    name: "protezLabView",
    component: protezLabView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/protez/materials",
    name: "protezMaterialsView",
    component: protezMaterialsView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/protez/orders",
    name: "protezOrdersView",
    component: protezOrdersView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/protez/technicians",
    name: "protezTechniciansView",
    component: protezTechniciansView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/protez/workorders",
    name: "protezWorkOrdersView",
    component: protezWorkOrdersView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/protez/createOrders",
    name: "protezCreateOrdersView",
    component: protezCreateOrdersView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/protez/createdOrders",
    name: "protezCreatedOrdersView",
    component: protezCreatedOrdersView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
