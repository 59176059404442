<template>
  <div
    class="flex bg-white items-center justify-between text-[#40464C] shadow border px-7 py-2"
  >
    <div class="flex items-end gap-3">
      <img :src="profileCircle" alt="profile photo" class="w-[56px] h-[56px]" />
      <div v-if="doctorInfo.doctorInfos">
        <p>
          {{ formatString(doctorInfo.doctorInfos.name) }}&nbsp;{{
            formatString(doctorInfo.doctorInfos.surname)
          }}
        </p>
        <p class="text-[#a0a3a6]">
          {{ formatString(doctorInfo.doctorInfos.unit_name) }}
        </p>
      </div>
    </div>
    <div class="mr-16">
      <p class>Tarih & Saat</p>
      <p class="font-medium">{{ getTodaysDate() }}</p>
    </div>
  </div>
</template>

<script setup>
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import { ref, onMounted } from "vue";
import { doctorInfo } from "@/modules/doctorSlots/components/doctorInfoReactiveTypes";

const getTodaysDate = () => {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay indeksi 0'dan başlar
  const year = date.getFullYear();

  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = `${day}.${month}.${year} (${hour}:${minute})`;
  return formattedDate;
};

const formatString = (str) => {
  if (!str) {
    return null;
  }
  return str
    .toLocaleLowerCase("tr-TR")
    .split(" ")
    .map((word) => word.charAt(0).toLocaleUpperCase("tr-TR") + word.slice(1))
    .join(" ");
};
</script>
