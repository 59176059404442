<template>
  <div class="flex gap-24 mb-20 text-[#2e4053]">
    <div class="cursor-pointer" @click="announcementsStore.navbarTitle = 0">
      <button>Tümü</button>
      <div
        v-if="announcementsStore.navbarTitle === 0"
        class="w-full h-[1px] bg-[#2E4053] mx-auto"
      ></div>
    </div>
    <div class="cursor-pointer" @click="announcementsStore.navbarTitle = 1">
      <button>Haberler</button>
      <div
        v-if="announcementsStore.navbarTitle === 1"
        class="w-full h-[1px] bg-[#2E4053] mx-auto"
      ></div>
    </div>
    <div class="cursor-pointer" @click="announcementsStore.navbarTitle = 2">
      <button>Duyurular</button>
      <div
        v-if="announcementsStore.navbarTitle === 2"
        class="w-full h-[1px] bg-[#2E4053] mx-auto"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { useAnnouncements } from "@/mainPage/store/announcementStore";
const announcementsStore = useAnnouncements();
</script>
