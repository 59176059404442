import { defineStore } from "pinia";
import {
  AddPatientApplicationRequest,
  addPatientApplicationRequest,
} from "@/types/operations/patientApplications/addPatientApplicationRequest";
import { AddPatientApplicationResponse } from "@/types/operations/patientApplications/addPatientApplicationResponse";
import axios from "axios";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";

import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "../../globalStore";
import { router } from "@/router/index";

export const addPatientApplicationStore = defineStore({
  id: "addPatientApplicationStore",
  state: () => ({
    addPatientApplicationRequest:
      addPatientApplicationRequest as AddPatientApplicationRequest | null,
    addPatientApplicationResponse: null as AddPatientApplicationResponse | null,
  }),
  actions: {
    async addPatientApplication(
      addPatientApplicationRequest: AddPatientApplicationRequest
    ) {
      const personelStoreData = personelStore();
      const applicationProvisionStore = useApplicationProvisionStore();
      const provisionState =
        applicationProvisionStore.applicationProvisionRequest;
      this.addPatientApplicationRequest = addPatientApplicationRequest;
      globalStore.customInfoCard = true;
      globalStore.customInfoType = "pending";
      await axios
        .post(
          `${clinicDomain}/patient-applications/create`,
          {
            ...addPatientApplicationRequest,
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.addPatientApplicationResponse = response.data;
            console.log(addPatientApplicationRequest, "bunuyolluyoruz");
            console.log(response.data, "incele");
            provisionState.application_id = response.data.data.id;
          } else {
            console.log(
              "addPatientApplicationStore.addPatientApplication axios not return 200 error. Response : ",
              response
            );
          }
        })
        .catch((err) => {
          console.log(addPatientApplicationRequest, "bunuyolluyoruz");
          setTimeout(() => {
            globalStore.customInfoCard = false;
            globalStore.customInfoType = "";
          }, 2000);
          console.log(err);
          globalStore.customInfoType = "fail";
        });
    },
  },
});
