import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";

export const useGetCountries = defineStore({
  id: "useGetCountries",
  state: () => ({
    getListCountriesResponse: null,
  }),
  actions: {
    async getCountries() {
      const personelStoreData = personelStore();

      await axios
        .get(`${adminDomain}/reference-codes/ulke_kodlari`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("ulkeler", response.data.data);
          this.getListCountriesResponse = response.data.data.map(
            (item, index) => {
              return {
                ...item,
                id: item.attributes.reference_name,
                text: _.startCase(_.toLower(item.attributes.reference_name)),
              };
            }
          );
        })
        .catch((error) => {
          console.log("Search ", error);
        });
    },
  },
});
