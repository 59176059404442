<template>
  <div
    class="ml-auto flex shadow gap-6 border w-[447px] bg-white pl-[20px] rounded-full text-black"
  >
    <img :src="searchLogo" alt="search" />
    <div class="w-10/12">
      <input
        type="text"
        class="w-full outline-none py-2"
        placeholder="Hasta ara"
        v-model="medulaStore.searchPatientInput"
        @input="handleInputChange"
        @keydown="handleKeyDown"
      />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
//asset imports
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";

//store imports
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { UseSearchPatientStore } from "@/store/modules/operationsStore/patient/useSearchPatientStore";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
//store inits
const medulaStore = useMedula();
const searchPatientStore = UseSearchPatientStore();
const getPatientStore = useGetPatientStore();
const bilesikKisiStore = bilesikKisiActions();

const handleInputChange = async () => {
  medulaStore.searchPatientInput = medulaStore.searchPatientInput.replace(
    /[^0-9]/g,
    ""
  );
  medulaStore.selectedPatientID = null;
  medulaStore.selectedPatient = {};
  medulaStore.appointmentsApplication = null;
  if (
    medulaStore.searchPatientInput.length < 5 ||
    medulaStore.searchPatientInput.length > 11
  ) {
    return false;
  }
  medulaStore.medulaSearchPatientListArray =
    await searchPatientStore.searchPatient(medulaStore.searchPatientInput);
  console.log(medulaStore.medulaSearchPatientListArray);
};

const handleKeyDown = async (e) => {
  if (e.key === "ArrowUp" && medulaStore.searchResultsSelectedIndex > 0) {
    e.preventDefault();
    medulaStore.searchResultsSelectedIndex--;
  } else if (
    e.key === "ArrowDown" &&
    medulaStore.searchResultsSelectedIndex <
      medulaStore.medulaSearchPatientListArray.length - 1
  ) {
    medulaStore.searchResultsSelectedIndex++;
  } else if (e.key === "Enter") {
    medulaStore.selectedPatient = await getPatientStore.getPatientA(
      medulaStore.medulaSearchPatientListArray[
        medulaStore.searchResultsSelectedIndex
      ].id
    );
    medulaStore.selectedPatientID =
      medulaStore.medulaSearchPatientListArray[
        medulaStore.searchResultsSelectedIndex
      ].id;
    medulaStore.searchPatientInput =
      medulaStore.medulaSearchPatientListArray[
        medulaStore.searchResultsSelectedIndex
      ].attributes.tc_identity_number;

    e.target.blur();
    console.log("kps");
  } else if (e.key >= "0" && e.key <= "9") {
    medulaStore.searchResultsSelectedIndex = 0;
  }
};
</script>
