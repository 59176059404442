<template>
  <div class="flex flex-col min-w-[650px] w-full h-full ml-[44px] mt-[15px]">
    <div
      class="border-x-[1px] border-t-[1px] border-[#D5DBE1] rounded-[5px] w-[110px] h-[30px] flex items-center justify-center"
    >
      <p class="text-[#2C3D8F] font-medium">Cihaz Tanım</p>
    </div>
    <div class="flex flex-col h-full border-[#D5DBE1] w-full">
      <div class="w-full border-b-[#D5DBE1] border-[1px]"></div>
      <div class="flex items-center mt-[58px]">
        <p class="text-[#454545] w-[180px]">Adı</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        >
          <p class="text-[#454545] font-medium ml-[14px]">Ultrason #1</p>
        </div>
        <!-- Aktif button -->
        <div
          class="border-[#16A34A] rounded-[5px] border-[1px] bg-[#16A34A1A] flex items-center justify-center w-[75px] h-[23px] ml-[85px] -mt-[20px]"
        >
          <div class="w-3 h-3 rounded-full bg-[#16A34A]"></div>
          <p class="text-[#16A34A] ml-2">Aktif</p>
        </div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Birim</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        >
          <p class="text-[#454545] font-medium ml-[14px]">Ultrason 5. Oda</p>
        </div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Cihaz Türü</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        >
          <p class="text-[#454545] font-medium ml-[14px]">CD</p>
        </div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Cihaz Tipi</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        >
          <p class="text-[#454545] font-medium ml-[14px]">Sabit</p>
        </div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">AE Title</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Ip</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Port</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
    </div>
  </div>
</template>