export const oneCikanSutKodlari = [
  {
    sutKodu: "401010",
    adi: "Diş Hekimi Muayenesi (Diş Tabibi)",
    fiyat: 63.94,
    turu: 1,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404181",
    adi: "Venner Kron  (Seramik)",
    fiyat: 809.8,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402020",
    adi: "Amalgam Dolgu (İki Yüzlü)",
    fiyat: 255.9,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402150",
    adi: "Kanal Tedavisi- Tek Kanal (Üst Dolgu Hariç)",
    fiyat: 483.38,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403090",
    adi: "Kompomer Dolgu (Diş Başına)",
    fiyat: 246.43,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
];

export const sutKodlari = [
  {
    sutKodu: "401010",
    adi: "Diş Hekimi Muayenesi (Diş Tabibi)",
    fiyat: 63.94,
    turu: 1,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401030",
    adi: "Konsültan Dişhekimi Muayenesi",
    fiyat: 36.54,
    turu: 4,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401060",
    adi: "Oklüzal Film (Adedi)",
    fiyat: 28.39,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401070",
    adi: "Ekstra-Oral Röntgen Filmi (Adedi)",
    fiyat: 61.6,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401080",
    adi: "Ortopantomograf Film (Panoromik)",
    fiyat: 118.45,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401090",
    adi: "Sefalometrik Film (Adedi)",
    fiyat: 85.3,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401100",
    adi: "Antero-Posterior Sefalometrik Film (Adedi)",
    fiyat: 75.82,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401110",
    adi: "El-Bilek Filmi (Adedi)",
    fiyat: 56.83,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401120",
    adi: "TME Röntgen Filmi (Adedi ve Tetkiki)",
    fiyat: 85.3,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401130",
    adi: "Sialografi, (Tek Taraf)",
    fiyat: 145.01,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "401150",
    adi: "Bite-Wing Film (Adedi)",
    fiyat: 28.39,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402010",
    adi: "Amalgam Dolgu (Bir Yüzlü)",
    fiyat: 208.53,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402020",
    adi: "Amalgam Dolgu (İki Yüzlü)",
    fiyat: 255.9,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402030",
    adi: "Amalgam Dolgu (Üç Yüzlü)",
    fiyat: 274.85,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402040",
    adi: "İnley Dolgu-Oklüzal",
    fiyat: 75.82,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402050",
    adi: "İnley Dolgu (İki Yüzlü)",
    fiyat: 75.82,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402060",
    adi: "İnley Dolgu (Üç Yüzlü)",
    fiyat: 94.77,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402070",
    adi: "Öndiş Kompozit Dolgu",
    fiyat: 227.46,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402080",
    adi: "Arkadiş Kompozit Dolgu",
    fiyat: 227.46,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402090",
    adi: "Öndiş Işınlı Kompozit Dolgu",
    fiyat: 265.36,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402100",
    adi: "Arkadiş Işınlı Kompozit Dolgu (Bir Yüzlü)",
    fiyat: 265.36,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402110",
    adi: "Arkadiş Işınlı Kompozit Dolgu (İki Yüzlü)",
    fiyat: 284.31,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402120",
    adi: "Arkadiş Işınlı Kompozit Dolgu (Üç Yüzlü)",
    fiyat: 303.29,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402130",
    adi: "Kuafaj (Dolgu Hariç)",
    fiyat: 28.39,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402140",
    adi: "Amputasyon (Dolgu Hariç)",
    fiyat: 142.15,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402150",
    adi: "Kanal Tedavisi- Tek Kanal (Üst Dolgu Hariç)",
    fiyat: 483.38,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402160",
    adi: "Black 5 (Kole) Dolgusu (Amalgam)",
    fiyat: 104.24,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402170",
    adi: "Black 5 (Kole) Dolgusu (Cam İyonomer)",
    fiyat: 104.24,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402180",
    adi: "Black 5 (Kole) Dolgusu (Kompozit)",
    fiyat: 104.24,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402190",
    adi: "Cam İonomer Dolgu",
    fiyat: 199.01,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402200",
    adi: "Dentin Pinli Restorasyonu (Pin Başına) (Dolgu Hariç)",
    fiyat: 66.29,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402240",
    adi: "Kanal İçi Post Uygulaması (Diş Başına)(Dolgu Hariç)",
    fiyat: 66.29,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402270",
    adi: "Gangren veya Periapikal Lezyon Tedavisi (Her Kanal İçin)",
    fiyat: 118.45,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402300",
    adi: "Ekstirpasyon (Her Diş İçin)",
    fiyat: 104.24,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402320",
    adi: "Onley (Laboratuar Ücreti Hariç)",
    fiyat: 123.21,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "402340",
    adi: "Kompozit Venner",
    fiyat: 293.8,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403010",
    adi: "Fissur Örtülmesi (Sealant) (Tek Diş)",
    fiyat: 66.29,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403020",
    adi: "Yerel Flor Uygulaması (Çürük Proflaksisi) Yarım Çene",
    fiyat: 66.29,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403030",
    adi: "Prefabrike Kron (Malzeme Ücreti Hariç)",
    fiyat: 217.99,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403040",
    adi: "Yer Tutucu (Sabit)(Teknisyen Ücreti Hariç)",
    fiyat: 189.54,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403050",
    adi: "Yer Tutucu (Hareketli)(Teknisyen Ücreti Hariç)",
    fiyat: 189.54,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403060",
    adi: "Çocuk Protezi (Akrilik, Bölümlü) (Tek Çene)(Tek. Ücreti Hariç)",
    fiyat: 331.7,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403070",
    adi: "Çocuk Protezi (Tam) (Tek Çene)(Tek. Ücreti Hariç)",
    fiyat: 331.7,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403080",
    adi: "Strip Kron",
    fiyat: 113.71,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403090",
    adi: "Kompomer Dolgu (Diş Başına)",
    fiyat: 246.43,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403100",
    adi: "Açık Apeksli Dişte Kanal Tedavisi (Her Kanal İçin)",
    fiyat: 284.31,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "403110",
    adi: "Bilinçli Sedasyon",
    fiyat: 170.59,
    turu: 9,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404010",
    adi: "Tam Protez (Akrilik) (Tek Çene)",
    fiyat: 1421.75,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404020",
    adi: "Bölümlü Protez (Akrilik, Tek Çene)",
    fiyat: 1421.75,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404030",
    adi: "Tam Protez (Metal Kaide) (Tek Çene)",
    fiyat: 1834.08,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404040",
    adi: "Bölümlü Protez (Metal Kaide, Tek Çene)",
    fiyat: 1834.08,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404050",
    adi: "Immediat Protez (Akrilik) (Tek Çene)",
    fiyat: 426.52,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404060",
    adi: "Rebazaj (Kaide Yenileme-Tek Çene)",
    fiyat: 246.43,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404070",
    adi: "Proteze Yumuşak Akrilik Uygulaması",
    fiyat: 199.01,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404080",
    adi: "Besleme (Tek Çene)",
    fiyat: 170.59,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404090",
    adi: "Tamir (Akrilik Protezde Kırık veya Çatlak)",
    fiyat: 85.3,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404100",
    adi: "Kroşe İlavesi",
    fiyat: 85.3,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404110",
    adi: "Metal İskelet Tamiri",
    fiyat: 113.71,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404120",
    adi: "Diş İlavesi (Tek Diş)",
    fiyat: 85.3,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404130",
    adi: "Gnatoloji, TME-Kas Muayenesi (Oklüzyon Kontrolü)",
    fiyat: 113.71,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404140",
    adi: "Oklüzal Aşındırmalar (Tek Çene)",
    fiyat: 113.71,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404150",
    adi: "Gece Plağı (Bruksizm İçin)",
    fiyat: 199.01,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404160",
    adi: "Pinley ve Çeşitleri",
    fiyat: 127.92,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404170",
    adi: "Tek Parça Döküm Kron",
    fiyat: 620.25,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404180",
    adi: "Venner Kron  (Akrilik)",
    fiyat: 620.25,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404181",
    adi: "Venner Kron  (Seramik)",
    fiyat: 809.8,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
  {
    sutKodu: "404190",
    adi: "Pivo (Çivil Kron) veya Post-Core",
    fiyat: 379.12,
    turu: 3,
    gecerlilikTarihi: "02.09.2023",
    listeAdi: "EK-2Ç",
  },
];
