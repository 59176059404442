<template>
  <div class="flex flex-col w-full mt-[40px] ml-[46px]">
    <div class="flex">
      <button
        @click="routerToOdemeIslemleri()"
        class="flex flex-col min-w-[343px] min-h-[167px] rounded-[20px] bg-white border-[1px] border-[#D9D9D9]"
      >
        <img :src="worldPink" alt="world" class="mt-[24px] ml-[29px]" />
        <p class="mt-[24px] ml-[29px] text-[#252525] font-medium text-xl">
          Ek Ödeme İşlemleri
        </p>
        <img :src="arrowRight" alt="arrow" class="ml-auto mr-6" />
      </button>
      <button
        class="flex flex-col min-w-[343px] min-h-[167px] rounded-[20px] bg-white border-[1px] border-[#D9D9D9] ml-[52px]"
      >
        <img :src="worldOrange" alt="world" class="mt-[24px] ml-[29px]" />
        <p class="mt-[24px] ml-[29px] text-[#252525] font-medium text-xl">
          Maaş Bordro İşlemleri
        </p>
        <img :src="arrowRight" alt="arrow" class="ml-auto mr-6" />
      </button>
    </div>
    <div class="flex mt-[36px] items-center">
      <button
        class="flex flex-col min-w-[343px] min-h-[167px] rounded-[20px] bg-white border-[1px] border-[#D9D9D9]"
      >
        <p class="text-[#526581] mt-[24px] ml-[29px] font-medium">
          2023 Yılına Ait Toplam Tutar
        </p>
        <p class="mt-[24px] ml-[29px] text-[#252525] font-medium text-3xl">
          1.500.000 TL
        </p>
        <div
          class="flex items-center h-[45px] mt-auto w-full bg-[#5265810D] rounded-b-[20px]"
        >
          <img :src="arrowRightUpGreen" class="w-3 ml-[24px]" alt="yuzde" />
          <p class="text-[#04842D] font-medium ml-2">2,15 %</p>
        </div>
      </button>
      <div
        class="flex items-center min-w-[801px] min-h-[94px] bg-white border-[1px] border-[#52658133] ml-auto mr-[100px]"
      >
        <div class="flex flex-col ml-[27px]">
          <p>Yıllık Kesinti Tutarı</p>
          <div class="flex">
            <img :src="linePercentegeBlue" alt="" />
            <p class="text-[#252525] font-medium ml-2">%42</p>
          </div>
          <div class="flex">
            <div class="flex rounded-[5px] px-1 items-center bg-[#04842D33]">
              <img :src="arrowRightUpGreen" class="h-2" alt="yuzde" />
              <p class="text-[#04842D] font-medium ml-1">2,15 %</p>
            </div>
            <p class="text-[#526581] font-medium ml-1">20.000 TL</p>
          </div>
        </div>
        <div class="flex flex-col ml-[52px]">
          <p>Yıllık Ek Ödeme Miktarı</p>
          <div class="flex">
            <img :src="linePercentegeBlue" class="w-[163px]" alt="" />
            <p class="text-[#252525] font-medium ml-2">%65</p>
          </div>
          <div class="flex">
            <div class="flex rounded-[5px] px-1 items-center bg-[#AC000033]">
              <img :src="arrowDownLeftRed" class="h-2" alt="yuzde" />
              <p class="text-[#AC0000] font-medium ml-1">1,11 %</p>
            </div>
            <p class="text-[#526581] font-medium ml-1">50.000 TL</p>
          </div>
        </div>
        <div class="flex flex-col ml-[52px]">
          <p>Yıllık Performans Ek Ödeme Miktarı</p>
          <div class="flex">
            <img :src="linePercentegeBlue" class="w-[251px]" alt="" />
            <p class="text-[#252525] font-medium ml-2">%85</p>
          </div>
          <div class="flex">
            <div class="flex rounded-[5px] px-1 items-center bg-[#04842D33]">
              <img :src="arrowRightUpGreen" class="h-2" alt="yuzde" />
              <p class="text-[#04842D] font-medium ml-1">4,15 %</p>
            </div>
            <p class="text-[#526581] font-medium ml-1">50.000 TL</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import worldPink from "@/modules/ekOdeme/assets/worldPink.svg";
import worldOrange from "@/modules/ekOdeme/assets/worldOrange.svg";
import arrowRight from "@/modules/ekOdeme/assets/arrowRight.svg";
import arrowRightUpGreen from "@/modules/ekOdeme/assets/arrowRightUpGreen.svg";
import arrowDownLeftRed from "@/modules/ekOdeme/assets/arrowDownLeftRed.svg";
import linePercentegeBlue from "@/modules/ekOdeme/assets/linePercentegeBlue.svg";
import { useRouter } from "vue-router";
import { ref } from "vue";

const router = useRouter();
const yillikKesintiTutariRef = ref(42);

const routerToOdemeIslemleri = () => {
  console.log("routerToOdemeIslemleri");
  router.push("/ek-odeme/islemleri");
};
</script>