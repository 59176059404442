<template>
  <div>
    <div class="bg-[#d9d9d9] p-3 rounded">
      <div class="flex">
        <div class="w-[390px] flex">
          <p class="w-[137px]">Anne Adı</p>
          <p class="font-medium">
            {{
              registeredPatientListStore.provisionsPatient?.attributes
                .mother_name
            }}
          </p>
        </div>
        <div class="w-[390px] flex">
          <p class="w-[137px]">Baba Adı</p>
          <p class="font-medium">
            {{
              registeredPatientListStore.provisionsPatient?.attributes
                .father_name
            }}
          </p>
        </div>
        <div class="flex">
          <p class="w-[137px]">Doğum Yeri</p>
          <p class="font-medium">
            {{
              registeredPatientListStore.provisionsPatient?.attributes
                .birthplace_province
            }}
          </p>
        </div>
      </div>
      <div class="flex mt-3">
        <div class="w-[390px] flex">
          <p class="w-[137px]">Medeni Hali</p>
          <p class="font-medium">
            {{
              registeredPatientListStore.provisionsPatient?.attributes
                .marital_status === "evli (resmen evli ya da birlikte yaşıyor)"
                ? "Evli"
                : registeredPatientListStore.provisionsPatient?.attributes
                    .marital_status === "bekar"
                ? "Bekar"
                : registeredPatientListStore.provisionsPatient?.attributes
                    .marital_status
            }}
          </p>
        </div>
        <div class="w-[390px] flex">
          <p class="w-[137px]">Sigortalı Türü</p>
          <p class="font-medium">
            {{ registeredPatientListStore.provisionsDatas.insuranceType }}
          </p>
        </div>
        <div class="flex">
          <p class="w-[137px]">S.G. Durumu</p>
          <p class="font-medium">
            {{ registeredPatientListStore.provisionsDatas.assuranceStatus }}
          </p>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <p class="font-medium">Adres</p>
      <div class="mt-3 flex">
        <!-- address city -->
        <div class="w-[400px] sidePanelSelect">
          <Select2
            :disabled="!registeredPatientListStore.editleniyor"
            class="w-[285px]"
            :options="getCitiesStore.getListCitiesResponse"
            placeholder="İl"
            v-model="
              registeredPatientListStore.provisionsUpdatingPatientDatas.province
            "
          />
        </div>

        <!-- address district -->
        <div class="w-[400px] sidePanelSelect">
          <Select2
            :disabled="!registeredPatientListStore.editleniyor"
            class="w-[285px]"
            :options="registeredPatientListStore.districts"
            placeholder="İlçe"
            v-model="
              registeredPatientListStore.provisionsUpdatingPatientDatas.district
            "
          />
        </div>

        <!-- address detail -->
        <div
          v-if="registeredPatientListStore.provisionsPatient"
          class="w-[285px] rounded items-center flex border p-[6px] border-[#D9D9D9]"
          :class="
            registeredPatientListStore.editleniyor ? 'bg-white' : 'bg-[#e6e6e6]'
          "
        >
          <input
            :disabled="!registeredPatientListStore.editleniyor"
            type="text"
            class="w-full h-full ml-2 focus:outline-none"
            placeholder="Adres Detay"
            v-model="
              registeredPatientListStore.provisionsUpdatingPatientDatas
                .addressDetail
            "
          />
        </div>
      </div>
      <div class="flex mt-4">
        <p class="font-medium w-[400px]">Cep Telefonu</p>
        <p class="font-medium w-[400px]">Ev Telefonu</p>
        <p class="font-medium">E-Mail</p>
      </div>
      <div class="my-3 flex">
        <!-- phone -->
        <div class="w-[400px]">
          <div
            v-if="registeredPatientListStore.provisionsPatient"
            class="w-[285px] rounded items-center flex border p-[6px] border-[#D9D9D9]"
            :class="
              registeredPatientListStore.editleniyor
                ? 'bg-white'
                : 'bg-[#e6e6e6]'
            "
          >
            <input
              :disabled="!registeredPatientListStore.editleniyor"
              type="text"
              class="w-full h-full ml-2 focus:outline-none"
              v-model="
                registeredPatientListStore.provisionsUpdatingPatientDatas
                  .phoneNumber
              "
            />
          </div>
        </div>

        <!-- housephone -->
        <div class="w-[400px]">
          <div
            v-if="registeredPatientListStore.provisionsPatient"
            class="w-[285px] rounded items-center flex border p-[6px] border-[#D9D9D9]"
            :class="
              registeredPatientListStore.editleniyor
                ? 'bg-white'
                : 'bg-[#e6e6e6]'
            "
          >
            <input
              :disabled="!registeredPatientListStore.editleniyor"
              type="text"
              class="w-full h-full ml-2 focus:outline-none"
              v-model="
                registeredPatientListStore.provisionsUpdatingPatientDatas
                  .homePhoneNumber
              "
            />
          </div>
        </div>

        <!-- email -->
        <div
          v-if="registeredPatientListStore.provisionsPatient"
          class="w-[285px] rounded items-center flex border p-[6px] border-[#D9D9D9]"
          :class="
            registeredPatientListStore.editleniyor ? 'bg-white' : 'bg-[#e6e6e6]'
          "
        >
          <input
            :disabled="!registeredPatientListStore.editleniyor"
            type="text"
            class="w-full h-full ml-2 focus:outline-none"
            v-model="
              registeredPatientListStore.provisionsUpdatingPatientDatas.email
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import _ from "lodash";

//store imports
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
import { useGetDistricts } from "@/store/modules/operationsStore/public/useGetDistricts";
import { useGetCities } from "@/store/modules/operationsStore/public/useGetCities";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { userInfo } from "@/modules/medula/addPatient/components/userInfoReactiveTypes";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore.ts";
import Select2 from "vue3-select2-component";

//store inits
const store = useMedula();
const registeredPatientListStore = useRegisteredPatientListStore();
const getCitiesStore = useGetCities();
const getDistrictsStore = useGetDistricts();
const getPatientStore = useGetPatientStore();

onMounted(async () => {
  if (
    !registeredPatientListStore.provisionsUpdatingPatientDatas.province ||
    registeredPatientListStore.districts
  ) {
    return false;
  }
  registeredPatientListStore.districts = await getDistrictsStore.getDistricts(
    registeredPatientListStore.provisionsUpdatingPatientDatas.province
  );
});

watch(
  () => registeredPatientListStore.editButton,
  (newValue) => {
    if (newValue) {
      registeredPatientListStore.editleniyor = true;
    } else {
      registeredPatientListStore.editleniyor = false;
    }
  }
);

watch(
  () => registeredPatientListStore.provisionsUpdatingPatientDatas.province,
  async (newValue, oldValue) => {
    registeredPatientListStore.districts = await getDistrictsStore.getDistricts(
      registeredPatientListStore.provisionsUpdatingPatientDatas.province
    );
    console.log("eski", registeredPatientListStore.provisionsPatient);
    console.log(
      "yeni",
      registeredPatientListStore.provisionsUpdatingPatientDatas
    );
  }
);
</script>
