import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";

export const useCancelVEMAppointment = defineStore({
  id: "cancelVEMAppointment",

  actions: {
    async cancelVemAppointment(randevuKodu: string, iptalAciklama: string) {
      globalStore.customInfoCard = true;
      globalStore.customInfoType = "pending";
      await axios
        .post(
          `${clinicDomain}/vem-appointments/cancel`,

          {
            RandevuKodu: randevuKodu,
            IptalEdenKullanici: "kullanici1",
            IptalAciklama: iptalAciklama,
          },

          {
            headers: {
              Authorization: patientAuthToken,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("cancelVemAppointment", response.data);

            setTimeout(() => {
              globalStore.customInfoCard = false;
              globalStore.customInfoType = "";
            }, 2000);

            globalStore.customInfoType = "success";
          }
        })
        .catch((error) => {
          console.error("Error cancelVemAppointment", error);
          setTimeout(() => {
            globalStore.customInfoCard = false;
            globalStore.customInfoType = "";
          }, 2000);

          globalStore.customInfoType = "fail";
          return false;
        });
    },
  },
});
