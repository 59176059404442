<template>
  <header
    class="bg-white py-3 px-5 flex items-center border-b-[0.5px] border-[#D9D9D9] w-full"
  >
    <button
      v-if="calendarNavbar"
      @click="router.push('/assistantScreen/registered-patient')"
      class="mr-[605px] text-[#09244B] font-medium flex gap-4 py-1 px-2 items-center"
    >
      <img :src="leftArrow" alt="" class="w-5" />
      <p class="text-lg">Geri</p>
    </button>

    <h1 class="headerText" :class="calendarNavbar && 'ml-24'">{{ title }}</h1>
    <div class="flex gap-5 ml-16" v-if="newPatientPage">
      <div
        :class="
          bilesikKisiStore.patientNationality === 'Mavi Kartlı Hasta' &&
          'bg-[#277DD2]'
        "
        class="px-[10px] py-[5px] mr-2 rounded-lg border-[1px] border-[#277DD2]"
      >
        <button
          @click="bilesikKisiStore.patientNationality = 'Mavi Kartlı Hasta'"
          class="h-full w-full"
        >
          <p
            :class="
              bilesikKisiStore.patientNationality === 'Mavi Kartlı Hasta' &&
              'text-white'
            "
            class="items-center font-normal text-[#277DD2]"
          >
            Mavi Kartlı Hasta
          </p>
        </button>
      </div>
      <div
        :class="
          bilesikKisiStore.patientNationality === 'Yabancı Uyruklu' &&
          'bg-[#277DD2]'
        "
        class="px-[10px] py-[5px] mr-4 rounded-lg border-[1px] border-[#277DD2]"
      >
        <button
          @click="bilesikKisiStore.patientNationality = 'Yabancı Uyruklu'"
          class="h-full w-full"
        >
          <p
            :class="
              bilesikKisiStore.patientNationality === 'Yabancı Uyruklu' &&
              'text-white'
            "
            class="items-center font-normal text-[#277DD2]"
          >
            Yabancı Uyruklu
          </p>
        </button>
      </div>
    </div>
    <div class="flex ml-auto">
      <button class="mr-[40px]">
        <img :src="menuIcon" alt="" @click="router.push('/mainPage')" />
      </button>
      <img :src="profileImg" alt="profile image" class="mr-2" />
      <button @click="arrowRef = !arrowRef" class="flex flex-col relative">
        <div class="flex gap-2 items-center">
          <p>
            {{ personelStoreData.personel.name }}
            {{ personelStoreData.personel.surname }}
          </p>
          <img
            :src="dropDownArrow"
            class="h-3 w-3 transform transition-transform duration-200 ease-in-out"
            :class="arrowRef ? 'rotate-180' : 'rotate-0'"
          />
        </div>
        <p class="text-[#00000080] text-xs">
          {{ personelStoreData.personel.role }}
        </p>
        <button
          @click="handleLogout"
          @blur="arrowRef = false"
          v-if="arrowRef"
          class="w-28 z-[999] cursor-pointer py-1 pl-2 pr-4 text-white bg-[#707070] rounded absolute right-0 -bottom-4"
        >
          <p>Çıkış Yap</p>
        </button>
      </button>
    </div>
  </header>
</template>

<script setup>
import menuIcon from "../assets/menu_icon.svg";
import profileImg from "../assets/profile-img.svg";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions.ts";
const personelStoreAuthActions = personelAuthActions();
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { personelStore } from "@/store/modules/administratorManagementStore/personel.ts";
import dropDownArrow from "@/modules/asistanEkrani/registeredPatientList/assets/dropDownArrow.svg";
import leftArrow from "@/modules/personel/assets/leftArrow.svg";
import { doctorInfo } from "@/modules/doctorSlots/components/doctorInfoReactiveTypes";
import { useGetDoctorInfoStore } from "@/store/modules/administratorManagementStore/staff/getDoctorInfo";
const getDoctorInfoStore = useGetDoctorInfoStore();

onMounted(async () => {
  if (doctorInfo.doctorInfos === null) {
    const session = JSON.parse(localStorage.getItem("personelStoreData"));
    doctorInfo.doctorInfos = await getDoctorInfoStore.getDoctorInfo(
      session.flowId
    );
  }
});

import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});
//define props
const props = defineProps({
  title: String,
  newPatientPage: Boolean,
  calendarNavbar: Boolean,
});

const router = useRouter();
const arrowRef = ref(false);

const personelStoreData = personelStore();

const handleLogout = () => {
  personelStoreAuthActions.logout();
};

//new patient logic

import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
const bilesikKisiStore = bilesikKisiActions();
</script>

<style scoped>
.headerText {
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

*:focus {
  outline: none;
}

*:focus-visible:not(*:active) {
  outline: 1px solid #277dd2;
}
</style>
