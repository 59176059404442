<template>
  <div
    :class="isMenuOpen ? 'min-w-[236px]' : 'min-w-[80px]'"
    class="h-screen bg-[#F5F5F5] rounded-[10px] flex flex-col"
  >
    <img
      @click="isMenuOpen = !isMenuOpen"
      :src="twoRightArrow"
      :class="isMenuOpen ? 'rotate-180' : 'rotate-0'"
      class="w-4 cursor-pointer mt-7 mr-4 ml-auto transition duration-500"
      alt="rightToggleArrow"
    />
    <div class="flex items-center cursor-pointer mt-48">
      <img :src="backCircleArrow" class="w-6 ml-7" alt="" />
      <p v-if="isMenuOpen" class="font-normal ml-[23px]">Diş Geçmişi</p>
    </div>
    <div
      @click="routerToPeriodontalChart"
      class="flex items-center cursor-pointer mt-9"
    >
      <img :src="toothLogo" class="w-5 ml-[29px]" alt="" />
      <p v-if="isMenuOpen" class="font-normal ml-[25px]">Periodontal Chart</p>
    </div>
    <div class="flex items-center cursor-pointer mt-9">
      <img :src="manLogo" class="h-6 ml-[30px]" alt="" />
      <p v-if="isMenuOpen" class="font-normal ml-[25px]">Baby Chart</p>
    </div>
    <div class="flex items-center cursor-pointer mt-9">
      <img :src="plusIcon" class="w-[38px] ml-[21px]" alt="" />
      <p v-if="isMenuOpen" class="font-normal ml-[16px]">İzleme</p>
    </div>
    <div class="flex items-center cursor-pointer mt-9">
      <img :src="fileLogo" class="h-6 ml-[31px]" alt="" />
      <p v-if="isMenuOpen" class="font-normal ml-[26px]">Tanı</p>
    </div>
    <div class="flex items-center cursor-pointer mt-9">
      <img :src="trashBinLogo" class="h-6 ml-[31px]" alt="" />
      <p v-if="isMenuOpen" class="font-normal ml-[24px]">Sil</p>
    </div>
  </div>
</template>

<script setup>
import backCircleArrow from "../../assets/backCircleArrow.svg";
import fileLogo from "../../assets/fileLogo.svg";
import manLogo from "../../assets/manLogo.svg";
import plusIcon from "../../assets/plusIcon.svg";
import toothLogo from "../../assets/toothLogo.svg";
import trashBinLogo from "../../assets/trashBinLogo.svg";
import twoRightArrow from "../../assets/twoRightArrow.svg";
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const isMenuOpen = ref(false);
const routerToPeriodontalChart = () => {
  router.push("/periodontal-chart");
};
</script>