import { defineStore } from "pinia";
export const useRegisteredPatientListStore = defineStore(
  "useRegisteredPatientListStore",
  {
    id: "useRegisteredPatientListStore",
    state: () => ({
      currentPage: 1,
      listDate: "2024-03-29",
      provisionsPatient: null,
      provisionRootApplication: null,
      provisionsDatas: {
        applicationID: "",
        trackingNo: "",
        acceptanceNo: "",
        corporation: "",
        provisionType: "",
        provisionDate: "",
        shippedTrackingNo: "",
        exceptionalStatus: "",
        followType: "",
        treatmentKind: "",
        unitCode: "",
        doctorCode: "",
        branchCode: "",
        branchName: "",
        assuranceStatus: "",
        insuranceType: "",
        treatmentType: "",
      },
      provisionsUpdatingPatientDatas: {
        patientID: "",
        province: "",
        district: "",
        addressDetail: "",
        phoneNumber: "",
        homePhoneNumber: "",
        email: "",
      },
      districts: null,
      caseDistricts: null,
      doctors: null,

      updatingPatientCard: {
        HastaKodu: "",
        IlKodu: {
          String: "",
          Valid: false,
        },
        IlceKodu: {
          String: "",
          Valid: false,
        },
      },
      updatingProvisionCard: {
        ProvizyonTipi: { id: "", text: "" },
        IstisnaiHal: { id: "", text: "" },
        TakipTipi: { id: "", text: "" },
        TedaviTuru: { id: "", text: "" },
        TedaviTipi: { id: "", text: "" },
        BirimAdi: { id: "", text: "" },
        BransKodu: { id: "", text: "" },
        BirimKodu: { id: "", text: "" },
        DoktorAdi: { id: "", text: "" },
        DoktorSoyadi: { id: "", text: "" },
        DoktorKodu: { id: "", text: "" },
      },
      panelHastaHeader: {},
      editProvisionCardShow: false,
      editleniyor: false,
      provizyonsuzFilter: false,
      provizyonluFilter: false,
      sidePanelShow: false,
      sidePanelCardNavigation: 1,
      navbarSearch: "",
      editButton: false,
      provisionMaviKartYabanciButtonsShow: [false, false],
      patientProvisionCardInfo: {
        sigortaliTuru: "",
        kayitTarihi: "",
        provizyonTipi: "",
        takipDurumu: "",
        takipNo: "",
        takipTarihi: "",
        takipTipi: "",
        tedaviTipi: "",
        tTedaviTuru: "",
        sevkDurumu: "",
      },

      patientHastaKartiInfo: {
        adresIl: "",
        adresIlce: "",
        adresParagraph: "",
        evTelefonu: "",
        ad: "",
        soyad: "",
        anneAdi: "",
        babaAdi: "",
        dogumYeri: "",
        medeniDurumu: "",
        sgDurumu: "",
        sigortaliTuru: "",
      },
      patientApplicationsFilter: {
        basvuruDurumu: "",
        provizyonTipi: "",
        birimAdi: "",
        kurumAdi: "",
        sigortaTipi: "",
        search: "",
      },
    }),
  }
);
