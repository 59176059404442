<template>
  <div
    :class="dentalChartStore.dentalChartSUTError[1] && 'overlay'"
    @click="openPopup()"
  >
    <div
      :class="dentalChartStore.dentalChartSUTError[1] && 'open-popup'"
      class="popup w-[531px] h-[190px] bg-white alignCard flex flex-col"
    >
      <button class="ml-auto -mt-5 -mr-3">
        <img :src="exitImg" alt="" />
      </button>
      <div class="flex flex-col items-center">
        <img class="w-[48px]" :src="warningImg" alt="" />
        <p class="text-[#707070] text-sm">
          Hizmet kaydı oluşturulmayan diş (46) bulunmaktadır.
        </p>
        <p class="text-[#707070] text-sm">
          Devam etmek isterseniz hizmet kaydı yapılmayan diş tedavi listesinden
        </p>
        <p class="text-[#707070] text-sm">kaldırılacaktır.</p>
        <div class="flex gap-3 mt-3">
          <button class="bg-[#E3E3E3] rounded-[25px] px-3 py-1">
            Hizmet kaydı oluştur
          </button>
          <button class="bg-[#277DD2] rounded-[25px] text-white px-3 py-1">
            Devam et
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import warningImg from "../../../assets/warning-red.svg";
import exitImg from "../../../assets/exit-white-bg-red.svg";
import { useDentalChart } from "../../dentalChartStore/dentalChartStore";
const dentalChartStore = useDentalChart();
const openPopup = () => {
  dentalChartStore.dentalChartSUTError[1] =
    !dentalChartStore.dentalChartSUTError[1];
};

const onCreateAppointment = async () => {
  //   popup.value = true;
  //   if (globalStore.isAppointmentCreateSuccesful) {
  //     setTimeout(() => {
  //       router.push("/appointment");
  //       popup.value = false;
  //     }, 3000);
  //   } else if (!globalStore.isAppointmentCreateSuccesful) {
  //     setTimeout(() => {
  //       popup.value = false;
  //     }, 3000);
  //   }
};
</script>

<style scoped>
.open-popup {
  visibility: visible;
  transform: translate(-100%, 0) scale(1);
}
.popup {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  right: 31%;
  top: 25%;
  transform: translate(0, 0) scale(1);

  color: #333;
  visibility: hidden;
  transition: transform 0.4s, left 0.4s;
}
.open-popup {
  visibility: visible;
  transform: translate(-25%, 0) scale(1);
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
}
</style>