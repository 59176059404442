import { createRouter, createWebHistory } from "vue-router";

import hastaDoktorMainViewVue from "@/modules/hastaDoktor/view/hastaDoktorMainView.vue";
import appoinmentRouter from "./appointmentRouter";
import asistantRouter from "./asistantRouter";
import personelRouter from "./personelRouter";
import dentalChartRouter from "./dentalChartRouter";
import periodontalChartRouter from "./periodontalChartRouter";
import studentAssistantRouter from "./studentAssistantRouter";
import anamnezFormRouter from "./anamnezFormRouter";
import doctorSlotsRouter from "./doctorSlotsRouter";
import klinikYonetimRouter from "./klinikYonetimRouter";
import doctorScreenRouter from "./doctorScreenRouter";
import ekOdemeRouter from "./ekOdemeRouter";
import radiologyRouter from "./radiologyRouter";
import mainPageRouter from "./mainPageRouter";
import protezRouter from "./protezRouter";

export const authRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...appoinmentRouter.options.routes,
    ...asistantRouter.options.routes,
    ...personelRouter.options.routes,
    ...dentalChartRouter.options.routes,
    ...studentAssistantRouter.options.routes,
    ...doctorSlotsRouter.options.routes,
    ...periodontalChartRouter.options.routes,
    ...anamnezFormRouter.options.routes,
    ...klinikYonetimRouter.options.routes,
    ...doctorScreenRouter.options.routes,
    ...ekOdemeRouter.options.routes,
    ...radiologyRouter.options.routes,
    ...mainPageRouter.options.routes,
    ...protezRouter.options.routes,
  ],
});

export default authRouter;
