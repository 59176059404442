import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useDeleteMessageTemplate = defineStore({
  id: "useDeleteMessageTemplate",
  state: () => ({}),
  actions: {
    async deleteMessageTemplate(id) {
      const personelStoreData = personelStore();
      await axios
        .delete(`${adminDomain}/message-template/${id}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("deleteMessageTemplateResponse", response.data);
        })
        .catch((error) => {
          console.log("deleteMessageTemplateError", error);
        });
    },
  },
});
