<template>
  <div class="bar">
    <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar, Chart } from "vue-chartjs";
import {
  data,
  tooltip,
  options,
  addData,
  removeData,
} from "./temporaryChartData/barChartConfig.js";
import { ref } from "vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const buildDataset = (index) => ({
  labels: tooltip.labels[index],
  label: tooltip.datasets[index].label,
  data: tooltip.datasets[index].data,
  backgroundColor: data.datasets[0].backgroundColor,
  // borderRadius: 20,
  // barPercentage: 0.3,
});

const resetDataset = () => ({
  labels: data.labels,
  label: data.datasets[0].label,
  data: data.datasets[0].data,
  barPercentage: 1,
  backgroundColor: data.datasets[0].backgroundColor,
});
export default {
  components: {
    Bar,
  },
  data() {
    const addChart = (index) => {
      const newSet = buildDataset(index);
      this.datasets = [];
      this.datasets.push(newSet);
      this.labels = [];
      this.labels.push(newSet.label);
      console.log(this.labels);
    };
    const resetChart = () => {
      const newSet = resetDataset();
      console.log(newSet);
      this.datasets = [];
      this.datasets.push(newSet);
      this.labels = [];
      this.labels.push(newSet.labels);
      console.log("labels", this.datasets);
    };
    return {
      datasets: [resetDataset()],
      // labels:[this.]
      chartOptions: {
        responsive: true,
        onClick: function (evt, array) {
          if (array.length === 0) {
            resetChart();
          } else {
            addChart(array[0].index);
          }
        },
      },
    };
  },

  computed: {
    chartData() {
      return {
        labels: [...this.datasets[0].labels],
        datasets: [...this.datasets],
      };
    },
  },
};
</script>

<style scoped>
.bar {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
}
#my-chart-id {
  width: 500px;
  height: 208px;
}
</style>
