import { defineStore } from "pinia";
export const doctorSlotsStore = defineStore("doctorSlotsStore", {
  id: "doctorSlotsStore",
  state: () => ({
    doctorSlotsMenu: 1,
    doctorServiceFilter: 0,
    doctorInfo: {
      ad: "",
      soyad: "",
      bolum: "",
      email: "",
      cepTelefonu: "",
      dogumTarihi: "",
      tcNo: "",
      cinsiyet: "",
      evAdresi: "",
      unvanGrubu: "",
      unvan: "",
      goreveBaslamaTarihi: "",
      kadroYeri: "",
    },
    doctorGelireDonusenHizmetTablosu: [
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Yapıldı",
        toplam: "300 işlem puanı",
      },
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Yapıldı",
        toplam: "300 işlem puanı",
      },
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Yapıldı",
        toplam: "300 işlem puanı",
      },
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Yapıldı",
        toplam: "300 işlem puanı",
      },
    ],
    doctorGelireDonusmemisHizmetTablosu: [
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Bulunamadı",
        toplam: "300 işlem puanı",
      },
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Bulunamadı",
        toplam: "300 işlem puanı",
      },
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Bulunamadı",
        toplam: "300 işlem puanı",
      },
      {
        tarih: "01/01/2021",
        sutKodu: "401010",
        islemSayisi: "15",
        islemPuani: "200",
        hizmetTuru: "Diş Hekimi Muayenesi",
        ucret: "500 TL",
        hizmetKaydi: "Bulunamadı",
        toplam: "300 işlem puanı",
      },
    ],
  }),
  getters: {},
  actions: {},
});
