<template>
  <div class="sticky flex-col mt-7 mb-14 w-full lg:mx-auto  ">
    
    <div class="sticky top-0">

      <div
        class="flex rounded-[32.5px] mx-auto w-[98%] h-16 bg-[#F6F6F6] cursor-pointer items-center"
      >
        <button
          @click="buttonManagement = 1"
          :class="buttonManagement === 1 && 'bg-white'"
          class="rounded-[32.5px] flex items-center justify-center ml-9 w-[200px] h-[50px]"
        >
          <p :class="buttonManagement === 1 ? 'text-[#0057FF]' : 'text-black'">
            Hasta Tedavi Ücret
          </p>
        </button>
        <button
          @click="buttonManagement = 2"
          :class="buttonManagement === 2 && 'bg-white'"
          class="rounded-[32.5px] flex items-center justify-center ml-9 w-[150px] h-[50px]"
        >
          <p :class="buttonManagement === 2 ? 'text-[#0057FF]' : 'text-black'">
            Hekim Puan Tablosu
          </p>
        </button>
      </div>
    </div>
    <div class="w-[1100px] mx-auto">

      <div
        class="flex items-center border-[1px] border-[#ECECEC] bg-[#FCFCFC]   h-[46px] mx-2"
      >
        <p class="text-[#2C3D8F] font-normal ml-9">Tarih</p>
        <p class="text-[#2C3D8F] font-normal ml-[65px]">Alan</p>
        <p class="text-[#2C3D8F] font-normal ml-[60px]">Kod</p>
        <p class="text-[#2C3D8F] font-normal ml-[50px]">Konum</p>
        <p class="text-[#2C3D8F] font-normal ml-[40px]">Ücret</p>
        <p class="text-[#2C3D8F] font-normal ml-[240px]">Hizmet Türü</p>
        <p class="text-[#2C3D8F] font-normal ml-[200px]">Durum</p>
        
      </div>
        <dentalChartDoctorSectionDoctorInfoComponent
          v-for="(patient, index) in store.dentalChartDoctorSectionDoctorInfo"
          :key="index"
          :tarih="patient.tarih"
          :alan="patient.alan"
          :kod="patient.kod"
          :konum="patient.konum"
          :ucret="patient.ucret"
          :ucretTuru="patient.ucretTuru"
          :hizmetTuru="patient.hizmetTuru"
          :durum="patient.durum"
        />
    </div>
      
  </div>
</template>

<script setup>
import { ref } from "vue";
import dentalChartDoctorSectionDoctorInfoComponent from "./dentalChartDoctorSectionDoctorInfoComponent";
// Store
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();

const buttonManagement = ref(1);
</script>