<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">AD / SOYAD</th>
        <th class="text-xl w-[200px] text-center">SİPARİŞ SAYISI</th>
        <th class="text-xl w-[300px] text-center">TEDAVİ ADI</th>
        <th class="text-xl w-[250px] text-center">OLUŞTURULMA TARİHİ</th>
        <th class="text-xl w-[200px] text-center">EKLEYEN HEKİM</th>
        <th class="text-xl w-[200px] text-center">KURUM ADI</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(technician, index) in techniciansData.slice(
          (currentPage - 1) * 7,
          currentPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px]"
        :class="index === activeIndex ? 'bg-[#f4f8fc]' : 'bg-white'"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
            class="bg-white mt-1"
          />
        </td>
        <td class="w-[200px] text-center">
          {{ technician.ad + " " + technician.soyad }}
        </td>
        <td class="w-[200px] text-center">
          {{ technician.siparisSayisi }}
        </td>
        <td class="w-[200px] text-center">
          {{ technician.tedaviAdi }}
        </td>
        <td class="w-[300px] text-center">
          {{ technician.olusturulmaTarihi }}
        </td>
        <td class="w-[250px] text-center">
          <p>
            {{ technician.ekleyenHekim }}
          </p>
        </td>
        <td class="w-[200px] text-center">
          {{ technician.kurumAdi }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from "vue";
import { techniciansData } from "@/modules/protez/store/temporaryProtezData.js";
defineProps(["currentPage"]);

const activeIndex = ref(null);
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
  } else {
    activeIndex.value = index;
  }
};
</script>
