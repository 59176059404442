<template>
  <div class="mt-28 flex flex-col items-center">
    <p class="h-6">POLİKLİNİKLERİMİZİN KLİNİK İŞLEYİŞ DÜZENİ</p>
    <p class="h-6">
      İLK MUAYENE İÇİN RANDEVU ALACAKLAR AŞAĞIDAKİ HUSUSLARA DİKKAT ETMESİ
      GEREKMEKTEDİR.
    </p>
    <div class="mt-2 max-w-[1035px]">
      <p class="h-10">
        İlk defa hastanemize başvuracak hastalarımızın üzerinde T.C. kimlik
        numarası yazan resmi bir belge (kimlik-sürücü belgesi-pasaport vb) ile
        başvurmaları zorunludur.
      </p>
      <p class="mt-3 h-10 font-medium text-[#4198F7]">
        Randevularımız yetişkin hastalar için her gün saat 15:00'te bir sonraki
        iş günü için web sitemiz üzerinden açılmaktadır. (Yetişkin Hasta İlk
        Muayenesi Randevularını web sitemiz üzerinden alabilirsiniz.)
      </p>
      <p class="mt-3 h-16">
        Randevular; Yetişkin İlk Muayene (15 yaş ve üzeri), Çocuk İlk Muayene
        Pedodonti (0-15 yaş arası) randevuları şeklinde verilmektedir.
        Randevularınızı belirlenen yaş gruplarına göre; 0-15 yaş için her hafta
        Salı-Çarşamba-Perşembe-Cuma günleri olmak üzere saat 15.00 ‘te web
        sitemiz üzerinden veya üzerinden alabilirsiniz
      </p>
      <p class="mt-3 h-5 font-normal">
        18 yaşından küçük hastalarımızın muayene ve tedavilerine gelirken
        ebeveynleriyle birlikte gelmesi gerekmektedir.
      </p>
      <p class="mt-3 h-5">
        Hafta Sonları ve Resmi Tatil günlerine randevu verilmemektedir.
      </p>
      <p class="mt-3 h-10">
        Merkezimize muayene ve tedavi amaçlı başvuran
        <span class="font-bold">YEŞİLKART </span> hastalarımızın her
        gelişlerinden önce Ağız ve Diş Sağlığı Merkezi/Hastanesi gibi 2. Basamak
        sağlık kuruluşlarından <span class="font-bold">SEVK </span> almaları
        gerekmektedir.
      </p>
      <p class="mt-3 h-20">
        Hastanemize başvuran kurum sandık hastalarının, kurum sevki, kurum
        kimlik kartı ve kimlik ibrazı yapmaları gerekmektedir. Her Anabilim Dalı
        Kliniğinin günlük hasta kontenjan sayısı (kabul edilen hasta sayısı)
        değişiklik göstermektedir. Mevcut tedavi edilecek hasta sayıları ilgili
        Anabilim Dalı uzman hekim sayısı ve tedavi süreleri göz önüne alınarak
        ilgili Anabilim Dalı Başkanları tarafından belirlenmektedir. Randevu
        günü verilen randevusaatinde Merkezimize gelen hastaların, ilk olarak
        hasta kabul birimine başvurarak giriş yaptırması gerekir.
      </p>
      <p class="mt-3 h-16">
        İlk muayene sonrası hekimin yönlendirme yaptığı teşhis planlama formu
        ile hastalarımız hasta kabul birimine başvurarak yönlendirmeleri
        yapılmaktadır. İlk muayene randevusunda sadece ilk muayene yapılarak
        teşhis konulmaktadır. Konulan teşhise göre ilgili bölümlerde tedaviler
        ayrıca verilen randevularla yapılmaktadır.
      </p>
      <p class="mt-3 h-20 font-bold text-[#4198F7]">
        KSBÜ Ağız Diş Sağlığı Uygulama ve Araştırma Merkezi, bölgede Ağız ve Diş
        Sağlığı konusunda tüm Anabilim Dalları bazında uzman diş hekimi bulunan
        en üst düzey Diş Hastanesi konumundadır. Merkezimiz, bir sağlık kurumu
        olmanın öncesinde bir eğitim kurumudur. Bu nedenle Merkezimiz bünyesinde
        bulunan akademisyen diş hekimlerinin ilk önceliği, öğrencilere teorik
        eğitimler vermek ve onları pratik eğitimlerle destekleyip arta kalan
        zamanda klinik süreçlerini gerçekleştirmektir.
      </p>
      <div class="mt-7 flex max-h-[700px] flex-col">
        <div class="flex h-[120px] flex-row">
          <div class="ml-10 w-[310px]">
            <p class="headerUl">
              Ağız, Diş ve Çene Radyolojisi Anabilim Dalı
            </p>
            <ul class="mt-1">
              <li class="h-4">Tüm Ağız ve Diş muayenesi</li>
              <li class="h-4">Röntgen çekimi</li>
              <li class="h-4">Tedavi Planlamasının yapılması</li>
              <li class="h-4">3 Boyutlu Tomografi çekimi</li>
              <li class="h-4">Ultrasonografik görüntüleme</li>
            </ul>
          </div>
          <div class="ml-24">
            <p class="headerUl">Restoratif Diş Tedavisi Anabilim Dalı</p>
            <ul>
              <li class="h-4">Dolgu</li>
              <li class="h-4">Diş tedavileri</li>
              <li class="h-4">Diş beyazlatma tedavileri</li>
              <li class="h-4">Estetik dolgu-restorasyon uygulamaları</li>
              <li class="h-4">Lamina tedavileri</li>
            </ul>
          </div>
        </div>
        <div class="mt-5 flex h-[120px] flex-row">
          <div class="ml-10 w-[310px]">
            <p class="headerUl">Periodontoloji Anabilim Dalı</p>
            <ul class="mt-1">
              <li class="h-4">Diş eti hastalıkları tedavisi</li>
              <li class="h-4">Diş taşı temizlikleri</li>
              <li class="h-4">Dişeti çekilmelerinin tedavisi</li>
              <li class="h-4">Dişeti iltihaplarının ileri tedavisi</li>
              <li class="h-4">İmplant çevresi hastalıkların tedavisi</li>
              <li class="h-4">Dişeti estetik cerrahi uygulamaları</li>
            </ul>
          </div>
          <div class="ml-24">
            <p class="headerUl">
              Ağız, Diş ve Çene Cerrahisi Anabilim Dalı
            </p>
            <ul>
              <li class="h-4">Dentoalveolar Cerrahi</li>
              <li class="h-4">Diş Çekimleri</li>
              <li class="h-4">Dental İmplant Cerrahisi</li>
              <li class="h-4">
                Kemik ve yumuşak doku yetersizliklerinin greftler veya diğer
                cerrahi teknikler ile onarımı
              </li>
              <li class="h-4">
                Ağız, Diş ve Çene patolojilerinin teşhis ve tedavileri
              </li>
              <li class="h-4">Çene-yüz deformitelerinin tamiri</li>
              <li class="h-4">
                Çene eklemi hastalıklarının ileri teşhis ve tedavisi
              </li>
              <li class="h-4">Çene kemikleri kırıklarının cerrahi tedavisi</li>
            </ul>
          </div>
        </div>
        <div class="mt-10 flex h-[120px] flex-row">
          <div class="ml-10 w-[310px]">
            <p class="headerUl">Endodonti Anabilim Dalı</p>
            <ul class="mt-1">
              <li class="h-4">Kanal tedavileri</li>
              <li class="h-4">Kanal içi alet kırıklarının tedavisi</li>
              <li class="h-10">
                Başarısız kanal tedavilerinin yenilenmesi (Retreatment)
              </li>
              <li class="h-4">Diş apselerinin tedavisi</li>
            </ul>
          </div>
          <div class="ml-24">
            <p class="headerUl">
              Pedodonti Anabilim Dalı (15 yaşından küçük çocukların diş
              tedavileri)
            </p>
            <ul>
              <li class="h-4">Dolgu</li>
              <li class="h-4">Kanal tedavileri</li>
              <li class="h-4">Diş çekimi</li>
              <li class="h-4">Yerel flor uygulaması</li>
              <li class="h-4">Yer tutucu</li>
              <li class="h-4">Çürük önleyici uygulamalar</li>
              <li class="h-4">
                Süt dişi ve daimi diş travmalarının uzun dönem tedavisi
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-10 flex h-[120px] flex-row">
          <div class="ml-10 w-[310px]">
            <p class="headerUl">Protetik Diş Tedavisi Anabilim Dalı</p>
            <ul class="mt-1">
              <li class="h-4">Protez yapımı</li>
              <li class="h-4">Kron-Köprü tedavisi</li>
              <li class="h-4">Alt-üst hareketli protez</li>
              <li class="h-4">İmplant üstü protezler</li>
              <li class="h-4">Estetik lamina uygulamaları</li>
              <li class="h-4">Çene-yüz protezleri</li>
              <li class="h-4">Beslenme plakları</li>
              <li class="h-4">
                Diş sıkma-diş gıcırdatma erken dönem tedavileri
              </li>
            </ul>
          </div>
          <div class="ml-24">
            <p class="headerUl ">Ortodonti Anabilim Dalı</p>
            <ul>
              <li class="h-4">Diş teli tedavisi</li>
              <li class="h-4">Şeffaf plak tedavisi</li>
              <li class="h-4">Erişkin diş teli tedavisi</li>
              <li class="h-4">Dudak-damak yarığı</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>


<style scoped>
.headerUl {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
li {
  font-size: 14px;
  margin-left: 10px;
  list-style-type: disc;
}
</style>
