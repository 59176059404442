<template>
  <div class="flex-[2] px-4 flex flex-col gap-8">
    <div>
      <h4 class="font-medium text-[#287DD2] text-xl mb-2">
        AKTİF RANDEVU HATIRLATMA
      </h4>
      <div class="rounded bg-[#fbfbfb] p-2 flex flex-col gap-6">
        <div class="flex gap-4 items-start">
          <button
            class="flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] h-6 w-12 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="activeAppointmentReminder ? 'bg-[#026D4D]' : 'bg-gray-300'"
            @click="toggleActiveAppointmentReminder()"
          >
            <div
              class="h-5 w-5 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{
                'translate-x-5': activeAppointmentReminder,
              }"
            ></div>
          </button>
          <div class="text-lg -mt-1">
            <p class="font-semibold">Hatırlatma Mesajını Etkinleştir</p>
            <p class="opacity-50">Hatırlatma Mesajını Etkinleştir</p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-1">
            <p class="opacity-50">Kaç saat önce gönderilsin?</p>
            <Select2
              class="rounded align-middle justify-between p-1 flex h-[31px] w-[300px] border-[#D9D9D9] cursor-pointer"
              :options="['24 saat', '12 saat', '6 saat', '3 saat', '1 saat']"
              @select="chooseBirim"
            />
          </div>
          <div class="flex flex-col gap-1">
            <p class="opacity-50">Mesaj içeriği</p>
            <Select2
              class="rounded align-middle justify-between p-1 flex h-[31px] w-[300px] border-[#D9D9D9] cursor-pointer"
              :options="['Şablon 1', 'Şablon 2', 'Şablon 3']"
              @select="chooseBirim"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h4 class="font-medium text-[#F94144] text-xl mb-2">
        İPTAL EDİLEN RANDEVU HATIRLATMA
      </h4>
      <div class="rounded bg-[#fbfbfb] p-2 flex flex-col gap-6">
        <div class="flex gap-4 items-start">
          <button
            class="flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] h-6 w-12 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="passiveAppointmentReminder ? 'bg-[#026D4D]' : 'bg-gray-300'"
            @click="togglePassiveAppointmentReminder()"
          >
            <div
              class="h-5 w-5 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{
                'translate-x-5': passiveAppointmentReminder,
              }"
            ></div>
          </button>
          <div class="text-lg -mt-1">
            <p class="font-semibold">Hatırlatma Mesajını Etkinleştir</p>
            <p class="opacity-50">Hatırlatma Mesajını Etkinleştir</p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <!-- <div class="flex flex-col gap-1">
            <p class="opacity-50">Kaç saat önce gönderilsin?</p>
            <Select2
              class="rounded align-middle justify-between p-1 flex h-[31px] w-[300px] border-[#D9D9D9] cursor-pointer"
              :options="['24 saat', '12 saat', '6 saat', '3 saat', '1 saat']"
            />
          </div> -->
          <div class="flex flex-col gap-1">
            <p class="opacity-50">Mesaj içeriği</p>
            <Select2
              class="rounded align-middle justify-between p-1 flex h-[31px] w-[300px] border-[#D9D9D9] cursor-pointer"
              :options="['Şablon 1', 'Şablon 2', 'Şablon 3']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Select2 from "vue3-select2-component";
import { ref } from "vue";
const activeAppointmentReminder = ref(true);
const passiveAppointmentReminder = ref(false);
const toggleActiveAppointmentReminder = () => {
  activeAppointmentReminder.value = !activeAppointmentReminder.value;
};

const togglePassiveAppointmentReminder = () => {
  passiveAppointmentReminder.value = !passiveAppointmentReminder.value;
};
</script>
