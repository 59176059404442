<template>
  <div class="flex w-full min-h-screen">
    <doctorHastaKabulSidebar />
    <div class="w-full relative flex flex-col">
      <personelHeaderNavbar title="Klinik Yönetimi" />
      <main class="p-3 flex flex-col gap-5 h-full bg-[#fbfbfb]">
        <klinikYonetimUnitHome />
      </main>
    </div>
  </div>
</template>

<script setup>
import personelHeaderNavbar from "@/modules/personel/components/personelHeaderNavbar";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import klinikYonetimUnitHome from "../components/klinikYonetimUnitHome.vue";
import { useKlinikYonetimStore } from "../store/klinikYonetim";
import { useGetUnit } from "@/store/modules/administratorManagementStore/hospital/Units/getUnitStore";
import { useGetDoctorsByUnitStore } from "@/store/modules/administratorManagementStore/staff/getDoctorsByUnit";
const doctorsByUnitStore = useGetDoctorsByUnitStore();
const klinikYonetimStore = useKlinikYonetimStore();
const getUnitStore = useGetUnit();
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;

onMounted(async () => {
  klinikYonetimStore.klinikUnitScreenUnitDatas = null;
  klinikYonetimStore.klinikUnitScreenUnitDatas = await getUnitStore.getUnit(
    unitID
  );
});

onMounted(async () => {
  klinikYonetimStore.klinikUnitScreenDoctors = null;
  klinikYonetimStore.klinikUnitScreenDoctors =
    await doctorsByUnitStore.getDoctorsByUnit(unitID);
});
</script>
