<template>
  <div
    v-if="personalStore.personalNavbar == 1"
    class="text-[#464E5F] w-full overflow-y-auto overflow-x-hidden rounded-[5px] flex flex-col"
  >
    <div class="flex gap-4 items-center my-3 ml-[1px]">
      <div class="">
        <Select2
          class="w-[300px] border-[#D9D9D9]"
          :options="unitListStore.getListUnitsResponse"
          placeholder="Birim"
          v-model="personalStore.personalTableBirimFilter"
        />
      </div>

      <form
        class="focus-within:outline-1 focus-within:outline-double focus-within:outline-[#277dd2] flex gap-3 border bg-white py-2 pl-2 rounded-full w-[400px]"
      >
        <img :src="search" alt="search" />
        <input
          type="text"
          class="w-10/12 outline-none"
          placeholder="Hasta ya da Doktor ara"
        />
      </form>
      <div class="ml-auto flex items-center gap-3">
        <p class="font-medium">Provizyon Al</p>
        <button
          class="flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
          :class="
            appointmentsStore.isProvisionedAppointments
              ? 'bg-[#026D4D]'
              : 'bg-gray-300'
          "
          @click="toggleProvizyonAl"
        >
          <div
            class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
            :class="{
              'translate-x-3': appointmentsStore.isProvisionedAppointments,
            }"
          ></div>
        </button>
      </div>
    </div>

    <div
      class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col h-full"
    >
      <div
        class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
      >
        <div class="h-[80%] w-[20%] flex items-center">
          <p class="font-medium ml-4">RANDEVU SAATİ</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">HASTA AD/SOYAD</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">BİRİM</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">DOKTOR</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">PROVİZYON TİPİ</p>
        </div>
      </div>
      <div
        v-for="(appointment, index) in appointmentsStore.appointments
          .appointments.data"
        class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
        :key="index"
      >
        <div class="h-[80%] w-[20%] flex items-center gap-2">
          <p class="ml-4">
            {{
              appointment.attributes.appointment_time.split(" ")[1].slice(0, 5)
            }}
          </p>
          <img :src="arrowRight" />
          {{
            appointment.attributes.appointment_end_time
              .split(" ")[1]
              .slice(0, 5)
          }}
        </div>
        <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
          <p>
            {{ appointment.attributes?.patient.patient_name }}&nbsp;{{
              appointment.attributes?.patient.patient_surname
            }}
          </p>
          <p class="opacity-80 text-sm">
            TC({{ appointment.attributes?.patient.tc_id_number }})
          </p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <div class="flex items-center justify-center px-[10px] py-[5px]">
            <div
              class="w-3 h-3 flex-shrink-0 rounded-full"
              :style="{ backgroundColor: '#fd8540' }"
            ></div>
            <p class="ml-1">
              {{ _.startCase(_.toLower(appointment.attributes?.unit_name)) }}
            </p>
          </div>
        </div>
        <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
          <p>
            {{ appointment.attributes?.doctor_name }}
          </p>
          <!-- <p class="opacity-80 text-sm">Unvan</p> -->
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <div class="group relative">
            <button
              v-if="!appointment.attributes.tracking_number"
              @click="routeToProvision(appointment)"
              class="px-1 py-2 bg-white border-[#277DD2] border-[1px] rounded-xl"
            >
              <p class="text-[#277DD2] font-medium">Provizyon Al</p>
            </button>
            <div
              class="text-sm text-left absolute z-10 text-white hidden group-focus-within:block group-hover:block right-0 -top-8 py-1 rounded-lg"
            >
              <button
                @click="provisionWithSettings(appointment)"
                class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 w-40 py-1"
              >
                Otomatik Provizyon Al
              </button>
            </div>
          </div>
          <p v-if="appointment.attributes.tracking_number">
            {{ appointment.attributes.tracking_number }}
          </p>
        </div>
      </div>

      <!-- placeholder -->
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

import { ref, watch, onMounted } from "vue";
import _ from "lodash";
import search from "@/mainPage/assets/search.svg";
import arrowRight from "@/modules/personel/assets/arrowRight.svg";
import { usePersonalStore } from "@/modules/personel/store/personalStore";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useSearchAppointmentsStore } from "@/store/modules/operationsStore/appointment/vem-appointment/useSearchAppointmentsFull";
import { useAppointments } from "./appointmentsStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import Select2 from "vue3-select2-component";
import { useGetProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/getProvisionSettings";
import { addPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/addPatientApplicationStore.ts";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";
import {
  optionTypes,
  exceptionalTypes,
  fallowypes,
  treatmentKindTypes,
  treatmentTypes,
} from "@/modules/medula/provision/components/dropDownTypes";
import { updateTedaviTipiStore } from "@/store/modules/operationsStore/application/updateTedaviTipi.ts";
const updTedaviTipiStore = updateTedaviTipiStore();
const applicationProvisionStore = useApplicationProvisionStore();
const provisionState = applicationProvisionStore.applicationProvisionRequest;
const appointmentsStore = useAppointments();
const medulaStore = useMedula();
const getPatientStore = useGetPatientStore();
const applicationStore = addPatientApplicationStore();
const applicationState = applicationStore.addPatientApplicationRequest;
const getProvisionSettingsStore = useGetProvisionSettings();

const unitListStore = useGetUnitsList();
const personalStore = usePersonalStore();
const dayMilliseconds = 24 * 60 * 60 * 1000;
onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});

onMounted(async () => {
  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  appointmentsStore.activeDate = formattedDate;
  appointmentsStore.appointmentsPage = 1;
  appointmentsStore.appointments.appointments.data = [];
  const fetchAppointmentsReq = {
    doctor_id: "",
    doctor_name: "",
    doctor_surname: "",
    unit_id: personalStore.personalTableBirimFilter,
    tc_identity_number: "",
    patient_name: "",
    patient_surname: "",
    phone_number: "",
    start_date: formattedDate,
    end_date: new Date(
      new Date(appointmentsStore.activeDate).getTime() + dayMilliseconds
    )
      .toISOString()
      .split("T")[0],
    provision_identifier: appointmentsStore.isProvisionedAppointments
      ? "true"
      : "false",
    page_id: 1,
    page_size: 6,
  };
  await useSearchAppointmentsStore().searchAppointments(fetchAppointmentsReq);
});

watch(
  () => personalStore.personalTableBirimFilter,
  async () => {
    appointmentsStore.appointmentsPage = 1;
    appointmentsStore.appointments.appointments.data = [];
    const fetchAppointmentsReq = {
      doctor_id: "",
      doctor_name: "",
      doctor_surname: "",
      unit_id: personalStore.personalTableBirimFilter,
      tc_identity_number: "",
      patient_name: "",
      patient_surname: "",
      phone_number: "",
      start_date: appointmentsStore.activeDate,
      end_date: new Date(
        new Date(appointmentsStore.activeDate).getTime() + dayMilliseconds
      )
        .toISOString()
        .split("T")[0],
      provision_identifier: appointmentsStore.isProvisionedAppointments
        ? "true"
        : "false",
      page_id: 1,
      page_size: 6,
    };
    await useSearchAppointmentsStore().searchAppointments(fetchAppointmentsReq);
  }
);

const toggleProvizyonAl = () => {
  appointmentsStore.isProvisionedAppointments =
    !appointmentsStore.isProvisionedAppointments;
};

watch(
  () => appointmentsStore.isProvisionedAppointments,
  async (newValue) => {
    const fetchAppointmentsReq = {
      doctor_id: "",
      doctor_name: "",
      doctor_surname: "",
      unit_id: personalStore.personalTableBirimFilter,
      tc_identity_number: "",
      patient_name: "",
      patient_surname: "",
      phone_number: "",
      start_date: appointmentsStore.activeDate,
      end_date: new Date(
        new Date(appointmentsStore.activeDate).getTime() + dayMilliseconds
      )
        .toISOString()
        .split("T")[0],
      provision_identifier: newValue ? "true" : "false",
      page_id: 1,
      page_size: 6,
    };
    await useSearchAppointmentsStore().searchAppointments(fetchAppointmentsReq);
  }
);

const routeToProvision = async (appointment) => {
  medulaStore.resetDropdowns();
  router.push("/asistantScreen/medula/homePage");
  medulaStore.selectedPatient = await getPatientStore.getPatientA(
    appointment.attributes.patient.patient_id
  );
  medulaStore.selectedPatientID = appointment.attributes.patient.patient_id;
  medulaStore.searchPatientInput = appointment.attributes.patient.tc_id_number;
  medulaStore.appointmentsApplication =
    appointment.attributes.patient.patient_application_id;
  medulaStore.appointmentsApplicationDatas = appointment.attributes;
  console.log("oy", appointment);
};

const provisionWithSettings = async (appointment) => {
  await getProvisionSettingsStore.getProvisionSettings();
  const treatmentType = treatmentKindTypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.tedavi_turu
  );
  const followType = fallowypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.takip_tipi
  );
  const provisionType = optionTypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.provizyon_tipi
  );
  const tedavi_tipi = treatmentTypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.tedavi_tipi
  );
  await updTedaviTipiStore.updateTedaviTipi(
    appointment.attributes.patient.patient_application_id,
    treatmentType.knidosID,
    followType.id,
    provisionType.id,
    tedavi_tipi.id
  );
  await applicationProvisionStore.getApplicationProvisions(provisionState);
};
</script>
