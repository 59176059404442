import { defineStore } from "pinia";
import { GetPatientResponse } from "@/types/operations/patient/getPatientResponse";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import axios from "axios";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
import { globalStore } from "../../globalStore";
import { personelStore } from "../../administratorManagementStore/personel";
import { UseSearchPatientStore } from "./useSearchPatientStore";
import { useUpdatePatientStore } from "@/store/modules/operationsStore/patient/useUpdatePatientStore";
import _ from "lodash";

export const useGetPatientStore = defineStore({
  id: "getPatient",
  state: () => ({
    getPatientResponse: null as GetPatientResponse | null,
  }),
  actions: {
    async getPatientA(id: string) {
      try {
        const personelStoreData = personelStore();
        let token = localStorage.getItem("patientStoreData");
        let accessToken = token ? JSON.parse(token).accessToken : "";
        const response = await axios.get(`${clinicDomain}/patients/${id}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        });
        console.log("getPatientA", response.data);
        return response.data.data;
      } catch (error) {
        console.error("Error in searchPatient:", error);
      }
    },

    async getPatient(id: string, screen: string) {
      const medulaStore = useMedula();
      const searchPatientStore = UseSearchPatientStore();
      const updatePatientStore = useUpdatePatientStore();
      const registeredPatientListStore = useRegisteredPatientListStore();

      await axios
        .get(`${clinicDomain}/patients/${id}`, {
          headers: {
            Authorization: patientAuthToken,
            //AcceptLanguage:--
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            this.getPatientResponse = response.data;

            response.data.TcKimlikNumarasi.Int64 !== 0
              ? (globalStore.isPatiendIdExist = true)
              : (globalStore.isPatiendIdExist = false);
            console.log(
              "useGetPatientStore.getPatient : ",
              this.getPatientResponse
            );
            searchPatientStore.searchPatientSingularResponse = response.data;
            if (screen === "provision") {
              medulaStore.hastaHeader = response.data;
              medulaStore.updatingHasta = _.cloneDeep(response.data);

              console.log("provisionbrr", medulaStore.hastaHeader);
            } else if (screen === "registeredPatientList") {
              registeredPatientListStore.panelHastaHeader = response.data;
              registeredPatientListStore.updatingPatientCard = _.cloneDeep(
                response.data
              );
            }
            console.log("singular", response.data);
          }
        });
    },
  },
});
