<template>
  <div class="flex flex-col w-full h-screen overflow-x-hidden">
    <dentalChartNavbar />

    <div class="mt-5 ml-2">
      <dentalChartTeethButtons />
    </div>
    <!-- <anamnezPopUpMain /> -->
    <dentalChartTeethComponent
      v-if="!dentalChartStore.dentalChartAnamnezPopUpIsOpen"
    />
    <div
      v-if="!dentalChartStore.dentalChartAnamnezPopUpIsOpen"
      class="w-full min-h-[300px] overflow-x-auto"
    >
      <!-- <dentalChartDoctorRadiologyComponent/> -->
      <dentalChartDoctorSectionComponent />
    </div>
    <!-- <dentalChartChangeTooth /> -->
    <!-- <dentalChartChangesForToothSave /> -->
  </div>
</template>

<script setup>
import dentalChartNavbar from "./dentalChartNavbar.vue";
import dentalChartTeethComponent from "./dentalChartTeethComponent.vue";
import dentalChartDoctorSectionComponent from "./dentalChartDoctorSectionComponent.vue";
import dentalChartTeethButtons from "./dentalChartTeethButtons.vue";
// import anamnezPopUpMain from "@/modules/dentalChart/dentalChartAnamnezPopUp/components/anamnezPopUpMain.vue";

import dentalChartDoctorRadiologyComponent from "./dentalChartDoctorRadiologyComponent.vue";
import dentalChartChangeTooth from "./dentalChartChangeTooth.vue";
import dentalChartChangesForToothSave from "./dentalChartChangesForToothSave.vue";
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const dentalChartStore = useDentalChart();
</script>