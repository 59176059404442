<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">SİPARİŞ ID</th>
        <th class="text-xl w-[200px] text-center">HASTA ADI</th>
        <th class="text-xl w-[200px] text-center">KURUM ADI</th>
        <th class="text-xl w-[200px] text-center">GÖNDEREN HEKİM</th>
        <th class="text-xl w-[300px] text-center">HASTANEDEN ÇIKIŞ TARİHİ</th>
        <th class="text-xl w-[200px] text-center">DURUM</th>
        <th class="text-xl w-[350px] text-center">TESLİM DURUMU</th>
        <!-- v-if="!popUpManagement.detailPopUp" -->
        <div class="my-auto w-12">
          <th></th>
        </div>
        <!-- <div v-if="popUpManagement.detailPopUp" class="my-auto">
            <th></th>
          </div> -->
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(order, index) in filteredOrders().slice(
          (currentCargoPage - 1) * 7,
          currentCargoPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px] bg-white"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
            class="bg-white mt-1"
          />
        </td>
        <td class="w-[200px] text-center relative">
          {{ order.siparisID }}
        </td>
        <td class="w-[200px] text-center">
          <div>
            {{ order.hastaAdi }}
          </div>
        </td>
        <td class="w-[200px] text-center">
          {{ order.kurumAdi }}
        </td>

        <td class="w-[200px] text-center">
          <p>
            {{ order.kargoBilgileri.gonderenHekim }}
          </p>
        </td>
        <td class="w-[250px] text-center">
          {{ order.kargoBilgileri.hastanedenCikisTarihi }}
        </td>

        <td class="w-[200px] text-center">
          <div
            v-if="order.kargoBilgileri.kargoDurumu === 1"
            class="flex items-center justify-center gap-3"
          >
            <img :src="upArrow" alt="detailIcon" />
            <p>Kargo Gönderildi</p>
          </div>
          <div
            v-if="order.kargoBilgileri.kargoDurumu === 0"
            class="flex items-center justify-center gap-3"
          >
            <img :src="downArrow" alt="detailIcon" />
            <p>Kargo Geldi</p>
          </div>
        </td>
        <td class="w-[350px] text-center">
          <div
            v-if="order.teslimDurumu === 0"
            class="text-[#bfbfbf] flex gap-2 items-center justify-center"
          >
            <img :src="approve" alt="approve" class="cursor-pointer" />Teslim
            durumunu onaylayın
          </div>
          <div
            v-if="order.teslimDurumu === 1"
            class="text-[#5cb85c] flex gap-2 items-center justify-center"
          >
            <img :src="delivered" alt="delivered" />Teslim edildi
          </div>
        </td>

        <div>
          <td>
            <div
              class="cursor-pointer mt-2 w-fit px-2"
              @click="
                ordersPopUpManagement.detailPopUp = true;
                activeIndex = index;
              "
            >
              <img :src="detailIcon" alt="detailIcon" />
            </div>
          </td>
        </div>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import {
  tempData,
  tempOrdersData,
  popUpManagement,
  ordersPopUpManagement,
  cargoNavbarTitle,
} from "@/modules/protez/store/temporaryProtezData.js";
import detailIcon from "@/modules/protez/assets/detailIcon.svg";
import upArrow from "@/modules/protez/assets/upArrow.svg";
import downArrow from "@/modules/protez/assets/downArrow.svg";
import delivered from "@/modules/protez/assets/delivered.svg";
import approve from "@/modules/protez/assets/approve.svg";
const activeIndex = ref(null);
defineProps(["currentCargoPage"]);
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
  } else {
    activeIndex.value = index;
  }
};
watch(
  () => cargoNavbarTitle.value,
  (newVal) => {
    activeIndex.value = null;
  }
);

const filteredOrders = () => {
  switch (cargoNavbarTitle.value) {
    case 0:
      return tempOrdersData;
    case 1:
      return tempOrdersData.filter(
        (order) => order.kargoBilgileri.kargoDurumu === 0
      );
    case 2:
      return tempOrdersData.filter(
        (order) => order.kargoBilgileri.kargoDurumu === 1
      );
  }
};
</script>
