<template>
  <div class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col">
    <div
      class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
    >
      <div class="flex items-center w-12">
        <p class="font-medium"></p>
      </div>
      <div class="h-[80%] w-[350px] flex justify-center items-center">
        <p class="font-medium">ANAMNEZ</p>
      </div>
      <div class="h-[80%] w-[350px] flex justify-center items-center">
        <p class="font-medium">OLUŞTURAN HEKİM</p>
      </div>
      <div class="h-[80%] w-[350px] flex justify-center items-center">
        <p class="font-medium">BİRİM</p>
      </div>
      <div class="h-[80%] w-[350px] flex justify-center items-center">
        <p class="font-medium">OLUŞTURULMA TARİHİ</p>
      </div>
      <div class="h-[80%] w-[200px] flex justify-center items-center">
        <p class="font-medium"></p>
      </div>
    </div>
    <div
      v-for="(anamnez, index) in anamneses"
      class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
      :key="index"
    >
      <div class="flex flex-col w-12">
        <input type="checkbox" name="" id="" class="ml-auto rounded w-6" />
      </div>
      <div class="h-[80%] w-[350px] flex gap-1 justify-center items-center">
        <button @click="favAnamnesis(anamnez)" class="mb-1">
          <img
            v-if="anamnez.attributes.favorite === false"
            :src="star"
            alt="star"
          />
          <img v-else :src="starYellow" alt="" />
        </button>
        <p>{{ anamnez.attributes.template_name }}</p>
      </div>
      <div class="h-[80%] w-[350px] flex flex-col justify-center items-center">
        <p>{{ anamnez.attributes.doctor_name }}</p>
      </div>
      <div class="h-[80%] w-[350px] flex flex-col justify-center items-center">
        <p>
          {{
            _.startCase(
              _.toLower(anamnesisStore.anamnesesUnitScreenUnitDatas?.birim_adi)
            )
          }}
        </p>
      </div>
      <div
        class="h-[80%] w-[350px] flex justify-center items-center text-center"
      >
        <p>
          {{ moment(anamnez.attributes.created_at).utc().format("DD.MM.YYYY") }}
        </p>
      </div>
      <div class="h-[80%] w-[200px] flex flex-col justify-center items-center">
        <div
          class="ml-12 focus:outline-double relative group focus:outline-1 focus:outline-[#277dd2] mt-2 w-fit px-2"
        >
          <img :src="moreDotsIcon" alt="moreDots" />
          <div
            class="text-sm text-left absolute z-[100] text-white hidden group-focus-within:block group-hover:block right-0 py-1 rounded-lg"
          >
            <button
              @click="
                router.push(
                  `/polyclinic/anamneses/${encodeURIComponent(
                    unitID
                  )}/read-anamnesis/${anamnez.id}`
                )
              "
              class="text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
            >
              Görüntüle
            </button>
            <button
              @click="
                router.push(
                  `/polyclinic/anamneses/${encodeURIComponent(
                    unitID
                  )}/update-anamnesis/${anamnez.id}`
                )
              "
              class="text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
            >
              Düzenle
            </button>
            <button
              @click="deleteAnamnesis(anamnez.id)"
              class="text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
            >
              Sil
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import moment from "moment";
import _ from "lodash";
import moreDotsIcon from "@/modules/protez/assets/moreDots.svg";
import star from "@/modules/anamnezForm/assets/star.svg";
import starYellow from "@/modules/anamnezForm/assets/starYellow.svg";
import { useRouter } from "vue-router";
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();
const anamneses = ref(null);
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();

onMounted(async () => {
  const res = await anamnesisTemplateStore.listUnitsAnamneses(unitID);
  anamneses.value = res.anamneses;
  console.log("sa", anamneses.value);
});

const favAnamnesis = async (anamnesis) => {
  anamnesis.attributes.favorite === true
    ? (anamnesis.favorite = "false")
    : (anamnesis.favorite = "true");
  await anamnesisTemplateStore.updateAnamnesisTemplate(anamnesis);
  anamnesis.attributes.favorite = !anamnesis.attributes.favorite;
};

const deleteAnamnesis = async (anamnesisID) => {
  await anamnesisTemplateStore.deleteAnamnesisTemplate(anamnesisID);
  anamneses.value = anamneses.value.filter(
    (anamnez) => anamnez.id !== anamnesisID
  );
};
</script>
