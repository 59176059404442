<template>
  <div
    v-if="patientStoreData.patientSessionActiveToken"
    class="flex justify-center items-center h-[100vh]"
  >
    <Vue3Lottie :animationData="loading" :height="200" :width="200" />
  </div>
  <div
    v-if="patientStoreData.patientSessionActiveToken"
    class="flex justify-center items-center"
  >
    <loginErrorPopUp userType="patient" />
  </div>
  <div>
    <div
      class="flex flex-col h-full justify-center items-center"
      v-if="!patientStoreData.patientSessionActiveToken"
    >
      <form action="" class="flex h-full">
        <div class="m-4 my-auto sm:flex w-1/2 flex-col items-center hidden">
          <img
            :src="dentalKutahya"
            alt="kütahyaüniversitesilogo"
            class="w-3/5"
          />
          <p class="m-2 text-center text-4xl font-medium">
            KÜTAHYA SAĞLIK BİLİMLERİ
          </p>
          <p class="m-2 text-center text-4xl font-medium">ÜNİVERSİTESİ</p>
          <p class="m-2 flex text-center text-4xl font-medium">
            AĞIZ DİŞ SAĞLIĞI UYGULAMA
          </p>
          <p class="m-2 text-center text-4xl font-medium">
            VE ARAŞTIRMA MERKEZİ
          </p>
        </div>
        <div class="mt-10 h-[90vh] border-l-4 hidden sm:block"></div>
        <div class="m-auto justify-center items-center flex flex-col">
          <p class="flex text-4xl font-medium justify-center">
            Online Randevu Sistemi
          </p>
          <div class="border-b-2 w-full"></div>
          <br />
          <div class="flex flex-col items-start justify-start w-[85%]">
            <p class="my-2 flex text-2xl font-medium justify-center">
              Tc Kimlik No<span style="color: red">*</span>
            </p>
            <!-- {
    "tc_id_number": "10774881040",
    "dob": "1929-07-01",
    "phone_number": "05469116156"
} -->
            <input
              class="focus:shadow-outline w-full md:w-[400px] appearance-none rounded border py-2 px-3 font-light leading-tight text-gray-700 shadow focus:outline-none"
              id="username"
              v-model="idnumber"
              type="text"
              minlength="11"
              maxlength="11"
              placeholder="12345678900"
              required
            />
            <p class="my-2 flex text-2xl font-medium">
              Doğum Tarihi <span style="color: red">*</span>
            </p>
            <input
              class="focus:shadow-outline w-full md:w-[400px] appearance-none rounded border py-2 px-3 font-light leading-tight text-gray-700 shadow focus:outline-none"
              id="username"
              v-model="dateofbirth"
              type="date"
              placeholder="GG.AA.YY"
              required
            />

            <p class="my-2 flex text-2xl font-medium">
              Telefon No<span style="color: red">*</span>
            </p>
            <input
              class="focus:shadow-outline w-full md:w-[400px] appearance-none rounded border py-2 px-3 font-light leading-tight text-gray-700 shadow focus:outline-none"
              id="username"
              v-model="phonenumber"
              type="text"
              placeholder="5XXXXXXXXX"
              minlength="10"
              maxlength="10"
              required
            />

            <p class="text-l my-4 flex font-normal md:w-[390px]">
              Randevu Alabilmek için lütfen telefon numaranızı doğrulayın.
            </p>
            <label for="" class="mt-2 mb-8 flex items-center">
              <input
                class="flex w-4 items-center rounded border-none"
                type="checkbox"
                v-model="isChecked"
                @click="checkboxSelect()"
              />
              <span
                class="text-l ml-4 flex items-center font-normal text-gray-400"
                >Uyarı metnini okudum, kabul ediyorum.
              </span>
            </label>
          </div>
          <button
            @click="getSmsCode()"
            :class="
              phonenumber.length == 10 && isChecked === true
                ? 'focus:shadow-outline  w-5/6 rounded bg-[#0C53FC] py-2 px-4 font-medium text-white shadow hover:bg-blue-400 focus:outline-none'
                : 'focus:shadow-outline  w-5/6 cursor-not-allowed rounded bg-[#C9C9C9] py-2 px-4 font-medium text-[#9E9E9E] shadow focus:outline-none'
            "
            type="button"
            :disabled="isChecked === false && phonenumber.length !== 10"
          >
            Onay Kodunu Gönder
          </button>
        </div>
      </form>
      <div class="container flex">
        <div
          :class="popupLoginRef ? 'visible' : 'invisible'"
          class="popupLogin open-popupLogin flex flex-col items-center justify-center border-2"
        >
          <button
            class="text-[#D9534F] flex mt-3 rounded-full ml-auto"
            type="button"
            @click="closepopupLogin()"
          >
            <p
              class="text-[#D9534F] border-2 border-[#D9534F] rounded-full w-full h-full px-[7px]"
            >
              X
            </p>
          </button>

          <h2
            class="font-roboto mt-4 flex h-10 justify-center text-2xl font-bold"
          >
            Telefon Doğrulama
          </h2>

          <img :src="loginPhone" class="" alt="" />
          <div class="flex flex-col">
            <p class="font-roboto mt-2 text-xl font-medium text-[#263445]">
              {{ phonenumber }}
            </p>
            <p
              class="nunitoFontFamily mt-4 text-base font-normal text-[#87949E]"
            >
              numaralı telefona kod gönderildi.
            </p>
            <div class="nunitoFontFamily mt-1 flex items-center justify-center">
              <input
                v-for="(digit, index) in numDigits"
                :key="index"
                type="tel"
                class="inputNumber mx-2 flex h-8 w-8 flex-row items-center justify-center text-center"
                maxlength="1"
                v-model="digits[index]"
                @input="focusNextInput(index)"
                @keydown.backspace.prevent="deleteDigit(index)"
                :ref="'digitInput' + index"
              />
            </div>
            <p
              id="countdown"
              class="nunitoFontFamily mt-5 text-base font-normal text-[#4198F7]"
            >
              {{ counter }}
            </p>
          </div>
          <button
            class="flex h-[60px] w-[400px] items-center justify-center rounded-[10px] bg-[#4198F7] text-xl font-bold text-white mt-5"
            type="button"
            @click="oryHandleLogin"
          >
            Giriş Yap
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import moment from "moment";
import loading from "../../../../assets/loading.json";
// import dentalKutahya from "../../../../../../assets/logo-kütahya-sağlık-bilimleri-üniversitesi-1.png";
import loginPhone from "../../../../../../assets/loginPhone.svg";
import { ref } from "vue";
import { GetSms } from "../../../../../../services/admin/OTP/getSmsCode";
import { patientAuthActions } from "@/store/modules/administratorManagementStore/patientAuth/patientAuthActions.ts";
import { globalStore } from "../../../.././../../store/modules/globalStore.ts";
import loginErrorPopUp from "../../../../components/loginErrorPopUp";
import { UseSearchPatientStore } from "@/store/modules/operationsStore/patient/useSearchPatientStore";
import { patientStore } from "@/store/modules/administratorManagementStore/patient";
import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
import { userInfo } from "@/modules/medula/addPatient/components/userInfoReactiveTypes";
import { useAddPatientStore } from "@/store/modules/operationsStore/patient/useAddPatientStore.ts";
const addPatientStore = useAddPatientStore();
const patient = addPatientStore.addPatientRequest;

const bilesikKisiStore = bilesikKisiActions();
const patientStoreData = patientStore();
const patientStoreAuthActions = patientAuthActions();
const searchPatientStore = UseSearchPatientStore();
let popupLoginRef = ref(null);
let idnumber = ref("");
let dateofbirth = ref("");
let phonenumber = ref("");
const counter = ref(90);
let isChecked = ref(false);

const numDigits = 6;
const digits = ref(Array(numDigits).fill(""));
const currentInputIndex = ref(numDigits - 1);

// patientStoreAuthActions.checkPatientSessionToken();

const checkboxSelect = () => {
  isChecked.value = !isChecked.value;
};

const getSmsCode = async () => {
  if (
    idnumber.value &&
    dateofbirth.value &&
    phonenumber.value &&
    isChecked.value === true
  ) {
    popupLoginRef.value = true;
    let timerIntervalID = setInterval(() => {
      counter.value--;
      if (counter.value <= 0 || popupLoginRef.value == false) {
        closepopupLogin();
        popupLoginRef.value = false;
        clearInterval(timerIntervalID);
      }
    }, 1000);
    console.log("ds", dateofbirth.value);

    await GetSms.getSmsCode(
      idnumber.value,
      moment(dateofbirth.value).format("YYYY-MM-DD"),
      phonenumber.value
    );
  }
};
const closepopupLogin = () => {
  popupLoginRef.value = false;
  idnumber.value = "";
  dateofbirth.value = "";
  phonenumber.value = "";
  digits.value = Array(numDigits).fill("");
  counter.value = 90;
};

const oryHandleLogin = async () => {
  if (idnumber.value && dateofbirth.value && phonenumber.value) {
    console.log(dateofbirth.value);
    await patientStoreAuthActions.login({
      idNumber: idnumber.value,
      dateOfBirth: dateofbirth.value,
      phoneNumber: phonenumber.value,
      code: digits.value.join(""),
    });
    if (patientStoreData.patient.accessToken) {
      // personelStoreData.patient.patientID=
      const patientStoreDataJson = JSON.stringify({
        accessToken: patientStoreData.patient.accessToken,
      });
      localStorage.setItem("patientStoreData", patientStoreDataJson);

      const searchedPatient = await searchPatientStore.searchPatient(
        idnumber.value
      );
      console.log(searchedPatient);
      if (searchedPatient.length > 0) {
        patientStoreData.patient.patientID = searchedPatient[0].id;
      } else {
        await bilesikKisiStore.kpsBilesikKisiSorgulama(idnumber.value);
        const dateObj = new Date(userInfo.dateOfBirth);
        const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getUTCDate().toString().padStart(2, "0");
        const hours = dateObj.getUTCHours().toString().padStart(2, "0");
        const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");

        const outputDate = `${dateObj.getUTCFullYear()}-${month}-${day}T${hours}:${minutes}:00Z`;
        patient.tc_identity_number = userInfo.tcNo;
        patient.name = userInfo.userName;
        patient.surname = userInfo.userSurname;
        patient.mother_name = userInfo.nameOfMother;
        patient.father_name = userInfo.nameOfFather;
        patient.dob = outputDate;
        patient.declared_dob = outputDate;
        patient.birthplace_province = userInfo.ilButtonDropDownSelectedValue;
        patient.birthplace_district = userInfo.ilceButtonDropDownSelectedValue;
        // patient.KayitZamani = outputDate;
        // patient.GuncellemeZamani.Time = outputDate;
        patient.gender = userInfo.gender === "Erkek" ? "erkek" : "kadın";
        patient.marital_status =
          userInfo.maritalStatus === "Bekâr"
            ? "bekar"
            : userInfo.maritalStatus === "Evli"
            ? "evli (resmen evli ya da birlikte yaşıyor)"
            : "";
        patient.province = userInfo.adresIlButtonDropDownSelectedValue;
        patient.district = userInfo.adresIlceButtonDropDownSelectedValue;

        patient.address_description = userInfo.adress;
        patient.phone_number = userInfo.phoneNumber;
        patient.home_phone_number = userInfo.homeNumber;
        patient.work_phone_number = userInfo.workNumber;
        patient.email = userInfo.email;
        await addPatientStore.addPatient(patient);
        console.log("ss", addPatientStore.addPatientResponse);
        patientStoreData.patient.patientID =
          addPatientStore.addPatientResponse.data.id;
      }
    }
    const patientStoreDataJson = JSON.stringify({
      accessToken: patientStoreData.patient.accessToken,
      patientID: patientStoreData.patient.patientID,
    });
    localStorage.setItem("patientStoreData", patientStoreDataJson);
  } else {
    alert("bilgileri kontrol edin");
  }
};

function focusNextInput(index) {
  if (index < digits.value.length - 1) {
    const nextInput = event.target.nextElementSibling;
    if (nextInput) {
      nextInput.focus();
    }
  }
}

function focusPreviousInput(index) {
  const previousInput = document.querySelector(`#digitInput${index - 1}`);
  if (previousInput) {
    previousInput.focus();
  }
}
function deleteDigit(index) {
  digits.value[index] = ""; // mevcut digit'i sil
  const previousInput = event.target.previousElementSibling;
  if (previousInput !== null) {
    previousInput.value = "";
    previousInput.focus();
  }
}

// Input kutusuna basıldığında, currentInputIndex değişkenini güncelle
function updateCurrentInputIndex(index) {
  currentInputIndex.value = index;
}
</script>

<style scoped>
* {
  font-family: "Roboto", sans-serif;
}
.container {
  display: flex;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
}
.popupLogin {
  width: 493px;
  height: 512px;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.1);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  visibility: hidden;
  transition: transform 0.4s, top 0.4s;
}
.open-popupLogin {
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
}
.inputNumber {
  border: 2px solid;
  border-color: grey;
  border-radius: 10px;
  color: #4198f7;
}
.inputNumber:focus {
  border-color: #4198f7 !important;
}
.nunitoFontFamily {
  font-family: "Nunito", sans-serif;
}
.text-red-600 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: red;
  background-color: #e53e3e;
  font-size: 16px;
  font-weight: bold;
  margin-top: -4px;
  margin-right: 4px;
}
button.text-red-600 {
  justify-content: center;
}

button.text-red-600::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid red;
  z-index: -1;
}
</style>
