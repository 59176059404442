import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";

export const useDailyAppointmentsStore = defineStore({
  id: "dailyAppointmentsStore",
  state: () => ({
    listDailyAppointmentsResponse: null as null,
    paginationListDailyAppointments: [],
  }),
  actions: {
    async listDailyAppointments(
      page: number,
      page_size: number,
      klinikKodu: string
    ) {
      await axios
        .get(
          `${clinicDomain}/vem-appointments/daily?page=${page.toString()}&page_size=${page_size.toString()}&klinik_kodu=${klinikKodu}`,
          {
            headers: {
              Authorization: patientAuthToken,
              //AcceptLanguage:--
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("gunluk randevu cekiyorum", response.data);
            this.listDailyAppointmentsResponse = response.data;
          }
        });
    },
  },
});
