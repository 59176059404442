import { defineStore } from "pinia";
import {
  AddPatientApplicationRequest,
  addPatientApplicationRequest,
  UpdatePatientApplicationRequest,
} from "@/types/operations/patientApplications/addPatientApplicationRequest";
import { AddPatientApplicationResponse } from "@/types/operations/patientApplications/addPatientApplicationResponse";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { personelStore } from "../../administratorManagementStore/personel";

import { patientAuthToken } from "@/common/token";

export const updatePatientApplicationStore = defineStore({
  id: "updatePatientApplication",
  state: () => ({}),
  actions: {
    async updatePatientApplication(
      updatePatientApplicationReq: UpdatePatientApplicationRequest
    ) {
      try {
        const personelStoreData = personelStore();
        const response = await axios.put(
          `${clinicDomain}/patient-applications`,
          updatePatientApplicationReq,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("updatePatientApplication", response.data);
        // return response.data.data;
      } catch (error) {
        console.error("Error in updatePatientApplication:", error);
      }
    },

    // async updatePatientApplication(
    //   id: string,
    //   updatePatientApplicationRequest: AddPatientApplicationRequest
    // ) {
    //   await axios
    //     .put(
    //       `${clinicDomain}/patients/applications/update/${id}`,
    //       {
    //         ...updatePatientApplicationRequest, //buraları düzelt
    //       },
    //       {
    //         headers: {
    //           Authorization: patientAuthToken,
    //           //AcceptLanguage:--
    //         },
    //         withCredentials: true,
    //       }
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         console.log(
    //           "guncellemeye yollanan",
    //           updatePatientApplicationRequest
    //         );
    //         console.log(response.data, "updated");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("guncellemeye yollanan", updatePatientApplicationRequest);
    //       console.log(err);
    //     });
    // },
  },
});
