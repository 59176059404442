<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">SİPARİŞ ID</th>
        <th class="text-xl w-[250px] text-center">HASTA ADI</th>
        <th class="text-xl w-[250px] text-center">TEDAVİ ADI</th>
        <th class="text-xl w-[200px] text-center">KURUM ADI</th>
        <th class="text-xl w-[350px] text-center">OLUŞTURULMA TARİHİ</th>
        <th class="text-xl w-[200px] text-center">TESLİM TARİHİ</th>
        <th class="text-xl w-[450px] text-center">TEKNİSYEN</th>
        <!-- v-if="!popUpManagement.detailPopUp" -->
        <div class="my-auto w-12">
          <th></th>
        </div>
        <!-- <div v-if="popUpManagement.detailPopUp" class="my-auto">
                <th></th>
              </div> -->
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(order, index) in filteredOrders.slice(
          (currentOrderPage - 1) * 7,
          currentOrderPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px] bg-white"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
            class="bg-white mt-1"
          />
        </td>
        <td class="w-[200px] text-center relative">
          {{ order.siparisID }}
        </td>
        <td class="w-[250px] text-center">
          {{ order.hastaAdi }}
        </td>
        <td class="w-[250px] text-center">
          {{ order.tedaviAdi }}
        </td>

        <td class="w-[200px] text-center">
          <p>
            {{ order.kurumAdi }}
          </p>
        </td>
        <td class="w-[350px] text-center">
          {{ order.olusturulmaTarihi }}
        </td>

        <td class="w-[200px] text-center">
          {{ order.teslimTarihi }}
        </td>
        <td class="w-[450px] text-center">
          <div>
            <img src="" alt="" />
            <p v-if="order.teknisyen">{{ order.teknisyen }}</p>
            <div v-if="!order.teknisyen">
              <button
                class="focus:outline-none mx-auto gap-3 rounded-lg flex items-center justify-center py-1 px-2 shadow text-white bg-[#277DD2]"
              >
                <img :src="plus" alt="plus" />
                <p>Teknisyen ekle</p>
              </button>
            </div>
          </div>
        </td>
        <div>
          <td>
            <div
              class="cursor-pointer mt-2 w-fit px-2"
              @click="
                ordersPopUpManagement.detailPopUp = true;
                activeIndex = index;
              "
            >
              <img :src="moreDotsIcon" alt="moreDots" />
            </div>
          </td>
        </div>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from "vue";
import {
  tempData,
  tempOrdersData,
  popUpManagement,
  ordersPopUpManagement,
} from "@/modules/protez/store/temporaryProtezData.js";
import plus from "@/modules/protez/assets/plusWhite.svg";
import moreDotsIcon from "@/modules/protez/assets/moreDots.svg";
const activeIndex = ref(null);
defineProps(["currentOrderPage"]);
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
  } else {
    activeIndex.value = index;
  }
};
const filteredOrders = tempOrdersData.filter(
  (order) => order.durum === "Onaylanan"
);
</script>
