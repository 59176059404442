<template>
  <aside
    class="fixed border z-20 bg-white text-[#464e5f] rounded-md py-2 px-4 top-[73px] right-0 w-[700px] h-full transition-all duration-500"
    :class="
      globalStore.isCreateAppointmentSideBarOpen
        ? 'translate-x-0'
        : 'translate-x-full'
    "
  >
    <div class="flex justify-between items-center mb-3">
      <h4 class="font-medium text-xl">
        {{
          globalStore.isUpdating ? "Randevuyu Güncelle" : "Yeni Randevu Oluştur"
        }}
      </h4>
      <button
        @click="globalStore.isCreateAppointmentSideBarOpen = false"
        class="rounded-full focus:outline-double focus:outline-1 focus:outline-[#277dd2] border-[1px] border-[#A3B2B6] w-10 h-10 flex items-center justify-center"
      >
        <img class="w-8" :src="cross" alt="" />
      </button>
    </div>
    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-1">
        <p class="font-medium">Birim</p>
        <div class="appointmentSidebarSelect">
          <Select2
            :options="unitListStore.getListUnitsResponse"
            @select="chooseBirim"
            placeholder="Seçiniz"
            v-model="appointmentBookStore.unit_code"
            :disabled="doctorScreen || clinicScreen"
          />
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <p class="font-medium">Doktor</p>
        <div class="appointmentSidebarSelect">
          <Select2
            :options="appointmentBookStore.filteredDoctors"
            @select="chooseDoctor"
            placeholder="Seçiniz"
            v-model="appointmentBookStore.doctor_code"
            :disabled="doctorScreen"
          />
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <p class="font-medium">Randevu Tipi</p>
        <div class="appointmentSidebarSelect withoutInput">
          <Select2
            :options="[
              {
                id: 'normal_randevu',
                text: 'Normal Randevu',
                typeID: 'normal_randevu',
              },
              {
                id: 'mesai_disi_ogretim_uyesi',
                text: 'Mesai Dışı Öğretim Üyesi Randevu',
                typeID: 'mesai_disi_ogretim_uyesi',
              },
              {
                id: 'implant_estetik_dis_klinigi',
                text: 'İmplant Estetik Diş Kliniği',
                typeID: 'implant_estetik_dis_klinigi',
              },
            ]"
            @select="chooseAppointmentType"
            placeholder="Seçiniz"
            v-model="appointmentBookStore.appointmentType"
          />
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <p class="font-medium">Hasta</p>
        <div
          class="relative rounded-[10px]"
          @focusin="appointmentBookStore.searchPatientSearchBarFocus = true"
          @focusout="handleFocusOut"
        >
          <div
            class="border-[1px] border-[#a3b2b6] focus-within:border-none bg-white rounded-[10px] font-medium"
          >
            <form class="rounded-[10px]">
              <input
                type="text"
                class="w-full px-2 py-[13px] rounded-[10px] placeholder:text-[#999999] focus:outline-none focus:placeholder:text-white focus:text-white focus:bg-[#a3b2b6]"
                placeholder="Hasta ara"
                @input="handleInputChange"
                v-model="appointmentBookStore.searchPatientInput"
              />
            </form>
          </div>
          <div
            v-if="
              appointmentBookStore &&
              appointmentBookStore.searchPatientInput &&
              appointmentBookStore.searchPatientInput.length > 4 &&
              appointmentBookStore.searchPatientSearchBarFocus
            "
            class="absolute p-2 border shadow w-full z-40 right-0 top-[56px] rounded-[10px] bg-[#fbfbfb] flex flex-col gap-2"
          >
            <!-- true -->
            <div
              v-if="true"
              class="flex flex-col gap-4 max-h-[250px] overflow-y-auto"
              ref="resultsContainer"
            >
              <!-- placeholder -->
              <div
                v-for="(
                  patient, index
                ) in appointmentBookStore.medulaSearchPatientListArray"
                :key="patient + index - index"
                class="bg-[#f1f2f3] rounded p-2 flex"
                @mousedown="selectPatient(patient)"
              >
                <img :src="profileCircle" alt="profile photo" class="w-8 h-8" />
                <div class="ml-2">
                  <p class="font-medium">
                    {{ patient.attributes.name }}
                    {{ patient.attributes.surname }}
                  </p>
                  <p class="my-1">
                    {{ patient.attributes.tc_identity_number }}
                  </p>
                </div>
              </div>
              <!-- placeholder -->
            </div>
            <p v-if="false" class="ml-auto text-[#333333]">
              <span class="font-semibold">89</span> sonuç bulundu.
            </p>

            <!-- false -->
            <div
              class="text-center"
              v-if="
                appointmentBookStore.medulaSearchPatientListArray.length < 1
              "
            >
              <h4 class="font-bold text-[#323232]">BİLGİLENDİRME</h4>

              <p class="font-medium text-[#D9534F] my-2">
                Bu TC Numarasına Ait Kayıt Bulunamamaktadır
              </p>
              <!-- @focus="medulaStore.searchPatientButtonFocus = true"
                @blur="medulaStore.searchPatientButtonFocus = false"
                bunlari keyboard navigationda kullanmayi surdur  -->
              <button
                @mousedown="routeCreateNewPatient"
                class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] border-[1px] py-2 px-7 rounded-[10px] border-[#277DD2] text-[#277DD2] font-semibold"
              >
                Yeni Kayıt Oluştur
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <p class="font-medium">Tarih</p>

        <div class="appointmentSidebarDatePicker">
          <selectDateWithDatePicker />
          <!-- <VueDatePicker
            v-model="appointmentBookStore.selectedDate"
            locale="tr"
            auto-apply
            highlight-disabled-days
            format="dd/MM/yyyy"
            :enable-time-picker="false"
            :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
            placeholder="Seçiniz"
          /> -->
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <p class="font-medium">Saat</p>
        <div class="h-36 overflow-auto">
          <button
            v-for="(
              slot, index
            ) in searchFreeSlotsForClinicStore.freeSlotsResponse"
            :key="index"
            class="border-[1px] border-[#a3b2b6] mb-2 font-medium rounded-[10px] mr-1 py-2 focus:outline-[#277dd2] focus:outline-1 w-[100px]"
            :class="
              slot.id === appointmentBookStore.slot_id
                ? 'bg-[#5CB85C] text-white'
                : 'bg-white text-[#464E5F]'
            "
            @click="appointmentBookStore.slot_id = slot.id"
          >
            {{ slot.attributes.start_time.split(" ")[1].slice(0, 5) }}&nbsp;
            {{ slot.attributes.end_time.split(" ")[1].slice(0, 5) }}
          </button>
        </div>
      </div>
      <button
        @click="addAppointment"
        class="py-[13px] font-medium rounded-[10px] focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
        :class="
          appointmentBookStore.selectedPatientID && appointmentBookStore.slot_id
            ? 'bg-[#277dd2] text-white'
            : 'bg-[#F7F9FB] text-[#A3B2B6] cursor-not-allowed'
        "
        :disabled="
          !appointmentBookStore.selectedPatientID ||
          !appointmentBookStore.slot_id
        "
      >
        Randevu Oluştur
      </button>
    </div>
  </aside>
</template>

<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import Select2 from "vue3-select2-component";
import selectDateWithDatePicker from "./calendar/selectDateWithDatePicker.vue";
import { useAppointmentBook } from "./appointmentBookStore";
import { UseSearchPatientStore } from "@/store/modules/operationsStore/patient/useSearchPatientStore";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useAddVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useAddVEMAppointment";
import { useUpdateVEMAppointmentStore } from "@/store/modules/operationsStore/appointment/vem-appointment/useUpdateVEMAppointment";
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import { useGetDoctorsByUnitStore } from "@/store/modules/administratorManagementStore/staff/getDoctorsByUnit";

const props = defineProps({
  doctorScreen: Boolean,
  clinicScreen: Boolean,
});

const staffListStore = useGetStaffList();

import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();

const appointmentBookStore = useAppointmentBook();
const getPatientStore = useGetPatientStore();
const searchPatientStore = UseSearchPatientStore();
const addAppointmentStore = useAddVEMAppointment();
const updateAppointmentStore = useUpdateVEMAppointmentStore();

//asset imports
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import errorRed from "@/modules/medula/provision/assets/errorRed.svg";
import cross from "@/mainPage/assets/cross.svg";
import { showSidebar } from "@/modules/asistanEkrani/components/navbarStore";
import { ref, onMounted, watch } from "vue";
import { globalStore } from "@/store/modules/globalStore";
import moment from "moment";
import { useRouter } from "vue-router";

const router = useRouter();
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic.ts";
import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";

const searchFreeSlotsForClinicStore = SearchFreeSlotsforClinic();
const bilesikKisiStore = bilesikKisiActions();
const doctorsByUnitStore = useGetDoctorsByUnitStore();

onMounted(async () => {
  if (props.doctorScreen && appointmentBookStore.filteredDoctors) {
    return false;
  }
  if (appointmentBookStore.unit_code === "") {
    appointmentBookStore.filteredDoctors = await staffListStore.getStaffList();
  } else {
    appointmentBookStore.filteredDoctors =
      await doctorsByUnitStore.getDoctorsByUnit(appointmentBookStore.unit_code);
  }
});

watch(
  () => searchFreeSlotsForClinicStore.calendarSelectedEndDate,
  async (newValue, oldValue) => {
    // appointmentBookStore.unit_code = "";
    // appointmentBookStore.doctor_code = "";
    appointmentBookStore.appointmentType = "";
    await searchFreeSlotsForClinicStore.SearchFreeSlotsforClinic(
      searchFreeSlotsForClinicStore.calendarSelectedStartDate,
      searchFreeSlotsForClinicStore.calendarSelectedEndDate,
      appointmentBookStore.unit_code,
      appointmentBookStore.doctor_code,
      appointmentBookStore.appointmentType
    );
    if (globalStore.isUpdating) {
      return false;
    }
    appointmentBookStore.searchPatientInput = "";
    appointmentBookStore.selectedPatientID = null;
    appointmentBookStore.slot_id = "";
  }
);

watch(
  () => appointmentBookStore.doctor_code,
  async (newValue, oldValue) => {
    if (!props.doctorScreen) {
      await searchFreeSlotsForClinicStore.SearchFreeSlotsforClinic(
        searchFreeSlotsForClinicStore.calendarSelectedStartDate,
        searchFreeSlotsForClinicStore.calendarSelectedEndDate,
        appointmentBookStore.unit_code,
        appointmentBookStore.doctor_code,
        appointmentBookStore.appointmentType
      );
      appointmentBookStore.slot_id = "";
    }
  }
);

watch(
  () => appointmentBookStore.appointmentType,
  async (newValue, oldValue) => {
    await searchFreeSlotsForClinicStore.SearchFreeSlotsforClinic(
      searchFreeSlotsForClinicStore.calendarSelectedStartDate,
      searchFreeSlotsForClinicStore.calendarSelectedEndDate,
      appointmentBookStore.unit_code,
      appointmentBookStore.doctor_code,
      appointmentBookStore.appointmentType
    );
    appointmentBookStore.slot_id = "";
  }
);

// watch(
//   () => appointmentBookStore.unit_code,
//   async (newValue, oldValue) => {
//     if (!props.doctorScreen) {
//       await searchFreeSlotsForClinicStore.SearchFreeSlotsforClinic(
//         searchFreeSlotsForClinicStore.calendarSelectedStartDate,
//         searchFreeSlotsForClinicStore.calendarSelectedEndDate,
//         appointmentBookStore.unit_code,
//         appointmentBookStore.doctor_code,
//         appointmentBookStore.appointmentType
//       );
//       appointmentBookStore.slot_id = "";
//       if (newValue === "") {
//         appointmentBookStore.filteredDoctors =
//           await staffListStore.getStaffList();
//       } else {
//         appointmentBookStore.filteredDoctors =
//           await doctorsByUnitStore.getDoctorsByUnit(newValue);
//       }
//     }
//   }
// );

const addAppointment = async () => {
  if (globalStore.isUpdating) {
    await updateAppointmentStore.updateVEMAppointment(
      appointmentBookStore.updatingAppointment,
      appointmentBookStore.selectedPatientID,
      appointmentBookStore.slot_id
    );
    return true;
  }
  await addAppointmentStore.addVemAppointment(
    appointmentBookStore.selectedPatientID,
    appointmentBookStore.slot_id
  );
};

const handleFocusOut = () => {
  setTimeout(() => {
    if (!appointmentBookStore.searchPatientButtonFocus) {
      appointmentBookStore.searchPatientSearchBarFocus = false;
    }
  }, 0);
};

const handleInputChange = async () => {
  appointmentBookStore.searchPatientInput =
    appointmentBookStore.searchPatientInput.replace(/[^0-9]/g, "");
  appointmentBookStore.selectedPatientID = null;
  if (
    appointmentBookStore.searchPatientInput.length < 5 ||
    appointmentBookStore.searchPatientInput.length > 11
  ) {
    return false;
  }
  appointmentBookStore.medulaSearchPatientListArray =
    await searchPatientStore.searchPatient(
      appointmentBookStore.searchPatientInput
    );
  console.log(appointmentBookStore.medulaSearchPatientListArray);
};

// watch(
//   () => appointmentBookStore.searchPatientInput,
//   (newValue, oldValue) => {
//     handleInputChange();
//   }
// );

const selectPatient = async (patient) => {
  console.log(patient);
  appointmentBookStore.selectedPatientID = patient.id;
  appointmentBookStore.searchPatientInput =
    patient.attributes.tc_identity_number;
};

const chooseDoctor = (doktor) => {
  console.log(doktor);
  // appointmentBookStore.doctor_code = doktor.personel_kodu;
};

const chooseAppointmentType = (appointment) => {
  console.log(appointment);
  // appointmentBookStore.appointmentType = appointment.typeID;
};

const chooseBirim = (birim) => {
  console.log("ee", birim);
  // appointmentBookStore.unit_code = birim.birim_kodu;
  console.log(appointmentBookStore.unit_code);
};

const routeCreateNewPatient = () => {
  // e.target.blur();
  router.push("/assistantScreen/addPatient");
  bilesikKisiStore.kpsBilesikKisiSorgulama(
    appointmentBookStore.searchPatientInput
  );
  console.log("kps");
};

const handleKeyDown = (e) => {
  //   //buna sonuçlar varsa diye bir şart eklenmesi gerekiyor ve her input gerçekleştiğinde index 0lanmalı
  //   if (e.key === "ArrowUp" && medulaStore.searchResultsSelectedIndex > 0) {
  //     e.preventDefault();
  //     medulaStore.searchResultsSelectedIndex--;
  //   } else if (
  //     e.key === "ArrowDown" &&
  //     medulaStore.searchResultsSelectedIndex < 3
  /* hardcoded value for now, will be changed with results length */
  //   ) {
  //     medulaStore.searchResultsSelectedIndex++;
  //   } else if (e.key >= "0" && e.key <= "9") {
  //     medulaStore.searchResultsSelectedIndex = 0;
  //   }
};

onMounted(() => {
  observeClassChanges(
    document.querySelector(".withoutInput .select2-container")
  );
});

function observeClassChanges(element) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === "class") {
        const newClasses = element.classList.toString();
        console.log("Class değişikliği tespit edildi:");
        console.log("Yeni Class:", newClasses);

        if (newClasses.includes("select2-container--open")) {
          console.log("İstediğiniz class var!");
          const dropdownElement = document.querySelector(
            ".select2-search--dropdown"
          );
          if (dropdownElement) {
            dropdownElement.style.setProperty("display", "none", "important");
            console.log("select2-search--dropdown gizlendi.");
          } else {
            console.log("select2-search--dropdown elemanı bulunamadı.");
          }
        }
      }
    });
  });

  observer.observe(element, { attributes: true });

  //   setInterval(() => {
  //     console.log(
  //       "observer'ın bağlı olduğu element var mı?",
  //       element
  //     );
  //   }, 1000);
}
</script>

<style>
.appointmentSidebarSelect .select2-selection,
.appointmentSidebarSelect .select2-selection--single {
  height: 50px !important;
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  /* color: #464e5f !important; */
  /* if disabled background-color: #F7F9FB; */
}

.appointmentSidebarSelect .select2-container--open .select2-selection--single {
  background-color: #a3b2b6;
  outline: 0;
}

/* .select2-search--dropdown {
    display: none !important;
} */

.appointmentSidebarSelect
  .select2-container--open
  .select2-selection__placeholder,
.appointmentSidebarSelect
  .select2-container--open
  .select2-selection__rendered {
  color: white !important;
}

.select2-selection__rendered {
  /* display: flex !important; */
  /* align-items: center; */
  color: #464e5f !important;
  font-weight: 500 !important;
}

.appointmentSidebarSelect .select2-selection__arrow {
  /* margin-top: 10px; */
  top: 12px !important;
  margin-right: 14px;
}

.select2-dropdown {
  border: 1px #a3b2b6 solid !important;
  border-radius: 10px !important;
}

.appointmentSidebarSelect .select2-selection__arrow b {
  border-width: 0.5em 0.4em 0.5em 0.4em !important;
  /* top: 7px !important; */
}

.appointmentSidebarSelect .select2-container--open .select2-selection__arrow {
  top: 4px !important;
}

.select2-results__option {
  font-weight: 500 !important;
  padding: 15px 10px !important;
  margin: 0px;
  border-top: 1px #a3b2b6 solid !important;
}

.select2-results__option--highlighted {
  color: white !important;
}

.appointmentSidebarDatePicker .dp__main,
.appointmentSidebarDatePicker .dp__input_wrap,
.appointmentSidebarDatePicker .dp__input {
  height: 50px;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  color: #464e5f;
}

.appointmentSidebarDatePicker .dp__input::placeholder {
  color: #999999;
  font-weight: 500;
}

.appointmentSidebarDatePicker .dp__input_wrap {
  border: 1px #a3b2b6 solid;
}

.appointmentSidebarDatePicker .dp__main:focus-within {
  background-color: #a3b2b6;
}

.appointmentSidebarDatePicker .dp__main:focus-within .dp__input {
  background-color: #a3b2b6;
  color: white;
  outline: 1px double #a3b2b6;
}

.appointmentSidebarDatePicker .dp__main:focus-within .dp__input::placeholder {
  color: white;
}

/* .select2-container--open .select2-selection__arrow b {
  background-color: white !important;
} */

/* .appointmentSidebarSelect span {
  color: red !important;
} */
</style>
