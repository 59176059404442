<template>
  <div class="border-b py-2 px-4 flex items-center justify-between">
    <button class="-z-50 bg-[#F94144] text-white py-2 px-4 rounded-3xl">
      Anamnezi sil
    </button>
    <h1 class="text-xl font-medium">
      {{
        anamnesisTemplateStore.getAnamnesisResponse?.data.attributes
          .template_name
      }}
    </h1>
    <button
      @click="deleteAnamnesis"
      class="bg-[#F94144] text-white py-2 px-4 rounded-3xl"
    >
      Anamnezi sil
    </button>
  </div>
  <div class="bg-[#f5f5f5] py-2 px-4">
    <p class="font-medium">
      <span class="opacity-70 font-normal">Anamnez Açıklaması:</span>
      -
    </p>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;
let anamnesisID = router.currentRoute.value.params.anamnesisID;

import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();

const deleteAnamnesis = async () => {
  await anamnesisTemplateStore.deleteAnamnesisTemplate(anamnesisID);
  router.push(`/polyclinic/anamneses/${encodeURIComponent(unitID)}`);
};
</script>
