<template>
  <div
    class="flex flex-col border-2 rounded-[20px] ml-3 min-w-[508px] h-[882px] border-[#D9D9D940] bg-[#F8F8F8]"
  >
    <div
      class="mt-2 w-[98%] h-[55px] bg-[#DBEAFE80] mx-auto rounded-[20px] flex items-center"
    >
      <p class="text-[#09244B] text-xl font-medium w-[130px] ml-[26px]">
        ŞABLON ADI
      </p>
    </div>
    <div class="flex mt-2">
      <div
        class="border-[1px] border-[#D5DBE1] rounded-[20px] flex w-[95%] mx-auto h-[30px] items-center bg-[#D9D9D926]"
      >
        <img
          :src="searchLogo"
          alt="searchIcon"
          class="h-[20px] pl-1 cursor-pointer"
        />
        <input
          type="text"
          v-model="radiologyRontgenStore.radiologyRontgenSearch"
          class="h-full w-full pl-1 rounded-[5px] bg-[#D9D9D926]"
          placeholder="Ara"
        />
      </div>
    </div>
    <div
      class="flex flex-col border-[1px] border-[#00000033] bg-white rounded-[10px] h-[650px] w-[95%] mx-auto overflow-auto mt-2"
    >
      <div
        class="element flex min-h-[54px] items-center"
        v-for="(item, index) in radiologyRontgenStore.radiologySablonAdiList"
        :key="index"
      >
        <p class="ml-[21px] font-medium">{{ item }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import { useRadiologyRontgenStore } from "@/modules/radiology/radiologyRontgen/store/radiologyRontgenStore";

const radiologyRontgenStore = useRadiologyRontgenStore();
</script>

<style scoped>
.element:nth-child(even) {
  background: #d9d9d926;
}
.element:nth-child(odd) {
  background: white;
}
</style>