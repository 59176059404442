import { defineStore } from "pinia";
import moment from "moment";
export const useKlinikYonetimStore = defineStore("klinikYonetimStore", {
  id: "klinikYonetimStore",
  state: () => ({
    clinicDoctors: null,
    klinikYonetimNavbar: 1,
    showAddDoctorSidebar: false,

    klinikUnitScreenUnitDatas: null,
    klinikUnitScreenDoctors: null,

    addClinicPopUp: false,

    klinikYonetimViewCardList: "Card",
    klinikYonetimHekimSearch: "",
    klinikYonetimDoctorDetailFlow: [false, false, false, false],
    klinikYonetimDoctorDetailButtonIndex: [null, null, null, null],
    klinikYonetimUnitListViewButton: false,
    klinikYonetimCheckBoxValue: [null, null, null, null, null],
    klinikYonetimUnitEditButtonIndex: -1,
    klinikYonetimDoctors: null,
    clinicalManagementUnits: [
      {
        unitName: "Unit1",
        breakdown: false,
        events: [
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]11:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]12:30:00"),
            eventTitle: "Event1",
          },
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]08:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]10:30:00"),
            eventTitle: "Event2",
          },
        ],
      },
      {
        unitName: "Unit2",
        breakdown: false,
        events: [
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]11:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]12:30:00"),
            eventTitle: "Event1",
          },
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]08:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]10:30:00"),
            eventTitle: "Event2",
          },
        ],
      },
      {
        unitName: "Unit3",
        breakdown: false,
        events: [
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]11:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]12:30:00"),
            eventTitle: "Event1",
          },
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]08:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]10:30:00"),
            eventTitle: "Event2",
          },
        ],
      },
      {
        unitName: "Unit4",
        breakdown: true,
        events: [
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]11:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]12:30:00"),
            eventTitle: "Event1",
          },
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]08:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]10:30:00"),
            eventTitle: "Event2",
          },
        ],
      },
      {
        unitName: "Unit5",
        breakdown: false,
        events: [
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]11:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]12:30:00"),
            eventTitle: "Event1",
          },
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]08:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]10:30:00"),
            eventTitle: "Event2",
          },
        ],
      },
      {
        unitName: "Unit6",
        breakdown: true,
        events: [
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]11:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]12:30:00"),
            eventTitle: "Event1",
          },
          {
            eventStartDate: moment().format("YYYY-MM-DD[T]08:30:00"),
            eventEndDate: moment().format("YYYY-MM-DD[T]10:30:00"),
            eventTitle: "Event2",
          },
        ],
      },
    ],
    klinikYonetimUnits: [
      {
        isim: "Periodontoloji",
        id: 0,
        klinik: "Periodontoloji",
        ariza: "Arizali",
      },

      {
        isim: "Unit İsim #1",
        id: 1,
        klinik: "Periodontoloji",
        ariza: "Arizali",
      },
      {
        isim: "Unit İsim #1",
        id: 2,
        klinik: "Periodontoloji",
        ariza: "Arizali",
      },
      {
        isim: "Unit İsim #1",
        id: 3,
        klinik: "Periodontoloji",
        ariza: "Arizali",
      },
      {
        isim: "Unit İsim #1",
        id: 4,
        klinik: "Periodontoloji",
        ariza: "Arizali",
      },
      {
        isim: "Ordodonti",
        id: 5,
        klinik: "Ordodonti",
        ariza: "Arizali",
      },
      {
        isim: "Ordodonti",
        id: 6,
        klinik: "Ordodonti",
        ariza: "Sorunsuz",
      },
    ],
  }),
  actions: {
    getFilteredDoctors(unitID) {
      console.log(unitID);
    },

    filteredDoctors(klinik, unvanValue = null, klinikValue = null) {
      let doctor = this.klinikYonetimDoctors.filter(
        (user) => user.klinik === klinik
      );
      if (unvanValue != null && unvanValue != "Hepsi") {
        doctor = doctor.filter((user) => user.unvan === unvanValue);
      }
      if (klinikValue != null && klinikValue != "Hepsi") {
        doctor = doctor.filter((user) => user.klinik === klinikValue);
      }
      const searchQuery = this.klinikYonetimHekimSearch.toLowerCase();
      doctor = doctor.filter((user) =>
        user.isim.toLowerCase().includes(searchQuery)
      );

      const doctorNumber = doctor.length;
      return [doctor, doctorNumber];
    },
    filteredUnits(klinik, arizaValue = null, klinikValue = null) {
      let unit = this.klinikYonetimUnits.filter(
        (user) => user.klinik === klinik
      );
      if (arizaValue != null && arizaValue != "Hepsi") {
        unit = unit.filter((user) => user.ariza === arizaValue);
      }
      if (klinikValue != null && klinikValue != "Hepsi") {
        unit = unit.filter((user) => user.klinik === klinikValue);
      }
      const searchQuery = this.klinikYonetimHekimSearch.toLowerCase();
      unit = unit.filter((user) =>
        user.isim.toLowerCase().includes(searchQuery)
      );

      const unitNumber = unit.length;
      return [unit, unitNumber];
    },
    filteredHekim(unvanValue = null, klinikValue = null) {
      let doctor = this.klinikYonetimDoctors;
      if (unvanValue != null && unvanValue != "Hepsi") {
        doctor = doctor.filter((user) => user.unvan === unvanValue);
      }
      if (klinikValue != null && klinikValue != "Hepsi") {
        doctor = doctor.filter((user) => user.klinik === klinikValue);
      }
      const searchQuery = this.klinikYonetimHekimSearch.toLowerCase();
      doctor = doctor.filter((user) =>
        user.isim.toLowerCase().includes(searchQuery)
      );

      const doctorNumber = doctor.length;
      return [doctor, doctorNumber];
    },
  },
});
