<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">ÜRÜN NO</th>
        <th class="text-xl w-[500px] text-center">ÜRÜN ADI</th>
        <th class="text-xl w-[300px] text-center">HEKİM ADI</th>
        <th class="text-xl w-[250px] text-center">STOK BİLGİSİ</th>
        <th
          class="text-xl w-[200px] text-center"
          v-if="!popUpManagement.addProductPopUp"
        >
          FİYAT
        </th>

        <th
          class="text-xl w-[200px] text-center"
          v-if="!popUpManagement.addProductPopUp"
        >
          AÇIKLAMA
        </th>
        <div v-if="!popUpManagement.addProductPopUp" class="my-auto">
          <th></th>
        </div>
        <div v-if="!popUpManagement.addProductPopUp" class="my-auto">
          <th></th>
        </div>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(material, index) in tempMaterialData.slice(
          (currentPage - 1) * 7,
          currentPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px] bg-white"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            class="bg-white mt-1"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
          />
        </td>
        <td class="w-[200px] text-center">
          {{ material.urunNo }}
        </td>
        <td class="w-[500px] text-center">
          <p v-if="activeIndex !== index || !editingItem">
            {{ material.urunAdi }}
          </p>
          <input
            type="text"
            v-if="activeIndex === index && editingItem"
            class="border focus:outline-none py-1 px-2"
            v-model="editingItems.urunAdi"
            @keyup.enter="handleEnterKeyPress"
          />
        </td>
        <td class="w-[300px] text-center">
          <p v-if="activeIndex !== index || !editingItem">
            {{ material.hekimAdi }}
          </p>
          <input
            type="text"
            v-if="activeIndex === index && editingItem"
            class="border focus:outline-none py-1 px-2"
            v-model="editingItems.hekimAdi"
            @keyup.enter="handleEnterKeyPress"
          />
        </td>

        <td class="w-[250px] text-center">
          <p v-if="activeIndex !== index || !editingItem">
            {{ material.stokBilgisi }}
          </p>
          <input
            type="text"
            v-if="activeIndex === index && editingItem"
            class="border focus:outline-none py-1 px-2"
            v-model="editingItems.stokBilgisi"
            @keyup.enter="handleEnterKeyPress"
          />
        </td>
        <td
          class="w-[200px] text-center"
          v-if="!popUpManagement.addProductPopUp"
        >
          <p v-if="activeIndex !== index || !editingItem">
            {{ material.fiyat }}
          </p>
          <input
            type="text"
            v-if="activeIndex === index && editingItem"
            class="border focus:outline-none py-1 px-2"
            v-model="editingItems.fiyat"
            @keyup.enter="handleEnterKeyPress"
          />
        </td>

        <td
          class="w-[200px] text-center"
          v-if="!popUpManagement.addProductPopUp"
        >
          <p v-if="activeIndex !== index || !editingItem">
            {{ material.aciklama }}
          </p>
          <input
            type="text"
            v-if="activeIndex === index && editingItem"
            class="border focus:outline-none py-1 px-2"
            v-model="editingItems.aciklama"
            @keyup.enter="handleEnterKeyPress"
          />
        </td>
        <div v-if="!popUpManagement.addProductPopUp">
          <td>
            <div
              class="cursor-pointer mt-2 w-fit ml-12 px-2 flex gap-4"
              v-if="activeIndex === index"
            >
              <div
                class="w-6 h-6 text-white flex justify-center items-center bg-[#277DD2] rounded-full p-1"
              >
                <p
                  v-if="activeIndex === index && editingItem"
                  @click="editingItem = false"
                >
                  X
                </p>
                <img
                  v-if="activeIndex !== index || !editingItem"
                  :src="edit"
                  alt="edit"
                  @click="editingItem = true"
                />
              </div>
              <div
                @click="popUpManagement.deletePopUp = true"
                class="w-6 h-6 flex justify-center items-center bg-[#277DD2] rounded-full p-1"
              >
                <img :src="deleteIcon" alt="delete" />
              </div>
            </div>
          </td>
        </div>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import { tempMaterialData } from "@/modules/protez/store/temporaryProtezData.js";
import edit from "@/modules/protez/assets/edit.svg";
import deleteIcon from "@/modules/protez/assets/deleteIcon.svg";
import { popUpManagement } from "@/modules/protez/store/temporaryProtezData.js";
const editingItem = ref(false);
const activeIndex = ref(null);
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
  } else {
    activeIndex.value = index;
  }
};
defineProps(["currentPage"]);

watch(
  () => activeIndex.value,
  (newIndex) => {
    if (newIndex !== null) {
      // Düzenleme moduna girdiğimizde ilgili özellikleri kopyala
      editingItems.urunAdi = tempMaterialData[activeIndex.value].urunAdi;
      editingItems.hekimAdi = tempMaterialData[activeIndex.value].hekimAdi;
      editingItems.stokBilgisi =
        tempMaterialData[activeIndex.value].stokBilgisi;
      editingItems.fiyat = tempMaterialData[activeIndex.value].fiyat;
      editingItems.aciklama = tempMaterialData[activeIndex.value].aciklama;
    }
  }
);

watch(
  () => editingItem,
  (newValue) => {
    if (newValue) {
      // Düzenleme moduna girdiğimizde ilgili özellikleri kopyala
      editingItems.urunAdi = tempMaterialData[activeIndex.value].urunAdi;
      editingItems.hekimAdi = tempMaterialData[activeIndex.value].hekimAdi;
      editingItems.stokBilgisi =
        tempMaterialData[activeIndex.value].stokBilgisi;
      editingItems.fiyat = tempMaterialData[activeIndex.value].fiyat;
      editingItems.aciklama = tempMaterialData[activeIndex.value].aciklama;
    }
  }
);

const clearEditingItems = () => {
  editingItems.urunAdi = "";
  editingItems.hekimAdi = "";
  editingItems.stokBilgisi = "";
  editingItems.fiyat = "";
  editingItems.aciklama = "";
};

const editingItems = reactive({
  urunAdi: "",
  hekimAdi: "",
  stokBilgisi: "",
  fiyat: "",
  aciklama: "",
});

const handleEnterKeyPress = () => {
  console.log(editingItems);
};

//clearEditingItems();//bunu aslında endpointe istek atınca yapmak lazım dosya yapısı da az değişir
//bu editingi de ayrı tutarım
</script>
