<template>
  <div class="p-4 h-full border-r">
    <div
      class="py-2 font-medium w-[300px] border-2 border-[#9ca0a4] bg-[#fbfbfb] flex items-center justify-center rounded-[30px] mb-16"
    >
      FORM ELEMENTLERİ
    </div>
    <div class="text-[#287dd2] font-medium flex flex-col gap-4">
      <div
        draggable="true"
        @dragstart="dragStart('singleChoice')"
        class="cursor-pointer bg-[#fbfbfb] rounded-[10px] py-2 px-3 border-[1px] border-[#9ca0a4] flex gap-3"
      >
        <img :src="singleChoice" alt="" />
        <p>Tek Seçimli</p>
      </div>

      <div
        draggable="true"
        @dragstart="dragStart('multipleChoice')"
        class="cursor-pointer bg-[#fbfbfb] rounded-[10px] py-2 px-3 border-[1px] border-[#9ca0a4] flex gap-3"
      >
        <img :src="multipleChoice" alt="" />
        <p>Çoktan seçmeli</p>
      </div>

      <div
        draggable="true"
        @dragstart="dragStart('multipleChoiceTable')"
        class="cursor-pointer bg-[#fbfbfb] rounded-[10px] py-2 px-3 border-[1px] border-[#9ca0a4] flex gap-3"
      >
        <img :src="multipleChoiceTable" alt="" />
        <p>Çoktan seçmeli tablosu</p>
      </div>

      <div
        draggable="true"
        @dragstart="dragStart('shortAnswer')"
        class="cursor-pointer bg-[#fbfbfb] rounded-[10px] py-2 px-3 border-[1px] border-[#9ca0a4] flex gap-3"
      >
        <img :src="shortAnswer" alt="" />
        <p>Kısa yanıt</p>
      </div>

      <div
        draggable="true"
        @dragstart="dragStart('linearScale')"
        class="cursor-pointer bg-[#fbfbfb] rounded-[10px] py-2 px-3 border-[1px] border-[#9ca0a4] flex gap-3"
      >
        <img :src="minus" alt="" />
        <p>Doğrusal ölçek</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import singleChoice from "@/modules/anamnezForm/assets/singleChoice.svg";
import multipleChoice from "@/modules/anamnezForm/assets/multipleChoice.svg";
import multipleChoiceTable from "@/modules/anamnezForm/assets/multipleChoiceTable.svg";
import shortAnswer from "@/modules/anamnezForm/assets/shortAnswer.svg";
import minus from "@/modules/anamnezForm/assets/minus.svg";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();

const dragStart = (type) => {
  anamnesisStore.draggingItem = {
    index: null,
    type: type,
  };
};
</script>
