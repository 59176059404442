<template>
  <div class="flex flex-col gap-5">
    <div>
      <div class="flex px-4 py-1 gap-4 bg-[#eaf2fb] rounded">
        <img :src="doctorInfoIcon" alt="" />
        <p class="text-[#277dd2] font-medium">Kişisel Bilgileri</p>
      </div>
      <div class="border rounded divide-y-[1px] divide-[#e5e5e5]">
        <div
          v-for="(info, index) in doctorInfo.personalInfo"
          :key="index"
          class="px-2 py-1 flex items-center"
          :class="index % 2 === 0 ? 'bg-white' : 'bg-[#fbfbfb]'"
        >
          <p class="w-36 text-[#9ea1a4] font-medium">{{ info.label }}</p>
          <p class="text-[#40464C]">{{ info.value }}</p>
          <p
            class="opacity-50"
            v-if="doctorInfo.personalInfo.length - 1 === index"
          >
            <!-- &nbsp;(26 yaş) -->
          </p>
        </div>
      </div>
    </div>

    <div>
      <div class="flex px-4 py-1 gap-4 bg-[#eaf2fb] rounded">
        <img :src="contactIcon" alt="" />
        <p class="text-[#277dd2] font-medium">İletişim Bilgileri</p>
      </div>
      <div class="border rounded divide-y-[1px] divide-[#e5e5e5]">
        <div
          v-for="(info, index) in doctorInfo.contactInfo"
          :key="index"
          class="px-2 py-1 flex items-center"
          :class="index % 2 === 0 ? 'bg-white' : 'bg-[#fbfbfb]'"
        >
          <p class="w-36 text-[#9ea1a4] font-medium">{{ info.label }}</p>
          <p class="text-[#40464C]">{{ info.value }}</p>
        </div>
      </div>
    </div>

    <div>
      <div class="flex px-4 py-1 gap-4 bg-[#eaf2fb] rounded">
        <img :src="stethoscopeIcon" alt="" />
        <p class="text-[#277dd2] font-medium">Hekim Bilgileri</p>
      </div>
      <div class="border rounded divide-y-[1px] divide-[#e5e5e5]">
        <div
          v-for="(info, index) in doctorInfo.workInfo"
          :key="index"
          class="px-2 py-1 flex items-center"
          :class="index % 2 === 0 ? 'bg-white' : 'bg-[#fbfbfb]'"
        >
          <p class="w-36 text-[#9ea1a4] font-medium">{{ info.label }}</p>
          <p class="text-[#40464C]">{{ info.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import doctorInfoIcon from "../assets/doctorInfoIcon.svg";
import contactIcon from "../assets/contactIcon.svg";
import stethoscopeIcon from "../assets/stethoscopeIcon.svg";
import { watch, onMounted } from "vue";
import { doctorInfo } from "../components/doctorInfoReactiveTypes";
import { useGetStaffStore } from "@/store/modules/administratorManagementStore/staff/getStaffStore";
const getStaffStore = useGetStaffStore();

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("tr-TR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

const formatString = (str) => {
  if (!str) {
    return null;
  }
  return str
    .toLocaleLowerCase("tr-TR")
    .split(" ")
    .map((word) => word.charAt(0).toLocaleUpperCase("tr-TR") + word.slice(1))
    .join(" ");
};

watch(
  () => doctorInfo.doctorInfos,
  (newValue) => {
    doctorInfo.personalInfo[0].value = doctorInfo.doctorInfos.name;
    doctorInfo.personalInfo[1].value = formatString(
      doctorInfo.doctorInfos.surname
    );
    doctorInfo.personalInfo[2].value = doctorInfo.doctorInfos.tc_id_number;
    doctorInfo.personalInfo[3].value = formatString(
      doctorInfo.doctorInfos.gender
    );
    doctorInfo.personalInfo[4].value = null;
    doctorInfo.personalInfo[5].value = formatString(
      doctorInfo.doctorInfos.birth_place
    );
    doctorInfo.personalInfo[6].value = `${formatDate(
      doctorInfo.doctorInfos.birth_date
    )} (${formatAgeString(doctorInfo.doctorInfos.birth_date)})
    `;

    doctorInfo.contactInfo[0].value = doctorInfo.doctorInfos.home_phone_number;
    doctorInfo.contactInfo[1].value = doctorInfo.doctorInfos.work_phone_number;
    doctorInfo.contactInfo[2].value = doctorInfo.doctorInfos.email;
    doctorInfo.contactInfo[3].value = doctorInfo.doctorInfos.province;
    doctorInfo.contactInfo[4].value = doctorInfo.doctorInfos.district;
    doctorInfo.contactInfo[5].value = formatString(
      doctorInfo.doctorInfos.address
    );

    doctorInfo.workInfo[0].value = formatString(
      doctorInfo.doctorInfos.appellation
    );
    doctorInfo.workInfo[1].value = formatString(
      doctorInfo.doctorInfos.appellation_group
    );
    doctorInfo.workInfo[2].value = formatString(doctorInfo.doctorInfos.section);
    doctorInfo.workInfo[3].value = formatString(
      doctorInfo.doctorInfos.staff_place
    );
    doctorInfo.workInfo[4].value = formatDate(
      doctorInfo.doctorInfos.start_date
    );
  }
);

onMounted(async () => {
  doctorInfo.personalInfo[0].value = doctorInfo.doctorInfos.name;
  doctorInfo.personalInfo[1].value = formatString(
    doctorInfo.doctorInfos.surname
  );
  doctorInfo.personalInfo[2].value = doctorInfo.doctorInfos.tc_id_number;
  doctorInfo.personalInfo[3].value = formatString(
    doctorInfo.doctorInfos.gender
  );
  doctorInfo.personalInfo[4].value = null;
  doctorInfo.personalInfo[5].value = formatString(
    doctorInfo.doctorInfos.birth_place
  );
  doctorInfo.personalInfo[6].value = `${formatDate(
    doctorInfo.doctorInfos.birth_date
  )} (${formatAgeString(doctorInfo.doctorInfos.birth_date)})
    `;
  doctorInfo.contactInfo[0].value = doctorInfo.doctorInfos.home_phone_number;
  doctorInfo.contactInfo[1].value = doctorInfo.doctorInfos.work_phone_number;
  doctorInfo.contactInfo[2].value = doctorInfo.doctorInfos.email;
  doctorInfo.contactInfo[3].value = doctorInfo.doctorInfos.province;
  doctorInfo.contactInfo[4].value = doctorInfo.doctorInfos.district;
  doctorInfo.contactInfo[5].value = formatString(
    doctorInfo.doctorInfos.address
  );

  doctorInfo.workInfo[0].value = formatString(
    doctorInfo.doctorInfos.appellation
  );
  doctorInfo.workInfo[1].value = formatString(
    doctorInfo.doctorInfos.appellation_group
  );
  doctorInfo.workInfo[2].value = formatString(doctorInfo.doctorInfos.section);
  doctorInfo.workInfo[3].value = formatString(
    doctorInfo.doctorInfos.staff_place
  );
  doctorInfo.workInfo[4].value = formatDate(doctorInfo.doctorInfos.start_date);
});

function formatAgeString(dob) {
  const birthDate = new Date(dob);
  const today = new Date();

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();
  let ageDays = today.getDate() - birthDate.getDate();

  if (ageDays < 0) {
    ageMonths--;
    const tempDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      birthDate.getDate()
    );
    ageDays = Math.floor((today - tempDate) / (1000 * 60 * 60 * 24));
  }

  if (ageMonths < 0) {
    ageYears--;
    ageMonths += 12;
  }

  const ageString = `${ageYears} yaş`;
  return ageString;
}
</script>
