<template>
  <div class="flex-grow flex flex-col">
    <activitiesNavigationSection />

    <div class="h-full" v-if="medulaStore.selectedPatientID">
      <activityAppointments v-if="medulaStore.activitiesNavigation === 1" />
      <!-- <activityApplications v-if="medulaStore.activitiesNavigation === 2" /> -->
      <pastActivities v-if="medulaStore.activitiesNavigation === 2" />
    </div>
  </div>
</template>

<script setup>
//section imports
import activitiesNavigationSection from "@/modules/medula/provision/components/patientActivitiesSubs/activitiesNavigation.vue";
import activityAppointments from "@/modules/medula/provision/components/patientActivitiesSubs/activityAppointments.vue";
import activityApplications from "@/modules/medula/provision/components/patientActivitiesSubs/activityApplications.vue";
import pastActivities from "@/modules/medula/provision/components/patientActivitiesSubs/pastActivities.vue";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
const medulaStore = useMedula();
</script>
