<template>
  <div class="mt-40 flex flex-col items-center">
    <p class="text-xl font-bold">
      Hakkımızda
    </p>
    <div class="mt-8 max-w-[1035px]">
      <p>
        Diş Hekimliği Fakültemiz, 15 Nisan 2011 tarihli ve 27906 sayılı Resmi Gazete'de yayınlanmış olan, 4 Nisan 2011 tarihli ve 2011/1595 sayılı Bakanlar Kurulu kararı ile 
Dumlupınar Üniversite'ne bağlı olarak kurulmuştur. Fakültemizin klinik uygulama alanı olan Ağız Diş Sağlığı Uygulama ve Araştırma Merkezi, Yükseköğretim Kurumu’nun 
18 Haziran 2015 tarihli 75.850.160-101.04/34014 sayılı yazısı ile kurulmuş olup, karar 5 Ağustos 2015 tarih 29436 sayılı Resmi Gazete'de yayınlanmıştır. Diş Hekimliği 
Fakültesi Ağız Diş Sağlığı Uygulama ve Araştırma Merkezi Müdürlüğümüzün 8 Nisan 2016 tarihinde açılışı yapılarak poliklinik hizmetlerine başlanmıştır.
      </p>
      <p class="mt-3">
        Diş Hekimliği Fakültemiz ve Ağız Diş Sağlığı Uygulama ve Araştırma Merkezi Müdürlüğümüz 18 Mayıs 2018 tarih ve 30425 sayılı Resmi Gazete'de yayınlanan 7141 sayılı
kanun uyarınca yeni kurulan Kütahya Sağlık Bilimleri Üniversitesi'ne bağlanmış olup hizmetlerine artık üniversitemiz bünyesinde devam etmektedir.
      </p>
      <p class="mt-3">
        Kütahya Sağlık Bilimleri Üniversitesi Diş Hekimliği Fakültesi Ağız Diş Sağlığı Uygulama ve Araştırma Merkezi Müdürlüğümüz, uzman kadrosuyla şuan yeni hizmet binamızda
 siz kıymetli hastalarımıza hizmet vermektedir. Hasta kabulü ve randevu işlemleri konusunda detaylı bilgiler için lütfen randevu menüsünü inceleyiniz. Sağlıklı günler dileriz.
      </p>
    </div>
    <p class="text-xl font-medium mt-5">
      Misyonumuz
    </p>
    <div class="mt-8 max-w-[1035px]  flex flex-col " >
      <p >
        Gelişmiş eğitim-araştırma geliştirme faaliyetleri ve sağlık hizmet sunum ortamına sahip olarak, milli ve manevi değerleri özümsemiş, nitelikli öğrenciler ve uzman diş hekimleri yetiştiren, çalışan ve öğrenci memnuniyetini önemseyen, hastalarına memnuniyet odaklı sağlık hizmeti sunan, bilime ve ulusal ekonomiye katkıda bulunan, 

      </p>
      <p class="flex justify-center ">yenilikçi, ulaşılabilir, kaliteli bir merkez olmak.</p>
    </div>
    <p class="text-xl font-medium mt-8">
      Vizyonumuz
    </p>
    <div class="mt-8 max-w-[1035px]  flex flex-col " >
      <p >
        Araştırma geliştirme faaliyetleri, kaliteli eğitim-öğretimi ve sağlık hizmet sunumu ile ulusal ve uluslararası alanda tanınan ve tercih


      </p>
      <p class="flex justify-center"> edilen, örnek gösterilen, güvenilir bir merkez olmak.</p>
    </div>
  </div>
</template>
  
