import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useDeleteVEMAppointment = defineStore({
  id: "deleteVEMAppointment",
  state: () => ({
    errorOccured: false,
  }),
  actions: {
    async deleteVEMAppointment(randevuKodu: string) {
      globalStore.customInfoCard = true;
      globalStore.customInfoType = "pending";
      const personelStoreData = personelStore();

      await axios
        .delete(`${clinicDomain}/appointments/${randevuKodu}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("deleteVEMAppointment success", response.data);
            setTimeout(() => {
              globalStore.customInfoCard = false;
              globalStore.customInfoType = "";
            }, 2000);

            globalStore.customInfoType = "success";
            this.errorOccured = false;
            return true;
          }
        })
        .catch((error) => {
          console.error("Error deleteVEMAppointment", error);
          setTimeout(() => {
            globalStore.customInfoCard = false;
            globalStore.customInfoType = "";
          }, 2000);

          globalStore.customInfoType = "fail";
          this.errorOccured = true;

          return false;
        });
    },
  },
});
