<template>
  <div class="relative flex">
    <doctorHastaKabulSidebar />
    <div class="overflow-auto relative flex flex-col w-full">
      <personelHeaderNavBar title="İlk Muayene" />
      <main class="px-7 mt-2 flex flex-col h-full bg-white text-[#40464C]">
        <patientCard />
      </main>
    </div>
  </div>
</template>

<script setup>
import personelHeaderNavBar from "@/modules/personel/components/personelHeaderNavbar.vue";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import patientCard from "@/modules/doctorScreen/components/doctorPatientList/patientCard.vue";
import { onMounted } from "vue";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useRouter } from "vue-router";
const router = useRouter();
let patientID = router.currentRoute.value.params.patientID;

const getPatientStore = useGetPatientStore();
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
const doctorsSlotsStore = doctorSlotsStore();

onMounted(async () => {
  doctorsSlotsStore.selectedPatient = {};
  doctorsSlotsStore.selectedPatient = await getPatientStore.getPatientA(
    patientID
  );
});
</script>
