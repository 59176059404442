<template>
  <div class="overflow-auto relative flex flex-col w-full">
    <div class="flex items-center justify-between mb-3 border-b">
      <div class="text-white"></div>
      <h2 class="font-medium text-[#40464c]">ICON INDEX</h2>
      <button
        @click="
          router.push(
            `/polyclinic/examinations/dental-chart-plan/${$route.params.patientID}`
          )
        "
        class="text-white bg-[#F94144] px-6 py-2 font-semibold"
      >
        Kapat
      </button>
    </div>
    <!-- patient info -->
    <div class="py-2 px-10 flex items-center gap-8 border-b">
      <div class="flex items-center gap-3">
        <img :src="profileCircle" alt="profile photo" />
        <div class="flex flex-col justify-between">
          <span class="text-red-500 text-xs">ARh(+)</span>
          <span class="text-[#282829] font-medium"
            >{{ patient?.attributes?.name }}
            {{ patient?.attributes?.surname }}</span
          >
          <!-- <span class="text-[#3C3C43] text-xs">(24 yıl 6 ay 14 gün)</span> -->
        </div>
      </div>
      <div class="flex items-center gap-8 text-xs">
        <div class="flex gap-6">
          <div class="flex flex-col gap-2">
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">TC Kimlik No</p>
              <p class="font-medium">
                {{ patient?.attributes?.tc_identity_number }}
              </p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Doğum Tarihi</p>
              <p class="font-medium">{{ patient?.attributes?.dob }}</p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Hasta Türü</p>
              <p class="font-medium">SSK</p>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">GSS Takip No</p>
              <p class="font-medium">14A9B4J</p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Katkı Payı</p>
              <p class="font-medium">-</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex text-sm w-full max-w-[1800px] my-6 gap-32 mx-auto">
      <div class="flex my-6 gap-32">
        <div class="flex flex-col gap-8">
          <div class="flex flex-col gap-4">
            <span class="font-medium">Estetik Değerlendirme</span>
            <div class="flex gap-6 shadow-md py-2 px-4 rounded-md w-fit">
              <div
                class="flex flex-col items-center"
                v-for="i in getScaleRange(1, 10)"
                :key="i"
              >
                <label>{{ i }}</label>
                <input
                  v-model="aesthetic"
                  class="w-6 h-6"
                  type="radio"
                  :value="i"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <span class="font-medium">Üst Ark Çapraşıklığı Değerlendirme</span>
            <div class="flex gap-6 shadow-md py-2 px-4 rounded-md w-fit">
              <div
                class="flex flex-col items-center"
                v-for="item in upperArchOptions"
                :key="item.value"
              >
                <label>{{ item.name }}</label>
                <input
                  v-model="upperArch"
                  class="w-6 h-6"
                  type="radio"
                  :value="item.value"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <span class="font-medium">Üst Ark Boşluğu</span>
            <div class="flex gap-6 shadow-md py-2 px-4 rounded-md w-fit">
              <div
                class="flex flex-col items-center"
                v-for="item in upperSpacingOptions"
                :key="item.value"
              >
                <label>{{ item.name }}</label>
                <input
                  v-model="upperSpacing"
                  class="w-6 h-6"
                  type="radio"
                  :value="item.value"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <span class="font-medium">Çapraz Kapanış</span>
            <div class="flex gap-6 shadow-md py-2 px-4 rounded-md w-fit">
              <div
                class="flex flex-col items-center"
                v-for="item in crossbiteOptions"
                :key="item.value"
              >
                <label>{{ item.name }}</label>
                <input
                  v-model="crossbite"
                  class="w-6 h-6"
                  type="radio"
                  :value="item.value"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <span class="font-medium">Ön Açık Kapanış</span>
            <div class="flex gap-6 shadow-md py-2 px-4 rounded-md w-fit">
              <div
                class="flex flex-col items-center"
                v-for="item in openBiteOptions"
                :key="item.value"
              >
                <label>{{ item.name }}</label>
                <input
                  v-model="openBite"
                  class="w-6 h-6"
                  type="radio"
                  :value="item.value"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <span class="font-medium">Bukkal Bölge Sağ</span>
            <div class="flex gap-6 shadow-md py-2 px-4 rounded-md w-fit">
              <div
                class="flex flex-col items-center"
                v-for="item in buccalRightOptions"
                :key="item.value"
              >
                <label>{{ item.name }}</label>
                <input
                  v-model="buccalRight"
                  class="w-6 h-6"
                  type="radio"
                  :value="item.value"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <span class="font-medium">Bukkal Bölge Sol</span>
            <div class="flex gap-6 shadow-md py-2 px-4 rounded-md w-fit">
              <div
                class="flex flex-col items-center"
                v-for="item in buccalLeftOptions"
                :key="item.value"
              >
                <label>{{ item.name }}</label>
                <input
                  v-model="buccalLeft"
                  class="w-6 h-6"
                  type="radio"
                  :value="item.value"
                />
              </div>
            </div>
          </div>

          <!-- <button
            class="text-[#277dd2] border-[1px] border-[#277dd2] w-[400px] h-10 font-medium rounded flex items-center justify-center"
          >
            Puan Hesapla
          </button> -->
          <div
            class="text-white font-semibold bg-[#277dd2] flex items-center justify-between text-lg w-[400px] rounded py-2 px-4"
          >
            <span>Icon Index Skoru:</span>
            <span>{{ iconScore }}</span>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-[20px]">
          <span class="font-medium ml-8"
            >Ortodontik Tedavi Gereklilik Değerlendirme</span
          >
          <div
            v-for="(item, index) in items"
            :key="index"
            class="flex items-center gap-4 h-fit"
          >
            <input
              type="checkbox"
              :id="item.id"
              :value="item.id"
              v-model="selectedCheckboxes"
            />
            <div
              class="border rounded-r-[10px] w-[450px] flex items-center gap-2"
            >
              <div
                class="bg-[#277dd2] flex-shrink-0 h-auto w-1 rounded-l-lg self-stretch"
              ></div>
              <p class="py-1">{{ item.text }}</p>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-[20px]">
          <span class="font-medium ml-8">Açıklamalar</span>
          <div
            class="border ml-9 rounded-r-[10px] w-[450px] flex items-center gap-2"
          >
            <div
              class="bg-[#277dd2] h-auto w-1 rounded-l-lg self-stretch"
            ></div>
            <textarea
              v-model="explanation"
              placeholder="Açıklama giriniz..."
              cols="59"
              rows="3"
              class="resize-none"
            ></textarea>
          </div>
        </div>
        <div class="flex flex-col gap-[20px]">
          <span class="font-medium ml-8">Değerlendiren Hekim Bilgisi</span>
          <input type="text" class="border w-[450px] ml-9 rounded p-1" />
        </div>
        <button
          @click="showScoreModal = true"
          class="ml-9 text-[#277dd2] border-[1px] border-[#277dd2] w-[400px] h-10 font-medium rounded flex items-center justify-center"
        >
          Değerlendirmeyi Kaydet
        </button>
      </div>
    </div>
    <div v-if="showScoreModal" class="overlay"></div>
    <div
      v-if="showScoreModal"
      class="absolute z-50 pb-4 rounded text-sm w-1/2 py-2 top-1/3 left-1/2 -translate-x-1/2 flex flex-col gap-4 -translate-y-1/2 bg-white"
    >
      <span class="w-full border-b pb-2 text-[#40464C] font-medium text-center"
        >ICON INDEX SKORU</span
      >
      <div class="flex gap-16 px-10 items-center">
        <div
          class="rounded-full flex-shrink-0 font-medium text-4xl flex items-center justify-center w-[100px] h-[100px]"
          :class="
            iconScore >= 43
              ? 'bg-[#e3f2d8] text-[#73BC3B]'
              : 'bg-[#fed8d4] text-[#F83A28]'
          "
        >
          {{ iconScore }}
        </div>
        <div class="flex flex-col gap-2">
          <div
            v-for="checkbox in selectedCheckboxes"
            class="flex items-center gap-2"
          >
            <input type="checkbox" checked disabled />
            <div
              class="border rounded-r-[10px] w-[450px] flex items-center gap-2"
            >
              <div
                class="bg-[#277dd2] flex-shrink-0 h-auto w-1 rounded-l-lg self-stretch"
              ></div>
              <p class="py-1 break-words">
                {{ items[checkbox - 1].text }}
              </p>
            </div>
          </div>
          <span v-if="explanation" class="font-medium">Açıklamalar</span>
          <div
            v-if="explanation"
            class="border rounded-r-[10px] w-[450px] flex items-center gap-2"
          >
            <div
              class="bg-[#277dd2] flex-shrink-0 h-auto w-1 rounded-l-lg self-stretch"
            ></div>
            <p class="py-1 break-words">
              {{ explanation }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex gap-[20px] mx-auto">
        <button
          @click="showScoreModal = false"
          class="text-[#40464C] font-medium border-[1px] border-[#40464C] rounded w-[120px] h-8 flex items-center justify-center"
        >
          Vazgeç
        </button>
        <button
          @click="saveIconScore"
          class="text-[#277dd2] font-medium border-[1px] border-[#277dd2] rounded w-[120px] h-8 flex items-center justify-center"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import sterilizationModal from "@/modules/doctorScreen/components/doctorDentalChart/sterilizationModal.vue";
import cross from "@/modules/medula/provision/assets/crossIcon.svg";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import { useDentalChart } from "@/modules/dentalChart/dentalChartMain/dentalChartStore/dentalChartStore";
const dentalChartStore = useDentalChart();
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
import { onMounted, ref, computed } from "vue";
const pastActivitiesStore = usePastActivitiesStore();
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useRouter } from "vue-router";
import { useDentalChartPlanStore } from "@/modules/doctorScreen/store/dentalChartPlanStore";
const dentalChartPlanStore = useDentalChartPlanStore();
const router = useRouter();
let patientID = router.currentRoute.value.params.patientID;
const patient = ref();
const searchTerm = ref("");
const getScaleRange = (min, max) => {
  const range = [];
  for (let i = min; i <= max; i++) {
    range.push(i);
  }
  return range;
};

const saveIconScore = () => {
  const data = {
    aesthetic: aesthetic.value,
    upperArch: upperArch.value,
    upperSpacing: upperSpacing.value,
    crossbite: crossbite.value,
    openBite: openBite.value,
    buccalRight: buccalRight.value,
    buccalLeft: buccalLeft.value,
    orthodonticNeeds: orthodonticNeeds.value,
    explanation: explanation.value,
    doctorInfo: doctorInfo.value,
    selectedCheckboxes: selectedCheckboxes.value,
  };
  console.log(data);
  showScoreModal.value = false;
  window.location.reload();
};

const items = [
  {
    id: 1,
    text: "Ortodontik boşluk kapatma (her yarım çenede en az bir ya da daha fazla doğumsal diş eksikliği) veya protez öncesi ortodontik tedavi ihtiyacı olan hipodonti vakaları veya polidiastema vakaları,",
    selected: false,
  },
  {
    id: 2,
    text: "6 mm’den fazla artmış overjeti bulunan vakalar",
    selected: false,
  },
  {
    id: 3,
    text: "Tek taraflı veya her iki bukkal bölgede, posterior lingual çapraz kapanış veya nonoklüzyonu mevcut olan vakalar,",
    selected: false,
  },
  {
    id: 4,
    text: "2 mm ve üzeri posterior ve/veya anterior açık kapanış vakaları,",
    selected: false,
  },
  {
    id: 5,
    text: "Diş etine veya damak mukozasına temas görülen artmış overbite mevcut olan vakalar,",
    selected: false,
  },
  {
    id: 6,
    text: "Herhangi bir patolojik sebep, düşmemiş süt dişi, süpernümere (artık diş), yer değiştirme veya çapraşıklık gibi sebeplerden dolayı sürmesi engellenmiş (kök gelişimini tamamlamış veya pozisyonu nedeniyle sürememiş) gömülü diş mevcudiyeti olan vakalar,",
    selected: false,
  },
  {
    id: 7,
    text: "Üst ve/veya alt çenedeki diastema/çapraşıklığın 6 mm ve üzerinde olduğu vakalar,",
    selected: false,
  },
  {
    id: 8,
    text: "Krepitasyon, kliking, açma veya kapamada deviasyonun eşlik ettiği TMD vakaları,",
    selected: false,
  },
  {
    id: 9,
    text: "Bidental ve/veya bimaksiller protrüzyon vakaları",
    selected: false,
  },
  { id: 10, text: "Fasiyal asimetri (laterognati) vakaları", selected: false },
];

const aesthetic = ref(null);
const upperArch = ref(null);
const upperSpacing = ref(null);
const crossbite = ref(null);
const openBite = ref(null);
const buccalRight = ref(null);
const buccalLeft = ref(null);
const orthodonticNeeds = ref([]);
const explanation = ref("");
const doctorInfo = ref("");
const selectedCheckboxes = ref([]);

const showScoreModal = ref(false);

const upperArchOptions = [
  { name: "<2mm", value: 0 },
  { name: "2.1 - 5mm", value: 1 },
  { name: "5.1 - 9mm", value: 2 },
  { name: "9.1 - 13mm", value: 3 },
  { name: "13.1 - 17mm", value: 4 },
  { name: ">17mm ya da gömülü diş", value: 5 },
];

const upperSpacingOptions = [
  { name: "<2mm", value: 0 },
  { name: "2.1 - 5mm", value: 1 },
  { name: "5.1 - 9mm", value: 2 },
];

const crossbiteOptions = [
  { name: "Mevcut Değil", value: 0 },
  { name: "Mevcut", value: 1 },
];

const openBiteOptions = [
  { name: "Alt kesicinin 1/3’den daha az örtmüş", value: 0 },
  { name: "1/3’den 2/3’üne kadar örtmüş", value: 1 },
  { name: "2/3’ünden tamamına kadar örtülmüş", value: 2 },
  { name: "Tamamen örtülmüş", value: 3 },
];

const buccalRightOptions = [
  { name: "Molar distal", value: 0 },
  { name: "1/2 molar distal", value: 1 },
  { name: "Molar mesial", value: 2 },
];

const buccalLeftOptions = [
  { name: "Molar distal", value: 0 },
  { name: "1/2 molar distal", value: 1 },
  { name: "Molar mesial", value: 2 },
];

const orthodonticOptions = [
  { name: "Çapraşıklık", value: "crowding" },
  { name: "Boşluklar", value: "spacing" },
  { name: "Alt çene pozisyonu", value: "mandibular" },
  { name: "Üst çene pozisyonu", value: "maxillary" },
];

const aestheticWeight = 7;
const upperArchWeight = 5;
const upperSpacingWeight = 5;
const crossbiteWeight = 5;
const openBiteWeight = 4;
const buccalRightWeight = 3;
const buccalLeftWeight = 3;

const iconScore = computed(() => {
  return (
    (aesthetic.value || 0) * aestheticWeight +
    (upperArch.value || 0) * upperArchWeight +
    (upperSpacing.value || 0) * upperSpacingWeight +
    (crossbite.value || 0) * crossbiteWeight +
    (openBite.value || 0) * openBiteWeight +
    (buccalRight.value || 0) * buccalRightWeight +
    (buccalLeft.value || 0) * buccalLeftWeight
  );
});

const getPatientStore = useGetPatientStore();

onMounted(async () => {
  patient.value = await getPatientStore.getPatientA(patientID);
});
</script>
