<template>
  <div class="h-full w-full">
    <button
      @click="goBackArrowButton()"
      class="flex items-center mt-[40px] ml-[40px]"
    >
      <img :src="leftArrow" alt="leftArrow" />
      <p class="text-[#333333] text-xl ml-2">Geri</p>
    </button>
    <form
      name="login_form"
      class="h-full w-full"
      id="login_form"
      action=""
      autocomplete="on"
      @submit.prevent="sendCodeToEmail()"
    >
      <div class="ml-[15%] mt-[25%]">
        <p class="text-[#333333] font-bold text-[26px] mb-[20px]">
          Şifremi unuttum
        </p>
        <p class="text-[#333333B2] mb-[35px]">
          E-posta adresinizi giriniz.Şifrenizi sıfırlamak için size kod
          göndereceğiz.
        </p>
        <label class="flex cursor-pointer items-center" for="firstRadio">
          <div class="relative w-96">
            <img
              :src="mailBlack"
              class="absolute inset-y-0 px-4 mt-[20px] flex items-center"
              alt=""
            />
            <input
              :class="
                personalStore.isAuthPasswordFalse
                  ? 'bg-[#E9383833] w-96 h-14   border py-2 px-5  leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]'
                  : 'w-96 h-14  border py-2 px-5 leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]'
              "
              id="username"
              v-model="email"
              type="email"
              placeholder="E-mail"
              required
              autocomplete="username"
            />
          </div>
        </label>
      </div>

      <button
        type="submit"
        :class="
          email
            ? 'focus:shadow-outline   h-14  bg-[#312CB5]  font-medium text-white shadow   mt-7 mb-7 '
            : 'focus:shadow-outline   h-14 cursor-not-allowed  bg-[#D9D9D9]  font-medium text-[#454545] shadow  mt-7 mb-7'
        "
        class="ml-[15%] w-[383px] rounded-[30px]"
        @click="sendCodeToEmail()"
      >
        Gönder
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { usePersonalStore } from "@/modules/personel/store/personalStore";
import leftArrow from "@/modules/personel/assets/leftArrow.svg";

const personalStore = usePersonalStore();

const sendCodeToEmail = () => {
  console.log("sendCodeToEmail");
};
const goBackArrowButton = () => {
  personalStore.iForgetMyPassword = 0;
};
</script>