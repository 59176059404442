import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import axios from "axios";
import { personelStore } from "../../administratorManagementStore/personel";
import _ from "lodash";

export const useGetStaffStore = defineStore({
  id: "getStaff",
  state: () => ({}),
  actions: {
    async getStaff(id: string) {
      try {
        const personelStoreData = personelStore();
        const response = await axios.get(`${clinicDomain}/staffs/${id}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        });
        console.log("getStaff", response.data);
        return response.data;
      } catch (error) {
        console.error("Error in searchStaff:", error);
      }
    },
  },
});
