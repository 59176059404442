import { defineStore } from "pinia";
import {
  ApplicationProvisionRequest,
  applicationProvisionRequest,
} from "@/types/operations/patientApplications/applicationProvisionRequest";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "../../globalStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useApplicationProvisionStore = defineStore({
  id: "useApplicationProvisionStore",
  state: () => ({
    applicationProvisionRequest:
      applicationProvisionRequest as ApplicationProvisionRequest,
    applicationProvisionResponse: {},
  }),
  actions: {
    async getApplicationProvisions(
      applicationProvisionRequest: ApplicationProvisionRequest
    ) {
      const personelStoreData = personelStore();
      this.applicationProvisionRequest = applicationProvisionRequest;
      globalStore.customInfoCard = true;
      globalStore.customInfoType = "pending";
      await axios
        .post(
          `${clinicDomain}/patient-applications/provision/create`,
          {
            ...applicationProvisionRequest,
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              globalStore.customInfoCard = false;
              globalStore.customInfoType = "";
            }, 2000);
            globalStore.isMedulaCardSuccess = true;
            globalStore.customInfoType = "success";
            // if (response.data.HastaKabulReturn.SonucKodu !== "0000") {
            //   globalStore.isMedulaCardError = true;
            //   globalStore.customInfoType = "fail";
            // } else {
            //   globalStore.isMedulaCardSuccess = true;
            //   globalStore.customInfoType = "success";
            // }

            this.applicationProvisionResponse = response.data;

            console.log(response, "provizyondan dondu");
            console.log(applicationProvisionRequest, "provizyon istegi");
            useMedula().appointmentsApplication = "";
            // useMedula().bagliTakipAliniyor = false;
            // useMedula().randevuProvizyonBasvuruNo = "";
            // useMedula().randevuProvizyonHastaTc = "";
            // useMedula().alinamamisBasvuruNo = "";
            // useMedula().alinamamisBasvuruHastaTc = "";
          }
        })
        .catch((err) => {
          setTimeout(() => {
            globalStore.customInfoCard = false;
            globalStore.customInfoType = "";
          }, 2000);
          console.log(applicationProvisionRequest, "provizyon istegi");

          console.log(err);
          globalStore.customInfoType = "fail";
          // globalStore.isMedulaCardSuccess = true;
          //oradaki hastakabulreturn yerine eğer buradan yollanmışsa response.data.errors[0].detail
          this.applicationProvisionResponse =
            err.response.data.errors[0].detail;
          // globalStore.isMedulaCardError = true;
          globalStore.isMedulaCardSuccess = true;

          console.log(err);
          console.log(applicationProvisionRequest, "provizyon istegi");
          console.log("bunudondur", err.response);
          // useMedula().bagliTakipAliniyor = false;
        });
    },
  },
});
