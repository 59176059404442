<template>
  <div
    :class="store.assistantCalendarDetailButton && 'open-popup'"
    class="slidingPageBackground absolute top-2 right-0 popup w-[616px] z-10 flex-col overflow-auto bg-white"
  >
    <div class="flex h-[55px] justify-between">
      <div class="flex h-[75px] flex-col">
        <div class="ml-1 flex h-8 items-center justify-center">
          <button class="mt-1 ml-1" @click="decreaseDate()">
            <img :src="leftArrowBlack" alt="" />
          </button>
          <p>Bugün</p>
          <button @click="increaseDate()" class="mt-1 ml-1">
            <img :src="rightArrowBlack" alt="" />
          </button>
        </div>
        <p class="flex h-4 justify-center">{{ dateShown }}</p>
      </div>

      <div class="mt-2 flex flex-col mr-4">
        <button
          class="w-[87px] min-h-[30px] border-2 border-[#2C3D8F] rounded-[5px] borderBoxShadow"
        >
          <p class="text-[10px] text-[#2C3D8F]">Randevu Listesi</p>
        </button>
        <button
          @click="store.assistantCalendarDetailButton = false"
          class="items-end flex justify-end mt-3 focus:outline-none"
        >
          <img :src="exitButtonLogo" alt="" />
        </button>
      </div>
    </div>
    <div class="mt-3">
      <hastaListesiHastaBilgi />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import upArrowBlue from "../assets/arrowUpBlue.svg";
import downArrowBlue from "../assets/arrowDownBlue.svg";
import exitButtonLogo from "../assets/exitButtonLogo.svg";
import rightArrowBlack from "../assets/arrowRightBlack.svg";
import leftArrowBlack from "../assets/arrowLeftBlack.svg";
import VueDatePicker from "@vuepic/vue-datepicker";
import addDays from "date-fns/addDays";
import hastaListesiHastaBilgi from "../components/hastaListesiHastaBilgi.vue";
// Import store
import { useAsistanEkrani } from "../asistanEkraniStore/asistanEkraniStore";
const store = useAsistanEkrani();
let date = ref(new Date());
let dateShown = ref(
  `${date.value.getDate()}/${
    date.value.getMonth() + 1
  }/${date.value.getFullYear()}`
);
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
watch(date, () => {
  dateShown.value = `${date.value.getDate()}/${
    date.value.getMonth() + 1
  }/${date.value.getFullYear()}`;
});
let amountOfDate = 0;
const increaseDate = () => {
  amountOfDate += 1;
  dateShown.value = `${date.value.getDate() + amountOfDate}/${
    date.value.getMonth() + 1
  }/${date.value.getFullYear()}`;
};
const decreaseDate = () => {
  amountOfDate -= 1;
  dateShown.value = `${date.value.getDate() + amountOfDate}/${
    date.value.getMonth() + 1
  }/${date.value.getFullYear()}`;
};

const highlightedDates = ref([
  addDays(new Date(), 1),
  addDays(new Date(), 2),
  addDays(new Date(), 3),
  addDays(new Date(), -1),
]);

let bolumlerButtonRef = ref(false);

const showTumBirimlerRef = (valueOfRef) => {
  bolumlerButtonRef.value = valueOfRef;
};
</script>

<style scoped>
.slidingPageBackground {
  border: 1px solid #d5dbe1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bolumlerimizButtonBg {
  background: #ffffff;
  border: 1px solid #adadad;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
.bolumlerimizText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #333333;
}

.hastaRandevuAjanda .dp__calendar_header_item {
  background-color: #f1f6ff;
}
.hastaRandevuAjanda .dp__calendar_row {
  background-color: #f1f6ff;
  margin: 0;
  padding: 4px;
}
.hastaRandevuAjanda .dp__calendar_row:last-child {
  background-color: #f1f6ff;
  height: 45px;
}
.hastaRandevuAjanda .dp__month_year_wrap {
  height: 45px;
  width: 150px;
}
.hastaRandevuAjanda .dp__calendar_header {
  margin-top: 20px;
  width: 253px;
}
.hastaRandevuAjanda .dp__month_year_row {
  background-color: #f1f6ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hastaRandevuAjanda .dp__instance_calendar {
  width: 253px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.borderBoxShadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.popup {
  visibility: hidden;
  transition: all 0.6s;
  right: 100px;
}
.open-popup {
  visibility: visible;
  transform: translateX(100px);
}
</style>