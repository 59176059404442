<template>
  <div class="flex-[2] flex flex-col bg-white">
    <h2
      class="w-full p-[10px] text-sm font-medium bg-[#F5F5F5] flex items-center justify-center bg-"
    >
      Tedavi Planlama
    </h2>
    <div class="flex border-b items-center justify-between font-medium text-xs">
      <div class="flex items-center">
        <button
          class="flex items-center justify-center w-[120px] h-[38px]"
          :class="
            dentalChartPlanStore.planStatusTab === 'activePlans'
              ? 'bg-white text-[#277dd2]'
              : 'bg-[#f5f5f5] text-[#40464C]'
          "
          @click="dentalChartPlanStore.planStatusTab = 'activePlans'"
        >
          Aktif
        </button>
        <button
          class="flex items-center justify-center w-[120px] h-[38px]"
          :class="
            dentalChartPlanStore.planStatusTab === 'remainingPlans'
              ? 'bg-white text-[#277dd2]'
              : 'bg-[#f5f5f5] text-[#40464C]'
          "
          @click="dentalChartPlanStore.planStatusTab = 'remainingPlans'"
        >
          Devam eden
        </button>
        <button
          class="flex items-center justify-center w-[120px] h-[38px]"
          :class="
            dentalChartPlanStore.planStatusTab === 'completedPlans'
              ? 'bg-white text-[#277dd2]'
              : 'bg-[#f5f5f5] text-[#40464C]'
          "
          @click="dentalChartPlanStore.planStatusTab = 'completedPlans'"
        >
          Tamamlanmış
        </button>
      </div>
      <button
        @click="addPlan"
        class="mr-4 flex items-center gap-3 text-[#277dd2]"
      >
        <img :src="plus" class="w-4" />
        Plan ekle
      </button>
    </div>
    <div class="border-b flex gap-8 items-center text-xs px-4">
      <button
        v-for="(plan, index) in dentalChartPlanStore?.examinationPlans?.[
          dentalChartPlanStore.planStatusTab
        ]"
        class="pb-1 pt-[10px] px-1 border-b-4"
        :class="
          dentalChartPlanStore.activePlanTab === index
            ? 'bg-white text-[#277dd2] border-[#277dd2]'
            : 'text-[#a0a3a6] border-transparent'
        "
        @click="dentalChartPlanStore.activePlanTab = index"
      >
        {{ plan.planName }}
      </button>
    </div>
    <div class="flex-grow text-xs">
      <div
        class="flex flex-col gap-5 mt-32 items-center"
        v-if="
          dentalChartPlanStore?.examinationPlans?.[
            dentalChartPlanStore.planStatusTab
          ]?.length === 0
        "
      >
        <p class="text-[#a0a3a6] text-sm">
          Aktif bir tedavi planı bulunmamaktadır. Plan ekle butonunu kullanarak
          tedavi planı oluşturabilirsiniz.
        </p>
        <button
          @click="addPlan"
          class="text-xs flex items-center gap-3 text-[#277dd2]"
        >
          <img :src="plus" class="w-4" />
          Plan ekle
        </button>
      </div>
      <button
        v-else-if="
          dentalChartPlanStore?.examinationPlans?.[
            dentalChartPlanStore.planStatusTab
          ]?.[dentalChartPlanStore.activePlanTab]?.planStages?.length === 0
        "
        @click="addStage"
        class="mt-12 w-[200px] h-10 rounded mx-auto justify-center text-xs border-[1px] border-[#277dd2] flex items-center gap-3 text-[#277dd2]"
      >
        <img :src="plus" class="w-4" />
        Aşama ekle
      </button>
      <div
        class="px-5 py-4 flex flex-col gap-3 overflow-y-auto h-[550px]"
        v-else
      >
        <div
          v-for="(planStage, index) in dentalChartPlanStore?.examinationPlans?.[
            dentalChartPlanStore.planStatusTab
          ]?.[dentalChartPlanStore.activePlanTab]?.planStages"
          class="flex flex-col gap-1"
        >
          <button
            class="flex items-center divide-x-2 divide-[#277dd2] gap-3 py-1 px-2 rounded border"
            :class="
              dentalChartPlanStore.planStatusTab === 'activePlans'
                ? 'bg-[#E6EFF5]'
                : dentalChartPlanStore.planStatusTab === 'remainingPlans'
                ? 'bg-[#fcf5ca]'
                : 'bg-[#d5ebc5]'
            "
            @click="
              dentalChartPlanStore.openedStage === index
                ? (dentalChartPlanStore.openedStage = null)
                : (dentalChartPlanStore.openedStage = index)
            "
          >
            <img :src="plus" class="w-4" />

            <div class="flex items-center gap-1 pl-1">
              <div class="flex flex-col gap-1 items-start">
                <span class="font-medium">{{ planStage.stageName }}</span>
                <button
                  @click.stop="makeAppointment"
                  class="flex gap-1 items-center"
                >
                  <img :src="plus" class="w-[10px]" />
                  <span class="text-[#939ba1]">Randevu oluştur</span>
                </button>
              </div>
            </div>
          </button>

          <div v-if="index === dentalChartPlanStore.openedStage">
            <table class="min-w-full bg-white cursor-pointer">
              <thead>
                <tr class="w-full text-[10px] sm:text-xs border-y">
                  <th
                    v-for="(column, index) in columns"
                    :key="index"
                    class="max-w-12 truncate sm:max-w-fit py-2 px-2 xl:px-6 text-left font-medium whitespace-nowrap"
                  >
                    {{ column.name }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-[10px] sm:text-xs">
                <tr
                  v-for="(
                    process, processIndex
                  ) in planStage.stageProcessesInfos"
                  :key="processIndex"
                  class="border-y border-[#d9d9d9] hover:bg-gray-100"
                >
                  <td
                    class="py-2 flex flex-col px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    <span
                      :title="process.processName"
                      class="w-32 truncate block"
                      >{{ process.processName }}</span
                    >
                    <span class="text-[#939ba1]">{{
                      process.processSutCode
                    }}</span>
                  </td>
                  <td
                    class="py-2 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    <span
                      :title="formattedToothNumbers(process.toothNumbers)"
                      class="w-20 truncate block"
                    >
                      {{ formattedToothNumbers(process.toothNumbers) }}</span
                    >
                  </td>
                  <td
                    class="py-2 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    <span
                      :title="process.toothNumbers.length"
                      class="w-20 truncate block"
                    >
                      {{ process.toothNumbers.length }}</span
                    >
                  </td>
                  <td
                    class="py-2 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    <span :title="process.price" class="w-20 truncate block">
                      {{ process.price }}</span
                    >
                  </td>
                  <td
                    class="py-2 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    <span
                      :title="process.price * process.toothNumbers.length"
                      class="w-20 truncate block"
                    >
                      {{ process.price * process.toothNumbers.length }}</span
                    >
                  </td>
                  <td
                    class="py-2 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    <img
                      @click="removeProcess(index, processIndex)"
                      :src="bin"
                      class="w-6"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          @click="addStage"
          class="mt-6 w-[200px] flex-shrink-0 h-10 rounded mx-auto justify-center text-xs border-[1px] border-[#277dd2] flex items-center gap-3 text-[#277dd2]"
        >
          <img :src="plus" class="w-4" />
          Aşama ekle
        </button>
      </div>
    </div>
    <div
      class="text-xs mt-auto border-t py-2 px-6 flex items-center justify-between"
    >
      <div class="text-[#457C1A] bg-[#f8fnf5] flex flex-col gap-1">
        <span>Genel Toplam Fiyat</span>
        <span class="font-medium text-base">₺{{ totalPrice || 0 }}</span>
      </div>
      <div class="flex items-center gap-2">
        <div class="flex flex-col gap-1">
          <span>Oluşturan Hekim</span>
          <span class="font-medium">Dr. Ahmet Yılmaz</span>
        </div>
        <div class="flex flex-col gap-1">
          <span>Kaydedilen Tarih/Saat</span>
          <span class="font-medium">12.05.2021</span>
        </div>
      </div>
      <div class="flex items-center gap-2">
        <button
          class="font-medium rounded flex items-center justify-center w-[92px] h-10 border-[1px] border-[#277dd2] text-[#277dd2]"
        >
          Önizleme
        </button>
        <button
          class="font-medium rounded flex items-center justify-center w-[92px] h-10 border-[1px] border-[#277dd2] text-[#277dd2]"
        >
          Yazdır
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import plus from "@/modules/protez/assets/plus.svg";
import bin from "@/modules/doctorScreen/assets/bin.svg";
import { useDentalChartPlanStore } from "@/modules/doctorScreen/store/dentalChartPlanStore";
const dentalChartPlanStore = useDentalChartPlanStore();
import { ref, watch, computed } from "vue";
const formattedToothNumbers = (teeth) => {
  if (!teeth) return "";
  return teeth.join(",");
};
const makeAppointment = () => {
  console.log("makeAppointment");
};

watch(
  () =>
    dentalChartPlanStore?.examinationPlans?.[
      dentalChartPlanStore.planStatusTab
    ],
  (newValue) => {
    if (newValue?.length > 0) {
      dentalChartPlanStore.activePlanTab = 0;
    }
  }
);

watch(
  () =>
    dentalChartPlanStore?.examinationPlans?.[dentalChartPlanStore.planStatusTab]
      ?.length,
  (newValue) => {
    if (newValue > 0) {
      dentalChartPlanStore.openedStage = 0;
    }
  }
);

watch(
  () => dentalChartPlanStore.activePlanTab,
  (newValue) => {
    if (newValue > 0) {
      dentalChartPlanStore.openedStage = 0;
    }
  }
);

watch(
  () =>
    dentalChartPlanStore?.examinationPlans?.[
      dentalChartPlanStore.planStatusTab
    ]?.[dentalChartPlanStore.activePlanTab]?.planStages.length,
  (newValue) => {
    dentalChartPlanStore.openedStage = newValue - 1;
  }
);

// const addPlan = () => {
//   console.log("addPlan");
// };
const addPlan = () => {
  if (
    dentalChartPlanStore?.examinationPlans?.[dentalChartPlanStore.planStatusTab]
      .length === 4
  ) {
    return false;
  }
  dentalChartPlanStore?.examinationPlans?.[
    dentalChartPlanStore.planStatusTab
  ]?.push({
    planName:
      "Plan " +
      (dentalChartPlanStore?.examinationPlans?.[
        dentalChartPlanStore.planStatusTab
      ]?.length +
        1),
    planStages: [],
  });
  dentalChartPlanStore.activePlanTab =
    dentalChartPlanStore?.examinationPlans?.[dentalChartPlanStore.planStatusTab]
      ?.length - 1;
};

const addStage = () => {
  if (
    dentalChartPlanStore?.examinationPlans?.[
      dentalChartPlanStore.planStatusTab
    ]?.[dentalChartPlanStore.activePlanTab]?.planStages.length === 4
  ) {
    return false;
  }
  dentalChartPlanStore?.examinationPlans?.[
    dentalChartPlanStore.planStatusTab
  ]?.[dentalChartPlanStore.activePlanTab]?.planStages.push({
    stageName:
      "Aşama " +
      (dentalChartPlanStore?.examinationPlans?.[
        dentalChartPlanStore.planStatusTab
      ]?.[dentalChartPlanStore.activePlanTab]?.planStages.length +
        1),
    stageProcessesInfos: [],
  });
};
const removeProcess = (index, processIndex) => {
  dentalChartPlanStore?.examinationPlans?.[
    dentalChartPlanStore.planStatusTab
  ]?.[dentalChartPlanStore.activePlanTab]?.planStages[
    index
  ]?.stageProcessesInfos.splice(processIndex, 1);
  console.log("removeProcess");
};

const totalPrice = computed(() => {
  const total = dentalChartPlanStore?.examinationPlans?.[
    dentalChartPlanStore.planStatusTab
  ]?.[dentalChartPlanStore.activePlanTab]?.planStages.reduce(
    (acc, stage) =>
      acc +
      stage.stageProcessesInfos.reduce(
        (acc, process) => acc + process.price * process.toothNumbers.length,
        0
      ),
    0
  );
  return total?.toFixed(2) || 0;
});

const columns = [
  { key: "process", name: "İşlem Adı/Kodu" },
  { key: "teeth", name: "Diş Kodu/Konum" },
  { key: "count", name: "Adet" },
  { key: "price", name: "Birim Fiyat" },
  { key: "totalPrice", name: "Toplam Fiyat" },
];
const rows = [
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
];
</script>
