<template>
  <div class="flex flex-col min-w-[650px] h-full ml-[44px] mt-[15px]">
    <div
      class="border-x-[1px] border-t-[1px] border-[#D5DBE1] rounded-[5px] w-[130px] h-[30px] flex items-center justify-center"
    >
      <p class="text-[#2C3D8F] font-medium">Birim Tanımları</p>
    </div>
    <div class="flex flex-col h-full border-r-[1px] border-[#D5DBE1]">
      <div class="w-full border-b-[#D5DBE1] border-[1px]"></div>
      <div class="flex items-center mt-[58px]">
        <p class="text-[#454545] w-[180px]">Adı</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
        <!-- Aktif button -->
        <div
          class="border-[#16A34A] rounded-[5px] border-[1px] bg-[#16A34A1A] flex items-center justify-center w-[75px] h-[23px] ml-[85px] -mt-[20px]"
        >
          <div class="w-3 h-3 rounded-full bg-[#16A34A]"></div>
          <p class="text-[#16A34A] ml-2">Aktif</p>
        </div>
      </div>
      <div class="flex items-center mt-[29px]">
        <p class="text-[#454545] w-[180px]">Teletip Entegrasyon</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex items-center mt-[29px]">
        <p class="text-[#454545] w-[180px]">Doktor Seçimi</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex items-center mt-[29px]">
        <p class="text-[#454545] w-[180px]">Birim Türü</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex items-center mt-[29px]">
        <p class="text-[#454545] w-[180px]">Pacs Birim ID</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex mt-[37px]">
        <div class="flex">
          <p class="text-[#454545]">Çekim Onay Zorunlu</p>
          <input type="checkbox" name="" id="" class="ml-[20px]" />
        </div>
        <div class="flex ml-8">
          <p class="text-[#454545]">Yönlendirme Birimi</p>
          <input type="checkbox" name="" id="" class="ml-[20px]" />
        </div>
        <div class="flex ml-8">
          <p class="text-[#454545]">Yön. Birimi Kabul</p>
          <input type="checkbox" name="" id="" class="ml-[20px]" />
        </div>
      </div>
    </div>
  </div>
</template>