<template>
  <div class="flex flex-col">
    <div
      v-for="(dental, index) in store.dentalChartToothNumberCheckbox"
      :key="index"
    >
      <div
        v-if="store.dentalChartToothNumberCheckbox[index]"
        class="mt-5 flex h-[500px] flex-col rounded-[5px] bg-white"
      >
        <div class="flex flex-col">
          <div
            class="ml-1 mt-3 flex h-7 w-[120px] items-center justify-center rounded-[10px] border-[1px] border-[#ECECEC] bg-[#E1EDFF]"
          >
            <p class="font-normal">Muayene</p>
          </div>
          <div class="ml-3 w-[95%] border-t-2 border-[#ECECEC]"></div>
          <div class="flex flex-col">
            <div
              v-for="(examination, index) in examinations"
              v-bind:key="examination.examinationNumber"
              @click="
                selectExamination(
                  examination.examinationName,
                  examination.examinationNameInfo
                )
              "
              :class="
                store.dentalChartExaminationCheckbox[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][index] && 'bg-[#E1EDFF4D]/[.3]'
              "
              class="ml-2 mt-1 flex h-7 items-center"
            >
              <p class="text-[#3C3C43D9]/[.85]">
                {{ examination.examinationNumber }}
              </p>
              <p class="ml-3 text-[#3C3C43]">
                {{ examination.examinationName }}
                <span class="text-[#3C3C43D9]/[.5]">
                  {{ examination.examinationNameInfo }}</span
                >
              </p>

              <input
                v-model="
                  store.dentalChartExaminationCheckbox[
                    store.dentalChartToothNumberCheckbox.indexOf(true)
                  ][index]
                "
                class="inp-cbx"
                :id="examination.examinationNumber"
                type="checkbox"
              /><label class="cbx ml-auto" :for="examination.examinationNumber"
                ><span
                  ><svg width="12px" height="10px">
                    <use xlink:href="#check"></use></svg></span
              ></label>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div
            class="ml-1 mt-3 flex h-7 w-[120px] items-center justify-center rounded-[10px] border-[1px] border-[#ECECEC] bg-[#E1EDFF]"
          >
            <p class="font-normal">Hizmetler</p>
          </div>
          <div class="ml-3 w-[95%] border-t-2 border-[#ECECEC]"></div>
          <div class="flex flex-col">
            <div
              v-for="(service, index) in services"
              :key="index"
              :class="
                store.dentalChartServicesCheckbox[index] &&
                'bg-[#E1EDFF4D]/[.3]'
              "
              class="ml-2 mt-1 flex items-center"
            >
              <div class="flex w-full flex-col">
                <div class="flex w-full items-center justify-end">
                  <p class="text-[#3C3C43D9]/[.85]">
                    {{ service.serviceNumber }}
                  </p>
                  <p class="ml-3 text-[#3C3C43]">
                    {{ service.serviceName }}
                    <span class="text-[#3C3C43D9]/[.5]"
                      >{{ service.serviceNameInfo }}
                    </span>
                  </p>

                  <input
                    v-model="store.dentalChartServicesCheckbox[index]"
                    class="inp-cbx ml-auto mr-[10px]"
                    :id="service.serviceNumber"
                    type="checkbox"
                  /><label class="cbx ml-auto" :for="service.serviceNumber"
                    ><span
                      ><svg width="12px" height="10px">
                        <use xlink:href="#check"></use></svg></span
                  ></label>
                </div>
                <dentalChartSearchServicesDetail
                  v-if="store.dentalChartServicesCheckbox[index]"
                />
              </div>
            </div>
            <!-- <div
              @click="openPopup()"
              class="mx-auto my-4 flex h-[44px] w-[200px] cursor-pointer items-center justify-center rounded-[5px] bg-[#0094FF]"
            >
              <p class="text-white">Onayla</p>
            </div> -->
            <div :class="popup && 'overlay'" @click="openPopup()">
              <div
                :class="popup && 'open-popup'"
                class="popup flex h-full w-full border-l-8 border-[#36B10B]"
              >
                <img
                  :src="successInfoLogo"
                  class="ml-4 w-[18px]"
                  alt="successImg"
                />
                <p
                  class="ml-2 flex h-full w-[80%] items-center text-xs font-semibold text-[#373A3C]"
                >
                  {{
                    store.dentalChartSelectedTeethNumber[
                      store.dentalChartToothNumberCheckbox.indexOf(true)
                    ]
                  }}
                  Numaralı Diş İçin Tedavi Planı Oluşturulmuştur!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import successInfoLogo from "../../assets/successInfoLogo.svg";
import dentalChartSearchServicesDetail from "./dentalChartSearchServicesDetail.vue";
import { examinations, services } from "../dentalChartData/dentalChartData.js";
import { ref } from "vue";

import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();

// PopUp
let popup = ref(null);
const openPopup = () => {
  popup.value = !popup.value;
};

const selectExamination = (examinationName, examinationNameInfo) => {
  store.setDoctorInfoSection({
    tarih: "03/03/2002",
    alan: "46",
    kod: "401010",
    konum: "Ön",
    ucret: "50 TL",
    ucretTuru: "kamuSatisTarifesi",
    hizmetTuru: `${examinationName} ${examinationNameInfo} `,
    durum: "Planlandı",
  });
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.open-popup {
  visibility: visible;
  transform: translate(-50%, 0) scale(1);
}
.popup {
  width: 300px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  right: 0;
  top: 5%;
  transform: translate(0, 0) scale(1);

  color: #333;
  visibility: hidden;
  transition: transform 0.4s, left 0.4s;
}
.open-popup {
  visibility: visible;
  transform: translate(-25%, 0) scale(1);
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
}
.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
}
.cbx:not(:last-child) {
  margin-right: 6px;
}
.cbx:hover {
  background: rgba(0, 119, 255, 0.06);
}
.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.cbx:hover span:first-child {
  border-color: #07f;
}
.inp-cbx {
  position: absolute;
  visibility: hidden;
}
.inp-cbx:checked + .cbx span:first-child {
  background: #07f;
  border-color: #07f;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: #223254;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  body {
    width: auto;
    display: inherit;
    padding: 20px;
  }
  .cbx {
    width: 100%;
    margin-bottom: 4px;
    display: inline-block;
  }
}
@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
</style>