<template>
  <div class="flex w-full min-h-screen">
    <doctorHastaKabulSidebar />
    <div class="w-full relative flex flex-col">
      <personelHeaderNavbar title="Anamnez İşlemleri" />
      <main class="p-3 flex flex-col gap-5 h-full bg-[#fbfbfb]">
        <anamnesesUnitHome />
      </main>
    </div>
  </div>
</template>

<script setup>
import anamnesesUnitHome from "@/modules/anamnezForm/components/anamnesesUnitView/anamnesesUnitHome.vue";
import personelHeaderNavbar from "@/modules/personel/components/personelHeaderNavbar";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import { useGetUnit } from "@/store/modules/administratorManagementStore/hospital/Units/getUnitStore";
import { useGetDoctorsByUnitStore } from "@/store/modules/administratorManagementStore/staff/getDoctorsByUnit";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();
const doctorsByUnitStore = useGetDoctorsByUnitStore();
const anamnesisStore = useAnamnesistore();
const getUnitStore = useGetUnit();
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;

onMounted(async () => {
  if (anamnesisStore.anamnesesUnitScreenUnitDatas) {
    return;
  }
  anamnesisStore.anamnesesUnitScreenUnitDatas = null;
  anamnesisStore.anamnesesUnitScreenUnitDatas = await getUnitStore.getUnit(
    unitID
  );
});

onMounted(async () => {
  anamnesisStore.anamnesesUnitScreenDoctors = null;
  anamnesisStore.anamnesesUnitScreenDoctors =
    await doctorsByUnitStore.getDoctorsByUnit(unitID);
});

onMounted(async () => {
  await anamnesisTemplateStore.listAnamnesisTemplate();
});
</script>
