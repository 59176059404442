<template>
  <div
    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white border shadow-lg rounded-lg flex flex-col justify-center items-center px-10 py-4"
  >
    <p>Seçilen tarihleri onaylıyor musunuz?</p>
    <p class="text-[#277DD2] mb-2">
      {{
        moment(doctorCalendarStore().selectedDays[0].date).format(
          "DD MMMM  ",
          "tr"
        )
      }}
      -
      {{
        moment(
          doctorCalendarStore().selectedDays[
            doctorCalendarStore().selectedDays.length - 1
          ].date
        ).format("DD MMMM  ", "tr")
      }}
    </p>
    <select
      v-model="doctorCalendarStore().appointmentType"
      value="Randevu tipi seçiniz"
      class="my-2 focus:outline-none border-2 border-black rounded p-2"
    >
      <option value="" selected disabled>Randevu tipi seçiniz</option>
      <option value="normal_randevu">Normal Randevu</option>
      <option value="mesai_disi_ogretim_uyesi">
        Mesai Dışı Öğretim Üyesi Randevu
      </option>
      <option value="implant_estetik_dis_klinigi">
        İmplant Estetik Diş Kliniği
      </option>
    </select>
    <div class="flex gap-4">
      <button
        @click="
          doctorCalendarStore().appointmentType = '';
          opendateApprovePopUpFalse();
        "
        class="text-black bg-[#E3E3E3] w-20 h-8 rounded-2xl focus:outline-none"
      >
        Vazgeç</button
      ><button
        @click="
          opendateApprovePopUpFalse();
          openAddToDrCalendarTrue();
        "
        class="bg-[#277DD2] text-white w-20 h-8 rounded-2xl focus:outline-none"
        :class="
          doctorCalendarStore().appointmentType
            ? 'cursor-pointer'
            : 'opacity-50 cursor-not-allowed'
        "
        :disabled="!doctorCalendarStore().appointmentType"
      >
        Onayla
      </button>
    </div>
  </div>
</template>

<script setup>
import { searchSlotsBetweenDate } from "../../../store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
import moment from "moment";
import { selectedDTypes } from "./selectedDTypes";
import { randevuTipi } from "./selectedDTypes";
import { doctorCalendarStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorCalendarStore";

moment.locale("tr");
const props = defineProps({
  opendateApprovePopUpFalse: Function,
  openAddToDrCalendarTrue: Function,
});
</script>
