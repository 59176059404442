import { defineStore } from "pinia";
export const useAppointments = defineStore("appointmentsStore", {
  id: "appointmentsStore",
  state: () => ({
    activeDate: "",
    appointments: {
      appointments: {
        data: [],
      },
      count: 0,
    },
    appointmentsPage: 1,
    isProvisionedAppointments: false,
  }),
});
