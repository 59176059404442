<template>
    <div 
    v-if="store.studentAssistantFilterAcceptFlow===true"
    class="absolute top-11 min-h-[600px] w-[416px] bg-[#F8F9FD] border-2 border-[#D9D9D9] rounded-[10px]">
            <div class="flex flex-col ml-6">
                <p class="text-[#2C3D8F] text-xl font-medium mt-8">Dönem</p>
                <div class="bg-white w-[372px] h-[200px]   border-2 border-[#D9D9D9] rounded-[10px]">
                        <p class="text-[#2C3D8F] font-bold items-center justify-center flex mt-[28px]">23 Ocak - 17 Mart 2023</p>
                </div>
            </div>
            <div class="flex flex-col ml-6">
                <p class="text-[#2C3D8F] text-xl font-medium mt-8">Öğrenci Ad/Soyad</p>
                <div class="bg-white w-[372px] h-[200px]  border-2 border-[#D9D9D9] rounded-[10px]">
                        <p class="text-[#2C3D8F] font-bold items-center justify-center flex mt-[28px]">A’dan E harfine</p>
                </div>
            </div>
    </div>
</template>

<script setup>
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();
</script>