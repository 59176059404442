import { reactive, ref } from "vue";

export const globalStore = reactive({
  customInfoCard: false,
  customInfoType: "",
  customInfoText: "",
  status: false,
  isUpdating: false,
  isUserLoggedOut: false, //for show logout message on the landing page
  isMedulaCardSuccess: false,
  isMedulaCardError: false,
  isPatiendIdExist: false,
  isAppointmentCreateSuccesful: false,
  isAppointmentCreateFail: false,
  createSettingSuccessful: false,
  createSettingFail: false,
  isCreating: false,
  isSideBarFieldsFull: true,
  isCreateAppointmentSideBarOpen: false,
  calendarSideBarType: "",
  appointmentStartTime: new Date(),
  appointmentEndTime: new Date(),
  patient: {},
  isEventsLoading: false, //for monthly
  isWeeklyEventsLoading: false, //for weekly
  getVerifySmsCodeStatus(responseStatus: boolean) {
    this.status = responseStatus;
  },
  getIsUserLoggedOut(loggedOut: boolean) {
    this.isUserLoggedOut = loggedOut;
  },
  setIsAppointmentCreateSuccesful(prop: boolean) {
    this.isAppointmentCreateSuccesful = prop;
  },
});
