<template>
  <div class="flex justify-between">
    <div class="flex flex-col gap-4 w-[450px]">
      <!-- provision type -->
      <div class="flex items-center justify-between">
        <p>Provizyon Tipi</p>
        <Select2
          class="w-[285px]"
          :options="optionTypes"
          placeholder=""
          v-model="RegisteredPatientListStore.provisionsDatas.provisionType"
          :disabled="!RegisteredPatientListStore.editleniyor"
        />
      </div>

      <!-- provision date -->
      <div class="flex items-center justify-between">
        <p>Provizyon Tarihi</p>
        <div
          class="w-[285px] rounded items-center flex p-[3px] border-[1px] border-[#ADADAD] bg-[#e6e6e6]"
        >
          <input
            disabled
            type="text"
            class="w-full h-full ml-1 focus:outline-none"
            v-model="RegisteredPatientListStore.provisionsDatas.provisionDate"
          />
        </div>
      </div>

      <!-- sevk takip no -->
      <div class="flex items-center justify-between">
        <p>Sevk Takip No</p>
        <div
          class="w-[285px] rounded items-center flex p-[3px] border-[1px] border-[#ADADAD] bg-[#e6e6e6]"
        >
          <input
            disabled
            type="text"
            class="w-full h-full ml-2 focus:outline-none"
            v-model="
              RegisteredPatientListStore.provisionsDatas.shippedTrackingNo
            "
          />
        </div>
      </div>

      <!-- exceptional -->
      <div class="flex items-center justify-between">
        <p>İstisnai Durum</p>
        <Select2
          class="w-[285px]"
          :options="exceptionalTypes"
          placeholder=""
          v-model="RegisteredPatientListStore.provisionsDatas.exceptionalStatus"
          :disabled="!RegisteredPatientListStore.editleniyor"
        />
      </div>

      <!-- follow type -->
      <div class="flex items-center justify-between">
        <p>Takip Tipi</p>
        <Select2
          class="w-[285px]"
          :options="fallowypes"
          placeholder=""
          v-model="RegisteredPatientListStore.provisionsDatas.followType"
          :disabled="!RegisteredPatientListStore.editleniyor"
        />
      </div>

      <!-- treatment kind -->
      <div class="flex items-center justify-between">
        <p>Tedavi Türü</p>
        <Select2
          class="w-[285px]"
          :options="treatmentKindTypes"
          placeholder=""
          v-model="RegisteredPatientListStore.provisionsDatas.treatmentKind"
          :disabled="!RegisteredPatientListStore.editleniyor"
        />
      </div>

      <!-- add adli vaka section with adli vaka condition -->
      <div
        class="flex items-center justify-between"
        v-if="RegisteredPatientListStore.provisionsDatas.provisionType === 'T'"
      >
        <p>Plaka No</p>
        <input
          :disabled="!RegisteredPatientListStore.editleniyor"
          type="text"
          class="w-[285px] h-[28px] p-1 rounded border border-[#e6e6e6]"
          :class="
            RegisteredPatientListStore.editleniyor ? 'bg-white' : 'bg-[#ADADAD]'
          "
        />
      </div>
    </div>
    <div class="flex flex-col gap-4 w-[450px]">
      <!-- unit -->
      <div class="flex items-center justify-between">
        <p>Birim</p>
        <Select2
          class="w-[285px]"
          :options="unitListStore.getListUnitsResponse"
          @select="chooseBirim"
          placeholder=""
          v-model="RegisteredPatientListStore.provisionsDatas.unitCode"
          :disabled="!RegisteredPatientListStore.editleniyor"
        />
      </div>

      <!-- doctor -->
      <div class="flex items-center justify-between">
        <p>Doktor</p>
        <Select2
          class="w-[285px]"
          :options="RegisteredPatientListStore.doctors"
          placeholder=""
          v-model="RegisteredPatientListStore.provisionsDatas.doctorCode"
          :disabled="!RegisteredPatientListStore.editleniyor"
        />
      </div>
      <!-- brans code/name -->
      <div class="flex items-center justify-between">
        <p>Branş Kodu / Adı</p>
        <div class="flex">
          <div
            class="w-[85px] h-7 mr-2 rounded items-center flex p-[3px] border-[1px] border-[#ADADAD]"
          >
            <p>
              {{ RegisteredPatientListStore.provisionsDatas.branchCode }}
            </p>
          </div>
          <div
            class="w-[191px] overflow-hidden whitespace-nowrap h-7 rounded items-center flex p-[3px] border-[1px] border-[#ADADAD] bg-[#e6e6e6]"
          >
            <p>
              {{
                _.startCase(
                  _.toLower(
                    RegisteredPatientListStore.provisionsDatas.branchName
                  )
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- assurance -->
      <!-- <div class="flex items-center justify-between">
        <p>S.G. Durumu</p>
        <div
          class="w-[285px] rounded items-center flex p-[3px] border-[1px] border-[#ADADAD] bg-[#e6e6e6]"
        >
          <input
            disabled
            type="text"
            class="w-full h-full focus:outline-none pl-1"
            :value="
              RegisteredPatientListStore.patientProvisionCardInfo.HastaBilgileri
                ?.SigortaliTuru === '1'
                ? 'Çalışan'
                : RegisteredPatientListStore.patientProvisionCardInfo
                    .HastaBilgileri?.SigortaliTuru === '2'
                ? 'Emekli'
                : RegisteredPatientListStore.patientProvisionCardInfo
                    .HastaBilgileri?.SigortaliTuru === '3'
                ? 'SSK Kurum Personeli'
                : RegisteredPatientListStore.patientProvisionCardInfo
                    .HastaBilgileri?.SigortaliTuru === '4'
                ? 'Diğer'
                : '' ?? ''
            "
          />
        </div>
      </div> -->

      <!-- insurance -->
      <!-- <div class="flex items-center justify-between">
        <p>Sigortalı Türü</p>
        <div
          class="w-[285px] rounded items-center flex p-[3px] border-[1px] border-[#ADADAD] bg-[#e6e6e6]"
        >
          <input
            disabled
            type="text"
            class="w-full h-full focus:outline-none pl-1"
            :value="
              RegisteredPatientListStore.patientProvisionCardInfo.HastaBilgileri
                ?.SigortaliTuru === '1'
                ? 'Çalışan'
                : RegisteredPatientListStore.patientProvisionCardInfo
                    .HastaBilgileri?.SigortaliTuru === '2'
                ? 'Emekli'
                : RegisteredPatientListStore.patientProvisionCardInfo
                    .HastaBilgileri?.SigortaliTuru === '3'
                ? 'SSK Kurum Personeli'
                : RegisteredPatientListStore.patientProvisionCardInfo
                    .HastaBilgileri?.SigortaliTuru === '4'
                ? 'Diğer'
                : '' ?? ''
            "
          />
        </div>
      </div> -->

      <!-- treatment type -->
      <div class="flex items-center justify-between">
        <p>Tedavi Tipi</p>
        <Select2
          class="w-[285px]"
          :options="treatmentTypes"
          placeholder=""
          v-model="RegisteredPatientListStore.provisionsDatas.treatmentType"
          :disabled="!RegisteredPatientListStore.editleniyor"
        />
      </div>

      <!-- add adli vaka section with adli vaka condition -->
      <div
        v-if="
          RegisteredPatientListStore.provisionsDatas.provisionType === 'V' ||
          RegisteredPatientListStore.provisionsDatas.provisionType === 'I' ||
          RegisteredPatientListStore.provisionsDatas.provisionType === 'T'
        "
        class="flex flex-col gap-4"
      >
        <div class="flex items-center justify-between">
          <p>Vaka İl</p>
          <Select2
            class="w-[285px]"
            :options="getCitiesStore.getListCitiesResponse"
            placeholder=""
            :disabled="!RegisteredPatientListStore.editleniyor"
          />
        </div>

        <div class="flex items-center justify-between">
          <p>Vaka İlçe</p>
          <Select2
            class="w-[285px]"
            :options="RegisteredPatientListStore.caseDistricts"
            placeholder=""
            :disabled="!RegisteredPatientListStore.editleniyor"
          />
        </div>

        <div class="flex items-center justify-between">
          <p>Vaka Tarihi</p>
          <div
            :class="{
              'rounded w-[285px] h-[28px] border-[1px] border-[#ADADAD] bg-[#e6e6e6]':
                !RegisteredPatientListStore.editleniyor,
            }"
          >
            <VueDatePicker
              locale="tr"
              auto-apply
              format="dd/MM/yyyy"
              :enable-time-picker="false"
              :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
              class="w-[285px] rounded"
              :class="{
                'opacity-5': !RegisteredPatientListStore.editleniyor,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import _ from "lodash";
import Select2 from "vue3-select2-component";
import VueDatePicker from "@vuepic/vue-datepicker";

//store imports
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useGetDistricts } from "@/store/modules/operationsStore/public/useGetDistricts";
import {
  optionTypes,
  exceptionalTypes,
  fallowypes,
  treatmentKindTypes,
  treatmentTypes,
} from "@/modules/medula/provision/components/dropDownTypes";
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import { useGetDoctorsByUnitStore } from "@/store/modules/administratorManagementStore/staff/getDoctorsByUnit";
import { useGetCities } from "@/store/modules/operationsStore/public/useGetCities";

//store inits
const RegisteredPatientListStore = useRegisteredPatientListStore();
const unitListStore = useGetUnitsList();
const staffListStore = useGetStaffList();
const doctorsByUnitStore = useGetDoctorsByUnitStore();
const getCitiesStore = useGetCities();
const getDistrictsStore = useGetDistricts();

onMounted(async () => {
  if (
    !RegisteredPatientListStore.provisionsDatas.unitCode ||
    RegisteredPatientListStore.doctors
  ) {
    return false;
  }
  RegisteredPatientListStore.doctors =
    await doctorsByUnitStore.getDoctorsByUnit(
      RegisteredPatientListStore.provisionsDatas.unitCode
    );
});

watch(
  () => RegisteredPatientListStore.provisionsDatas.unitCode,
  async (newValue, oldValue) => {
    RegisteredPatientListStore.doctors =
      await doctorsByUnitStore.getDoctorsByUnit(
        RegisteredPatientListStore.provisionsDatas.unitCode
      );
  }
);

onMounted(async () => {
  // await staffStore.getStaffList();
  // await unitsStore.getUnitsList();
});

const chooseBirim = (birim) => {
  console.log(birim);
  // AddpatientApplicationStore.addPatientApplicationRequest.branskodu =
  //   birim.MedulaBransKodu.String;

  // AddpatientApplicationStore.addPatientApplicationRequest.birimkodu =
  //   birim.BirimKodu;
  RegisteredPatientListStore.provisionsDatas.branchName = birim.text;
  RegisteredPatientListStore.provisionsDatas.branchCode =
    birim.medula_brans_kodu;
  RegisteredPatientListStore.provisionsDatas.doctorCode = "";

  // store.medulaProvizyon.BirimAdı.text = birim.text;
  // store.medulaProvizyon.BirimAdı.id = birim.id;
};
</script>
