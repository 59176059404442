import { createRouter, createWebHistory } from "vue-router";
import asistanEkraniHomeView from "../../modules/asistanEkrani/view/asistanEkraniHomeView.vue";
import assistantAppointmentBookView from "@/modules/asistanEkrani/asistanCalendar/view/assistantAppointmentBookView.vue";
import asistantViewPatientRouter from "./asistantEventsRouter/asistantViewPatientRouter";
import { requirePersonelAuth } from "@/components/requireAuth";
const routes = [
  {
    path: "/asistantScreen/Calendar",
    name: "assistantAppointmentBookView",
    component: assistantAppointmentBookView,
    beforeEnter: requirePersonelAuth,
  },

  ...asistantViewPatientRouter.options.routes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
