<template>
  <div
    class="bg-[#fbfbfb] px-3 py-2 border flex-grow rounded-md overflow-auto h-[670px]"
  >
    <button
      class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] bg-white border-[1px] rounded-md border-[#277dd2] font-medium text-[#277dd2] py-1 px-3"
    >
      KPS Bilgileri Getir
    </button>
    <div class="flex items-center justify-between relative">
      <h4
        class="font-medium mt-3 w-fit text-[#09244B] pb-1 focus:outline-double focus:outline-1 focus:outline-[#277dd2] border-b-[#277DD2] border-b-2"
      >
        Hasta Bilgileri
      </h4>
      <!-- Settings img and filter part -->
      <button
        @click="settingsToggle = !settingsToggle"
        class="focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
      >
        <img :src="settingsImg" class="h-6" alt="" />
      </button>
      <div
        v-if="settingsToggle"
        class="absolute top-12 right-0 flex flex-col gap-1 z-10 w-[160px] p-1 rounded-md border-[1px] border-[#277dd2] bg-white"
      >
        <div
          v-for="(item, i) in [
            'Ad/Soyad',
            'Cep Telefonu',
            'Ev Telefonu',
            'E-mail',
            'Adres',
            'Anne Adı ',
            'Baba Adı ',
            'Doğum Yeri ',
            'Medeni Hali ',
            'Doğum Tarihi ',
          ]"
          v-bind:key="item"
          class="flex justify-between"
        >
          <p class="font-thin text-[#3C3C43]">{{ item }}</p>
          <button
            class="flex cursor-pointer items-center justify-between focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
            @click="
              medulaStore.medulaSearchFilterButton[i] =
                !medulaStore.medulaSearchFilterButton[i]
            "
          >
            <div
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                medulaStore.medulaSearchFilterButton[i] === true
                  ? 'bg-[#0457F8]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': medulaStore.medulaSearchFilterButton[i],
                }"
              ></div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <div
        v-for="(patientInfo, index) in patientInfos"
        v-bind:key="patientInfo"
      >
        <div
          v-if="medulaStore.medulaSearchFilterButton[index]"
          class="flex flex-col gap-3 mt-3"
        >
          <p class="font-medium text-[17px]">{{ patientInfo.name }}</p>
          <div v-for="(placeholder, i) in patientInfo.placeholder" :key="i">
            <input
              v-if="
                patientInfo.name !== 'Adres' ||
                (placeholder !== 'İl' && placeholder !== 'İlçe')
              "
              class="border rounded-md h-10 w-80 px-2 focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
              :class="!patientInfo.editable ? 'bg-[#d9d9d9]' : 'bg-white'"
              :disabled="!patientInfo.editable"
              v-model="patientInfo.value[i]"
              :placeholder="placeholder"
              @keypress="handleEnter($event)"
            />
            <Select2
              v-else-if="placeholder === 'İl'"
              class="w-[320px]"
              :options="getCitiesStore.getListCitiesResponse"
              :placeholder="placeholder"
              v-model="patientInfo.value[i]"
              @select="updatePatientInfos"
            />
            <Select2
              v-else-if="placeholder === 'İlçe'"
              class="w-[320px]"
              :options="districts"
              :placeholder="placeholder"
              v-model="patientInfo.value[i]"
              @select="updatePatientInfos"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from "vue";
import Select2 from "vue3-select2-component";

//asset imports
import settingsImg from "@/modules/asistanEkrani/assets/settingsImg.svg";

//store imports
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useUpdatePatientStore } from "@/store/modules/operationsStore/patient/useUpdatePatientStore";
import { useGetCities } from "@/store/modules/operationsStore/public/useGetCities";
import { useGetDistricts } from "@/store/modules/operationsStore/public/useGetDistricts";

//store inits
const medulaStore = useMedula();
const updatePatientStore = useUpdatePatientStore();

const settingsToggle = ref(false);
const getCitiesStore = useGetCities();
const getDistrictsStore = useGetDistricts();
const districts = ref(null);

const patientInfos = reactive([
  {
    name: "Ad/Soyad",
    placeholder: [""],
    value: [medulaStore.selectedPatient?.attributes?.name],
    editable: false,
  },
  {
    name: "Cep Telefonu",
    placeholder: ["(5321234567)"],
    value: [medulaStore.selectedPatient?.attributes?.phone_number],
    editable: true,
  },
  {
    name: "Ev Telefonu",
    placeholder: ["(2121234567)"],
    value: [medulaStore.selectedPatient?.attributes?.home_phone_number],
    editable: true,
  },
  {
    name: "E-mail",
    placeholder: ["kisi@gmail.com"],
    value: [medulaStore.selectedPatient?.attributes?.email],
    editable: true,
  },
  {
    name: "Adres",
    placeholder: ["İl", "İlçe", "Adres Detay"],
    value: [
      medulaStore.selectedPatient?.attributes?.province,
      medulaStore.selectedPatient?.attributes?.district,
      medulaStore.selectedPatient?.attributes?.address_description,
    ],
    editable: true,
  },
  {
    name: "Anne Adı",
    placeholder: [""],
    value: [
      medulaStore.selectedPatient?.attributes?.mother_name +
        " " +
        medulaStore.selectedPatient?.attributes?.surname,
    ],
    editable: false,
  },
  {
    name: "Baba Adı",
    placeholder: [""],
    value: [
      medulaStore.selectedPatient?.attributes?.father_name +
        " " +
        medulaStore.selectedPatient?.attributes?.surname,
    ],
    editable: false,
  },
  {
    name: "Doğum Yeri",
    placeholder: ["İl", "İlçe"],
    value: [
      medulaStore.selectedPatient?.attributes?.birthplace_province,
      medulaStore.selectedPatient?.attributes?.birthplace_district,
    ],
    editable: false,
  },
  {
    name: "Medeni Hali",
    placeholder: [""],
    value: [
      medulaStore.selectedPatient?.attributes?.marital_status ===
      "evli (resmen evli ya da birlikte yaşıyor)"
        ? "Evli"
        : medulaStore.selectedPatient?.attributes?.marital_status === "bekar"
        ? "Bekar"
        : medulaStore.selectedPatient?.attributes?.marital_status,
    ],
    editable: false,
  },
  {
    name: "Doğum Tarihi",
    placeholder: [""],
    value: [
      formatDateFromISO8601(medulaStore.selectedPatient?.attributes?.dob),
    ],
    editable: false,
  },
]);

onMounted(async () => {
  if (!patientInfos[4].value[0] || districts.value) {
    return false;
  }
  districts.value = await getDistrictsStore.getDistricts(
    patientInfos[4].value[0]
  );
});

watch(
  () => patientInfos[4].value[0],
  async (newValue, oldValue) => {
    districts.value = await getDistrictsStore.getDistricts(
      patientInfos[4].value[0]
    );
  }
);

const updatePatientInfos = async () => {
  await new Promise((resolve) => setTimeout(resolve, 0));
  const updatePatientReq = {
    patient_id: "",
    province: "",
    district: "",
    address_description: "",
    phone_number: "",
    home_phone_number: "",
    email: "",
  };
  updatePatientReq.patient_id = medulaStore.selectedPatient?.id;
  updatePatientReq.province = patientInfos[4].value[0];
  updatePatientReq.district = patientInfos[4].value[1];
  updatePatientReq.address_description = patientInfos[4].value[2];
  updatePatientReq.phone_number = patientInfos[1].value[0];
  updatePatientReq.home_phone_number = patientInfos[2].value[0];
  updatePatientReq.email = patientInfos[3].value[0];

  await updatePatientStore.updatePatient(updatePatientReq);
};

const handleEnter = async (e) => {
  if (e.key === "Enter") {
    await updatePatientInfos();
  }
};

function formatDateFromISO8601(isoDateString) {
  const dateObject = new Date(isoDateString);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Ay indeksi 0'dan başlar
  const year = dateObject.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;
  return formattedDate;
}
</script>
