import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useUpdateProvisionSettings = defineStore({
  id: "useUpdateProvisionSettings",
  state: () => ({}),
  actions: {
    async updateProvisionSettings(
      provisionType,
      exceptionalType,
      treatmentType,
      followType,
      treatmentKind
    ) {
      const personelStoreData = personelStore();
      await axios
        .put(
          `${adminDomain}/provision-settings`,
          {
            provizyon_tipi: provisionType,
            istisnai_hal: exceptionalType,
            tedavi_tipi: treatmentType,
            takip_tipi: followType,
            tedavi_turu: treatmentKind,
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("updateProvisionResponse", response);
        })
        .catch((error) => {
          console.log("updateProvisionError", error);
        });
    },
  },
});
