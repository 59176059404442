<template>
    <div class=" flex flex-col items-center  mt-28">
      <p class="font-medium text-xl">
        Öğretim Muayenesi Hakkında Bilgi
      </p>
      <div class="mt-2 flex flex-col max-w-[1035px]">
        <p class="">
          Ağız Diş Sağlığı Uygulama ve Araştırma Merkezimizden oluşan yoğun talebe cevap verebilmek ve siz kıymetli hastalarımıza daha çok hizmet edebilmek adına alanında uzman öğretim üyelerimiz tarafından " <span class="text-[#4198F7] font-medium">Mesai Dışı (İlave Ücretli) Öğretim Üyesi Yetişkin Hasta İlk Muayene</span> " randevuları açılmıştır. Öğretim üyesi ilk muayene randevuları merkezimiz web sitesinde yer alan randevu sayfası üzerindeki " <span class="text-[#4198F7] font-medium">Öğretim Üyesi Randevusu</span> " başlığı altından alınabilecektir.
        </p>
        <p class="flex justify-center mt-8 font-medium text-base">
          Mesai Dışı (İlave Ücretli) Öğretim Üyesi Yetişkin Hasta İlk Muayenesi Nedir?
        </p>
        <p class="mt-3  ">
          Sağlık Uygulama Tebliği'nin 1.9.1. maddesi gereğince, hafta içi öğretim üyeleri tarafından ilave ücret karşılığında “<span class="text-[#4198F7] font-medium">Muayene</span>” ve “<span class="text-[#4198F7] font-medium">Diş Tedavisi İşlemleri</span>” 
          yapılmaya başlanmıştır. Bu tedaviler alanında uzman diş hekimleri (öğretim üyesi) tarafından bizzat verilmektedir. Merkezimize randevu almadan doğrudan
          gelen hastalarımız mesai saatleri dışında tedaviye alınmayacaktır. Bu nedenle öğretim üyesi yetişkin hasta ilk muayenesi için muhakkak randevu alınması 
          gerekmektedir. Öğretim üyesi yetişkin hasta ilk muayene randevusu muhakkak web sayfamızdan alınacaktır.
        </p>
        <p class="flex justify-center mt-8 font-medium text-base">
          Mesai Dışı (İlave Ücretli) Öğretim Üyesi Yetişkin Hasta İlk Muayenesi Ücret Tarifesi Hakkında Bilgiler
        </p>
        <p class="mt-3 ">
          Bizzat öğretim üyesine muayene olmak isteyen hastalar için; Sağlık Uygulama Tebliği (SUT) ve Kamu Satış Tarifesi ile belirlenmiş olan poliklinik muayene 
          ücretinin iki katı kadar ilave ücreti,
        </p>
        <p class="mt-3 ">
          Bizzat öğretim üyesi tarafından verilen tetkik, tedavi, ameliyat vb. hizmetler için ise Sağlık Uygulama Tebliği ve Kamu Satış Tarifesi ile belirlenen hizmet
bedelinin en fazla bir katı kadar ilave ücret ödemeleri gerekmektedir.
        </p>
        <p class="mt-3 ">
          Ancak öğretim üyeleri tarafından mesai saatleri dışında bizzat verilen sağlık hizmetleri için yapılacak ödemede, Sosyal Güvenlik Kurumunca belirlenmiş 
sağlık hizmetleri bedelinin bir defada asgari ücretin iki katını geçemez.
        </p>
        <p class="mt-3 ">
          Sosyal güvencesi olmayan hastalarda ise Kamu Satış Tarifesi üzerinden ücretlendirme yapılır. Sosyal güvencesiz hasta için öğretim üyesi yetişkin hasta 
ilk muayene ücretlendirmesi konusunda detay almak için lütfen merkezimiz irtibat telefonlarını kullanınız.
        </p>

   

        
        <p class="flex items-center justify-center w-full mt-6 ">
          Öğretim Üyesi Muayene ile İlgili SAĞLIK UYGULAMA TEBLİĞİ 1.9 Maddesine istinaden İlave Ücret Uygulama Listesi aşağıdaki linktedir.
        </p>
  
      </div>
    </div>
  </template>
  
  <script setup>
  </script>
  
  
  <style scoped>


  </style>
  