import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useGetDoctorInfoStore = defineStore({
  id: "useGetDoctorInfo",
  state: () => ({
    getDoctorInfoResponse: null,
  }),
  actions: {
    async getDoctorInfo(id) {
      try {
        const personelStoreData = personelStore();
        const response = await axios.get(`${adminDomain}/staffs/doctor/${id}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        });
        console.log("getDoctorInfo", response.data);
        this.getDoctorInfoResponse = response.data;
        return response.data;
      } catch (error) {
        console.error("Error in getDoctorInfo:", error);
      }
    },
  },
});
