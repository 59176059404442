<template>
  <div class="flex w-full">
    <appointmentViewSideBar :activePage="2" />
    <div class="w-full bg-[#fbfbfb] flex flex-col">
      <AppointmentViewNavBar />
      <main class="p-4">
        <div
          class="px-2 py-[2px] rounded bg-[#277dd2] text-white flex items-center gap-2 w-fit"
        >
          <img :src="randevuOluşturAppointmentWhite" alt="" />
          <span class="mt-[2px]">Randevu Bilgileri</span>
        </div>
        <div
          class="bg-white text-[#464E5F] h-fit shadow px-4 py-10 flex flex-col gap-3"
        >
          <div
            class="sm:w-[550px] flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="font-medium text-xl">Randevu Tipi</span>
            <span
              class="w-[280px] sm:w-[365px] px-3 py-2 border rounded-[5px] truncate"
              >{{
                parsedType?.text
                  ? toTitleCase(parsedType?.text.replace(/_/g, " "))
                  : "\u00A0"
              }}</span
            >
          </div>
          <div
            class="sm:w-[550px] flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="font-medium text-xl">TC Kimlik No</span>
            <span
              class="w-[280px] sm:w-[365px] px-3 py-2 border rounded-[5px] truncate"
              >{{
                appointmentStore.patient?.attributes.tc_identity_number
              }}&nbsp;
            </span>
          </div>
          <div
            class="sm:w-[550px] flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="font-medium text-xl">Ad-Soyad</span>
            <span
              class="w-[280px] sm:w-[365px] px-3 py-2 border rounded-[5px] truncate"
              >{{ appointmentStore.patient?.attributes.name }}&nbsp;{{
                appointmentStore.patient?.attributes.surname
              }}</span
            >
          </div>
          <div
            class="sm:w-[550px] flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="font-medium text-xl">Hekim Adı</span>
            <span
              class="w-[280px] sm:w-[365px] px-3 py-2 border rounded-[5px] truncate"
              >{{
                parsedContained?.[0]?.name?.first
                  ? toTitleCase(
                      parsedContained[0].name.first +
                        " " +
                        parsedContained[0].name.last
                    )
                  : "\u00A0"
              }}</span
            >
          </div>
          <div
            class="sm:w-[550px] flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="font-medium text-xl">Klinik Adı</span>
            <span
              class="w-[280px] sm:w-[365px] px-3 py-2 border rounded-[5px] truncate"
              >&nbsp;</span
            >
          </div>
          <div
            class="sm:w-[550px] flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="font-medium text-xl">Birim Adı</span>
            <span
              class="w-[280px] sm:w-[365px] px-3 py-2 border rounded-[5px] truncate"
              >{{
                parsedContained?.[1].name
                  ? toTitleCase(parsedContained?.[1].name)
                  : "\u00A0"
              }}</span
            >
          </div>
          <div
            class="sm:w-[550px] flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="font-medium text-xl">Randevu Zamanı</span>
            <span
              class="w-[280px] sm:w-[365px] px-3 py-2 border rounded-[5px] truncate"
              >{{
                chosenSlot?.data.attributes.start_time
                  ? moment(chosenSlot?.data.attributes.start_time)
                      .utc()
                      .format("DD MMMM YYYY HH:mm")
                  : "\u00A0"
              }}</span
            >
          </div>
        </div>
        <div
          class="flex flex-col-reverse lg:flex-row mt-6 lg:mt-12 items-center justify-center gap-4 lg:gap-8"
        >
          <button
            @click="routePrevious"
            class="rounded w-72 text-[#E12901] border-[1px] border-[#E12901] bg-white font-medium text-lg h-12 flex items-center justify-center"
          >
            Geri Dön
          </button>
          <button
            @click="addAppointment"
            class="rounded w-72 text-[#277dd2] border-[1px] border-[#277dd2] bg-white font-medium text-lg h-12 flex items-center justify-center"
          >
            Onayla
          </button>
        </div>
      </main>
    </div>
    <appointmentHelpInfo />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import appointmentHelpInfo from "../components/appointmentHelpInfo.vue";

import moment from "moment";
import { useRouter } from "vue-router";
import addDays from "date-fns/addDays";

import appointmentViewSideBar from "../components/appointmentViewSideBar.vue";
import AppointmentViewNavBar from "../components/appointmentViewNavBar.vue";
import randevuOluşturAppointmentWhite from "../assets/randevuOluşturAppointmentWhite.svg";
import BolumIcon from "../assets/BolumIcon.svg";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useGetUnit } from "@/store/modules/administratorManagementStore/hospital/Units/getUnitStore";
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic.ts";
import { useGetSlot } from "@/store/modules/operationsStore/slot/getSlot";
const searchFreeSlotsForClinicStore = SearchFreeSlotsforClinic();
import { useAddVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useAddVEMAppointment";
const addAppointmentStore = useAddVEMAppointment();
import { useAppointmentStore } from "../../store/appointmentStore";

const appointmentStore = useAppointmentStore();

const unitListStore = useGetUnitsList();
import { toTitleCase } from "@/utils/formatString";
const getSlotStore = useGetSlot();
const router = useRouter();
const currentRouteName = ref(router.currentRoute.value.path);
let unitID = router.currentRoute.value.params.unitID;
let appointmentType = router.currentRoute.value.params.appointmentType;
let slotID = router.currentRoute.value.params.slotID;
const chosenSlot = ref(null);
const parsedContained = ref(null);
const parsedType = ref(null);

const selectedDate = ref();
const highlightedDates = ref();
const visibleStartDate = ref(new Date());
const visibleEndDate = ref();
const selectedSlot = ref(null);

onMounted(async () => {
  chosenSlot.value = await getSlotStore.getSlot(slotID);
  if (!chosenSlot.value) {
    router.push({ name: "createAppointmentSlot" });
  }
  console.log(chosenSlot.value);
  parsedContained.value = JSON.parse(
    chosenSlot.value.data.attributes.contained
  );
  parsedType.value = JSON.parse(chosenSlot.value.data.attributes.service_type);
  console.log(parsedType.value);
});

const routePrevious = () => {
  router.push({ name: "createAppointmentSlot" });
};

const addAppointment = async () => {
  await addAppointmentStore.addVemAppointment(
    appointmentStore.patient.id,
    slotID
  );
};
</script>
