import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import axios from "axios";
import _ from "lodash";

export const useGetSlot = defineStore({
  id: "useGetSlot",
  state: () => ({}),
  actions: {
    async getSlot(id: string) {
      try {
        const personelStoreData = personelStore();
        let token = localStorage.getItem("patientStoreData");
        let accessToken = token ? JSON.parse(token).accessToken : "";
        const response = await axios.get(`${adminDomain}/slots/${id}`, {
          headers: {
            Authorization: `Bearer ${
              accessToken || personelStoreData.personel!.token
            }`,
          },
        });
        console.log("getSlot", response.data);
        return response.data;
      } catch (error) {
        console.error("Error in getSlot:", error);
      }
    },
  },
});
