<template>
  <div
    v-if="radiologyStore.radiologyOpenSideBar"
    class="h-full w-full absolute bg-[#00000080]"
  >
    <div
      class="relative w-[671px] h-[764px] bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg"
    >
      <div class="flex flex-col h-full">
        <!-- Header -->
        <div
          class="flex items-center border-b-[1px] border-[#00000080] bg-[#9BC3FF33] py-2"
        >
          <p class="text-[#373A3C] text-2xl font-extrabold ml-3">
            Cihaz seç ve Yeni randevu oluştur
          </p>
          <div
            class="flex items-center ml-auto rounded-[10px] border-[1px] border-[#F65D58] bg-[#F65D581A] px-2 py-1 mr-1"
          >
            <div class="h-2 w-2 bg-[#F65D58] rounded-full"></div>
            <p class="text-[#F65D58] ml-1">Radyoloji</p>
          </div>
        </div>
        <!-- Doctor and patient info -->
        <div class="flex bg-[#9BC3FF33] py-3">
          <img :src="profileImg" alt="profileImg" class="ml-4" />
          <p class="font-semibold my-auto ml-1">Doç. Dr. Alper BALKAN</p>
          <div
            class="bg-[#F0AD4E] rounded-[5px] text-white text-xs px-2 ml-1 h-5 flex items-center"
          >
            İşleme alan doktor
          </div>
          <div class="flex ml-auto items-center mr-4">
            <img :src="profileImg" alt="profileImg" class="mr-4" />
            <p class="font-semibold my-auto">Hasta Nalan Çiçek</p>
          </div>
        </div>

        <!-- Select device -->
        <div class="flex flex-col">
          <div class="flex mt-10">
            <!-- Circle 1 -->
            <div class="flex flex-col ml-12">
              <div
                class="bg-[#9BC3FF66] w-[70px] h-[70px] rounded-full text-white text-[40px] font-medium flex items-center justify-center"
              >
                1
              </div>
              <div class="w-2 h-[167px] bg-[#9BC3FF66] ml-[30px]"></div>
            </div>
            <div class="flex flex-col bg-[#F9FBFF] w-[511px] h-[230px] ml-6">
              <div v-if="selectedDevice != null">
                <VueDatePicker
                  v-model="date"
                  locale="tr"
                  auto-apply
                  :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
                  class="datepicker w-[200px] border-[1px] border-[#277DD2] rounded-[5px] ml-auto mr-3"
                />
              </div>
              <div
                class="w-full min-h-[38px]"
                v-if="selectedDevice == null"
              ></div>

              <p class="text-2xl text-[#373A3C] font-bold ml-4">Cihaz</p>
              <button
                @click="showMenuItem = !showMenuItem"
                class="w-[90%] flex items-center justify-center mx-auto bg-white border-[1px] border-[#ADADAD] min-h-[47px] rounded-[6px]"
              >
                <img
                  v-if="selectedDevice == null"
                  :src="plusCircleBlue"
                  alt=""
                />
                <p
                  v-if="selectedDevice == null"
                  class="text-[#373A3C] font-bold ml-2"
                >
                  Cihaz seç
                </p>
                <p
                  v-if="selectedDevice != null"
                  class="text-[#373A3C] font-bold ml-2"
                >
                  {{ selectedDevice }}
                </p>
              </button>
              <div
                v-if="showMenuItem"
                class="w-[69%] absolute mt-[120px] flex flex-col items-center mx-auto right-10 bg-white border-[1px] border-[#ADADAD] max-h-[105px] overflow-auto rounded-[6px]"
              >
                <button
                  @click="selectTheDevice(item)"
                  v-for="(item, index) in deviceItems"
                  :key="index"
                  class="w-full deviceItem flex border-b-[1px] border-[#00000080]"
                >
                  <p class="ml-4 font-medium">{{ item }}</p>
                </button>
              </div>
              <div class="flex flex-col">
                <p class="text-[#373A3C] font-bold text-xl ml-6 mt-4">TARİH</p>
                <div class="flex">
                  <p class="text-[#373A3C] font-bold text-xl ml-6 mt-4">
                    4 Nisan , Cuma
                  </p>
                  <div class="ml-auto flex w-[200px]">
                    <button
                      class="w-[110px] h-[23px] px-2 py-2 focus:outline-none rounded-lg flex flex-row items-center justify-center border-[1px] bg-white border-[#5CB85C]"
                    >
                      <span class="font-medium text-[#5CB85C]"> 13:20 </span>
                      <span class="text-sm ml-2 font-medium text-[#5CB85C]">
                        14:10
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Kaydet Vazgec -->
        <div class="flex gap-3 mt-auto ml-auto mr-[66px] mb-[50px]">
          <button
            @click="radiologyStore.radiologyOpenSideBar = false"
            class="w-[130px] h-[55px] bg-[#B5B5B5] rounded-full text-white font-medium text-2xl"
          >
            Vazgeç
          </button>
          <button
            @click="radiologyStore.radiologyOpenSideBar = false"
            class="w-[130px] h-[55px] bg-[#277DD2] rounded-full text-white font-medium text-2xl"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>




<script setup>
// v-if="selectedDevice != null && date"
import profileImg from "../assets/profile_img.svg";
import plusCircleBlue from "../assets/plus_circle_blue.svg";
import { ref } from "vue";
import { useRadiologyStore } from "../store/radiologyStore.js";

const radiologyStore = useRadiologyStore();
const showMenuItem = ref(false);
const selectedDevice = ref(null);
const date = ref();
const deviceItems = ref([
  "Cihaz #1",
  "Cihaz #2",
  "Cihaz #3",
  "Cihaz #3",
  "Cihaz #3",
]);

const selectTheDevice = (item) => {
  selectedDevice.value = item;
  showMenuItem.value = false;
};
</script>

<style scoped>
.deviceItem:first-child {
  border-radius: 6px 6px 0 0;
}
</style>


   