<template>
  <div
    :class="personalStore.personalSidebarIsExpanded ? ' w-[230px]' : 'w-[81px]'"
    class="sidebarBG sticky top-0 flex flex-col h-screen"
  >
    <div
      class="flex mt-4 mx-auto mb-[26px] cursor-pointer"
      @click="toggleSideBar()"
    >
      <img :src="logo" alt="" />
      <div
        v-if="personalStore.personalSidebarIsExpanded"
        class="flex flex-col items-center ml-1"
      ></div>
    </div>
    <button
      v-if="personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 1;
          pushRouterToAnaSayfa();
        }
      "
      :class="
        personalStore.personalSideBarActiveButton == 1
          ? 'text-[#277DD2]'
          : 'text-[#8492A5]'
      "
      class="w-[220px] min-h-[35px] mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-3"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 1
            ? homePageIconBlue
            : homePageIcon
        "
        alt=""
        class="ml-[10px] mb-[1px]"
      />
      <p class="ml-[10px] font-medium">Ana Sayfa</p>
    </button>
    <button
      v-if="!personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 1;
          pushRouterToAnaSayfa();
          personalStore.personalSidebarIsExpanded = true;
        }
      "
      class="mx-auto flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] p-2 rounded-full w-[32px] h-[32px] mt-[16px]"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 1
            ? homePageIconBlue
            : homePageIcon
        "
        class="h-4 w-4"
        alt=""
      />
    </button>
    <!-- Hasta Listesi Extended Button -->
    <button
      v-if="personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 2;
          pushRouterToAddPatient();
        }
      "
      :class="
        personalStore.personalSideBarActiveButton == 2
          ? 'text-[#277DD2]'
          : 'text-[#8492A5]'
      "
      class="w-[220px] min-h-[35px] mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-3"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 2
            ? addPatientIconBlue
            : addPatientIcon
        "
        alt=""
        class="ml-[10px] mb-[1px]"
      />
      <p class="ml-[10px] font-medium">Yeni Hasta Kayıt</p>
    </button>
    <button
      v-if="!personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 2;
          pushRouterToAddPatient();
          personalStore.personalSidebarIsExpanded = true;
        }
      "
      class="mx-auto flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] p-2 rounded-full w-[32px] h-[32px] mt-[16px]"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 2
            ? addPatientIconBlue
            : addPatientIcon
        "
        class="h-4 w-4"
        alt=""
      />
    </button>
    <button
      v-if="personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 3;
          appointmentBookStore.calendarViewNavigation = 3;
          pushRouterToAjanda();
        }
      "
      :class="
        personalStore.personalSideBarActiveButton == 3
          ? 'text-[#277DD2]'
          : 'text-[#8492A5]'
      "
      class="w-[220px] min-h-[35px] mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-3"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 3
            ? createAppointmentIconBlue
            : createAppointmentIcon
        "
        alt=""
        class="ml-[10px] mb-[1px]"
      />
      <p class="ml-[10px] font-medium">Randevu Oluştur</p>
    </button>
    <button
      v-if="!personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 3;
          appointmentBookStore.calendarViewNavigation = 3;
          pushRouterToAjanda();
          personalStore.personalSidebarIsExpanded = true;
        }
      "
      class="mx-auto flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] p-2 rounded-full w-[32px] h-[32px] mt-[16px]"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 3
            ? createAppointmentIconBlue
            : createAppointmentIcon
        "
        class="h-4 w-4"
        alt=""
      />
    </button>
    <button
      v-if="personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 4;
          appointmentBookStore.calendarViewNavigation = 1;
          pushRouterToAjanda();
        }
      "
      :class="
        personalStore.personalSideBarActiveButton == 4
          ? 'text-[#277DD2]'
          : 'text-[#8492A5]'
      "
      class="w-[220px] min-h-[35px] mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-3"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 4
            ? appointmentListIconBlue
            : appointmentListIcon
        "
        alt=""
        class="ml-[10px] mb-[1px]"
      />
      <p class="ml-[10px] font-medium">Randevu Defteri</p>
    </button>
    <button
      v-if="!personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 4;

          pushRouterToAjanda();
          appointmentBookStore.calendarViewNavigation = 1;
          personalStore.personalSidebarIsExpanded = true;
        }
      "
      class="mx-auto flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] p-2 rounded-full w-[32px] h-[32px] mt-[16px]"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 4
            ? appointmentListIconBlue
            : appointmentListIcon
        "
        class="h-4 w-4"
        alt=""
      />
    </button>
    <button
      v-if="personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 5;
          pushRouterToPatientList();
        }
      "
      :class="
        personalStore.personalSideBarActiveButton == 5
          ? 'text-[#277DD2]'
          : 'text-[#8492A5]'
      "
      class="w-[220px] min-h-[35px] mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-10"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 5
            ? patientAdmissionIconBlue
            : patientAdmissionIcon
        "
        alt=""
        class="ml-[10px] mb-[1px]"
      />
      <p class="ml-[10px] font-medium">Hasta Kabul İşlemleri</p>
    </button>
    <button
      v-if="!personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 5;
          pushRouterToPatientList();
          personalStore.personalSidebarIsExpanded = true;
        }
      "
      class="mx-auto flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] p-2 rounded-full w-[32px] h-[32px] mt-[16px]"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 5
            ? patientAdmissionIconBlue
            : patientAdmissionIcon
        "
        class="h-4 w-4"
        alt=""
      />
    </button>
    <button
      v-if="personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 6;
          pushRouterToProvizyonAl();
        }
      "
      :class="
        personalStore.personalSideBarActiveButton == 6
          ? 'text-[#277DD2]'
          : 'text-[#8492A5]'
      "
      class="w-[220px] min-h-[35px] mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-3"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 6
            ? provisionIconBlue
            : provisionIcon
        "
        alt=""
        class="ml-[10px] mb-[1px]"
      />
      <p class="ml-[10px] font-medium">Provizyon İşlemleri</p>
    </button>
    <button
      v-if="!personalStore.personalSidebarIsExpanded"
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 6;
          pushRouterToProvizyonAl();
          personalStore.personalSidebarIsExpanded = true;
        }
      "
      class="mx-auto flex focus:outline-double focus:outline-1 focus:outline-[#277dd2] p-2 rounded-full w-[32px] h-[32px] mt-[16px]"
    >
      <img
        :src="
          personalStore.personalSideBarActiveButton == 6
            ? provisionIconBlue
            : provisionIcon
        "
        class="h-4 w-4"
        alt=""
      />
    </button>

    <!-- Toggle Menu Click -->
    <div @click="toggleSideBar()" class="h-full cursor-pointer"></div>
    <button
      @click="
        () => {
          personalStore.personalSideBarActiveButton = 7;
          pushRouterToSettings();
        }
      "
      class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-auto w-full min-h-[80px] items-center pl-4 flex"
      :class="
        personalStore.personalSideBarActiveButton === 7
          ? 'bg-[#287dd2] text-white'
          : 'bg-white text-[#8492A5]'
      "
    >
      <img
        v-if="personalStore.personalSideBarActiveButton === 7"
        :src="settingIconWhite"
        alt=""
      />
      <img v-else :src="settingIcon" alt="" />
      <div v-if="personalStore.personalSidebarIsExpanded" class="ml-2">
        <p class="font-medium">Ayarlar</p>
      </div>
    </button>
  </div>
</template>

<script setup>
import { router } from "@/router/index";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions";
import { usePersonalStore } from "@/modules/personel/store/personalStore";
import logo from "../assets/logo.svg";
import addPatientIcon from "../assets/addPatientIcon.svg";
import appointmentListIcon from "../assets/appointmentListIcon.svg";
import createAppointmentIcon from "../assets/createAppointmentIcon.svg";
import homePageIcon from "../assets/homePageIcon.svg";
import patientAdmissionIcon from "../assets/patientAdmissionIcon.svg";
import provisionIcon from "../assets/provisionIcon.svg";
import provisionIconBlue from "../assets/provisionIconBlue.svg";
import settingIcon from "../assets/settingIcon.svg";
import settingIconWhite from "../assets/settingIconWhite.svg";
import addPatientIconBlue from "../assets/addPatientIconBlue.svg";
import appointmentListIconBlue from "../assets/appointmentListIconBlue.svg";
import createAppointmentIconBlue from "../assets/createAppointmentIconBlue.svg";
import homePageIconBlue from "../assets/homePageIconBlue.svg";
import patientAdmissionIconBlue from "../assets/patientAdmissionIconBlue.svg";
import { useAppointmentBook } from "@/modules/asistanEkrani/components/appointmentBookStore";

defineProps({
  isMainDashboard: Boolean,
});
const personalStore = usePersonalStore();
const getCurrentRouter = useRouter();
const currentRouteName = ref(getCurrentRouter.currentRoute.value.path);
const personelStoreAuthActions = personelAuthActions();
const appointmentBookStore = useAppointmentBook();

const toggleSideBar = () => {
  personalStore.personalSidebarIsExpanded =
    !personalStore.personalSidebarIsExpanded;
};
const handleSideBarClick = (sideBarActiveButtonNum) => {
  personalStore.personalSideBarActiveButton = sideBarActiveButtonNum;
};

const pushRouterToAnaSayfa = () => {
  router.push("/personel/homePage");
};
const pushRouterToPatientList = () => {
  router.push("/assistantScreen/registered-patient");
};
const pushRouterToAjanda = () => {
  router.push("/asistantScreen/Calendar");
};
const pushRouterToAddPatient = () => {
  router.push("/assistantScreen/addPatient");
};
const pushRouterToProvizyonAl = () => {
  router.push("/asistantScreen/medula/homePage");
};

const pushRouterToSettings = () => {
  router.push("/assistantScreen/settings");
};
switch (currentRouteName.value) {
  case "/personel/homePage":
    personalStore.personalSidebarIsExpanded = true;
    personalStore.personalSideBarActiveButton = 1;
    break;
  case "/assistantScreen/addPatient":
    personalStore.personalSidebarIsExpanded = true;
    personalStore.personalSideBarActiveButton = 2;
    break;
  case "/assistantScreen/registered-patient":
    personalStore.personalSidebarIsExpanded = true;
    personalStore.personalSideBarActiveButton = 5;
    break;
  case "/asistantScreen/medula/homePage":
    personalStore.personalSidebarIsExpanded = true;
    personalStore.personalSideBarActiveButton = 6;
    break;
  case "/assistantScreen/settings":
    personalStore.personalSidebarIsExpanded = true;
    personalStore.personalSideBarActiveButton = 7;
    break;
}

const onHandleLogout = () => {
  personelStoreAuthActions.logout();
};
</script>

<style scoped>
.sidebarBG {
  border-radius: 8px;
  border-right: 1px solid rgba(9, 36, 75, 0.2);
  background: #fff;
}
/* .sidebarActiveButtonBG {
  border-radius: 10px;
  border: 1px solid #fff;
  background: #277dd2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
} */
/* .sidebarPassiveButtonBG {
  border-radius: 10px;
  background: white;
} */

/* .subNavigationHover:hover {
  background-color: rgba(82, 101, 129, 0.5);
}
.subNavigationActiveBG {
  background-color: rgb(82, 101, 129);
} */
</style>
