<template>
  <div
    class="min-h-[103px] flex items-center justify-between border-b-[0.5px] border-[#D9D9D9]"
  >
    <p class="ml-[60px] headerText">{{ props.headerTitle }}</p>
    <div class="flex items-center">
      <p class="text-xl font-medium mr-9">
        Kütahya Ağız Diş Sağlığı Uygulama ve Araştırma Merkezi Müdürlüğü
      </p>
      <button>
        <img :src="menuIcon" alt="" class="mr-[29px]" />
      </button>
      <div @click="arrowRef = !arrowRef" class="flex flex-col mr-[96px]">
        <button class="flex focus:outline-none">
          <img :src="profilePicture" alt="pp" class="mr-4" />

          <div class="flex flex-col items-start">
            <p>Nalan ÇİÇEK</p>
            <p class="text-[#00000080] text-xs">Asistan</p>
          </div>
          <img
            :src="dropDownArrow"
            class="h-2 w-2 ml-1 transform transition-transform duration-200 ease-in-out"
            :class="arrowRef ? 'rotate-180' : 'rotate-0'"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import menuIcon from "@/modules/personel/assets/menu_icon.svg";
import profilePicture from "@/modules/ekOdeme/assets/profilePicture.svg";
import dropDownArrow from "@/modules/asistanEkrani/registeredPatientList/assets/dropDownArrow.svg";

import { ref } from "vue";
const arrowRef = ref(false);
const props = defineProps({
  headerTitle: String,
});
</script>

<style scoped>
.headerText {
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
