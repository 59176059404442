import { smsBasePath } from "@/common/domains";
import axios from "axios";
import { globalStore } from "@/store/modules/globalStore";

const GetSms = {
  getSmsCode: (tcNo: string, dob: string, phoneNumber: string) => {
    axios
      .post(
        `${smsBasePath}/patients/login`,
        { tc_id_number: tcNo, dob: dob, phone_number: `0${phoneNumber}` },
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImY5NDFlNmQ0LWM1NjMtNGNiYS05ODg0LTcyMzk4NmU0OGJkNCIsInVzZXJJRCI6ImM1NTlmMzMzLWQ1ZmItNDM5MS05ZDkwLWJjYTEzY2Q2Y2IyNiIsInVzZXJuYW1lIjoiYW9rdGVtIiwicm9sZSI6Iml0LXN0YWZmIiwiaXNzdWVkX2F0IjoiMjAyNC0wNS0xNlQxMTo1OTozNC45MTY4ODUwNDNaIiwiZXhwaXJlZF9hdCI6IjIwMjQtMDUtMTdUMTE6NTk6MzQuOTE2ODg2MjU1WiJ9.D5QywTjWRL4eaJnPuMKyDzBgsd14n7Ggbi1OMmIpKv8",
          },
        }
      )
      .then((response) => {
        console.log("Response from get sms code:", response.data);
      })
      .catch((error) => {
        console.error("Error while getting sms code:", error);
      });
  },

  // verifySmsCode: (body: any) => {
  //   return axios
  //     .post(`${smsBasePath}/sms/verify`, body, {
  //       headers: {
  //         Authorization: "Basic bmFraXR0ZTolTjl2alZqdWluYiprNlN3",
  //         "Content-Type": "application/json",
  //       },
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       if (response.data.status === "true") {
  //         globalStore.getVerifySmsCodeStatus(true);
  //         console.log("getSmsCode.verifySmsCode Success");
  //       } else {
  //         globalStore.getVerifySmsCodeStatus(false);
  //         console.log("getSmsCode.verifySmsCode Fail Error");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error while sms verify:", error);
  //     });
  // },
};

export { GetSms };
