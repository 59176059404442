<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">TC NO</th>
        <th class="text-xl w-[200px] text-center">HASTA ADI</th>
        <th class="text-xl w-[300px] text-center">TEDAVİ DURUMU</th>
        <th class="text-xl w-[250px] text-center">OLUŞTURULMA TARİHİ</th>
        <th class="text-xl w-[200px] text-center">TESLİM TARİHİ</th>

        <th
          class="text-xl w-[200px] text-center"
          v-if="!popUpManagement.detailPopUp"
        >
          HEKİM ADI
        </th>
        <div v-if="!popUpManagement.detailPopUp" class="my-auto">
          <th></th>
        </div>
        <div v-if="popUpManagement.detailPopUp" class="my-auto">
          <th></th>
        </div>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(patient, index) in tempData.slice(
          (currentPage - 1) * 7,
          currentPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px]"
        :class="patient.disSiparisMi ? 'bg-[#EFECFB]' : 'bg-white'"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
            class="bg-white mt-1"
          />
        </td>
        <td class="w-[200px] text-center">
          {{ patient.tcKimlikNo }}
        </td>
        <td class="w-[200px] text-center">
          {{ patient.hastaAdi }}
        </td>
        <td class="w-[300px] text-center">
          {{ patient.tedaviDurumu }}
        </td>

        <td class="w-[250px] text-center">
          <p>
            {{ patient.olusturulmaTarihi }}
          </p>
        </td>
        <td class="w-[200px] text-center">
          {{ patient.teslimTarihi }}
        </td>

        <td class="w-[200px] text-center" v-if="!popUpManagement.detailPopUp">
          {{ patient.hekimAdi }}
        </td>
        <div v-if="!popUpManagement.detailPopUp">
          <td>
            <div
              class="cursor-pointer mt-2 w-fit ml-12 px-2"
              @click="
                popUpManagement.detailPopUp = true;
                activeIndex = index;
              "
            >
              <img :src="moreDotsIcon" alt="moreDots" />
            </div>
          </td>
        </div>
        <div v-if="popUpManagement.detailPopUp && activeIndex === index">
          <td>
            <div
              class="w-0 h-0 border-t-[20px] border-t-transparent border-r-[35px] border-r-blue-500 border-b-[20px] border-b-transparent"
            ></div>
          </td>
        </div>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from "vue";
import { tempData } from "../store/temporaryProtezData.js";
import moreDotsIcon from "../assets/moreDots.svg";
import { popUpManagement } from "../store/temporaryProtezData.js";
const activeIndex = ref(null);
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
  } else {
    activeIndex.value = index;
  }
};
defineProps(["currentPage"]);
</script>
