import { ref, reactive, watch } from "vue";

import { serviceDates } from "../serviceDateReactiveTypes";
const reactiveServiceDates = reactive(serviceDates);
watch(reactiveServiceDates, (newServiceDates) => {
  // Tooltip veya başka bir işlemi güncellemek için burada yeni verileri kullanabilirsiniz.
  console.log(newServiceDates, "serviceDates güncellendi:");
  console.log(tooltip.datasets[0], "tooltip güncellendi:");
  Chart.defaults.backgroundColor = "#277dd2";
  data.datasets[0].data = [
    calculateTotal(reactiveServiceDates.Ocak),
    calculateTotal(reactiveServiceDates.Şubat),
    calculateTotal(reactiveServiceDates.Mart),
    calculateTotal(reactiveServiceDates.Nisan),
    calculateTotal(reactiveServiceDates.Mayıs),
    calculateTotal(reactiveServiceDates.Haziran),
    calculateTotal(reactiveServiceDates.Temmuz),
    calculateTotal(reactiveServiceDates.Ağustos),
    calculateTotal(reactiveServiceDates.Eylül),
    calculateTotal(reactiveServiceDates.Ekim),
    calculateTotal(reactiveServiceDates.Kasım),
    calculateTotal(reactiveServiceDates.Aralık),
  ];
  tooltip.datasets[0].data = [
    calculateTotal(reactiveServiceDates.Ocak),
    reactiveServiceDates.Ocak[0],
    reactiveServiceDates.Ocak[1],
    reactiveServiceDates.Ocak[2],
    reactiveServiceDates.Ocak[3],
    reactiveServiceDates.Ocak[4],
    reactiveServiceDates.Ocak[5],
    reactiveServiceDates.Ocak[6],
    reactiveServiceDates.Ocak[7],
    reactiveServiceDates.Ocak[8],
    reactiveServiceDates.Ocak[9],
  ];
  tooltip.datasets[1].data = [
    calculateTotal(reactiveServiceDates.Şubat),
    reactiveServiceDates.Şubat[0],
    reactiveServiceDates.Şubat[1],
    reactiveServiceDates.Şubat[2],
    reactiveServiceDates.Şubat[3],
    reactiveServiceDates.Şubat[4],
    reactiveServiceDates.Şubat[5],
    reactiveServiceDates.Şubat[6],
    reactiveServiceDates.Şubat[7],
    reactiveServiceDates.Şubat[8],
    reactiveServiceDates.Şubat[9],
  ];
  tooltip.datasets[2].data = [
    calculateTotal(reactiveServiceDates.Mart),
    reactiveServiceDates.Mart[0],
    reactiveServiceDates.Mart[1],
    reactiveServiceDates.Mart[2],
    reactiveServiceDates.Mart[3],
    reactiveServiceDates.Mart[4],
    reactiveServiceDates.Mart[5],
    reactiveServiceDates.Mart[6],
    reactiveServiceDates.Mart[7],
    reactiveServiceDates.Mart[8],
    reactiveServiceDates.Mart[9],
  ];
  tooltip.datasets[3].data = [
    calculateTotal(reactiveServiceDates.Nisan),
    reactiveServiceDates.Nisan[0],
    reactiveServiceDates.Nisan[1],
    reactiveServiceDates.Nisan[2],
    reactiveServiceDates.Nisan[3],
    reactiveServiceDates.Nisan[4],
    reactiveServiceDates.Nisan[5],
    reactiveServiceDates.Nisan[6],
    reactiveServiceDates.Nisan[7],
    reactiveServiceDates.Nisan[8],
    reactiveServiceDates.Nisan[9],
  ];
  tooltip.datasets[4].data = [
    calculateTotal(reactiveServiceDates.Mayıs),
    reactiveServiceDates.Mayıs[0],
    reactiveServiceDates.Mayıs[1],
    reactiveServiceDates.Mayıs[2],
    reactiveServiceDates.Mayıs[3],
    reactiveServiceDates.Mayıs[4],
    reactiveServiceDates.Mayıs[5],
    reactiveServiceDates.Mayıs[6],
    reactiveServiceDates.Mayıs[7],
    reactiveServiceDates.Mayıs[8],
    reactiveServiceDates.Mayıs[9],
  ];
  tooltip.datasets[5].data = [
    calculateTotal(reactiveServiceDates.Haziran),
    reactiveServiceDates.Haziran[0],
    reactiveServiceDates.Haziran[1],
    reactiveServiceDates.Haziran[2],
    reactiveServiceDates.Haziran[3],
    reactiveServiceDates.Haziran[4],
    reactiveServiceDates.Haziran[5],
    reactiveServiceDates.Haziran[6],
    reactiveServiceDates.Haziran[7],
    reactiveServiceDates.Haziran[8],
    reactiveServiceDates.Haziran[9],
  ];
  tooltip.datasets[6].data = [
    calculateTotal(reactiveServiceDates.Temmuz),
    reactiveServiceDates.Temmuz[0],
    reactiveServiceDates.Temmuz[1],
    reactiveServiceDates.Temmuz[2],
    reactiveServiceDates.Temmuz[3],
    reactiveServiceDates.Temmuz[4],
    reactiveServiceDates.Temmuz[5],
    reactiveServiceDates.Temmuz[6],
    reactiveServiceDates.Temmuz[7],
    reactiveServiceDates.Temmuz[8],
    reactiveServiceDates.Temmuz[9],
  ];
  tooltip.datasets[7].data = [
    calculateTotal(reactiveServiceDates.Ağustos),
    reactiveServiceDates.Ağustos[0],
    reactiveServiceDates.Ağustos[1],
    reactiveServiceDates.Ağustos[2],
    reactiveServiceDates.Ağustos[3],
    reactiveServiceDates.Ağustos[4],
    reactiveServiceDates.Ağustos[5],
    reactiveServiceDates.Ağustos[6],
    reactiveServiceDates.Ağustos[7],
    reactiveServiceDates.Ağustos[8],
    reactiveServiceDates.Ağustos[9],
  ];
  tooltip.datasets[8].data = [
    calculateTotal(reactiveServiceDates.Eylül),
    reactiveServiceDates.Eylül[0],
    reactiveServiceDates.Eylül[1],
    reactiveServiceDates.Eylül[2],
    reactiveServiceDates.Eylül[3],
    reactiveServiceDates.Eylül[4],
    reactiveServiceDates.Eylül[5],
    reactiveServiceDates.Eylül[6],
    reactiveServiceDates.Eylül[7],
    reactiveServiceDates.Eylül[8],
    reactiveServiceDates.Eylül[9],
  ];
  tooltip.datasets[9].data = [
    calculateTotal(reactiveServiceDates.Ekim),
    reactiveServiceDates.Ekim[0],
    reactiveServiceDates.Ekim[1],
    reactiveServiceDates.Ekim[2],
    reactiveServiceDates.Ekim[3],
    reactiveServiceDates.Ekim[4],
    reactiveServiceDates.Ekim[5],
    reactiveServiceDates.Ekim[6],
    reactiveServiceDates.Ekim[7],
    reactiveServiceDates.Ekim[8],
    reactiveServiceDates.Ekim[9],
  ];
  tooltip.datasets[10].data = [
    calculateTotal(reactiveServiceDates.Kasım),
    reactiveServiceDates.Kasım[0],
    reactiveServiceDates.Kasım[1],
    reactiveServiceDates.Kasım[2],
    reactiveServiceDates.Kasım[3],
    reactiveServiceDates.Kasım[4],
    reactiveServiceDates.Kasım[5],
    reactiveServiceDates.Kasım[6],
    reactiveServiceDates.Kasım[7],
    reactiveServiceDates.Kasım[8],
    reactiveServiceDates.Kasım[9],
  ];
  tooltip.datasets[11].data = [
    calculateTotal(reactiveServiceDates.Aralık),
    reactiveServiceDates.Aralık[0],
    reactiveServiceDates.Aralık[1],
    reactiveServiceDates.Aralık[2],
    reactiveServiceDates.Aralık[3],
    reactiveServiceDates.Aralık[4],
    reactiveServiceDates.Aralık[5],
    reactiveServiceDates.Aralık[6],
    reactiveServiceDates.Aralık[7],
    reactiveServiceDates.Aralık[8],
    reactiveServiceDates.Aralık[9],
  ];
});

const calculateTotal = (month) => {
  const total = month.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return total;
};

export const data = {
  labels: [
    "Oca",
    "Şub",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Ağu",
    "Eyl",
    "Eki",
    "Kas",
    "Ara",
  ],
  datasets: [
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      borderColor: "#277dd2",
      data: [
        calculateTotal(reactiveServiceDates.Ocak),
        calculateTotal(reactiveServiceDates.Şubat),
        calculateTotal(reactiveServiceDates.Mart),
        calculateTotal(reactiveServiceDates.Nisan),
        calculateTotal(reactiveServiceDates.Mayıs),
        calculateTotal(reactiveServiceDates.Haziran),
        calculateTotal(reactiveServiceDates.Temmuz),
        calculateTotal(reactiveServiceDates.Ağustos),
        calculateTotal(reactiveServiceDates.Eylül),
        calculateTotal(reactiveServiceDates.Ekim),
        calculateTotal(reactiveServiceDates.Kasım),
        calculateTotal(reactiveServiceDates.Aralık),
      ],
      // backgroundColor: "red",
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
  ],
};
export const tooltip = reactive({
  labels: [
    [
      "Oca",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Şub",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Mar",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Nis",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "May",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Haz",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Tem",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Ağu",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Eyl",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Eki",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Kas",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
    [
      "Ara",
      "1-3",
      "4-6",
      "7-9",
      "10-12",
      "13-15",
      "16-18",
      "19-21",
      "22-24",
      "25-27",
      "28-31",
    ],
  ],
  datasets: [
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Ocak),
        reactiveServiceDates.Ocak[0],
        reactiveServiceDates.Ocak[1],
        reactiveServiceDates.Ocak[2],
        reactiveServiceDates.Ocak[3],
        reactiveServiceDates.Ocak[4],
        reactiveServiceDates.Ocak[5],
        reactiveServiceDates.Ocak[6],
        reactiveServiceDates.Ocak[7],
        reactiveServiceDates.Ocak[8],
        reactiveServiceDates.Ocak[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Şubat),
        reactiveServiceDates.Şubat[0],
        reactiveServiceDates.Şubat[1],
        reactiveServiceDates.Şubat[2],
        reactiveServiceDates.Şubat[3],
        reactiveServiceDates.Şubat[4],
        reactiveServiceDates.Şubat[5],
        reactiveServiceDates.Şubat[6],
        reactiveServiceDates.Şubat[7],
        reactiveServiceDates.Şubat[8],
        reactiveServiceDates.Şubat[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Mart),
        reactiveServiceDates.Mart[0],
        reactiveServiceDates.Mart[1],
        reactiveServiceDates.Mart[2],
        reactiveServiceDates.Mart[3],
        reactiveServiceDates.Mart[4],
        reactiveServiceDates.Mart[5],
        reactiveServiceDates.Mart[6],
        reactiveServiceDates.Mart[7],
        reactiveServiceDates.Mart[8],
        reactiveServiceDates.Mart[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Nisan),
        reactiveServiceDates.Nisan[0],
        reactiveServiceDates.Nisan[1],
        reactiveServiceDates.Nisan[2],
        reactiveServiceDates.Nisan[3],
        reactiveServiceDates.Nisan[4],
        reactiveServiceDates.Nisan[5],
        reactiveServiceDates.Nisan[6],
        reactiveServiceDates.Nisan[7],
        reactiveServiceDates.Nisan[8],
        reactiveServiceDates.Nisan[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Mayıs),
        reactiveServiceDates.Mayıs[0],
        reactiveServiceDates.Mayıs[1],
        reactiveServiceDates.Mayıs[2],
        reactiveServiceDates.Mayıs[3],
        reactiveServiceDates.Mayıs[4],
        reactiveServiceDates.Mayıs[5],
        reactiveServiceDates.Mayıs[6],
        reactiveServiceDates.Mayıs[7],
        reactiveServiceDates.Mayıs[8],
        reactiveServiceDates.Mayıs[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Haziran),
        reactiveServiceDates.Haziran[0],
        reactiveServiceDates.Haziran[1],
        reactiveServiceDates.Haziran[2],
        reactiveServiceDates.Haziran[3],
        reactiveServiceDates.Haziran[4],
        reactiveServiceDates.Haziran[5],
        reactiveServiceDates.Haziran[6],
        reactiveServiceDates.Haziran[7],
        reactiveServiceDates.Haziran[8],
        reactiveServiceDates.Haziran[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Temmuz),
        reactiveServiceDates.Temmuz[0],
        reactiveServiceDates.Temmuz[1],
        reactiveServiceDates.Temmuz[2],
        reactiveServiceDates.Temmuz[3],
        reactiveServiceDates.Temmuz[4],
        reactiveServiceDates.Temmuz[5],
        reactiveServiceDates.Temmuz[6],
        reactiveServiceDates.Temmuz[7],
        reactiveServiceDates.Temmuz[8],
        reactiveServiceDates.Temmuz[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Ağustos),
        reactiveServiceDates.Ağustos[0],
        reactiveServiceDates.Ağustos[1],
        reactiveServiceDates.Ağustos[2],
        reactiveServiceDates.Ağustos[3],
        reactiveServiceDates.Ağustos[4],
        reactiveServiceDates.Ağustos[5],
        reactiveServiceDates.Ağustos[6],
        reactiveServiceDates.Ağustos[7],
        reactiveServiceDates.Ağustos[8],
        reactiveServiceDates.Ağustos[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Eylül),
        reactiveServiceDates.Eylül[0],
        reactiveServiceDates.Eylül[1],
        reactiveServiceDates.Eylül[2],
        reactiveServiceDates.Eylül[3],
        reactiveServiceDates.Eylül[4],
        reactiveServiceDates.Eylül[5],
        reactiveServiceDates.Eylül[6],
        reactiveServiceDates.Eylül[7],
        reactiveServiceDates.Eylül[8],
        reactiveServiceDates.Eylül[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Ekim),
        reactiveServiceDates.Ekim[0],
        reactiveServiceDates.Ekim[1],
        reactiveServiceDates.Ekim[2],
        reactiveServiceDates.Ekim[3],
        reactiveServiceDates.Ekim[4],
        reactiveServiceDates.Ekim[5],
        reactiveServiceDates.Ekim[6],
        reactiveServiceDates.Ekim[7],
        reactiveServiceDates.Ekim[8],
        reactiveServiceDates.Ekim[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Kasım),
        reactiveServiceDates.Kasım[0],
        reactiveServiceDates.Kasım[1],
        reactiveServiceDates.Kasım[2],
        reactiveServiceDates.Kasım[3],
        reactiveServiceDates.Kasım[4],
        reactiveServiceDates.Kasım[5],
        reactiveServiceDates.Kasım[6],
        reactiveServiceDates.Kasım[7],
        reactiveServiceDates.Kasım[8],
        reactiveServiceDates.Kasım[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
    {
      label: "TOTAL HEKİM PUANI",
      backgroundColor: "#277DD2",
      data: [
        calculateTotal(reactiveServiceDates.Aralık),
        reactiveServiceDates.Aralık[0],
        reactiveServiceDates.Aralık[1],
        reactiveServiceDates.Aralık[2],
        reactiveServiceDates.Aralık[3],
        reactiveServiceDates.Aralık[4],
        reactiveServiceDates.Aralık[5],
        reactiveServiceDates.Aralık[6],
        reactiveServiceDates.Aralık[7],
        reactiveServiceDates.Aralık[8],
        reactiveServiceDates.Aralık[9],
      ],
      // borderRadius: 20,
      // barPercentage: 0.3,
    },
  ],
});

console.log(data.datasets);
