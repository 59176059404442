<template>
  <div class="flex w-full">
    <appointmentViewSideBar :activePage="2" />
    <div class="w-full bg-[#fbfbfb] flex flex-col">
      <AppointmentViewNavBar />
      <main class="p-4">
        <div
          class="px-2 py-[2px] rounded bg-[#277dd2] text-white flex items-center gap-2 w-fit"
        >
          <img :src="randevuOluşturAppointmentWhite" alt="" />
          <span class="mt-[2px]">Randevu Bilgileri</span>
        </div>
        <div
          class="bg-white text-[#464E5F] h-full shadow px-4 py-10 flex flex-col gap-7"
        >
          <div
            class="hidden lg:flex w-full max-w-[1500px] items-center justify-between mx-auto"
          >
            <div class="flex items-center gap-2">
              <img :src="BolumIcon" alt="" />
              <span class="text-xl">Birim Adı</span>
            </div>
            <span class="text-xl">Açıklama</span>
            <span class="text-xl">Randevu Saati</span>
          </div>
          <div class="w-full h-[1px] bg-[#C5CDD8]"></div>
          <div class="flex flex-col gap-3">
            <div
              class="p-2 rounded cursor-pointer"
              :class="
                selectedUnit === index
                  ? 'border-2 border-[#277dd2] bg-[#eaf2fb]'
                  : 'border'
              "
              @click="selectUnit(index)"
              v-for="(unit, index) in unitListStore.getListUnitsResponse"
              :key="index"
            >
              <div
                class="max-w-[1550px] w-full flex items-center justify-between mx-auto flex-wrap"
              >
                <div class="flex items-center gap-5 w-60">
                  <input
                    v-model="selectedUnit"
                    type="radio"
                    :id="'radio-' + index"
                    :value="index"
                    class="scale-150"
                  />
                  <label class="my-auto pt-[1px]" :for="'radio-' + index">{{
                    toTitleCase(unit.birim_adi)
                  }}</label>
                </div>
                <p class="w-[500px] break-all">
                  İlk muayene randevularımız her gün saat 09:00’da aktif
                  olmaktadır.
                </p>
                <span class="mr-20">09.00</span>
              </div>
            </div>
          </div>
          <button
            :disabled="selectedUnit === null"
            class="rounded w-60 h-10 mx-auto flex items-center justify-center"
            :class="
              selectedUnit !== null
                ? 'text-[#277dd2] border-[1px] border-[#277dd2] bg-white font-medium'
                : 'bg-[#d9d9d9] text-[#707070]'
            "
            @click="
              () =>
                router.push(
                  `${currentRouteName}/${unitListStore.getListUnitsResponse[selectedUnit].birim_kodu}`
                )
            "
          >
            Devam et
          </button>
        </div>
      </main>
    </div>
    <appointmentHelpInfo />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import appointmentHelpInfo from "../components/appointmentHelpInfo.vue";

import { useRouter } from "vue-router";
import appointmentViewSideBar from "../components/appointmentViewSideBar.vue";
import AppointmentViewNavBar from "../components/appointmentViewNavBar.vue";
import randevuOluşturAppointmentWhite from "../assets/randevuOluşturAppointmentWhite.svg";
import BolumIcon from "../assets/BolumIcon.svg";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
import { toTitleCase } from "@/utils/formatString";

const router = useRouter();
const currentRouteName = ref(router.currentRoute.value.path);

const selectedUnit = ref(null);

onMounted(async () => {
  // if (unitListStore.getListUnitsResponse) {
  //   return false;
  // change session control method to use this
  // }
  await unitListStore.getUnitsList();
  console.log(currentRouteName.value);
});

const selectUnit = (index) => {
  selectedUnit.value = index;
};
</script>
