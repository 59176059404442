<template>
  <div
    class="mx-auto w-[850px] bg-white py-3 border-2 border-l-[10px] border-l-[#287dd2] rounded-[10px] h-fit"
  >
    <div class="px-6 flex flex-col">
      <p class="font-medium text-lg w-full mb-3 bg-transparent text-center">
        <span class="text-red-600 text-2xl" v-if="formElement.is_required"
          >*</span
        >
        {{ formElement.question }}
      </p>
      <div>
        <div class="flex mb-3">
          <div class="w-[260px]">&nbsp;</div>
          <div class="flex flex-grow">
            <div
              class="flex-1 text-center font-medium"
              v-for="(column, index) in formElement.answer.columns"
              :key="index"
            >
              {{ column }}
            </div>
          </div>
        </div>
        <div
          class="flex-1 flex mb-2 bg-[#f5f5f5] p-2 rounded"
          v-for="(row, index) in formElement.answer.rows"
          :key="index"
        >
          <div class="w-[260px] text-sm">{{ row }}</div>
          <div class="flex flex-grow">
            <div
              v-for="(column, columnIndex) in formElement.answer.columns"
              :key="columnIndex"
              class="flex-1 flex"
            >
              <input
                class="w-5 h-5 mx-auto my-auto"
                type="radio"
                :id="`table_option_${uuid}${props.index}_${row}_${index}_${columnIndex}`"
                :name="`table_option_group_${uuid}${props.index}_${row}_${index}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
const uuid = ref(uuidv4());
const props = defineProps({
  index: Number,
  formElement: Object,
});
</script>
