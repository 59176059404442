import { defineStore } from "pinia";
import { DeleteProvisionResponse } from "@/types/operations/patientApplications/deleteProvisionResponse";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useSearchPatientApplications } from "@/store/modules/operationsStore/patient/useSearchPatientApplications";
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
import { personelStore } from "../../administratorManagementStore/personel";

export const useDeleteProvision = defineStore({
  id: "deleteProvision",
  state: () => ({
    deleteProvisionResponse: null as DeleteProvisionResponse | null,
  }),
  actions: {
    async deleteProvision(id: string) {
      try {
        const personelStoreData = personelStore();
        const response = await axios.post(
          `${clinicDomain}/patient-applications/provision/cancel`,
          { application_id: id },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("deleteProvision", response.data);
        return response.data;
      } catch (error) {
        console.log("error in deleteProvision", error);
      }
    },

    // async deleteProvision(id: string) {
    //   const RegisteredPatientListStore = useRegisteredPatientListStore();
    //   globalStore.customInfoCard = true;
    //   globalStore.customInfoType = "pending";
    //   await axios
    //     .post(
    //       `${clinicDomain}/patients/applications/cancelProvision?appID=${id}`,
    //       {
    //         appID: id,
    //       },
    //       {
    //         headers: {
    //           Authorization: patientAuthToken,
    //           //AcceptLanguage:--
    //         },
    //         withCredentials: true,
    //       }
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         setTimeout(() => {
    //           globalStore.customInfoCard = false;
    //           globalStore.customInfoType = "";
    //         }, 2000);

    //         globalStore.customInfoType = "success";
    //         RegisteredPatientListStore.editProvisionCardShow = false;
    //         this.deleteProvisionResponse = response.data;
    //         console.log(this.deleteProvisionResponse, "sil provizyon");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(id);
    //       setTimeout(() => {
    //         globalStore.customInfoCard = false;
    //         globalStore.customInfoType = "";
    //       }, 2000);
    //       console.log(error);
    //       globalStore.customInfoType = "fail";
    //       RegisteredPatientListStore.editProvisionCardShow = false;
    //     });
    // },
  },
});
