<template>
  <div
    class="relative z-50"
    @focusin="medulaStore.searchPatientSearchBarFocus = true"
    @focusout="handleFocusOut"
  >
    <searchBar />
    <searchResults
      v-if="
        medulaStore &&
        medulaStore.searchPatientInput &&
        medulaStore.searchPatientInput.length > 4 &&
        medulaStore.searchPatientSearchBarFocus
      "
    />
  </div>
</template>

<script setup>
//section imports
import searchResults from "@/modules/medula/provision/components/patientSearchSubs/searchResults.vue";
import searchBar from "./patientSearchSubs/searchBar.vue";

//store imports
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";

//store inits
const medulaStore = useMedula();

const handleFocusOut = () => {
  setTimeout(() => {
    if (!medulaStore.searchPatientButtonFocus) {
      medulaStore.searchPatientSearchBarFocus = false;
    }
  }, 0);
};
</script>
