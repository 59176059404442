<template>
  <div
    class="h-screen sticky top-0 left-0 bg-white text-[#6d7187] text-xl flex justify-center p-4"
  >
    <div class="h-full mx-4">
      <button
        class="focus:outline-none"
        @click="announcementsStore.isSidebarOpen = false"
      >
        <img :src="cross" alt="" />
      </button>
    </div>
    <div class="flex flex-col gap-12 mt-3">
      <button class="text-start">Ana sayfa</button>
      <button class="text-start" @click="router.push('/personel/homePage')">
        Hasta Kayıt Kabul Modülü
      </button>
      <button
        class="text-start"
        @click="router.push('/appointment/create-appointment')"
      >
        Randevu Modülü
      </button>
      <button class="text-start" @click="router.push('/polyclinic/homepage')">
        Poliklinik Modülü
      </button>
      <button class="text-start" @click="router.push('/radiology')">
        Radyoloji Modülü
      </button>
      <button class="text-start">
        Stok Takip, Satın Alma ve Demirbaş İşlemleri Modülü
      </button>
      <button class="text-start" @click="router.push('/protez')">
        Protez Laboratuvar Modülü
      </button>
      <button class="text-start">Muhasebe Modülü</button>
      <button class="text-start">Personel Bordro İşlemleri Modülü</button>
    </div>
  </div>
</template>

<script setup>
import cross from "../assets/cross.svg";
// import { isSidebarOpen } from "../store/announcementStore";
import { useAnnouncements } from "@/mainPage/store/announcementStore";
const announcementsStore = useAnnouncements();
import { useRouter } from "vue-router";
const router = useRouter();
</script>
