<template>
  <div class="h-full flex gap-8 text-[#464E5F]">
    <div class="px-7 py-4 rounded-lg border flex flex-col gap-2">
      <p class="font-medium text-xl">Hekim Puanı</p>
      <doctorTotalScoreBarChart />
      <div
        class="border gap-2 p-1 rounded-lg flex flex-col items-center justify-center"
      >
        <p>Toplam Hekim Puanı</p>
        <p class="font-medium text-xl">3940</p>
      </div>
      <div class="p-3 border rounded-lg flex flex-col gap-1">
        <div class="flex items-center justify-between">
          <p>Gelir Miktarı</p>
          <div class="flex items-center">
            <div class="static w-20 h-[1px] bg-[#464E5F]"></div>
            <div
              class="bg-[#026D4D] w-48 flex justify-center py-[2px] rounded-lg"
            >
              <p class="font-medium text-white">1430</p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <p>Gelire Dönüşmüş Puan</p>
          <div class="flex items-center">
            <div class="static w-20 h-[1px] bg-[#464E5F]"></div>
            <div
              class="bg-[#277DD2] w-48 flex justify-center py-[2px] rounded-lg"
            >
              <p class="font-medium text-white">430</p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <p>Gelire Dönüşmemiş Puan</p>
          <div class="flex items-center">
            <div class="static w-20 h-[1px] bg-[#464E5F]"></div>
            <div
              class="bg-[#F94144] w-48 flex justify-center py-[2px] rounded-lg"
            >
              <p class="font-medium text-white">130</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center gap-10 mt-1">
        <div class="flex-1">
          <div
            v-for="(month, index) in data.labels.slice(0, 6)"
            :key="index"
            class="flex items-center justify-between mt-2"
          >
            <div class="flex items-center gap-4">
              <div
                class="rounded-full w-4 h-4"
                :class="index === 1 ? 'bg-[#277DD2]' : 'bg-[#c3e1ff]'"
              ></div>
              <p>{{ months[index] }}</p>
            </div>
            <p>{{ data.datasets[0].data[index] }}</p>
          </div>
        </div>

        <div class="flex-1">
          <div
            v-for="(month, index) in data.labels.slice(6, 12)"
            class="flex items-center justify-between mt-2"
          >
            <div class="flex items-center gap-4">
              <div
                class="rounded-full w-4 h-4"
                :class="false ? 'bg-[#277DD2]' : 'bg-[#c3e1ff]'"
              ></div>
              <p>{{ months[index + 6] }}</p>
            </div>
            <p>{{ data.datasets[0].data[index + 6] }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-grow px-7 py-4 rounded-lg border flex flex-col gap-2">
      <div class="flex justify-between mb-3">
        <p class="font-medium text-xl">Hizmet Tablosu</p>
        <img :src="settingDotsCircleIcon" alt="" />
      </div>

      <div class="flex justify-between">
        <div class="flex gap-3">
          <div class="monthSelectComponent">
            <Select2
              class="w-[200px] rounded-full"
              :options="months"
              placeholder="Ay Seçiniz"
            />
          </div>
          <form
            class="flex h-[40px] px-2 items-center border-[1px] border-[#aaa] rounded-[30px] focus-within:outline-1 focus-within:outline-double focus-within:outline-[#277dd2]"
          >
            <img :src="searchLogo" alt="search" class="w-5 bg-transparent" />
            <input
              type="text"
              class="pl-2 bg-transparent focus:outline-none py-1"
              placeholder="Sut kodu ara"
            />
          </form>
        </div>
        <div class="flex gap-6 text-sm">
          <div
            class="py-1 w-48 border rounded-lg flex flex-col gap-1 items-center"
          >
            <p>Hizmet Kaydı Yapıldı</p>
            <div class="flex items-center gap-2">
              <div class="bg-[#050505] w-20 h-3 rounded-[50px]">
                <div class="bg-[#277DD2] w-1/2 h-full rounded-[50px]"></div>
              </div>
              <p class="font-medium">50.0%</p>
            </div>
          </div>
          <div
            class="py-1 px-3 min-w-[56px] border rounded-lg flex flex-col gap-1 items-center"
          >
            <p>Hizmet Kaydı Yapılmadı</p>
            <div class="flex items-center gap-2">
              <div class="bg-[#050505] w-20 h-3 rounded-[50px]">
                <div class="bg-[#277DD2] w-1/2 h-full rounded-[50px]"></div>
              </div>
              <p class="font-medium">50.0%</p>
            </div>
          </div>
        </div>
      </div>

      <div class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col">
        <div
          class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
        >
          <div class="h-[80%] w-[20%] flex items-center">
            <p class="font-medium ml-4">GÜN</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p class="font-medium">SUT</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p class="font-medium">İŞLEM SAYISI</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p class="font-medium">İŞLEM PUANI</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p class="font-medium">ÜCRET</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p class="font-medium">HİZMET KAYDI</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p class="font-medium">TOPLAM</p>
          </div>
        </div>
        <div
          v-for="(service, index) in [1, 2, 3, 4, 5, 6, 7]"
          class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
          :key="service + index"
        >
          <div class="h-[80%] w-[20%] flex items-center gap-2">
            <p class="ml-6">01</p>
          </div>
          <div
            class="h-[80%] w-[20%] flex flex-col justify-center items-center"
          >
            <p>401010</p>
            <p class="opacity-80 text-sm">Cad-Cam Uyg..</p>
          </div>
          <div
            class="h-[80%] w-[20%] flex flex-col justify-center items-center"
          >
            <p>15</p>
          </div>
          <div
            class="h-[80%] w-[20%] flex flex-col justify-center items-center"
          >
            <p>200</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p>500 TL</p>
          </div>
          <div class="h-[80%] w-[20%] flex justify-center items-center">
            <p>Yapıldı</p>
            <p class="opacity-80 text-sm">(13/15)</p>
          </div>
          <div
            class="h-[80%] w-[20%] flex flex-col justify-center items-center"
          >
            <p>300 İşlem Puanı</p>
            <p class="opacity-80 text-sm">700 TL Ücret</p>
          </div>
        </div>
      </div>

      <servicesPagination />
    </div>
  </div>
</template>

<style>
.monthSelectComponent .select2-selection {
  border-radius: 30px !important;
}

.monthSelectComponent .select2-selection,
.monthSelectComponent .select2-selection__rendered,
.monthSelectComponent .select2-selection__arrow {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 8px !important;
}

.examinationStatusSelectComponent .select2-selection,
.examinationStatusSelectComponent .select2-selection__rendered,
.examinationStatusSelectComponent .select2-selection__arrow {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 8px !important;
}

.monthSelectComponent .select2-selection__arrow {
  right: 10px !important;
  top: -1px !important;
}

.examinationStatusSelectComponent .select2-selection__arrow {
  right: 10px !important;
  top: -1px !important;
}
.monthSelectComponent .select2-container--open .select2-selection__arrow {
  top: -9px !important;
}

.examinationStatusSelectComponent
  .select2-container--open
  .select2-selection__arrow {
  top: -9px !important;
}

.monthSelectComponent .select2-selection__arrow b {
  border-width: 8px 6px 8px 6px !important;
  /* top: 7px !important; */
}

.examinationStatusSelectComponent .select2-selection__arrow b {
  border-width: 8px 6px 8px 6px !important;
  /* top: 7px !important; */
}
</style>

<script setup>
import servicesPagination from "./servicesPagination.vue";
import doctorTotalScoreBarChart from "./doctorTotalScoreBarChart.vue";
import { data, tooltip } from "./temporaryChartData/barChartConfig.js";
import { ref } from "vue";
import Select2 from "vue3-select2-component";
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";

import settingDotsCircleIcon from "@/modules/doctorSlots/assets/settingDotsCircleIcon.svg";
const months = ref([
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
]);
</script>
