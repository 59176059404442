import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useAddVEMAppointment = defineStore({
  id: "useAddVEMAppointment",

  actions: {
    async addVemAppointment(hastaKodu: string, slot_id: string) {
      const personelStoreData = personelStore();

      await axios
        .post(
          `${clinicDomain}/appointments/create`,

          {
            patient_id: hastaKodu,
            slot_id: slot_id,
          },

          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("addVemAppointment", response.data);
          globalStore.isCreateAppointmentSideBarOpen = false;
          globalStore.isAppointmentCreateSuccesful = true;
          setTimeout(() => {
            globalStore.isAppointmentCreateSuccesful = false;
          }, 2000);
        })
        .catch((error) => {
          console.error("Error addVemAppointment", error);
          globalStore.isAppointmentCreateFail = true;
          setTimeout(() => {
            globalStore.isAppointmentCreateFail = false;
          }, 2000);
          return false;
        });
    },
  },
});
