<template>
  <div class="w-full h-screen flex">
    <div
      class="flex flex-col bgLeftSide h-full min-w-[45%] items-center justify-center"
    >
      <img :src="logoWhite" alt="kütahyaüniversitesilogo" class="w-[300px]" />
      <img :src="whiteCircle" alt="circle" class="absolute left-0 bottom-0" />
      <img
        :src="whiteCircleRightOne"
        alt="circle"
        class="absolute left-0 bottom-0"
      />
    </div>
    <personelLoginSifremiUnuttum v-if="personalStore.iForgetMyPassword != 0" />
    <form
      v-if="personalStore.iForgetMyPassword == 0"
      name="login_form"
      class="h-full w-full"
      id="login_form"
      action=""
      autocomplete="on"
      @submit.prevent="onHandleLogin()"
    >
      <div class="ml-[15%] mt-[25%]">
        <p class="text-[#333333] font-bold text-[26px] mb-[35px]">Giriş Yap</p>
        <label class="flex cursor-pointer items-center" for="firstRadio">
          <div class="relative w-96">
            <img
              :src="mailBlack"
              class="absolute inset-y-0 px-4 mt-[20px] flex items-center"
              alt=""
            />
            <input
              :class="
                personalStore.isAuthPasswordFalse
                  ? 'bg-[#E9383833] w-96 h-14   border py-2 px-5  leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]'
                  : 'w-96 h-14  border py-2 px-5 leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]'
              "
              id="username"
              v-model="username"
              type="text"
              placeholder="Kullanıcı Adı"
              required
              autocomplete="username"
            />
          </div>
        </label>
        <label class="flex cursor-pointer items-center" for="firstRadio">
          <div className="relative  w-96">
            <img
              :src="personalStore.isAuthPasswordFalse ? lockRed : lockBlack"
              class="absolute inset-y-0 px-4 mt-3 flex items-center"
              alt=""
            />
            <input
              :class="
                personalStore.isAuthPasswordFalse
                  ? 'bg-[#E9383833] w-96 h-14   border py-2 px-5  leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]'
                  : 'w-96 h-14  border py-2 px-5 leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]'
              "
              id="password"
              v-model="password"
              placeholder="Şifre:"
              required
              autocomplete="current-password"
              :type="isPasswordVisible ? 'text' : 'password'"
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 focus:outline-none"
              @click="isPasswordVisible = !isPasswordVisible"
            >
              <img :src="eyeBlack" alt="eye" />
            </div>
          </div>
        </label>
      </div>
      <p
        v-if="personalStore.isAuthPasswordFalse"
        class="text-[#E93838] ml-[18%]"
      >
        Hatalı Şifre
      </p>
      <div class="flex ml-[15%] w-[383px] mt-2">
        <div class="flex items-center cursor-pointer">
          <input type="checkbox" for="beniHatirla" />
          <p class="ml-3 text-[#454545]">Beni hatırla</p>
        </div>
        <div class="ml-auto cursor-pointer">
          <p class="text-[#454545]" for="sifremiUnuttum">Şifremi unuttum</p>
        </div>
      </div>
      <button
        type="submit"
        :class="
          username && password
            ? 'focus:shadow-outline   h-14  bg-[#312CB5]  font-medium text-white shadow   mt-7 mb-7 '
            : 'focus:shadow-outline   h-14 cursor-not-allowed  bg-[#D9D9D9]  font-medium text-[#454545] shadow  mt-7 mb-7'
        "
        class="ml-[15%] w-[383px] rounded-[30px]"
        @click="onHandleLogin()"
      >
        Giriş Yap
      </button>
    </form>
  </div>
  <!-- <div v-if="isPersonelLoggedIn === true">
    <loginErrorPopUp userType="personel" />
  </div> -->
</template>
<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import logoWhite from "@/modules/personel/assets/logoWhite.svg";
import whiteCircle from "@/modules/personel/assets/whiteCircle.svg";
import eyeBlack from "@/modules/personel/assets/eye_black.svg";
import lockBlack from "@/modules/personel/assets/lock_black.svg";
import lockRed from "@/modules/personel/assets/lock_red.svg";
import mailBlack from "@/modules/personel/assets/mail_black.svg";
import whiteCircleRightOne from "@/modules/personel/assets/whiteCircleRightOne.svg";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions";
import loginErrorPopUp from "../../user/components/loginErrorPopUp";
import personelLoginSifremiUnuttum from "@/modules/personel/personelLogin/personelLoginSifremiUnuttum.vue";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { usePersonalStore } from "@/modules/personel/store/personalStore";
import { useRouter } from "vue-router";

const router = useRouter();
const isPasswordVisible = ref(false);

const personalStore = usePersonalStore();
const personelStoreData = personelStore();

const inputClass = ref(
  personalStore.isAuthPasswordFalse
    ? " bg-[#E9383833] w-96 h-14   border py-2 px-5  leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]"
    : " w-96 h-14  border py-2 px-5 leading-tight text-gray-800 focus:shadow-outline:#4338CA placeholder:font-medium rounded-[30px]"
);

const username = ref("");
const password = ref("");
const personelStoreAuthActions = personelAuthActions();

onMounted(async () => {
  await personelStoreAuthActions.checkSessionToken();
  if (personelStoreData.personelSessionActiveToken) {
    console.log("personel login oldu");
    router.push("/personel/homePage");
  }
});
watch(
  () => personelStoreData.personelSessionActiveToken,
  (newValue) => {
    console.log("personel degisti");

    if (newValue) {
      console.log("personel login oldu");
      router.push("/personel/homePage");
    }
  }
);
const onHandleLogin = () => {
  if (username.value && password.value) {
    personelStoreAuthActions.login({
      username: username.value,
      password: password.value,
    });
  }
};
</script>
<style>
.bgLeftSide {
  background: linear-gradient(
    180deg,
    rgba(39, 125, 210, 0.37) 0.42%,
    #277dd2 62.27%,
    #277dd2 100%
  );
}
</style>
