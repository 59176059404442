
<template>
  <div class="w-[1000px]">
    <div class="flex mt-4 ml-4 overflow-x-auto">
      <button
        v-for="(item, index) in buttunNums"
        :key="index"
        class="p-2 mx-2 w-[35px] h-[35px] flex items-center justify-center"
        :class="
          store.bigToothSelectedTeeth.includes(item) &&
          'shadow-md rounded-full bg-[#4198F7] text-white'
        "
        @click="store.bigToothSelectedTeeth.push(item)"
      >
        {{ item }}
      </button>
    </div>
    <div class="flex justify-between h-full">
      <div class="overflow-y-auto">
        <div
          v-for="(toothNumber, index) in store.bigToothSelectedTeeth"
          :key="index"
          class="flex flex-col w-[190px] mt-[50px] ml-4"
        >
          <div class="numberHeader w-[190px] flex justify-center">
            {{ toothNumber }}
          </div>
          <div
            v-for="line in [1, 2, 3]"
            :key="line"
            class="flex justify-center"
          >
            <img
              v-if="
                getToothImg(
                  line,
                  store.getdentalChartTeethNumberStatePosition(toothNumber)[1]
                )
              "
              :src="
                getToothImg(
                  line,
                  store.getdentalChartTeethNumberStatePosition(toothNumber)[1]
                )
              "
              alt="tooth"
              class="my-2"
            />
          </div>
        </div>
        <div class="h-[160px]"></div>
      </div>
      <div
        :class="
          store.bigToothInfoModalInModalFlow >= 2 ? ' mr-[15%]' : ' mr-[46%]'
        "
        class="flex"
      >
        <div class="flex flex-col items-center">
          <img
            :src="yellowBorderLine1Tooth1"
            alt=""
            class="w-[170px] mt-[65px]"
          />
          <img
            :src="yellowBorderLine2Tooth1"
            alt=""
            class="w-[170px] mt-[35px]"
          />
          <img
            :src="yellowBorderLine3Tooth1"
            alt=""
            class="w-[170px] mt-[35px]"
          />
        </div>
        <!-- Buttons -->
        <div
          v-if="store.bigToothInfoModalInModalFlow >= 2"
          class="flex flex-col items-center ml-[40px]"
        >
          <button
            @click="store.bigToothActiveArray[0] = 1"
            :class="
              store.bigToothActiveArray[0] == 1 && 'activeBigToothOrangeBorder'
            "
            class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] h-[35px] flex items-center mt-[150px] focus:outline-none"
          >
            <p
              :class="store.bigToothActiveArray[0] == 1 && 'text-[#FF7518]'"
              class="text-[#454545] text-xl"
            >
              Cercival Buccal
            </p>
          </button>
          <button
            @click="store.bigToothActiveArray[1] = 1"
            :class="
              store.bigToothActiveArray[1] == 1 && 'activeBigToothOrangeBorder'
            "
            class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] h-[35px] focus:outline-none flex items-center mt-[140px] w-[156px] justify-center"
          >
            <p
              :class="store.bigToothActiveArray[1] == 1 && 'text-[#FF7518]'"
              class="text-[#454545] text-xl"
            >
              Buccal
            </p>
          </button>
          <div class="flex gap-6 mt-3">
            <button
              @click="store.bigToothActiveArray[2] = 1"
              :class="
                store.bigToothActiveArray[2] == 1 &&
                'activeBigToothOrangeBorder'
              "
              class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] h-[35px] focus:outline-none flex items-center"
            >
              <p
                :class="store.bigToothActiveArray[2] == 1 && 'text-[#FF7518]'"
                class="text-[#454545] text-xl"
              >
                Mesial
              </p>
            </button>
            <button
              @click="store.bigToothActiveArray[3] = 1"
              :class="
                store.bigToothActiveArray[3] == 1 &&
                'activeBigToothOrangeBorder'
              "
              class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] h-[35px] focus:outline-none flex items-center"
            >
              <p
                :class="store.bigToothActiveArray[3] == 1 && 'text-[#FF7518]'"
                class="text-[#454545] text-xl"
              >
                Incisal
              </p>
            </button>
            <button
              @click="store.bigToothActiveArray[4] = 1"
              :class="
                store.bigToothActiveArray[4] == 1 &&
                'activeBigToothOrangeBorder'
              "
              class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] h-[35px] focus:outline-none flex items-center"
            >
              <p
                :class="store.bigToothActiveArray[4] == 1 && 'text-[#FF7518]'"
                class="text-[#454545] text-xl"
              >
                Distal
              </p>
            </button>
          </div>
          <button
            @click="store.bigToothActiveArray[5] = 1"
            :class="
              store.bigToothActiveArray[5] == 1 && 'activeBigToothOrangeBorder'
            "
            class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] h-[35px] focus:outline-none flex items-center mt-3 w-[156px] justify-center"
          >
            <p
              :class="store.bigToothActiveArray[5] == 1 && 'text-[#FF7518]'"
              class="text-[#454545] text-xl"
            >
              Palatal
            </p>
          </button>

          <button
            @click="store.bigToothActiveArray[6] = 1"
            :class="
              store.bigToothActiveArray[6] == 1 && 'activeBigToothOrangeBorder'
            "
            class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] h-[35px] mt-[80PX] focus:outline-none flex items-center"
          >
            <p
              :class="store.bigToothActiveArray[6] == 1 && 'text-[#FF7518]'"
              class="text-[#454545] text-xl"
            >
              Cercival Palatal
            </p>
          </button>
          <div class="flex gap-6 mt-3">
            <button
              @click="store.bigToothActiveArray[7] = 1"
              :class="
                store.bigToothActiveArray[7] == 1 &&
                'activeBigToothOrangeBorder'
              "
              class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] focus:outline-none flex items-center"
            >
              <p
                :class="store.bigToothActiveArray[7] == 1 && 'text-[#FF7518]'"
                class="text-[#454545] text-xl w-[70px]"
              >
                Class 4 Mesial
              </p>
            </button>
            <button
              @click="store.bigToothActiveArray[8] = 1"
              :class="
                store.bigToothActiveArray[8] == 1 &&
                'activeBigToothOrangeBorder'
              "
              class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] focus:outline-none flex items-center"
            >
              <p
                :class="store.bigToothActiveArray[8] == 1 && 'text-[#FF7518]'"
                class="text-[#454545] text-xl w-[70px]"
              >
                Class 4 Distal
              </p>
            </button>
          </div>
          <div class="flex gap-6 mt-3">
            <button
              @click="store.bigToothActiveArray[9] = 1"
              :class="
                (store.bigToothActiveArray[9] == 1 ||
                  store.bigToothActiveArray[1] == 1) &&
                'activeBigToothOrangeBorder'
              "
              class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] focus:outline-none flex items-center"
            >
              <p
                :class="
                  (store.bigToothActiveArray[9] == 1 ||
                    store.bigToothActiveArray[1] == 1) &&
                  'text-[#FF7518]'
                "
                class="text-[#454545] text-xl w-[70px]"
              >
                Buccal Surface
              </p>
            </button>
            <button
              @click="store.bigToothActiveArray[10] = 1"
              :class="
                store.bigToothActiveArray[10] == 1 &&
                'activeBigToothOrangeBorder'
              "
              class="p-2 border-dashed border-[1px] border-[#454545] rounded-[10px] focus:outline-none flex items-center"
            >
              <p
                :class="store.bigToothActiveArray[10] == 1 && 'text-[#FF7518]'"
                class="text-[#454545] text-xl w-[70px]"
              >
                Palatal Surface
              </p>
            </button>
          </div>
        </div>
        <!-- Buttons end -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDentalChart } from "../../dentalChartMain/dentalChartStore/dentalChartStore";
import yellowBorderLine1Tooth1 from "../../assets/yellowBorder/yellowBorderLine1Tooth1.svg";
import yellowBorderLine2Tooth1 from "../../assets/yellowBorder/yellowBorderLine2Tooth1.svg";
import yellowBorderLine3Tooth1 from "../../assets/yellowBorder/yellowBorderLine3Tooth1.svg";
const store = useDentalChart();
const buttunNums = [
  11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33,
  34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48,
];

const getToothImg = (line, num) => {
  if ((line == 2 || line == 5) && num >= 6 && num <= 11) {
    return null;
  }
  return require(`../../assets/blueBackground/blueBackgroundLine${line}Tooth${num}.svg`);
};
</script>

<style scoped>
.numberHeader {
  border-radius: 20px;
  border: 1px solid #fff;
  background: #dbeafe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.activeBigToothOrangeBorder {
  border-radius: 10px;
  border: 1px solid #ff7518;
  background: #fde1cd;
  box-shadow: 0px 4px 4px 0px rgba(255, 117, 24, 0.5);
}
</style>