<template>
  <div class="flex w-full">
    <personalSideBar />
    <div class="w-full flex flex-col">
      <personelHeaderNavbar title="Ayarlar" />
      <main class="p-4 flex flex-col gap-7 h-full">
        <!-- nav -->
        <div class="mx-auto font-medium">
          <button
            @click="activeTab = 1"
            class="rounded h-[67px] w-[200px] mr-[1px]"
            :class="
              activeTab === 1
                ? 'text-[#277dd2] border-b-2 border-[#277dd2] bg-white'
                : 'text-[#9ea1a4] bg-[#fbfbfb]'
            "
          >
            Klinik ve Hekim Ayarları
          </button>
          <button
            @click="activeTab = 2"
            class="rounded h-[67px] w-[200px]"
            :class="
              activeTab === 2
                ? 'text-[#277dd2] border-b-2 border-[#277dd2] bg-white'
                : 'text-[#9ea1a4] bg-[#fbfbfb]'
            "
          >
            Provizyon Ayarları
          </button>
        </div>
        <div
          class="text-[#F94144] bg-[#F94144] bg-opacity-10 px-4 py-3 rounded-md"
        >
          *Kilitle seçeneğini aktif duruma getirmeniz halinde varsayılan seçenek
          provizyon ekranında uygulanacaktır.
        </div>
        <provisionSettings v-if="activeTab === 2" />
        <clinicAndDoctorSettings v-if="activeTab === 1" />
      </main>
    </div>
  </div>
</template>


<script setup>
import { ref } from "vue";
import personalSideBar from "@/modules/personel/components/personalSideBar.vue";
import personelHeaderNavbar from "@/modules/personel/components/personelHeaderNavbar";
import provisionSettings from "../components/provisionSettings.vue";
import clinicAndDoctorSettings from "../components/clinicAndDoctorSettings.vue";
const activeTab = ref(2);
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import { onMounted } from "vue";
const staffListStore = useGetStaffList();
onMounted(async () => {
  if (staffListStore.getListStaffResponse) {
    return false;
  }
  await staffListStore.getStaffList();
});

import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});
</script>
