export const tempData = [
  {
    Başvuru_Tarihi: "2021-01-01",
    Başvuru_Saati: "10:00",
    tedavitipi: "Ayaktan",
    tedavituru: "Ağız Protez",
    status: "İstek",
    islemAdi: "Protez",
    istemTipi: "Röntgen",
    provizyonTipi: "MR",
    tcKimlikNo: "12345678910",
    hastaAdi: "Ali",
    hastaNo: "123456",
    basvuruNo: "1",
    islemYapanBirim: "Endodonti",
    islemYapanHekim: "Doç.Dr.Cengiz K.",
    BKEdenPersonel: "Neşe Karaböcek",
  },
  {
    Başvuru_Tarihi: "2021-01-01",
    Başvuru_Saati: "10:00",
    tedavitipi: "Ayaktan",
    tedavituru: "Ağız Protez",
    status: "İstek",
    islemAdi: "Protez",
    istemTipi: "Röntgen",
    provizyonTipi: "MR",
    tcKimlikNo: "12345678910",
    hastaAdi: "Ali",
    hastaNo: "123456",
    basvuruNo: "1",
    islemYapanBirim: "Endodonti",
    islemYapanHekim: "Doç.Dr.Cengiz K.",
    BKEdenPersonel: "Neşe Karaböcek",
  },
  {
    Başvuru_Tarihi: "2021-01-01",
    Başvuru_Saati: "10:00",
    tedavitipi: "Ayaktan",
    tedavituru: "Ağız Protez",
    status: "İstek",
    islemAdi: "Protez",
    istemTipi: "Röntgen",
    provizyonTipi: "MR",
    tcKimlikNo: "12345678910",
    hastaAdi: "Ali",
    hastaNo: "123456",
    basvuruNo: "1",
    islemYapanBirim: "Endodonti",
    islemYapanHekim: "Doç.Dr.Cengiz K.",
    BKEdenPersonel: "Neşe Karaböcek",
  },
];
