import { createRouter, createWebHistory } from "vue-router";
import klinikYonetimHomeView from "../../modules/klinikYonetim/view/klinikYonetimHomeView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";
import klinikYonetimUnitView from "@/modules/klinikYonetim/view/klinikYonetimUnitView.vue";
import { useKlinikYonetimStore } from "@/modules/klinikYonetim/store/klinikYonetim.js";

// Use the klinikYonetimStore.unit in your code

const routes = [
  {
    path: "/polyclinic/clinic-management",
    name: "klinikYonetimHomeView",
    component: klinikYonetimHomeView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/clinic-management/:unitID",
    name: "klinikYonetimUnitView",
    component: klinikYonetimUnitView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
