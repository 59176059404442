import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router/index";
import { globalStore } from "@/store/modules/globalStore";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import _ from "lodash";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";

export const provisionActions = defineStore({
  id: "getProvisionRead",
  actions: {
    async getProvisionRead() {
      const applicationProvisionStore = useApplicationProvisionStore();
      // const medulaStore = useMedula();
      const registeredPatientListStore = useRegisteredPatientListStore();

      await axios
        .post(
          `${clinicDomain}/patients/applications/readProvision?`,
          {
            // appID: applicationProvisionStore?.applicationProvisionRequest.appID,
          },
          {
            headers: {
              Authorization: patientAuthToken,
              //AcceptLanguage:--
            },
            withCredentials: true,
          }
        )
        // .post(
        //   `${clinicDomain}/patients/applications/readProvision?appID=030e5c83-edd4-46c1-9ead-ab1a47f3c6bd`,
        //   // `${clinicDomain}/patients/applications/readProvision?appID=${applicationProvisionStore?.applicationProvisionRequest.appID}`,
        //   // {
        //   //   appID: applicationProvisionStore?.applicationProvisionRequest.appID,
        //   // },
        //   {
        //     headers: {
        //       Authorization: patientAuthToken,
        //       //AcceptLanguage:--
        //     },
        //   }
        // )
        .then((res) => {
          if (res.status === 200) {
            console.log(
              "read icin yolluyoruz"
              // applicationProvisionStore?.applicationProvisionRequest.appID
            );
            console.log(
              "applicationProvisionActions.getApplicationProvisionBarcode Successful.",
              res.data
            );
            registeredPatientListStore.patientProvisionCardInfo =
              res.data.HastaKabulOkuReturn;
            // medulaStore.medulaProvizyon = res.data;
            registeredPatientListStore.updatingProvisionCard = _.cloneDeep(
              res.data.HastaKabulOkuReturn
            );
          }
        })
        .catch((err) => {
          console.log(
            "applicationProvisionActions.getApplicationProvisionBarcode Error : ",
            err
          );
        });
    },
  },
});
