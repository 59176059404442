<template>
  <div class="fixed w-full">
    <div class="flex w-full flex-col">
      <div class="flex w-full">
        <div class="mx-auto flex bg-white py-3">
          <div class="flex">
            <div class="flex flex-col items-center">
              <img :src="logo" alt="logo" class="h-[33px]" />
            </div>
            <div class="flex">
              <button class="ml-5 flex items-center">
                <img :src="germanFlag" alt="" />
              </button>
              <button class="ml-5 flex items-center">
                <img :src="englandFlag" alt="" />
              </button>
            </div>
          </div>
          <div
            class="navbarBackground ml-10 flex max-h-[82px] items-center gap-7"
          >
            <button @click="handleRedirectHomePage" class="flex">
              <p
                :class="
                  (route.path === '/landing' || route.path === '/') &&
                  'font-medium text-[#4198F7]'
                "
                class="flex items-center text-xl font-light"
              >
                Ana sayfa
              </p>
            </button>

            <div class="relative w-[240px] text-lg">
              <div class="flex">
                <button
                  class="flex w-full items-center bg-white"
                  @click="handleRedirectRandevuSureleriPage"
                  @blur="isOptionsExpanded = false"
                >
                  <p
                    :class="
                      (route.path === '/landing/randevu-sureleri' ||
                        route.path === '/landing/klinik-isleyis' ||
                        route.path === '/landing/randevu-isleyis') &&
                      'font-medium text-[#4198F7]'
                    "
                    class="mr-2 text-xl font-light"
                  >
                    Randevu Verme Süreleri
                  </p>
                </button>
                <button @click="isOptionsExpanded = !isOptionsExpanded">
                  <img
                    :src="dropDownArrow"
                    class="h-3 w-3 transform transition-transform duration-200 ease-in-out"
                    :class="isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
                  />
                </button>
              </div>
              <transition
                enter-active-class="transform transition duration-500 ease-custom"
                enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                enter-to-class="translate-y-0 scale-y-100 opacity-100"
                leave-active-class="transform transition duration-300 ease-custom"
                leave-class="translate-y-0 scale-y-100 opacity-100"
                leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
              >
                <ul
                  v-show="isOptionsExpanded"
                  class="absolute left-0 right-0 w-[255px] divide-y overflow-hidden rounded-lg border-2 border-[#B4B7B9] bg-white shadow-lg"
                >
                  <li
                    class="cursor-pointer px-3 py-2 transition-colors duration-300 hover:bg-gray-200"
                    @click="handleRedirectKlinikIsleyisPage"
                  >
                    <p class="flex justify-center text-base font-light">
                      Klinik İşleyiş Düzeni
                    </p>
                  </li>
                  <li
                    class="cursor-pointer px-3 py-2 transition-colors duration-300 hover:bg-gray-200"
                    @click="handleRedirectRandevuIsleyisPage"
                  >
                    <p class="text-base font-light">
                      Randevu İşlemleri Hakkında Bilgi
                    </p>
                  </li>
                </ul>
              </transition>
            </div>

            <button @click="handleRedirectOgretimUyesiPage" class="flex">
              <p
                :class="
                  route.path === '/landing/ogretim-uyesi' &&
                  'font-medium text-[#4198F7]'
                "
                class="flex items-center text-xl font-light"
              >
                Öğretim Üyesi Muayenesi
              </p>
            </button>
            <button @click="handleRedirectSorulanSorularPage" class="flex">
              <p
                :class="
                  route.path === '/landing/sorulan-sorular' &&
                  'font-medium text-[#4198F7]'
                "
                class="flex items-center text-xl font-light"
              >
                Sıkça Sorulan Sorular
              </p>
            </button>
            <button @click="handleRedirectHakkimizdaPage" class="flex">
              <p
                :class="
                  route.path === '/landing/hakkimizda' &&
                  'font-medium text-[#4198F7]'
                "
                class="flex items-center text-xl font-light"
              >
                Hakkımızda
              </p>
            </button>
          </div>
        </div>
      </div>
      <div class="flex ml-[75%]">
        <button
          @click="handleRedirectLoginPage"
          class="font-roboto randevuAlNavbarButton z-10 mr-4 flex h-11 w-32 items-center"
        >
          <p class="w-full items-center justify-center text-white">
            Randevu Al
          </p>
        </button>
        <button
          @click="handleRedirectHBYSGiris"
          class="font-roboto randevuAlNavbarButton z-10 mr-2 flex h-11 w-32 items-center"
        >
          <p class="w-full items-center justify-center text-white">
            HBYS Giriş
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
// import kutahyaSaglıkBilimleriLogo from "../assets/kutahyaSaglıkBilimleriLogo.svg";
import logo from "@/modules/personel/assets/logo.svg";

import englandFlag from "../assets/englandFlag.svg";
import germanFlag from "../assets/germanFlag.svg";
import router from "../../../../router/index";
import upArrowBlue from "../assets/upArrowBlue.svg";
import downArrowBlack from "../assets/downArrowBlack.svg";
import dropDownArrow from "@/modules/asistanEkrani/registeredPatientList/assets/dropDownArrow.svg";

import { useRoute } from "vue-router";
import { ref } from "vue";
const isOptionsExpanded = ref(false);
const route = useRoute();

const handleRedirectLoginPage = () => {
  router.push("/appointment/create-appointment");
};
const handleRedirectHBYSGiris = () => {
  router.push("/personel/loginPage");
};
const handleRedirectHomePage = () => {
  router.push("/landing");
};
const handleRedirectRandevuIsleyisPage = () => {
  router.push("/landing/randevu-isleyis");
};
const handleRedirectKlinikIsleyisPage = () => {
  router.push("/landing/klinik-isleyis");
};
const handleRedirectRandevuSureleriPage = () => {
  router.push("/landing/randevu-sureleri");
};
const handleRedirectOgretimUyesiPage = () => {
  router.push("/landing/ogretim-uyesi");
};
const handleRedirectHakkimizdaPage = () => {
  router.push("/landing/hakkimizda");
};
const handleRedirectSorulanSorularPage = () => {
  router.push("/landing/sorulan-sorular");
};
</script>

<style scoped>
p {
  font-family: "Roboto", sans-serif;
}
.navbarBackground {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(245, 245, 245, 0.5);
}

.activeNavbar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #4198f7;
}
.randevuAlNavbarButton {
  background: #5cb85c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
</style>
