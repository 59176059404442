import { createRouter, createWebHistory } from "vue-router";
import doctorScreensPlanView from "../../modules/doctorSlots/view/doctorScreensPlanView.vue";
import doctorScreensPerformanceView from "../../modules/doctorSlots/view/doctorScreensPerformanceView.vue";
import doctorScreensAppointmentsView from "../../modules/doctorSlots/view/doctorScreensAppointmentsView.vue";
import doctorScreensInfoView from "../../modules/doctorSlots/view/doctorScreensInfoView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/polyclinic/doctor-screens/infos",
    name: "doctorScreensInfoView",
    component: doctorScreensInfoView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/doctor-screens/plan",
    name: "doctorScreensPlanView",
    component: doctorScreensPlanView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/doctor-screens/performance",
    name: "doctorScreensPerformanceView",
    component: doctorScreensPerformanceView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/doctor-screens/appointments",
    name: "doctorScreensAppointmentsView",
    component: doctorScreensAppointmentsView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
