<template>
  <div class="flex w-full h-full relative">
    <protezSidebar :activeRoute="2" />
    <div class="flex flex-col w-full h-full">
      <div class="w-full py-3 flex gap-12 items-center px-10 border-b">
        <h2 class="text-2xl font-semibold text-black">Sipariş</h2>
      </div>
      <div class="flex flex-col mt-10 px-10 gap-4">
        <div class="flex items-center justify-between">
          <h2 class="text-xl font-medium">Oluşturulan Sipariş Bilgileri</h2>
          <div class="flex items-center gap-3">
            <span class="text-[#676767]">Sipariş oluşturulma tarihi</span>
            <span class="font-medium">{{
              new Date().toLocaleDateString("en-GB")
            }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-8 w-[1400px]">
          <div class="flex flex-col">
            <div
              class="ml-auto flex items-center gap-2 text-white bg-[#5CB85C] rounded-l-[20px] rounded-tr-[20px] py-1 px-4"
            >
              <img :src="checkCircle" class="w-[22px]" />
              <p>Siparişiniz başarılı bir şekilde oluşturulmuştur.</p>
            </div>
            <div class="border rounded py-2 px-4 flex flex-col">
              <div class="flex flex-col gap-4">
                <span class="font-medium">14 Numaralı Diş</span>
                <div class="gap-3 flex items-center">
                  <div class="flex items-center justify-between w-36">
                    <span class="text-[#676767]">Tedavi</span>
                    <span>------></span>
                  </div>
                  <span class="text-[#fb6b1a] font-medium">Sabit protez</span>
                </div>
                <div class="gap-3 flex items-center">
                  <div class="flex items-center justify-between w-36">
                    <span class="text-[#676767]">Tedavi paketi</span>
                    <span>-------></span>
                  </div>
                  <span class="text-[#fb6b1a] font-medium"
                    >Venner kron, akrilik</span
                  >
                </div>
                <div class="flex items-center justify-between w-[800px]">
                  <div class="gap-3 flex items-center">
                    <div class="flex items-center justify-between w-36">
                      <span class="text-[#676767]">Sipariş ID</span>
                      <span>------></span>
                    </div>
                    <span class="text-[#fb6b1a] font-medium">A125B50M7</span>
                  </div>
                  <div class="gap-3 flex items-center">
                    <div class="flex items-center justify-between w-36">
                      <span class="text-[#676767] font-medium">Fiyat</span>
                      <span>------></span>
                    </div>
                    <span class="text-[#fb6b1a] font-medium">2700TL</span>
                  </div>
                </div>
              </div>
              <button
                class="ml-auto font-medium py-1 px-3 rounded-[20px] bg-[#F2D5CC] text-[#BE2E00]"
              >
                Siparişi iptal et
              </button>
            </div>
          </div>
          <div class="flex flex-col">
            <div
              class="ml-auto flex items-center gap-2 text-white bg-[#5CB85C] rounded-l-[20px] rounded-tr-[20px] py-1 px-4"
            >
              <img :src="checkCircle" class="w-[22px]" />
              <p>Siparişiniz başarılı bir şekilde oluşturulmuştur.</p>
            </div>
            <div class="border rounded py-2 px-4 flex flex-col">
              <div class="flex flex-col gap-4">
                <span class="font-medium">35 Numaralı Diş</span>
                <div class="gap-3 flex items-center">
                  <div class="flex items-center justify-between w-36">
                    <span class="text-[#676767]">Tedavi</span>
                    <span>------></span>
                  </div>
                  <span class="text-[#fb6b1a] font-medium">Sabit protez</span>
                </div>
                <div class="gap-3 flex items-center">
                  <div class="flex items-center justify-between w-36">
                    <span class="text-[#676767]">Tedavi paketi</span>
                    <span>-------></span>
                  </div>
                  <span class="text-[#fb6b1a] font-medium"
                    >Jaket kron, akrilik</span
                  >
                </div>
                <div class="flex items-center justify-between w-[800px]">
                  <div class="gap-3 flex items-center">
                    <div class="flex items-center justify-between w-36">
                      <span class="text-[#676767]">Sipariş ID</span>
                      <span>------></span>
                    </div>
                    <span class="text-[#fb6b1a] font-medium">A125B50M7</span>
                  </div>
                  <div class="gap-3 flex items-center">
                    <div class="flex items-center justify-between w-36">
                      <span class="text-[#676767] font-medium">Fiyat</span>
                      <span>------></span>
                    </div>
                    <span class="text-[#fb6b1a] font-medium">2500TL</span>
                  </div>
                </div>
              </div>
              <button
                class="ml-auto font-medium py-1 px-3 rounded-[20px] bg-[#F2D5CC] text-[#BE2E00]"
              >
                Siparişi iptal et
              </button>
            </div>
          </div>
          <div class="flex items-center gap-6 ml-auto">
            <span class="font-medium text-[#676767]">Genel Toplam</span>
            <span class="font-bold text-xl">5.200 TL</span>
          </div>
        </div>
      </div>
      <div class="mx-auto flex items-center gap-12 mt-14">
        <button
          @click="$router.push('/protez/createOrders')"
          class="py-2 flex items-center justify-center border-[1px] w-[390px] border-[#277dd2] rounded-[10px] text-[#277dd2]"
        >
          Yeni sipariş oluştur
        </button>
        <button
          @click="$router.push('/protez')"
          class="py-2 flex items-center justify-center border-[1px] w-[390px] border-[#277dd2] rounded-[10px] text-white bg-[#277dd2]"
        >
          Ana sayfaya dön
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import checkCircle from "@/modules/protez/assets/checkCircle.svg";
import protezSidebar from "@/modules/protez/components/protezSidebar.vue";
import { useProthesisStore } from "@/modules/protez/store/prothesisStore";
const prothesisStore = useProthesisStore();
</script>
