import { createRouter, createWebHistory } from "vue-router";
import landingPageView from "../../modules/landingPage/ui/view/landingPageView.vue";
import landingPageTelephoneView from "../../modules/landingPage/ui/view/landingPageTelephoneView.vue";
import landingPageSikcaSorulanSorular from "../../modules/landingPage/ui/view/landingPageSikcaSorulanSorularView.vue";
import landingPageOgretimUyesiView from "../../modules/landingPage/ui/view/landingPageOgretimUyesiBilgiView.vue";
import landingPageRandevuIsleyisView from "../../modules/landingPage/ui/view/landingPageRandevuIsleyisView.vue";
import landingPageRandevuVermeSureleriView from "../../modules/landingPage/ui/view/landingPageRandevuVermeSureleriView.vue";
import landingPageKlinikIsleyisView from "../../modules/landingPage/ui/view/landingPageKlinikIsleyisView.vue";
import landingPageHakkimizda from "../../modules/landingPage/ui/view/landingPageHakkimizdaView.vue";

const routes = [
  { path: "/landing", name: "landing", component: landingPageView },
  {
    path: "/landing/telephone",
    name: "landingPageTelephoneView",
    component: landingPageTelephoneView,
  },
  {
    path: "/landing/randevu-isleyis",
    name: "landingPageRandevuIsleyisView",
    component: landingPageRandevuIsleyisView,
  },
  {
    path: "/landing/klinik-isleyis",
    name: "landingPageKlinikIsleyisView",
    component: landingPageKlinikIsleyisView,
  },
  {
    path: "/landing/randevu-sureleri",
    name: "landingPageRandevuVermeSureleriView",
    component: landingPageRandevuVermeSureleriView,
  },
  {
    path: "/landing/ogretim-uyesi",
    name: "landingPageOgretimUyesiView",
    component: landingPageOgretimUyesiView,
  },
  {
    path: "/landing/hakkimizda",
    name: "landingPageHakkimizda",
    component: landingPageHakkimizda,
  },
  {
    path: "/landing/sorulan-sorular",
    name: "landingPageSikcaSorulanSorular",
    component: landingPageSikcaSorulanSorular,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
