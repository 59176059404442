import { createRouter, createWebHistory } from "vue-router";
import createAppointmentType from "../../modules/appointment/ui/view/createAppointmentType.vue";
import createAppointmentUnit from "../../modules/appointment/ui/view/createAppointmentUnit.vue";
import createAppointmentSlot from "../../modules/appointment/ui/view/createAppointmentSlot.vue";
import createAppointmentFinish from "../../modules/appointment/ui/view/createAppointmentFinish.vue";
import appointmentTreatmentPlan from "../../modules/appointment/ui/view/appointmentTreatmentPlan.vue";
import appointmentMyAppointments from "../../modules/appointment/ui/view/appointmentMyAppointments.vue";
import appointmentPayments from "../../modules/appointment/ui/view/appointmentPayments.vue";
import patientProfile from "../../modules/appointment/ui/view/patientProfile.vue";
import { requirePatientAuth } from "../../components/requireAuth";

const validAppointmentTypes = [
  "normal-randevu",
  "mesai-disi-ogretim-uyesi",
  "implant-estetik-dis-klinigi",
];

const routes = [
  {
    path: "/appointment/create-appointment",
    name: "createAppointmentType",
    component: createAppointmentType,
    beforeEnter: requirePatientAuth,
  },
  {
    path: "/appointment/create-appointment/:appointmentType",
    name: "createAppointmentUnit",
    component: createAppointmentUnit,
    beforeEnter: async (to, from, next) => {
      await requirePatientAuth(to, from, async () => {
        const appointmentType = to.params.appointmentType;
        if (validAppointmentTypes.includes(appointmentType)) {
          next();
        } else {
          next({ name: "createAppointmentType" }); // Geçersizse yönlendirin
        }
      });
    },
  },
  {
    path: "/appointment/create-appointment/:appointmentType/:unitID",
    name: "createAppointmentSlot",
    component: createAppointmentSlot,
    beforeEnter: requirePatientAuth,
  },
  {
    path: "/appointment/create-appointment/:appointmentType/:unitID/:slotID",
    name: "createAppointmentFinish",
    component: createAppointmentFinish,
    beforeEnter: requirePatientAuth,
  },
  {
    path: "/appointment/treatment-plan",
    name: "appointmentTreatmentPlan",
    component: appointmentTreatmentPlan,
    beforeEnter: requirePatientAuth,
  },
  {
    path: "/appointment/my-appointments",
    name: "appointmentMyAppointments",
    component: appointmentMyAppointments,
    beforeEnter: requirePatientAuth,
  },
  {
    path: "/appointment/payments",
    name: "appointmentPayments",
    component: appointmentPayments,
    beforeEnter: requirePatientAuth,
  },
  {
    path: "/appointment/patient-profile",
    name: "patientProfile",
    component: patientProfile,
    beforeEnter: requirePatientAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
