import { defineStore } from "pinia";
export const useAsistanEkrani = defineStore("store", {
  id: "asistanEkraniStore",
  state: () => ({
    search: "",
    searchHastaSwitch: true,
    searchLabSwitch: true,
    searchTetkikSwitch: true,
    searchRadyolojiSwitch: true,
    assistantCalendarDetailButton: false,
    assistantCalendarListAppointment: [],
  }),
  getters: {
    searchGetter: () => this.search,
  },
  actions: {
    setSearch(search) {
      this.state.search = search;
    },
    toggleCalenderDetail() {
      this.assistantCalendarDetailButton = !this.assistantCalendarDetailButton;
    },
  },
});
