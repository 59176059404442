<template>
  <div class="flex w-full h-full">
    <protezSidebar :activeRoute="3" />
    <div class="flex flex-col w-full h-full pl-6 pr-28">
      <div class="w-full px-3 py-8 flex gap-12 items-center">
        <h2 class="text-4xl font-semibold text-[#526581]">Sipariş Listesi</h2>
        <div class="border flex items-center p-1 w-72 gap-4">
          <img :src="searchIcon" alt="searchIcon" />
          <input
            class="w-full focus:outline-none"
            type="text"
            placeholder="Sipariş ID ara"
          />
        </div>
      </div>
      <div class="mb-3">
        <div
          v-if="workOrdersPopUpManagement.getProductionPopUp"
          @click="getProduction"
          class="bg-[#eaf2fb] text-[#277DD2] border-[1px] border-[#277dd2] cursor-pointer flex itemx-center justify-center w-fit px-2 py-1 gap-2 rounded-full"
        >
          <img :src="getProductionIcon" alt="get production" />
          <p>Üretime al</p>
        </div>
      </div>
      <div class="flex">
        <div class="flex flex-col">
          <workOrdersTable :currentPage="currentPage" />

          <div class="mb-5 ml-auto mr-[50px]">
            <vue-awesome-paginate
              :total-items="filteredOrders.length"
              :items-per-page="7"
              :max-pages-shown="7"
              v-model="currentPage"
              :on-click="onClickHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import protezSidebar from "@/modules/protez/components/protezSidebar.vue";
import personelSideBar from "@/modules/personel/components/personalSideBar.vue";
import searchIcon from "../assets/searchIcon.svg";
import getProductionIcon from "../assets/getProductionIcon.svg";
import { workOrdersPopUpManagement } from "../store/temporaryProtezData";
import paginationPatientTable from "@/modules/asistanEkrani/registeredPatientList/components/paginationRegisteredPatientTable";
import workOrdersTable from "../components/protezWorkOrders/workOrdersTable.vue";
import { tempOrdersData } from "@/modules/protez/store/temporaryProtezData.js";
const filteredOrders = tempOrdersData.filter((order) => {
  return order.teknisyen;
});
const getProduction = () => {
  console.log("getProduction");
};
const onClickHandler = (page) => {
  console.log(page);
};
const currentPage = ref(1);
</script>
