<template>
  <div>
    <div
      @click="globalStore.isCreateAppointmentSideBarOpen = false"
      class="flex h-[66px] w-full justify-between navbarStyle"
      :class="{ 'disable-scroll': globalStore.isCreateAppointmentSideBarOpen }"
    >
      <div class="mt-4">
        <button class="ml-[27px]" @click="calendarRedirect('weekly')">
          <p
            :class="[
              'text-xl',
              'font-medium',
              pageName === 'weekly' ? 'text-[#0275D8]' : '',
            ]"
          >
            Haftalık
          </p>
        </button>
        <button class="ml-[27px]">
          <p
            :class="[
              'text-xl',
              'font-medium',
              pageName === 'monthly' ? 'text-[#0275D8]' : '',
            ]"
            @click="calendarRedirect('monthly')"
          >
            Aylık
          </p>
        </button>
      </div>
    </div>
    <div
      v-if="globalStore.isCreateAppointmentSideBarOpen"
      class="panel"
      name="panel-slide"
      id="panel"
    >
      <!-- <addNewAppointmentSideBar /> -->
    </div>

    <div>
      <div v-if="pageName == 'monthly'" class="overflow-hidden">
        <AsistantCalendarMonthlyView />
      </div>
      <div v-if="pageName == 'weekly'">
        <AsistantCalendarWeeklyView />
      </div>
    </div>
  </div>
</template>
<script setup>
import AsistantCalendarMonthlyView from "./radiologyMonthlyCalendar";
import AsistantCalendarWeeklyView from "./radiologyWeeklyCalendar";
import { ref } from "vue";
import { globalStore } from "@/store/modules/globalStore";

let pageName = ref("monthly");
const pageNumber = ref(1);

const calendarRedirect = (params) => {
  switch (params) {
    case "monthly":
      pageName.value = "monthly";
      pageNumber.value = 1;
      break;

    case "weekly":
      pageName.value = "weekly";
      pageNumber.value = 2;
      break;

    default:
      break;
  }
};
</script>

<style scoped>
.disable-scroll {
  overflow: hidden;
}

.panel {
  overflow-y: scroll;
  position: fixed;
  width: 40%;
  min-height: 100%;
  top: 0;
  right: -30%;
  background-color: #fbfbfb;
  animation: panelSlide 0.3s forwards;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.panel-hidden {
  right: -30%;
}
@keyframes panelSlide {
  0% {
    right: -30%;
  }
  100% {
    right: 0;
  }
}
.panel-slide-leave-active {
  animation: panelSlideOut 0.5s ease-out forwards;
  animation: panelSlideOut 0.5s ease-out backwards;
}

@keyframes panelSlideOut {
  0% {
    right: 0;
  }
  70% {
    right: -10%;
  }
  100% {
    right: -30%;
  }
}

.panel-slide-enter-active {
  animation: panelSlideIn 0.3s forwards;
}

.panel-slide-leave-active {
  animation: panelSlideOut 0.3s forwards;
}

.panel-slide-enter-from,
.panel-slide-leave-to {
  right: -30%;
}
.navbarStyle {
  border: 1px solid rgba(155, 195, 255, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.navbarInput {
  background: rgba(250, 250, 250, 0.93);
  border-radius: 10px;
}
</style>
