import { defineStore } from "pinia";

export const useAnamnesistore = defineStore("anamnesisFormStore", {
  id: "anamnesisFormStore",
  state: () => ({
    //unitView
    anamnesesUnitScreenUnitDatas: null,
    anamnesesUnitScreenDoctors: null,
    anamnesesNavbar: 1,

    //create anamnesis form

    draggingItem: null,
    anamnesisTemplateQuestions: [],

    activeSection: 0,
    sections: [
      {
        sectionName: "Bölüm 1",
        formElements: [
          {
            type: "singleChoice",
            question: "Question 1",
            options: ["Option 1", "Option 2"],
            isRequired: false,
          },
          {
            type: "multipleChoice",
            question: "Question 2",
            options: ["Option A", "Option B", "Option C"],
            isRequired: false,
          },
          {
            type: "shortAnswer",
            question: "Question 3",
            placeholder: "Yanıtlayınız",
            maxLength: 250,
            isRequired: true,
          },
          {
            type: "multipleChoiceTable",
            question: "Question 4",
            rows: ["Row 1", "Row 2"],
            columns: ["Column 1", "Column 2"],
            isRequired: false,
          },
          {
            type: "linearScale",
            question: "Question 5",
            scaleMin: 1,
            minPlaceholder: "En düşük",
            scaleMax: 5,
            maxPlaceholder: "En yüksek",
            isRequired: false,
          },
        ],
      },
      {
        sectionName: "Bölüm 2",
        formElements: [],
      },
    ],

    // backendExampleData: {
    //   data: {
    //     type: "anamnesis",
    //     id: 9,
    //     attributes: {
    //       class: "clinic-specific",
    //       template_name: "Diş Durumu Anamnezi",
    //       unit: "350392bb-80f7-43d4-a7f2-007b78c3361b",
    //       doctor_id: "483e06c4-3a6a-4956-94c5-516e412d154d",
    //       doctor_name: "Yasemin Beliz ÖNDER",
    //       sections: [
    //         {
    //           section_name: "Bölüm 1",
    //           form_elements: [
    //             {
    //               type: "singleChoice",
    //               question: "Sigara kullanıyor musunuz?",
    //               answer: {
    //                 options: ["Hayır", "Evet"],
    //               },
    //             },
    //             {
    //               type: "multipleChoiceTable",
    //               question: "Aşağıdaki tabloyu doldurunuz.",
    //               answer: {
    //                 rows: [
    //                   "Dişinize herhangi bir darbe aldınız mı?",
    //                   "Dişiniz üzerinde yakın zamanda bir işlem yapıldı mı?",
    //                   "Alerjiniz olan bir ilaç grubu var mı?",
    //                 ],
    //                 columns: ["Hayır", "Evet"],
    //               },
    //             },
    //           ],
    //         },
    //         {
    //           section_name: "Bölüm 2",
    //           form_elements: [
    //             {
    //               type: "multipleChoice",
    //               question: "Hangilerini tüketiyorsunuz?",
    //               answer: {
    //                 options: [
    //                   "Alkol",
    //                   "Tuzlu yiyecekler",
    //                   "Şekerli yiyecekler",
    //                 ],
    //               },
    //             },
    //             {
    //               type: "shortAnswer",
    //               question: "Ağrınızın başlama öyküsünü kısaca açıklayınız.",
    //               answer: {},
    //             },
    //             {
    //               type: "linearScale",
    //               question: "Hissettiğiniz ağrı düzeyini belirtin.",
    //               answer: {
    //                 scale_min: 1,
    //                 scale_max: 5,
    //               },
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   },
    // },

    exampleData: {
      type: "anamnesis_template",
      id: "c8def50e-3c01-40c7-af52-b4a184bfe18e",
      attributes: {
        class: "clinic-specific",
        templateName: "Example Template",
        templateDescription: "This is an example template",
        unit: "Pedodonti",
        doctorId: "c8def50e-3c01-40c7-af52-b4a184bfe18e",
        doctorName: "physician-123",
        sections: [
          {
            sectionName: "Bölüm 1",
            formElements: [
              {
                type: "singleChoice",
                question: "Sigara kullanıyor musunuz?",
                options: [
                  { text: "Evet", isChecked: false },
                  { text: "Hayır", isChecked: false },
                ],
                isRequired: false,
              },
              {
                type: "multipleChoice",
                question: "Hangilerini tüketiyorsunuz?",
                options: ["Alkol", "Tuzlu yiyecekler", "Şekerli yiyecekler"],
                isRequired: false,
              },
              {
                type: "shortAnswer",
                question: "Ağrınızın başlama öyküsünü kısaca açıklayınız.",
                placeholder: "Yanıtlayınız",
                maxLength: 250,
                isRequired: true,
              },
              {
                type: "multipleChoiceTable",
                question: "Aşağıdaki tabloyu doldurunuz.",
                rows: [
                  "Dişinize herhangi bir darbe aldınız mı?",
                  "Dişiniz üzerinde yakın zamanda bir işlem yapıldı mı?",
                  "Alerjiniz olan bir ilaç grubu var mı?",
                ],
                columns: ["Hayır", "Evet"],
                isRequired: true,
              },
              {
                type: "linearScale",
                question: "Hissettiğiniz ağrı düzeyini belirtin.",
                scaleMin: "1",
                minPlaceholder: "Az",
                scaleMax: "5",
                maxPlaceholder: "Çok",
                isRequired: true,
              },
            ],
          },
          {
            sectionName: "Bölüm 2",
            formElements: [
              {
                type: "linearScale",
                question: "Question 5",
                scaleMin: "1",
                minPlaceholder: "En düşük",
                scaleMax: "5",
                maxPlaceholder: "En yüksek",
                isRequired: true,
              },
              {
                type: "multipleChoice",
                question: "Question 2",
                options: ["Option A", "Option B", "Option C"],
                isRequired: false,
              },
              {
                type: "shortAnswer",
                question: "Question 3",
                placeholder: "Yanıtlayınız",
                maxLength: 250,
                isRequired: true,
              },
              {
                type: "multipleChoiceTable",
                question: "Question 4",
                rows: ["Row 1", "Row 2"],
                columns: ["Column 1", "Column 2"],
                isRequired: true,
              },
              {
                type: "singleChoice",
                question: "Question 2",
                options: [
                  { text: "Option 4", isChecked: false },
                  { text: "Option 132", isChecked: false },
                ],
                isRequired: false,
              },
            ],
          },
        ],
      },
    },
  }),
  actions: {},
});
