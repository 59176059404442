<template>
  <div class="flex-grow flex flex-col">
    <div class="border-b-2 border-b-[#287dd2]">
      <div class="px-10 py-3 border-b flex justify-between items-center">
        <div class="flex flex-col gap-3">
          <input
            class="text-xl font-medium w-[1100px]"
            type="text"
            placeholder="Anamnez Adı..."
            v-model="templateName"
          />
          <input
            class="w-[1100px]"
            type="text"
            placeholder="Anamnez Açıklaması..."
            v-model="templateDescription"
          />
        </div>
        <button
          @click="handleSubmit"
          class="py-2 px-4 text-white bg-[#277dd2] rounded-[30px]"
        >
          Kaydet
        </button>
      </div>

      <div class="flex gap-2 pt-2 px-10 items-center">
        <div
          v-for="(section, index) in anamnesisStore.sections"
          class="flex gap-1"
          draggable="true"
          @dragstart="
            anamnesisStore.draggingItem = {
              index: index,
              type: null,
            }
          "
          @dragover.prevent
          @drop="dropSection($event, index)"
        >
          <div
            class="text-white rounded-t-[15px] flex justify-center items-center w-[150px] h-[40px] cursor-pointer"
            :class="
              index === anamnesisStore.activeSection
                ? 'bg-black'
                : 'bg-[#287dd2]'
            "
            @click="anamnesisStore.activeSection = index"
          >
            <input
              type="text"
              class="w-full bg-transparent text-center placeholder:text-white"
              placeholder="Bölüm adı giriniz"
              v-model="section.sectionName"
            />
          </div>
          <button @click="confirmDelete(index)">
            <img :src="cross" alt="" />
          </button>
        </div>
        <button
          v-if="anamnesisStore.sections.length !== 7"
          @click="addSection()"
          class="flex gap-3 text-[#a0a3a6] ml-4"
        >
          <img :src="circlePlusGray" alt="" />
          <p>Yeni bölüm ekle</p>
        </button>
      </div>
    </div>
    <div
      class="bg-[#f5f6f9] py-10 flex-grow flex flex-col items-center gap-10 overflow-auto"
    >
      <div
        v-for="(formElement, index) in anamnesisStore.sections[
          anamnesisStore.activeSection
        ].formElements"
        :key="index"
        class="cursor-pointer"
      >
        <component
          :is="renderQuestion(formElement.type)"
          :index="index"
          formElement:formElement
          draggable="true"
          @dragstart="
            anamnesisStore.draggingItem = {
              index: index,
              type: formElement.type,
            }
          "
          @dragover.prevent
          @drop="dropItemToComponent($event, index)"
        />
      </div>
      <div
        @dragover.prevent
        @drop="dropItem"
        class="w-[850px] min-h-[143px] text-[#9b9ea3] text-lg border-2 border-dashed border-[#9b9ea3] flex items-center justify-center"
      >
        İstediğiniz soru tipini buraya sürükleyip bırakınız.
      </div>
    </div>
  </div>
</template>

<script setup>
import singleChoiceQuestion from "@/modules/anamnezForm/components/createAnamnesisView/questionTypes/singleChoiceQuestion.vue";
import multipleChoiceQuestion from "@/modules/anamnezForm/components/createAnamnesisView/questionTypes/multipleChoiceQuestion.vue";
import shortAnswerQuestion from "@/modules/anamnezForm/components/createAnamnesisView/questionTypes/shortAnswerQuestion.vue";
import multipleChoiceTableQuestion from "@/modules/anamnezForm/components/createAnamnesisView/questionTypes/multipleChoiceTableQuestion.vue";
import linearScaleQuestion from "@/modules/anamnezForm/components/createAnamnesisView/questionTypes/linearScaleQuestion.vue";
import cross from "@/modules/medula/provision/assets/crossIcon.svg";
import circlePlusGray from "@/modules/anamnezForm/assets/circlePlusGray.svg";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";

const templateName = ref("");
const templateDescription = ref("");
const confirmDelete = (index) => {
  if (anamnesisStore.sections.length <= 1) {
    return false;
  }
  if (confirm("Bu bölümü silmek istediğinize emin misiniz?")) {
    removeSection(index);
  }
};
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;
let anamnesisID = router.currentRoute.value.params.anamnesisID;

onMounted(() => {
  resetTemplate();
});

watch(
  () => anamnesisTemplateStore.getAnamnesisResponse,
  (newValue) => {
    if (anamnesisID) {
      templateName.value = newValue.data.attributes.template_name;
      // templateDescription.value = newValue.data.attributes.description;
      anamnesisStore.sections = newValue.data.attributes.sections.map(
        (section) => ({
          sectionName: section.section_name,
          formElements: section.form_elements.map((element) => {
            const {
              scale_min,
              scale_max,
              min_placeholder,
              max_placeholder,
              ...restOfAnswer
            } = element.answer;
            return {
              type: element.type,
              question: element.question,
              isRequired: element.is_required,
              scaleMin: scale_min?.toString(),
              scaleMax: scale_max?.toString(),
              minPlaceholder: min_placeholder,
              maxPlaceholder: max_placeholder,
              ...restOfAnswer,
            };
          }),
        })
      );
      console.log("mm", anamnesisStore.sections);
    }
  }
);

const resetTemplate = () => {
  anamnesisStore.sections = [
    {
      sectionName: "Bölüm 1",
      formElements: [],
    },
    {
      sectionName: "Bölüm 2",
      formElements: [],
    },
  ];
  anamnesisStore.activeSection = 0;
  templateName.value = "";
  templateDescription.value = "";
};

const renderQuestion = (question) => {
  const components = {
    singleChoice: singleChoiceQuestion,
    multipleChoice: multipleChoiceQuestion,
    multipleChoiceTable: multipleChoiceTableQuestion,
    shortAnswer: shortAnswerQuestion,
    linearScale: linearScaleQuestion,
  };
  return components[question] || null;
};
const insertItemAtIndex = (item, index) => {
  if (
    anamnesisStore.draggingItem.index === index ||
    anamnesisStore.draggingItem.index === null
  ) {
    return;
  }

  const currentItem = anamnesisStore.sections[
    anamnesisStore.activeSection
  ].formElements.splice(anamnesisStore.draggingItem.index, 1)[0];

  anamnesisStore.sections[anamnesisStore.activeSection].formElements.splice(
    index,
    0,
    currentItem
  );
};

const dropItemToComponent = (event, index) => {
  event.preventDefault();
  if (!anamnesisStore.draggingItem) return;
  if (anamnesisStore.draggingItem.index === null) {
    return;
  }

  const currentItem =
    anamnesisStore.sections[anamnesisStore.activeSection].formElements[
      anamnesisStore.draggingItem.index
    ];

  if (anamnesisStore.draggingItem.index === index) {
    return;
  }

  insertItemAtIndex(currentItem, index);
};

const dropSection = (event, item) => {
  event.preventDefault();
  if (!anamnesisStore.draggingItem) return;
  if (anamnesisStore.draggingItem.index === null) {
    return;
  }
  if (anamnesisStore.draggingItem.type !== null) {
    moveItemToSection(item);
    return;
  }
  moveSection(item);
};

const moveItemToSection = (item) => {
  anamnesisStore.sections[item].formElements.push(
    anamnesisStore.sections[anamnesisStore.activeSection].formElements[
      anamnesisStore.draggingItem.index
    ]
  );
  anamnesisStore.sections[anamnesisStore.activeSection].formElements.splice(
    anamnesisStore.draggingItem.index,
    1
  );
  anamnesisStore.activeSection = item;
};

const moveSection = (item) => {
  if (anamnesisStore.draggingItem.index === null) {
    const draggedSection = anamnesisStore.sections.splice(
      anamnesisStore.draggingItem.index,
      1
    );
    anamnesisStore.sections.splice(item, 0, draggedSection[0]);
    return;
  }
  const draggedSection = anamnesisStore.sections.splice(
    anamnesisStore.draggingItem.index,
    1
  );
  anamnesisStore.sections.splice(item, 0, draggedSection[0]);
  anamnesisStore.activeSection = item;
};

const dropItem = (event) => {
  event.preventDefault();
  const newItem = createNewItem();
  anamnesisStore.sections[anamnesisStore.activeSection].formElements.push(
    newItem
  );
};

const createNewItem = () => {
  const typeMap = {
    singleChoice: { question: "", options: ["", ""] },
    multipleChoice: { question: "", options: ["", ""] },
    multipleChoiceTable: { question: "", rows: ["", ""], columns: ["", ""] },
    shortAnswer: { question: "", placeholder: "" },
    linearScale: {
      question: "",
      scaleMin: "1",
      minPlaceholder: "",
      scaleMax: "5",
      maxPlaceholder: "",
    },
  };
  if (!anamnesisStore.draggingItem) return;
  return {
    type: anamnesisStore.draggingItem.type,
    ...typeMap[anamnesisStore.draggingItem.type],
    isRequired: false,
  };
};

const addSection = () => {
  if (anamnesisStore.sections.length === 7) {
    return false;
  }
  anamnesisStore.sections.push({
    sectionName: "Bölüm " + (anamnesisStore.sections.length + 1),
    formElements: [],
  });
  anamnesisStore.activeSection = anamnesisStore.sections.length - 1;
};

const removeSection = (index) => {
  if (anamnesisStore.sections.length === 1) {
    return;
  }
  anamnesisStore.activeSection = 0;
  anamnesisStore.sections.splice(index, 1);
};

const handleSubmit = async () => {
  if (anamnesisID) {
    await updateAnamnesis();
  } else {
    await addAnamnesis();
  }
};

const addAnamnesis = async () => {
  const anamnesisBody = {
    class: "clinic-specific",
    template_name: templateName.value,
    unit: unitID,
    doctor_id: JSON.parse(localStorage.getItem("personelStoreData")).staffId,
    sections: anamnesisStore.sections.map((section) => ({
      section_name: section.sectionName,
      form_elements: section.formElements.map((element) => {
        const answer = {};
        switch (element.type) {
          case "singleChoice":
          case "multipleChoice":
            answer.options = element.options;
            break;
          case "shortAnswer":
            answer.max_length = element.maxLength;
            break;
          case "multipleChoiceTable":
            answer.rows = element.rows;
            answer.columns = element.columns;
            break;
          case "linearScale":
            answer.scale_min = parseInt(element.scaleMin);
            answer.min_placeholder = element.minPlaceholder;
            answer.scale_max = parseInt(element.scaleMax);
            answer.max_placeholder = element.maxPlaceholder;
            break;
          default:
            break;
        }
        return {
          type: element.type,
          question: element.question,
          is_required: element.isRequired,
          answer: answer,
        };
      }),
    })),
  };
  await anamnesisTemplateStore.addAnamnesisTemplate(anamnesisBody);
  router.push(
    `/polyclinic/anamneses/${encodeURIComponent(unitID)}/read-anamnesis/${
      anamnesisTemplateStore.createAnamnesisResponse.data.id
    }`
  );
};

const updateAnamnesis = async () => {
  const anamnesisBody = {
    id: parseInt(anamnesisID),
    class: "clinic-specific",
    template_name: templateName.value,
    unit: unitID,
    doctor_id: JSON.parse(localStorage.getItem("personelStoreData")).staffId,
    sections: anamnesisStore.sections.map((section) => ({
      section_name: section.sectionName,
      form_elements: section.formElements.map((element) => {
        const answer = {};
        switch (element.type) {
          case "singleChoice":
          case "multipleChoice":
            answer.options = element.options;
            break;
          case "shortAnswer":
            answer.max_length = element.maxLength;
            break;
          case "multipleChoiceTable":
            answer.rows = element.rows;
            answer.columns = element.columns;
            break;
          case "linearScale":
            answer.scale_min = parseInt(element.scaleMin);
            answer.min_placeholder = element.minPlaceholder;
            answer.scale_max = parseInt(element.scaleMax);
            answer.max_placeholder = element.maxPlaceholder;
            break;
          default:
            break;
        }
        return {
          type: element.type,
          question: element.question,
          is_required: element.isRequired,
          answer: answer,
        };
      }),
    })),
  };
  await anamnesisTemplateStore.updateAnamnesisTemplate(anamnesisBody);
  router.push(
    `/polyclinic/anamneses/${encodeURIComponent(
      unitID
    )}/read-anamnesis/${anamnesisID}`
  );
};
</script>
