<template>
  <div class="relative flex">
    <!-- left sidebar -->
    <div class="h-screen border-r px-3 pt-28">
      <a href="/polyclinic/examinations">
        <img :src="calendarBlack" class="w-6 h-6" />
      </a>
    </div>
    <!-- content -->
    <div class="overflow-auto relative flex flex-col w-full">
      <personelHeaderNavBar title="Muayene İşlemleri" />
      <!-- patient info -->
      <div class="py-2 px-4 flex items-center gap-8">
        <div class="flex items-center gap-3">
          <img :src="profileCircle" alt="profile photo" />
          <div class="flex flex-col justify-between">
            <span class="text-red-500 text-xs">ARh(+)</span>
            <span class="text-[#282829] font-medium"
              >{{ patient?.attributes?.name }}
              {{ patient?.attributes?.surname }}</span
            >
            <!-- <span class="text-[#3C3C43] text-xs">(24 yıl 6 ay 14 gün)</span> -->
          </div>
        </div>
        <div class="flex items-center gap-4">
          <button class="rounded-full shadow p-2" href="">
            <img :src="userId" />
          </button>
          <button class="rounded-full shadow p-2"><img :src="enabiz" /></button>
        </div>
        <div class="flex items-center gap-8 text-xs">
          <div class="flex gap-6">
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">TC Kimlik No</p>
                <p class="font-medium">
                  {{ patient?.attributes?.tc_identity_number }}
                </p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Doğum Tarihi</p>
                <p class="font-medium">{{ patient?.attributes?.dob }}</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Hasta Türü</p>
                <p class="font-medium">SSK</p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">GSS Takip No</p>
                <p class="font-medium">14A9B4J</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Katkı Payı</p>
                <p class="font-medium">-</p>
              </div>
            </div>
          </div>

          <div class="flex gap-6">
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Hekim</p>
                <p class="font-medium">Yasemin Önder</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Öğrenci</p>
                <p class="font-medium">-</p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Arş. Gör.</p>
                <p class="font-medium">-</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Anestezi</p>
                <p class="font-medium">-</p>
              </div>
            </div>
          </div>
        </div>

        <button
          @click="
            dentalChartStore.dentalChartDiagnozSelectedButtonName = '';
            router.push(
              `/polyclinic/examinations/add-anamnesis/${$route.params.patientID}`
            );
          "
          class="ml-auto"
        >
          <img :src="cross" class="w-6 h-6" />
        </button>
      </div>
      <div class="flex bg-[#F5F5F5] flex-grow gap-5">
        <div class="flex-[3] bg-white h-full">
          <!-- icon  -->
          <div class="bg-[#F5F5F5] p-2 w-full flex justify-between">
            <div class="flex items-center gap-3">
              <img :src="tooth" class="w-6 h-6" />
              <img :src="medicine" class="w-6 h-6" />
              <img :src="medicalHistory" class="w-6 h-6" />
            </div>
            <div class="flex items-center gap-3">
              <button
                @click="
                  router.push(
                    `/polyclinic/examinations/icon-score/${$route.params.patientID}`
                  )
                "
                class="px-2 py-1 rounded-full text-xs border-[1px] border-[#73BC3B] text-[#73BC3B]"
              >
                Icon Index
              </button>
              <button
                @click="showSterilizationModal = true"
                class="px-2 py-1 rounded-full text-xs border-[1px] border-[#73BC3B] text-[#73BC3B]"
              >
                Sterilizasyon
              </button>
              <!-- <img :src="profileCircle" class="w-6 h-6" />
              <img :src="profileCircle" class="w-6 h-6" /> -->
            </div>
          </div>

          <!-- chart -->
          <div class="bg-white pt-2">
            <div class="flex gap-4 items-center text-xs px-3">
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Hepsini gör
              </button>
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Planlı
              </button>
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Tamamlanmış
              </button>
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Devam eden
              </button>
              <button
                class="ml-auto bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Tanı ekle
              </button>
            </div>
            <dentalChartTeethComponent class="pb-2" />
          </div>

          <!-- suts -->
          <div>
            <div class="bg-[#F5F5F5] w-full flex items-center border-y text-xs">
              <button
                class="font-medium w-[180px] h-10 flex items-center justify-center"
                :class="{
                  'bg-white text-[#277dd2]': activeUnit === 1,
                }"
                @click="activeUnit = 1"
              >
                Endodonti
              </button>
              <button
                class="font-medium w-[180px] h-10 flex items-center justify-center"
                :class="{
                  'bg-white text-[#277dd2]': activeUnit === 2,
                }"
                @click="activeUnit = 2"
              >
                Periodontoloji
              </button>
              <button
                class="font-medium w-[180px] h-10 flex items-center justify-center"
                :class="{
                  'bg-white text-[#277dd2]': activeUnit === 3,
                }"
                @click="activeUnit = 3"
              >
                Protez
              </button>
            </div>
            <div class="text-sm">
              <div class="flex">
                <div
                  class="border flex-[3] py-1 font-medium flex items-center justify-center"
                >
                  Sık kullanılan işlemler
                </div>
                <div
                  class="border flex-[2] py-1 font-medium flex items-center justify-center"
                >
                  Sut kodu ara
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex-[3] p-2 h-[175px] overflow-auto border-x flex gap-3 text-xs flex-wrap items-start"
                >
                  <div class="flex gap-3 flex-wrap">
                    <button
                      v-for="sut in oneCikanSutKodlari"
                      @click="selectButton(sut)"
                      class="border rounded-xl h-8 w-[300px] flex items-center gap-3"
                      :class="{
                        'text-[#277dd2]':
                          dentalChartStore.dentalChartDiagnozSelectedButtonName ===
                          sut.adi,
                      }"
                    >
                      <div class="bg-[#277dd2] h-full w-1 rounded-l-lg"></div>
                      {{ sut.sutKodu }}
                      {{ sut.adi }}
                    </button>
                  </div>
                </div>
                <div class="flex p-[6px] flex-col gap-3 border-x flex-[2]">
                  <input
                    class="border text-sm rounded-lg py-1 px-2"
                    type="text"
                    v-model="searchTerm"
                    @input="filterSutCodes"
                    placeholder="SUT Kodu ara..."
                  />

                  <div
                    v-if="searchTerm.trim().length >= 2"
                    class="flex flex-col gap-3 h-[135px] overflow-auto"
                  >
                    <button
                      v-for="sut in filteredSutCodes"
                      @click="selectButton(sut)"
                      class="border text-xs rounded-3xl py-2 px-4 text-start"
                      :class="{
                        'text-[#277dd2]':
                          dentalChartStore.dentalChartDiagnozSelectedButtonName ===
                          sut.adi,
                      }"
                    >
                      {{ sut.sutKodu }}
                      {{ sut.adi }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- plan -->
        <examinationPlan />
      </div>
    </div>
    <iconScoreModal
      v-if="showIconScoreModal"
      :closeIconScoreModal="closeIconScoreModal"
    />
    <sterilizationModal
      v-if="showSterilizationModal"
      :closeSterilizationModal="closeSterilizationModal"
    />
    <dentalChartCursorFollower />
  </div>
</template>

<script setup>
import sterilizationModal from "@/modules/doctorScreen/components/doctorDentalChart/sterilizationModal.vue";
import cross from "@/modules/medula/provision/assets/crossIcon.svg";
import plus from "@/modules/protez/assets/plus.svg";
import enabiz from "@/modules/doctorScreen/assets/enabiz.png";
import userId from "@/modules/doctorScreen/assets/userId.svg";
import tooth from "@/modules/doctorScreen/assets/tooth.svg";
import medicine from "@/modules/doctorScreen/assets/medicine.svg";
import medicalHistory from "@/modules/doctorScreen/assets/medicalHistory.svg";
import calendarBlack from "@/modules/doctorSlots/assets/calendarBlack.svg";
import personelHeaderNavBar from "@/modules/personel/components/personelHeaderNavbar.vue";
import { oneCikanSutKodlari, sutKodlari } from "./data";
import examinationPlan from "../components/doctorDentalChart/examinationPlan.vue";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import patientCard from "@/modules/doctorScreen/components/doctorPatientList/patientCard.vue";
import navAndSearch from "@/modules/doctorScreen/components/doctorPatientList/navAndSearch.vue";
import pastActivities from "@/modules/doctorScreen/components/doctorPatientList/pastActivities.vue";
import chosenExamination from "../components/doctorPatientList/pastActivitiesSubs/chosenExamination.vue";
import { useDentalChart } from "@/modules/dentalChart/dentalChartMain/dentalChartStore/dentalChartStore";
const dentalChartStore = useDentalChart();
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
import { onMounted, ref } from "vue";
const pastActivitiesStore = usePastActivitiesStore();
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useRouter } from "vue-router";
import { useDentalChartPlanStore } from "@/modules/doctorScreen/store/dentalChartPlanStore";
const dentalChartPlanStore = useDentalChartPlanStore();
const router = useRouter();
let patientID = router.currentRoute.value.params.patientID;
const patient = ref();
const searchTerm = ref("");

const filteredSutCodes = ref([]);
const activeUnit = ref(1);
const showIconScoreModal = ref(false);
const showSterilizationModal = ref(false);

const closeIconScoreModal = () => {
  showIconScoreModal.value = false;
};

const closeSterilizationModal = () => {
  showSterilizationModal.value = false;
};

const filterSutCodes = () => {
  if (searchTerm.value.trim().length < 2) {
    return;
  }
  filteredSutCodes.value = sutKodlari.filter((sut) =>
    sut.sutKodu.startsWith(searchTerm.value)
  );
};

const addProcess = (sut) => {
  dentalChartPlanStore?.examinationPlans?.[
    dentalChartPlanStore.planStatusTab
  ]?.[dentalChartPlanStore.activePlanTab]?.planStages?.[
    dentalChartPlanStore.openedStage
  ]?.stageProcessesInfos?.push({
    processName: sut.adi,
    processSutCode: sut.sutKodu,
    toothNumbers: [],
    count: 0,
    price: sut.fiyat,
  });
};

const selectButton = (sut) => {
  if (dentalChartStore.dentalChartDiagnozSelectedButtonName === sut.adi) {
    dentalChartStore.dentalChartDiagnozSelectedButtonName = "";
    return;
  }
  dentalChartStore.dentalChartDiagnozSelectedButtonName = sut.adi;
  addProcess(sut);
};

const getPatientStore = useGetPatientStore();
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
import DentalChartTeethComponent from "@/modules/dentalChart/dentalChartMain/components/dentalChartTeethComponent.vue";
import DentalChartCursorFollower from "@/modules/dentalChart/dentalChartMain/components/dentalChartCursorFollower.vue";
const doctorsSlotsStore = doctorSlotsStore();
// onMounted(async () => {
//   dentalChartPlanStore.examinationPlans = {
//     activePlans: [],
//     completedPlans: [],
//     remainingPlans: [],
//   };
// });

onMounted(async () => {
  patient.value = await getPatientStore.getPatientA(patientID);
});

onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
});

const handleKeyDown = (event) => {
  if (event.key === "Escape") {
    dentalChartStore.dentalChartDiagnozSelectedButtonName = "";
  }
};
</script>
