<template>
  <div class="flex w-full text-sm text-[#40464C] h-screen relative">
    <div
      class="w-[220px] flex h-full flex-col gap-4 items-center px-3 py-1 border-r-2"
    >
      <div class="flex flex-col gap-1 items-center w-full">
        <img :src="profileCircle" class="w-10" alt="" />
        <span
          :title="'Selen Boz(K)'"
          class="text-[#40464c] truncate w-40 text-center"
          >Selen Boz<span>(K)</span></span
        >
        <span class="text-[#9fa2a5] truncate w-full text-center"
          >24 yıl 6 ay 14 gün</span
        >
      </div>

      <div class="flex flex-col gap-2 items-center">
        <button
          class="w-[145px] h-8 rounded-[20px] flex items-center justify-center text-[#277dd2] border-[1px] border-[#277dd2]"
        >
          Hastayı çağır
        </button>
        <button
          @click="
            router.push(
              `/polyclinic/examinations/dental-chart/${$route.params.patientID}`
            )
          "
          class="w-[145px] h-8 rounded-[20px] flex items-center justify-center text-[#277dd2] border-[1px] border-[#277dd2]"
        >
          Görüntüle
        </button>
        <button
          @click="
            router.push(
              `/polyclinic/examinations/dental-chart-plan/${$route.params.patientID}`
            )
          "
          class="w-[145px] h-8 rounded-[20px] flex items-center justify-center text-[#277dd2] border-[1px] border-[#277dd2]"
        >
          Plan oluştur
        </button>
        <div
          class="w-[145px] p-2 rounded-[20px] flex items-center justify-center bg-[#faeee5] text-[#CC5500] text-center"
        >
          Endodonti
        </div>
      </div>
      <div class="flex flex-col gap-4 items-center">
        <div class="flex flex-col gap-1 items-center">
          <div
            class="w-8 h-8 rounded-full bg-[#ececed] flex items-center justify-center"
          >
            <img src="" alt="" class="w-6" />
          </div>
          <span class="font-medium text-center text-xs">TC</span>
          <span class="font-medium text-center w-40 truncate">50404354565</span>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <div
            class="w-8 h-8 rounded-full bg-[#ececed] flex items-center justify-center"
          >
            <img src="" alt="" class="w-6" />
          </div>
          <span class="font-medium text-center text-xs">Hekim</span>
          <span class="font-medium text-center w-40 truncate"
            >Melih BAYRAK</span
          >
        </div>
        <div class="flex flex-col gap-1 items-center">
          <div
            class="w-8 h-8 rounded-full bg-[#ececed] flex items-center justify-center"
          >
            <img src="" alt="" class="w-6" />
          </div>
          <span class="font-medium text-center text-xs">Provizyon Tipi</span>
          <span class="font-medium text-center w-40 truncate">İş Kazası</span>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <div
            class="w-8 h-8 rounded-full bg-[#ececed] flex items-center justify-center"
          >
            <img src="" alt="" class="w-6" />
          </div>
          <span class="font-medium text-center text-xs">Kabul No</span>
          <span class="font-medium text-center w-40 truncate">123456-1</span>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <div
            class="w-8 h-8 rounded-full bg-[#ececed] flex items-center justify-center"
          >
            <img src="" alt="" class="w-6" />
          </div>
          <span class="font-medium text-center text-xs"
            >Kabul Tarihi/Saati</span
          >
          <span class="font-medium text-center w-40 truncate"
            >26 Ağustos 2023/09:20</span
          >
        </div>
        <div class="flex flex-col gap-1 items-center">
          <div
            class="w-8 h-8 rounded-full bg-[#ececed] flex items-center justify-center"
          >
            <img src="" alt="" class="w-6" />
          </div>
          <span class="font-medium text-center text-xs">Başvuru No</span>
          <span class="font-medium text-center w-40 truncate">51AB2456</span>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <div
            class="w-8 h-8 rounded-full bg-[#ececed] flex items-center justify-center"
          >
            <img src="" alt="" class="w-6" />
          </div>
          <span class="font-medium text-center text-xs">GSS Takip No</span>
          <span class="font-medium text-center w-40 truncate">124924</span>
        </div>
      </div>
    </div>

    <div class="w-full relative flex flex-col">
      <div class="flex items-center justify-between mb-3 border-b">
        <div class="text-white"></div>
        <h2 class="font-medium text-[#40464c]">HASTA ANAMNEZLERİ</h2>
        <button
          @click="router.push('/polyclinic/examinations')"
          class="text-white bg-[#F94144] px-6 py-2 font-semibold"
        >
          Kapat
        </button>
      </div>

      <main class="flex flex-col gap-5 h-full bg-[#fbfbfb]">
        <patientCall :inModal="true" />
        <div class="flex gap-10 text-[#464E5F] mt-3 flex-grow">
          <div
            class="flex-1 rounded bg-white py-4 px-3 flex flex-col gap-3 relative"
          >
            <h3 class="text-[#277dd2] font-semibold">Hasta anamnez tablosu</h3>
            <div
              class="flex flex-col gap-6 absolute z-50 w-full bg-white"
              v-if="viewingAnamnesis"
            >
              <div>
                <button
                  @click="viewingAnamnesis = null"
                  class="text-white bg-[#F94144] px-6 py-2 font-semibold absolute right-0 -top-6"
                >
                  Kapat
                </button>
                <h4 class="font-semibold">TIBBİ ANAMNEZ</h4>
                <div class="p-3 border flex flex-col gap-1 rounded-[10px] mt-2">
                  <div class="flex">
                    <p>Sistematik Hastalıklar :&nbsp;</p>
                    <p class="opacity-80">fffjjfjfjfjfjfj</p>
                  </div>
                  <div class="flex">
                    <p>Kardiyovasküler Hastalıklar :&nbsp;</p>
                    <p class="opacity-80">dkdndnkdkdknknnkdnk</p>
                  </div>
                  <div class="flex">
                    <p>Endokrin Hastalıklar :&nbsp;</p>
                    <p class="opacity-80">ddkdkndnknkdnkdnkd</p>
                  </div>
                  <div class="flex">
                    <p>Sigara Kullanımı :&nbsp;</p>
                    <p class="opacity-80">djkjdkjdkjkd</p>
                  </div>
                </div>
              </div>

              <div>
                <h4 class="font-semibold">DENTAL ANAMNEZ</h4>
                <div class="p-3 border flex flex-col gap-1 rounded-[10px] mt-2">
                  <div class="flex">
                    <p>Diş Fırçalama :&nbsp;</p>
                    <p class="opacity-80">Günde 1</p>
                  </div>
                  <div class="flex">
                    <p>Arayüz Bakımı :&nbsp;</p>
                    <p class="opacity-80">Yok</p>
                  </div>
                  <div class="flex">
                    <p>Arayüz Temizlik Ajanı :&nbsp;</p>
                    <p class="opacity-80">Kürdan</p>
                  </div>
                  <div class="flex">
                    <p>Arayüz Temizlik Ajanı Kullanım Sıklığı :&nbsp;</p>
                    <p class="opacity-80">Düzenli</p>
                  </div>
                  <div class="flex">
                    <p>Diş Etinde Kızarıklık :&nbsp;</p>
                    <p class="opacity-80">Yok</p>
                  </div>
                  <div class="flex">
                    <p>Diş Etinde Büyüme(Ödem) :&nbsp;</p>
                    <p class="opacity-80">Yok</p>
                  </div>
                  <div class="flex">
                    <p>Ağız Kokusu :&nbsp;</p>
                    <p class="opacity-80">Yok</p>
                  </div>
                  <div class="flex">
                    <p>Müdahale Gerektirecek Diş veya Diş Eti Ağrısı :&nbsp;</p>
                    <p class="opacity-80">Var</p>
                  </div>
                </div>
              </div>
            </div>
            <table class="min-w-full bg-white cursor-pointer">
              <thead>
                <tr class="w-full text-[10px] sm:text-xs md:text-sm border-y">
                  <th
                    v-for="(column, index) in columns"
                    :key="index"
                    class="max-w-12 truncate sm:max-w-fit py-3 px-2 xl:px-6 text-left font-medium whitespace-nowrap"
                  >
                    {{ column.name }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-[10px] sm:text-xs md:text-sm">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="border-y border-[#d9d9d9] hover:bg-gray-100"
                >
                  <td
                    v-for="(column, colIndex) in columns"
                    :key="colIndex"
                    class="py-3 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    {{ row[column.key] }}
                  </td>
                  <td
                    class="py-3 px-2 xl:px-6 my-auto text-left whitespace-nowrap"
                  >
                    <div class="group relative flex flex-col">
                      <img :src="threeDots" alt="" class="w-1" />
                      <div
                        class="text-xs flex-col text-left absolute z-40 text-white hidden group-hover:flex -top-10 right-0 py-1 rounded-lg"
                      >
                        <button
                          class="text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-40 py-1"
                        >
                          Düzenle
                        </button>
                        <button
                          class="text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-40 py-1"
                        >
                          Sil
                        </button>
                        <button
                          @click="viewingAnamnesis = index.toString()"
                          class="text-left bg-[#707070] hover:bg-opacity-90 px-2 w-40 py-1"
                        >
                          Görüntüle
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="flex-1 h-fit rounded bg-white py-4 px-3 flex flex-col gap-3"
          >
            <h3 class="text-[#277dd2] font-semibold">Yeni anamnez ekle</h3>
            <div class="flex gap-3 bg-white py-1 pl-2 rounded-full text-black">
              <img :src="search" alt="search" class="w-6" />
              <input
                type="text"
                class="w-full outline-none py-2"
                placeholder="Ara"
              />
              <button
                @click="settingsToggle = !settingsToggle"
                class="focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
              >
                <img :src="settingsImg" class="w-6" alt="" />
              </button>
              <!-- <div
        v-if="settingsToggle"
        class="absolute top-12 right-0 flex flex-col gap-1 z-10 w-[160px] p-1 rounded-md border-[1px] border-[#277dd2] bg-white"
      >
        <div
          v-for="(item, i) in [
            'Ad/Soyad',
            'Cep Telefonu',
            'Ev Telefonu',
            'E-mail',
            'Adres',
            'Anne Adı ',
            'Baba Adı ',
            'Doğum Yeri ',
            'Medeni Hali ',
            'Doğum Tarihi ',
          ]"
          v-bind:key="item"
          class="flex justify-between"
        >
          <p class="font-thin text-[#3C3C43]">{{ item }}</p>
          <button
            class="flex cursor-pointer items-center justify-between focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
            @click="
              medulaStore.medulaSearchFilterButton[i] =
                !medulaStore.medulaSearchFilterButton[i]
            "
          >
            <div
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                medulaStore.medulaSearchFilterButton[i] === true
                  ? 'bg-[#0457F8]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': medulaStore.medulaSearchFilterButton[i],
                }"
              ></div>
            </div>
          </button>
        </div>
      </div> -->
            </div>
            <div
              class="flex items-center gap-6 -mb-3 overflow-x-auto w-[800px]"
            >
              <button
                class="px-3 py-1"
                :class="{
                  'border-b-[4px] border-[#277dd2] text-[#277dd2]':
                    activeTab === 1,
                }"
                @click="activeTab = 1"
              >
                <span class="w-20 truncate">Favori Anamnezler</span>
              </button>
              <button
                v-for="(unit, index) in unitListStore?.getListUnitsResponse"
                class="px-3 py-1"
                :class="{
                  'border-b-[4px] border-[#277dd2] text-[#277dd2] ':
                    activeTab === unit.birim_kodu,
                }"
                @click="activeTab = unit.birim_kodu"
              >
                <span class="w-20 truncate">{{ unit.birim_adi }}</span>
              </button>
              <img :src="chevronRight" class="ml-auto w-2" />
            </div>
            <div class="flex flex-col">
              <div
                v-for="anamnesis in anamneses"
                class="border-t p-2 flex items-center justify-between"
              >
                <div class="flex items-center gap-3">
                  <img :src="medicalHistoryBlue" class="w-8" alt="" />
                  <span class="w-full truncate">{{
                    anamnesis?.attributes?.template_name
                  }}</span>
                </div>
                <button
                  class="text-[#277dd2] border-[1px] border-[#277dd2] p-2 rounded-[20px]"
                >
                  Anamnez ekle
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <examinationPlanModal
      v-if="showExaminationPlanModal"
      :closeExaminationPlanModal="closeExaminationPlanModal"
    />
  </div>
</template>

<script setup>
import examinationPlanModal from "@/modules/doctorScreen/components/doctorAddAnamnesis/examinationPlanModal.vue";
import patientCall from "@/modules/doctorScreen/components/doctorHastaKabul/patientCall.vue";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import threeDots from "@/modules/asistanEkrani/assets/threeDotsLogo.svg";
import search from "@/mainPage/assets/search.svg";
import settingsImg from "@/modules/asistanEkrani/assets/settingsImg.svg";
import medicalHistoryBlue from "@/modules/doctorScreen/assets/medicalHistoryBlue.svg";
import { addAnamnesisPatientStore } from "@/modules/doctorScreen/store/addAnamnesisPatientStore";
import { ref, computed, watch, onMounted } from "vue";
import chevronRight from "@/modules/doctorScreen/assets/rightArrowHead.svg";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();
import { useRouter } from "vue-router";
const router = useRouter();
const showExaminationPlanModal = ref(true);

const closeExaminationPlanModal = () => {
  showExaminationPlanModal.value = false;
};

onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});

const addAnamnesisStore = addAnamnesisPatientStore();

const settingsToggle = ref(false);
const anamneses = ref(null);
const activeTab = ref(1);
const viewingAnamnesis = ref(null);

watch(
  () => activeTab.value,
  async (newValue) => {
    if (newValue === 1) {
      // get favorite anamneses
    } else {
      // get unit's anamneses
      const res = await anamnesisTemplateStore.listUnitsAnamneses(newValue);
      anamneses.value = res.anamneses;
    }
  }
);

const columns = [
  { key: "anamnesis", name: "Anamnez" },
  { key: "doctor", name: "Oluşturan Hekim" },
  { key: "unit", name: "Birim" },
  { key: "createDate", name: "Oluşturulma Tarihi" },
];

const rows = [
  {
    anamnesis: "Anamnez 1",
    doctor: "Yasemin ÖNDER",
    unit: "Periodontoloji",
    createDate: "22/07/24",
  },
  {
    anamnesis: "Anamnez 2",
    doctor: "Yasemin ÖNDER",
    unit: "Periodontoloji",
    createDate: "22/07/24",
  },
  {
    anamnesis: "Anamnez 3",
    doctor: "Yasemin ÖNDER",
    unit: "Periodontoloji",
    createDate: "22/07/24",
  },
  {
    anamnesis: "Anamnez 4",
    doctor: "Yasemin ÖNDER",
    unit: "Periodontoloji",
    createDate: "22/07/24",
  },
  {
    anamnesis: "Anamnez 5",
    doctor: "Yasemin ÖNDER",
    unit: "Periodontoloji",
    createDate: "22/07/24",
  },
];
</script>
