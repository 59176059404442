<template>
  <div
    class="absolute right-0 top-[50px] rounded bg-[#fbfbfb] p-3 flex flex-col gap-2 min-w-[1025px]"
  >
    <!-- true -->
    <div
      class="flex flex-col gap-4 max-h-[335px] overflow-y-auto"
      ref="resultsContainer"
    >
      <div
        v-for="(patient, index) in medulaStore.medulaSearchPatientListArray"
        @mousedown="clickHandler(patient)"
        :key="index"
        class="bg-[#f1f2f3] rounded p-2 flex"
        :class="
          medulaStore.searchResultsSelectedIndex === index
            ? 'border-[#277DD2] border-[1px]'
            : ''
        "
      >
        <img :src="profileCircle" alt="profile photo" class="w-8 h-8" />
        <div class="ml-2 min-w-[200px]">
          <p class="font-medium text-lg">
            {{ patient.attributes.name }} {{ patient.attributes.surname }}
          </p>
          <p class="font-medium text-xl my-1">
            {{ patient.attributes.tc_identity_number }}
          </p>
          <div class="flex items-center gap-2 text-sm text-[#277DD2]">
            <div class="w-[10px] h-[10px] bg-[#277DD2] rounded-full"></div>
            {{ formatString(patient.attributes.patient_type) }}
          </div>
        </div>

        <div
          v-if="
            index === 0 &&
            medulaStore.sideAppointmentInformations &&
            medulaStore.sideAppointmentInformations.count > 0
          "
          class="flex flex-col gap-1 ml-14"
        >
          <div class="flex">
            <div class="w-[100px] flex justify-between mr-2">
              <p>Bölüm</p>
              <p>:</p>
            </div>
            <p class="font-medium">
              {{
                formatString(
                  medulaStore.sideAppointmentInformations.appointments.data?.[0]
                    .attributes.unit_name
                )
              }}
            </p>
          </div>
          <div class="flex">
            <div class="w-[100px] flex justify-between mr-2">
              <p>Doktor</p>
              <p>:</p>
            </div>
            <p class="font-medium">
              {{
                medulaStore.sideAppointmentInformations.appointments.data?.[0]
                  .attributes.doctor_name
              }}
            </p>
          </div>
          <div class="flex">
            <div class="w-[100px] flex justify-between mr-2">
              <p>Tarih & Saat</p>
              <p>:</p>
            </div>
            <p class="font-medium">
              {{
                formatDateAndTime(
                  medulaStore.sideAppointmentInformations.appointments.data?.[0]
                    .attributes.appointment_time
                ).formattedDate + " &"
              }}
              <span class="font-semibold">
                {{
                  formatDateAndTime(
                    medulaStore.sideAppointmentInformations.appointments
                      .data?.[0].attributes.appointment_time
                  ).formattedTime
                }}
                <span class="text-sm">{{
                  formatDateAndTime(
                    medulaStore.sideAppointmentInformations.appointments
                      .data?.[0].attributes.appointment_end_time
                  ).formattedTime
                }}</span></span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <p
      v-if="
        medulaStore.medulaSearchPatientListArray &&
        medulaStore.medulaSearchPatientListArray.length > 0
      "
      class="ml-auto text-[#333333]"
    >
      <span class="font-semibold">{{
        medulaStore.medulaSearchPatientListArray.length
      }}</span>
      sonuç bulundu.
    </p>

    <!-- false -->
    <div
      v-if="
        medulaStore.medulaSearchPatientListArray &&
        medulaStore.medulaSearchPatientListArray.length === 0
      "
    >
      <h4 class="text-2xl font-bold text-[#323232]">BİLGİLENDİRME</h4>
      <div class="flex items-center justify-between mt-3 mb-7">
        <div class="flex items-center text-[#D9534F] gap-6">
          <img :src="errorRed" alt="" />
          <p class="font-semibold text-xl">
            Bu TC Numarasına Ait Kayıt Bulunamamaktadır
          </p>
        </div>
        <button
          @click="routeCreateNewPatient"
          @focus="medulaStore.searchPatientButtonFocus = true"
          @blur="medulaStore.searchPatientButtonFocus = false"
          class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] border-[1px] py-2 px-7 text-xl rounded-lg border-[#277DD2] text-[#277DD2] font-semibold"
        >
          Yeni Kayıt Oluştur
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
//asset imports
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import errorRed from "@/modules/medula/provision/assets/errorRed.svg";
//store imports
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
import { useSearchPatientAppointments } from "@/store/modules/operationsStore/patient/useSearchPatientAppointments";
//store inits
const medulaStore = useMedula();
const getPatientStore = useGetPatientStore();
const bilesikKisiStore = bilesikKisiActions();
const searchPatientAppointmentsStore = useSearchPatientAppointments();

const clickHandler = async (patient) => {
  console.log(patient);
  medulaStore.selectedPatient = await getPatientStore.getPatientA(patient.id);
  medulaStore.selectedPatientID = patient.id;
  medulaStore.searchPatientInput = patient.attributes.tc_identity_number;
};

const routeCreateNewPatient = (e) => {
  e.target.blur();
  router.push("/assistantScreen/addPatient");
  bilesikKisiStore.kpsBilesikKisiSorgulama(medulaStore.searchPatientInput);
  console.log("kps");
};

//scroll logic
const resultsContainer = ref(null);

onMounted(() => {
  scrollSelectedIntoView();
});

watch(
  () => medulaStore.searchResultsSelectedIndex,
  () => {
    scrollSelectedIntoView();
  }
);

watch(
  () => medulaStore.medulaSearchPatientListArray,
  async (newValue, oldValue) => {
    console.log("newValue", newValue);
    console.log("oldValue", oldValue);

    // Derinlemesine karşılaştırma yapılıyor
    const isEqual = JSON.stringify(newValue) === JSON.stringify(oldValue);

    if (isEqual) {
      return true;
    }
    medulaStore.sideAppointmentInformations = null;
    if (medulaStore.medulaSearchPatientListArray.length > 0) {
      medulaStore.sideAppointmentInformations =
        await searchPatientAppointmentsStore.searchPatientAppointments(
          moment().format("YYYY-MM-DD"),
          moment().add(1, "week").format("YYYY-MM-DD"),
          medulaStore.medulaSearchPatientListArray[0].id
        );
    }
  }
);
const formatDateAndTime = (date) => {
  const formattedDate = moment.utc(date).format("DD/MM/YYYY");
  const formattedTime = moment.utc(date).format("HH:mm");
  return { formattedDate, formattedTime };
};

const formatString = (str) => {
  if (!str) {
    return null;
  }
  return str
    .toLocaleLowerCase("tr-TR")
    .split(" ")
    .map((word) => word.charAt(0).toLocaleUpperCase("tr-TR") + word.slice(1))
    .join(" ");
};

const scrollSelectedIntoView = () => {
  const selectedElement = resultsContainer.value.querySelector(
    `div:nth-child(${medulaStore.searchResultsSelectedIndex + 1})`
  );

  if (selectedElement) {
    const containerRect = resultsContainer.value.getBoundingClientRect();
    const elementRect = selectedElement.getBoundingClientRect();

    if (elementRect.bottom > containerRect.bottom) {
      resultsContainer.value.scrollTop +=
        elementRect.bottom - containerRect.bottom;
    } else if (elementRect.top < containerRect.top) {
      resultsContainer.value.scrollTop -= containerRect.top - elementRect.top;
    }
  }
};
</script>
