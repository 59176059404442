<template>
  <div
    class="mx-auto w-[850px] bg-white py-3 border-2 border-l-[10px] border-l-[#287dd2] rounded-[10px] h-fit"
  >
    <div class="pl-6 flex flex-col mb-2">
      <p class="font-medium text-lg w-full mb-3 bg-transparent">
        <span class="text-red-600 text-2xl" v-if="formElement.is_required"
          >*</span
        >
        {{ formElement.question }}
      </p>
      <div class="flex justify-center gap-8 mt-3">
        <p class="mt-auto font-medium text-[#287dd2]">
          {{ formElement.answer.min_placeholder }}
        </p>
        <div class="flex justify-center gap-16">
          <div
            class="flex flex-col items-center"
            v-for="i in getScaleRange(
              formElement.answer.scale_min,
              formElement.answer.scale_max
            )"
          >
            <!-- :for="'option_' + props.index + '_' + i" -->
            <label>{{ i }}</label>
            <input
              class="w-6 h-6"
              type="radio"
              :id="uuid + props.index + '_' + formElement.question + '_' + i"
              :name="uuid + '_' + formElement.question + '_' + props.index"
            />
          </div>
        </div>
        <p class="mt-auto font-medium text-[#287dd2]">
          {{ formElement.answer.max_placeholder }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
const uuid = ref(uuidv4());

const props = defineProps({
  index: Number,
  formElement: Object,
});

const getScaleRange = (min, max) => {
  const range = [];
  for (let i = min; i <= max; i++) {
    range.push(i);
  }
  return range;
};
</script>
