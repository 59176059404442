<template>
  <div class="flex w-full min-h-screen relative">
    <doctorHastaKabulSidebar />
    <div class="w-full relative flex flex-col">
      <personelHeaderNavbar title="Klinik Yönetimi" />
      <main class="p-3 flex flex-col gap-5 h-full bg-[#fbfbfb]">
        <klinikYonetimHome />
      </main>
    </div>
    <addClinicPopUp v-if="klinikYonetimStore.addClinicPopUp" />
    <div class="overlay" v-if="klinikYonetimStore.addClinicPopUp"></div>
  </div>
</template>

<script setup>
import personelHeaderNavbar from "@/modules/personel/components/personelHeaderNavbar";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import klinikYonetimHome from "@/modules/klinikYonetim/components/klinikYonetimHome.vue";
import addClinicPopUp from "../components/addClinicPopUp.vue";
import { useKlinikYonetimStore } from "@/modules/klinikYonetim/store/klinikYonetim";
const klinikYonetimStore = useKlinikYonetimStore();
</script>
