<template>
  <div class="flex w-full min-h-screen">
    <personalSideBar />
    <div class="w-full relative">
      <personelHeaderNavBar title="Yeni Hasta Kayıt" newPatientPage />
      <patientEnrollment />
    </div>
  </div>
</template>

<script setup>
import personalSideBar from "@/modules/personel/components/personalSideBar.vue";
import personelHeaderNavBar from "@/modules/personel/components/personelHeaderNavbar.vue";
import patientEnrollment from "../components/patientEnrollment.vue";
import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
const bilesikKisiStore = bilesikKisiActions();
</script>

<style>
</style>
