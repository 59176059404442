<template>
  <anamnesesUnitNavbar />
  <unitAnamnesesTableNav />
  <anamnesesTable v-if="anamnesisStore.anamnesesNavbar === 1" />
  <favoriteAnamnesesTable v-if="anamnesisStore.anamnesesNavbar === 2" />
</template>

<script setup>
import anamnesesUnitNavbar from "./anamnesesUnitNavbar.vue";
import unitAnamnesesTableNav from "./unitAnamnesesTableNav.vue";
import anamnesesTable from "./anamnesesTable.vue";
import favoriteAnamnesesTable from "./favoriteAnamnesesTable.vue";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();
</script>
