<template>
  <div class="flex overflow-hidden relative">
    <div class="absolute z-[999] right-20 top-20">
      <customInfoCard v-if="globalStore.customInfoCard" />
    </div>
    <personalSideBar />
    <div class="overflow-y-auto">
      <div class="flex flex-col">
        <personelHeaderNavbar title="Hasta Kabul İşlemleri" />
        <filterRegisteredPatients />
        <registeredPatientListTable />
      </div>
      <div class="flex justify-between items-center gap-4 mr-8">
        <div class="flex gap-2 ml-2">
          <div class="w-4 h-4 rounded-full bg-[#d9534f] ml-auto"></div>
          GSS Takip No Alınmadı
        </div>
        <div class="flex mr-4"><paginationPatientTable class="ml-auto" /></div>
      </div>
    </div>

    <sideProvisionPatientCard v-if="RegisteredPatientListStore.sidePanelShow" />

    <medulaSuccesCard v-if="globalStore.isMedulaCardSuccess" />
    <div class="overlay" v-if="RegisteredPatientListStore.sidePanelShow"></div>
  </div>
</template>

<script setup>
import customInfoCard from "@/components/customInfoCard.vue";
import { globalStore } from "@/store/modules/globalStore";
import { registeredPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/getPatientApplicationRegistrationList.ts";
import { ref, onMounted, watch, watchEffect } from "vue";
import registeredPatientListTable from "../components/registeredPatientListTable.vue";
import { useRegisteredPatientListStore } from "../store/registeredPatientList";
import personalSideBar from "@/modules/personel/components/personalSideBar.vue";
import personelHeaderNavbar from "@/modules/personel/components/personelHeaderNavbar";
import paginationPatientTable from "@/modules/asistanEkrani/registeredPatientList/components/paginationRegisteredPatientTable.vue";
import sideProvisionPatientCard from "@/modules/asistanEkrani/registeredPatientList/components/sidePanel/sidePanel.vue";
import filterRegisteredPatients from "../components/filterRegisteredPatients.vue";
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import medulaSuccesCard from "@/modules/medula/provision/components/medulaSuccesCard.vue";
const staffListStore = useGetStaffList();
// onMounted(async () => {
//   if (staffListStore.getListStaffResponse) {
//     return false;
//   }
//   await staffListStore.getStaffList();
// });
import moment from "moment";

import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});

const watchExecuted = ref(false);

const RegisteredPatientApplicationStore = registeredPatientApplicationStore();
const RegisteredPatientListStore = useRegisteredPatientListStore();

const fetchData = async () => {
  await RegisteredPatientApplicationStore.getRegisteredPatientApplications(
    1,
    8,
    moment(RegisteredPatientListStore.listDate).utc().format("YYYY-MM-DD"),
    moment(RegisteredPatientListStore.listDate).utc().format("YYYY-MM-DD"),
    RegisteredPatientListStore.patientApplicationsFilter.basvuruDurumu.id,
    RegisteredPatientListStore.patientApplicationsFilter.provizyonTipi.id,
    RegisteredPatientListStore.patientApplicationsFilter.birimAdi.id,
    RegisteredPatientListStore.patientApplicationsFilter.kurumAdi.text,
    RegisteredPatientListStore.patientApplicationsFilter.sigortaTipi.text,
    RegisteredPatientListStore.patientApplicationsFilter.search
  );
};

onMounted(async () => {
  watchExecuted.value = true;
  setTimeout(() => {
    watchExecuted.value = false;
  }, 0);
  RegisteredPatientListStore.patientApplicationsFilter.basvuruDurumu = {};
  RegisteredPatientListStore.patientApplicationsFilter.provizyonTipi = {};
  RegisteredPatientListStore.patientApplicationsFilter.birimAdi = {};
  RegisteredPatientListStore.patientApplicationsFilter.kurumAdi = {};
  RegisteredPatientListStore.patientApplicationsFilter.sigortaTipi = {};
  RegisteredPatientListStore.patientApplicationsFilter.search = "";
  await fetchData();
});

watch(
  () => RegisteredPatientListStore.listDate,

  async () => {
    if (watchExecuted.value) {
      return false;
    }
    RegisteredPatientListStore.currentPage = 1;
    await RegisteredPatientApplicationStore.getRegisteredPatientApplications(
      1,
      8,
      moment(RegisteredPatientListStore.listDate).utc().format("YYYY-MM-DD"),
      moment(RegisteredPatientListStore.listDate).utc().format("YYYY-MM-DD"),
      RegisteredPatientListStore.patientApplicationsFilter.basvuruDurumu.id,
      RegisteredPatientListStore.patientApplicationsFilter.provizyonTipi.id,
      RegisteredPatientListStore.patientApplicationsFilter.birimAdi.id,
      RegisteredPatientListStore.patientApplicationsFilter.kurumAdi.text,
      RegisteredPatientListStore.patientApplicationsFilter.sigortaTipi.text,
      RegisteredPatientListStore.patientApplicationsFilter.search
    );
  }
);
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 45;
}
p {
  padding: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  align-items: center;
  display: flex;
}
header {
  background-color: #fafcff;
  padding: 20px;
}
</style>
