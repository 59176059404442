<template>
  <div class="flex flex-col gap-4 px-6">
    <div
      v-for="(item, index) in unitListStore.getListUnitsResponse"
      :key="index"
    >
      <button
        class="py-3 border flex justify-between items-center w-full px-3"
        @click="toggleAccordion(item.birim_kodu, index)"
      >
        <div class="flex items-center">
          <img :src="rightArrowHead" alt="rightArrowHead" class="w-3" />
          <h2 class="font-medium text-[20px] w-[330px] ml-7 text-left">
            {{ _.startCase(_.toLower(item.birim_adi)) }} Kliniği
          </h2>
          <span class="py-1 px-2 rounded text-sm bg-[#DDDDDD] text-[#6F6F6F]">
            22 Anamnez
          </span>
        </div>
        <button
          class="text-sm text-[#277dd2] font-medium"
          @click="routeToUnitScreen(item)"
        >
          Detay gör / Düzenle
        </button>
      </button>
      <div
        v-if="index === activeIndex"
        class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col"
      >
        <!-- v-if="
            klinikYonetimStore.clinicDoctors !== null &&
            klinikYonetimStore.clinicDoctors.length !== 0
          " for below -->
        <div
          class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
        >
          <div class="h-[80%] w-[300px] flex justify-center items-center">
            <p class="font-medium">ANAMNEZ</p>
          </div>
          <div class="h-[80%] w-[350px] flex justify-center items-center">
            <p class="font-medium">OLUŞTURAN HEKİM</p>
          </div>
          <div class="h-[80%] w-[350px] flex justify-center items-center">
            <p class="font-medium">BİRİM</p>
          </div>
          <div class="h-[80%] w-[350px] flex justify-center items-center">
            <p class="font-medium">OLUŞTURULMA TARİHİ</p>
          </div>
          <div class="h-[80%] w-[200px] flex justify-center items-center">
            <p class="font-medium"></p>
          </div>
        </div>
        <div
          v-for="(anamnez, index) in anamneses"
          class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
          :key="index"
        >
          <div
            class="h-[80%] w-[300px] flex flex-col justify-center items-center"
          >
            <p>{{ anamnez.attributes.template_name }}</p>
          </div>
          <div
            class="h-[80%] w-[350px] flex flex-col justify-center items-center"
          >
            <p>{{ anamnez.attributes.doctor_name }}</p>
          </div>
          <div
            class="h-[80%] w-[350px] flex flex-col justify-center items-center"
          >
            <p>{{ _.startCase(_.toLower(item.birim_adi)) }}</p>
          </div>
          <div
            class="h-[80%] w-[350px] flex justify-center items-center text-center"
          >
            <p>
              {{
                moment(anamnez.attributes.created_at).utc().format("DD.MM.YYYY")
              }}
            </p>
          </div>
          <div
            class="h-[80%] w-[200px] flex flex-col justify-center items-center"
          >
            <div
              class="ml-12 focus:outline-double relative group focus:outline-1 focus:outline-[#277dd2] w-fit px-2"
            >
              <img :src="moreDotsIcon" alt="moreDots" />
              <div
                class="text-sm text-left absolute z-[100] text-white hidden group-focus-within:block group-hover:block right-0 py-1 rounded-lg"
              >
                <button
                  @click="
                    router.push(
                      `/polyclinic/anamneses/${encodeURIComponent(
                        item.birim_kodu
                      )}/read-anamnesis/${anamnez.id}`
                    )
                  "
                  class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
                >
                  Görüntüle
                </button>
                <button
                  @click="
                    router.push(
                      `/polyclinic/anamneses/${encodeURIComponent(
                        item.birim_kodu
                      )}/update-anamnesis/${anamnez.id}`
                    )
                  "
                  class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
                >
                  Düzenle
                </button>
                <button
                  @click="deleteAnamnesis(anamnez.id)"
                  class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
                >
                  Sil
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";
const router = useRouter();
import _ from "lodash";
import moreDotsIcon from "@/modules/protez/assets/moreDots.svg";
import rightArrowHead from "@/modules/doctorScreen/assets/rightArrowHead.svg";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
// import { useKlinikYonetimStore } from "../store/klinikYonetim";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useGetDoctorsByUnitStore } from "@/store/modules/administratorManagementStore/staff/getDoctorsByUnit";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();
const anamnesisStore = useAnamnesistore();
const doctorsByUnitStore = useGetDoctorsByUnitStore();
// const klinikYonetimStore = useKlinikYonetimStore();
const unitListStore = useGetUnitsList();
const activeIndex = ref(-1);
const anamneses = ref(null);

onMounted(async () => {
  anamnesisStore.anamnesesUnitScreenUnitDatas = null;
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});

const toggleAccordion = async (unit, index) => {
  if (activeIndex.value !== index) {
    activeIndex.value = index;
    const res = await anamnesisTemplateStore.listUnitsAnamneses(unit);
    anamneses.value = res.anamneses;
    console.log("brr", anamneses.value);
  } else {
    activeIndex.value = -1;
  }
};

const deleteAnamnesis = async (anamnesisID) => {
  await anamnesisTemplateStore.deleteAnamnesisTemplate(anamnesisID);
  activeIndex.value = -1;
};

const routeToUnitScreen = (item) => {
  router.push(`/polyclinic/anamneses/${encodeURIComponent(item.id)}`);
};
</script>
