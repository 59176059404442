<script>
// Import navbar
import { defineComponent } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { searchSlotsBetweenDate } from "../../../store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
import Select2 from "vue3-select2-component";
import { mapStores } from "pinia";
import { ref, watch } from "vue";
import { useAppointmentBook } from "../components/appointmentBookStore.js";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import assistantDeleteAppointment from "../components/assistanDeleteAppointment.vue";
import { updatingAppointment } from "@/modules/doctorSlots/components/updatingAppointment";
import customInfoCard from "@/components/customInfoCard.vue";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";

// Detail Hasta
import hastaRandevuAjanda from "../components/hastaRandevuAjanda.vue";
import AssistantCalendarDetailButton from "../components/AssistantCalendarDetailButton.vue";
// References
import {
  INITIAL_EVENTS,
  fetchAppointments,
  initalBackgroundMonthlyEvents,
} from "../../../components/asistant/CalendarEvent.ts";
import { globalStore } from "@/store/modules/globalStore";
import {
  calendarStoreCalendarFilter,
  returnCalendarSlotClinicName,
  boolCountSearchSlotsForClinic,
} from "@/modules/asistanEkrani/asistanCalendar/store/calendarStore.js";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic";
import moment from "moment";
import { useDeleteVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useDeleteVEMAppointment";
export default defineComponent({
  components: {
    searchSlotsBetweenDate,
    FullCalendar,
    hastaRandevuAjanda,
    AssistantCalendarDetailButton,
    Select2,
    useDeleteVEMAppointment,
    assistantDeleteAppointment,
    updatingAppointment,
    customInfoCard,
  },
  computed: {
    propertyAAndPropertyB() {
      return `${this.calendarStoreCalendarFilter}|${
        globalStore.appointmentStartTime
      }|${useAppointmentBook().unit_code}|${this.mounted}`;
    },
  },
  data() {
    return {
      mounted: false,
      openCancelPopUp: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        customButtons: {
          create: {
            text: "time",
            //icon: "fa-regular fa-user",
            click: this.handleRequestApproval,
          },
        },
        themeSystem: "bootstrap4",
        themeButtonIcons: true,
        headerToolbar: {
          start: "create",
          left: "prev,title,next",
          center: "",
          right: "",
          // right: "requestApproval,refresh,save,filter,scheduleSettings",
        },
        timeZone: "tr",
        dayHeaderFormat: { weekday: "long" },
        eventLimit: 3,
        firstDay: 1,
        fixedWeekCount: false,
        showNonCurrentDates: false,
        dayHeaderContent: function (arg) {
          return {
            html: `<span class="custom-day-header">${arg.text}</span>`,
            classNames: ["custom-day-header"],
          };
        },
        datesSet: async function (info) {
          console.log("datesSet changed", info.view.calendar);
          globalStore.appointmentStartTime = info.start; // Görüntülenen tarih aralığının başlangıç tarihi-globalstore'a gerek kalmayabilir
          globalStore.appointmentEndTime = info.end; // Görüntülenen tarih aralığının bitiş tarihi
          console.log(
            "globalStore.appointmentStartTimeglobalStore.appointmentStartTime",
            globalStore.appointmentStartTime
          );
        },
        // initialEvents: initalBackgroundMonthlyEvents,
        events: [...initalBackgroundMonthlyEvents, ...INITIAL_EVENTS],
        initialView: "dayGridMonth",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
      },
      toggleShowHover: false,
      currentEvents: [],
      title: "",
      globalStore,
      useGetUnitsList,
      useAppointmentBook,
      calendarStoreCalendarFilter,
      boolCountSearchSlotsForClinic,
      calenderObject: ref("calendar"),
      calenderStartTime: ref(globalStore.appointmentStartTime),
    };
  },
  watch: {
    // whenever question changes, this function will run
    async propertyAAndPropertyB(newVal, oldQuestion) {
      this.calendarOptions.events = [];
      console.log("assistant calendar", newVal);
      this.handleResetTheEvents();
      const fetchAppointmentsReq = {
        doctor_id: "",
        doctor_name: "",
        doctor_surname: "",
        unit_id: useAppointmentBook().unit_code,
        tc_identity_number: "",
        patient_name: "",
        patient_surname: "",
        phone_number: "",
        start_date: moment(globalStore.appointmentStartTime).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
        provision_identifier: "",
        page_id: 1,
        page_size: 100,
      };

      await fetchAppointments(fetchAppointmentsReq);
      await searchSlotsBetweenDate().slotsBetweenDate(
        "",
        moment(globalStore.appointmentStartTime).format("YYYY-MM-DD"),
        moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
        "calendarMonthlySlots",
        "free",
        useAppointmentBook().unit_code,
        1,
        5000,
        "calendar"
      );
      console.log(
        "initalBackgroundMonthlyEventsinitalBackgroundMonthlyEventsinitalBackgroundMonthlyEvents watcher filter",
        initalBackgroundMonthlyEvents
      );
      this.calendarOptions.events = [
        ...initalBackgroundMonthlyEvents,
        ...INITIAL_EVENTS,
      ];
    },
  },

  async mounted() {
    this.mounted = true;
    
    globalStore.isAppointmentCreateSuccesful = false;
    // this.calendarOptions.initialEvents = initalBackgroundMonthlyEvents;

    this.setTurkishLocale(); // Türkçe dil ayarlarını ayarla
    const titleElement = document.querySelector(".fc-toolbar-title");
    
    titleElement.addEventListener("click", this.handleTitleClick);
  },

  methods: {
    openCancelPopUpTrue() {
      this.openCancelPopUp = true;
    },
    openCancelPopUpFalse() {
      this.openCancelPopUp = false;
    },
    
    handleTitleClick() {
      globalStore.isCreateAppointmentSideBarOpen = false;

      const calendarApi = this.$refs.calendar.getApi();
      calendarApi.today(); // Takvimi bugüne getirin
    },

    setTurkishLocale() {
      this.calendarOptions.locale = "tr"; // Dil ayarını Türkçe olarak belirle
      this.calendarOptions.slotLabelFormat = {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      }; // Saat formatını Türkçe ayarla
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    async handleDateSelect(selectInfo, condition) {
      // await new Promise((resolve) => {
      //   setTimeout(resolve, 100);
      // });

      if (condition) {
        globalStore.isUpdating = true;
        let calendarApi = selectInfo.view.calendar;
        console.log("eyyo", selectInfo.view.calendar);
        console.log("baslangic", selectInfo.event.startStr);
        globalStore.calendarSideBarType = "";
        globalStore.isCreateAppointmentSideBarOpen = true; //when an cell clicked, open the side bar
        SearchFreeSlotsforClinic().$state.calendarSelectedStartDate =
          selectInfo.event.startStr; //send start en end days to side bar component
        SearchFreeSlotsforClinic().$state.calendarSelectedEndDate =
          moment(selectInfo.event.startStr)
            .add(-2, "hour")
            .format("YYYY-MM-DDTHH:mm:ss") + "Z";
        
        calendarApi.unselect(); // clear date selection
        //burada orada çekilen patientstoreu extended propstan ayarlaman lazım
      } else {
        globalStore.isUpdating = false;
        let calendarApi = selectInfo.view.calendar;
        console.log("eyyo", selectInfo.view.calendar);
        console.log("baslangic", selectInfo.startStr + "T00:00:00Z");
        globalStore.calendarSideBarType = "";
        globalStore.isCreateAppointmentSideBarOpen = true; //when an cell clicked, open the side bar
        SearchFreeSlotsforClinic().$state.calendarSelectedStartDate =
          selectInfo.startStr + "T00:00:00Z"; //send start en end days to side bar component
        SearchFreeSlotsforClinic().$state.calendarSelectedEndDate =
          selectInfo.endStr + "T00:00:00Z";

        calendarApi.unselect(); // clear date selection
      }
      globalStore.isCreateAppointmentSideBarOpen = true;
      
    },
    async handleEventClick(clickInfo) {
      console.log(clickInfo);
      
    },
    handleEventDelete(arguman) {
      globalStore.isCreateAppointmentSideBarOpen = false;
      this.openCancelPopUpTrue();
      this.deletingDate = arguman.startStr;
      this.deletingEvent = arguman;
      console.log(arguman);
    },
    async handleEventUpdate(arguman) {
      globalStore.isUpdating = true; //ama sonra bunu kapa
     
      this.handleDateSelect(arguman, true);
      console.log("argumentum", arguman);
      const fullName = arguman.event._def.title.split(" ");
      useAppointmentBook().updatingAppointment =
        arguman.event._def.extendedProps.patientApponintmentCode;
      useAppointmentBook().searchPatientInput =
        arguman.event._def.extendedProps.patient;
      useAppointmentBook().selectedPatientID =
        arguman.event._def.extendedProps.patientID;
      console.log(updatingAppointment, "apdeytıd");
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleEventsConsoleLog(events) {
      console.log("events", events);
    },
    handleResetTheEvents() {
      this.currentEvents = [];
      this.initalBackgroundMonthlyEvents = [];
    },
    handleSetCalendarData() {
      this.calendarOptions.events = [
        ...initalBackgroundMonthlyEvents,
        ...INITIAL_EVENTS,
      ];
      console.log("handleSetCalendarData");
    },
    
  },
});
</script>

<template>
  <div class="relative monthlyCalendar">
    <div class="absolute z-[998] right-20 top-20">
      <customInfoCard v-if="globalStore.customInfoCard" />
    </div>
    <div
      v-if="globalStore.isAppointmentCreateSuccesful"
      class="bg-green-50 border rounded-lg w-2/5 h-10 pop-up success"
    >
      Randevu başarı ile oluşturuldu.
    </div>
    <div
      v-if="globalStore.isAppointmentCreateFail"
      class="bg-red-300 border rounded-lg w-2/5 h-11 pop-up success"
    >
      Randevu oluşturulurken bir hata meydana geldi.
    </div>
    <div class="calendar-app">
      <div class="relative"></div>
      <div class="calendar-app-main">
        <FullCalendar
          ref="fullCalendar"
          class="calendar-app-calendar"
          :options="calendarOptions"
        >
          <template v-slot:eventContent="arg">
            <div
              @mouseover="arg.onHover = true"
              @mouseleave="arg.onHover = false"
              @click="handleEventsConsoleLog(arg.event)"
              class="w-full relative group"
            >
              <div
                class="bg-[#707070] z-[51] text-white absolute hidden group-hover:block w-32 right-0 -top-4"
              >
                <p
                  class="border-bottom p-1 hover:opacity-80 cursor-pointer"
                  @click="handleEventUpdate(arg)"
                  v-if="arg.event.extendedProps.patientStatus === 'Hasta'"
                >
                  Düzenle
                </p>
                <p
                  class="p-1 hover:opacity-80 cursor-pointer"
                  @click="handleEventDelete(arg.event)"
                >
                  Sil
                </p>
              </div>
              <div class="calendar-monthly" v-if="arg.event.title !== ''">
                <b>{{ arg.event.title }}</b>
                <div class="clinic">
                  <span class="clinicText">{{
                    arg.event.extendedProps.patientStatus
                  }}</span>
                </div>
              </div>
            </div>
          </template>
        </FullCalendar>
      </div>
      <hastaRandevuAjanda />
      <assistantDeleteAppointment
        :openCancelPopUpFalse="openCancelPopUpFalse"
        :deletingDate="deletingDate"
        :deletingEvent="deletingEvent"
        type="appointment"
        v-if="openCancelPopUp"
      />
      <div class="overlay" v-if="openCancelPopUp"></div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.monthlyCalendar .calendar-app-main {
  padding: 0 3em 0 3em;
  height: 700px;
}

.monthlyCalendar .slidingPageBackground {
  display: none;
}

@import "./calendarStyle.css";
.pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
}

.pop-up.success {
  opacity: 1;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
