import { createRouter, createWebHistory } from "vue-router";
import studentAssistantHomeView from "../../modules/studentAsistant/view/studentAssistantHomeView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

// import { requireAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/student-assistant",
    name: "studentAssistantHomeView",
    component: studentAssistantHomeView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
