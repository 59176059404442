<template>
  <div class="bar">
    <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar, Chart } from "vue-chartjs";
import {
  data,
  tooltip,
  toplamBirimPuani,
} from "@/modules/doctorSlots/components/temporaryChartData/barChartConfig.js";
import { ref } from "vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const buildDataset = (index) => {
  console.log(index);
  console.log("index", index, "labels", toplamBirimPuani.labels);
  return {
    labels: toplamBirimPuani.labels[index],
    label: toplamBirimPuani.datasets[index].label,
    data: toplamBirimPuani.datasets[index].data,
    barPercentage: 1,
    backgroundColor: "#526581",
    borderRadius: 5,
  };
};
const resetDataset = () => ({
  labels: data.labels,
  label: data.datasets[0].label,
  data: data.datasets[0].data,
  barPercentage: 1,
  backgroundColor: "#526581",
  borderRadius: 5,
});
export default {
  components: {
    Bar,
  },
  data() {
    const addChart = (index) => {
      const newSet = buildDataset(index);
      this.datasets = [];
      this.datasets.push(newSet);
      this.labels = [];
      this.labels.push(newSet.label);
      console.log(this.labels);
    };
    const resetChart = () => {
      const newSet = resetDataset();
      console.log(newSet);
      this.datasets = [];
      this.datasets.push(newSet);
      this.labels = [];
      this.labels.push(newSet.labels);
      console.log("labels", this.datasets);
    };
    return {
      datasets: [resetDataset()],
      chartOptions: {
        type: "bar",
        responsive: true,
        scales: {
          x: {
            stacked: true,
            categoryPercentage: 0.5,
            barPercentage: 1,
          },

          y: {
            stacked: true,
          },
        },
        maintainAspectRatio: false,
        onClick: function (evt, array) {
          if (array.length === 0) {
            resetChart();
          } else {
            addChart(array[0].index);
          }
        },
      },
    };
  },

  computed: {
    chartData() {
      return {
        labels: [...this.datasets[0].labels],
        datasets: [...this.datasets],
      };
    },
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  min-height: 400px;

  padding: 2rem;
  background: #fff;
}
</style>
