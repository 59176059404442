<template>
  <div class="flex w-full min-h-screen overflow-y-hidden">
    <doctorHastaKabulSidebar />
    <div class="w-full relative flex flex-col overflow-y-hidden">
      <createAnamnesisHeader title="Yeni Anamnez Oluştur" />
      <main class="flex h-full bg-white headerlessHeight overflow-y-hidden">
        <anamnesisFormElements />
        <createAnamnesisTemplate />
      </main>
    </div>
  </div>
</template>

<script setup>
import anamnesisFormElements from "@/modules/anamnezForm/components/createAnamnesisView/anamnesisFormElements.vue";
import createAnamnesisTemplate from "@/modules/anamnezForm/components/createAnamnesisView/createAnamnesisTemplate.vue";
import createAnamnesisHeader from "@/modules/anamnezForm/components/createAnamnesisView/createAnamnesisHeader";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;
</script>

<style scoped>
.headerlessHeight {
  height: calc(100vh - 73px);
}
</style>
