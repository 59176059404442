<template>
  <div class="flex w-full h-full">
    <protezSidebar :activeRoute="4" />
    <div class="flex flex-col w-full h-full pl-6 pr-28">
      <div class="w-full px-3 py-8 flex gap-12 items-center">
        <h2 class="text-4xl font-semibold text-[#526581]">
          Laboratuvar Listesi
        </h2>
        <div class="border flex items-center p-1 w-72 gap-4">
          <img :src="searchIcon" alt="searchIcon" />
          <input
            class="w-full focus:outline-none"
            type="text"
            placeholder="TC ya da Hasta ara"
          />
        </div>
      </div>
      <div class="flex">
        <div class="flex flex-col">
          <protezTable :currentPage="currentPage" />
          <div class="flex items-center gap-4 text-right w-full mt-2">
            <div class="bg-[#5D3FD3] w-3 h-3 rounded-full ml-auto"></div>
            Tablodaki renkli alanlar dışarıdan gelen siparişleri göstermektedir
          </div>
          <div class="mb-5 ml-auto mr-[50px]">
            <vue-awesome-paginate
              :total-items="tempData.length"
              :items-per-page="7"
              :max-pages-shown="7"
              v-model="currentPage"
              :on-click="onClickHandler"
            />
          </div>
        </div>
        <treatmentDetail v-if="popUpManagement.detailPopUp" />
      </div>
    </div>
    <addFolder v-if="popUpManagement.uploadPopUp" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import personelSideBar from "@/modules/personel/components/personalSideBar.vue";
import searchIcon from "../assets/searchIcon.svg";
import paginationPatientTable from "@/modules/asistanEkrani/registeredPatientList/components/paginationRegisteredPatientTable";
import protezTable from "../components/protezTable.vue";
import treatmentDetail from "../components/treatmentDetail.vue";
import addFolder from "../components/addFolder.vue";
import { popUpManagement, tempData } from "../store/temporaryProtezData.js";
import protezSidebar from "@/modules/protez/components/protezSidebar.vue";

const onClickHandler = (page) => {
  console.log(page);
};
const currentPage = ref(1);
</script>
