import { clinicDomain } from "@/common/domains";
import axios from "axios";
import { authToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import { searchSlotsBetweenDate } from "../../store/modules/operationsStore/slot/searchSlotsBetweenDate";
import { searchSlotsByDoctor } from "../../store/modules/operationsStore/slot/searchSlotsByDoctor";
import moment from "moment";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { useAppointments } from "@/modules/personel/components/appointmentsStore";
import { fetchAppointmentsRequest } from "@/types/operations/appointment/fetchAppointmentsRequest";

import _ from "lodash";
import { calendarStoreCalendarFilter } from "@/modules/asistanEkrani/asistanCalendar/store/calendarStore.js";
let eventGuid = 0;
interface Event {
  id: string;
  title: string;
  detail: string;
  patientStatus: string;
  start: string;
  end: string;
  unitCode: string;
  onHover: boolean;
}

let INITIAL_EVENTS: Event[] = [
  {
    id: createEventId(),
    title: "",
    detail: "",
    patientStatus: "",
    start: "",
    end: "",
    unitCode: "",
    onHover: true,
  },
];

let initalBackgroundWeeklyEvents: {
  start: string;
  end: string;
  display: string;
}[] = [];
let initalBackgroundMonthlyEvents: {
  start: string;
  end: string;
  display: string;
}[] = [];
let initalBackgroundListEvents: {
  start: string;
  end: string;
  title: string;
}[] = [];
let backgroundEvents: { start: string; end: string; display: string }[] = [];
let backgroundListEvents: { start: string; end: string; title: string }[] = [];
let backgroundMonthlyEvents: { start: string; end: string; display: string }[] =
  [];

async function fetchFreeWeeklySlots() {
  // console.log("BU MUUUUUUUUUUUUUUUUU");
  const store = searchSlotsBetweenDate();
  initalBackgroundWeeklyEvents = [];
  backgroundEvents = [];
  if (store.slotsBetweenDateResponse.count > 0) {
    let emptySlots: any[] = [];
    Object.keys(store.slotsBetweenDateResponse.slots.data).forEach(function (
      key,
      index
    ) {
      emptySlots.push(store.slotsBetweenDateResponse.slots.data[key]);
    });

    //   emptySlots = JSON.parse(JSON.stringify(emptySlots));
    emptySlots.map((emptySlotItems: any) => {
      const startTime = new Date(emptySlotItems.attributes.start_time);
      startTime.setMinutes(0);
      const endTime = new Date(emptySlotItems.attributes.start_time);
      endTime.setHours(endTime.getHours() + 1);
      endTime.setMinutes(0);
      const weeklyEvent = {
        groupId: "aviliableForAppointment",
        start: startTime.toISOString(),
        end: endTime.toISOString(),
        slotStart: new Date(emptySlotItems.attributes.start_time).toISOString(),
        slotEnd: new Date(emptySlotItems.attributes.end_time).toISOString(),
        display: "background",
        // yol bulamazsan diğer eventte yaptığın gibi tarihi 00 minute'a ayarlarsın hatta ende gerek yok ilk saate 1 ekleyip onu 00.00a eşitlersin end de o olur
      };
      backgroundEvents.push(weeklyEvent);
    });
    console.log("backgroundEvents", backgroundEvents);
    initalBackgroundWeeklyEvents = [...backgroundEvents];
    console.log("initalBackgroundWeeklyEvents", initalBackgroundWeeklyEvents);
  }
}

async function fetchFreeWeeklySlotsbyDoctor() {
  const store = searchSlotsByDoctor();
  initalBackgroundWeeklyEvents = [];
  backgroundEvents = [];
  console.log(store.doctorSlots, "berhr3hhwqrhewhe");
  if (store.doctorSlots) {
    let emptySlots: any[] = [];

    const slotArray = store.doctorSlots;
    console.log(slotArray, "dsfgı");
    for (const slot of slotArray) {
      emptySlots.push(slot);
    }

    // emptySlots = JSON.parse(JSON.stringify(emptySlots));
    console.log("empiti", emptySlots);
    emptySlots.map((emptySlotItem: any) => {
      const weeklyEvent = {
        groupId: "aviliableForAppointment",
        start: new Date(emptySlotItem.StartTime).toISOString(),
        end: new Date(emptySlotItem.EndTime).toISOString(),
        display: "background",
      };
      backgroundEvents.push(weeklyEvent);
    });

    console.log("backgroundEvents", backgroundEvents);
    initalBackgroundWeeklyEvents = [...backgroundEvents];
    console.log("initalBackgroundWeeklyEvents", initalBackgroundWeeklyEvents);
  }
}
async function fetchFreeListSlots() {
  const store = searchSlotsBetweenDate();
  store.$state.slotsBetweenDateResponse.count > 0 &&
    store.$state.slotsBetweenDateResponse.slots.data.map((index: any) => {
      const event = {
        start: new Date(index.StartTime).toISOString(),
        end: new Date(index.EndTime).toISOString(),
        title: "background",
      };

      backgroundListEvents.push(event);
    });
  initalBackgroundListEvents = [...backgroundListEvents];
}

async function fetchFreeMonthlySlots() {
  const store = searchSlotsBetweenDate();
  initalBackgroundMonthlyEvents = [];
  backgroundMonthlyEvents = [];
  if (store.slotsBetweenDateResponse.count > 0) {
    let emptySlots: any[] = [];
    Object.keys(store.slotsBetweenDateResponse.slots.data).forEach(function (
      key,
      index
    ) {
      emptySlots.push(store.slotsBetweenDateResponse.slots.data[key]);
    });
    // emptySlots = JSON.parse(JSON.stringify(emptySlots));
    emptySlots.map((emptySlotItems: any) => {
      // if (emptySlotItems.Status === "free") {
      // console.log("sdfj", emptySlotItems);
      const monthlyEvent = {
        groupId: "aviliableForAppointment",
        start: new Date(emptySlotItems.attributes.start_time)
          .toISOString()
          .substring(0, 10),
        end: new Date(emptySlotItems.attributes.end_time)
          .toISOString()
          .substring(0, 10),
        display: "background",
      };
      backgroundMonthlyEvents.push(monthlyEvent);
      // }
    });
    initalBackgroundMonthlyEvents = [...backgroundMonthlyEvents];
    return initalBackgroundMonthlyEvents;
  }
}

async function fetchAppointments(
  fetchAppointmentsReq: fetchAppointmentsRequest
) {
  try {
    let updatedEvents: Event[] = [];
    // globalStore.isEventsLoading = true;
    const personelStoreData = personelStore();
    const appointmentsStore = useAppointments();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    let patientStatus = null;
    let userName = "",
      userSurname = "",
      unitName = "",
      unitCode = "";

    try {
      await axios
        .post(
          `${clinicDomain}/appointments/filter`,
          fetchAppointmentsReq,
          // {
          //   doctor_id: "",
          //   doctor_name: "",
          //   doctor_surname: "",
          //   unit_id: "350392bb-80f7-43d4-a7f2-007b78c3361b",
          //   tc_identity_number: "",
          //   patient_name: "",
          //   patient_surname: "",
          //   phone_number: "",
          //   start_date: startDate,
          //   end_date: endDate,
          //   provision_identifier: "",
          //   page_id: 1,
          //   page_size: 500,
          // },

          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((res) => {
          console.log("epoyıntmınt", res.data);
          INITIAL_EVENTS = [];
          // if (res.data.count === 0) {
          //   console.log("Calendar Event there is no appointment.");
          //   // globalStore.isEventsLoading = false;

          //   INITIAL_EVENTS = [];
          //   return;
          // }
          if (res.data) {
            globalStore.isEventsLoading = false;
            console.log(
              "Calendar Event Appointments : ",
              res.data.appointments.data
            );
            res.data.appointments.data.map((appointment: any) => {
              console.log(appointment.attributes.name);
              console.log(
                "aa",
                moment(appointment.attributes.appointment_time)
                  .utc()
                  .format("YYYY-DD-MMTHH:mm:ss[Z]")
                  .replace(/:\d{2}:\d{2}Z$/, ":00:00Z")
              );

              const startTime = ``;
              const event = {
                id: createEventId(),
                title: `${appointment.attributes.patient.patient_name} ${appointment.attributes.patient.patient_surname}`,
                // title: appointment.attributes.patient.patient_name
                // title: "Berkay Karademir",
                doctorName: appointment.attributes.doctor_name,
                detail: appointment.attributes.unit_name,
                takipNo: appointment.attributes.tracking_number,
                patient: appointment.attributes.patient.tc_id_number,
                patientID: appointment.attributes.patient.patient_id,
                // patient: "31823193662",
                patientStatus: "Hasta",
                patientApponintmentCode: appointment.id,
                patientApplicationCode:
                  appointment.attributes.patient.patient_application_id,
                // patientApponintmentCode: appointment.RandevuKodu,
                // patientApplicationCode: appointment.HastaBasvuruKodu.String,

                // start: new Date("2024-11-03 12:04:05 +0000 UTC").toISOString(),

                // end: new Date("2024-11-03 12:04:05 +0000 UTC").toISOString(),
                // start: "2024-03-12T10:30:00.000Z",

                // end: "2024-03-12T10:45:00.000Z",
                start: moment(appointment.attributes.appointment_time)
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss[Z]")
                  .replace(/:\d{2}:\d{2}Z$/, ":00:00Z"),

                end: moment(appointment.attributes.appointment_time)
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss[Z]")
                  .replace(/:\d{2}:\d{2}Z$/, ":00:00Z"),

                appointmentStart: moment(
                  appointment.attributes.appointment_time
                )
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss[Z]"),
                appointmentEnd: moment(
                  appointment.attributes.appointment_end_time
                )
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss[Z]"),
                unitCode: `Endodonti`,
                onHover: true,
              };
              updatedEvents.push(event);
              //  else {
              //   const event = {
              //     id: createEventId(),
              //     // title: `${userName} ${userSurname}`,

              //     title: `${appointment.IptalAciklama.String}`,
              //     detail: ``,
              //     patient: ``,
              //     patientStatus: "İptal",
              //     patientApponintmentCode: appointment.RandevuKodu,
              //     takipNo: `${appointment.Takipno}`,
              //     start: moment(appointment.StartTime)
              //       .utc()
              //       .format("YYYY-MM-DDTHH:mm:ss[Z]"),
              //     end: moment(appointment.EndTime)
              //       .utc()
              //       .format("YYYY-MM-DDTHH:mm:ss[Z]"),
              //     unitCode: ``,
              //     onHover: true,
              //   };
              //   updatedEvents.push(event);
              // }
            });
          }
          INITIAL_EVENTS = [...updatedEvents];
          console.log("eyywererw", res.data);
          console.log("nenene", INITIAL_EVENTS);
        });
    } catch (error) {
      // Hata yönetimi
      globalStore.isEventsLoading = false;

      console.error("Hasta bilgisi alınırken bir hata oluştu:", error);
    }
  } catch (error) {
    globalStore.isEventsLoading = false;

    // Hata yönetimi
    console.error("Randevu alınırken bir hata oluştu:", error);
  }
}

const fetchDoctorSettings = async (doctorId, startDate, endDate, type) => {
  //type calendar or list
  let updatedEvents: Event[] = [];
  globalStore.isEventsLoading = true;
  try {
    const personelStoreData = personelStore();
    const response = await axios.get(
      `${clinicDomain}/doctor-settings/doctor?id=${doctorId}&start=${startDate}&end=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${personelStoreData.personel!.token}`,
        },
      }
    );
    console.log("fetchDoctorSettings", response.data);
    if (response.data.settings.data.length === 0) {
      console.log("Calendar Event there is no settings.");
      globalStore.isEventsLoading = false;
      INITIAL_EVENTS = [];
      globalStore.isEventsLoading = false;
      return;
    }

    if (type === "list") {
      globalStore.isEventsLoading = false;
      return response.data.settings.data;
    }

    response.data.settings.data.forEach((settingsArray) => {
      settingsArray.attributes.doctor_settings.forEach((setting) => {
        {
          const event = {
            id: createEventId(),
            title: `${setting.WorkingHours}`,
            detail: ``,
            patient: `setting`,
            patientStatus: "",
            patientApponintmentCode: `${setting.ID}`,
            start: moment(setting.SlotDate)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            end: moment(setting.SlotDate)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            unitCode: ``,
            onHover: true,
          };
          updatedEvents.push(event);
        }
      });
    });
    globalStore.isEventsLoading = false;
    INITIAL_EVENTS = [...updatedEvents];
  } catch (error) {
    globalStore.isEventsLoading = false;
    console.error("Error in fetchDoctorSettings:", error);
  }
};

function createEventId(): string {
  return String(eventGuid++);
}

export {
  fetchAppointments,
  INITIAL_EVENTS,
  createEventId,
  initalBackgroundWeeklyEvents,
  initalBackgroundMonthlyEvents,
  initalBackgroundListEvents,
  fetchFreeWeeklySlots,
  fetchFreeWeeklySlotsbyDoctor,
  fetchFreeMonthlySlots,
  fetchFreeListSlots,
  fetchDoctorSettings,
};
