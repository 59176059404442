<template>
  <div class="flex flex-col h-full w-full">
    <div class="flex w-full">
      <div class="flex w-full flex-col overflow-x-auto">
        <table class="w-full">
          <thead class="w-full">
            <tr class="min-h-[54px] h-[54px] w-full bg-[#F8F9FD] text-center">
              <th class="text-xl min-w-[180px] w-[180px]">Başvuru Tarihi</th>
              <th class="text-xl min-w-[180px] w-[180px]">Tedavi Tipi</th>
              <th class="text-xl min-w-[180px] w-[180px]">Tedavi Türü</th>
              <th class="text-xl min-w-[180px] w-[180px]">Statü</th>
              <th class="text-xl min-w-[180px] w-[180px]">İşlem Adı</th>
              <th class="text-xl min-w-[180px] w-[180px]">İstem Tipi</th>
              <th class="text-xl min-w-[180px] w-[180px]">Cihaz</th>
              <th class="text-xl min-w-[180px] w-[180px]">Provizyon Tipi</th>
              <th class="text-xl min-w-[180px] w-[180px]">TC Kimlik No.</th>
              <th class="text-xl min-w-[180px] w-[180px]">Hasta Adı</th>
              <th class="text-xl min-w-[180px] w-[180px]">Hasta No.</th>
              <th class="text-xl min-w-[180px] w-[180px]">Başvuru No.</th>
              <th class="text-xl min-w-[220px] w-[220px]">İstem Yapan Birim</th>
              <th class="text-xl min-w-[220px] w-[220px]">İstem Yapan Hekim</th>
              <th class="text-xl min-w-[220px] w-[220px]">B.K.Eden Personel</th>
              <th class="text-xl min-w-[220px]"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(patient, index) in tempData"
              :key="index"
              class="table-element border-y-[1px]"
              @click="
                () => {
                  openEditMenu();
                }
              "
            >
              <td class="flex flex-col items-start min-w-[180px] w-[180px]">
                <div
                  class="flex flex-col justify-center items-center w-full h-[60px] text-center"
                >
                  {{ patient.Başvuru_Tarihi }}
                  {{ patient.Başvuru_Saati }}
                </div>
              </td>
              <td class="min-w-[180px] w-[180px]">
                <div class="flex flex-col justify-center items-center">
                  <div class="h-[60px] flex items-center justify-center">
                    {{ patient.tedavitipi }}
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-col justify-center">
                  <div class="h-[60px] flex justify-center items-center">
                    {{ patient.tedavituru }}
                  </div>
                  <!-- <div
                  class="flex items-center justify-center w-[95px] h-[32px] unitBG border-[1px] rounded-[5px]"
                >
                  <div class="unitDot w-[9px] h-[9px] rounded-full"></div>
                  <p class="font-medium unitText ml-1">
                    {{ getColorOfUnit(patient.Provizyontipi) }}
                    {{ patient.tedavituru }}
                  </p>
                </div> -->
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <div class="h-[60px] flex items-center justify-center">
                    {{ patient.status }}
                  </div>
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ patient.islemAdi }}
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ patient.istemTipi }}
                </div>
              </td>
              <td>
                <button
                  @click="radiologyStore.radiologyOpenSideBar = true"
                  class="flex justify-center items-center border-[#277DD2] border-[1px] rounded-[20px] w-[100px] h-[30px] mx-auto"
                >
                  <img :src="plusBlue" alt="plus" class="mr-1" />
                  <p class="text-[#277DD2]">Sıraya al</p>
                </button>
              </td>
              <td>
                <div
                  class="flex justify-center items-center bg-[#F0AD4E] border-[1px] rounded-[5px] w-[100px] h-[38px] mx-auto"
                >
                  <p class="text-white">{{ patient.provizyonTipi }}</p>
                </div>
              </td>
              <td>
                <div class="flex justify-center items-center h-[60px]">
                  <p>
                    {{ patient.tcKimlikNo }}
                  </p>
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ _.startCase(_.toLower(patient.hastaAdi)) }}
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ _.startCase(_.toLower(patient.hastaNo)) }}
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ _.startCase(_.toLower(patient.basvuruNo)) }}
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ _.startCase(_.toLower(patient.islemYapanBirim)) }}
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ patient.islemYapanHekim }}
                </div>
              </td>
              <td>
                <div class="h-[60px] flex items-center justify-center">
                  {{ patient.BKEdenPersonel }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import _ from "lodash";

import { tempData } from "../store/temporaryRadiologyData.js";
import { useRadiologyStore } from "../store/radiologyStore.js";

import plusBlue from "../assets/plus_blue.svg";
// Toggle Panel and request to getPatient for patient info

const radiologyStore = useRadiologyStore();
const unitColorRef = ref("#000");

const getColorOfUnit = (birim) => {
  switch (birim) {
    case "Endodonti":
      unitColorRef.value = "#F0AD4E";
      break;
    case "Periodontoloji":
      unitColorRef.value = "#5BC0DE";
      break;
    case "Pedodonti":
      unitColorRef.value = "#DE5BC1";
      break;
    case "Radyoloji":
      unitColorRef.value = "#F65D58";
      break;
    case "Restoratif":
      unitColorRef.value = "#6498FD";
      break;
    case "Oral Diagnoz":
      unitColorRef.value = "#8BB8B0";
      break;
  }
};

const openEditMenu = () => {
  console.log("openEditMenu");
};
</script>
