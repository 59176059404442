<template>
  <div class="bar">
    <Bar
      :options="chartOptions"
      :data="chartData"
      id="my-chart-id"
      :plugins="plugins"
      :css-classes="cssClasses"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  mesaiDisiBirimToplamFiyat,
  genelMesaiDisiBirimToplamFiyat,
} from "@/modules/ekOdeme/store/tempChartData.js";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
const buildDataset = (index) => ({
  labels: mesaiDisiBirimToplamFiyat.labels,
  datasets: [
    {
      label: mesaiDisiBirimToplamFiyat.datasets[index].label,
      data: mesaiDisiBirimToplamFiyat.datasets[index].data,
      backgroundColor: "#526581",
      borderRadius: 20,
      barPercentage: 0.3,
    },
  ],
});
const resetDataset = () => ({
  labels: genelMesaiDisiBirimToplamFiyat.labels,
  datasets: genelMesaiDisiBirimToplamFiyat.datasets,
});
export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    // styles: {
    //   type: Object,
    //   default: () => {},
    // },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const addChart = (index) => {
      const newSet = buildDataset(index);
      this.datasets = [];
      this.datasets.push(newSet);

      console.log(this.labels);
      console.log(this.datasets);
    };
    const resetChart = () => {
      const newSet = resetDataset();
      console.log(newSet);
      this.datasets = [];
      this.datasets.push(newSet);
      this.labels = [];
      this.labels.push(newSet.labels);
      console.log("labels", this.datasets);
    };
    return {
      datasets: [
        {
          labels: [
            "Ocak",
            "Şubat",
            "Mart",
            "Nisan",
            "Mayıs",
            "Haziran",
            "Temmuz",
            "Ağustos",
            "Eylül",
            "Ekim",
            "Kasım",
            "Aralık",
          ],
          datasets: [
            {
              label: "Endodonti",
              backgroundColor: "#F0AD4E",
              data: [
                500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500,
                1000,
              ],
              borderRadius: 5,
            },
            {
              label: "Periodontoloji",
              data: [
                1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000,
                2000,
              ],
              backgroundColor: "#5BC0DE",
              borderRadius: 5,
            },
            {
              label: "Pedodonti",
              data: [
                2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000,
                3000,
              ],
              backgroundColor: "#DE5BC1",
              borderRadius: 5,
            },
            {
              label: "Radyoloji",
              data: [
                3000, 4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000,
                4000,
              ],
              backgroundColor: "#F65D58",
              borderRadius: 5,
            },
            {
              label: "Restoratif",
              data: [
                4000, 5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000,
                5000,
              ],
              backgroundColor: "#6498FD",
              borderRadius: 5,
            },
            {
              label: "Oral Diagnoz",
              data: [
                5000, 6000, 7000, 8000, 9000, 500, 1000, 2000, 3000, 4000, 5000,
                6000,
              ],
              backgroundColor: "#A9CFC8",
              borderRadius: 5,
            },
          ],
        },
      ],

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        onClick: function (evt, array, chart) {
          if (array.length === 0) {
            resetChart();
          } else {
            addChart(array[0].index);
          }

          //   evt.update();
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: [...this.datasets[0].labels],
        datasets: [...this.datasets[0].datasets],
      };
    },
  },
};
</script>
<style scoped>
.bar {
  width: 100%;
  min-height: 400px;

  padding: 2rem;
  background: #fff;
}
</style>
