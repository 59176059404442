import { createRouter, createWebHistory } from "vue-router";
import anamnesesHomeView from "../../modules/anamnezForm/view/anamnesesHomeView.vue";
import anamnesesUnitView from "../../modules/anamnezForm/view/anamnesesUnitView.vue";
import createAnamnesisView from "../../modules/anamnezForm/view/createAnamnesisView.vue";
import readAnamnesisView from "../../modules/anamnezForm/view/readAnamnesisView.vue";
import updateAnamnesisView from "../../modules/anamnezForm/view/updateAnamnesisView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/polyclinic/anamneses",
    name: "anamnesesHomeView",
    component: anamnesesHomeView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/anamneses/:unitID",
    name: "anamnesesUnitView",
    component: anamnesesUnitView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/anamneses/:unitID/create-anamnesis",
    name: "createAnamnesisView",
    component: createAnamnesisView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/anamneses/:unitID/read-anamnesis/:anamnesisID",
    name: "readAnamnesisView",
    component: readAnamnesisView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/anamneses/:unitID/update-anamnesis/:anamnesisID",
    name: "updateAnamnesisView",
    component: updateAnamnesisView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
