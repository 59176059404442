import axios from "axios";
import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import moment from "moment";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

import {
  fetchFreeMonthlySlots,
  fetchFreeWeeklySlots,
  fetchFreeWeeklySlotsbyDoctor,
  fetchFreeListSlots,
} from "@/components/asistant/CalendarEvent";

// export const corsHeaders = {
//   "Access-Control-Allow-Origin": "*",
//   "Access-Control-Allow-Headers":
//     "authorization, x-client-info, apikey, content-type",
// };

export const searchSlotsBetweenDate = defineStore({
  id: "slotsBetweenDate",
  state: () => ({
    calendarSelectedStartDate: Date(),
    calendarSelectedEndDate: Date(),
    slotsBetweenDateResponse: {
      count: 0,
      slots: {
        data: [],
      },
    },
    pickerSlotsBetweenDateResponse: {
      count: 0,
      slots: {
        data: [],
      },
    },
  }),
  persist: {
    storage: sessionStorage,
    paths: ["slotsBetweenDateResponse"],
  },
  actions: {
    // async slotsBetweenDate(
    //   startDate: string,
    //   endDate: string,
    //   functionType: string,
    //   selectedClinic: string
    // ) {
    //   globalStore.isEventsLoading = true;
    //   const personelStoreData = personelStore();
    //   await axios
    //     .get(
    //       `${clinicDomain}/slots?page_id=1&page_size=40`,

    //       {
    //         headers: {
    //           Authorization: `Bearer ${personelStoreData.personel!.token}`,
    //         },
    //       }
    //     )
    //     .then(async (response) => {
    //       globalStore.isEventsLoading = false;
    //       this.slotsBetweenDateResponse = response.data;
    //       console.log("slot response", response.data);
    //       switch (functionType) {
    //         case "calendarWeeklySlots":
    //           await fetchFreeWeeklySlots();
    //           break;
    //         case "calendarMonthlySlots":
    //           await fetchFreeMonthlySlots();
    //           break;
    //         case "calendarListSlots":
    //           await fetchFreeListSlots();
    //           break;
    //           // case "calendarWeeklySlotsbyDoctor":
    //           // await fetchFreeWeeklySlotsbyDoctor();
    //           break;

    //         default:
    //           break;
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Error slot", error);
    //       globalStore.isEventsLoading = false; //donulecek
    //     });
    // },
    async slotsBetweenDate(
      doctor,
      startDate: string,
      endDate: string,
      functionType: string,
      status: string,
      selectedClinic: string,
      currentPage,
      pageSize,
      from: string,
      appointmentType?
    ) {
      globalStore.isEventsLoading = true;
      const personelStoreData = personelStore();
      let token = localStorage.getItem("patientStoreData");
      let accessToken = token ? JSON.parse(token).accessToken : "";
      await axios
        .post(
          `${clinicDomain}/slots/filter`,
          {
            practitioner_id: doctor,
            location_id: "",
            unit_id: selectedClinic,
            status: status,
            treatment_type: appointmentType,
            start_time: startDate + "T00:00:00Z",
            end_time: endDate + "T00:00:00Z",
            page_id: currentPage,
            page_size: pageSize,
          },
          {
            headers: {
              Authorization: `Bearer ${
                accessToken || personelStoreData.personel!.token
              }`,
            },
          }
        )
        .then(async (response) => {
          globalStore.isEventsLoading = false;
          if (from === "calendar") {
            this.slotsBetweenDateResponse = response.data;
          } else {
            this.pickerSlotsBetweenDateResponse = response.data;
          }
          console.log("slot response", response.data);
          switch (functionType) {
            case "calendarWeeklySlots":
              await fetchFreeWeeklySlots();
              break;
            case "calendarMonthlySlots":
              await fetchFreeMonthlySlots();
              break;
            case "calendarListSlots":
              await fetchFreeListSlots();
              break;
              // case "calendarWeeklySlotsbyDoctor":
              // await fetchFreeWeeklySlotsbyDoctor();
              break;

            default:
              break;
          }
        })
        .catch((error) => {
          console.error("Error slot", error);
          globalStore.isEventsLoading = false; //donulecek
        });
    },
  },
});
