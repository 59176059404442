<template>
  <div
    class="flex flex-col h-full mx-auto w-[94%] max-w-[94%] min-h-[400px] mt-4"
  >
    <div class="flex">
      <button @click="routerToEkOdeme()" class="flex items-center">
        <img :src="leftArrowBlack" alt="arrow" />
        <p class="text-[#252525] ml-2">Ana sayfa</p>
      </button>

      <VueDatePicker
        v-model="date"
        locale="tr"
        auto-apply
        :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
        :highlight="highlightedDates"
        class="datepicker w-[158px] border-[1px] border-[#5265811A] rounded-[5px] ml-auto mr-[88px]"
      />
    </div>
    <div class="flex mt-3">
      <!-- Toplam birim puanı -->
      <button
        class="flex flex-col min-w-[343px] min-h-[167px] rounded-[5px] bg-white border-[1px] border-[#5265811A]"
      >
        <p class="text-[#526581] mt-[24px] ml-[29px] font-medium">
          Toplam birim puanı
        </p>
        <p class="mt-[24px] ml-[29px] text-[#252525] font-medium text-3xl">
          5298
        </p>
        <div
          class="flex items-center h-[45px] mt-auto w-full bg-[#5265810D] rounded-b-[5px]"
        >
          <img :src="arrowRightUpGreen" class="w-3 ml-[24px]" alt="yuzde" />
          <p class="text-[#04842D] font-medium ml-2">2,15 %</p>
          <p class="text-[#526581] font-light ml-6">Son ay</p>
          <button class="ml-auto mr-7">
            <p class="text-[#526581] font-medium">Detay gör</p>
          </button>
        </div>
      </button>
      <!-- Toplam birim puanının gelire dönüşü  -->
      <button
        class="flex flex-col min-w-[343px] min-h-[167px] rounded-[5px] bg-white border-[1px] border-[#5265811A] ml-[78px]"
      >
        <p class="text-[#526581] mt-[24px] ml-[29px] font-medium">
          Toplam birim puanının gelire dönüşü
        </p>
        <p class="mt-[24px] ml-[29px] text-[#252525] font-medium text-3xl">
          2.510,78 TL
        </p>
        <div
          class="flex items-center h-[45px] mt-auto w-full bg-[#5265810D] rounded-b-[5px]"
        >
          <img :src="arrowDownLeftRed" class="w-3 ml-[24px]" alt="yuzde" />
          <p class="text-[#AC0000] font-medium ml-2">5,11 %</p>
          <p class="text-[#526581] font-light ml-6">Son ay</p>
          <button class="ml-auto mr-7">
            <p class="text-[#526581] font-medium">Detay gör</p>
          </button>
        </div>
      </button>
      <!-- Mesai içi birim toplam puan and Mesai dışı birim toplam puan -->
      <button
        class="flex flex-col justify-center items-center min-w-[343px] min-h-[167px] rounded-[5px] bg-white border-[1px] border-[#5265811A] ml-[78px]"
      >
        <p class="text-[#526581] mt-[24px] flex font-medium">
          Mesai içi birim toplam puan
        </p>
        <div
          class="w-[94px] h-[1px] border-t-[0.5px] border-[#526581D9] mt-2"
        ></div>
        <p class="mt-[20px] text-[#252525] font-medium text-3xl">5250</p>
        <div
          class="flex items-center h-[45px] mt-auto w-full bg-[#5265810D] rounded-b-[5px]"
        >
          <p class="text-[#526581] font-light ml-6">Toplam gelire dönüşen</p>
          <p class="text-[#252525] font-medium ml-4">1,247.00 TL</p>
        </div>
      </button>
      <button
        class="flex flex-col justify-center items-center min-w-[343px] min-h-[167px] rounded-[5px] bg-white border-[1px] border-[#5265811A]"
      >
        <p class="text-[#526581] mt-[24px] flex font-medium">
          Mesai dışı birim toplam puan
        </p>
        <div
          class="w-[94px] h-[1px] border-t-[0.5px] border-[#526581D9] mt-2"
        ></div>
        <p class="mt-[20px] text-[#252525] font-medium text-3xl">2179</p>
        <div
          class="flex items-center h-[45px] mt-auto w-full bg-[#5265810D] rounded-b-[5px]"
        >
          <p class="text-[#526581] font-light ml-6">Toplam gelire dönüşen</p>
          <p class="text-[#252525] font-medium ml-4">2,591.21 TL</p>
        </div>
      </button>
    </div>
    <div
      v-if="ekOdemeStore.isDetayGorButtonActive == false"
      class="flex items-center bg-white mt-[35px] w-full"
    >
      <!-- Chart Navbar part -->
      <div
        class="flex min-w-[850px] max-h-[52px] w-full items-center border-b-[1px] border-[#D9D9D9B2]"
      >
        <button
          @click="ekOdemeStore.ekOdemeButtonRef = 1"
          :class="
            ekOdemeStore.ekOdemeButtonRef === 1
              ? 'activeButton'
              : 'passiveButton'
          "
          class="h-[40px] ml-1 w-[165px] focus:outline-none"
        >
          <p
            :class="
              ekOdemeStore.ekOdemeButtonRef === 1
                ? 'text-[#252C32]'
                : 'text-[#6F6F6F]'
            "
          >
            Toplam Birim Puanı
          </p>
        </button>
        <button
          :class="
            ekOdemeStore.ekOdemeButtonRef === 2
              ? 'activeButton'
              : 'passiveButton'
          "
          @click="ekOdemeStore.ekOdemeButtonRef = 2"
          class="h-[40px] w-[282px] focus:outline-none"
        >
          <p
            :class="
              ekOdemeStore.ekOdemeButtonRef === 2
                ? 'text-[#252C32]'
                : 'text-[#6F6F6F]'
            "
          >
            Toplam Birim Puanının Gelire Dönüşü
          </p>
        </button>
        <!-- Unvan Filter -->
        <Menu
          as="div"
          class="relative inline-block text-left ml-auto mr-[12px]"
        >
          <div>
            <MenuButton
              class="inline-flex gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-[#252525B2] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-[122px] min-h-[28px] max-h-[28px] items-center justify-between"
            >
              <!-- <p class="text-black/50 ml-2">Ay</p> -->
              <div class="flex items-center">
                <p class="mx-2">
                  {{ ekOdemeStore.monthFilterValue }}
                </p>
              </div>
              <img :src="arrowDownBlue" alt="arrow" class="mr-2 h-[6px]" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem
                  v-for="(month, index) in monthsOfTheYearInTurkish"
                  :key="index"
                  v-slot="{ active }"
                >
                  <div
                    @click="ekOdemeStore.monthFilterValue = month"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    class="cursor-pointer"
                  >
                    {{ month }}
                  </div>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <button class="mr-[30px]">
          <img :src="cloudDownload" alt="" />
        </button>
      </div>
      <!-- Percent Line Charts -->
      <div
        class="flex flex-col min-w-[385px] ml-auto bg-white border-[1px] border-[#5265811A]"
      >
        <div class="flex items-center min-w-[335px] min-h-[94px]">
          <div class="flex flex-col ml-[16px]">
            <p>Mesai içi</p>
            <div class="flex">
              <img :src="linePercentegeBlue" alt="" />
              <p class="text-[#252525] font-medium ml-2">%42</p>
            </div>
          </div>
          <div class="flex flex-col ml-[52px] mr-[16px]">
            <p>Mesai dışı</p>
            <div class="flex">
              <img :src="linePercentegeBlue" class="w-[163px]" alt="" />
              <p class="text-[#252525] font-medium ml-2">%65</p>
            </div>
          </div>
        </div>
        <div class="flex items-center mb-[6px]">
          <button
            @click="
              {
                ekOdemeStore.isDetayGorButtonActive = true;
                ekOdemeStore.ekOdemeButtonRef = 3;
              }
            "
            class="flex rounded-[10px] ml-[13px] border-[1px] border-[#277DD2] px-1"
          >
            <p>Detay gör</p>
          </button>
          <p class="ml-auto mr-[18px] text-[#252525] text-s">
            *Toplam gelire göre oranları
          </p>
        </div>
      </div>
    </div>
    <!-- Detay gor Section -->

    <div
      v-if="ekOdemeStore.isDetayGorButtonActive == true"
      class="flex items-center bg-white mt-[35px] w-full"
    >
      <!-- Chart Navbar part -->
      <div
        class="flex min-w-[850px] max-h-[52px] w-full items-center border-b-[1px] border-[#D9D9D9B2]"
      >
        <button
          @click="ekOdemeStore.ekOdemeButtonRef = 3"
          :class="
            ekOdemeStore.ekOdemeButtonRef === 3
              ? 'activeButton'
              : 'passiveButton'
          "
          class="h-[40px] ml-1 w-[220px] focus:outline-none"
        >
          <p
            :class="
              ekOdemeStore.ekOdemeButtonRef === 3
                ? 'text-[#252C32]'
                : 'text-[#6F6F6F]'
            "
          >
            Mesai İçi Birim Toplam Fiyat
          </p>
        </button>
        <button
          :class="
            ekOdemeStore.ekOdemeButtonRef === 4
              ? 'activeButton'
              : 'passiveButton'
          "
          @click="ekOdemeStore.ekOdemeButtonRef = 4"
          class="h-[40px] w-[242px] focus:outline-none"
        >
          <p
            :class="
              ekOdemeStore.ekOdemeButtonRef === 4
                ? 'text-[#252C32]'
                : 'text-[#6F6F6F]'
            "
          >
            Mesai Dışı Birim Toplam Fiyat
          </p>
        </button>
        <!-- Unvan Filter -->
        <Menu
          as="div"
          class="relative inline-block text-left ml-auto mr-[12px]"
        >
          <div>
            <MenuButton
              class="inline-flex gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-[#252525B2] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-[122px] min-h-[28px] max-h-[28px] items-center justify-between"
            >
              <!-- <p class="text-black/50 ml-2">Ay</p> -->
              <div class="flex items-center">
                <p class="mx-2">
                  {{ ekOdemeStore.monthFilterValue }}
                </p>
              </div>
              <img :src="arrowDownBlue" alt="arrow" class="mr-2 h-[6px]" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem
                  v-for="(month, index) in monthsOfTheYearInTurkish"
                  :key="index"
                  v-slot="{ active }"
                >
                  <div
                    @click="ekOdemeStore.monthFilterValue = month"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    class="cursor-pointer"
                  >
                    {{ month }}
                  </div>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <button class="mr-[30px]">
          <img :src="cloudDownload" alt="" />
        </button>
      </div>
      <!-- Percent Line Charts -->
      <div
        class="flex flex-col min-w-[385px] ml-auto bg-white border-[1px] border-[#5265811A]"
      >
        <button
          @click="
            {
              ekOdemeStore.isDetayGorButtonActive = false;
              ekOdemeStore.ekOdemeButtonRef = 1;
            }
          "
          class="relative ml-auto -mt-2 -mr-2"
        >
          <img :src="exitRed" alt="" />
        </button>
        <div class="flex items-center min-w-[335px] min-h-[94px]">
          <div class="flex flex-col ml-[16px]">
            <p>Mesai içi</p>
            <div class="flex">
              <img :src="linePercentegeBlue" alt="" />
              <p class="text-[#252525] font-medium ml-2">%42</p>
            </div>
          </div>
          <div class="flex flex-col ml-[52px] mr-[16px]">
            <p>Mesai dışı</p>
            <div class="flex">
              <img :src="linePercentegeBlue" class="w-[163px]" alt="" />
              <p class="text-[#252525] font-medium ml-2">%65</p>
            </div>
          </div>
        </div>
        <div class="flex items-center mb-[6px]">
          <p class="ml-auto mr-[18px] text-[#252525] text-s">
            *Toplam gelire göre oranları
          </p>
        </div>
      </div>
    </div>
    <!-- Chart -->

    <toplamBirimPuaniChart
      v-if="
        ekOdemeStore.ekOdemeButtonRef == 1 &&
        ekOdemeStore.isDetayGorButtonActive == false
      "
    />
    <toplamBirimPuanininGelireDonusuChart
      v-if="
        ekOdemeStore.ekOdemeButtonRef == 2 &&
        ekOdemeStore.isDetayGorButtonActive == false
      "
    />
    <mesaiIciBirimToplamFiyatChart
      v-if="
        ekOdemeStore.ekOdemeButtonRef == 3 &&
        ekOdemeStore.isDetayGorButtonActive == true
      "
    />
    <mesaiDisiBirimToplamFiyat
      v-if="
        ekOdemeStore.ekOdemeButtonRef == 4 &&
        ekOdemeStore.isDetayGorButtonActive == true
      "
    />
    <ekOdemeIslemleriTable />
  </div>
</template>

<script setup>
import leftArrowBlack from "@/modules/ekOdeme/assets/leftArrowBlack.svg";
import exitRed from "@/modules/ekOdeme/assets/exitRed.svg";
import arrowRightUpGreen from "@/modules/ekOdeme/assets/arrowRightUpGreen.svg";
import arrowDownLeftRed from "@/modules/ekOdeme/assets/arrowDownLeftRed.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import arrowDownBlue from "@/modules/klinikYonetim/assets/arrow-down-blue.svg";
import cloudDownload from "@/modules/ekOdeme/assets/cloudDownload.svg";
import linePercentegeBlue from "@/modules/ekOdeme/assets/linePercentegeBlue.svg";
import ekOdemeToplamBirimPuaniChart from "./ekOdemeToplamBirimPuaniChart.vue";
import toplamBirimPuaniChart from "./toplamBirimPuaniChart.vue";
import toplamBirimPuanininGelireDonusuChart from "./toplamBirimPuanininGelireDonusuChart.vue";
import mesaiIciBirimToplamFiyatChart from "./mesaiIciBirimToplamFiyatChart.vue";
import mesaiDisiBirimToplamFiyat from "./mesaiDisiBirimToplamFiyat.vue";
import ekOdemeIslemleriTable from "./ekOdemeIslemleriTable.vue";
import { useRouter } from "vue-router";
import { useEkOdemeStore } from "../store/ekOdemeStore.js";
const ekOdemeStore = useEkOdemeStore();
const router = useRouter();
import { ref } from "vue";
const highlightedDates = ref();
const date = ref();
// Filter Months Ref
const monthsOfTheYearInTurkish = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];
// const disabledDates = computed(() => {
//   const today = new Date();

//   const tomorrow = new Date(today);
//   tomorrow.setDate(tomorrow.getDate() + 1);

//   const afterTomorrow = new Date(tomorrow);
//   afterTomorrow.setDate(tomorrow.getDate() + 1);

//   return [tomorrow, afterTomorrow];
// });

const routerToEkOdeme = () => {
  console.log("routerToOdemeIslemleri");
  router.push("/ek-odeme");
};
</script>

<style scoped>
.activeButton {
  border: 2px #c6dcf5 solid;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.passiveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(198, 220, 245, 0.4);
  border-radius: 5px;
}
</style>