<template>
  <div class="flex  w-full flex-col overflow-x-auto">
    <div class="mt-4 flex w-full">
      <div
        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
        v-if="store.studentAssistantHideItems[0]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Hasta Telefon</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[1]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Hasta Dosya No.</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[2]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Dönem</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[3]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Grup</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[4]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Öğrenci Numarası</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[5]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Öğrenci Ad/Soyad</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[6]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Doktor Endikasyon</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[7]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Endikasyon Tarihi</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[8]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Açıklama</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[9]"

        class="ml-[10px] flex h-[55px] w-[200px] min-w-[200px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">Bölüm/Sabit/Total</p>
      </div>
      <div
      v-if="store.studentAssistantHideItems[10]"

        class="ml-[10px] flex h-[55px] w-[300px] min-w-[300px] items-center justify-center rounded-[5px] bg-[#F1F1F1]"
      >
        <p class="text-xl font-medium text-[#2C3D8F]">
          Total/Bölümlü Hareketli/Sabit
        </p>
      </div>
      <!-- <div class="w-[200px]">

      </div> -->
    </div>
    <div class="mt-4 flex w-full flex-col">
      <studentAssitantTablePatientElement />
      <studentAssitantTablePatientElement />
      <studentAssitantTablePatientElement />
    </div>
  </div>
</template>

<script setup>
import studentAssitantTablePatientElement from "./studentAssitantTablePatientElement.vue";
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();
</script>