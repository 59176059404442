import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import axios from "axios";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useSearchPatientApplications = defineStore({
  id: "getPatientApplication",
  state: () => ({}),
  actions: {
    async searchPatientApplications(id: string) {
      const personelStoreData = personelStore();
      const medulaStore = useMedula();
      await axios
        .get(
          `${clinicDomain}/patient-applications/patient?patient_id=${id}&page_size=50&page_id=1`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data, "eplikeyşıns");
            medulaStore.hastaBasvurulari = response.data;
          }
        })
        .catch((err) => {
          console.log(
            "useSearchPatientApplications.searchPatientApplications catch error : ",
            err
          );
          medulaStore.hastaBasvurulari = {
            applications: {
              data: [],
            },
            count: 0,
          };
        });
    },
  },
});
