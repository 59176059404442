import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useCreateProvisionSettings = defineStore({
  id: "useCreateProvisionSettings",
  state: () => ({
    createProvisionSettingResponse: null,
  }),
  actions: {
    async createProvisionSettings(
      provisionType,
      exceptionalType,
      treatmentType,
      followType,
      treatmentKind
    ) {
      const personelStoreData = personelStore();
      await axios
        .post(
          `${adminDomain}/provision-settings/create`,
          {
            provizyon_tipi: { id: "-", text: "-" },
            istisnai_hal: { id: "-", text: "-" },
            tedavi_tipi: { id: "-", text: "-" },
            takip_tipi: { id: "-", text: "-" },
            tedavi_turu: { id: "-", text: "-" },
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("createProvisionResponse", response);
          this.createProvisionSettingResponse = response.data;
        })
        .catch((error) => {
          console.log("createProvisionError", error);
        });
    },
  },
});
