import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useDeleteProvisionSettings = defineStore({
  id: "useDeleteProvisionSettings",
  state: () => ({}),
  actions: {
    async deleteProvisionSettings() {
      const personelStoreData = personelStore();
      await axios
        .delete(`${adminDomain}/provision-settings`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("deleteProvisionResponse", response.data);
        })
        .catch((error) => {
          console.log("deletetProvisionError", error);
        });
    },
  },
});
