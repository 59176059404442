<template>
  <div
    class="border-2 border-[#D9D9D9] bg-white rounded-[20px] h-[96%] w-[96%] mx-auto"
  >
    <div class="flex mt-10">
      <img :src="leftArrowBlack" alt="arrow" class="ml-3" />
      <p class="text-[#454545] font-inter text-4xl font-semibold ml-3">
        {{ treatmentInfoRef.treatmentTitle }}
      </p>
    </div>
    <!-- Dis flow Start -->
    <div v-if="store.bigToothInfoModalInModalFlow == 0">
      <div class="flex flex-wrap items-center justify-center">
        <button
          class="treatmentOptionsCard flex items-center justify-center w-[110px] h-[124px] p-2 my-10 mx-3"
          v-for="(item, index) in treatmentOptions"
          :key="index"
          @click="setFlow(1, item, 0)"
        >
          <p class="font-inter text-[#263445] font-medium text-xl">
            {{ item }}
          </p>
        </button>
      </div>

      <!-- Treat -->
      <p
        class="text-[#454545] text-2xl font-semibold w-[90%] mx-auto font-inter"
      >
        Treat
      </p>
      <p
        v-if="!store.bigToothInfoModalTreats[0]"
        class="ml-3 font-light text-xl font-inter"
      >
        Şu anda bekleyen tedavi yok
      </p>
      <div v-if="store.bigToothInfoModalTreats[0]">
        <div
          v-for="(item, index) in store.bigToothInfoModalTreats"
          :key="index"
          class="bg-[#C6DCF51A] w-[90%] mx-auto h-[55px] flex items-center"
        >
          <p class="ml-3 font-inter">
            {{
              `${item.treatmentTitle} ,${item.treatmentTreat} ,${item.treatmentDentinEnamel} ,${item.treatmentCavitationYesNo} ,${item.treatmentCavitation}`
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- Tooth flow 2  -->
    <div v-if="store.bigToothInfoModalInModalFlow >= 1">
      <div class="flex flex-wrap items-center justify-center">
        <button
          class="toothTreatmentOptionsCard flex items-center justify-center w-[200px] mt-3 text-sm h-[35px] p-2 mx-3"
          v-for="(item, index) in toothTreatmentOptions"
          :key="index"
          :class="
            store.bigToothInfoModalInModalFlow > 1 &&
            item == treatmentInfoRef.treatmentTreat
              ? 'activeToothTreatmentOptionsCard'
              : 'toothTreatmentOptionsCard'
          "
          @click="setFlow(2, item, 1)"
        >
          <p class="font-inter text-[#263445]">
            {{ item }}
          </p>
        </button>
      </div>

      <!-- Treat -->
      <div v-if="store.bigToothInfoModalInModalFlow >= 2" class="mt-[51px]">
        <p
          class="text-[#454545] text-2xl font-semibold w-[90%] mx-auto font-inter"
        >
          Treat
        </p>
        <div class="flex flex-wrap items-center justify-center">
          <button
            :class="
              store.bigToothInfoModalInModalFlow > 2 &&
              item == treatmentInfoRef.treatmentDentinEnamel
                ? 'activeToothTreatmentOptionsCard'
                : 'toothTreatmentOptionsCard'
            "
            class="flex items-center justify-center w-[200px] mt-3 text-sm h-[35px] p-2 mx-3"
            v-for="(item, index) in toothTreat"
            :key="index"
            @click="setFlow(3, item, 2)"
          >
            <p class="font-inter text-[#263445]">
              {{ item }}
            </p>
          </button>
        </div>
      </div>
      <div v-if="store.bigToothInfoModalInModalFlow >= 3" class="mt-[51px]">
        <p
          class="text-[#454545] text-2xl font-semibold w-[90%] mx-auto font-inter"
        >
          Cavitation
        </p>
        <div class="flex flex-wrap items-center justify-center">
          <button
            :class="
              store.bigToothInfoModalInModalFlow > 3 &&
              item == treatmentInfoRef.treatmentCavitationYesNo
                ? 'activeToothTreatmentOptionsCard'
                : 'toothTreatmentOptionsCard'
            "
            class="flex items-center justify-center w-[200px] mt-3 text-sm h-[35px] p-2 mx-3"
            v-for="(item, index) in ['Yes', 'No']"
            :key="index"
            @click="setFlow(4, item, 3)"
          >
            <p class="font-inter text-[#263445]">
              {{ item }}
            </p>
          </button>
        </div>
      </div>
      <div v-if="store.bigToothInfoModalInModalFlow >= 4" class="mt-[51px]">
        <p
          class="text-[#454545] text-2xl font-semibold w-[90%] mx-auto font-inter"
        >
          Cavitation
        </p>
        <div class="flex flex-wrap items-center justify-center">
          <button
            :class="
              store.bigToothInfoModalInModalFlow > 4 &&
              item == treatmentInfoRef.treatmentCavitation
                ? 'activeToothTreatmentOptionsCard'
                : 'toothTreatmentOptionsCard'
            "
            class="flex items-center justify-center w-[110px] mt-3 text-sm h-[35px] p-2 mx-3"
            v-for="(item, index) in toothCavitation"
            :key="index"
            @click="setFlow(5, item, 4)"
          >
            <p class="font-inter text-[#263445]">
              {{ item }}
            </p>
          </button>
        </div>
      </div>
      <div v-if="store.bigToothInfoModalInModalFlow >= 5" class="mt-[51px]">
        <div class="border-b-[1px] border-[#C6DCF5] w-[85%] mx-auto"></div>
        <div class="flex flex-wrap items-center justify-center">
          <button
            :class="
              store.bigToothInfoModalInModalFlow > 5 &&
              item == treatmentInfoRef.treatmentCavitation
                ? 'activeToothTreatmentOptionsCard'
                : 'toothTreatmentOptionsCard'
            "
            class="flex items-center justify-center w-[155px] mt-3 text-sm h-[35px] p-2 mx-3"
            v-for="(item, index) in ['Treat', 'Monitor', 'Save']"
            :key="index"
            @click="
              () => {
                setFlow(6, item, 5);
                store.bigToothInfoModalInModalFlow = 0;
              }
            "
          >
            <p class="font-inter text-[#263445]">
              {{ item }}
            </p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import leftArrowBlack from "../../assets/leftArrowBlack.svg";
import { useDentalChart } from "../../dentalChartMain/dentalChartStore/dentalChartStore";
import { ref } from "vue";
const store = useDentalChart();
const treatmentOptions = ["Kanal Tedavisi", "Kayıp Diş", "Çürük", "Restoratif"];
const toothTreatmentOptions = [
  "Amalgam Dolgu (İki Yüzlü)",
  "Kuafaj (Dolgu Hariç)",
  "Amalgam Dolgu (Üç Yüzlü)",
  "İnley Dolgu (Oklüzal)",
];
const toothTreat = ["Dentin", "Enamel"];
const toothCavitation = ["C1", "C2", "C3", "C4"];
const setFlow = (flowValue, itemValue, refName) => {
  if (store.bigToothInfoModalInModalFlow < flowValue) {
    store.bigToothInfoModalInModalFlow = flowValue;
  }
  switch (refName) {
    case 0:
      treatmentInfoRef.treatmentTitle = itemValue;
      break;
    case 1:
      treatmentInfoRef.treatmentTreat = itemValue;
      break;
    case 2:
      treatmentInfoRef.treatmentDentinEnamel = itemValue;
      break;
    case 3:
      treatmentInfoRef.treatmentCavitationYesNo = itemValue;
      break;
    case 4:
      treatmentInfoRef.treatmentCavitation = itemValue;
      break;
    default:
      break;
  }
  if (refName == 5) {
    store.bigToothInfoModalTreats.push(treatmentInfoRef);
    treatmentInfoRef = {
      treatmentTitle: "",
      treatmentTreat: "",
      treatmentDentinEnamel: "",
      treatmentCavitationYesNo: "",
      treatmentCavitation: "",
    };
    store.bigToothActiveArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  }
};
let treatmentInfoRef = {
  treatmentTitle: "",
  treatmentTreat: "",
  treatmentDentinEnamel: "",
  treatmentCavitationYesNo: "",
  treatmentCavitation: "",
};
</script>
<style scoped>
.treatmentOptionsCard {
  border-radius: 10px;
  border: 1px solid #c6dcf5;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: -4px 6px 4px 0px rgba(198, 220, 245, 0.25);
}
.treatmentOptionsCard:hover {
  border-radius: 10px;
  border: 2px solid #4198f7;
  background: rgba(198, 220, 245, 0.2);
  box-shadow: -4px 6px 4px 0px rgba(198, 220, 245, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.toothTreatmentOptionsCard {
  border-radius: 10px;
  border: 1px solid #c6dcf5;
  background: #fff;
}
.toothTreatmentOptionsCard:hover {
  border-radius: 10px;
  border: 2px solid #4198f7;
  background: rgba(198, 220, 245, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.activeToothTreatmentOptionsCard {
  border-radius: 10px;
  border: 2px solid #4198f7;
  background: rgba(198, 220, 245, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>