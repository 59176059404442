<template>
  <div class="overlay"></div>
  <div
    class="text-sm z-50 rounded w-5/6 h-5/6 bg-white shadow-md absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
  >
    <div class="flex items-center justify-between mb-3 border-b">
      <div class="text-white w-[86px]"></div>
      <h2 class="font-medium text-[#40464c]">TEDAVİ PLANLARI</h2>
      <button
        @click="closeExaminationPlanModal"
        class="text-white bg-[#F94144] px-6 py-2 font-semibold"
      >
        Kapat
      </button>
    </div>

    <div class="flex items-center px-10 justify-between mb-3 border-b">
      <div class="flex items-center w-[350px] gap-1">
        <img :src="profileCircle" class="w-10" alt="" />
        <span>Selen Çiçek BOZ</span>
        <span class="text-[#a0a3a6]">(24 yıl 6 ay 14 gün)</span>
      </div>
      <div class="flex p-2 bg-[#f5f5f5]">
        <button
          class="w-[200px] h-10 flex items-center justify-center"
          :class="
            planStatusTab === 'activePlans'
              ? 'bg-white text-[#40464C]'
              : 'bg-[#f5f5f5] text-[#40464C]'
          "
          @click="planStatusTab = 'activePlans'"
        >
          Aktif
        </button>
        <button
          class="w-[200px] h-10 flex items-center justify-center"
          :class="
            planStatusTab === 'remainingPlans'
              ? 'bg-white text-[#40464C]'
              : 'bg-[#f5f5f5] text-[#40464C]'
          "
          @click="planStatusTab = 'remainingPlans'"
        >
          Devam eden
        </button>
        <button
          class="w-[200px] h-10 flex items-center justify-center"
          :class="
            planStatusTab === 'completedPlans'
              ? 'bg-white text-[#40464C]'
              : 'bg-[#f5f5f5] text-[#40464C]'
          "
          @click="planStatusTab = 'completedPlans'"
        >
          Tamamlanmış
        </button>
      </div>
      <div
        class="flex shadow gap-2 border w-[350px] bg-white pl-[10px] rounded-lg text-black"
      >
        <img :src="searchLogo" alt="search" />
        <div class="w-10/12">
          <input
            type="text"
            class="w-full outline-none py-2"
            placeholder="Hasta Adı, Tc Numarası veya Kabul No İle Ara"
          />
        </div>
      </div>
    </div>

    <div class="border-b flex gap-8 items-center px-4">
      <button
        v-for="(plan, index) in dentalChartPlanStore?.staticExaminationPlans?.[
          planStatusTab
        ]"
        class="pb-1 pt-[10px] px-1 border-b-4"
        :class="
          activePlanTab === index
            ? 'bg-white text-[#277dd2] border-[#277dd2]'
            : 'text-[#a0a3a6] border-transparent'
        "
        @click="activePlanTab = index"
      >
        {{ plan.planName }}
      </button>
    </div>

    <div class="flex gap-20 px-8 py-2">
      <dentalChartTeethComponent :modal="true" />
      <div
        class="flex flex-col gap-3 flex-grow h-[450px] overflow-auto"
        v-if="
          dentalChartPlanStore?.staticExaminationPlans?.[planStatusTab]?.[
            activePlanTab
          ]?.planStages
        "
      >
        <div
          v-for="(planStage, index) in dentalChartPlanStore?.staticExaminationPlans?.[
            planStatusTab
          ]?.[activePlanTab]?.planStages"
          class="flex flex-col gap-1"
        >
          <button
            class="flex items-center divide-x-2 divide-[#277dd2] gap-3 py-1 px-2 bg-[#E6EFF5] rounded border-[1px] border-[#277dd2]"
            @click="
              openedStage === index
                ? (openedStage = null)
                : (openedStage = index)
            "
          >
            <img :src="plus" class="w-4" />

            <div class="flex items-center gap-1 pl-1">
              <div class="flex flex-col gap-1 items-start">
                <span class="font-medium">{{ planStage.stageName }}</span>
                <button
                  @click.stop="makeAppointment"
                  class="flex gap-1 items-center"
                >
                  <img :src="plus" class="w-[10px]" />
                  <span class="text-[#939ba1]">Randevu oluştur</span>
                </button>
              </div>
            </div>
          </button>

          <div v-if="index === openedStage">
            <table class="min-w-full bg-white cursor-pointer">
              <thead>
                <tr class="w-full text-[10px] sm:text-xs border-y">
                  <th
                    v-for="(column, index) in columns"
                    :key="index"
                    class="max-w-12 truncate sm:max-w-fit py-2 px-2 xl:px-6 text-left font-medium whitespace-nowrap"
                  >
                    {{ column.name }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-[10px] sm:text-xs">
                <!-- v-for="(process, index) in planStage?.stageProcessesInfos" -->
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="border-y border-[#d9d9d9] hover:bg-gray-100"
                >
                  <td
                    v-for="(column, colIndex) in columns"
                    :key="colIndex"
                    class="py-2 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
                  >
                    {{ row[column.key] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col gap-3 flex-grow justify-center items-center"
        v-else
      >
        Plan aşamaları bulunamadı.
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  closeExaminationPlanModal: Function,
});
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import DentalChartTeethComponent from "@/modules/dentalChart/dentalChartMain/components/dentalChartTeethComponent.vue";
import plus from "@/modules/protez/assets/plus.svg";
import { useDentalChartPlanStore } from "@/modules/doctorScreen/store/dentalChartPlanStore";
const dentalChartPlanStore = useDentalChartPlanStore();
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import { ref } from "vue";

const planStatusTab = ref("activePlans");
const activePlanTab = ref(0);
const openedStage = ref(0);
const makeAppointment = () => {
  console.log("makeAppointment");
};

const columns = [
  { key: "process", name: "İşlem Adı/Kodu" },
  { key: "teeth", name: "Diş Kodu/Konum" },
  { key: "count", name: "Adet" },
  { key: "price", name: "Birim Fiyat" },
  { key: "totalPrice", name: "Toplam Fiyat" },
];
const rows = [
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },

  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
  {
    process: "Diş Hekim Muayenesi",
    teeth: "1, 3, 5",
    count: "2",
    price: "10",
    totalPrice: "20",
  },
];
</script>
