import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useGetStaffList = defineStore({
  id: "useGetStaffList",
  state: () => ({
    getListStaffResponse: null,
  }),
  actions: {
    async getStaffList() {
      try {
        const personelStoreData = personelStore();
        const response = await axios.get(
          `${adminDomain}/staffs?page_id=1&page_size=50`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("getStaffList", response.data);
        this.getListStaffResponse = response.data;
        return response.data.map((item, index) => {
          return {
            ...item,
            id: item.personel_kodu,
            text: item.ad + " " + _.startCase(_.toLower(item.soyadi)),
          };
        });
      } catch (error) {
        console.error("Error in getStaffList:", error);
      }
    },
  },
});
