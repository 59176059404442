<template>
  <div v-if="pastActivitiesStore.detailScreen" class="flex gap-20">
    <button
      class="pb-1"
      :class="
        pastActivitiesStore.examinationDetailsNav === 1
          ? 'font-medium text-[#277dd2] border-b-2 border-b-[#277dd2]'
          : 'text-[#8492a5]'
      "
      @click="pastActivitiesStore.examinationDetailsNav = 1"
    >
      Hizmetler
    </button>
    <button
      class="pb-1"
      :class="
        pastActivitiesStore.examinationDetailsNav === 2
          ? 'font-medium text-[#277dd2] border-b-2 border-b-[#277dd2]'
          : 'text-[#8492a5]'
      "
      @click="pastActivitiesStore.examinationDetailsNav = 2"
    >
      Radyoloji Bilgileri
    </button>
    <button
      class="pb-1"
      :class="
        pastActivitiesStore.examinationDetailsNav === 3
          ? 'font-medium text-[#277dd2] border-b-2 border-b-[#277dd2]'
          : 'text-[#8492a5]'
      "
      @click="pastActivitiesStore.examinationDetailsNav = 3"
    >
      Reçete
    </button>
    <button
      class="pb-1"
      :class="
        pastActivitiesStore.examinationDetailsNav === 4
          ? 'font-medium text-[#277dd2] border-b-2 border-b-[#277dd2]'
          : 'text-[#8492a5]'
      "
      @click="pastActivitiesStore.examinationDetailsNav = 4"
    >
      Anamnez
    </button>
  </div>
</template>

<script setup>
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
const pastActivitiesStore = usePastActivitiesStore();
</script>
