<template>
  <div class="flex flex-col h-screen w-full overflow-hidden">
    <!-- Birim Ekle PopUp -->
    <radiologyBirimEklePopUp />
    <radiologyCihazEklePopUp />
    <div
      class="bg-[#277DD2] w-full min-h-[58px] justify-between flex items-center relative"
    >
      <button
        class="bg-white rounded-[5px] flex gap-3 items-center px-[10px] py-[3px] ml-3"
      >
        <img :src="arrowLeftBlue" alt="" class="h-3" />
        <p class="text-[#277DD2]">######</p>
      </button>
      <p class="text-white font-semibold text-2xl">RADYOLOJİ HASTA LİSTESİ</p>
      <div></div>
    </div>
    <div class="flex w-full h-full">
      <personelSideBar />
      <div class="flex flex-col w-full h-full">
        <radiologySlider class="mt-5 ml-3" />
        <!-- Birim Tanimlari -->
        <div
          v-if="radiologyRontgenStore.radiologyRontgenSliderButton == 1"
          class="flex"
        >
          <radiologyBirimTanimlariList />
          <radiologyBirimTanimlariInfo />
          <div class="flex flex-col ml-[22px]">
            <radiologyHekimListesiSlider />
            <radiologyHekimListesi
              v-if="radiologyRontgenStore.radiologyHekimListesiSlider == 1"
            />
          </div>
        </div>
        <!-- Cihaz Tanimlari -->
        <div
          v-if="radiologyRontgenStore.radiologyRontgenSliderButton == 2"
          class="flex"
        >
          <radiologyCihazTanimlariList />
          <radiologyCihazTanimlariInfo />
        </div>
        <!-- Entegrasyon Tanımları -->
        <div
          v-if="radiologyRontgenStore.radiologyRontgenSliderButton == 3"
          class="flex"
        >
          <radiologyEntegrasyonTanimlariList />
          <radiologyEntegrasyonTanimlariInfo />
        </div>
        <!-- Entegrasyon Tanımları -->
        <div
          v-if="radiologyRontgenStore.radiologyRontgenSliderButton == 4"
          class="flex"
        >
          <radiologyRaporSablonList />
          <radiologyRaporSablonInfo />
        </div>
        <div
          v-if="radiologyRontgenStore.radiologyRontgenSliderButton == 5"
          class="flex"
        >
          <radiologyIstemTanimlariList />
          <radiologyIstemTanimlariInfo />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import arrowLeftBlue from "@/modules/medula/provision/assets/arrowLeftBlue.svg";
import personelSideBar from "@/modules/personel/components/personalSideBar.vue";
import radiologySlider from "@/modules/radiology/radiologyRontgen/components/radiologySlider.vue";
import radiologyBirimTanimlariInfo from "@/modules/radiology/radiologyRontgen/components/radiologyBirimTanimlariInfo.vue";
import radiologyBirimTanimlariList from "@/modules/radiology/radiologyRontgen/components/radiologyBirimTanimlariList.vue";
import radiologyHekimListesiSlider from "@/modules/radiology/radiologyRontgen/components/radiologyHekimListesiSlider.vue";
import radiologyBirimEklePopUp from "@/modules/radiology/radiologyRontgen/components/radiologyBirimEklePopUp.vue";
import radiologyHekimListesi from "@/modules/radiology/radiologyRontgen/components/radiologyHekimListesi.vue";
import radiologyCihazTanimlariList from "@/modules/radiology/radiologyRontgen/components/radiologyCihazTanimlariList.vue";
import radiologyCihazTanimlariInfo from "@/modules/radiology/radiologyRontgen/components/radiologyCihazTanimlariInfo.vue";
import radiologyCihazEklePopUp from "@/modules/radiology/radiologyRontgen/components/radiologyCihazEklePopUp.vue";
import radiologyEntegrasyonTanimlariList from "@/modules/radiology/radiologyRontgen/components/radiologyEntegrasyonTanimlariList.vue";
import radiologyEntegrasyonTanimlariInfo from "@/modules/radiology/radiologyRontgen/components/radiologyEntegrasyonTanimlariInfo.vue";
import radiologyRaporSablonList from "@/modules/radiology/radiologyRontgen/components/radiologyRaporSablonList.vue";
import radiologyRaporSablonInfo from "@/modules/radiology/radiologyRontgen/components/radiologyRaporSablonInfo.vue";
import radiologyIstemTanimlariList from "@/modules/radiology/radiologyRontgen/components/radiologyIstemTanimlariList.vue";
import radiologyIstemTanimlariInfo from "@/modules/radiology/radiologyRontgen/components/radiologyIstemTanimlariInfo.vue";

import { useRadiologyRontgenStore } from "@/modules/radiology/radiologyRontgen/store/radiologyRontgenStore";

const radiologyRontgenStore = useRadiologyRontgenStore();
</script>
<style scoped>
.ce-block__content,
.ce-toolbar__content {
  max-width: 1000px !important; /* example value, adjust for your own use case */
}
</style>