import { defineStore } from "pinia";
export const doctorCalendarStore = defineStore("doctorCalendarStore", {
  id: "doctorCalendarStore",
  state: () => ({
    appointmentType: "",
    doctorID: "",
    updatingSetting: null,
    selectedDays: [
      //   {
      //     date: new Date(),
      //     workingHours: [
      //       {
      //         start: "09",
      //         end: "11",
      //       },
      //       {
      //         start: "12",
      //         end: "14",
      //       },
      //       {
      //         start: "15",
      //         end: "16",
      //       },
      //     ],
      //     workingMinutes: [
      //       {
      //         start: "15",
      //         end: "30",
      //       },
      //       {
      //         start: "00",
      //         end: "30",
      //       },
      //       {
      //         start: "45",
      //         end: "15",
      //       },
      //     ],
      //     overbookAllowed: true,
      //     overbookLimit: 4,
      //     examinationDuration: 30,
      //   },
      //   {
      //     date: new Date(),
      //     workingHours: [
      //       {
      //         start: "09",
      //         end: "10",
      //       },
      //       {
      //         start: "11",
      //         end: "13",
      //       },
      //       {
      //         start: "14",
      //         end: "15",
      //       },
      //     ],
      //     workingMinutes: [
      //       {
      //         start: "15",
      //         end: "30",
      //       },
      //       {
      //         start: "00",
      //         end: "30",
      //       },
      //       {
      //         start: "45",
      //         end: "15",
      //       },
      //     ],
      //     overbookAllowed: true,
      //     overbookLimit: 4,
      //     examinationDuration: 30,
      //   },
    ],
  }),
  getters: {},
  actions: {},
});
