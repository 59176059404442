<template>
  <div class="mt-2 mb-4 relative">
    <div class="flex gap-8 pt-2">
      <div class="examinationStatusSelectComponent">
        <Select2
          class="w-[300px] border-[#D9D9D9] mt-[6px]"
          :options="[
            'Tümü',
            'Sırada',
            'Takipte',
            'Öncelikli',
            'Muayene Tamamlandı',
            'Kontrolde',
          ]"
          placeholder="Muayene Durumu"
        />
      </div>
      <button
        @click="filtersOpen = !filtersOpen"
        class="rounded-full border px-6 py-1 flex gap-2 items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
      >
        <img :src="filterIcon" alt="filter" />
        <p>Filtrele</p>
      </button>
      <form
        class="flex gap-3 border bg-white py-1 pl-2 rounded-full text-black w-[350px] focus-within:outline-1 focus-within:outline-double focus-within:outline-[#277dd2]"
      >
        <img class="w-6" :src="searchLogo" alt="search" />
        <input
          type="text"
          class="w-10/12 outline-none py-2"
          placeholder="Hasta ya da Hekim ara"
        />
      </form>
    </div>
    <div
      v-if="filtersOpen"
      class="bg-[#fbfbfb] absolute w-full py-3 px-8 flex flex-col gap-4"
    >
      <div class="flex gap-2 mt-2">
        <Combobox
          v-model="
            RegisteredPatientListStore.patientApplicationsFilter.basvuruDurumu
          "
        >
          <div class="relative w-[250px]">
            <div
              class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
            >
              <ComboboxInput
                placeholder="Başvuru Durumu"
                class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                :displayValue="(status) => status.text"
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
              >
                <div
                  v-if="
                    filteredApplicationStatuses.length === 0 && query !== ''
                  "
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Sonuç bulunamadı
                </div>
                <ComboboxOption
                  as="template"
                  key="33"
                  value="{ id: '', text: 'Tümü' }"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">Tümü</span>
                  </li>
                </ComboboxOption>
                <ComboboxOption
                  v-for="status in filteredApplicationStatuses"
                  as="template"
                  :key="status.id"
                  :value="status"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">
                      {{ status.text }}
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>

        <Combobox
          v-model="
            RegisteredPatientListStore.patientApplicationsFilter.provizyonTipi
          "
        >
          <div class="relative w-[250px]">
            <div
              class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
            >
              <ComboboxInput
                placeholder="Provizyon Tipi"
                class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                :displayValue="(provisionType) => provisionType.text"
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
              >
                <div
                  v-if="filteredProvisionTypes.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Sonuç bulunamadı
                </div>
                <ComboboxOption
                  as="template"
                  key="33"
                  value="{ id: '', text: 'Tümü' }"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">Tümü</span>
                  </li>
                </ComboboxOption>
                <ComboboxOption
                  v-for="provisionType in filteredProvisionTypes"
                  as="template"
                  :key="provisionType.id"
                  :value="provisionType"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">
                      {{ provisionType.text }}
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>

        <Combobox
          v-model="
            RegisteredPatientListStore.patientApplicationsFilter.birimAdi
          "
        >
          <div class="relative w-[250px]">
            <div
              class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
            >
              <ComboboxInput
                placeholder="Birim"
                class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                :displayValue="(unit) => unit.text"
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
              >
                <div
                  v-if="filteredUnits.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Sonuç bulunamadı
                </div>
                <ComboboxOption
                  as="template"
                  key="33"
                  value="{ id: '', text: 'Tümü' }"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">Tümü</span>
                  </li>
                </ComboboxOption>
                <ComboboxOption
                  v-for="unit in filteredUnits"
                  as="template"
                  :key="unit.id"
                  :value="unit"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">
                      {{ unit.text }}
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>

        <Combobox
          v-model="
            RegisteredPatientListStore.patientApplicationsFilter.kurumAdi
          "
        >
          <div class="relative w-[250px]">
            <div
              class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
            >
              <ComboboxInput
                placeholder="Kurum adı"
                class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                :displayValue="
                  (assuranceType) =>
                    assuranceType.text === '' ? 'Tümü' : assuranceType.text
                "
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
              >
                <div
                  v-if="filteredAssuranceTypes.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Sonuç bulunamadı
                </div>
                <ComboboxOption
                  as="template"
                  key="33"
                  value="{ id: '', text: '' }"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">Tümü</span>
                  </li>
                </ComboboxOption>
                <ComboboxOption
                  v-for="assuranceType in filteredAssuranceTypes"
                  as="template"
                  :key="assuranceType.id"
                  :value="assuranceType"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">
                      {{ assuranceType.text }}
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>

        <Combobox
          v-model="
            RegisteredPatientListStore.patientApplicationsFilter.sigortaTipi
          "
        >
          <div class="relative w-[250px]">
            <div
              class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
            >
              <ComboboxInput
                placeholder="Sigortalı Tipi"
                class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                :displayValue="
                  (insuranceType) =>
                    insuranceType.text === '' ? 'Tümü' : insuranceType.text
                "
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
              >
                <div
                  v-if="filteredInsuranceTypes.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Sonuç bulunamadı
                </div>
                <ComboboxOption
                  as="template"
                  key="33"
                  value="{ id: '', text: '' }"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">Tümü</span>
                  </li>
                </ComboboxOption>
                <ComboboxOption
                  v-for="insuranceType in filteredInsuranceTypes"
                  as="template"
                  :key="insuranceType.id"
                  :value="insuranceType"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">
                      {{ insuranceType.text }}
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>
      </div>

      <button
        @click="handleSaveFilters"
        class="w-fit mt-3 ml-auto focus:outline-double focus:outline-1 focus:outline-[#277dd2] bg-white text-[#277DD2] border-[1px] border-[#277DD2] py-1 px-6 rounded-md"
      >
        Kaydet
      </button>
    </div>
  </div>
</template>

<script setup>
import Select2 from "vue3-select2-component";
import menuIcon from "@/modules/personel/assets/menu_icon.svg";
import profileImg from "@/modules/personel/assets/profile-img.svg";
import filterIcon from "@/modules/asistanEkrani/registeredPatientList/assets/filterIcon.svg";
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions.ts";
const personelStoreAuthActions = personelAuthActions();
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
const RegisteredPatientListStore = useRegisteredPatientListStore();
import { personelStore } from "@/store/modules/administratorManagementStore/personel.ts";
import _ from "lodash";
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import {
  optionTypes,
  assuranceTypes,
  insuranceTypes,
  applicationStatuses,
} from "@/modules/medula/provision/components/dropDownTypes";
import { registeredPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/getPatientApplicationRegistrationList";
import appointmentListDateComponent from "@/modules/asistanEkrani/components/appointmentListDateComponent.vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
let query = ref("");
const router = useRouter();
const arrowRef = ref(false);
const filtersOpen = ref(false);
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";

const unitListStore = useGetUnitsList();
const personelStoreData = personelStore();
const RegisteredPatientApplicationStore = registeredPatientApplicationStore();

onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});

// basvuruDurumlari

let filteredApplicationStatuses = computed(() =>
  query.value === ""
    ? applicationStatuses.value
    : applicationStatuses.value.filter((status) =>
        status.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// provizyonTipi

let filteredProvisionTypes = computed(() =>
  query.value === ""
    ? optionTypes.value
    : optionTypes.value.filter((provision) =>
        provision.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// birim
let filteredUnits = computed(() =>
  query.value === ""
    ? unitListStore.getListUnitsResponse
    : unitListStore.getListUnitsResponse.filter((unit) =>
        unit.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// devredilenkurum
let filteredAssuranceTypes = computed(() =>
  query.value === ""
    ? assuranceTypes.value
    : assuranceTypes.value.filter((assuranceType) =>
        assuranceType.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// sigortalı tipi
let filteredInsuranceTypes = computed(() =>
  query.value === ""
    ? insuranceTypes.value
    : insuranceTypes.value.filter((insuranceType) =>
        insuranceType.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

const handleSaveFilters = () => {
  //   RegisteredPatientApplicationStore.getRegisteredPatientApplications(
  //     1,
  //     8,
  //     RegisteredPatientListStore.patientApplicationsFilter.basvuruDurumu,
  //     RegisteredPatientListStore.patientApplicationsFilter.provizyonTipi,
  //     RegisteredPatientListStore.patientApplicationsFilter.birimAdi,
  //     RegisteredPatientListStore.patientApplicationsFilter.kurumAdi,
  //     RegisteredPatientListStore.patientApplicationsFilter.sigortaTipi
  //   );
  //   filtersOpen.value = false;
  //   RegisteredPatientListStore.currentPage = 1;
};
</script>
