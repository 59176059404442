import { defineStore } from "pinia";
import { SearchSingularPatientResponse } from "@/types/operations/patient/searchPatientResponse";
import { SearchPatientListResponse } from "@/types/operations/patient/searchPatientListResponse";
import {
  SearchPatientRequest,
  searchPatientRequest,
} from "@/types/operations/patient/searchPatientRequest";
import { useUpdatePatientStore } from "@/store/modules/operationsStore/patient/useUpdatePatientStore";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { personelStore } from "../../administratorManagementStore/personel";
import { globalStore } from "../../globalStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { patientStore } from "@/store/modules/administratorManagementStore/patient";
export const UseSearchPatientStore = defineStore({
  id: "searchPatient",
  state: () => ({
    searchPatientRequest: searchPatientRequest as SearchPatientRequest | null,
    searchPatientSingularResponse: null as SearchSingularPatientResponse | null,
    searchPatientListResponse: null as SearchPatientListResponse | null,
  }),
  actions: {
    async searchPatient(searchPatientRequest: SearchPatientRequest) {
      try {
        const personelStoreData = personelStore();
        const patientStoreData = patientStore();
        let token = localStorage.getItem("patientStoreData");
        let accessToken = token ? JSON.parse(token).accessToken : "";
        const response = await axios.get(
          `${clinicDomain}/patients/search?tc_identity_number=${searchPatientRequest}&page_id=1&page_size=5`,

          {
            headers: {
              // Authorization:
              //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQ2OWJlZjQ4LWM2MTgtNGFjZS1hNmMzLTM5ODhhNDEyYWEzMSIsInVzZXJJRCI6ImM1NTlmMzMzLWQ1ZmItNDM5MS05ZDkwLWJjYTEzY2Q2Y2IyNiIsInVzZXJuYW1lIjoiYW9rdGVtIiwicm9sZSI6InVzZXIiLCJpc3N1ZWRfYXQiOiIyMDI0LTAzLTA0VDAyOjQwOjI4LjMyNDYxNDkzM1oiLCJleHBpcmVkX2F0IjoiMjAyNC0wMy0wNVQwMjo0MDoyOC4zMjQ2MjQ1OThaIn0.nqpGyAHyl686aiM5ATdY19FbqQXBOZCWsE-BATfcj8A",
              Authorization: `Bearer ${
                accessToken ||
                personelStoreData.personel!.token
              }`,
            },
          }
        );

        console.log(response.data.data[0]);
        return response.data.data;
      } catch (error) {
        console.error("Error in searchPatient:", error);
      }
    },
    // async searchPatient(searchPatientRequest: SearchPatientRequest) {
    //   const medulaStore = useMedula();
    //   const updatePatientStore = useUpdatePatientStore();
    //   await axios
    //     .post(
    //       `${clinicDomain}/patients/search`,
    //       {
    //         ...searchPatientRequest,
    //       },
    //       {
    //         headers: {
    //           Authorization: patientAuthToken,
    //           //AcceptLanguage:--
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.searchPatientListResponse = response.data;

    //         console.log(response.data);
    //         if (response.data !== null && response.data.count !== 0) {
    //           console.log("search patient :  ", response.data);
    //           medulaStore.medulaSearchPatientListArray = response.data.patients;
    //           globalStore.isPatiendIdExist = true;
    //         } else {
    //           medulaStore.medulaSearchPatientListArray = [];
    //           globalStore.isPatiendIdExist = false;
    //           medulaStore.medulaPatientHastaKodu = "";
    //         }

    //         console.log("search patient :  ", response.data);
    //       } else {
    //         console.log("UseSearchPatientStore Response is not 200");
    //       }
    //     });
    // },
  },
});
