<template>
  <div class="mt-40 flex flex-col items-center">
    <p class="text-xl font-medium">
      İLK MUAYENE İÇİN RANDEVU ALACAKLAR AŞAĞIDAKİ HUSUSLARA DİKKAT ETMESİ
      GEREKMEKTEDİR.
    </p>
    <div class="mt-8 max-w-[1035px]">
      <p class="">
        İlk defa hastanemize başvuracak hastalarımızın üzerinde T.C. kimlik
        numarası yazan resmi bir belge (kimlik-sürücü belgesi-pasaport vb) ile
        başvurmaları zorunludur.
      </p>
      <p class="mt-3 font-medium text-[#4198F7]">
        Randevularımız yetişkin hastalar için her gün saat 15:00'te bir sonraki
        iş günü için web sitemiz üzerinden açılmaktadır. (Yetişkin Hasta İlk
        Muayenesi Randevularını web sitemiz üzerinden alabilirsiniz.)
      </p>
      <p class="mt-3">
        Randevular; Yetişkin İlk Muayene (15 yaş ve üzeri), Çocuk İlk Muayene
        Pedodonti (0-15 yaş arası) randevuları şeklinde verilmektedir.
        Randevularınızı belirlenen yaş gruplarına göre; 0-15 yaş için her hafta
        Salı-Çarşamba-Perşembe-Cuma günleri olmak üzere saat 15.00 ‘te web
        sitemiz üzerinden veya üzerinden alabilirsiniz
      </p>

      <p class="mt-3">
        <span class="font-medium text-[#4198F7]"
          >18 yaşından küçük hastalarımızın muayene ve tedavilerine gelirken
          ebeveynleriyle birlikte gelmesi gerekmektedir. Hafta Sonları ve Resmi
          Tatil günlerine randevu verilmemektedir.</span
        >
        Merkezimize muayene ve tedavi amaçlı başvuran
        <span class="font-bold">YEŞİLKART </span> hastalarımızın her
        gelişlerinden önce Ağız ve Diş Sağlığı Merkezi/Hastanesi gibi 2. Basamak
        sağlık kuruluşlarından <span class="font-bold">SEVK </span> almaları
        gerekmektedir.
      </p>

      <p class="mt-6">
        Her Anabilim Dalı Kliniğinin günlük hasta kontenjan sayısı (kabul edilen
        hasta sayısı) değişiklik göstermektedir. Mevcut tedavi edilecek hasta
        sayıları ilgili Anabilim Dalı uzman hekim sayısı ve tedavi süreleri göz
        önüne alınarak ilgili Anabilim Dalı Başkanları tarafından
        belirlenmektedir.
      </p>
      <p class="mt-6">
        Randevu günü verilen randevu saatinde merkezimize gelen hastaların, ilk
        olarak hasta kabul birimine başvurarak giriş yaptırması gerekir. İlk
        muayene sonrası hekimin yönlendirme yaptığı teşhis planlama formu ile
        hastalarımız hasta kabul birimine başvurarak yönlendirmeleri
        yapılmaktadır. İlk muayene randevusunda sadece ilk muayene yapılarak
        teşhis konulmaktadır. Konulan teşhise göre ilgili bölümlerde tedaviler
        ayrıca verilen randevularla yapılmaktadır.
      </p>
      <p
        class="mt-3 flex h-4 w-full items-center justify-center font-bold text-[#4198F7]"
      >
        Alacağınız randevu kapsamında muayene ve işlemleriniz Kütahya Sağlık
        Bilimleri Üniversitesi Diş Hekimliği Fakültesi
      </p>
      <p
        class="mt-3 flex h-4 w-full items-center justify-center font-bold text-[#4198F7]"
      >
        Ana Bilim Dallarında görevli olan hekim/asistan/öğrenci tarafından
        gerçekleştirilebilir.
      </p>
    </div>
  </div>
</template>
  
