import { ref, reactive } from "vue";

export const navbarTitle = ref(0);
export const orderNavbarTitle = ref(0);
export const cargoNavbarTitle = ref(0);
export const orderCreatedPopUp=ref(false)

export const tempData = [
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: true,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
  {
    tcKimlikNo: "12345678910",
    hastaAdi: "Melisa Çiçek",
    tedaviDurumu: "Cad Cam Uygulaması",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan Beyaz",
    disSiparisMi: false,
    dosyaNumarasi: "123456789",
    tedaviAdi: "Badem",
    islemYapanHekim: "Yunus Aydın",
    tedavidekiDis: ["21", "36"],
  },
];

export const tempMaterialData = [
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
  {
    urunNo: "1",
    urunAdi: "Jaket Kron, tam seramik (Metal desteksiz)",
    hekimAdi: "Nalan Beyaz",
    stokBilgisi: "6 adet",
    fiyat: "2.500 TL",
    aciklama: "Özel Protez Merkezi",
  },
];

export const tempOrdersData = [
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Onaylanan",
    olcuDurumu: "Ölçü geldi",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy"],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },

  {
    siparisID: "A1234567",
    durum: "Işleme alınmamış",
    olcuDurumu: "Ölçü yenilemede",
    olcuYenilemeSebebi: "",
    uretimDurumu: "Üretim",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 1,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: ["eyy", "eyy", "eyy"],
    dijitalOlcu: true,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 0,
      kurumAdresi: "Kütahya Merkez",
    },
  },
  {
    siparisID: "A1234567",
    durum: "Üretim",
    olcuDurumu: "Ölçü bekleniyor",
    olcuYenilemeSebebi:
      "Yapılması planan bölgede proteze ait tüm anatomik sınırları kapsamayan",
    uretimDurumu: "Prova",
    uretimAsamasi: "Dentin Prova",
    teslimDurumu: 0,
    siparisSayisi: "3",
    hastaAdi: "Melisa ÇİÇEK",
    tcNo: "12345678900",
    olusturulmaTarihi: "01/01/2023",
    teslimTarihi: "01/05/2023",
    hekimAdi: "Nalan BEYAZ",
    olcuAlanHekimAdi: "Alaz YÜCE",
    olcuAlanHekimBirimi: "Ortodonti",
    kurumAdi: "Özel Klinik",
    tedaviAdi: "Hareketli Protez",
    yorumlar: [],
    dijitalOlcu: false,
    dosyalar: ["eyy", "eyy", "eyy"],
    teknisyen: "Melih BAYRAK",
    kargoBilgileri: {
      gonderenHekim: "Sena Jale",
      hastanedenCikisTarihi: "01/01/2023",
      kargoDurumu: 1,
      kurumAdresi: "Kütahya Merkez",
    },
  },
];

export const techniciansData = [
  {
    ad: "Melih",
    soyad: "BAYRAK",
    siparisSayisi: "4",
    tedaviAdi: "Hareketli Protez",
    olusturulmaTarihi: "11/10/2023",
    ekleyenHekim: "Suna Gezer",
    kurumAdi: "Özel Klinik",
  },
  {
    ad: "Melih",
    soyad: "BAYRAK",
    siparisSayisi: "4",
    tedaviAdi: "Hareketli Protez",
    olusturulmaTarihi: "11/10/2023",
    ekleyenHekim: "Suna Gezer",
    kurumAdi: "Özel Klinik",
  },
  {
    ad: "Melih",
    soyad: "BAYRAK",
    siparisSayisi: "4",
    tedaviAdi: "Hareketli Protez",
    olusturulmaTarihi: "11/10/2023",
    ekleyenHekim: "Suna Gezer",
    kurumAdi: "Özel Klinik",
  },
];

export const popUpManagement = reactive({
  detailPopUp: false,
  uploadPopUp: false,
  deletePopUp: false,
  editPopUp: false,
  addProductPopUp: false,
});

export const ordersPopUpManagement = reactive({
  detailPopUp: false,
  measureRenewalPopUp: false,
  nonProcessEditPopUp: false,
  uploadPopUp: false,
});

export const workOrdersPopUpManagement = reactive({
  getProductionPopUp: false,
});

export const addingProduct = reactive({
  productName: "",
  productType: "",
  productStock: "",
  productPrice: "",
  personelName: "",
  productDescription: "",
});

export const measureRenewalReasons = [
  {
    id: 0,
    text: "Yumuşak ve sert doku detaylarını iyi vermeme.",
  },
  {
    id: 1,
    text: "Üzerinde hava kabarcığı bulunan.",
  },
  {
    id: 2,
    text: "Ölçü maddesi kaşıktan ayrılmış ya da herhangi bir maddesi kopmuş.",
  },
  {
    id: 3,
    text: "Klinikte dezenfeksiyonu yapılmamış.",
  },
  {
    id: 4,
    text: "Diş çekim yerleri iyileşmemiş.",
  },
  {
    id: 5,
    text: "Süresinde kabul edilmeyen.",
  },
  {
    id: 6,
    text: "Kole sınırları net olmayan ölçüler yenilenir.",
  },
  {
    id: 7,
    text: "Yapılması planlanan bölgede proteze ait tüm anatomik sınırları kapsamayan.",
  },
];
