<template>
  <div class="bg-white shadow p-4 flex-1 text-[#464E5F]">
    <h2 class="font-medium text-xl">Hasta Sayısı</h2>
    <div class="mt-3 shadow p-3 rounded-xl bg-[#FBFBFB] border">
      <div class="flex justify-between text-lg">
        <div>
          <h4 class="font-semibold text-[#09244B]">TOPLAM</h4>
          <p class="mt-2"><span class="font-semibold mr-2">25</span>Hasta</p>
        </div>
        <div>
          <h4 class="font-semibold text-[#09244B]">MUAYENE OLMAYAN</h4>
          <p class="mt-2"><span class="font-semibold mr-2">14</span>Hasta</p>
        </div>
      </div>
      <div>
        <!-- make loop for unitStatistics -->
        <div
          v-for="unit in unitStatistics"
          :key="unit.unit"
          class="flex items-center mt-4 w-40"
        >
          <div
            class="w-4 h-4 rounded-full"
            :style="{ backgroundColor: unit.color }"
          ></div>
          <p class="ml-2">{{ unit.unit }}</p>
          <p class="ml-auto font-medium">
            {{ unit.notExamined }}<span class="text-xl">/{{ unit.total }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

const unitStatistics = reactive([
  {
    color: "#fd8540",
    unit: "Endodonti",
    notExamined: 3,
    total: 5,
  },
  {
    color: "#f7c137",
    unit: "Ortodonti",
    notExamined: 2,
    total: 5,
  },
  {
    color: "#25937F",
    unit: "Oral Diagnoz",
    notExamined: 1,
    total: 5,
  },
  {
    color: "#FFE83E",
    unit: "Periodontoloji",
    notExamined: 2,
    total: 5,
  },
  {
    color: "#DE5BC1",
    unit: "Pedodonti",
    notExamined: 1,
    total: 5,
  },
  {
    color: "#6498FD",
    unit: "Restoratif",
    notExamined: 3,
    total: 5,
  },
]);
</script>
