<template>
  <div class="h-full flex flex-col flex-grow">
    <div class="mt-2 flex gap-3 items-center">
      <actAppointmentsDateComponent />
      <!-- <VueDatePicker
        v-model="medulaStore.appointmentArray"
        locale="tr"
        auto-apply
        format="dd/MM/yyyy"
        :enable-time-picker="false"
        week-picker
        :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
        class="w-[285px]"
      /> -->
    </div>
    <ul
      tabindex="0"
      class="flex-grow flex flex-col -ml-3 justify-start gap-4 overflow-y-auto pt-3 focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
      :class="
        medulaStore.medulaProvizyonButtonVModel.provizyonTipi.id === 'V'
          ? 'max-h-[130px]'
          : 'max-h-[175px]'
      "
    >
      <div
        v-for="appointment in medulaStore.randevuBilgileri.appointments.data"
        :key="appointment"
        class="relative bg-[#fbfbfb] w-[750px] px-4 py-[10px] flex items-center gap-5 border rounded-lg"
      >
        <p class="text-xl font-medium">KSBÜ Ağız ve Diş</p>
        <div class="h-20 w-[2px] bg-[#D9D9D9] inline-block">&nbsp;</div>
        <div class="flex flex-col gap-1">
          <div class="flex items-center">
            <p class="w-32">Tarih & Saat</p>
            <p class="font-medium">
              {{
                appointment.attributes.appointment_time.slice(8, 10) +
                "/" +
                appointment.attributes.appointment_time.slice(5, 7) +
                "/" +
                appointment.attributes.appointment_time.slice(0, 4)
              }}
              &
              {{ appointment.attributes.appointment_time.slice(11, 16) }}
            </p>
          </div>
          <div class="flex items-center">
            <p class="w-32">Bölüm</p>
            <p class="font-medium">
              {{ _.startCase(_.toLower(appointment.attributes.unit_name)) }}
            </p>
          </div>
          <div class="flex items-center">
            <p class="w-32">Doktor</p>
            <p class="font-medium">{{ appointment.attributes.doctor_name }}</p>
          </div>
        </div>
        <div
          class="text-white rounded-lg absolute -top-4 right-0 p-1"
          :class="false ? ' bg-[#5CB85C]' : 'bg-[#277dd2]'"
        >
          <p
            class="font-light"
            v-if="
              Math.floor(
                (Date.parse(
                  new Date(
                    appointment.attributes.appointment_time
                  ).toISOString()
                ) -
                  Date.now()) /
                  (1000 * 60 * 60 * 24)
              ) === 0
            "
          >
            Randevu Bugün
          </p>
          <p
            class="font-light"
            v-else-if="
              Date.parse(
                new Date(appointment.attributes.appointment_time).toISOString()
              ) > Date.now()
            "
          >
            Randevuya
            <span class="font-medium">{{
              Math.floor(
                (Date.parse(
                  new Date(
                    appointment.attributes.appointment_time
                  ).toISOString()
                ) -
                  Date.now()) /
                  (1000 * 60 * 60 * 24)
              )
            }}</span>
            gün kaldı
          </p>
          <p class="font-light" v-else>Randevu tarihi geçti</p>
        </div>
      </div>
    </ul>
  </div>
</template>

<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import { onMounted, watch } from "vue";
import moment from "moment";
import _ from "lodash";

import actAppointmentsDateComponent from "./actAppointmentsDateComponent.vue";
import { useSearchPatientAppointments } from "@/store/modules/operationsStore/patient/useSearchPatientAppointments";

import { useMedula } from "../../medulaStore/medulaStore";
const medulaStore = useMedula();

const searchPatientAppointmentsStore = useSearchPatientAppointments();
// onMounted(() => {
//   searchPatientAppointmentnsStore.searchPatientAppointments(
//     medulaStore.selectedPatientID
//   );
// });

watch(
  () => medulaStore.appointmentArray[0],
  async (newValue, oldValue) => {
    await searchPatientAppointmentsStore.searchPatientAppointments(
      moment(medulaStore.appointmentArray[0]).format("YYYY-MM-DD"),
      moment(medulaStore.appointmentArray[1]).format("YYYY-MM-DD"),
      medulaStore.selectedPatientID
    );
  }
);
</script>
