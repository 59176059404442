<template>
  <div class="flex w-full h-full" v-if="navbarTitle === 0">
    <protezSidebar :activeRoute="5" />
    <div class="flex flex-col w-full h-full pl-6 pr-28">
      <div class="w-full px-3 py-8 flex gap-12 items-center">
        <h2 class="text-4xl font-semibold text-[#526581]">Siparişler</h2>
        <div class="border flex items-center p-1 w-72 gap-4">
          <img :src="searchIcon" alt="searchIcon" />
          <input
            class="w-full focus:outline-none"
            type="text"
            placeholder="Sipariş ID ya da Hasta ara"
          />
        </div>
        <div class="ml-auto">
          <button
            @click="routerToCreateOrders"
            class="focus:outline-none rounded-lg mb-4 flex gap-3 items-center justify-center py-1 px-2 shadow text-white bg-[#277DD2]"
          >
            <img :src="plus" alt="plus" />
            <p class="mt-1">Sipariş Oluştur</p>
          </button>
          <button
            @click="navbarTitle = 1"
            class="focus:outline-none rounded-full flex gap-3 px-4 items-center justify-center py-1 bg-white text-[#09244B] border-[1px] border-[#09244B]"
          >
            Prova listesi
          </button>
        </div>
      </div>
      <div>
        <ordersNavbar />
      </div>
      <div
        class="flex gap-4 mb-3"
        v-if="
          orderNavbarTitle === 1 && ordersPopUpManagement.nonProcessEditPopUp
        "
      >
        <div
          @click="deleteOrder"
          class="bg-[#f9d4cc] text-[#e12e06] cursor-pointer flex itemx-center justify-center w-fit px-2 py-1 gap-2 rounded-full"
        >
          <img :src="cancelOrder" alt="cancelOrder" />
          <p>Siparişi iptal et</p>
        </div>
        <div
          @click="ordersPopUpManagement.measureRenewalPopUp = true"
          class="bg-[#eff8ef] text-[#5cb85c] cursor-pointer flex itemx-center justify-center w-fit px-2 py-1 gap-2 rounded-full"
        >
          <img :src="renew" alt="renew" />
          <p>Ölçü yenileme</p>
        </div>
      </div>
      <div class="flex relative">
        <div class="flex flex-col">
          <allOrdersTable
            :currentOrderPage="currentOrderPage"
            v-if="orderNavbarTitle === 0"
          />
          <notOnProcessOrdersTable
            :currentOrderPage="currentOrderPage"
            v-if="orderNavbarTitle === 1"
          />
          <approvedOrdersTable
            :currentOrderPage="currentOrderPage"
            v-if="orderNavbarTitle === 2"
          />
          <onProductionOrdersTable
            :currentOrderPage="currentOrderPage"
            v-if="orderNavbarTitle === 3"
          />
          <deliveredTable
            :currentOrderPage="currentOrderPage"
            v-if="orderNavbarTitle === 4"
          />
          <measureRenewalReason
            v-if="
              ordersPopUpManagement.measureRenewalPopUp &&
              orderNavbarTitle === 1 &&
              ordersPopUpManagement.nonProcessEditPopUp
            "
          />
          <div
            v-if="orderNavbarTitle === 1"
            class="flex items-center gap-4 text-right w-full mt-2"
          >
            <div class="bg-[#b2533e] w-3 h-3 rounded-full ml-auto"></div>
            Tablodaki renkli alanlar dijital gelen ölçüleri göstermektedir
          </div>
          <div class="mb-5 ml-auto mr-[50px]">
            <vue-awesome-paginate
              :total-items="getTotalItems"
              :items-per-page="7"
              :max-pages-shown="7"
              v-model="currentOrderPage"
              :on-click="onClickHandlerOrder"
            />
          </div>
        </div>
        <orderDetail v-if="ordersPopUpManagement.detailPopUp" />
      </div>
    </div>
    <addFolder v-if="ordersPopUpManagement.uploadPopUp" />
  </div>
  <div class="flex w-full h-full" v-if="navbarTitle === 1">
    <protezSidebar :activeRoute="5" />
    <div class="flex flex-col w-full h-full pl-6 pr-28">
      <div class="w-full px-3 py-8 flex gap-12 items-center">
        <h2 class="text-4xl font-semibold text-[#526581]">Prova Listesi</h2>
        <div class="border flex items-center p-1 w-72 gap-4">
          <img :src="searchIcon" alt="searchIcon" />
          <input
            class="w-full focus:outline-none"
            type="text"
            placeholder="Sipariş ID ya da Hasta ara"
          />
        </div>
        <div class="ml-auto">
          <button
            @click="navbarTitle = 0"
            class="focus:outline-none rounded-full flex gap-3 px-4 items-center justify-center py-1 bg-[#09244B] text-white"
          >
            Siparişler
          </button>
        </div>
      </div>
      <div>
        <cargosNavbar />
      </div>
      <div
        class="flex gap-4 mb-3"
        v-if="
          orderNavbarTitle === 1 && ordersPopUpManagement.nonProcessEditPopUp
        "
      >
        <div
          @click="deleteOrder"
          class="bg-[#f9d4cc] text-[#e12e06] cursor-pointer flex itemx-center justify-center w-fit px-2 py-1 gap-2 rounded-full"
        >
          <img :src="cancelOrder" alt="cancelOrder" />
          <p>Siparişi iptal et</p>
        </div>
        <div
          @click="ordersPopUpManagement.measureRenewalPopUp = true"
          class="bg-[#eff8ef] text-[#5cb85c] cursor-pointer flex itemx-center justify-center w-fit px-2 py-1 gap-2 rounded-full"
        >
          <img :src="renew" alt="renew" />
          <p>Ölçü yenileme</p>
        </div>
      </div>
      <div class="flex relative">
        <div class="flex flex-col">
          <allCargosTable :currentCargoPage="currentCargoPage" />
          <div class="mb-5 ml-auto mr-[50px]">
            <vue-awesome-paginate
              :total-items="getCargoTotalItems"
              :items-per-page="7"
              :max-pages-shown="7"
              v-model="currentCargoPage"
              :on-click="onClickHandlerCargo"
            />
          </div>
        </div>
        <productionDetail v-if="ordersPopUpManagement.detailPopUp" />
      </div>
    </div>
    <addFolder v-if="ordersPopUpManagement.uploadPopUp" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import personelSideBar from "@/modules/personel/components/personalSideBar.vue";
import searchIcon from "../assets/searchIcon.svg";
import plus from "@/modules/protez/assets/plusWhite.svg";
import cancelOrder from "@/modules/protez/assets/cancelOrder.svg";
import renew from "@/modules/protez/assets/renew.svg";
import addFolder from "../components/addFolder.vue";
import {
  navbarTitle,
  orderNavbarTitle,
  ordersPopUpManagement,
  tempOrdersData,
  cargoNavbarTitle,
} from "../store/temporaryProtezData.js";
import allOrdersTable from "../components/protezOrders/allOrdersTable.vue";
import notOnProcessOrdersTable from "../components/protezOrders/notOnProcessOrdersTable.vue";
import approvedOrdersTable from "../components/protezOrders/approvedOrdersTable.vue";
import onProductionOrdersTable from "../components/protezOrders/onProductionOrdersTable.vue";
import deliveredTable from "../components/protezOrders/deliveredTable.vue";
import ordersNavbar from "../components/protezOrders/ordersNavbar.vue";
import orderDetail from "../components/protezOrders/orderDetail.vue";
import productionDetail from "../components/protezCargos/productionDetail.vue";
import measureRenewalReason from "../components/protezOrders/measureRenewalReason.vue";
import cargosNavbar from "../components/protezCargos/cargosNavbar.vue";
import allCargosTable from "../components/protezCargos/allCargosTable.vue";
import { watch } from "vue";
import { useRouter } from "vue-router";
import protezSidebar from "@/modules/protez/components/protezSidebar.vue";

const router = useRouter();
const routerToCreateOrders = () => {
  router.push("/protez/createOrders");
};
watch(
  () => orderNavbarTitle.value,
  (newVal) => {
    ordersPopUpManagement.measureRenewalPopUp = false;
    ordersPopUpManagement.nonProcessEditPopUp = false;
  }
);

const getTotalItems = computed(() => {
  if (orderNavbarTitle.value === 0) {
    return tempOrdersData.length;
  } else if (orderNavbarTitle.value === 1) {
    return filteredNotOnProcessOrders.length;
  } else if (orderNavbarTitle.value === 2) {
    return filteredApprovedOrders.length;
  } else if (orderNavbarTitle.value === 3) {
    return filteredOnProductionOrders.length;
  } else if (orderNavbarTitle.value === 4) {
    return filteredDeliveredOrders.length;
  } else {
    return "ey";
  }
});
cargoNavbarTitle;
const getCargoTotalItems = computed(() => {
  if (cargoNavbarTitle.value === 0) {
    return tempOrdersData.length;
  } else if (cargoNavbarTitle.value === 1) {
    return filteredComingOrders.length;
  } else if (cargoNavbarTitle.value === 2) {
    return filteredArrivingOrders.length;
  } else {
    return "ey";
  }
});

const filteredNotOnProcessOrders = tempOrdersData.filter(
  (order) => order.durum === "Işleme alınmamış"
);
const filteredApprovedOrders = tempOrdersData.filter(
  (order) => order.durum === "Onaylanan"
);
const filteredOnProductionOrders = tempOrdersData.filter(
  (order) => order.durum === "Üretim"
);

const filteredDeliveredOrders = tempOrdersData.filter(
  (order) => order.teslimDurumu === 1
);

const filteredComingOrders = tempOrdersData.filter(
  (order) => order.kargoBilgileri.kargoDurumu === 0
);
const filteredArrivingOrders = tempOrdersData.filter(
  (order) => order.kargoBilgileri.kargoDurumu === 1
);

const onClickHandlerOrder = (page) => {
  console.log(page);
};
const onClickHandlerCargo = (page) => {
  console.log(page);
};
const currentOrderPage = ref(1);
const currentCargoPage = ref(1);
const deleteOrder = () => {
  console.log("deleteOrder");
};
</script>
