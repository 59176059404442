<template>
  <div class="w-full bg-white rounded-[40px] h-full">
    <div class="absolute z-[998] right-20 top-20">
      <customInfoCard v-if="globalStore.customInfoCard" />
    </div>
    <main class="py-4 px-12 flex flex-col gap-7 h-full">
      <!-- nav -->
      <div class="mx-auto font-medium">
        <button
          @click="activeTab = 1"
          class="rounded h-[67px] w-[200px] mr-[1px]"
          :class="
            activeTab === 1
              ? 'text-[#277dd2] border-b-2 border-[#277dd2] bg-white'
              : 'text-[#9ea1a4] bg-[#fbfbfb]'
          "
        >
          Görüntüleme Ayarları
        </button>
        <button
          @click="activeTab = 2"
          class="rounded h-[67px] w-[200px]"
          :class="
            activeTab === 2
              ? 'text-[#277dd2] border-b-2 border-[#277dd2] bg-white'
              : 'text-[#9ea1a4] bg-[#fbfbfb]'
          "
        >
          SMS Ayarları
        </button>
      </div>

      <smsSettings v-if="activeTab === 2" />
      <viewSettings v-if="activeTab === 1" />
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import _ from "lodash";
import { useRouter } from "vue-router";
import { useAppointmentBook } from "@/modules/asistanEkrani/components/appointmentBookStore";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import Select2 from "vue3-select2-component";
import customInfoCard from "@/components/customInfoCard.vue";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { globalStore } from "@/store/modules/globalStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import viewSettings from "@/modules/asistanEkrani/components/appointmentBookSettingSubs/viewSettings.vue";
import smsSettings from "@/modules/asistanEkrani/components/appointmentBookSettingSubs/smsSettings.vue";

const activeTab = ref(2);
const medulaStore = useMedula();
const appointmentBookStore = useAppointmentBook();
const unitListStore = useGetUnitsList();

onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});
</script>
