import { defineStore } from "pinia";
export const useRadiologyRontgenStore = defineStore(
  "useRadiologyRontgenStore",
  {
    id: "useRadiologyRontgenStore",
    state: () => ({
      radiologyRontgenSliderButton: 1,
      radiologyRontgenSearch: "",
      radiologyBirimTanimlari: [
        "Röntgen 2",
        "Röntgen 3",
        "Röntgen 4",
        "Röntgen 5",
      ],
      radiologyHekimler: [
        "Doç.Dr. Suna Gezer",
        "Doç.Dr. Selin Bal",
        "Doç.Dr. Melih Gezen",
        "Doç.Dr. Suna Gezer",
        "Doç.Dr. Selin Bal",
        "Doç.Dr. Melih Gezen",
      ],
      radiologyHekimListesiSlider: 1,
      isBirimEklePopUpOpen: false,
      radiologyRongtenBirimEklePopUpInput: "",
      radiologyRongtenBirimEklePopUpButton: "",
      isCihazEklePopUpOpen: false,
      radiologyCihazEklePopUpInput: "",
      radiologyCihazEklePopUpButton: "",
      radiologyCihazTanimlari: [
        "Röntgen 2",
        "Röntgen 3",
        "Röntgen 4",
        "Röntgen 5",
      ],
      radiologyEntegrasyonTanimlari: [
        "Cihaz 1",
        "Cihaz 2",
        "Cihaz 3",
        "Cihaz 4",
      ],
      radiologySablonAdiList: ["#######"],
      radiologyIstemTanimlariList: [
        { id: "401.050", name: "Periapikal Film" },
        { id: "401.050", name: "Periapikal Film" },
        { id: "401.050", name: "Periapikal Film" },
        { id: "401.050", name: "Periapikal Film" },
      ],
    }),
  }
);
