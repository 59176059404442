<template>
  <div class="flex h-[126px] w-full flex-col bg-white">
    <div class="flex">
      <dentalChartTopPatientInfoCard />
    </div>
    <div class="mt-10 w-full border-t-2 border-[#EBEBEB]"></div>
    <div class="mt-1 flex items-center">
      <button @click="routeToRadiologyScreens">
        <img :src="teethLogo" class="ml-5" alt="teethLogo" />
      </button>
      <div>
        <img :src="pillLogo" class="ml-5" alt="pillLogo" />
      </div>
      <div
        @click="
          store.dentalChartAnamnezButtonCard =
            !store.dentalChartAnamnezButtonCard
        "
        :class="
          store.dentalChartAnamnezButtonCard
            ? 'bg-[#0094FF] border-white  w-[240px] h-[28px]'
            : 'bg-white border-[#0094FF] w-[94px] h-[26px]'
        "
        class="ml-5 flex cursor-pointer items-center justify-center rounded-[5px] border-2"
      >
        <p
          :class="
            store.dentalChartAnamnezButtonCard
              ? 'text-white p-1'
              : 'text-[#0094FF]'
          "
          class="font-medium"
        >
          Anamnez
        </p>
      </div>
      <!-- <dentalChartAnamnezCard /> -->
      <div
        :class="buttonBg ? 'bg-[#9A4AFF] ' : 'bg-white '"
        class="ml-5 flex h-[26px] w-[152px] cursor-pointer items-center justify-center rounded-[5px] border-2 border-[#9A4AFF]"
      >
        <p
          @click="purpleToothFunction"
          :class="buttonBg ? ' text-white' : ' text-[#9A4AFF]'"
          class="font-medium"
        >
          Protez Tedavi Planı
        </p>
      </div>
      <div
        @click="openPopup"
        class="ml-auto flex h-7 w-20 cursor-pointer items-center justify-center rounded-[5px] bg-[#F6F6F6]"
      >
        <img :src="printerLogo" alt="" />
        <p class="ml-2 font-normal text-[#454444]">Yazdır</p>
      </div>
      <div
        class="ml-2 flex h-7 mr-4 cursor-pointer items-center justify-center rounded-[5px] bg-white border-[1px] border-[#E6E6E6]"
      >
        <p class="ml-2 font-normal text-[#454444]">{{ getDateToday }}</p>
      </div>
    </div>
    <div class="mt-[6px] w-full border-t-2 border-[#EBEBEB]"></div>
  </div>
</template>
<script setup>
import pillLogo from "../../assets/pillLogo.svg";
import teethLogo from "../../assets/teethLogo.svg";
import printerLogo from "../../assets/printerLogo.svg";
// import dentalChartAnamnezCard from "./dentalChartAnamnezCard.vue";
import { ref } from "vue";
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
import moment from "moment";
import dentalChartTopPatientInfoCard from "./dentalChartTopPatientInfoCard.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useDentalChart();
const openPopup = () => {
  store.dentalChartSUTError[1] = !store.dentalChartSUTError[1];
};
let buttonBg = ref(false);
const purpleToothFunction = () => {
  buttonBg.value = true;
  const toothPosition = store.getdentalChartTeethNumberStatePosition(
    store.dentalChartSelectedTeethNumber[
      store.dentalChartToothNumberCheckbox.indexOf(true)
    ]
  );
  store.dentalChartTeethNumberState[toothPosition] = 7;
};
moment.locale("tr");
const getDateToday = ref(moment().format("D MMMM, YYYY"));

const routeToRadiologyScreens = () => {
  router.push("/radiology");
};
</script>
<style scoped>
.navbarNameBorder {
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}
</style>