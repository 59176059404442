<template>
  <div>
    <button
      v-if="
        store.dentalChartSideBarMuayeneButton |
          store.dentalChartSideBarHizmetButton
      "
      @click="openPopup()"
      class="ml-[189px] mt-7 h-10 w-[81px] rounded-[5px] bg-white border-[#0094FF] border-[1px] focus:outline-none"
    >
      <p class="text-[#0094FF]">Onayla</p>
    </button>
    <div :class="popup && 'overlay'" @click="openPopup()">
      <div
        :class="popup && 'open-popup'"
        class="popup flex h-full w-full border-l-8 border-[#36B10B]"
      >
        <img :src="successInfoLogo" class="ml-4 w-[18px]" alt="successImg" />
        <p
          class="ml-2 flex h-full w-[80%] items-center text-xs font-semibold text-[#373A3C]"
        >
          {{ store.dentalChartSelectedTeethNumber }}
          {{ props.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import successInfoLogo from "../../assets/successInfoLogo.svg";

import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();
const props = defineProps(["text"]);
// PopUp
let popup = ref(null);
const openPopup = () => {
  popup.value = !popup.value;
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.open-popup {
  visibility: visible;
  transform: translate(-50%, 0) scale(1);
}
.popup {
  width: 300px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  right: 0;
  top: 5%;
  transform: translate(0, 0) scale(1);

  color: #333;
  visibility: hidden;
  transition: transform 0.4s, left 0.4s;
}
.open-popup {
  visibility: visible;
  transform: translate(-25%, 0) scale(1);
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
}
</style>