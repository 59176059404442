<template>
  <div
    class="absolute bg-white z-50 w-full h-fit top-0 left-0 p-4 shadow-xl flex gap-2 border-2 rounded border-[#288dd2]"
  >
    <div class="basis-1/2 pr-24 flex flex-col">
      <div class="flex items-center justify-between">
        <h3 class="text-xl font-semibold">Sipariş Detayı</h3>
        <div class="flex gap-6">
          <div class="flex items-center gap-2 cursor-pointer">
            <img :src="printer" alt="printer" /> Yazdır
          </div>
          <div class="flex items-center gap-2 cursor-pointer">
            <img :src="smallBarcode" alt="barcode" /> Barkod Yazdır
          </div>
        </div>
      </div>
      <p
        class="ml-auto my-2 bg-[#f8eeec] text-[#b2533e] py-1 px-4 rounded-full font-semibold"
      >
        A1234567
      </p>
      <div class="flex gap-4 mb-4">
        <div class="flex flex-col gap-3">
          <div class="flex">
            <p class="w-48">Kurum Adı</p>
            <p class="font-semibold">Özel Protez Merkezi</p>
          </div>
          <div class="flex">
            <p class="w-48">Tedavi Planı</p>
            <p class="font-semibold">Sabit Protez</p>
          </div>
          <div class="flex">
            <p class="w-48">Ölçü alan hekim / Birimi</p>
            <p class="font-semibold">Nalan ÇİÇEK / Ortodonti</p>
          </div>
          <div class="flex">
            <p class="w-48">Sipariş oluşturan hekim</p>
            <p class="font-semibold">Alper GÜÇLÜ</p>
          </div>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex">
            <p class="w-48">Oluşturulma Tarihi</p>
            <p class="font-semibold">01/01/2023</p>
          </div>
          <div class="flex">
            <p class="w-48">Tedavideki Dişler</p>
            <p class="font-semibold">21,36</p>
          </div>
          <div class="flex">
            <p class="w-48">Ölçü Durumu</p>
            <p class="font-semibold">Yenileniyor</p>
          </div>
          <div class="flex"></div>
        </div>
      </div>
      <div
        class="rounded-full w-full p-2 bg-[#252525] text-white flex justify-center items-center"
      >
        Metal Destekli Kron
      </div>
      <!-- STEP SECTION -->
      <div>
        <div class="relative my-4">
          <div
            class="bg-[#277DD2] text-white rounded p-3 max-w-xs mb-3"
            :class="{
              'ml-auto': currentStep >= 4,
            }"
          >
            <p class="font-semibold">Cad - Cam Uygulaması</p>
            <p>05/02/2023</p>
          </div>

          <div
            class="absolute -bottom-3 w-0 h-0 border-l-[10px] border-l-transparent border-t-[17px] border-t-[#277DD2] border-r-[10px] border-r-transparent"
            :class="{
              'left-4': currentStep === 1,
              'left-36': currentStep === 2,
              'left-60 ml-4': currentStep === 3,
              'left-96': currentStep === 4,
              'right-32': currentStep === 5,
              'right-4': currentStep === 6,
            }"
          ></div>
        </div>
        <div class="flex items-center justify-between relative">
          <div v-for="step in 6" :key="step" class="relative">
            <div
              v-if="step <= currentStep"
              class="relative w-12 h-12 bg-[#277DD2] rounded-full flex items-center justify-center z-10"
            >
              <img
                v-if="step === 6"
                :src="success"
                alt="success"
                class="h-6 w-6 text-white"
              />
              <img
                v-if="step === currentStep && step !== 6"
                :src="continueIcon"
                alt="continueIcon"
                class="h-3 w-3 text-white"
              />
            </div>

            <div
              v-else
              class="relative w-12 h-12 bg-white border rounded-full flex items-center justify-center z-10"
            ></div>
          </div>
          <div
            class="bg-[#277DD2] h-1 absolute top-1/2 -translate-y-1/2 mx-auto -z-10"
            :class="{
              'w-10': currentStep === 1,
              'w-40': currentStep === 2,
              'w-64': currentStep === 3,
              'w-96': currentStep === 4,
              'w-[500px]': currentStep === 5,
              'w-[630px]': currentStep === 6,
            }"
          ></div>
        </div>
        <div class="w-full flex mt-4 justify-between items-center">
          <div>
            <p class="opacity-50">Oluşturulma Tarihi</p>
            <p>02/02/2023</p>
          </div>
          <div>
            <p class="opacity-50">Teslim Tarihi</p>
            <p>25/02/2023</p>
          </div>
        </div>
        <div class="w-full mt-2 flex">
          <p class="ml-auto">
            *Teslim tarihine son <span class="text-[#E12901]">23</span> gün
            kaldı
          </p>
        </div>
      </div>
      <!-- FOLDER SECTION -->
      <div class="mt-2">
        <div class="flex items-center justify-between w-full">
          <h3 class="text-2xl font-semibold">Dosyalar</h3>
          <button
            @click="ordersPopUpManagement.uploadPopUp = true"
            class="flex gap-4 items-center justify-center focus:outline-none border py-1 px-2 bg-white text-[#277DD2] rounded"
          >
            <img :src="documentUploadIcon" alt="documentUploadIcon" /> Dosya
            yükle
          </button>
        </div>
        <div
          class="bg-[#f4f4f4] w-full h-20 flex items-center justify-center rounded-2xl"
        >
          <div class="bg-[#e6e6e6] py-2 px-28 rounded-full">
            <p class="text-xl">Dosya Bulunamadı</p>
          </div>
        </div>
      </div>
    </div>
    <!-- yorum section -->
    <div class="basis-1/2 py-4">
      <p class="font-semibold text-xl ml-3">Yorum</p>
      <div
        class="bg-[#eaf2fb] h-full flex py-4 px-2 justify-center rounded-2xl"
      >
        <div class="relative h-fit">
          <textarea
            v-model="comment"
            cols="84"
            rows="7"
            placeholder="Yorum yaz..."
            class="resize-none rounded-xl p-2 focus:outline-none"
          ></textarea>

          <button
            @click="addComment"
            class="absolute right-2 bottom-2 focus:outline-none rounded py-1 px-3 text-sm bg-[#277DD2] text-white"
          >
            Gönder
          </button>
        </div>
      </div>
    </div>
    <div
      class="absolute top-0 right-0 flex justify-center items-center rounded-full w-12 h-12 bg-[#FBEDED] cursor-pointer"
      @click="ordersPopUpManagement.detailPopUp = false"
    >
      <img :src="closeIcon" alt="closeIcon" />
    </div>
  </div>
</template>

<script setup>
import printer from "@/modules/protez/assets/printer.svg";
import smallBarcode from "@/modules/protez/assets/smallBarcode.svg";
import closeIcon from "@/modules/protez/assets/closeIcon.svg";
import rightArrowIcon from "@/modules/protez/assets/rightArrowIcon.svg";
import leftArrowIcon from "@/modules/protez/assets/leftArrowIcon.svg";
import success from "@/modules/protez/assets/success.svg";
import continueIcon from "@/modules/protez/assets/continueIcon.svg";
import documentUploadIcon from "@/modules/protez/assets/documentUploadIcon.svg";
import { ref } from "vue";
const currentStep = ref(6);
const comment = ref("");
import {
  popUpManagement,
  ordersPopUpManagement,
} from "@/modules/protez/store/temporaryProtezData.js";
const addComment = () => {
  console.log("post comment");
  comment.value = "";
};
</script>
