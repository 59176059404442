<template>
  <div class="flex flex-col min-w-[650px] w-full h-full ml-[44px] mt-[15px]">
    <div
      class="border-x-[1px] border-t-[1px] border-[#D5DBE1] rounded-[5px] w-[140px] h-[30px] flex items-center justify-center"
    >
      <p class="text-[#2C3D8F] font-medium">RIS Entegrasyon</p>
    </div>
    <div class="flex flex-col h-full border-[#D5DBE1] w-full">
      <div class="w-full border-b-[#D5DBE1] border-[1px]"></div>
      <div class="flex items-center mt-[58px]">
        <p class="text-[#454545] w-[180px]">Ris Sistem Adı</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        ></div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Entegrasyon Tipi</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        ></div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Teletıp Kurum Adı</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        ></div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Ris Lan Ip</p>
        <div
          class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px] flex items-center"
        ></div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Ris Wan Ip</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex items-center mt-[16px]">
        <p class="text-[#454545] w-[180px]">Ris Sistem Port</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
    </div>
  </div>
</template>