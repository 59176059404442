<template>
  <div
    class="z-50 absolute top-1/2 right-3 -translate-y-1/2 px-4 py-2 bg-[#fbfbfb] text-[#464E5F] min-h-[550px] min-w-[1180px] rounded-xl"
  >
    <div class="flex justify-between items-center">
      <div
        class="bg-opacity-10 bg-[#F0AD4E] rounded-xl px-3 py-2 text-[#F0AD4E] font-semibold"
      >
        Sevk Hasta
      </div>
      <button
        class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] rounded-full flex items-center justify-center w-9 h-9 bg-[#f8eaea]"
        @click="
          RegisteredPatientListStore.provisionsPatient = null;
          RegisteredPatientListStore.sidePanelShow = false;
          RegisteredPatientListStore.editleniyor = false;
          RegisteredPatientListStore.editButton = false;
        "
      >
        <img class="w-3" :src="closeIcon" alt="close icon" />
      </button>
    </div>
    <patientInfo />
    <cardsNavigation />
    <provisionCard
      v-if="RegisteredPatientListStore.sidePanelCardNavigation === 1"
    />
    <patientCard
      v-if="RegisteredPatientListStore.sidePanelCardNavigation === 2"
    />
  </div>
</template>

<style scoped>
.panel {
  position: fixed;
  width: 67%;
  top: 10;
  right: -30%;
  animation: panelSlide 0.3s forwards;
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
@keyframes panelSlide {
  0% {
    right: -30%;
  }
  100% {
    right: 0;
  }
}
</style>

<script setup>
import { onMounted } from "vue";
//section imports
import patientInfo from "@/modules/asistanEkrani/registeredPatientList/components/sidePanel/patientInfo.vue";
import cardsNavigation from "@/modules/asistanEkrani/registeredPatientList/components/sidePanel/cardsNavigation.vue";
import provisionCard from "@/modules/asistanEkrani/registeredPatientList/components/sidePanel/provisionCard.vue";
import patientCard from "@/modules/asistanEkrani/registeredPatientList/components/sidePanel/patientCard.vue";

//asset imports
import closeIcon from "@/modules/protez/assets/closeIcon.svg";
//store imports
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
import { useGetCities } from "@/store/modules/operationsStore/public/useGetCities";
//store inits
const RegisteredPatientListStore = useRegisteredPatientListStore();
const getCitiesStore = useGetCities();

onMounted(async () => {
  if (getCitiesStore.getListCitiesResponse) {
    return false;
  }
  await getCitiesStore.getCities();
});
</script>
