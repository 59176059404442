import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import axios from "axios";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useSearchPatientAppointments = defineStore({
  id: "getPatientAppointments",
  state: () => ({}),
  actions: {
    async searchPatientAppointments(
      startDate: Date,
      endDate: Date,
      id: string
    ) {
      try {
        const personelStoreData = personelStore();
        const medulaStore = useMedula();
        const response = await axios.get(
          `${clinicDomain}/appointments/patient/date?patient_id=${id}&patient_tc_identity=&start_date=${startDate}&end_date=${endDate}&page_id=1&page_size=500`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        medulaStore.randevuBilgileri = response.data;
        return response.data;
      } catch (error) {
        console.error("Error in searchPatientAppointments:", error);
      }
    },
  },
});
