<template>
  <div class="flex flex-col gap-6">
    <div>
      <h4 class="font-semibold">TIBBİ ANAMNEZ</h4>
      <div class="p-3 border flex flex-col gap-1 rounded-[10px] mt-2">
        <div class="flex">
          <p>Sistematik Hastalıklar :&nbsp;</p>
          <p class="opacity-80">
            ####################################################################
          </p>
        </div>
        <div class="flex">
          <p>Kardiyovasküler Hastalıklar :&nbsp;</p>
          <p class="opacity-80">
            ####################################################################
          </p>
        </div>
        <div class="flex">
          <p>Endokrin Hastalıklar :&nbsp;</p>
          <p class="opacity-80">
            ####################################################################
          </p>
        </div>
        <div class="flex">
          <p>Sigara Kullanımı :&nbsp;</p>
          <p class="opacity-80">
            ####################################################################
          </p>
        </div>
      </div>
    </div>

    <div>
      <h4 class="font-semibold">DENTAL ANAMNEZ</h4>
      <div class="p-3 border flex flex-col gap-1 rounded-[10px] mt-2">
        <div class="flex">
          <p>Diş Fırçalama :&nbsp;</p>
          <p class="opacity-80">Günde 1</p>
        </div>
        <div class="flex">
          <p>Arayüz Bakımı :&nbsp;</p>
          <p class="opacity-80">Yok</p>
        </div>
        <div class="flex">
          <p>Arayüz Temizlik Ajanı :&nbsp;</p>
          <p class="opacity-80">Kürdan</p>
        </div>
        <div class="flex">
          <p>Arayüz Temizlik Ajanı Kullanım Sıklığı :&nbsp;</p>
          <p class="opacity-80">Düzenli</p>
        </div>
        <div class="flex">
          <p>Diş Etinde Kızarıklık :&nbsp;</p>
          <p class="opacity-80">Yok</p>
        </div>
        <div class="flex">
          <p>Diş Etinde Büyüme(Ödem) :&nbsp;</p>
          <p class="opacity-80">Yok</p>
        </div>
        <div class="flex">
          <p>Ağız Kokusu :&nbsp;</p>
          <p class="opacity-80">Yok</p>
        </div>
        <div class="flex">
          <p>Müdahale Gerektirecek Diş veya Diş Eti Ağrısı :&nbsp;</p>
          <p class="opacity-80">Var</p>
        </div>
      </div>
    </div>
  </div>
</template>
