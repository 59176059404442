<template>
  <div
    class="flex items-center justify-between text-[#40464C] shadow border px-7 py-2"
  >
    <div class="flex items-end gap-3">
      <img :src="profileCircle" alt="profile photo" class="w-[56px] h-[56px]" />
      <div v-if="doctorInfo.doctorInfos">
        <p>
          {{ doctorInfo.doctorInfos.name }}&nbsp;{{
            formatString(doctorInfo.doctorInfos.surname)
          }}
        </p>
        <p class="text-[#a0a3a6]">
          {{ formatString(doctorInfo.doctorInfos.unit_name) }}
        </p>
      </div>
    </div>
    <div class="flex items-center gap-8 font-medium">
      <button
        class="px-[17px] focus:outline-1 focus:outline-double focus:outline-[#277dd2]"
        :class="
          router.currentRoute.value.path === '/polyclinic/doctor-screens/infos'
            ? 'border-b-2 border-[#277dd2] text-[#277dd2]'
            : 'text-[#a0a3a6]'
        "
        @click="router.push('/polyclinic/doctor-screens/infos')"
      >
        Kullanıcı Bilgileri
      </button>
      <button
        class="px-[17px] focus:outline-1 focus:outline-double focus:outline-[#277dd2]"
        :class="
          router.currentRoute.value.path === '/polyclinic/doctor-screens/plan'
            ? 'border-b-2 border-[#277dd2] text-[#277dd2]'
            : 'text-[#a0a3a6]'
        "
        @click="router.push('/polyclinic/doctor-screens/plan')"
      >
        Çizelge
      </button>
      <button
        class="px-[17px] focus:outline-1 focus:outline-double focus:outline-[#277dd2]"
        :class="
          router.currentRoute.value.path ===
          '/polyclinic/doctor-screens/performance'
            ? 'border-b-2 border-[#277dd2] text-[#277dd2]'
            : 'text-[#a0a3a6]'
        "
        @click="router.push('/polyclinic/doctor-screens/performance')"
      >
        Performans
      </button>
      <button
        class="px-[17px] focus:outline-1 focus:outline-double focus:outline-[#277dd2]"
        :class="
          router.currentRoute.value.path ===
          '/polyclinic/doctor-screens/appointments'
            ? 'border-b-2 border-[#277dd2] text-[#277dd2]'
            : 'text-[#a0a3a6]'
        "
        @click="router.push('/polyclinic/doctor-screens/appointments')"
      >
        Randevular
      </button>
    </div>
    <form
      class="flex px-2 items-center rounded bg-[#f3f3f3] focus-within:outline-1 focus-within:outline-double focus-within:outline-[#277dd2]"
    >
      <input
        type="text"
        class="pr-1 bg-transparent focus:outline-none py-1"
        placeholder="Doktor ara"
      />
      <img :src="searchLogo" alt="search" class="w-5 bg-[#f3f3f3]" />
    </form>
  </div>
</template>

<script setup>
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import { doctorInfo } from "@/modules/doctorSlots/components/doctorInfoReactiveTypes";
import { useRouter } from "vue-router";
const router = useRouter();
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
const doctorSlotStore = doctorSlotsStore();

const formatString = (str) => {
  if (!str) {
    return null;
  }
  return str
    .toLocaleLowerCase("tr-TR")
    .split(" ")
    .map((word) => word.charAt(0).toLocaleUpperCase("tr-TR") + word.slice(1))
    .join(" ");
};
</script>
