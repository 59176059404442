<template>
  <div class="flex w-full">
    <appointmentViewSideBar :activePage="4" />
    <div class="w-full bg-[#fbfbfb] flex flex-col">
      <AppointmentViewNavBar />
      <main class="p-4">
        <div
          class="px-2 py-[2px] rounded bg-[#277dd2] text-white flex items-center gap-2 w-fit"
        >
          <img :src="tedaviPlaniLogoWhite" alt="" />
          <span>Tedavi Planı</span>
        </div>
        <div
          class="bg-[#EBEBEB] text-[#707070] font-semibold text-xl sm:text-3xl py-[15px] flex items-center justify-center mt-3 rounded-lg"
        >
          TEDAVİ PLANI BULUNAMADI
        </div>
        <!-- <div class="flex flex-wrap gap-20">
          <div class="bg-white rounded p-3 border">
            <div class="p-1 rounded bg-white shadow border">
              <div class="flex rounded-lg">
                <div class="flex flex-col"></div>
              </div>
            </div>
          </div>
        </div> -->
      </main>
    </div>
    <appointmentHelpInfo />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import appointmentHelpInfo from "../components/appointmentHelpInfo.vue";

import moment from "moment";
import { useRouter } from "vue-router";
import addDays from "date-fns/addDays";
import tedaviPlaniLogoWhite from "../assets/tedaviplaniWhite.svg";
import appointmentViewSideBar from "../components/appointmentViewSideBar.vue";
import AppointmentViewNavBar from "../components/appointmentViewNavBar.vue";
import BolumIcon from "../assets/BolumIcon.svg";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useGetUnit } from "@/store/modules/administratorManagementStore/hospital/Units/getUnitStore";
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic.ts";
import { useGetSlot } from "@/store/modules/operationsStore/slot/getSlot";
const searchFreeSlotsForClinicStore = SearchFreeSlotsforClinic();

const unitListStore = useGetUnitsList();
import { toTitleCase } from "@/utils/formatString";
const getSlotStore = useGetSlot();
const router = useRouter();

onMounted(async () => {
  console.log("hi");
});
</script>
