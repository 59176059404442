<template>
  <div class="mt-3 flex flex-col">
    <provisionInfos />
    <clinicAndDoctorInfos v-if="!medulaStore.appointmentsApplication" />
    <div v-if="medulaStore.appointmentsApplication">
      <div class="flex">
        <p class="w-16">Doktor:</p>
        <p>{{ medulaStore.appointmentsApplicationDatas.doctor_name }}</p>
      </div>
      <div class="flex">
        <p class="w-16">Birim:</p>
        <p>
          {{
            _.startCase(
              _.toLower(medulaStore.appointmentsApplicationDatas.unit_name)
            )
          }}
        </p>
      </div>
    </div>
    <div class="flex gap-10">
      <button
        @click="medulaStore.resetDropdowns"
        class="focus:outline-double bg-white text-red-600 border-[1px] border-red-600 focus:outline-1 focus:outline-[#277dd2] ml-auto py-2 px-4 rounded-md font-medium"
      >
        Sıfırla
      </button>
      <button
        v-if="medulaStore.appointmentsApplication"
        @click="medulaStore.appointmentsApplication = null"
        class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] py-2 px-4 rounded-md font-medium"
        :class="
          medulaStore.selectedPatientID
            ? 'bg-white text-red-600 border-[1px] border-red-600 '
            : 'bg-[#D9D9D9] text-white cursor-not-allowed'
        "
      >
        Vazgeç
      </button>

      <button
        @click="getProvision"
        class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] py-2 px-4 rounded-md font-medium"
        :class="
          !isDisabled()
            ? medulaStore.appointmentsApplication
              ? 'bg-white ml-0 text-[#277dd2] border-[1px] border-[#277dd2] '
              : ' bg-white text-[#277dd2] border-[1px] border-[#277dd2] '
            : 'bg-[#D9D9D9] text-white cursor-not-allowed'
        "
        :disabled="isDisabled()"
      >
        Provizyon Al
      </button>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import provisionInfos from "@/modules/medula/provision/components/getProvisionSubs/provisionInfos.vue";
import clinicAndDoctorInfos from "@/modules/medula/provision/components/getProvisionSubs/clinicAndDoctorInfos.vue";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { addPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/addPatientApplicationStore.ts";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";
import { updateTedaviTipiStore } from "@/store/modules/operationsStore/application/updateTedaviTipi.ts";
const updTedaviTipiStore = updateTedaviTipiStore();
const medulaStore = useMedula();
const applicationStore = addPatientApplicationStore();
const applicationState = applicationStore.addPatientApplicationRequest;
const applicationProvisionStore = useApplicationProvisionStore();
const provisionState = applicationProvisionStore.applicationProvisionRequest;

const isDisabled = () => {
  // Eğer medulaStore.appointmentsApplication yoksa veya içeriği boşsa
  if (!medulaStore.appointmentsApplication) {
    // Herhangi biri boşsa true döndür
    if (
      !applicationState.provision_type ||
      !applicationState.treatment_type ||
      !applicationState.tedavi_turu_medula ||
      !applicationState.follow_type ||
      !applicationState.tedavi_tipi ||
      !applicationState.doctor_code ||
      !applicationState.unit_code ||
      !applicationState.branch_code ||
      !medulaStore.selectedPatientID
    ) {
      return true;
    }
  } else {
    // medulaStore.appointmentsApplication varsa
    // Herhangi biri boşsa true döndür
    if (
      !applicationState.provision_type ||
      !applicationState.treatment_type ||
      !applicationState.tedavi_turu_medula ||
      !applicationState.follow_type ||
      !applicationState.tedavi_tipi ||
      !medulaStore.selectedPatientID
    ) {
      return true;
    }
  }
  // Hepsi doluysa veya medulaStore.appointmentsApplication varsa
  return false;
};

const getProvision = async () => {
  if (!medulaStore.appointmentsApplication) {
    applicationState.patient_id = medulaStore.selectedPatientID;
    console.log("Provision", applicationState);
    await applicationStore.addPatientApplication(applicationState);
    await applicationProvisionStore.getApplicationProvisions(provisionState);
  } else {
    console.log("update et");
    await updTedaviTipiStore.updateTedaviTipi(
      medulaStore.appointmentsApplication,
      applicationState.treatment_type,
      applicationState.follow_type,
      applicationState.provision_type,
      applicationState.tedavi_tipi
    );
    await applicationProvisionStore.getApplicationProvisions(provisionState);
  }
};
</script>
