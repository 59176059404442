import { defineStore } from "pinia";
export const useDentalChartPlanStore = defineStore("dentalChartPlanStore", {
  id: "dentalChartPlanStore",
  state: () => ({
    examinationPlans: {
      activePlans: [
        {
          planName: "Plan 1",
          planStages: [
            {
              stageName: "Aşama 1",
              stageProcessesInfos: [
                {
                  processName: "Oklüzal film, her biri",
                  processSutCode: "401050",
                  toothNumbers: [1, 2, 3, 4, 5, 6, 7, 8],
                  price: 1400,
                },
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
            {
              stageName: "Aşama 2",
              stageProcessesInfos: [
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
          ],
        },
        {
          planName: "Plan 2",
          planStages: [
            {
              stageName: "Başlangıç Aşaması",
              stageProcessesInfos: [
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401050",
                  toothNumbers: [1, 2, 3, 4, 5, 6, 7, 8],
                  price: 1400,
                },
                {
                  processName: "Protez Temizliği",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
            {
              stageName: "İleri Aşama",
              stageProcessesInfos: [
                {
                  processName: "Kanal Tedavisi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
          ],
        },
      ],
      remainingPlans: [],
      completedPlans: [],
    },
    planStatusTab: "activePlans",
    activePlanTab: 0,
    openedStage: 0,
    staticExaminationPlans: {
      activePlans: [
        {
          planName: "Plan 1",
          planStages: [
            {
              stageName: "Aşama 1",
              stageProcessesInfos: [
                {
                  processName: "Oklüzal film, her biri",
                  processSutCode: "401050",
                  toothNumbers: [1, 2, 3, 4, 5, 6, 7, 8],
                  price: 1400,
                },
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
            {
              stageName: "Aşama 2",
              stageProcessesInfos: [
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
          ],
        },
        {
          planName: "Plan 2",
          planStages: [
            {
              stageName: "Başlangıç Aşaması",
              stageProcessesInfos: [
                {
                  processName: "Diş Hekim Muayenesi",
                  processSutCode: "401050",
                  toothNumbers: [1, 2, 3, 4, 5, 6, 7, 8],
                  price: 1400,
                },
                {
                  processName: "Protez Temizliği",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
            {
              stageName: "İleri Aşama",
              stageProcessesInfos: [
                {
                  processName: "Kanal Tedavisi",
                  processSutCode: "401060",
                  toothNumbers: [1, 2, 6, 7, 8],
                  price: 1000,
                },
              ],
            },
          ],
        },
      ],
      remainingPlans: [],
      completedPlans: [],
    },
  }),
  getters: {},
  actions: {},
});
