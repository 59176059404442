<template>
  <div class="flex mb-4">
    <button
      @click="radiologyRontgenStore.radiologyHekimListesiSlider = 1"
      :class="
        radiologyRontgenStore.radiologyHekimListesiSlider === 1
          ? 'activeButton'
          : 'passiveButton'
      "
      class="h-[40px] w-[135px] focus:outline-none"
    >
      <p
        :class="
          radiologyRontgenStore.radiologyHekimListesiSlider === 1
            ? 'text-[#252C32]'
            : 'text-[#6F6F6F]'
        "
      >
        Hekim Listesi
      </p>
    </button>
    <button
      :class="
        radiologyRontgenStore.radiologyHekimListesiSlider === 2
          ? 'activeButton'
          : 'passiveButton'
      "
      @click="radiologyRontgenStore.radiologyHekimListesiSlider = 2"
      class="h-[40px] w-[150px] focus:outline-none"
    >
      <p
        :class="
          radiologyRontgenStore.radiologyHekimListesiSlider === 2
            ? 'text-[#252C32]'
            : 'text-[#6F6F6F]'
        "
      >
        Teknisyen Listesi
      </p>
    </button>
    <button
      :class="
        radiologyRontgenStore.radiologyHekimListesiSlider === 3
          ? 'activeButton'
          : 'passiveButton'
      "
      @click="radiologyRontgenStore.radiologyHekimListesiSlider = 3"
      class="h-[40px] w-[150px] focus:outline-none"
    >
      <p
        :class="
          radiologyRontgenStore.radiologyHekimListesiSlider === 3
            ? 'text-[#252C32]'
            : 'text-[#6F6F6F]'
        "
      >
        Cihaz Listesi
      </p>
    </button>
  </div>
</template>

<script setup>
import { useRadiologyRontgenStore } from "@/modules/radiology/radiologyRontgen/store/radiologyRontgenStore";
const radiologyRontgenStore = useRadiologyRontgenStore();
</script>

<style scoped>
.activeButton {
  border: 2px #c6dcf5 solid;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.passiveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(198, 220, 245, 0.4);
  border-radius: 5px;
}
@media (min-height: 1024px) {
  .mediaQueryHeight {
    height: 410px;
  }
}
.endodontiCardBG {
  background: rgba(37, 44, 50, 0.1);
  border: 1px solid #252c32;
  border-radius: 10px;
}
input.largerCheckbox {
  width: 20px;
  height: 20px;
}
</style>