<template>
  <div class="mt-auto  flex h-[70px] w-full rounded-[30px] bg-[#F1F1F1]">
    <div class="ml-[250px] my-auto flex h-full">

        <button
        @click="toggleDownBar(0)"
          :class="selectedButtonRef === 0 && 'bg-white'"
          class="w-[132px] h-[60px] my-auto rounded-[30px]"
        >
          <p :class="selectedButtonRef === 0 ?'text-[#4338CA] font-medium':'text-black font-light'">Aranacaklar</p>
        </button>
        <button
        @click="toggleDownBar(1)"
          :class="selectedButtonRef === 1 && 'bg-white'"
          class="w-[132px] h-[60px] my-auto rounded-[30px]"
        >
          <p :class="selectedButtonRef === 1 ?'text-[#4338CA] font-medium':'text-black font-light'">5.sınıf</p>
        </button>
        <button
        @click="toggleDownBar(2)"
          :class="selectedButtonRef === 2 && 'bg-white'"
          class="w-[132px] h-[60px] my-auto rounded-[30px]"
        >
          <p :class="selectedButtonRef === 2 ?'text-[#4338CA] font-medium':'text-black font-light'">4.sınıf</p>
        </button>
        <button
        @click="toggleDownBar(3)"
          :class="selectedButtonRef === 3 && 'bg-white'"
          class="w-[132px] h-[60px] my-auto rounded-[30px]"
        >
          <p :class="selectedButtonRef === 3 ?'text-[#4338CA] font-medium':'text-black font-light'">Asistan Listesi</p>
        </button>
        <button
        @click="toggleDownBar(4)"
          :class="selectedButtonRef === 4 && 'bg-white'"
          class="w-[132px] h-[60px] my-auto rounded-[30px]"
        >
          <p :class="selectedButtonRef === 4 ?'text-[#4338CA] font-medium':'text-black font-light'">İmplant Üstü</p>
        </button>
    
        <button class="ml-5"><img :src="plusCircleBlue" alt="" /></button>
    </div>
  </div>
</template>


<script setup>
import plusCircleBlue from "../assets/plusCircleBlue.svg";
import { ref } from "vue";
const selectedButtonRef = ref(0);

const toggleDownBar = (buttonNum) =>{
    selectedButtonRef.value=buttonNum
}
</script>