<template>
  <div
    class="px-4 pt-4 pb-16 text-white"
    style="background: linear-gradient(to bottom, #3183d4, #81b3e5)"
  >
    <div class="flex items-center">
      <img
        :src="logoWhite"
        alt="logo"
        class="cursor-pointer"
        @click="announcementsStore.isSidebarOpen = true"
      />
    </div>
    <div class="flex items-center ml-20 mt-6">
      <img :src="announcement" alt="announcement" class="mr-10" />
      <div class="flex flex-col gap-4">
        <h2 class="text-4xl font-semibold">Duyurular</h2>
        <div
          class="flex gap-3 bg-white py-1 pl-2 rounded-full text-black w-[450px]"
        >
          <img :src="search" alt="search" />
          <input
            type="text"
            class="w-10/12 outline-none py-2"
            placeholder="Ara"
          />
        </div>
      </div>
    </div>
    <img :src="circles" alt="circles" class="absolute right-0" />
  </div>
</template>

<script setup>
import logoWhite from "@/modules/personel/assets/logoWhite.svg";
import circles from "../assets/circles.svg";
import announcement from "../assets/announcement.svg";
import search from "../assets/search.svg";
import { useAnnouncements } from "@/mainPage/store/announcementStore";
const announcementsStore = useAnnouncements();
</script>
