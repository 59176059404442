<template>
  <header
    class="bg-white justify-between py-3 px-5 flex items-center border-b-[0.5px] border-[#D9D9D9] w-full"
  >
    <div class="flex gap-8">
      <button
        @click="
          router.push(`/polyclinic/anamneses/${encodeURIComponent(unitID)}`)
        "
        class="font-medium flex gap-4 py-1 px-2 items-center"
      >
        <img :src="leftArrow" alt="" class="w-5" />
        <p class="text-lg">Geri</p>
      </button>
      <div class="flex items-center gap-3">
        <button class="opacity-40">Anamnez İşlemleri</button>
        <img class="opacity-40" :src="rightArrowHead" alt="rightArrowHead" />
        <button class="font-medium">
          {{
            title ||
            anamnesisTemplateStore.getAnamnesisResponse?.data.attributes
              .template_name
          }}
        </button>
      </div>
    </div>

    <div class="flex">
      <button class="mr-[40px]">
        <img :src="menuIcon" alt="" @click="router.push('/mainPage')" />
      </button>
      <img :src="profileImg" alt="profile image" class="mr-2" />
      <button @click="arrowRef = !arrowRef" class="flex flex-col relative">
        <div class="flex gap-2 items-center">
          <p>
            {{ personelStoreData.personel.name }}
            {{ personelStoreData.personel.surname }}
          </p>
          <img
            :src="dropDownArrow"
            class="h-3 w-3 transform transition-transform duration-200 ease-in-out"
            :class="arrowRef ? 'rotate-180' : 'rotate-0'"
          />
        </div>
        <p class="text-[#00000080] text-xs">
          {{ personelStoreData.personel.role }}
        </p>
        <button
          @click="handleLogout"
          @blur="arrowRef = false"
          v-if="arrowRef"
          class="w-28 z-[999] cursor-pointer py-1 pl-2 pr-4 text-white bg-[#707070] rounded absolute right-0 -bottom-4"
        >
          <p>Çıkış Yap</p>
        </button>
      </button>
    </div>
  </header>
</template>

<script setup>
const props = defineProps({
  title: String,
});
import { ref } from "vue";
import rightArrowHead from "@/modules/doctorScreen/assets/rightArrowHead.svg";
import menuIcon from "@/modules/personel/assets/menu_icon.svg";
import profileImg from "@/modules/personel/assets/profile-img.svg";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions.ts";
const personelStoreAuthActions = personelAuthActions();
import { useRouter } from "vue-router";
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;
import { personelStore } from "@/store/modules/administratorManagementStore/personel.ts";
import dropDownArrow from "@/modules/asistanEkrani/registeredPatientList/assets/dropDownArrow.svg";
import leftArrow from "@/modules/personel/assets/leftArrow.svg";
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();

const arrowRef = ref(false);

const personelStoreData = personelStore();

const handleLogout = () => {
  personelStoreAuthActions.logout();
};

//new patient logic

import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
const bilesikKisiStore = bilesikKisiActions();
</script>

<style scoped>
.headerText {
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
