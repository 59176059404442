import { defineStore } from "pinia";
export const useEkOdemeStore = defineStore("useEkOdemeStore", {
  id: "useEkOdemeStore",
  state: () => ({
    ekOdemeButtonRef: 1,
    monthFilterValue: "Ocak",
    temporaryDataForEkOdemeTable: [
      {
        birim: "Endodonti",
        puan: "500",
        gelireDonusen: "250 TL",
        mesaiIciPuan: "800",
        mesaiIciGelireDonusen: "800 TL",
        mesaiDisiPuan: "1500",
        mesaiDisiGelireDonusen: "1400 TL",
      },
      {
        birim: "Periodontoloji",
        puan: "500",
        gelireDonusen: "250 TL",
        mesaiIciPuan: "800",
        mesaiIciGelireDonusen: "800 TL",
        mesaiDisiPuan: "1500",
        mesaiDisiGelireDonusen: "1400 TL",
      },
      {
        birim: "Pedodonti",
        puan: "500",
        gelireDonusen: "250 TL",
        mesaiIciPuan: "800",
        mesaiIciGelireDonusen: "800 TL",
        mesaiDisiPuan: "1500",
        mesaiDisiGelireDonusen: "1400 TL",
      },
      {
        birim: "Radyoloji",
        puan: "500",
        gelireDonusen: "250 TL",
        mesaiIciPuan: "800",
        mesaiIciGelireDonusen: "800 TL",
        mesaiDisiPuan: "1500",
        mesaiDisiGelireDonusen: "1400 TL",
      },
      {
        birim: "Restoratif",
        puan: "500",
        gelireDonusen: "250 TL",
        mesaiIciPuan: "800",
        mesaiIciGelireDonusen: "800 TL",
        mesaiDisiPuan: "1500",
        mesaiDisiGelireDonusen: "1400 TL",
      },
      {
        birim: "Oral Diagnoz",
        puan: "500",
        gelireDonusen: "250 TL",
        mesaiIciPuan: "800",
        mesaiIciGelireDonusen: "800 TL",
        mesaiDisiPuan: "1500",
        mesaiDisiGelireDonusen: "1400 TL",
      },
    ],
    isDetayGorButtonActive: false,
  }),
});
