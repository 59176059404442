<template>
  <div
    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white p-3 border shadow-lg w-[600px] placeholder:opacity-50 placeholder:text-black rounded-lg flex flex-col justify-center items-center"
  >
    <p class="text-center">
      {{
        appointmentBookStore.deletingAppointmentFromList.attributes.name +
        " " +
        appointmentBookStore.deletingAppointmentFromList.attributes.surname
      }}
      adlı hastanın
      <span class="text-[#277DD2]">{{
        new Date(
          appointmentBookStore.deletingAppointmentFromList.attributes.start_time
        ).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span>
      tarihli randevusunu iptal etmek istediğinize emin misiniz?
    </p>

    <div class="flex gap-4">
      <button
        @click="appointmentBookStore.deletingAppointmentFromList = null"
        class="text-black bg-[#E3E3E3] w-20 h-8 rounded-2xl focus:outline-none"
      >
        Hayır
      </button>

      <button
        @click="handleDelete()"
        class="bg-[#277DD2] text-white w-20 h-8 rounded-2xl focus:outline-none"
      >
        Evet
      </button>
    </div>
  </div>
</template>

<script setup>
import { useAppointmentBook } from "./appointmentBookStore";
const appointmentBookStore = useAppointmentBook();
import { useDeleteVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useDeleteVEMAppointment";
const deleteVEMAppointmentStore = useDeleteVEMAppointment();

const handleDelete = async () => {
  await deleteVEMAppointmentStore.deleteVEMAppointment(
    appointmentBookStore.deletingAppointmentFromList.attributes.appointment_id
  );
  appointmentBookStore.deletingAppointmentFromList = null;
};
</script>
