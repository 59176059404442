import { createRouter, createWebHistory } from "vue-router";
import dentalChartView from "../../modules/dentalChart/dentalChartMain/view/dentalChartView.vue";
import diagnosisView from "../../modules/dentalChart/dentalChartMain/view/diagnosisView.vue";
import tedaviPlanlamaView from "../../modules/dentalChart/dentalChartTedaviPlanlama/view/tedaviPlanlamaView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/dental-chart",
    name: "dentalChartView",
    component: dentalChartView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/dental-chart/tedavi-planlama",
    name: "tedaviPlanlamaView",
    component: tedaviPlanlamaView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/examinations/:patientID/diagnosis",
    name: "diagnosisView",
    component: diagnosisView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
