<template>
  <div
    class="mx-auto w-[850px] bg-white py-2 border-2 border-l-[10px] border-l-[#287dd2] rounded-[10px] h-fit"
  >
    <div class="pl-6 flex flex-col">
      <input
        class="font-medium text-lg w-full mb-3 bg-transparent"
        type="text"
        placeholder="Sorunuzu giriniz..."
        v-model="
          anamnesisStore.sections[anamnesisStore.activeSection].formElements[
            props.index
          ].question
        "
      />
      <div class="flex flex-col gap-2 text-sm">
        <div
          class="flex items-center gap-2"
          v-for="(option, index) in anamnesisStore.sections[
            anamnesisStore.activeSection
          ].formElements[props.index].options"
        >
          <input class="w-5 h-5" type="checkbox" disabled :id="index" />
          <input
            class="w-[160px] bg-transparent"
            type="text"
            v-model="
              anamnesisStore.sections[anamnesisStore.activeSection]
                .formElements[props.index].options[index]
            "
            placeholder="Seçeneğinizi giriniz..."
          />
          <button @click="removeOption(index)">
            <img class="w-[10px]" :src="cross" alt="" />
          </button>
        </div>
        <button
          class="flex items-center gap-2 mt-1 ml-[2px] w-fit"
          @click="addOption()"
        >
          <img class="w-4" :src="plusBlueIcon" alt="" />
          <p class="text-[#277dd2]">Seçenek ekle</p>
        </button>
      </div>
    </div>
    <div class="border-t flex">
      <div class="px-2 pt-2 flex divide-x-2 ml-auto items-center">
        <div class="pr-4 flex gap-3">
          <button @click="cloneFormElement">
            <img class="w-6 h-6 my-auto" :src="filesGray" alt="" />
          </button>
          <button @click="removeFormElement">
            <img class="w-6 h-6 my-auto" :src="trashBinGray" alt="" />
          </button>
        </div>
        <div class="pl-4 flex gap-5 items-center">
          <p class="text-lg">Gerekli</p>
          <button
            class="flex h-6 w-12 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              anamnesisStore.sections[anamnesisStore.activeSection]
                .formElements[props.index].isRequired
                ? 'bg-[#026D4D]'
                : 'bg-gray-300'
            "
            @click="toggleIsRequired()"
          >
            <div
              class="h-5 w-5 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{
                'translate-x-5':
                  anamnesisStore.sections[anamnesisStore.activeSection]
                    .formElements[props.index].isRequired,
              }"
            ></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import cross from "@/modules/medula/provision/assets/crossIcon.svg";
import trashBinGray from "@/modules/anamnezForm/assets/trashBinGray.svg";
import filesGray from "@/modules/anamnezForm/assets/filesGray.svg";
import plusBlueIcon from "@/modules/medula/provision/assets/plusBlueIcon.svg";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();

const props = defineProps({
  index: Number,
});

const removeFormElement = () => {
  anamnesisStore.sections[anamnesisStore.activeSection].formElements.splice(
    props.index,
    1
  );
};

const cloneFormElement = () => {
  const clonedFormElement = JSON.parse(
    JSON.stringify(
      anamnesisStore.sections[anamnesisStore.activeSection].formElements[
        props.index
      ]
    )
  );
  anamnesisStore.sections[anamnesisStore.activeSection].formElements.splice(
    props.index + 1,
    0,
    clonedFormElement
  );
};

const toggleIsRequired = () => {
  anamnesisStore.sections[anamnesisStore.activeSection].formElements[
    props.index
  ].isRequired =
    !anamnesisStore.sections[anamnesisStore.activeSection].formElements[
      props.index
    ].isRequired;
};

const addOption = () => {
  anamnesisStore.sections[anamnesisStore.activeSection].formElements[
    props.index
  ].options.push("");
};

const removeOption = (index) => {
  anamnesisStore.sections[anamnesisStore.activeSection].formElements[
    props.index
  ].options.splice(index, 1);
};
</script>
