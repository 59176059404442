<template>
  <div class="mt-2 flex gap-3 items-center">
    <actApplicationsDateComponent />
  </div>
  <ul
    tabindex="0"
    class="max-h-[200px] h-full flex-grow flex flex-col gap-4 overflow-y-auto pt-[15px] focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
    :class="
      medulaStore.medulaProvizyonButtonVModel.provizyonTipi.id === 'V'
        ? 'max-h-[130px]'
        : 'max-h-[180px]'
    "
  >
    <div
      v-for="application in RegisteredPatientApplicationStore
        .listPatientApplicationResponse?.applications.data"
      :key="application"
      class="relative bg-[#fbfbfb] w-full px-3 py-[12px] flex items-center gap-4 border rounded-lg"
    >
      <div class="flex gap-3">
        <p class="text-3xl font-medium">
          {{ getUTCDay(application.attributes.created_at) }}
        </p>
        <div>
          <p class="opacity-70 text-2xl">
            {{ getTurkishDayName(application.attributes.created_at) }}
          </p>
          <p class="opacity-70">
            {{ getUTCMonth(application.attributes.created_at) }}
            {{ getUTCYear(application.attributes.created_at) }}
          </p>
          <p class="font-medium opacity-90">
            {{ getUTCHours(application.attributes.created_at) }}:{{
              getUTCMinutes(application.attributes.created_at)
            }}
          </p>
        </div>
      </div>
      <div class="h-28 w-[2px] bg-[#D9D9D9] inline-block">&nbsp;</div>
      <div class="flex flex-col gap-[6px]">
        <div class="flex items-center">
          <p class="w-28">Takip No</p>
          <p
            class="font-medium max-w-[186px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.takip_no }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-28">Başvuru No</p>
          <p
            class="font-medium max-w-[186px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.medula_basvuru_no }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-28">Kabul No</p>
          <p
            class="font-medium max-w-[186px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.application_protocol_number }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-28">Tedavi Tipi</p>
          <p
            class="font-medium max-w-[186px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.tedavi_tipi_name }}
          </p>
        </div>
      </div>
      <div class="h-28 w-[2px] bg-[#D9D9D9] inline-block">&nbsp;</div>
      <div class="flex flex-col gap-[6px]">
        <div class="flex items-center">
          <p class="w-[120px]">Kabul Tipi</p>
          <p
            class="font-medium max-w-[184px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.acceptance_type }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-[120px]">Provizyon Tipi</p>
          <p
            class="font-medium max-w-[184px] whitespace-nowrap overflow-x-hidden"
          >
            {{ parseProvizyonTipi(application.attributes.provizyon_tipi) }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-[120px]">Birim</p>
          <p
            class="font-medium max-w-[184px] whitespace-nowrap overflow-x-hidden"
          >
            {{ _.startCase(_.toLower(application.attributes.unit_name)) }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-[120px]">Kurum Bilgisi</p>
          <p
            class="font-medium max-w-[184px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.devredilen_kurum }}
          </p>
        </div>
      </div>
      <div class="h-28 w-[2px] bg-[#D9D9D9] inline-block">&nbsp;</div>
      <div class="flex flex-col gap-[6px]">
        <div class="flex items-center">
          <p class="w-[120px]">Hekim Adı</p>
          <p
            class="font-medium max-w-[167px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.doctor_name }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-[120px]">Geliş Sebebi</p>
          <p
            class="font-medium max-w-[167px] whitespace-nowrap overflow-x-hidden"
          ></p>
        </div>
        <div class="flex items-center">
          <p class="w-[120px]">Tedavi Türü</p>
          <p
            class="font-medium max-w-[167px] whitespace-nowrap overflow-x-hidden"
          >
            {{ _.startCase(_.toLower(application.attributes.treatment_type)) }}
          </p>
        </div>
        <div class="flex items-center">
          <p class="w-[120px]">Kabul Durumu</p>
          <p
            class="font-medium max-w-[180px] whitespace-nowrap overflow-x-hidden"
          >
            {{ application.attributes.application_status }}
          </p>
        </div>
      </div>
      <div
        class="font-medium focus:outline-none absolute -top-4 right-0 rounded-lg px-3 py-1 flex"
        :class="
          false
            ? 'text-[#5CB85C] bg-[#5CB85C] bg-opacity-20'
            : 'text-[#D9534F] bg-[#D9534F] bg-opacity-20'
        "
      >
        <p>Fatura ödenmedi</p>
      </div>
    </div>
  </ul>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useMedula } from "../../medulaStore/medulaStore";
import actApplicationsDateComponent from "./actApplicationsDateComponent.vue";
import { registeredPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/getPatientApplicationRegistrationList";
const RegisteredPatientApplicationStore = registeredPatientApplicationStore();
import moment from "moment";

import _ from "lodash";
import {
  parseProvizyonTipi,
  parseTedaviTipi,
} from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientListParsingData";
import { useSearchPatientApplications } from "@/store/modules/operationsStore/patient/useSearchPatientApplications";
const medulaStore = useMedula();
const searchPatientApplicationsStore = useSearchPatientApplications();

watch(
  () => medulaStore.applicationDateRange[0],
  async (newValue, oldValue) => {
    console.log("CALİSTİ!");
    await RegisteredPatientApplicationStore.getRegisteredPatientApplications(
      1,
      50,
      moment(medulaStore.applicationDateRange[0]).format("YYYY-MM-DD"),
      moment(medulaStore.applicationDateRange[1]).format("YYYY-MM-DD"),
      "",
      "",
      "",
      "",
      "",
      ""
    );
  }
);

function parseUTCDate(utcDateString) {
  try {
    const date = new Date(utcDateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    // İstanbul için saat farkını ekle (UTC+3)
    // const istanbulOffset = 3 * 60 * 60 * 1000; // 3 saatlik milisaniye cinsinden fark
    // const istanbulDate = new Date(date.getTime() + istanbulOffset);

    return date;
  } catch (error) {
    console.error("Error parsing UTC date to Istanbul time:", error);
    return null;
  }
}

function getTurkishDayName(utcDateString) {
  const turkishDays = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  const date = parseUTCDate(utcDateString);
  const dayIndex = date ? date.getUTCDay() : null;

  return dayIndex !== null ? turkishDays[dayIndex] : null;
}

function getUTCYear(utcDateString) {
  const date = parseUTCDate(utcDateString);
  return date ? date.getUTCFullYear() : null;
}

function getUTCMonth(utcDateString) {
  const turkishMonths = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const date = parseUTCDate(utcDateString);
  const monthIndex = date ? date.getUTCMonth() : null;

  return monthIndex !== null ? turkishMonths[monthIndex] : null;
}

function getUTCDay(utcDateString) {
  const date = parseUTCDate(utcDateString);
  return date ? date.getUTCDate() : null;
}

function getUTCHours(utcDateString) {
  const date = parseUTCDate(utcDateString);
  return date ? date.getHours().toString().padStart(2, "0") : null;
}

function getUTCMinutes(utcDateString) {
  const date = parseUTCDate(utcDateString);
  const minutes = date ? date.getUTCMinutes() : null;

  return minutes !== null ? minutes.toString().padStart(2, "0") : null;
}
</script>
