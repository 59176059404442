<template>
  <div class="flex w-full h-full">
    <protezSidebar :activeRoute="1" />
    <div
      class="flex flex-col w-full h-screen items-center text-2xl text-[#526581]"
    >
      <div class="font-semibold text-center mt-12">
        <h1 class="mb-2">AĞIZ DİŞ SAĞLIĞI UYGULAMA VE ARAŞTIRMA MERKEZİ</h1>
        <h2 class="text-2xl">DİŞ PROTEZİ</h2>
      </div>
      <div class="w-full flex flex-col justify-center h-full gap-20">
        <div class="flex justify-around">
          <button
            @click="router.push('/protez/createOrders')"
            class="focus:outline-none p-6 w-[300px] h-[300px] border rounded-lg flex items-center justify-center flex-col"
          >
            <img :src="createOrderIcon" alt="createOrderIcon" />
            <h3 class="text-3xl text-center font-semibold mt-4">
              Yeni sipariş oluştur
            </h3>
          </button>
          <button
            @click="router.push('/protez/workorders')"
            class="focus:outline-none p-6 w-[300px] h-[300px] border rounded-lg flex items-center justify-center flex-col"
          >
            <img :src="myOrdersIcon" alt="myOrdersIcon" />
            <h3 class="text-3xl text-center font-semibold mt-4">
              Siparişlerim
            </h3>
          </button>
          <button
            @click="router.push('/protezlab')"
            class="focus:outline-none p-6 w-[300px] h-[300px] border rounded-lg flex items-center justify-center flex-col"
          >
            <img :src="myAccountIcon" alt="myAccountIcon" />
            <h3 class="text-3xl text-center font-semibold mt-4">Hesabım</h3>
          </button>
        </div>
        <div class="flex justify-center gap-[275px]">
          <button
            @click="router.push('/protez/orders')"
            class="focus:outline-none p-6 w-[300px] h-[300px] border rounded-lg flex items-center justify-center flex-col"
          >
            <img :src="orderProcessIcon" alt="orderProcessIcon" />
            <h3 class="text-3xl text-center font-semibold mt-4">
              Sipariş süreci hakkında
            </h3>
          </button>
          <button
            @click="handleLogout"
            class="focus:outline-none p-6 w-[300px] h-[300px] border rounded-lg flex items-center justify-center flex-col"
          >
            <img :src="exitIcon" alt="exitIcon" />
            <h3 class="text-3xl text-center font-semibold mt-4">Çıkış yap</h3>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import protezSidebar from "@/modules/protez/components/protezSidebar.vue";
import createOrderIcon from "../assets/createOrderIcon.svg";
import exitIcon from "../assets/exitIcon.svg";
import myAccountIcon from "../assets/myAccountIcon.svg";
import myOrdersIcon from "../assets/myOrdersIcon.svg";
import orderProcessIcon from "../assets/orderProcessIcon.svg";
import { useRouter } from "vue-router";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions.ts";
const personelStoreAuthActions = personelAuthActions();
const router = useRouter();

const handleLogout = () => {
  personelStoreAuthActions.logout();
};
</script>
