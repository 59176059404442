import { createRouter, createWebHistory } from "vue-router";
import PersonelLoginPage from "@/modules/personel/personelLogin/PersonelLoginPage.vue";
import PersonelRegisterPage from "../../modules/personel/personelRegister/PersonelRegisterPage.vue";
import PersonelVerifyAccountPage from "../../modules/personel/personelLogin/PersonelVerifyAccountPage.vue";
import PersonelSmsAndEmailVerifyPage from "../../modules/personel/personelLogin/PersonelSmsAndEmailVerifyPage.vue";
import PersonelHomePage from "../../modules/personel/view/PersonalHomePage.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/personel/register",
    name: "PersonelRegisterPage",
    component: PersonelRegisterPage,
  },
  {
    path: "/personel/loginPage",
    name: "PersonelLoginPage",
    component: PersonelLoginPage,
  },
  {
    path: "/personel/verifyAccount",
    name: "PersonelVerifyAccountPage",
    component: PersonelVerifyAccountPage,
  },
  {
    path: "/personel/verifyAccount/enterCode",
    name: "PersonelSmsAndEmailVerifyPage",
    component: PersonelSmsAndEmailVerifyPage,
  },
  {
    path: "/personel/homePage",
    name: "PersonelHomePage",
    component: PersonelHomePage,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
