import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { userInfo } from "@/modules/medula/addPatient/components/userInfoReactiveTypes";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useGetCities = defineStore({
  id: "useGetCities",
  state: () => ({
    getListCitiesResponse: null,
  }),
  actions: {
    async getCities() {
      const personelStoreData = personelStore();

      await axios
        .get(`${clinicDomain}/provinces`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("useGetCities.useGetCities:", response);

          const transformedData = response.data.map((city, index) => {
            return {
              ...city,
              id: city.il_adi,
              text: city.il_adi,
            };
          });

          // Yeniden biçimlendirilmiş veriyi state'e atama
          this.getListCitiesResponse = transformedData;
        });
    },
  },
});
