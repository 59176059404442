import { reactive } from "vue";

export const doctorInfo = reactive({
  doctorInfos: null,
  personalInfo: [
    { label: "AD", value: "" },
    { label: "SOYAD", value: "" },
    { label: "TC NO", value: "" },
    { label: "CİNSİYET", value: "" },
    { label: "MEDENİ HALİ", value: "" },
    { label: "DOĞUM YERİ", value: "" },
    { label: "DOĞUM TARİHİ", value: "" },
  ],
  contactInfo: [
    { label: "EV TELEFONU", value: "" },
    { label: "CEP TELEFONU", value: "" },
    { label: "E-POSTA", value: "" },
    { label: "İL", value: "" },
    { label: "İLÇE", value: "" },
    {
      label: "ADRES",
      value: "",
    },
  ],
  workInfo: [
    { label: "UNVAN", value: "" },
    { label: "UNVAN GRUBU", value: "" },
    { label: "BÖLÜM", value: "" },
    { label: "KADRO YERİ", value: "" },
    { label: "BAŞLAMA TARİHİ", value: "" },
  ],
  hizmetler: [
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Yapıldı",
      toplam: 100,
    },
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Yapıldı",
      toplam: 100,
    },
  ],
  doctorGelireDonusenHizmetTablosu: [
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Yapıldı",
      toplam: "300 işlem puanı",
    },
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Yapıldı",
      toplam: "300 işlem puanı",
    },
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Yapıldı",
      toplam: "300 işlem puanı",
    },
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Yapıldı",
      toplam: "300 işlem puanı",
    },
  ],
  doctorGelireDonusmemisHizmetTablosu: [
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Bulunamadı",
      toplam: "300 işlem puanı",
    },
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Bulunamadı",
      toplam: "300 işlem puanı",
    },
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Bulunamadı",
      toplam: "300 işlem puanı",
    },
    {
      tarih: "01/01/2021",
      sutKodu: "401010",
      islemSayisi: "15",
      islemPuani: "200",
      hizmetTuru: "Diş Hekimi Muayenesi",
      ucret: "500 TL",
      hizmetKaydi: "Bulunamadı",
      toplam: "300 işlem puanı",
    },
  ],
});
