import { createRouter, createWebHistory } from "vue-router";
import radiologyHomeView from "@/modules/radiology/radiologyMain/view/radiologyHomeView.vue";
import radiologyCalendarView from "@/modules/radiology/radiologyMain/view/radiologyCalendarView.vue";
import radiologyRontgenView from "@/modules/radiology/radiologyRontgen/view/radiologyRontgenView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/radiology",
    name: "radiologyHomeView",
    component: radiologyHomeView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/radiology/calendar",
    name: "radiologyCalendarView",
    component: radiologyCalendarView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/radiology/radiologyRontgenView",
    name: "radiologyRontgenView",
    component: radiologyRontgenView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
