import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "../../administratorManagementStore/personel";

export const useCancelDoctorSetting = defineStore({
  id: "cancelDoctorSetting",

  actions: {
    async cancelDoctorSetting(settingID) {
      globalStore.customInfoCard = true;
      globalStore.customInfoType = "pending";
      const personelStoreData = personelStore();

      await axios
        .delete(
          `${clinicDomain}/doctor-settings/${settingID}`,

          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("cancelDoctorSetting", response.data);
            setTimeout(() => {
              globalStore.customInfoCard = false;
              globalStore.customInfoType = "";
            }, 2000);

            globalStore.customInfoType = "success";
          }
        })
        .catch((error) => {
          console.error("Error cancelDoctorSetting", error);

          setTimeout(() => {
            globalStore.customInfoCard = false;
            globalStore.customInfoType = "";
          }, 2000);

          globalStore.customInfoType = "fail";
        });
    },
  },
});
