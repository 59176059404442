import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { authToken, patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import { useAddSlot } from "@/store/modules/operationsStore/slot/useAddSlot";
import { personelStore } from "../../administratorManagementStore/personel";


export const useAddDoctorSetting = defineStore({
  id: "useAddDoctorSetting",

  actions: {
    async addDoctorSetting(requestBody) {
      globalStore.customInfoCard = true;
      globalStore.customInfoType = "pending";
      const personelStoreData = personelStore();

      await axios
        .post(
          `${clinicDomain}/doctor-settings/create`,

          requestBody,

          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("addDoctorSettings", response);
            useAddSlot().addSlot(response.data.DoctorSettingsID);
            setTimeout(() => {
              globalStore.customInfoCard = false;
              globalStore.customInfoType = "";
            }, 2000);

            globalStore.customInfoType = "success";
            return true;
          }
        })
        .catch((error) => {
          console.error("Error addDoctorSettings", error);
          setTimeout(() => {
            globalStore.customInfoCard = false;
            globalStore.customInfoType = "";
          }, 2000);

          globalStore.customInfoType = "fail";
          return false;
        });
    },
  },
});
