import { ref } from "vue";

const optionTypes = ref([
  { id: "N", text: "N : Normal" },
  { id: "I", text: "I : İş Kazası" },
  { id: "A", text: "A : Acil" },
  { id: "T", text: "T : Trafik kazası" },
  { id: "V", text: "V : Adli vaka" },
  { id: "M", text: "M : Meslek hastalığı" },
  { id: "C", text: "C : Acil hal" },
  { id: "H", text: "H : Bulaşıcı hastalık" },
  { id: "R", text: "R : Koruyucu sağlık" },
  { id: "L", text: "L : Analık" },
  { id: "G", text: "G : Afet savaş grev lokavt" },
  { id: "U", text: "U : Bakıma muhtaç" },
  { id: "D", text: "D : Doğal afet" },
  { id: "S", text: "S : İstisnai hal" },
  { id: "E", text: "E : 3713/21" },
]);

const exceptionalTypes = ref([
  { id: "", text: "İstisnai hal yok" },
  { id: "1", text: "1 : Acil hal" },
  { id: "2", text: "2 : İş kazası ile meslek hastalığı" },
  { id: "3", text: "3 : Bildirimi zorunlu bulaşıcı hastalık" },
  { id: "4", text: "4 : Kişiye yönelik koruyucu sağlık hizmeti" },
  { id: "5", text: "5 : Trafik kazası" },
  { id: "6", text: "6 : Afet ve savaş ile grev ve lokavt hali" },
  { id: "7", text: "7 : 18 yaş altı çocuklar" },
  { id: "8", text: "8 : Tıbben başkasının bakımına muhtaç kişiler" },
  { id: "9", text: "9 : BKK na göre borçlular" },
  { id: "I", text: "I : İş kazası" },
  { id: "M", text: "M : Meslek hastalığı" },
  { id: "B", text: "B : Madde bağımlılığı" },
  { id: "V", text: "V : Acil hal - (Adli vaka kaynaklı başvurular)" },
]);

const fallowypes = ref([
  { id: "N", text: "N : Normal" },
  { id: "E", text: "E : Eşlik eden hastalık" },
  { id: "U", text: "U : Uzayan yatış" },
  { id: "K", text: "K : Komplikasyon" },
  { id: "T", text: "T : Tanı amaçlı günübirlik" },
  { id: "Y", text: "Y : Yoğun bakım" },
  { id: "P", text: "P : Hizmetin iptal olması veya yarım kalması" },
  { id: "S", text: "S : Kurum sevkli kontrol muayenesi" },
  { id: "C", text: "C : Kurum sevkli" },
  { id: "F", text: "F : Faturalanmış uzayan yatış" },
  { id: "B", text: "B : Faturalanmış uzayan yatış ve yoğun bakım" },
]);

const treatmentTypes = ref([
  { id: "0", text: "0 : Normal sorgu" },
  { id: "1", text: "1 : Diyaliz" },
  { id: "2", text: "2 : Fiziksel tedavi ve rehabilitasyon" },
  { id: "4", text: "4 : Kemik iliği" },
  { id: "5", text: "5 : Kök hücre nakli" },
  { id: "6", text: "6 : Ekstrakorporeal fotoferez tedavisi" },
  { id: "7", text: "7 : Hiperbarik oksijen tedavisi" },
  { id: "8", text: "8 : ESWL" },
  { id: "9", text: "9 : Ağız protez tedavisi" },
  { id: "10", text: "10 : Ketem" },
  { id: "11", text: "11 : Tüp bebek 1" },
  { id: "12", text: "12 : Tüp bebek 2" },
  { id: "13", text: "13 : Diş tedavisi" },
  { id: "14", text: "14 : Onkolojik tedavi" },
  { id: "15", text: "15 : Plazmaferez tedavisi" },
  { id: "16", text: "16 : ESWT" },
  { id: "17", text: "17 : Evde bakım hizmeti" },
  { id: "18", text: "18 : Analık hali" },
  { id: "19", text: "19 : İş göremezlik" },
  { id: "20", text: "20 : Ortodontik tedavi" },
  { id: "21", text: "21 : Alkol , madde bağımlılığı tedavisi" },
]);
const treatmentKindTypes = ref([
  { id: "ayaktan", text: "A : Ayaktan tedavi", knidosID: "ayaktan" },
  { id: "yatarak", text: "Y : Yatarak tedavi", knidosID: "yatarak" },
  { id: "günübirlik", text: "G : Günübirlik tedavi", knidosID: "günübirlik" },
  //arkadan boyle geldigi icin A, Y, G yerine degistirildi sorun olursa yeniden degistirilir
]);

const unitTypes = ref(["Unit 1", "Unit 2", "Unit 3", "Unit 4"]);

const assuranceTypes = ref([
  { id: "1", text: "SSK" },
  { id: "2", text: "Bağ-Kur" },
  { id: "3", text: "Emekli Sandığı" },
]);

const priorityTypes = ref([
  { id: "1", text: "Çalışan" },
  { id: "2", text: "Emekli" },
  { id: "3", text: "SSK Kurum Personeli" },
  { id: "4", text: "Diğer" },
]);

const kurumTypes = ref([
  { id: "1", text: "SSK" },
  { id: "2", text: "Bağkur" },
  { id: "3", text: "Emekli Sandığı" },
  { id: "4", text: "Yeşil Kart" },
  { id: "5", text: "Vatansız ve sığınmacılar" },
  {
    id: "6",
    text: "2022 sayılı 65 yaşını doldurmuş muhtaç, güçsüz ve kimsesiz Türk vatandaşlarına aylık bağlanması hakkında kanun hükümlerine göre aylık alan kişiler",
  },
  {
    id: "7",
    text: "1005 sayılı İstiklal Madalyası verilmiş bulunanlara vatani hizmet tertibinden şeref aylığı bağlanması hakkında kanun hükümlerine göre şeref aylığı alan kişiler",
  },
  {
    id: "10",
    text: "2828 sayılı Sosyal Hizmetler ve Çocuk Esirgeme Kurumu kanunu hükümlerine göre korunma, bakım ve rehabilitasyon hizmetlerinden ücretsiz faydalanan kişiler",
  },
  { id: "12", text: "S.G.K." },
  {
    id: "14",
    text: "Mütekabiliyet esası da dikkate alınmak şartıyla, oturma izni almış yabancı ülke vatandaşlarından yabancı bir ülke mevzuatı kapsamında sigortalı olmayan kişiler",
  },
  {
    id: "15",
    text: "25/8/1999 tarihli ve 4447 sayılı Kanun gereğince işsizlik ödeneği ve ilgili kanunları gereğince kısa çalışma ödeneğinden yararlandırılan kişiler",
  },
  { id: "17", text: "60-g isteğe bağlı sigortalılar" },
  { id: "18", text: "18 yaş altı sosyal güvenlik kapsamında olmayan kişiler" },
  { id: "19", text: "Üniversite Yabancı Uyruklu Öğrenciler" },
  { id: "20", text: "Stajyer Avukat" },
  { id: "21", text: "Kapsam Dışı" },
  { id: "99", text: "Yurtdışı sigortalılar" },
  { id: "22", text: "Acil Hal" },
  { id: "23", text: "TBMM" },
  { id: "25", text: "Ceza Infaz ve Tutukevi Kursiyeri" },
  { id: "26", text: "AİHM" },
  { id: "27", text: "60/c1-c3-c9" },
  { id: "28", text: "3713/21" },
  { id: "31", text: "60/f12, f13, f14 kapsamında sigortalı olanlar" },
]);

const insuranceTypes = ref([
  { id: "1", text: "Çalışan" },
  { id: "2", text: "Emekli" },
  { id: "3", text: "SSK Kurum Personeli" },
  { id: "4", text: "Diğer" },
]);

const applicationStatuses = ref([
  { id: "muayene iptal oldu", text: "Muayene iptal oldu" },
  { id: "muayene oldu", text: "Muayene oldu" },
  {
    id: "muayene olmayı bekliyor",
    text: "Muayene olmayı bekliyor",
  },
]);

export {
  optionTypes,
  exceptionalTypes,
  fallowypes,
  treatmentKindTypes,
  treatmentTypes,
  insuranceTypes,
  priorityTypes,
  assuranceTypes,
  unitTypes,
  kurumTypes,
  applicationStatuses,
};
