<template>
  <div class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col">
    <div
      class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
    >
      <div class="h-[80%] w-[20%] flex items-center">
        <p class="font-medium ml-4">SAAT</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">İŞLEM ADI/KODU</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">DİŞ KODU/KONUM</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">ADET</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">İSTEYEN HEKİM</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">UYGULAYAN HEKİM</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">BİRİM FİYAT</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">TOPLAM FİYAT</p>
      </div>
    </div>
    <div
      v-for="(service, index) in [1, 2, 3, 4, 5]"
      class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
      :key="service + index"
    >
      <div class="h-[80%] w-[20%] flex flex-col">
        <p class="ml-6">12:30</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center ml-[40px]">
        <p>İnley Dolgu İki Yüzlü</p>
        <p class="text-sm opacity-80">402050</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center">
        <p>46</p>
        <p>Mandibular First Molar</p>
      </div>
      <div
        class="h-[80%] w-[20%] flex flex-col justify-center items-center -ml-[40px]"
      >
        <p>2</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center text-center">
        <p>Cengiz KURTOĞLU</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p>Melisa ÇİÇEK</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p>50TL</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
        <p>350TL</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore";
const pastActivitiesStore = usePastActivitiesStore();
</script>
