<template>
  <div class="flex flex-col overflow-x-scroll">
    <table>
      <thead class="bg-[#f3f6f9]">
        <tr>
          <th class="min-w-[230px]">KABUL NO</th>
          <th class="min-w-[210px]">KABUL TARİHİ/SAATİ</th>
          <th class="min-w-[200px]">PROVİZYON TİPİ</th>
          <th class="min-w-[200px]">BAŞVURU NO</th>
          <th class="min-w-[200px]">HASTA ADI</th>
          <th class="min-w-[200px]">BİRİM</th>
          <th class="min-w-[200px]">KURUM ADI</th>
          <th class="min-w-[250px]">GSS TAKİP NO</th>
          <th class="min-w-[200px]">SİGORTALI TİPİ</th>
          <th class="min-w-[250px]">HEKİM ADI</th>
          <th class="min-w-[300px]">KABUL EDEN PERSONEL</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(application, index) in RegisteredPatientApplicationStore
            .listPatientApplicationResponse?.applications.data"
          :key="index"
          class="table-element border-y-[1px]"
          @click="
            () => {
              openEditMenu();
            }
          "
        >
          <td class="flex flex-col items-start">
            <div class="flex flex-col items-center">
              <div class="flex flex-col justify-center h-[60px]">
                <div class="flex items-center">
                  <!-- takip no yerine şimdilik application.attributes.record_number -->
                  <button
                    class="flex-shrink-0 h-5 w-5 focus:outline-none mr-3 rounded-full flex items-center justify-center"
                    :class="
                      application.attributes.application_protocol_number === ''
                        ? 'bg-[#D9534F]'
                        : 'bg-[#707070]'
                    "
                    @click.stop="
                      application.attributes.application_protocol_number !== ''
                        ? togglePanel(application)
                        : ''
                    "
                  >
                    <img
                      v-if="
                        application.attributes.application_protocol_number !==
                        ''
                      "
                      :src="moreIcon"
                      alt="moreIcon"
                    />
                  </button>
                  <div class="flex gap-2">
                    <div
                      class="w-[10px] h-[10px] flex-shrink-0 bg-[#277DD2] rounded-full mt-2"
                    ></div>
                    <div class="text-left">
                      <p>
                        {{ application.attributes.application_protocol_number }}
                      </p>

                      <p class="text-sm text-[#277DD2] ml-[2px]">
                        {{ application.attributes.application_status }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="flex flex-col justify-center items-center">
              <div class="h-[60px] flex flex-col items-center justify-center">
                <p class="text-[#464E5F]">
                  {{
                    new Date(
                      application.attributes.patient_acceptance_time
                    ).toLocaleDateString("tr-TR", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })
                  }}
                </p>

                <p class="text-[#7e848f] text-sm w-full text-left">
                  {{
                    new Date(
                      application.attributes.patient_acceptance_time
                    ).toLocaleTimeString("tr-TR", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                      timeZone: "Europe/Istanbul",
                    })
                  }}
                </p>
              </div>
            </div>
          </td>

          <td>
            <div class="h-[60px] flex items-center justify-center">
              <p
                v-if="application.attributes.application_protocol_number"
                @click="
                  application.attributes.provizyon_tipi === ''
                    ? pushRouterToProvizyonAl()
                    : ''
                "
                class="h-10 w-28 rounded-lg flex items-center justify-center"
                :class="
                  getColorOfProvision(application.attributes.provizyon_tipi)
                "
              >
                {{ parseProvizyonTipi(application.attributes.provizyon_tipi) }}
              </p>
              <p
                v-if="!application.attributes.application_protocol_number"
                @click="
                  routeToProvision(application)
                  // pushRouterToProvizyonAl();
                  // medulaStore.alinamamisBasvuruNo = application.id;
                  // medulaStore.alinamamisBasvuruHastaTc =
                  //   application.TCKimlikNumarasi;
                  // medulaStore.medulaSearch = application.TCKimlikNumarasi;
                  // medulaStore.bagliTakip = false;
                  // medulaStore.randevuProvizyonBasvuruNo = '';
                  // medulaStore.randevuProvizyonHastaTc = '';
                "
                class="h-10 w-28 rounded-lg flex items-center justify-center text-[#277DD2] bg-white border-[#277DD2] border-[1px] cursor-pointer"
              >
                Provizyon Al
              </p>
            </div>
          </td>

          <td>
            <div class="h-[60px] flex items-center justify-center">
              {{ application.attributes.medula_basvuru_no }}
            </div>
          </td>
          <td>
            <div class="flex justify-center items-center h-[60px]">
              <p>
                {{ application.attributes.patient_name }}
              </p>
              <p class="ml-1">
                {{ application.attributes.patient_surname }}
              </p>
            </div>
          </td>
          <td>
            <div class="h-[60px] flex items-center justify-center">
              {{ _.startCase(_.toLower(application.attributes.unit_name)) }}
            </div>
          </td>

          <td>
            <div class="h-[60px] flex items-center justify-center">
              {{ application.attributes.devredilen_kurum }}
            </div>
          </td>
          <td>
            <div class="h-[60px] flex items-center justify-center">
              {{
                application.attributes.takip_no === ""
                  ? "Takip No Almamış"
                  : application.attributes.takip_no
              }}
            </div>
          </td>

          <td>
            <div class="h-[60px] flex items-center justify-center">
              {{ application.attributes.sigortali_turu }}
            </div>
          </td>
          <td>
            <div class="flex justify-center items-center h-[60px]">
              <p>
                {{ application.attributes.doctor_name }}
              </p>
              <!-- <p class="ml-1">
                {{ _.startCase(_.toLower(patient.HekimSoyadi)) }}
              </p> -->
            </div>
          </td>
          <td>
            <div class="h-[60px] flex items-center justify-center">
              {{ application.attributes.created_by_name }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref } from "vue";
import _ from "lodash";

import arrowRightBgGray from "../assets/arrowRightBgGray.svg";
import moreIcon from "../assets/moreIcon.svg";
import { registeredPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/getPatientApplicationRegistrationList.ts";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore.ts";
import { provisionActions } from "@/store/modules/operationsStore/application/provision/applicationProvisionActions";
import {
  parseProvizyonTipi,
  parseIstisnaiHal,
  parseTakipTipi,
  parseTedaviTuru,
  parseTedaviTipi,
} from "../store/registeredPatientListParsingData";
import { router } from "@/router/index";
import { kurumTypes } from "@/modules/medula/provision/components/dropDownTypes";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useRegisteredPatientListStore } from "../store/registeredPatientList";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";
const applicationProvisionStore = useApplicationProvisionStore();

const RegisteredPatientListStore = useRegisteredPatientListStore();

const RegisteredPatientApplicationStore = registeredPatientApplicationStore();
const provisionActionsStore = provisionActions();
const getPatientStore = useGetPatientStore();

const medulaStore = useMedula();

const routeToProvision = async (application) => {
  router.push("/asistantScreen/medula/homePage");
  medulaStore.selectedPatient = await getPatientStore.getPatientA(
    application.attributes.patient_id
  );
  medulaStore.selectedPatientID = application.attributes.patient_id;
  medulaStore.searchPatientInput = application.attributes.tc_id_number;
  // medulaStore.appointmentsApplication = application.id;
  //appointments application değil burada reprovision yazmak lazım
  console.log("oy", application);
};

// Toggle Panel and request to getPatient for patient info
const togglePanel = async (application) => {
  console.log("hastamız", application.attributes.patient_id);
  console.log("applicationımız", application.id);
  RegisteredPatientListStore.sidePanelShow =
    !RegisteredPatientListStore.sidePanelShow;

  RegisteredPatientListStore.provisionsPatient =
    await getPatientStore.getPatientA(application.attributes.patient_id);

  RegisteredPatientListStore.provisionsUpdatingPatientDatas.patientID =
    RegisteredPatientListStore.provisionsPatient.id;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.province =
    RegisteredPatientListStore.provisionsPatient.attributes.province;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.district =
    RegisteredPatientListStore.provisionsPatient.attributes.district;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.addressDetail =
    RegisteredPatientListStore.provisionsPatient.attributes.address_description;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.phoneNumber =
    RegisteredPatientListStore.provisionsPatient.attributes.phone_number;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.homePhoneNumber =
    RegisteredPatientListStore.provisionsPatient.attributes.home_phone_number;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.email =
    RegisteredPatientListStore.provisionsPatient.attributes.email;
  console.log(
    "provisionsPatient",
    RegisteredPatientListStore.provisionsPatient
  );
  RegisteredPatientListStore.provisionRootApplication = application.attributes;
  RegisteredPatientListStore.provisionsDatas.applicationID = application.id;
  RegisteredPatientListStore.provisionsDatas.trackingNo =
    application.attributes.takip_no;
  RegisteredPatientListStore.provisionsDatas.acceptanceNo =
    application.attributes.application_protocol_number;
  RegisteredPatientListStore.provisionsDatas.corporation =
    application.attributes.devredilen_kurum;
  RegisteredPatientListStore.provisionsDatas.provisionType =
    application.attributes.provizyon_tipi;
  RegisteredPatientListStore.provisionsDatas.provisionDate =
    application.attributes.provizyon_tarihi;
  // RegisteredPatientListStore.provisionsDatas.shippedTrackingNo = application.attributes.sevktakipdur;
  // RegisteredPatientListStore.provisionsDatas.exceptionalStatus = application.attributes.istisnaihaldur;
  RegisteredPatientListStore.provisionsDatas.followType =
    application.attributes.takip_tipi;
  RegisteredPatientListStore.provisionsDatas.treatmentKind =
    application.attributes.treatment_type;
  RegisteredPatientListStore.provisionsDatas.unitCode =
    application.attributes.unit_code;
  RegisteredPatientListStore.provisionsDatas.doctorCode =
    application.attributes.doctor_code;
  RegisteredPatientListStore.provisionsDatas.branchCode =
    application.attributes.brans_kodu;
  RegisteredPatientListStore.provisionsDatas.branchName =
    application.attributes.unit_name;
  RegisteredPatientListStore.provisionsDatas.assuranceStatus =
    application.attributes.devredilen_kurum;
  RegisteredPatientListStore.provisionsDatas.insuranceType =
    application.attributes.sigortali_turu;
  RegisteredPatientListStore.provisionsDatas.treatmentType =
    application.attributes.tedavi_tipi;
  // applicationProvisionStore.applicationProvisionRequest.appID = application.id;
  // provisionActionsStore.getProvisionRead();
};
const unitColorRef = ref("#000");
const pushRouterToProvizyonAl = () => {
  router.push("/asistantScreen/medula/homePage");
};

const getColorOfProvision = (provision) => {
  switch (provision) {
    case "N":
      return "text-[#40464c] bg-[#e8e9ea]";
    case "I":
      return "text-[#40464c] bg-[#e8e9ea]";
    case "A":
      return "text-[#d9534f] bg-[#f8eaea]";
    case "T":
      return "";
    case "V":
      return "text-[#d9534f] bg-[#f8eaea]";
    case "M":
      return "";
    case "K":
      return "text-[#f0ad4e] bg-[#faf3ea]";
    case "C":
      return "";
    case "H":
      return "";
    case "R":
      return "";
    case "L":
      return "";
    case "G":
      return "";
    case "U":
      return "";
    case "D":
      return "";
    case "S":
      return "";
    case "E":
      return "";
    default:
      return "text-[#277DD2] bg-white border-[#277DD2] border-[1px] cursor-pointer";
  }
};

const openEditMenu = () => {
  console.log("openEditMenu");
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 0.5rem;
  text-align: center;
}

tr:not(:last-child) td {
  border-bottom: 1px solid #d5dbe1; /* Sadece satırlar arasına border ekleme */
}

.table-element {
  transition: transform 0.3s;
}
</style>
