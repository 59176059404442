<template>
  <div
    class="sticky top-0 flex flex-col h-screen w-[230px] min-w-[230px] bg-white border-t-[1px] border-b-[1px] border-r-[1px] border-[#09244B33]"
  >
    <!-- Header Title img -->
    <div class="flex items-center mt-4 mx-auto">
      <img :src="logo" alt="logo" />
    </div>

    <!-- Sidebar Sections -->
    <button
      @click="
        onClickSideBarChangePage(0);
        pushRouterToHomepage();
      "
      :class="
        doctorHastaKabulStore.sidebarActiveBar == 0 &&
        'border-l-4 border-[#277DD2] '
      "
      class="w-full h-[40px] flex focus:outline-none items-center mt-[30px]"
    >
      <img
        :src="
          doctorHastaKabulStore.sidebarActiveBar == 0
            ? anaSayfaBlueImg
            : anaSayfaWhiteImg
        "
        alt="anaSayfaImg"
        class="ml-[18px]"
      />
      <p
        class="font-medium ml-[12px]"
        :class="
          doctorHastaKabulStore.sidebarActiveBar == 0
            ? 'text-[#277DD2]'
            : 'text-[#09244B80]'
        "
      >
        Ana sayfa
      </p>
    </button>
    <button
      @click="
        onClickSideBarChangePage(1);
        pushRouterToClinicManagement();
      "
      :class="
        doctorHastaKabulStore.sidebarActiveBar == 1 &&
        'border-l-4 border-[#277DD2] '
      "
      class="w-full h-[40px] flex focus:outline-none items-center mt-2"
    >
      <img
        :src="
          doctorHastaKabulStore.sidebarActiveBar == 1
            ? klinikYonetimiBlueImg
            : klinikYonetimiWhiteImg
        "
        alt="anaSayfaImg"
        class="ml-[12px]"
      />
      <p
        class="font-medium ml-[12px]"
        :class="
          doctorHastaKabulStore.sidebarActiveBar == 1
            ? 'text-[#277DD2]'
            : 'text-[#09244B80]'
        "
      >
        Klinik Yönetimi
      </p>
      <div
        class="rounded-[5px] bg-[#454545] min-w-[30px] min-h-[23px] absolute right-2"
      >
        <p class="text-white font-medium">14</p>
      </div>
    </button>
    <button
      @click="
        onClickSideBarChangePage(2);
        pushRouterToAnamneses();
      "
      :class="
        doctorHastaKabulStore.sidebarActiveBar == 2 &&
        'border-l-4 border-[#277DD2] '
      "
      class="w-full h-[40px] flex focus:outline-none items-center mt-2"
    >
      <img
        :src="
          doctorHastaKabulStore.sidebarActiveBar == 2
            ? anamnezIslemleriBlueImg
            : anamnezIslemleriWhiteImg
        "
        alt="anaSayfaImg"
        class="ml-[12px]"
      />
      <p
        class="font-medium ml-[12px]"
        :class="
          doctorHastaKabulStore.sidebarActiveBar == 2
            ? 'text-[#277DD2]'
            : 'text-[#09244B80]'
        "
      >
        Anamnez İşlemleri
      </p>
    </button>

    <!-- Line -->
    <div class="w-full h-[2px] bg-[#F2F4F6] mt-[20px]"></div>
    <button
      @click="
        onClickSideBarChangePage(3);
        pushRouterToExaminations();
      "
      :class="
        doctorHastaKabulStore.sidebarActiveBar == 3 &&
        'border-l-4 border-[#277DD2] '
      "
      class="w-full h-[40px] flex focus:outline-none items-center mt-[20px]"
    >
      <img
        :src="
          doctorHastaKabulStore.sidebarActiveBar == 3
            ? muayeneIslemleriBlueImg
            : muayeneIslemleriWhiteImg
        "
        alt="anaSayfaImg"
        class="ml-[12px]"
      />
      <p
        class="font-medium ml-[12px]"
        :class="
          doctorHastaKabulStore.sidebarActiveBar == 3
            ? 'text-[#277DD2]'
            : 'text-[#09244B80]'
        "
      >
        Muayene İşlemleri
      </p>
      <div
        class="rounded-[5px] bg-[#454545] min-w-[30px] min-h-[23px] absolute right-2"
      >
        <p class="text-white font-medium">39</p>
      </div>
    </button>
    <button
      @click="
        onClickSideBarChangePage(4);
        pushRouterToDoctorScreensAppointments();
      "
      :class="
        doctorHastaKabulStore.sidebarActiveBar == 4 &&
        'border-l-4 border-[#277DD2] '
      "
      class="w-full h-[40px] flex focus:outline-none items-center mt-2"
    >
      <img
        :src="
          doctorHastaKabulStore.sidebarActiveBar == 4
            ? randevuDefteriBlueImg
            : randevuDefteriWhiteImg
        "
        alt="anaSayfaImg"
        class="ml-[12px]"
      />
      <p
        class="font-medium ml-[12px]"
        :class="
          doctorHastaKabulStore.sidebarActiveBar == 4
            ? 'text-[#277DD2]'
            : 'text-[#09244B80]'
        "
      >
        Randevu Defteri
      </p>
    </button>

    <div class="border-t-[2px] border-[#F2F4F6] h-[58px] mt-auto">
      <button class="w-full h-[40px] flex focus:outline-none items-center mt-2">
        <img :src="ayarlarWhiteImg" alt="anaSayfaImg" class="ml-[12px]" />
        <p class="font-medium ml-[12px] text-[#09244B80]">Ayarlar</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import { router } from "@/router/index";
import { useRouter } from "vue-router";
import { ref, watch } from "vue";
import { useDoctorHastaKabulStore } from "@/modules/doctorHastaKabul/store/doctorHastaKabulStore.js";
const doctorHastaKabulStore = useDoctorHastaKabulStore();
// Logolar
import anaSayfaBlueImg from "@/modules/doctorHastaKabul/assets/anaSayfaBlueImg.svg";
import anaSayfaWhiteImg from "@/modules/doctorHastaKabul/assets/anaSayfaWhiteImg.svg";
import anamnezIslemleriBlueImg from "@/modules/doctorHastaKabul/assets/anamnezIslemleriBlueImg.svg";
import anamnezIslemleriWhiteImg from "@/modules/doctorHastaKabul/assets/anamnezIslemleriWhiteImg.svg";
import klinikYonetimiBlueImg from "@/modules/doctorHastaKabul/assets/klinikYonetimiBlueImg.svg";
import klinikYonetimiWhiteImg from "@/modules/doctorHastaKabul/assets/klinikYonetimiWhiteImg.svg";
import muayeneIslemleriBlueImg from "@/modules/doctorHastaKabul/assets/muayeneIslemleriBlueImg.svg";
import muayeneIslemleriWhiteImg from "@/modules/doctorHastaKabul/assets/muayeneIslemleriWhiteImg.svg";
import randevuDefteriBlueImg from "@/modules/doctorHastaKabul/assets/randevuDefteriBlueImg.svg";
import randevuDefteriWhiteImg from "@/modules/doctorHastaKabul/assets/randevuDefteriWhiteImg.svg";
import randevuOlusturBlueImg from "@/modules/doctorHastaKabul/assets/randevuOlusturBlueImg.svg";
import randevuOlusturWhiteImg from "@/modules/doctorHastaKabul/assets/randevuOlusturWhiteImg.svg";
import ayarlarWhiteImg from "@/modules/doctorHastaKabul/assets/ayarlarWhiteImg.svg";

import logo from "../assets/logo.svg";

const getCurrentRouter = useRouter();
const currentRouteName = ref(getCurrentRouter.currentRoute.value.path);

// const toggleSideBar = () => {
//   personalStore.personalSidebarIsExpanded =
//     !personalStore.personalSidebarIsExpanded;
// };
// const handleSideBarClick = (sideBarActiveButtonNum) => {
//   personalStore.personalSideBarActiveButton = sideBarActiveButtonNum;
// };

const pushRouterToHomepage = () => {
  router.push("/polyclinic/homepage");
};
const pushRouterToClinicManagement = () => {
  router.push("/polyclinic/clinic-management");
};
const pushRouterToAnamneses = () => {
  router.push("/polyclinic/anamneses");
};
const pushRouterToExaminations = () => {
  router.push("/polyclinic/examinations");
};

const pushRouterToDoctorScreensAppointments = () => {
  router.push("/polyclinic/doctor-screens/appointments");
};
let unitID = useRouter().currentRoute.value.params.unitID;
let anamnesisID = useRouter().currentRoute.value.params.anamnesisID;
switch (currentRouteName.value) {
  case "/polyclinic/homepage":
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 0;
    break;

  case "/polyclinic/clinic-management":
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 1;
    break;
  case "/polyclinic/clinic-management/" + unitID:
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 1;
    break;
  case "/polyclinic/anamneses":
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 2;
    break;
  case "/polyclinic/anamneses/" + unitID:
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 2;
    break;
  case "/polyclinic/anamneses/" + unitID + "/create-anamnesis":
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 2;
    break;
  case "/polyclinic/anamneses/" + unitID + "/read-anamnesis/" + anamnesisID:
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 2;
    break;
  case "/polyclinic/anamneses/" + unitID + "/update-anamnesis/" + anamnesisID:
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 2;
    break;
  case "/polyclinic/examinations":
    // personalStore.personalSidebarIsExpanded = true;
    doctorHastaKabulStore.sidebarActiveBar = 3;
    break;
  case "/polyclinic/doctor-screens/appointments":
    doctorHastaKabulStore.sidebarActiveBar = 4;
    break;
  default:
    // personalStore.personalSidebarIsExpanded = false;
    doctorHastaKabulStore.sidebarActiveBar = 0;
    break;
}

const onClickSideBarChangePage = (index) => {
  doctorHastaKabulStore.sidebarActiveBar = index;
  doctorHastaKabulStore.popUpRightClick = false;
};
</script>

<style scoped>
.sidebarBG {
  border-radius: 8px;
  border: 1px solid rgba(9, 36, 75, 0.2);
  background: #fff;
}
.sidebarActiveButtonBG {
  border-radius: 10px;
  border: 1px solid #fff;
  background: #277dd2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.sidebarPassiveButtonBG {
  border-radius: 10px;
  background: white;
}
.userLogoutCardBG {
  border-radius: 0px 0px 8px 8px;
  background: rgba(9, 36, 75, 0.03);
  box-shadow: 0px 1px 0px 0px #f2f4f6 inset;
}
.subNavigationHover:hover {
  background-color: rgba(82, 101, 129, 0.5);
}
.subNavigationActiveBG {
  background-color: rgb(82, 101, 129);
}
</style>
