<template>
    <div class="absolute top-[345px] left-[570px] cursor-pointer z-50">
        <button 
        @click="openSideBar()"
        class="cursor-pointer z-50">
            <img :src="coloredPeopleCalendarImg" class="cursor-pointer" alt="">
        </button>
    </div>
</template>

<script setup>
import coloredPeopleCalendarImg from '../assets/coloredPeopleCalenderImg.svg'
import {useAsistanEkrani} from '../asistanEkraniStore/asistanEkraniStore'
const store=useAsistanEkrani()
const openSideBar = ()=>{
    store.assistantCalendarDetailButton=!store.assistantCalendarDetailButton
}
</script>