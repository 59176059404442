<template>
  <div class="outcontainer">
    <img
      :src="dentalKutahya"
      alt="kütahyaüniversitesilogo"
      class="w-94 h-72 mx-auto mt-3"
    />
    <div class="w-96 h-36 mt-4">
      <h4 class="font-medium text-center font-roboto text-2xl">
        Kütahya Sağlık Bilimleri Üniversitesi <br />Ağız ve Diş Sağlığı
        Uygulama<br />Araştırma Merkezi
      </h4>
    </div>
    <div class="inputregistercontainer w-96">
      <label class="label flex cursor-pointer items-center" for="firstRadio">
        <input
          :class="inputClass"
          id="username"
          v-model="username"
          type="text"
          placeholder="Ad/Soyad:"
          required
        />
      </label>
      <label class="label flex cursor-pointer items-center" for="firstRadio">
        <input
          :class="inputClass"
          id="emmail"
          v-model="email"
          type="text"
          placeholder="E-mail:"
          required
        />
      </label>
      <label class="label flex cursor-pointer items-center" for="firstRadio">
        <input
          :class="inputClass"
          id="phonenumber"
          v-model="phonenumber"
          type="text"
          placeholder="Telefon Numarası:"
          required
        />
      </label>
      <label class="label flex cursor-pointer items-center" for="firstRadio">
        <input
          :class="inputClass"
          id="password"
          v-model="password"
          type="text"
          placeholder="Şifre:"
          required
        />
      </label>
      <label class="label flex cursor-pointer items-center" for="firstRadio">
        <input
          :class="inputClass"
          id="clinic"
          v-model="clinic"
          type="text"
          placeholder="Klinik:"
          required
        />
      </label>
    </div>
    <button
      type="button"
      @click="onHandleLogin()"
      :class="
        username.length >= 5
          ? 'focus:shadow-outline  w-80 h-14 rounded bg-[#312CB5]  font-medium text-white shadow hover:bg-blue-400 focus:outline-none mt-7 mb-7 '
          : 'focus:shadow-outline  w-80 h-14 cursor-not-allowed rounded bg-[#C9C9C9]  font-medium text-[#9E9E9E] shadow focus:outline-none mt-7 mb-7'
      "
    >
      Kayıt Ol
    </button>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { personelRegisterActions } from "@/store/modules/administratorManagementStore/users/kratosRegister/personelRegisterActions";
// import dentalKutahya from "../../../assets/logo-kütahya-sağlık-bilimleri-üniversitesi-1.png";

let inputClass =
  " w-96 h-14 appearance-none border py-2 px-3  leading-tight text-gray-800 focus:outline-none placeholder:font-medium";

const username = ref("");
const email = ref("");
const phoneNumber = ref("");
const password = ref("");
const clinic = ref("");
const personelStoreRegisterActions = personelRegisterActions();

const onHandleLogin = () => {
  if (
    username.value &&
    email.value &&
    phoneNumber.value &&
    password.value &&
    clinic.value
  ) {
    personelStoreRegisterActions.register({
      userName: username.value,
      eMail: email.value,
      phoneNumber: phoneNumber.value,
      password: password.value,
      clinic: clinic.value,
    }); //userStore action
  } else {
    alert("bilgileri kontrol edin");
  }
};
</script>
<style>
.outcontainer {
  overflow: auto;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
}
.inputregistercontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 350px;
}
</style>
