<template>
  <div class="h-[321px] w-[499px]">
    <table>
      <!-- Table Header -->
      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in tableHeaderNums"
          :key="index"
        >
          <div
            class="rounded-[4px] bg-[#D9D9D980] hover:bg-[#E1EDFF] flex items-center justify-center min-w-[52px] w-[54px] min-h-[35px] mx-auto"
          >
            {{ headerItem }}
          </div>
        </th>
      </tr>
      <!--  Mobility -->
      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in store.tableMobility.slice(
            indexNum,
            indexNum + 8
          )"
          :key="index"
        >
          <button
            @click="changeTableMobility(index + indexNum)"
            :class="
              (store.tableMobility[index + indexNum] == '|' ||
                store.tableMobility[index + indexNum] == 2) &&
              'bg-[#E1EDFF]'
            "
            class="rounded-[4px] flex items-center justify-center min-w-[52px] w-[54px] min-h-[35px] mx-auto"
          >
            <p class="font-light text-sm">
              {{ headerItem }}
            </p>
          </button>
        </th>
      </tr>
      <!-- Implant -->
      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in store.tableImplant.slice(
            indexNum,
            indexNum + 8
          )"
          :key="index"
        >
          <button
            @click="changeTableImplant(index + indexNum)"
            class="rounded-[4px] bg-[#D9D9D980] hover:bg-[#E1EDFF] flex items-center justify-center min-w-[52px] w-[54px] min-h-[35px] mx-auto"
          >
            <div
              v-if="store.tableImplant[index + indexNum]"
              class="bg-[#F0AD4E] w-[22px] h-[22px]"
            ></div>
          </button>
        </th>
      </tr>
      <!-- Furcation -->
      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in store.tableFurcation.slice(
            indexNum,
            indexNum + 8
          )"
          :key="index"
        >
          <button
            @click="changeTableFurcation(index + indexNum)"
            class="rounded-[4px] bg-[#D9D9D980] hover:bg-[#E1EDFF] flex items-center justify-center min-w-[52px] w-[54px] min-h-[35px] mx-auto"
          >
            <img
              v-if="store.tableFurcation[index + indexNum] == 3"
              :src="orangeCircle"
              alt=""
            />
            <img
              v-if="store.tableFurcation[index + indexNum] == 1"
              :src="orangeCircleBorder"
              alt=""
            />
            <img
              v-if="store.tableFurcation[index + indexNum] == 2"
              :src="orangeHalfCircle"
              alt=""
            />
          </button>
        </th>
      </tr>
      <!-- Bleeding on Probing -->
      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in store.tableBleedingOnProbing.slice(
            indexNum,
            indexNum + 8
          )"
          :key="index"
        >
          <div class="flex">
            <button
              @click="changeTableBleedingOnProbing(index + indexNum, 0)"
              :class="
                store.tableBleedingOnProbing[index + indexNum][0] == 1
                  ? 'bg-[#D9534F]'
                  : 'bg-[#D9D9D980]'
              "
              class="rounded-[4px] hover:bg-[#D9534F80] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></button>
            <button
              @click="changeTableBleedingOnProbing(index + indexNum, 1)"
              :class="
                store.tableBleedingOnProbing[index + indexNum][1] == 1
                  ? 'bg-[#D9534F]'
                  : 'bg-[#D9D9D980]'
              "
              class="rounded-[4px] hover:bg-[#D9534F80] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></button>
            <button
              @click="changeTableBleedingOnProbing(index + indexNum, 2)"
              :class="
                store.tableBleedingOnProbing[index + indexNum][2] == 1
                  ? 'bg-[#D9534F]'
                  : 'bg-[#D9D9D980]'
              "
              class="rounded-[4px] hover:bg-[#D9534F80] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></button>
          </div>
        </th>
      </tr>
      <!-- Plaque -->
      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in store.tablePlaque.slice(
            indexNum,
            indexNum + 8
          )"
          :key="index"
        >
          <div class="flex">
            <button
              @click="changeTablePlaque(index + indexNum, 0)"
              :class="
                store.tablePlaque[index + indexNum][0] == 1
                  ? 'bg-[#4198F7]'
                  : 'bg-[#D9D9D980]'
              "
              class="rounded-[4px] hover:bg-[#4198F780] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></button>
            <button
              @click="changeTablePlaque(index + indexNum, 1)"
              :class="
                store.tablePlaque[index + indexNum][1] == 1
                  ? 'bg-[#4198F7]'
                  : 'bg-[#D9D9D980]'
              "
              class="rounded-[4px] hover:bg-[#4198F780] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></button>
            <button
              @click="changeTablePlaque(index + indexNum, 2)"
              :class="
                store.tablePlaque[index + indexNum][2] == 1
                  ? 'bg-[#4198F7]'
                  : 'bg-[#D9D9D980]'
              "
              class="rounded-[4px] hover:bg-[#4198F780] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></button>
          </div>
        </th>
      </tr>
      <!-- Gingival Margin -->

      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in store.tableGingivalMargin.slice(
            indexNum,
            indexNum + 8
          )"
          :key="index"
        >
          <div class="flex justify-around">
            <p
              @click="changeGingivalMargin(index + indexNum, 0)"
              class="font-light text-sm cursor-pointer"
            >
              {{ headerItem[0] }}
            </p>
            <p
              @click="changeGingivalMargin(index + indexNum, 1)"
              class="font-light text-sm cursor-pointer"
            >
              {{ headerItem[1] }}
            </p>
            <p
              @click="changeGingivalMargin(index + indexNum, 2)"
              class="font-light text-sm cursor-pointer"
            >
              {{ headerItem[2] }}
            </p>
          </div>
        </th>
      </tr>
      <!-- Probing Depth -->
      <tr>
        <th
          :class="props.isConverted && 'rotate-180'"
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in store.tableProbingDepth.slice(
            indexNum,
            indexNum + 8
          )"
          :key="index"
        >
          <div class="flex justify-around">
            <p
              @click="changeProbingDepth(index + indexNum, 0)"
              class="font-light text-sm cursor-pointer"
            >
              {{ headerItem[0] }}
            </p>
            <p
              @click="changeProbingDepth(index + indexNum, 1)"
              class="font-light text-sm cursor-pointer"
            >
              {{ headerItem[1] }}
            </p>
            <p
              @click="changeProbingDepth(index + indexNum, 2)"
              class="font-light text-sm cursor-pointer"
            >
              {{ headerItem[2] }}
            </p>
          </div>
        </th>
      </tr>
    </table>
  </div>
</template>
<script setup>
// Image imports
import orangeCircle from "../assets/orangeCircle.svg";
import orangeCircleBorder from "../assets/orangeCircleBorder.svg";
import orangeHalfCircle from "../assets/orangeHalfCircle.svg";
import { usePeriodontalChartStore } from "../store/periodontalChart";
const store = usePeriodontalChartStore();
const tableHeaderNums = props.tableHeaderNums;
const changeTableMobility = (index) => {
  switch (store.tableMobility[index]) {
    case 0:
      store.tableMobility[index] = "|";
      break;
    case "|":
      store.tableMobility[index] = 2;
      break;

    case 2:
      store.tableMobility[index] = 0;
      break;
  }
};
const changeTableImplant = (index) => {
  if (store.tableImplant[index] == 0) {
    store.tableImplant[index] = 1;
  } else if (store.tableImplant[index] == 1) {
    store.tableImplant[index] = 0;
  }
};
// Furcation
const changeTableFurcation = (index) => {
  switch (store.tableFurcation[index]) {
    case 0:
      store.tableFurcation[index] = 1;
      break;
    case 1:
      store.tableFurcation[index] = 2;
      break;
    case 2:
      store.tableFurcation[index] = 3;
      break;
    case 3:
      store.tableFurcation[index] = 0;
      break;
  }
};
// Change BleedingOnProbing
const changeTableBleedingOnProbing = (index, arrayNum) => {
  switch (store.tableBleedingOnProbing[index][arrayNum]) {
    case 0:
      store.tableBleedingOnProbing[index][arrayNum] = 1;
      break;
    case 1:
      store.tableBleedingOnProbing[index][arrayNum] = 0;
      break;
  }
};
// Change Plaque
const changeTablePlaque = (index, arrayNum) => {
  switch (store.tablePlaque[index][arrayNum]) {
    case 0:
      store.tablePlaque[index][arrayNum] = 1;
      break;
    case 1:
      store.tablePlaque[index][arrayNum] = 0;
      break;
  }
};
const changeGingivalMargin = (index, arrayNum) => {
  switch (store.tableGingivalMargin[index][arrayNum]) {
    case 0:
      store.tableGingivalMargin[index][arrayNum] = 1;
      break;
    case 1:
      store.tableGingivalMargin[index][arrayNum] = 0;
      break;
  }
};
const changeProbingDepth = (index, arrayNum) => {
  switch (store.tableProbingDepth[index][arrayNum]) {
    case 0:
      store.tableProbingDepth[index][arrayNum] = 1;
      break;
    case 1:
      store.tableProbingDepth[index][arrayNum] = 0;
      break;
  }
};
const createLine = (x1, y1, x2, y2, lineId) => {
  const distance = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
  const xMid = (x1 + x2) / 2;
  const yMid = (y1 + y2) / 2;
  const slopeInRadian = Math.atan2(y1 - y2, x1 - x2);
  const slopeInDegrees = (slopeInRadian * 180) / Math.PI;
  line = document.getElementById(lineId);
  line.style.width = distance;
  line.style.top = yMid;
  line.style.left = xMid - distance / 2;
  line.style.transform = "rotate(" + slopeInDegrees + "deg)";
};
const props = defineProps({
  tableHeaderNums: Array,
  isConverted: Boolean,
  indexNum: Number,
});
</script>
<style scoped>
td,
th {
  border: 1px solid #b7b7b7;
  width: 61px;
  height: 40px;
}
th:last-child {
  border-radius: 5px;
}
</style>