<template>
  <div class="flex flex-col gap-8">
    <div class="text-[#F94144] bg-[#F94144] bg-opacity-10 px-4 py-3 rounded-md">
      *Kilitle seçeneğini aktif duruma getirmeniz halinde varsayılan seçenek
      randevu defteri ekranlarında uygulanacaktır.
    </div>
    <div>
      <h4 class="font-medium mb-8">Randevu Defteri Ayarları</h4>
      <div class="flex">
        <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
          <p class="font-medium mb-3">Varsayılan Görünüm</p>
          <div class="flex">
            <Select2
              class="w-[300px]"
              :options="[
                { id: 1, text: 'Liste' },
                { id: 2, text: 'Haftalık' },
                { id: 3, text: 'Aylık' },
              ]"
              placeholder="Seçiniz"
            />

            <div class="ml-3 flex items-center gap-3">
              <p>Kilitle</p>
              <button
                class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
                :class="true ? 'bg-[#026D4D]' : 'bg-gray-300'"
              >
                <div
                  class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                  :class="{
                    'translate-x-3': true,
                  }"
                ></div>
              </button>
            </div>
          </div>
        </div>

        <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md ml-20">
          <p class="font-medium mb-3">Varsayılan Birim Seçimi</p>
          <div class="flex">
            <Select2
              class="w-[300px]"
              :options="unitListStore.getListUnitsResponse"
              placeholder="Seçiniz"
            />

            <div class="ml-3 flex items-center gap-3">
              <p>Kilitle</p>
              <button
                class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
                :class="true ? 'bg-[#026D4D]' : 'bg-gray-300'"
              >
                <div
                  class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                  :class="{
                    'translate-x-3': true,
                  }"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Select2 from "vue3-select2-component";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
</script>
