<template>
  <div class="ml-[10px] flex w-[2600px] border-b-[1px] border-[#2C3D8F] ">
    <p 
    v-if="store.studentAssistantHideItems[0]"

    class="flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
        (111) 222 33 44
    </p>
    <p     v-if="store.studentAssistantHideItems[1]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
        15
    </p>
    <p     v-if="store.studentAssistantHideItems[2]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
      3
    </p>
    <p     v-if="store.studentAssistantHideItems[3]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
        3
    </p>
    <p     v-if="store.studentAssistantHideItems[4]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
        27546
    </p>
    <p     v-if="store.studentAssistantHideItems[5]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
        Mehmet Hal
    </p>
    <p     v-if="store.studentAssistantHideItems[6]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
      2
    </p>
    <p     v-if="store.studentAssistantHideItems[7]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
      Doktor
    </p>
    <p    v-if="store.studentAssistantHideItems[8]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
      2
    </p>
    <p     v-if="store.studentAssistantHideItems[9]"
    class="ml-[10px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
      3
    </p>
    <p     v-if="store.studentAssistantHideItems[10]"
    class="ml-[40px] flex h-[55px] min-w-[200px] w-[200px]   items-center justify-center">
      3
    </p>
  </div>
</template>
<script setup>
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();
</script>
