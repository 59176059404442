<template>
  <aside
    class="flex flex-col fixed border z-20 bg-white text-[#464e5f] rounded-md py-2 px-4 top-0 right-0 w-[1000px] h-full transition-all duration-500"
    :class="
      klinikYonetimStore.showAddDoctorSidebar
        ? 'translate-x-0'
        : 'translate-x-full'
    "
  >
    <div class="flex justify-between items-center mb-3">
      <h4 class="font-medium text-xl">Yeni Hekim Ekle</h4>
      <button
        @click="klinikYonetimStore.showAddDoctorSidebar = false"
        class="rounded-full focus:outline-double focus:outline-1 focus:outline-[#277dd2] border-[1px] border-[#A3B2B6] w-10 h-10 flex items-center justify-center"
      >
        <img class="w-8" :src="cross" alt="" />
      </button>
    </div>

    <div class="flex flex-col gap-[20px]">
      <div class="flex flex-col gap-3">
        <div class="flex px-3 py-2 gap-4 bg-[#eaf2fb] rounded">
          <img :src="doctorInfoIcon" alt="" />
          <p class="text-[#277dd2] font-medium">Kişisel Bilgileri</p>
        </div>
        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <label for="tcNo" class="m-0 font-medium">TC Kimlik No</label>
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="tcNo"
            />
          </div>
          <div class="flex flex-col gap-2">
            <label for="name" class="m-0 font-medium">Ad</label>
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="name"
            />
          </div>
          <div class="flex flex-col gap-2">
            <label for="surname" class="m-0 font-medium">Soyad</label>
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="surname"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <label class="m-0 font-medium">Cinsiyet</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label class="m-0 font-medium">Medeni Hal</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label class="m-0 font-medium">Doğum Yeri</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <label class="m-0 font-medium">Doğum Tarihi</label>
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="tcNo"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-3">
        <div class="flex px-3 py-2 gap-4 bg-[#eaf2fb] rounded">
          <img :src="contactIcon" alt="" />
          <p class="text-[#277dd2] font-medium">İletişim Bilgileri</p>
        </div>
        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <label for="homePhoneNumber" class="m-0 font-medium"
              >Ev Telefonu</label
            >
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="homePhoneNumber"
            />
          </div>
          <div class="flex flex-col gap-2">
            <label for="phoneNumber" class="m-0 font-medium"
              >Cep Telefonu</label
            >
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="phoneNumber"
            />
          </div>
          <div class="flex flex-col gap-2">
            <label for="mail" class="m-0 font-medium">E-Posta</label>
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="mail"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <label class="m-0 font-medium">İl</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label class="m-0 font-medium">İlçe</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label for="address" class="m-0 font-medium">Adres</label>
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="address"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-3">
        <div class="flex px-3 py-2 gap-4 bg-[#eaf2fb] rounded">
          <img :src="stethoscopeIcon" alt="" />
          <p class="text-[#277dd2] font-medium">Hekim Bilgileri</p>
        </div>
        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <label for="tcNo" class="m-0 font-medium">Unvan</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label for="tcNo" class="m-0 font-medium">Unvan Grubu</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label for="tcNo" class="m-0 font-medium">Bölüm</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-between">
          <div class="flex flex-col gap-2">
            <label for="tcNo" class="m-0 font-medium">Kadro Yeri</label>
            <div class="addNewDoctorSelect">
              <Select2
                class="w-[250px]"
                :options="['Üsküdar', 'Kadıköy']"
                placeholder="Seçiniz"
              />
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label for="tcNo" class="m-0 font-medium">Başlama Tarihi</label>
            <input
              type="text"
              class="border-[1px] border-[#a3b2b6] w-[250px] h-[45px] px-2 text-lg rounded-[10px]"
              id="tcNo"
            />
          </div>
          <div class="flex flex-col gap-2 w-[250px]"></div>
        </div>
      </div>
    </div>

    <button
      :class="false ? 'opacity-100' : 'opacity-50 cursor-not-allowed'"
      class="bg-[#277dd2] text-2xl py-2 rounded-[10px] text-white mt-auto w-full"
    >
      Kaydet
    </button>
  </aside>
</template>

<script setup>
import Select2 from "vue3-select2-component";
import { useKlinikYonetimStore } from "@/modules/klinikYonetim/store/klinikYonetim";
const klinikYonetimStore = useKlinikYonetimStore();
import cross from "@/mainPage/assets/cross.svg";
import doctorInfoIcon from "@/modules/doctorSlots/assets/doctorInfoIcon.svg";
import contactIcon from "@/modules/doctorSlots/assets/contactIcon.svg";
import stethoscopeIcon from "@/modules/doctorSlots/assets/stethoscopeIcon.svg";
</script>

<style>
.addNewDoctorSelect .select2-selection {
  border-radius: 10px !important;
  font-size: 18px !important;
}

.addNewDoctorSelect .select2-selection,
.addNewDoctorSelect .select2-selection__rendered,
.addNewDoctorSelect .select2-selection__arrow {
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 4px !important;
  font-weight: 400 !important;
}

.addNewDoctorSelect .select2-selection__arrow {
  right: 10px !important;
  top: -1px !important;
}

.addNewDoctorSelect .select2-container--open .select2-selection__arrow {
  top: -9px !important;
}

.addNewDoctorSelect .select2-selection__arrow b {
  border-width: 8px 6px 8px 6px !important;
  /* top: 7px !important; */
}
</style>
