<template>
  <div>
    <div>
      <VueDatePicker
        v-model="date"
        locale="tr"
        format="dd/MM/yyyy"
        auto-apply
        :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
        :highlight="highlightedDates"
        :disabled-dates="disabledDates"
        :enable-time-picker="false"
        @update:model-value="handleDate"
        @update-month-year="handleMonthYear"
      />
    </div>
  </div>
</template>

<script setup>
import { globalStore } from "@/store/modules/globalStore";
import { useAppointmentBook } from "@/modules/asistanEkrani/components/appointmentBookStore";

import { ref, onMounted, computed, watch } from "vue";
import addDays from "date-fns/addDays";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic";
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate";
import moment from "moment";
import {
  isSideBarDateSelectButtonOn,
  returnCalendarSlotClinicName,
} from "@/modules/asistanEkrani/asistanCalendar/store/calendarStore.js";
const date = ref();
let dateObj = new Date();
let year = 0; // Yıl (örneğin: 2023)
let month = 0; // Ay (1-12 arası, Ocak 0 olarak başlar, bu yüzden +1 ekliyoruz)
let day = 0;
let hour = 0; // Saat (0-23 arası)
let minute = 0; // Dakika (0-59 arası)
let second = 0;
const clicinSlot = SearchFreeSlotsforClinic();
const betweenDateSlot = searchSlotsBetweenDate();
const highlightedDates = ref();
// const disabledDates = computed(() => {
//   const today = new Date();

//   const tomorrow = new Date(today);
//   tomorrow.setDate(tomorrow.getDate() + 1);

//   const afterTomorrow = new Date(tomorrow);
//   afterTomorrow.setDate(tomorrow.getDate() + 1);

//   return [tomorrow, afterTomorrow];
// });

const handleDate = (modelData) => {
  date.value = modelData;
  dateObj = new Date(date.value);
  year = dateObj.getFullYear(); // Yıl (örneğin: 2023)
  month = dateObj.getMonth() + 1; // Ay (1-12 arası, Ocak 0 olarak başlar, bu yüzden +1 ekliyoruz)
  day = dateObj.getDate();
  hour = dateObj.getHours();
  minute = dateObj.getMinutes();
  second = dateObj.getSeconds();
  clicinSlot.$state.calendarSelectedStartDate = new Date(
    `${year}-${month}-${day} 00:00:00`
  );

  clicinSlot.$state.calendarSelectedEndDate = new Date(
    `${year}-${month}-${day + 1} 00:00:00`
  );
  // betweenDateSlot.slotsBetweenDate(
  //   moment(clicinSlot.$state.calendarSelectedStartDate).format(
  //     "YYYY-MM-DD HH:MM:SS"
  //   ),
  //   moment(clicinSlot.$state.calendarSelectedEndDate).format(
  //     "YYYY-MM-DD HH:MM:SS"
  //   ),
  //   "calendarWeeklySlots"
  // );
};
const handleMonthYear = async ({ month, year }) => {
  const initalMonth = month + 1;
  const formattedMonth = initalMonth.toString().padStart(2, "0");
  const forwardMont = month == 11 ? month + 1 : month + 2;
  const formattedForwardMonth = forwardMont.toString().padStart(2, "0");
  betweenDateSlot.$state.calendarSelectedStartDate = `${year}-${formattedMonth}-01`; //send start en end days to side bar component
  betweenDateSlot.$state.calendarSelectedEndDate = `${year}-${formattedForwardMonth}-01`;
  await betweenDateSlot.slotsBetweenDate(
    betweenDateSlot.$state.calendarSelectedStartDate,
    betweenDateSlot.$state.calendarSelectedEndDate,
    "calendarMonthlySlots",
    returnCalendarSlotClinicName()
  );
  await searchSlotsBetweenDate().slotsBetweenDate(
    "",
    betweenDateSlot.$state.calendarSelectedStartDate, //saat dakika saniye bilgisini kaldırdım
    betweenDateSlot.$state.calendarSelectedEndDate,
    "calendarMonthlySlots",
    "free",
    useAppointmentBook().unit_code,
    1,
    5000,
    "picker"
  );
  if (betweenDateSlot.pickerSlotsBetweenDateResponse) {
    console.log(
      "AAAAAAAAAAA",
      betweenDateSlot.pickerSlotsBetweenDateResponse
    );
    let emptySlots = [];
    let emptySlotsArray = [];
    emptySlots = betweenDateSlot.pickerSlotsBetweenDateResponse.slots.data;
    // emptySlots = JSON.parse(JSON.stringify(emptySlots));
    emptySlots.map((emptySlotItem) => {
      console.log("eyy", emptySlotItem);
      const weeklyEvent = {
        groupId: "aviliableForAppointment",
        start: new Date(emptySlotItem.attributes.start_time).toISOString(),
        end: new Date(emptySlotItem.attributes.end_time).toISOString(),
        display: "background",
      };
      emptySlotsArray.push(weeklyEvent);
    });

    highlightedDates.value = emptySlotsArray.map((index) => {
      return [addDays(new Date(index.start), 0)]; // Başlangıç ve bitiş tarihlerini ekleyin  - Wed Jun 28 2023 15:28:52 GMT+0300 (GMT+03:00)
    });
  }
};

watch(
  () => globalStore.isCreateAppointmentSideBarOpen,
  (newValue) => {
    date.value = new Date(clicinSlot.$state.calendarSelectedStartDate);
    date.value.setHours(date.value.getHours());
  }
);

watch(
  () => clicinSlot.$state.calendarSelectedStartDate,
  (newValue) => {
    date.value = new Date(clicinSlot.$state.calendarSelectedStartDate);
    date.value.setHours(date.value.getHours());
  }
);
</script>
<style scoped>
.datepicker-container .datepicker {
  width: calc(var(--cell-size) * 7 + var(--cell-padding) * 14);
  height: calc(
    var(--month-year-row-height) + var(--cell-size) * 6 + var(--cell-padding) *
      12 + var(--row-margin) * 5
  );
}
</style>
