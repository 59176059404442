<template>
  <div class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col">
    <div
      class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
    >
      <div class="h-[80%] w-[20%] flex items-center">
        <p class="font-medium ml-4">TARİH</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">SAAT</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">BÖLÜM</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">RANDEVU TÜRÜ</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">HEKİM</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">DURUM</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium"></p>
      </div>
    </div>
    <div
      v-for="(appointment, index) in [1, 2, 3, 4, 5, 6, 7]"
      class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
      :key="appointment + index"
    >
      <div class="h-[80%] w-[20%] flex items-center gap-2">
        <p class="ml-6">26 Eyl 2023</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
        <p>09:30</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
        <p>Endodonti</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
        <p>Muayene</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p>Melisa ÇİÇEK</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p>Aktif</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
        <button class="px-6 py-1 bg-[#e8e9ea] font-medium rounded-[10px]">
          Yazdır
        </button>
      </div>
    </div>
  </div>
</template>
