import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useCreateMessageTemplate = defineStore({
  id: "useCreateMessageTemplate",
  state: () => ({
    createMessageTemplateResponse: null,
  }),
  actions: {
    async createMessageTemplate(title, message) {
      const personelStoreData = personelStore();
      await axios
        .post(
          `${adminDomain}/message-template/create`,
          {
            name: title,
            message: message,
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("createMessageTemplateResponse", response);
          this.createMessageTemplateResponse = response.data;
        })
        .catch((error) => {
          console.log("createMessageTemplateError", error);
        });
    },
  },
});
