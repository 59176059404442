<template>
  <div class="relative w-[600px] p-4 shadow-xl">
    <div class="flex items-center justify-between">
      <h3 class="text-xl font-semibold">Tedavi Detayı</h3>
      <div class="flex gap-6">
        <div class="flex items-center gap-2 cursor-pointer">
          <img :src="printer" alt="printer" /> Yazdır
        </div>
        <div
          class="flex justify-center items-center rounded-full w-12 h-12 bg-[#FBEDED] cursor-pointer"
          @click="popUpManagement.detailPopUp = false"
        >
          <img :src="closeIcon" alt="closeIcon" />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4 my-8">
      <div class="flex">
        <p class="w-48">Dosya Numarası</p>
        <p class="font-semibold">A12345</p>
      </div>
      <div class="flex">
        <p class="w-48">Tedavi Planı Adı</p>
        <p class="font-semibold">Badem</p>
      </div>
      <div class="flex">
        <p class="w-48">Hekim Adı / Birimi</p>
        <p class="font-semibold">Nalan ÇİÇEK / Ortodonti</p>
      </div>
      <div class="flex">
        <p class="w-48">İşlem Yapan Hekim</p>
        <p class="font-semibold">Alper GÜÇLÜ</p>
      </div>
      <div class="flex">
        <p class="w-48">Oluşturulma Tarihi</p>
        <p class="font-semibold">01/01/2023</p>
      </div>
      <div class="flex">
        <p class="w-48">Tedavideki Dişler</p>
        <p class="font-semibold">21,36</p>
      </div>
    </div>
    <div
      class="rounded-full w-full p-2 bg-[#252525] text-white flex justify-center items-center"
    >
      Metal Destekli Kron
    </div>
    <!-- STEP SECTION -->
    <div>
      <div class="relative my-4">
        <div
          class="bg-[#277DD2] text-white rounded p-3 max-w-xs mb-3"
          :class="{
            'ml-auto': currentStep >= 4,
          }"
        >
          <p class="font-semibold">Cad - Cam Uygulaması</p>
          <p>05/02/2023</p>
        </div>

        <div
          class="absolute -bottom-3 w-0 h-0 border-l-[10px] border-l-transparent border-t-[17px] border-t-[#277DD2] border-r-[10px] border-r-transparent"
          :class="{
            'left-4': currentStep === 1,
            'left-28': currentStep === 2,
            'left-52 ml-2': currentStep === 3,
            'left-80': currentStep === 4,
            'right-28': currentStep === 5,
            'right-4': currentStep === 6,
          }"
        ></div>
      </div>
      <div class="flex items-center justify-between relative">
        <div v-for="step in 6" :key="step" class="relative">
          <div
            v-if="step <= currentStep"
            class="relative w-12 h-12 bg-[#277DD2] rounded-full flex items-center justify-center z-10"
          >
            <img
              v-if="step === 6"
              :src="success"
              alt="success"
              class="h-6 w-6 text-white"
            />
            <img
              v-if="step === currentStep && step !== 6"
              :src="continueIcon"
              alt="continueIcon"
              class="h-3 w-3 text-white"
            />
          </div>

          <div
            v-else
            class="relative w-12 h-12 bg-white border rounded-full flex items-center justify-center z-10"
          ></div>
        </div>
        <div
          class="bg-[#277DD2] h-1 absolute top-1/2 -translate-y-1/2 mx-auto -z-10"
          :class="{
            'w-10': (currentStep === 1),
            'w-32': (currentStep === 2),
            'w-60': (currentStep === 3),
            'w-80': (currentStep === 4),
            'w-[400px]': (currentStep === 5),
            'w-[530px]': (currentStep === 6),
          }"
        ></div>
      </div>
      <div class="w-full flex mt-4 justify-between items-center">
        <div>
          <p class="opacity-50">Oluşturulma Tarihi</p>
          <p>02/02/2023</p>
        </div>
        <div>
          <p class="opacity-50">Teslim Tarihi</p>
          <p>25/02/2023</p>
        </div>
      </div>
      <div class="w-full mt-2 flex">
        <p class="ml-auto">
          *Teslim tarihine son <span class="text-[#E12901]">23</span> gün kaldı
        </p>
      </div>
    </div>
    <!-- FOLDER SECTION -->
    <div class="mt-8">
      <div class="flex items-center justify-between w-full">
        <h3 class="text-xl">Ekler</h3>
        <button
          @click="popUpManagement.uploadPopUp = true"
          class="flex gap-4 items-center justify-center focus:outline-none border py-1 px-2 bg-white text-[#277DD2] rounded"
        >
          <img :src="documentUploadIcon" alt="documentUploadIcon" /> Dosya yükle
        </button>
      </div>
      <div
        class="bg-[#f4f4f4] w-full h-36 flex items-center justify-center rounded-2xl"
      >
        <div class="bg-[#e6e6e6] py-2 px-28 rounded-full">
          <p class="text-2xl">Dosya Bulunamadı</p>
        </div>
      </div>
      <div
        class="bg-[#f4f4f4] w-full h-36 px-2 flex items-center justify-center rounded-2xl mt-2"
      >
        <div class="relative">
          <textarea
            v-model="comment"
            cols="62"
            rows="4"
            placeholder="Bir yorum ekleyin"
            class="resize-none rounded-xl p-2 focus:outline-none"
          ></textarea>

          <button
            @click="addComment"
            class="absolute right-2 bottom-2 focus:outline-none rounded py-1 px-3 text-sm bg-[#277DD2] text-white"
          >
            Gönder
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import printer from "../assets/printer.svg";
import closeIcon from "../assets/closeIcon.svg";
import rightArrowIcon from "../assets/rightArrowIcon.svg";
import leftArrowIcon from "../assets/leftArrowIcon.svg";
import success from "../assets/success.svg";
import continueIcon from "../assets/continueIcon.svg";
import documentUploadIcon from "../assets/documentUploadIcon.svg";
import { ref } from "vue";
const currentStep = ref(3);
const comment = ref("");
import { popUpManagement } from "../store/temporaryProtezData.js";
const addComment = () => {
  console.log("post comment");
  comment.value = "";
};
</script>
