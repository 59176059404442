<template>
  <div class="flex overflow-hidden">
    <div class="mt-[15px] flex flex-col">
      <div
        v-for="(detail, index) in serviceDetail.slice(1)"
        v-bind:key="detail.serviceDetailNumber"
      >
        <div
          :class="
            store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)] &&
            'bg-[#5CB85C]'
          "
          class="h-[18px] w-[18px] rounded-full border-2 border-[#D9D9D9] ml-2"
        ></div>
        <div 
        :class="
            store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)] ?
            'border-[#5CB85C]':'border-[#D9D9D9]'
          "
        class="h-[23px] w-[9px] border-r-2  ml-2"></div>
      </div>

      <div
        :class="
          store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][
            store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ].length - 1
          ] && 'bg-[#5CB85C]'
        "
        class="h-[18px] w-[18px] rounded-full border-2 border-[#D9D9D9] ml-2"
      ></div>
    </div>
    <div class="ml-2 -mt-[2px] flex-col ">
      <div
        v-for="(detail, index) in serviceDetail"
        v-bind:key="detail.serviceDetailNumber"
        class="mt-[11px] flex cursor-pointer"
        @click="selectExamination(index,detail.serviceDetailName)"
      >
        <div
          :class="
            store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)]
              ? 'border-t-[1px] border-b-[1px] border-l-[1px] bg-[#5CB85C]'
              : 'border-[1px]'
          "
          class="flex h-[30px] w-[94px] items-center justify-center rounded-bl-[4px] rounded-tl-[4px] border-[#D9D9D9]"
        >
          <p
            :class="
              store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)] &&
              ' text-white'
            "
          >
            {{ detail.serviceDetailNumber }} 
          </p>
        </div>
        <div
          :class="
            store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)]
              ? 'border-t-[1px] border-b-[1px] border-r-[1px] bg-[#5CB85C]'
              : 'border-[1px]'
          "
          class="flex h-[30px] w-[260px] items-center justify-center rounded-br-[4px] rounded-tr-[4px] border-[#D9D9D9]"
        >
          <p
            :class="
              store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)] &&
              ' text-white'
            "
          >
            {{ detail.serviceDetailName }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { serviceDetail } from "../dentalChartData/dentalChartData";
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();

const selectExamination = (index,hizmetTuruValue) => {
  store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)] =
    !store.dentalChartServicesDetailButtons[
                  store.dentalChartToothNumberCheckbox.indexOf(true)
                ][parseInt(index)];
     
    store.setDoctorInfoSection({
    tarih: "03/03/2002",
    alan: "46",
    kod: "401010",
    konum: "Ön",
    ucret: "50 TL",
    ucretTuru: "kamuSatisTarifesi",
    hizmetTuru: hizmetTuruValue,
    durum: "Planlandı",
  })

};
</script>