import { reactive } from "vue";
import { doctorInfo } from "./doctorInfoReactiveTypes";
import { data, tooltip } from "./temporaryChartData/barChartConfig";

export const serviceDates = reactive({
  Ocak: [100, 20, 40, 0, 0, 20, 0, 0, 0, 0],
  Şubat: [0, 0, 0, 0, 0, 0, 0, 0, 20, 0],
  Mart: [0, 0, 10, 0, 50, 0, 40, 0, 0, 0],
  Nisan: [0, 60, 0, 0, 0, 0, 0, 0, 0, 0],
  Mayıs: [0, 0, 0, 0, 0, 90, 10, 0, 10, 0],
  Haziran: [0, 0, 0, 50, 0, 0, 0, 0, 0, 0],
  Temmuz: [0, 0, 30, 0, 0, 0, 0, 0, 0, 0],
  Ağustos: [0, 0, 0, 20, 0, 0, 0, 0, 0, 0],
  Eylül: [0, 0, 0, 0, 0, 60, 0, 70, 0, 0],
  Ekim: [0, 0, 0, 0, 30, 0, 70, 0, 0, 0],
  Kasım: [0, 0, 0, 10, 0, 0, 60, 0, 0, 0],
  Aralık: [0, 20, 50, 0, 10, 0, 40, 0, 90, 0],
});

const considerRange = (sayi: number) => {
  if (sayi >= 1 && sayi <= 3) {
    return 0;
  } else if (sayi >= 4 && sayi <= 6) {
    return 1;
  } else if (sayi >= 7 && sayi <= 9) {
    return 2;
  } else if (sayi >= 10 && sayi <= 12) {
    return 3;
  } else if (sayi >= 13 && sayi <= 15) {
    return 4;
  } else if (sayi >= 16 && sayi <= 18) {
    return 5;
  } else if (sayi >= 19 && sayi <= 21) {
    return 6;
  } else if (sayi >= 22 && sayi <= 24) {
    return 7;
  } else if (sayi >= 25 && sayi <= 27) {
    return 8;
  } else if (sayi >= 28 && sayi <= 31) {
    return 9;
  }
};

export const getPoints = () => {
  doctorInfo.hizmetler.forEach((element) => {
    if (element.hizmetKaydi == "Yapıldı") {
      let date = new Date(element.tarih);
      let month = date.toLocaleDateString("tr", {
        month: "long",
      });
      let day = date.getDate();
      const range = considerRange(day);
      serviceDates[month][range] += element.toplam;
    }
  });

  // let date = new Date(doctorInfo.hizmetler[0].tarih);
  // let month = date.toLocaleDateString("tr", {
  //   month: "long",
  // });
  // let day = date.getDate();
  // console.log(day);
  // const range = considerRange(day);
  // console.log(range);
  // serviceDates[month][range] = 10;
  // // tooltip.datasets[0].data[1] = serviceDates.Ocak[2];
  // console.log(serviceDates, "servisdeyt");
  // console.log(tooltip.datasets[0].data, "selamlar");
};
