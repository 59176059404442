<template>
  <div class="h-full w-full overflow-auto">
    <!-- Top section -->

    <div class="flex mt-[43px]">
      <div class="w-[160px] ml-1">
        <p class="font-light mt-[51px]">Mobility</p>
        <p class="font-light mt-[16px]">Implant</p>
        <p class="font-light mt-[16px]">Furcation</p>
        <p class="font-light mt-[16px]">Bleeding on Probing</p>
        <p class="font-light mt-[16px]">Plaque</p>
        <p class="font-light mt-[16px]">Gingival Margin</p>
        <p class="font-light mt-[16px]">Probing Depth</p>
      </div>
      <periodontalChartToothTable
        :tableHeaderNums="[18, 17, 16, 15, 14, 13, 12, 11]"
        :isConverted="false"
        :indexNum="0"
      />
      <periodontalChartToothTable
        class="ml-[73px]"
        :tableHeaderNums="[21, 22, 23, 24, 25, 26, 27, 28]"
        :isConverted="false"
        :indexNum="8"
      />
    </div>
    <!-- Red Lines -->
    <div class="relative">
      <periodontalChartBlueLines class :isConverted="false" :indexNum="0" />
      <periodontalChartBlueLines
        class="ml-[740px]"
        :isConverted="false"
        :indexNum="8"
      />
      <periodontalChartBlueLines
        class="relative top-[100px]"
        :isConverted="false"
        :indexNum="16"
      />
      <periodontalChartBlueLines
        class="ml-[740px] relative top-[100px]"
        :isConverted="false"
        :indexNum="24"
      />
    </div>
    <!-- Red Lines -->
    <div class="relative">
      <periodontalChartRedLines
        class="top-[10px]"
        :isConverted="false"
        :indexNum="0"
      />
      <periodontalChartRedLines
        class="ml-[740px]"
        :isConverted="false"
        :indexNum="8"
      />
      <periodontalChartRedLines
        class="relative top-[100px]"
        :isConverted="false"
        :indexNum="16"
      />
      <periodontalChartRedLines
        class="ml-[740px] relative top-[100px]"
        :isConverted="false"
        :indexNum="24"
      />
    </div>

    <periodontalChartTeethLine :headers="['Buccal', 'Palatal']" />
    <!-- 6 Line Table -->
    <div class="flex mt-[43px]">
      <div class="w-[160px] ml-1">
        <p class="font-light mt-[10px]">Gingival Margin</p>
        <p class="font-light mt-[16px]">Probing Depth</p>
        <p class="font-light mt-[16px]">Plaque</p>
        <p class="font-light mt-[16px]">Bleeding on Probing</p>
        <p class="font-light mt-[16px]">Furcation</p>
        <p class="font-light mt-[16px]">Note</p>
      </div>
      <periodontalChartToothTable6Lines
        :tableHeaderNums="[21, 22, 23, 24, 25, 26, 27, 28]"
      />
      <periodontalChartToothTable6Lines
        class="ml-[73px]"
        :tableHeaderNums="[21, 22, 23, 24, 25, 26, 27, 28]"
      />
    </div>
    <!-- Gray Line -->
    <div class="flex ml-[153px]">
      <img :src="grayLine" alt="" />
      <div
        class="border-[1px] border-[#B7B7B7] rounded-[5px] flex w-[899px] h-[31px] items-center"
      >
        <p class="text-sm ml-[25px]">Mean Probing Depth =</p>
        <p class="text-sm ml-2">0 mm</p>
        <p class="text-sm ml-[80px]">Mean Attachment Level =</p>
        <p class="text-sm ml-2">0 mm</p>
        <p class="text-sm ml-[80px]">0 %</p>
        <p class="text-sm">Plaque</p>
        <p class="text-sm ml-[80px]">0 %</p>
        <p class="text-sm">Bleeding on Probing</p>
      </div>
      <img :src="grayLine" alt="" />
    </div>
    <!-- Bottom section -->
    <!-- 6 Line Table -->
    <div class="flex">
      <div class="w-[160px] ml-1 mt-[77px]">
        <p class="font-light mt-[16px]">Note</p>
        <p class="font-light mt-[16px]">Furcation</p>
        <p class="font-light mt-[16px]">Bleeding on Probing</p>
        <p class="font-light mt-[16px]">Plaque</p>
        <p class="font-light mt-[10px]">Gingival Margin</p>
        <p class="font-light mt-[16px]">Probing Depth</p>
      </div>
      <periodontalChartToothTable6Lines
        class="rotate-180"
        :tableHeaderNums="[21, 22, 23, 24, 25, 26, 27, 28]"
      />
      <periodontalChartToothTable6Lines
        class="ml-[73px] rotate-180"
        :tableHeaderNums="[21, 22, 23, 24, 25, 26, 27, 28]"
      />
    </div>
    <!-- Teeth line -->
    <div class="relative">
      <periodontalChartRedLines
        class="relative top-[10px]"
        :isConverted="false"
        :indexNum="0"
      />
      <periodontalChartRedLines
        class="ml-[740px] top-[10px] relative"
        :isConverted="false"
        :indexNum="8"
      />
      <periodontalChartRedLines
        class="relative top-[100px]"
        :isConverted="false"
        :indexNum="16"
      />
      <periodontalChartRedLines
        class="ml-[740px] relative top-[100px]"
        :isConverted="false"
        :indexNum="24"
      />
    </div>
    <periodontalChartTeethLine :headers="['Lingual', 'Buccal']" />
    <!-- 7 line table -->
    <div class="flex mt-[43px]">
      <div class="w-[160px] ml-1">
        <p class="font-light mt-[10px]">Gingival Margin</p>
        <p class="font-light mt-[16px]">Probing Depth</p>
        <p class="font-light mt-[16px]">Plaque</p>
        <p class="font-light mt-[16px]">Bleeding on Probing</p>
        <p class="font-light mt-[16px]">Furcation</p>
        <p class="font-light mt-[16px]">Implant</p>
        <p class="font-light mt-[16px]">Mobility</p>
      </div>
      <periodontalChartToothTable
        class="rotate-180"
        :tableHeaderNums="[48, 47, 46, 45, 44, 43, 42, 41]"
        :isConverted="true"
        :indexNum="16"
      />
      <periodontalChartToothTable
        class="ml-[73px] rotate-180"
        :tableHeaderNums="[31, 32, 33, 34, 35, 36, 37, 38]"
        :isConverted="true"
        :indexNum="24"
      />
    </div>

    <div class="h-[100px]"></div>
  </div>
</template>

<script setup>
import periodontalChartToothTable from "../components/periodontalChartToothTable.vue";
import periodontalChartTeethLine from "../components/periodontalChartTeethLine.vue";
import periodontalChartToothTable6Lines from "../components/periodontalChartToothTable6Lines.vue";
import periodontalChartRedLines from "../components/periodontalChartRedLines.vue";
import periodontalChartBlueLines from "../components/periodontalChartBlueLines.vue";
import grayLine from "../assets/grayLine.svg";
import { usePeriodontalChartStore } from "../store/periodontalChart";
const store = usePeriodontalChartStore();
</script>

