import { defineStore } from "pinia";
import { globalStore } from "@/store/modules/globalStore";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import axios from "axios";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useUpdateVEMAppointmentStore = defineStore({
  id: "updateVEMAppointment",
  actions: {
    async updateVEMAppointment(appointment: string, patient: string, slot) {
      const personelStoreData = personelStore();

      axios
        .put(
          `${clinicDomain}/appointments`,
          {
            appointment_id: appointment,
            patient_id: patient,
            slot_id: slot,
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          globalStore.isCreateAppointmentSideBarOpen = false;
          globalStore.isAppointmentCreateSuccesful = true;
          setTimeout(() => {
            globalStore.isAppointmentCreateSuccesful = false;
          }, 2000);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error update", error);
          globalStore.isAppointmentCreateFail = true;
          setTimeout(() => {
            globalStore.isAppointmentCreateFail = false;
          }, 2000);
        });
    },
  },
});
