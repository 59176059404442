<template>
  <div class="flex ml-[175px] relative">
    <div class="relative h-[5px]"></div>
    <div
      v-for="(item, index) in store.tableProbingDepth.slice(
        indexNum,
        indexNum + 8
      )"
      :key="index"
      class="flex"
    >
      <div
        :class="
          item[0] == 1
            ? 'top-[120px] border-[#01549B]'
            : 'top-[130px] border-[#01549B]/0'
        "
        class="relative border-b-[4px] w-[20px]"
      ></div>
      <div
        :class="
          item[1] == 1
            ? 'top-[120px] border-[#01549B]'
            : 'top-[130px] border-[#01549B]/0'
        "
        class="relative border-b-[4px] border-[#01549B] w-[20px]"
      ></div>
      <div
        :class="
          item[2] == 1
            ? 'top-[120px] border-[#01549B]'
            : 'top-[130px] border-[#01549B]/0'
        "
        class="relative border-b-[4px] border-[#01549B] w-[20px]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { usePeriodontalChartStore } from "../store/periodontalChart";
const store = usePeriodontalChartStore();
const props = defineProps({
  isConverted: Boolean,
  indexNum: Number,
});
</script>