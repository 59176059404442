<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center gap-3">
      <button class="opacity-40">Hastalar</button>
      <img class="opacity-40" :src="rightArrowHead" alt="rightArrowHead" />
      <button
        @click="pastActivitiesStore.detailScreen = false"
        class=""
        :class="
          pastActivitiesStore.detailScreen
            ? 'opacity-40'
            : 'opacity-100 text-[#277dd2] font-medium'
        "
      >
        {{ doctorsSlotsStore.selectedPatient?.attributes?.name }}
        {{ doctorsSlotsStore.selectedPatient?.attributes?.surname }}
      </button>
      <div
        class="flex items-center gap-3"
        v-if="pastActivitiesStore.detailScreen"
      >
        <img class="opacity-40" :src="rightArrowHead" alt="rightArrowHead" />
        <button
          @click="pastActivitiesStore.detailScreen = false"
          class="text-[#277dd2] font-medium"
        >
          Endodonti(123456-1)
        </button>
      </div>
    </div>
    <form
      class="flex gap-3 border bg-white pl-2 rounded-[10px] text-black w-[350px] focus-within:outline-1 focus-within:outline-double focus-within:outline-[#277dd2]"
    >
      <img :src="searchLogo" alt="search" />
      <input
        type="text"
        class="w-10/12 outline-none py-2"
        placeholder="Hasta ya da TC ara"
      />
    </form>
  </div>
</template>

<script setup>
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import rightArrowHead from "@/modules/doctorScreen/assets/rightArrowHead.svg";
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
const pastActivitiesStore = usePastActivitiesStore();
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
const doctorsSlotsStore = doctorSlotsStore();
</script>
