<template>
  <div class="flex h-screen min-w-[200px] flex-col items-center">
    <div
      class="mt-[52px] flex h-[55px] w-full items-center justify-center bg-[#FAFAFA]"
    >
      <p class="text-xl font-medium text-[#2C3D8F]">Hasta İsmi</p>
    </div>
    <div class="mt-[20px]">

      <p 
  
      class="flex h-[55px] min-w-[200px] w-[200px]    items-center ml-2 border-b-[1px] border-[#2C3D8F]">
      Emel Gün
      </p>
      <p 
  
      class="flex h-[55px] min-w-[200px] w-[200px]  items-center ml-2 border-b-[1px] border-[#2C3D8F]">
      Senem Balaban 
      </p>
      <p 
  
      class="flex h-[55px] min-w-[200px] w-[200px]  items-center ml-2 border-b-[1px] border-[#2C3D8F]">
      Murat Yavuz 
      </p>
    </div>
    
    <button class="mt-[55px] w-full">
      <img :src="plusCircleBlue" class="mx-auto" alt="" />

      <p class="font-medium mt-1">Yeni Kişi Ekle</p>
    </button>
  </div>
</template>


<script setup>
import plusCircleBlue from "../assets/plusCircleBlue.svg";
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();
</script>