import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useGetClinicalSettings = defineStore({
  id: "useGetClinicalSettings",
  state: () => ({
    getClinicalSettingsResponse: null,
    isClinicalSettingsUpdated: false,
  }),
  actions: {
    async getClinicalSettings() {
      const personelStoreData = personelStore();
      await axios
        .get(
          `${adminDomain}/clinical-settings/${
            personelStoreData.personel!.username
          }`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          this.getClinicalSettingsResponse = response.data;
          console.log("getClinicalResponse", this.getClinicalSettingsResponse);
        })
        .catch((error) => {
          console.log("getClinicalError", error);
          return error;
        });
    },
  },
});
