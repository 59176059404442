<template>
  <div class="relative flex">
    <div class="h-screen border-r px-3 pt-28">
      <a href="/polyclinic/examinations">
        <img :src="calendarBlack" class="w-6 h-6" />
      </a>
    </div>
    <div class="overflow-auto relative flex flex-col w-full">
      <personelHeaderNavBar title="Muayene İşlemleri" />
      <div class="py-2 px-4 flex items-center gap-8">
        <div class="flex items-center gap-3">
          <img :src="profileCircle" alt="profile photo" />
          <div class="flex flex-col justify-between">
            <span class="text-red-500 text-xs">ARh(+)</span>
            <span class="text-[#282829] font-medium"
              >{{ patient?.attributes?.name }}
              {{ patient?.attributes?.surname }}</span
            >
            <!-- <span class="text-[#3C3C43] text-xs">(24 yıl 6 ay 14 gün)</span> -->
          </div>
        </div>
        <div class="flex items-center gap-4">
          <img class="opacity-0" :src="profileCircle" alt="profile photo" />
          <img class="opacity-0" :src="profileCircle" alt="profile photo" />
        </div>
        <div class="flex items-center gap-8 text-xs">
          <div class="flex gap-6">
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">TC Kimlik No</p>
                <p class="font-medium">
                  {{ patient?.attributes?.tc_identity_number }}
                </p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Doğum Tarihi</p>
                <p class="font-medium">{{ patient?.attributes?.dob }}</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Hasta Türü</p>
                <p class="font-medium">SSK</p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">GSS Takip No</p>
                <p class="font-medium">14A9B4J</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Katkı Payı</p>
                <p class="font-medium">-</p>
              </div>
            </div>
          </div>

          <div class="flex gap-6">
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Hekim</p>
                <p class="font-medium">Yasemin Önder</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Öğrenci</p>
                <p class="font-medium">-</p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Arş. Gör.</p>
                <p class="font-medium">-</p>
              </div>
              <div class="flex gap-2 items-center">
                <p class="text-[#3C3C43] opacity-60 w-20">Anestezi</p>
                <p class="font-medium">-</p>
              </div>
            </div>
          </div>
        </div>

        <button
          @click="
            dentalChartStore.dentalChartDiagnozSelectedButtonName = '';
            router.push(
              `/polyclinic/examinations/add-anamnesis/${$route.params.patientID}`
            );
          "
          class="ml-auto"
        >
          <img :src="cross" class="w-6 h-6" />
        </button>
      </div>
      <div class="flex bg-[#F5F5F5] flex-grow">
        <div class="flex-[3] bg-white h-full">
          <!-- <div class="bg-[#F5F5F5] p-2 w-full flex justify-between">
              <div class="flex items-center gap-3">
                <img :src="profileCircle" class="w-6 h-6" />
                <img :src="profileCircle" class="w-6 h-6" />
                <img :src="profileCircle" class="w-6 h-6" />
              </div>
              <div class="flex items-center gap-3">
                <img :src="profileCircle" class="w-6 h-6" />
                <img :src="profileCircle" class="w-6 h-6" />
              </div>
            </div> -->

          <div class="bg-white pt-2">
            <div class="flex gap-4 items-center text-xs">
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Hepsini gör
              </button>
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Planlı
              </button>
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Tamamlanmış
              </button>
              <button
                class="bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Devam eden
              </button>
              <button
                class="ml-auto bg-[#F5F5F5] text-[#3C3C43] w-[100px] h-8 flex items-center justify-center rounded-[20px]"
              >
                Tanı ekle
              </button>
            </div>
            <dentalChartTeethComponent :examination="true" class="pb-2" />
          </div>

          <div class="bg-[#F5F5F5] w-full flex items-center border-y text-xs">
            <button
              class="bg-white font-medium w-[180px] h-10 flex items-center justify-center"
            >
              Hizmetler
            </button>
            <button
              class="font-medium w-[180px] h-10 flex items-center justify-center"
            >
              Diagnoz
            </button>
            <button
              class="font-medium w-[180px] h-10 flex items-center justify-center"
            >
              Hekim Puan Tablosu
            </button>
          </div>
          <div
            class="text-xs border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col"
          >
            <div
              class="flex items-center w-full min-h-[30px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
            >
              <div class="h-[80%] w-[20%] flex items-center">
                <p class="font-medium ml-4">TARİH/SAAT</p>
              </div>
              <div class="h-[80%] w-[20%] flex justify-center items-center">
                <p class="font-medium">İŞLEM ADI/KODU</p>
              </div>
              <div class="h-[80%] w-[20%] flex justify-center items-center">
                <p class="font-medium">DİŞ KODU/KONUM</p>
              </div>
              <div class="h-[80%] w-[20%] flex justify-center items-center">
                <p class="font-medium">ADET</p>
              </div>
              <div class="h-[80%] w-[20%] flex justify-center items-center">
                <p class="font-medium">İSTEYEN HEKİM</p>
              </div>
              <div class="h-[80%] w-[20%] flex justify-center items-center">
                <p class="font-medium">UYGULAYAN HEKİM</p>
              </div>
              <div class="h-[80%] w-[20%] flex justify-center items-center">
                <p class="font-medium">BİRİM FİYAT</p>
              </div>
              <div class="h-[80%] w-[20%] flex justify-center items-center">
                <p class="font-medium">TOPLAM FİYAT</p>
              </div>
            </div>
            <div class="h-40 overflow-auto">
              <div
                v-for="(appointment, index) in [1, 2, 3, 4, 5, 6, 7]"
                class="flex items-center w-full min-h-[40px] border-[1px] mx-auto"
                :key="appointment + index"
              >
                <div
                  class="h-[80%] w-[20%] flex items-center gap-2 text-[10px]"
                >
                  <p class="ml-6">16 Tem 2024</p>
                </div>
                <div
                  class="h-[80%] w-[20%] flex flex-col justify-center items-center"
                >
                  <p>401010</p>
                </div>
                <div
                  class="h-[80%] w-[20%] flex flex-col justify-center items-center"
                >
                  <p>46/Ön çene</p>
                </div>
                <div
                  class="h-[80%] w-[20%] flex flex-col justify-center items-center"
                >
                  <p>1</p>
                </div>
                <div class="h-[80%] w-[20%] flex justify-center items-center">
                  <p>Yasemin Önder</p>
                </div>
                <div class="h-[80%] w-[20%] flex justify-center items-center">
                  <p title="Selen Boffffwefgwegzwevgegw" class="w-20 truncate">
                    Yasemin Önder
                  </p>
                </div>
                <div class="h-[80%] w-[20%] flex justify-center items-center">
                  <p>₺1400</p>
                </div>
                <div class="h-[80%] w-[20%] flex justify-center items-center">
                  <p>₺1400</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-[2] bg-white">
          <div class="flex gap-4 justify-center pt-5 mb-4">
            <button
              class="shadow text-[#3C3C43] border w-[150px] h-12 flex items-center justify-center rounded"
            >
              Diagnoz
            </button>
            <button
              class="shadow text-[#3C3C43] border w-[150px] h-12 flex items-center justify-center rounded"
            >
              Tedavi
            </button>
          </div>

          <!-- <div class="flex flex-col gap-3">
              <button
                v-for="sut in oneCikanSutKodlari"
                class="border rounded-3xl py-2 px-4 text-start"
              >
                {{ sut.sutKodu }}
                {{ sut.adi }}
              </button>
            </div> -->
          <div class="flex flex-col gap-3">
            <a
              v-if="dentalChartStore.dentalChartSelectedTeethNumber?.length > 0"
              href="/polyclinic/examinations"
              class="px-5 py-2 border w-fit rounded no-underline"
              >İşlemi tamamla</a
            >
            <input
              class="border text-lg rounded-lg py-2 px-4"
              type="text"
              v-model="searchTerm"
              @input="filterSutCodes"
              placeholder="SUT Kodu ara..."
            />
            <div
              v-if="searchTerm.trim().length < 2"
              class="flex flex-col gap-3 h-[585px] overflow-auto"
            >
              <button
                v-for="sut in oneCikanSutKodlari"
                @click="selectButton(sut.adi)"
                class="border rounded-3xl py-2 px-4 text-start"
                :class="{
                  'text-[#277dd2]':
                    dentalChartStore.dentalChartDiagnozSelectedButtonName ===
                    sut.adi,
                }"
              >
                {{ sut.sutKodu }}
                {{ sut.adi }}
              </button>
            </div>
            <div v-else class="flex flex-col gap-3 h-[585px] overflow-auto">
              <button
                v-for="sut in filteredSutCodes"
                @click="selectButton(sut.adi)"
                class="border rounded-3xl py-2 px-4 text-start"
                :class="{
                  'text-[#277dd2]':
                    dentalChartStore.dentalChartDiagnozSelectedButtonName ===
                    sut.adi,
                }"
              >
                {{ sut.sutKodu }}
                {{ sut.adi }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dentalChartCursorFollower />
  </div>
</template>

<script setup>
import cross from "@/modules/medula/provision/assets/crossIcon.svg";
import calendarBlack from "@/modules/doctorSlots/assets/calendarBlack.svg";

import personelHeaderNavBar from "@/modules/personel/components/personelHeaderNavbar.vue";
import { oneCikanSutKodlari, sutKodlari } from "./data";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import patientCard from "@/modules/doctorScreen/components/doctorPatientList/patientCard.vue";
import navAndSearch from "@/modules/doctorScreen/components/doctorPatientList/navAndSearch.vue";
import pastActivities from "@/modules/doctorScreen/components/doctorPatientList/pastActivities.vue";
import chosenExamination from "../components/doctorPatientList/pastActivitiesSubs/chosenExamination.vue";
import { useDentalChart } from "@/modules/dentalChart/dentalChartMain/dentalChartStore/dentalChartStore";
const dentalChartStore = useDentalChart();
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
import { onMounted, ref } from "vue";
const pastActivitiesStore = usePastActivitiesStore();
import { useDentalChartPlanStore } from "@/modules/doctorScreen/store/dentalChartPlanStore";
const dentalChartPlanStore = useDentalChartPlanStore();
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useRouter } from "vue-router";
const router = useRouter();
let patientID = router.currentRoute.value.params.patientID;
const patient = ref();
const searchTerm = ref("");

const filteredSutCodes = ref([]);

const filterSutCodes = () => {
  if (searchTerm.value.trim().length < 2) {
    return;
  }
  filteredSutCodes.value = sutKodlari.filter((sut) =>
    sut.sutKodu.startsWith(searchTerm.value)
  );
};

const selectButton = (sutAdi) => {
  if (dentalChartStore.dentalChartDiagnozSelectedButtonName === sutAdi) {
    dentalChartStore.dentalChartDiagnozSelectedButtonName = "";
    return;
  }
  dentalChartStore.dentalChartDiagnozSelectedButtonName = sutAdi;
};

const getPatientStore = useGetPatientStore();
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
import DentalChartTeethComponent from "@/modules/dentalChart/dentalChartMain/components/dentalChartTeethComponent.vue";
import DentalChartCursorFollower from "@/modules/dentalChart/dentalChartMain/components/dentalChartCursorFollower.vue";
const doctorsSlotsStore = doctorSlotsStore();

// onMounted(async () => {
//   dentalChartPlanStore.examinationPlans = {
//     activePlans: [],
//     completedPlans: [],
//     remainingPlans: [],
//   };
// });

onMounted(async () => {
  patient.value = await getPatientStore.getPatientA(patientID);
});

onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
});

const handleKeyDown = (event) => {
  if (event.key === "Escape") {
    dentalChartStore.dentalChartDiagnozSelectedButtonName = "";
  }
};
</script>
