import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useUpdateClinicalSettings = defineStore({
  id: "useUpdateClinicalSettings",
  state: () => ({}),
  actions: {
    async updateClinicalSettings(
      clinicName,
      doctorName,
      defaultView,
      unitName
    ) {
      const personelStoreData = personelStore();
      await axios
        .put(
          `${adminDomain}/clinical-settings`,
          {
            klinik_adi: clinicName,
            doktor_adi: doctorName,
            randevu_defteri_varsayilan_gorunum: defaultView,
            randevu_defteri_varsayilan_birim: unitName,
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("updateClinicalResponse", response);
        })
        .catch((error) => {
          console.log("updateClinicalError", error);
        });
    },
  },
});
