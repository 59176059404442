<template>
  <div class="flex flex-col">
    <button
      ref="soru"
      @click="
        sliderBarArrowRef[0] === true
          ? toggleSliderBarArrowRef(0, false)
          : toggleSliderBarArrowRef(0, true)
      "
      class="mx-auto flex h-10 w-full max-w-[645px] flex-row items-center"
    >
      <p class="text-2xl text-[#707070]">09:00</p>
      <img
        :src="
          sliderBarArrowRef[0] == true ? arrowUpBlackBig : arrowDownBlackBig
        "
        class="ml-5"
        alt=""
      />
    </button>
    <div
      class="mx-auto h-0 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
    ></div>
    <div v-show="sliderBarArrowRef[0] === true" class="flex h-[230px]">
      <div
        :class="sliderBarArrowRef == '1' ? 'flex ' : ''"
        class="flex w-full flex-col border-l-[20px] border-[#5CB85C]"
      >
        <div class="mt-2 flex h-[107px] w-full justify-around">
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:10"
            bitisSaati="09:20"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Sevk Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:20"
            bitisSaati="09:30"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="İptal Edildi"
          />
        </div>
        <div class="mt-2 flex h-[107px] w-full justify-around">
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:20"
            bitisSaati="09:30"
            bolum="Randevu girilmemiş"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:10"
            bitisSaati="09:20"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Sevk Hasta"
          />
        </div>
      </div>
    </div>
    <hastaListesiHastaBilgiKisaBox
      firstHastaIsmi="Gokhun CELİK"
      firstBaslangicSaati="09:00"
      firstBitisSaati="09:10"
      firstDoktorIsmi="Safak BORAZAN"
      secondHastaIsmi="Gunes SARI"
      secondBaslangicSaati="09:10"
      secondBitisSaati="09:20"
      secondDoktorIsmi="Selen BOZ"
      thirdHastaIsmi="Melisa CICEK"
      thirdBaslangicSaati="09:20"
      thirdBitisSaati="09:30"
      thirdDoktorIsmi="Alper GUCLU"
      v-show="sliderBarArrowRef[0] === false"
    />

    <button
      ref="soru"
      @click="
        sliderBarArrowRef[1] === true
          ? toggleSliderBarArrowRef(1, false)
          : toggleSliderBarArrowRef(1, true)
      "
      class="mx-auto flex h-10 w-full max-w-[645px] flex-row items-center"
    >
      <p class="text-2xl text-[#707070]">09:30</p>

      <img
        :src="
          sliderBarArrowRef[1] === true ? arrowUpBlackBig : arrowDownBlackBig
        "
        class="ml-5"
        alt="arrow"
      />
    </button>
    <div
      class="mx-auto h-0 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
    ></div>
    <div class="flex h-[230px]" v-show="sliderBarArrowRef[1] === true">
      <div
        :class="sliderBarArrowRef == '2' ? 'flex ' : ''"
        class="flex w-full flex-col border-l-[20px] border-[#5CB85C]"
      >
        <div class="mt-2 flex h-[107px] w-full justify-around">
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
        </div>
        <div class="mt-2 flex h-[107px] w-full justify-around">
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
        </div>
      </div>
    </div>
    <hastaListesiHastaBilgiKisaBox
      firstHastaIsmi="Gokhun CELİK"
      firstBaslangicSaati="09:00"
      firstBitisSaati="09:10"
      firstDoktorIsmi="Safak BORAZAN"
      secondHastaIsmi="Gunes SARI"
      secondBaslangicSaati="09:10"
      secondBitisSaati="09:20"
      secondDoktorIsmi="Selen BOZ"
      thirdHastaIsmi="Melisa CICEK"
      thirdBaslangicSaati="09:20"
      thirdBitisSaati="09:30"
      thirdDoktorIsmi="Alper GUCLU"
      v-show="sliderBarArrowRef[1] === false"
    />
    <button
      ref="soru"
      @click="
        sliderBarArrowRef[1] === true
          ? toggleSliderBarArrowRef(1, false)
          : toggleSliderBarArrowRef(1, true)
      "
      class="mx-auto flex h-10 w-full max-w-[645px] flex-row items-center"
    >
      <p class="text-2xl text-[#707070]">09:30</p>

      <img
        :src="
          sliderBarArrowRef[1] === true ? arrowUpBlackBig : arrowDownBlackBig
        "
        class="ml-5"
        alt="arrow"
      />
    </button>
    <div
      class="mx-auto h-0 w-full max-w-[645px] border-t-2 border-[#D9D9D9]"
    ></div>
    <div class="flex h-[230px]" v-show="sliderBarArrowRef[1] === true">
      <div
        :class="sliderBarArrowRef == '2' ? 'flex ' : ''"
        class="flex w-full flex-col border-l-[20px] border-[#5CB85C]"
      >
        <div class="mt-2 flex h-[107px] w-full justify-around">
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
        </div>
        <div class="mt-2 flex h-[107px] w-full justify-around">
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
          <hastaListesiHastaBilgiBox
            baslangicSaati="09:00"
            bitisSaati="09:10"
            hastaIsmi="Gokhun CELİK"
            bolum="Endodonti Bölümü"
            hastaDurumu="Yeni Hasta"
          />
        </div>
      </div>
    </div>
    <hastaListesiHastaBilgiKisaBox
      firstHastaIsmi="Gokhun CELİK"
      firstBaslangicSaati="09:00"
      firstBitisSaati="09:10"
      firstDoktorIsmi="Safak BORAZAN"
      secondHastaIsmi="Gunes SARI"
      secondBaslangicSaati="09:10"
      secondBitisSaati="09:20"
      secondDoktorIsmi="Selen BOZ"
      thirdHastaIsmi="Melisa CICEK"
      thirdBaslangicSaati="09:20"
      thirdBitisSaati="09:30"
      thirdDoktorIsmi="Alper GUCLU"
      v-show="sliderBarArrowRef[1] === false"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import arrowDownBlackBig from "../assets/arrowDownBlackBig.svg";
import arrowUpBlackBig from "../assets/arrowUpBlackBig.svg";
import hastaListesiHastaBilgiBox from "./hastaListesiHastaBilgiBox.vue";
import hastaListesiHastaBilgiKisaBox from "./hastaListesiHastaBilgiKisaBox.vue";
let sliderBarArrowRef = ref([true, true]);

const toggleSliderBarArrowRef = (itemsPosition, valueOfRef) => {
  sliderBarArrowRef.value[itemsPosition] = valueOfRef;
};
</script>
