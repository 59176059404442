<script>
import { defineComponent } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useMedula } from "../../medulaStore/medulaStore";

export default defineComponent({
  components: {
    FullCalendar,
  },

  data() {
    return {
      calendarOptions: {
        plugins: [
          timeGridPlugin,
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        customButtons: {
          Bugün: {
            text: "Bugün",
            //icon: "fa-regular fa-user",
            click: this.handleTitleClick,
            class: "fc-today-button",
          },
        },

        datesSet: async function (info) {
          console.log("datesSet changed", info.view.calendar);
          useMedula().applicationDateRange[0] = info.start;
          useMedula().applicationDateRange[1] = info.end;
        },

        themeSystem: "bootstrap4",
        themeButtonIcons: true,

        headerToolbar: {
          start: "create",
          left: "prev,title,next",
          center: "",
          right: "",
        },
        views: {
          timeGridWeek: {
            allDaySlot: false, // all-day slotunu kapat
            titleFormat: { year: "numeric", month: "long", day: "numeric" },
          },
        },
        timeZone: "UTC",
        handleWindowResize: true,
        windowResizeDelay: 100,
        firstDay: 1,

        initialView: "timeGridWeek",
        //initialEvents: INITIAL_EVENTS,
        slotMinTime: "09:00:00",
        slotMaxTime: "16:00:00",
        slotDuration: "01:00:00",

        editable: true,
        selectable: true,
        selectMirror: true,
        weekends: true,
        // events: INITIAL_EVENTS, // same thing with initalevents
        select: this.handleDateSelect,
        eventsSet: this.handleEvents,
        eventAdd: this.handleAddEvents,
        eventMaxStack: 3,
        height: 900,
      },
      currentEvents: [],
      title: "", //for show the today's date
    };
  },
  async mounted() {
    this.setTurkishLocale(); //  language turkish
  },

  methods: {
    handleTitleClick() {
      const calendarApi = this.$refs.calendar.getApi();
      calendarApi.today(); // Takvimi bugüne getirin
    },

    setTurkishLocale() {
      this.calendarOptions.locale = "tr"; // Dil ayarını Türkçe olarak belirle
      this.calendarOptions.slotLabelFormat = {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      }; // Saat formatını Türkçe ayarla
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      let title = prompt("Please enter a new title for your event");
      let calendarApi = selectInfo.view.calendar;
      this.title = title;
      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },
    handleEventClick(clickInfo) {
      if (
        confirm(
          `Are you sure you want to delete the event '${clickInfo.title}'`
        )
      ) {
        clickInfo.remove();
      }
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleAddEvents() {
      console.log("add events here");
    },
  },
});
</script>

<template>
  <div class="actAppointmentsDate">
    <FullCalendar
      class="calendar-app-calendar"
      :options="calendarOptions"
      ref="calendar"
    >
    </FullCalendar>
  </div>
</template>

<style scoped>
@import "@/modules/medula/provision/components/actAppointmentsDateCSS/actAppointmentsDate.css";
</style>
