<template>
  <div class="bg-[#F3F6F9] py-3 px-4 rounded flex items-center gap-3 my-3">
    <img :src="profileCircle" alt="profile photo" />
    <h2 class="font-medium text-xl min-w-[300px]">
      {{ doctorsSlotsStore.selectedPatient?.attributes?.name }}
      {{ doctorsSlotsStore.selectedPatient?.attributes?.surname
      }}<span
        class="ml-2"
        :class="
          doctorsSlotsStore.selectedPatient?.attributes?.gender === 'erkek'
            ? 'text-[#0457F8]'
            : doctorsSlotsStore.selectedPatient?.attributes?.gender === 'kadın'
            ? 'text-[#DE3163]'
            : ''
        "
        >{{
          doctorsSlotsStore.selectedPatient?.attributes?.gender === "erkek"
            ? "(E)"
            : doctorsSlotsStore.selectedPatient?.attributes?.gender === "kadın"
            ? "(K)"
            : ""
        }}</span
      >
    </h2>
    <div
      class="ml-2 flex"
      :class="!pastActivitiesStore.detailScreen ? 'flex-col gap-2' : 'flex-row'"
    >
      <div class="flex">
        <div
          class="flex"
          :class="
            !pastActivitiesStore.detailScreen
              ? 'min-w-[422px]'
              : 'min-w-[302px]'
          "
        >
          <p
            :class="
              !pastActivitiesStore.detailScreen
                ? 'min-w-[142px]'
                : 'min-w-[102px] '
            "
          >
            TC No
          </p>
          <p class="font-medium">
            {{
              doctorsSlotsStore.selectedPatient?.attributes?.tc_identity_number
            }}
          </p>
        </div>
        <div class="min-w-[205px] flex">
          <p class="min-w-[105px]">Kan Grubu</p>
          <p class="font-medium">AB</p>
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="min-w-[422px] flex">
            <p class="min-w-[142px]">Doğum Tarihi</p>
            <p
              v-if="doctorsSlotsStore.selectedPatient?.attributes?.dob"
              class="font-medium"
            >
              {{
                formatDateFromISO8601(
                  doctorsSlotsStore.selectedPatient?.attributes?.dob
                )
              }}<span class="font-light ml-1 text-sm"
                >({{
                  formatAgeString(
                    doctorsSlotsStore.selectedPatient?.attributes?.dob
                  )
                }})</span
              >
            </p>
          </div>
          <div class="min-w-[275px] flex">
            <p class="min-w-[105px]">Telefon No</p>
            <p class="font-medium">
              {{ doctorsSlotsStore.selectedPatient?.attributes?.phone_number }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
const pastActivitiesStore = usePastActivitiesStore();
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
const doctorsSlotsStore = doctorSlotsStore();
function formatDateFromISO8601(isoDateString) {
  const dateObject = new Date(isoDateString);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Ay indeksi 0'dan başlar
  const year = dateObject.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;
  return formattedDate;
}

function formatAgeString(dob) {
  const birthDate = new Date(dob);
  const today = new Date();

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();
  let ageDays = today.getDate() - birthDate.getDate();

  if (ageDays < 0) {
    ageMonths--;
    const tempDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      birthDate.getDate()
    );
    ageDays = Math.floor((today - tempDate) / (1000 * 60 * 60 * 24));
  }

  if (ageMonths < 0) {
    ageYears--;
    ageMonths += 12;
  }

  const ageString = `${ageYears} yıl ${ageMonths} ay ${ageDays} gün`;
  return ageString;
}
</script>
