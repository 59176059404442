<template>
  <div
    v-if="store.dentalChartDiagnozSelectedButtonName"
    ref="myDiv"
    v-bind:style="{ left: computedLeft, top: computedTop }"
    id="my-div"
  >
    <p class="text-[#3C3C43]">
      {{ store.dentalChartDiagnozSelectedButtonName }}
    </p>
  </div>
</template>

<script setup>
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();
import { ref } from "vue";

// // Cursor follower
const myDiv = ref(null);
const computedLeft = ref();
const computedTop = ref();
// let urDiv = document.querySelector("#my-div");

// //Detect touch device
const move = (e) => {
  var x = e.pageX;
  var y = e.pageY;
  computedLeft.value = x - 0 + "px";
  computedTop.value = y + 40 + "px";
};
try {
  document.addEventListener("mousemove", (e) => {
    move(e);
  });
} catch (e) {
  console.log(e);
  document.removeEventListener("mousemove", myDiv);
}

// if (isSearchHasValue) {
//   document.removeEventListener("mousemove", myDiv);
// }
</script>

<style scoped>
#my-div {
  background-color: #c6dcf5;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-left: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px rgba(16, 0, 54, 0.2);
  transition: 0.1s ease-out;
}
</style>
