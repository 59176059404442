import { defineStore } from "pinia";
export const useRadiologyStore = defineStore("useRadiologyStore", {
  id: "useRadiologyStore",
  state: () => ({
    tedaviTuruFilter: "Hepsi",
    tedaviTipiFilter: "Hepsi",
    statuFilter: "Hepsi",
    birimFilter: "Hepsi",
    radiologySearch: "",
    radiologyOpenSideBar: false,
    radiologyRandevuDefteri: false,
  }),
});
