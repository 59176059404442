<template>
  <div v-if="store.studentAssistantFilterAcceptFlow===false" class="ml-[10px] flex h-[27px]">
    <div v-if="store.studentAssistantFilterFlow === 1">
      <div
        class="absolute top-11 left-[533px] min-h-[193px] w-[602px] rounded-[10px] border-2 border-[#D9D9D9] bg-white"
      >
        <div class="ml-4 mt-[15px] flex">
          <div
            class="mt-[28px] flex h-[35px] w-[223px] items-center rounded-[5px] border-[1px] border-[#D9D9D9]"
          >
            <p class="ml-5 font-light">Dönem</p>
          </div>
          <!-- Button -->
          <div class="flex items-center border-2 h-[35px] mt-[28px] ml-[24px] w-[254px] rounded-[5px] z-10">

              <studentAssistantFilterButtonFilterButton
              class="mt-1"
                valueOfTag="23 Ocak - 17 Mart 2023"
                :valuesOfItems="[
                  '23 Ocak - 17 Mart 2023',
                  '20 Mart - 12 Mayıs 2023',
                  '15 Mayıs - 7 Temmuz 2023',
                ]"
              />
          </div>
        </div>
        <div v-if="adresIlButtonDropDownSelectedValue==='Başka Bölüm Ekle'" class="flex mt-[67px] ml-6">
            <img :src="plusCircle" alt="">
            <!-- Flow Button -->
            <div class="ml-[10px]  mt-[8px] flex h-[27px]">
                <div v-if=" store.studentAssistantFilter " class="relative w-[240px] text-lg">
                  <div class="flex">
                    <button
                      class="flex w-full items-center rounded-lg bg-white px-1"
                      @click="adresIlButtonDropDown = !adresIlButtonDropDown"
                      @blur="adresIlButtonDropDown = false"
                    >
                      <p class="mr-2 flex w-[150px] text-sm font-light">
                        <span>{{ adresIlButtonDropDownSelectedValue }}</span>
                      </p>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="ml-[40px] h-4 w-4 transform transition-transform duration-200 ease-in-out"
                        :class="adresIlButtonDropDown ? 'rotate-180' : 'rotate-0'"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                  </div>
                  <transition
                    v-show="adresIlButtonDropDown"
                    class="z-50 absolute w-[200px]"
                    enter-active-class="transform transition duration-500 ease-custom"
                    enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                    enter-to-class="translate-y-0 scale-y-100 opacity-100"
                    leave-active-class="transform transition duration-300 ease-custom"
                    leave-class="translate-y-0 scale-y-100 opacity-100"
                    leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
                  >
                    <ul
                      class="cursor-pointer overflow-hidden rounded-lg bg-white shadow-lg"
                    >
                      <li
                        v-for="(option, index) in adresIlButtonDropDownValues"
                        :key="index"
                        class="flex max-h-[20px] w-full items-center duration-300 hover:bg-gray-200"
                        @mousedown.prevent="setadresIlButtonDropDownSelectedValue(option)"
                      >
                        <p class="text-sm font-light">
                          <span>{{ option }}</span>
                        </p>
                      </li>
                    </ul>
                  </transition>
                </div>
              </div>
        </div>
        <!-- 2. Buttion -->
        <div
        v-if="adresIlButtonDropDownSelectedValue!=='Başka Bölüm Ekle'"
        class="ml-4 mt-[15px] mb-[20px] flex">
            <div
              class="mt-[28px] flex h-[35px] w-[223px] items-center rounded-[5px] border-[1px] border-[#D9D9D9]"
            >
              <p class="ml-5 font-light">Öğrenci Ad/Soyad</p>
            </div>
            <!-- Button -->
            <div class="flex items-center border-2 h-[35px] mt-[28px] ml-[24px] w-[254px] rounded-[5px] z-10">
  
                <studentAssistantFilterButtonFilterButton
                class="mt-1"
                  valueOfTag="A’dan  --> E harfine"
                />
            </div>
          </div>
          <!-- Continue Button -->
          <button 
          v-if="adresIlButtonDropDownSelectedValue!=='Başka Bölüm Ekle'"
          @click="store.studentAssistantFilterAcceptFlow=true"
          class="flex items-center justify-center border-2 my-10 ml-[200px] w-[100px] h-[30px] rounded-[5px] mx-auto">
                <p>Devam Et</p>
          </button>

      </div>
    </div>
  </div>
</template>
      
      
      <script setup>
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();

import studentAssistantFilterButtonFilterButton from "./studentAssistantFilterButtonFilterButton.vue";
import plusCircle from '../assets/plusCircle.svg'
import { ref} from 'vue'
let adresIlButtonDropDown = ref(false);
let adresIlButtonDropDownSelectedValue = ref('Başka Bölüm Ekle');
let adresIlButtonDropDownValues = ref(['Hasta Dosya No','Dönem','Grup','Doktor Endikasyon','Öğrenci Numarası','Öğrenci Ad/Soyad']);
const setadresIlButtonDropDownSelectedValue = (option) => {
  adresIlButtonDropDownSelectedValue.value = option;
  adresIlButtonDropDown.value = false;
};
</script>


<style scoped>
.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>