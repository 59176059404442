<template>
  <div class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col">
    <div
      class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
    >
      <div class="flex items-center w-12">
        <p class="font-medium"></p>
      </div>
      <div class="h-[80%] w-[270px] flex justify-center items-center">
        <p class="font-medium">DOKTOR</p>
      </div>
      <div class="h-[80%] w-[200px] flex justify-center items-center">
        <p class="font-medium">UNVAN</p>
      </div>
      <div class="h-[80%] w-[200px] flex justify-center items-center">
        <p class="font-medium">TELEFON NO</p>
      </div>
      <div class="h-[80%] w-[230px] flex justify-center items-center">
        <p class="font-medium">E-POSTA</p>
      </div>
      <div class="h-[80%] w-[300px] flex justify-center items-center">
        <p class="font-medium">ADRES</p>
      </div>
      <div class="h-[80%] w-[200px] flex justify-center items-center">
        <p class="font-medium">DURUM</p>
      </div>
      <div class="h-[80%] w-[200px] flex justify-center items-center">
        <p class="font-medium"></p>
      </div>
    </div>
    <div
      v-for="(doctor, index) in klinikYonetimStore.klinikUnitScreenDoctors"
      class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
      :key="index"
    >
      <div class="flex flex-col w-12">
        <input type="checkbox" name="" id="" class="ml-auto rounded w-6" />
      </div>
      <div class="h-[80%] w-[270px] flex justify-center items-center gap-1">
        <img :src="profileCircle" alt="" class="w-10" />
        <p>{{ doctor.ad }}&nbsp;{{ doctor.soyadi }}</p>
      </div>
      <div class="h-[80%] w-[200px] flex flex-col justify-center items-center">
        <p>{{ doctor.unvan_kodu }}</p>
      </div>
      <div class="h-[80%] w-[200px] flex flex-col justify-center items-center">
        <p>{{ doctor.cep_telefonu }}</p>
      </div>
      <div class="h-[80%] w-[230px] flex flex-col justify-center items-center">
        <p>{{ doctor.eposta_adresi }}</p>
      </div>
      <div
        class="h-[80%] w-[300px] flex justify-center items-center text-center"
      >
        <p>{{ doctor.acik_adres }}</p>
      </div>
      <div class="h-[80%] w-[200px] flex justify-center items-center">
        <p>X</p>
      </div>
      <div class="h-[80%] w-[200px] flex flex-col justify-center items-center">
        <button
          class="ml-12 focus:outline-double relative group focus:outline-1 focus:outline-[#277dd2] mt-2 w-fit px-2"
          @click="activeIndex = index"
        >
          <img :src="moreDotsIcon" alt="moreDots" />
          <div
            class="text-sm text-left absolute z-[100] text-white hidden group-focus-within:block group-hover:block right-0 py-1 rounded-lg"
          >
            <button
              class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
            >
              Profili Görüntüle
            </button>
            <button
              class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
            >
              Klinikten Çıkar
            </button>
            <button
              class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-32 py-1"
            >
              Yeni Kliniğe Ekle
            </button>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import moreDotsIcon from "@/modules/protez/assets/moreDots.svg";
import { useRouter } from "vue-router";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import { onMounted } from "vue";
import { useKlinikYonetimStore } from "../../store/klinikYonetim";

const klinikYonetimStore = useKlinikYonetimStore();



//düzelt
</script>
