<template>
  <div class="text-[#40464C] flex flex-col gap-14">
    <div class="flex gap-20">
      <!-- provision setting -->
      <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
        <p class="font-medium mb-3">Provizyon Tipi (Varsayılan Seçenek)</p>
        <div class="flex">
          <Combobox v-model="provisionSettings.provisionType.value">
            <div class="relative w-[270px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="
                    (provisionType) =>
                      provisionType.text === '-' ? '' : provisionType.text
                  "
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredProvisionTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="provisionType in filteredProvisionTypes"
                    as="template"
                    :key="provisionType.id"
                    :value="provisionType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ provisionType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
          <div class="ml-3 flex items-center gap-3">
            <p>Kilitle</p>
            <button
              @click="
                provisionSettings.provisionType.isLocked =
                  !provisionSettings.provisionType.isLocked
              "
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                provisionSettings.provisionType.isLocked
                  ? 'bg-[#026D4D]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': provisionSettings.provisionType.isLocked,
                }"
              ></div>
            </button>
          </div>
        </div>
      </div>

      <!-- exceptional setting -->
      <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
        <p class="font-medium mb-3">İstisnai Hal (Varsayılan Seçenek)</p>
        <div class="flex">
          <Combobox v-model="provisionSettings.exceptionalType.value">
            <div class="relative w-[270px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="
                    (exceptionalType) =>
                      exceptionalType.text === 'İstisnai hal yok'
                        ? ''
                        : exceptionalType.text === '-'
                        ? ''
                        : exceptionalType.text
                  "
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredExceptionalTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="exceptionalType in filteredExceptionalTypes"
                    as="template"
                    :key="exceptionalType.id"
                    :value="exceptionalType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ exceptionalType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
          <div class="ml-3 flex items-center gap-3">
            <p>Kilitle</p>
            <button
              @click="
                provisionSettings.exceptionalType.isLocked =
                  !provisionSettings.exceptionalType.isLocked
              "
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                provisionSettings.exceptionalType.isLocked
                  ? 'bg-[#026D4D]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': provisionSettings.exceptionalType.isLocked,
                }"
              ></div>
            </button>
          </div>
        </div>
      </div>

      <!-- treatment type setting -->
      <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
        <p class="font-medium mb-3">Tedavi Tipi (Varsayılan Seçenek)</p>
        <div class="flex">
          <Combobox v-model="provisionSettings.treatmentType.value">
            <div class="relative w-[270px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="
                    (treatmentType) =>
                      treatmentType.text === '-' ? '' : treatmentType.text
                  "
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredTreatmentTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="treatmentType in filteredTreatmentTypes"
                    as="template"
                    :key="treatmentType.id"
                    :value="treatmentType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ treatmentType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
          <div class="ml-3 flex items-center gap-3">
            <p>Kilitle</p>
            <button
              @click="
                provisionSettings.treatmentType.isLocked =
                  !provisionSettings.treatmentType.isLocked
              "
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                provisionSettings.treatmentType.isLocked
                  ? 'bg-[#026D4D]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': provisionSettings.treatmentType.isLocked,
                }"
              ></div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-20">
      <!-- follow type setting -->
      <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
        <p class="font-medium mb-3">Takip tipi (Varsayılan Seçenek)</p>
        <div class="flex">
          <Combobox v-model="provisionSettings.followType.value">
            <div class="relative w-[270px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="
                    (followType) =>
                      followType.text === '-' ? '' : followType.text
                  "
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredFollowTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="followType in filteredFollowTypes"
                    as="template"
                    :key="followType.id"
                    :value="followType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ followType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
          <div class="ml-3 flex items-center gap-3">
            <p>Kilitle</p>
            <button
              @click="
                provisionSettings.followType.isLocked =
                  !provisionSettings.followType.isLocked
              "
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                provisionSettings.followType.isLocked
                  ? 'bg-[#026D4D]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': provisionSettings.followType.isLocked,
                }"
              ></div>
            </button>
          </div>
        </div>
      </div>

      <!-- treatment kind setting -->
      <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
        <p class="font-medium mb-3">Tedavi Türü (Varsayılan Seçenek)</p>
        <div class="flex">
          <Combobox v-model="provisionSettings.treatmentKind.value">
            <div class="relative w-[270px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="
                    (treatmentKindType) =>
                      treatmentKindType.text === '-'
                        ? ''
                        : treatmentKindType.text
                  "
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="
                      filteredTreatmentKindTypes.length === 0 && query !== ''
                    "
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="treatmentKindType in filteredTreatmentKindTypes"
                    as="template"
                    :key="treatmentKindType.id"
                    :value="treatmentKindType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ treatmentKindType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
          <div class="ml-3 flex items-center gap-3">
            <p>Kilitle</p>
            <button
              @click="
                provisionSettings.treatmentKind.isLocked =
                  !provisionSettings.treatmentKind.isLocked
              "
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                provisionSettings.treatmentKind.isLocked
                  ? 'bg-[#026D4D]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': provisionSettings.treatmentKind.isLocked,
                }"
              ></div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <button @click="deleteIt">delete</button> -->
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, reactive } from "vue";
import _ from "lodash";
import Select2 from "vue3-select2-component";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
let query = ref("");
import {
  optionTypes,
  exceptionalTypes,
  fallowypes,
  treatmentKindTypes,
  treatmentTypes,
} from "@/modules/medula/provision/components/dropDownTypes";

import { useGetProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/getProvisionSettings";
const getProvisionSettingsStore = useGetProvisionSettings();
import { useCreateProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/createProvisionSettings";
const createProvisionSettingsStore = useCreateProvisionSettings();
import { useUpdateProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/updateProvisionSettings";
const updateProvisionSettingsStore = useUpdateProvisionSettings();
import { useDeleteProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/deleteProvisionSettings";
const deleteProvisionSettingsStore = useDeleteProvisionSettings();

onMounted(async () => {
  await getProvisionSettingsStore.getProvisionSettings();
  console.log(
    "getProvisionSettingsStore",
    getProvisionSettingsStore.getProvisionSettingsResponse
  );
  if (getProvisionSettingsStore.getProvisionSettingsResponse === null) {
    await createProvisionSettingsStore.createProvisionSettings();
  } else {
    console.log(
      "getProvisionSettingsStore",
      getProvisionSettingsStore.getProvisionSettingsResponse
    );
    const properties = [
      { name: "provisionType", options: optionTypes, key: "provizyon_tipi" },
      {
        name: "exceptionalType",
        options: exceptionalTypes,
        key: "istisnai_hal",
      },
      { name: "treatmentType", options: treatmentTypes, key: "tedavi_tipi" },
      { name: "followType", options: fallowypes, key: "takip_tipi" },
      {
        name: "treatmentKind",
        options: treatmentKindTypes,
        key: "tedavi_turu",
      },
    ];

    properties.forEach((property) => {
      provisionSettings[property.name].value = property.options.value?.find(
        (option) =>
          option.text ===
          getProvisionSettingsStore.getProvisionSettingsResponse[property.key]
      );

      if (provisionSettings[property.name].value) {
        provisionSettings[property.name].isLocked = true;
      }
    });

    console.log(
      "provisionSettings.provisionType.value",
      provisionSettings.exceptionalType.value
    );
  }
  const watchLockedValue = (property) => {
    watch(
      () => provisionSettings[property].isLocked,
      (newValue) => {
        if (
          newValue &&
          provisionSettings[property].value &&
          provisionSettings[property].value.text !== "-"
        ) {
          updateSettings();
          console.log("updateee", provisionSettings[property].value);
        } else if (
          !newValue &&
          provisionSettings[property].value &&
          provisionSettings[property].value.text !== "-"
        ) {
          provisionSettings[property].value = { id: "-", text: "-" };
          updateSettings();
        }
      }
    );

    watch(
      () => provisionSettings[property].value,
      (newValue) => {
        if (newValue && provisionSettings[property].isLocked) {
          updateSettings();
        }
      }
    );
  };
  watchLockedValue("provisionType");
  watchLockedValue("exceptionalType");
  watchLockedValue("treatmentType");
  watchLockedValue("followType");
  watchLockedValue("treatmentKind");
});

const updateSettings = async () => {
  await updateProvisionSettingsStore.updateProvisionSettings(
    provisionSettings.provisionType.value,
    provisionSettings.exceptionalType.value,
    provisionSettings.treatmentType.value,
    provisionSettings.followType.value,
    provisionSettings.treatmentKind.value
  );
  getProvisionSettingsStore.isProvisionSettingsUpdated = true;
};

const deleteIt = async () => {
  await deleteProvisionSettingsStore.deleteProvisionSettings();
};

const provisionSettings = reactive({
  provisionType: {
    value: "",
    isLocked: false,
  },
  exceptionalType: {
    value: "",
    isLocked: false,
  },
  treatmentType: {
    value: "",
    isLocked: false,
  },

  followType: {
    value: "",
    isLocked: false,
  },
  treatmentKind: {
    value: "",
    isLocked: false,
  },
});

// provizyonTipi

let filteredProvisionTypes = computed(() =>
  query.value === ""
    ? optionTypes.value
    : optionTypes.value.filter((provision) =>
        provision.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// istisnaiHal

let filteredExceptionalTypes = computed(() =>
  query.value === ""
    ? exceptionalTypes.value
    : exceptionalTypes.value.filter((exceptional) =>
        exceptional.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// tedaviTipi

let filteredTreatmentTypes = computed(() =>
  query.value === ""
    ? treatmentTypes.value
    : treatmentTypes.value.filter((treatmentType) =>
        treatmentType.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// takipTipi

let filteredFollowTypes = computed(() =>
  query.value === ""
    ? fallowypes.value
    : fallowypes.value.filter((followType) =>
        followType.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// tedaviTuru

let filteredTreatmentKindTypes = computed(() =>
  query.value === ""
    ? treatmentKindTypes.value
    : treatmentKindTypes.value.filter((treatmentKind) =>
        treatmentKind.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);
</script>
