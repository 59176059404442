import { defineStore } from "pinia";
export const useDoctorHastaKabulStore = defineStore("doctorHastaKabulStore", {
  id: "doctorHastaKabulStore",
  state: () => ({
    sidebarActiveBar: 0,
    profileDropdownActive: false,
    profileDropdownSonIslemler:
      JSON.parse(sessionStorage.getItem("SonIslemler")) || null,
    tableNavbarActiveButton: 0,
    muayeneDurumu: "Muayene Durumu",
    popUpRightClick: false,
    sonCagirilanHasta: {
      ad: "----- ",
      soyad: "----",
      kabulTarihiSaati: "----",
    },
    siradakiHasta: {
      ad: "----",
      soyad: "----",
      kabulTarihiSaati: "----",
    },
    rightClickedPatientInfo: null,
  }),
});
