<template>
  <div class="flex w-full">
    <appointmentViewSideBar :activePage="2" />
    <div class="w-full bg-[#fbfbfb] flex flex-col">
      <AppointmentViewNavBar />
      <main class="p-4">
        <div
          class="px-2 py-[2px] rounded bg-[#277dd2] text-white flex items-center gap-2 w-fit"
        >
          <img :src="randevuOluşturAppointmentWhite" alt="" />
          <span class="mt-[2px]">Randevu Bilgileri</span>
        </div>
        <div
          class="bg-white text-[#464E5F] h-fit shadow px-4 py-10 flex flex-col gap-7"
        >
          <div
            class="hidden lg:flex w-full max-w-[1450px] items-center justify-between mx-auto"
          >
            <div class="flex items-center gap-2">
              <img :src="BolumIcon" alt="" />
              <span class="text-xl">Birim Adı</span>
            </div>
            <span class="text-xl ml-12">Tarih</span>
            <span class="text-xl w-[105px] mr-28">Saat</span>
          </div>
          <div class="w-full h-[1px] bg-[#C5CDD8]"></div>
          <div class="flex flex-col gap-3">
            <div>
              <div
                class="max-w-[1450px] w-full items-center flex lg:items-start justify-center lg:justify-between mx-auto flex-col gap-4 lg:gap-0 lg:flex-row"
              >
                <div
                  class="flex items-center gap-2 px-2 p-2 w-60 lg:w-80 rounded border-2 border-[#277dd2] bg-[#eaf2fb]"
                >
                  <input type="radio" class="scale-150" checked readonly />
                  <label class="my-auto pt-[1px]" :for="'radio-' + index">{{
                    toTitleCase(chosenUnit?.birim_adi)
                  }}</label>
                </div>
                <VueDatePicker
                  v-model="selectedDate"
                  inline
                  locale="tr"
                  auto-apply
                  :highlight="highlightedDates"
                  @update-month-year="handleMonthYear"
                  :enable-time-picker="false"
                  :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
                  :year-range="[2023, 2040]"
                  class="w-fit"
                />
                <div class="sm:w-96 flex justify-center lg:justify-end">
                  <div class="sm:w-96 flex gap-2 flex-wrap justify-center">
                    <button
                      v-for="(
                        slot, index
                      ) in searchFreeSlotsForClinicStore.freeSlotsResponse"
                      class="border-[1px] border-[#5CB85C] rounded w-[88px] h-12"
                      :class="
                        selectedSlot === index
                          ? 'bg-[#5CB85C] text-white'
                          : 'bg-white text-black'
                      "
                      @click="selectedSlot = index"
                    >
                      {{
                        new Date(slot.attributes.start_time)
                          .toISOString()
                          .substring(11, 16)
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="selectedSlot !== null"
          class="rounded w-72 text-[#277dd2] border-[1px] border-[#277dd2] bg-white font-medium text-lg mt-6 lg:mt-12 h-12 mx-auto flex items-center justify-center"
          @click="
            () =>
              router.push(
                `${currentRouteName}/${searchFreeSlotsForClinicStore.freeSlotsResponse[selectedSlot].id}`
              )
          "
        >
          Devam et
        </button>
      </main>
    </div>
    <appointmentHelpInfo />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import appointmentHelpInfo from "../components/appointmentHelpInfo.vue";

import moment from "moment";
import { useRouter } from "vue-router";
import addDays from "date-fns/addDays";

import appointmentViewSideBar from "../components/appointmentViewSideBar.vue";
import AppointmentViewNavBar from "../components/appointmentViewNavBar.vue";
import randevuOluşturAppointmentWhite from "../assets/randevuOluşturAppointmentWhite.svg";
import BolumIcon from "../assets/BolumIcon.svg";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useGetUnit } from "@/store/modules/administratorManagementStore/hospital/Units/getUnitStore";
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic.ts";
const searchFreeSlotsForClinicStore = SearchFreeSlotsforClinic();

const unitListStore = useGetUnitsList();
import { toTitleCase } from "@/utils/formatString";
const getUnitStore = useGetUnit();
const router = useRouter();
const currentRouteName = ref(router.currentRoute.value.path);
let unitID = router.currentRoute.value.params.unitID;
let appointmentType = router.currentRoute.value.params.appointmentType;
const chosenUnit = ref(null);
const selectedDate = ref();
const highlightedDates = ref();
const visibleStartDate = ref(new Date());
const visibleEndDate = ref();
const selectedSlot = ref(null);

onMounted(async () => {
  chosenUnit.value = await getUnitStore.getUnit(unitID);
  console.log(chosenUnit.value);
  if (!chosenUnit.value) {
    router.push({ name: "createAppointmentUnit" });
  }
  await searchSlotsBetweenDate().slotsBetweenDate(
    "",
    moment(visibleStartDate).format("YYYY-MM-01"),
    moment(visibleStartDate).add(1, "months").format("YYYY-MM-01"),
    "calendarMonthlySlots",
    "free",
    unitID,
    1,
    5000,
    "calendar",
    replaceHyphensWithUnderscores(appointmentType)
  );
  console.log("sda", searchSlotsBetweenDate().slotsBetweenDateResponse);
  let dates = null;
  highlightedDates.value =
    searchSlotsBetweenDate().slotsBetweenDateResponse.slots.data.map(
      (index) => {
        return [addDays(new Date(index.attributes.start_time), 0)]; // Başlangıç ve bitiş tarihlerini ekleyin  - Wed Jun 28 2023 15:28:52 GMT+0300 (GMT+03:00)
      }
    );
});

watch(
  () => selectedDate.value,
  async (newValue, oldValue) => {
    selectedSlot.value = null;
    await searchFreeSlotsForClinicStore.SearchFreeSlotsforClinic(
      moment(selectedDate.value).format("YYYY-MM-DDT00:00:00[Z]"),
      moment(selectedDate.value)
        .add(1, "days")
        .format("YYYY-MM-DDT00:00:00[Z]"),
      unitID,
      "",
      replaceHyphensWithUnderscores(appointmentType)
    );
  }
);

const handleMonthYear = async ({ month, year }) => {
  const initalMonth = month + 1;
  const formattedMonth = initalMonth.toString().padStart(2, "0");
  const forwardMont = month == 11 ? month + 1 : month + 2;
  const formattedForwardMonth = forwardMont.toString().padStart(2, "0");
  visibleStartDate.value = `${year}-${formattedMonth}-01`; //send start en end days to side bar component
  visibleEndDate.value = `${year}-${formattedForwardMonth}-01`;
  await searchSlotsBetweenDate().slotsBetweenDate(
    "",
    `${year}-${formattedMonth}-01`,
    `${year}-${formattedForwardMonth}-01`,
    "calendarMonthlySlots",
    "free",
    unitID,
    1,
    50000,
    "calendar",
    replaceHyphensWithUnderscores(appointmentType)
  );
  highlightedDates.value =
    searchSlotsBetweenDate().slotsBetweenDateResponse.slots.data.map(
      (index) => {
        return [addDays(new Date(index.attributes.start_time), 0)]; // Başlangıç ve bitiş tarihlerini ekleyin  - Wed Jun 28 2023 15:28:52 GMT+0300 (GMT+03:00)
      }
    );
};

function replaceHyphensWithUnderscores(str) {
  return str.replace(/-/g, "_");
}
</script>
