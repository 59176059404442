<template>
  <div class="flex-[3] px-4 flex flex-col gap-8">
    <div>
      <h4 class="font-medium text-[#249e60] text-xl mb-2">ŞABLON OLUŞTUR</h4>
      <div class="rounded border-[1px] border-[#249e60] p-2 flex flex-col">
        <div class="flex flex-col gap-4">
          <div>
            <label class="font-medium" for="templateName">Şablon Adı</label>
            <input
              type="text"
              id="templateName"
              class="w-full border bg-[#fbfbfb] px-2 py-1 rounded"
              placeholder="Şablon adını giriniz.."
              v-model="template.title"
            />
          </div>
          <div>
            <label class="font-medium" for="templateMessage"
              >Şablon Mesaj</label
            >
            <div class="flex items-center gap-4 mb-1">
              <button
                @click="useVariable('{birim.adi}')"
                class="rounded-3xl py-1 px-3 border bg-[#fbfbfb]"
              >
                Birim Adı
              </button>
              <button
                @click="useVariable('{doktor.adi}')"
                class="rounded-3xl py-1 px-3 border bg-[#fbfbfb]"
              >
                Doktor Adı
              </button>
              <button
                @click="useVariable('{randevu.zamani}')"
                class="rounded-3xl py-1 px-3 border bg-[#fbfbfb]"
              >
                Randevu Zamanı
              </button>
            </div>

            <textarea
              id="templateMessage"
              rows="3"
              class="bg-[#fbfbfb] resize-none py-1 px-2 rounded border w-full"
              placeholder="Şablon mesaj içeriğini giriniz.."
              v-model="template.message"
              
            ></textarea>
          </div>
        </div>
        <div class="ml-auto flex gap-6 mt-2">
          <button
            @click="handleCancel"
            class="rounded-[10px] w-[120px] h-[40px] flex items-center justify-center bg-[#e3e3e3] text-[#464E5F]"
          >
            Vazgeç
          </button>
          <button
            :disabled="!template.title || !template.message"
            @click="createTemplate"
            class="rounded-[10px] w-[120px] h-[40px] flex items-center justify-center bg-[#277dd2] text-white"
            :class="{
              'opacity-60': !template.title || !template.message,
            }"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>

    <div>
      <h4 class="font-medium text-xl mb-2">ŞABLON LİSTESİ</h4>
      <div
        class="pr-2 rounded flex flex-col gap-1 max-h-[157px] overflow-y-auto"
      >
        <div
          v-for="(
            template, index
          ) in listMessageTemplatesStore.listMessageTemplatesResponse"
          :key="index"
        >
          <p class="font-medium">{{ template.title }}</p>
          <div class="flex gap-2">
            <textarea
              :disabled="editingMessage !== template.id"
              id="templateMessage"
              rows="3"
              class="opacity-80 resize-none py-1 px-2 rounded w-full"
              :class="
                editingMessage === template.id
                  ? 'border-[1px] border-[#249e60]'
                  : 'border'
              "
              v-model="template.message"
            ></textarea>
            <div class="flex flex-col justify-center gap-3">
              <button
                v-if="editingMessage !== template.id"
                @click="handleEditing(template)"
              >
                <img class="w-5" :src="edit" alt="" />
              </button>
              <div
                v-if="editingMessage !== -1 && editingMessage === template.id"
              >
                <button @click="updateTemplate(template)">
                  <img class="w-11 -ml-[8px]" :src="success" alt="" />
                </button>
                <button @click="handleCancelEditing(template)">
                  <img class="w-4" :src="cross" alt="" />
                </button>
              </div>
              <button @click="deleteTemplate(template.id)">
                <img class="w-5" :src="deleteIcon" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import deleteIcon from "@/modules/doctorSlots/assets/deleteIcon.svg";
import edit from "@/modules/medula/provision/assets/pencilEdit.svg";
import success from "@/modules/medula/provision/assets/successCustom.svg";
import cross from "@/modules/medula/provision/assets/crossIcon.svg";

import { useListMessageTemplates } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/messageSettings/listMessageTemplates";
const listMessageTemplatesStore = useListMessageTemplates();
import { useUpdateMessageTemplate } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/messageSettings/updateMessageTemplate";
const updateMessageTemplateStore = useUpdateMessageTemplate();
import { useDeleteMessageTemplate } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/messageSettings/deleteMessageTemplate";
const deleteMessageTemplateStore = useDeleteMessageTemplate();
import { useCreateMessageTemplate } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/messageSettings/createMessageTemplate";
const createMessageTemplateStore = useCreateMessageTemplate();

const editingMessage = ref(-1);
const editingBaseMessage = ref("");
const template = reactive({
  title: "",
  message: "",
});

onMounted(async () => {
  await listMessageTemplatesStore.listMessageTemplates();
});

const useVariable = (variable) => {
  template.message += variable;
};

const handleCancel = () => {
  template.title = "";
  template.message = "";
};

const createTemplate = async () => {
  await createMessageTemplateStore.createMessageTemplate(
    template.title,
    template.message
  );
  template.title = "";
  template.message = "";

  await listMessageTemplatesStore.listMessageTemplates();
};

const handleEditing = (template) => {
  editingMessage.value = template.id;
  editingBaseMessage.value = template.message;
};

const updateTemplate = async (template) => {
  await updateMessageTemplateStore.updateMessageTemplate(
    template.id,
    template.title,
    template.message,
    "",
    ""
  );
  editingMessage.value = -1;
};

const handleCancelEditing = (template) => {
  editingMessage.value = -1;
  template.message = editingBaseMessage.value;
};

const deleteTemplate = async (id) => {
  await deleteMessageTemplateStore.deleteMessageTemplate(id);
  // Remove the deleted template from the list
  const index =
    listMessageTemplatesStore.listMessageTemplatesResponse.findIndex(
      (template) => template.id === id
    );
  if (index !== -1) {
    listMessageTemplatesStore.listMessageTemplatesResponse.splice(index, 1);
  }
};
</script>
