<template>
  <div class="outCard">
    <div
      style="
        position: relative;
        right: -460px;
        margin: 2px;
        width: 30px;
        height: 40px;
        border-radius: 50%;
        background: rgba(217, 217, 217, 0.5);
        border: 1px solid #d9534f;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <button
        style="
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          color: #d9534f;
          font-weight: bold;
          font-size: 18px;
          cursor: pointer;
        "
        @click="globalStore.isMedulaCardError = false"
      >
        X
      </button>
    </div>

    <div class="innerCard">
      <div class="flex flex-col justify-around">
        <!-- <span class="infoCardTitle">Sonuç Mesajı :</span> -->
        <!-- <span class="infoCardText">{{
          store.applicationProvisionResponse.HastaKabulReturn.SonucMesaji
        }}</span> -->
      </div>
    </div>
    <div class="flex w-44 m-1 relative" style="right: -420px">
      <button
        class="completebutton"
        @click="
          globalStore.isMedulaCardError = false;
          medulaStore.bagliTakip = false;
        "
      >
        Tamam
      </button>
    </div>
  </div>
</template>
<script setup>
import { globalStore } from "@/store/modules/globalStore";
import router from "@/router/index.ts";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore.ts";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
const medulaStore = useMedula();
const store = useApplicationProvisionStore();

const closePopUp = () => {
  router.push("/personel/homePage");
};
</script>
<style scoped>
.outCard {
  flex-direction: column;
  box-sizing: border-box;
  width: 527px;
  height: 255px;
  left: 427px;
  top: 287px;
  justify-content: center;
  background: #f9fbff;
  border: 1px solid #d9534f;
  border-radius: 20px;
  display: flex;
  visibility: visible;
  transform: translateX(100px);
  position: absolute;
  z-index: 9999; /* Yüksek bir z-index değeri seçin */
}
.innerCard {
  box-sizing: border-box;
  width: 497px;
  height: 356px;
  left: 442px;
  margin: auto;
  top: 329px;
  background: rgba(217, 83, 79, 0.2);
  border: 1px solid #d9534f;
  border-radius: 4px;
  display: flex;
  padding: 15px;
}
.infoCardTitle {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
.infoCardText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* identical to box height, or 110% */

  display: flex;
  color: #333333;
}
.completebutton {
  width: 81px;
  height: 35px;
  left: 766px;
  top: 696px;

  background: #d9534f;
  border-radius: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.printerbutton {
  box-sizing: border-box;
  width: 81px;
  height: 35px;
  left: 856px;
  top: 696px;

  background: #e3e3e3;
  border: 1px solid #000000;
  border-radius: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
</style>
