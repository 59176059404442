<template>
  <div
    v-if="radiologyRontgenStore.isCihazEklePopUpOpen"
    class="h-full w-full absolute bg-[#00000080] z-10"
  >
    <div
      class="relative w-[530px] h-[318px] rounded-[20px] border-[2px] border-[#D9D9D940] bg-white top-[35%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg"
    >
      <div
        class="flex flex-col w-[95%] mx-auto bg-white border-[0.5px] rounded-[20px] mt-3 min-h-[224px] border-[#00000033]"
      >
        <!-- Title -->
        <div
          class="bg-[#277DD233] w-[98%] mx-auto h-[51px] rounded-[20px] flex items-center justify-center"
        >
          <p class="text-[#364D6E] font-medium text-xl">CİHAZ EKLE</p>
        </div>
        <!-- Birim adi -->
        <div class="flex h-[54px] items-center mt-[25px]">
          <p class="text-[#707070E5] w-[80px] ml-[33px]">Cihaz Adı</p>
          <input
            type="text"
            class="h-[54px] rounded-[5px] bg-[#D9D9D94D] w-[332px] ml-[26px] px-1"
            placeholder="Cihaz adı giriniz..."
            v-model="radiologyRontgenStore.radiologyCihazEklePopUpInput"
          />
        </div>
        <!-- Birim Türü -->
        <div class="flex h-[54px] items-center mt-[25px]">
          <p class="text-[#707070E5] w-[80px] ml-[33px]">Birim Adı</p>
          <Select2
            class="rounded align-middle justify-between p-1 flex w-[332px] border-[#D9D9D9] cursor-pointer ml-[20px] select2 my-select2"
            :options="optionTypes"
            :settings="{ settingOption: value, settingOption: value }"
            @select="toggleBirimEkle($event)"
            placeholder="Birim Adı"
            :class="'text'"
          />
        </div>
      </div>
      <div class="flex gap-2 mx-auto mt-2 w-[260px]">
        <button
          @click="quitBirimEkle()"
          class="h-[49px] w-[112px] rounded-[25px] bg-[#E3E3E3] border-[1px] border-[#00000080]"
        >
          <p class="text-[#00000080]">Vazgeç</p>
        </button>
        <button
          @click="acceptBirimEkle()"
          class="h-[49px] w-[112px] rounded-[25px] bg-[#FFFFFF] border-[#277DD2] border-[1px]"
        >
          <p class="text-[#277DD2]">Kaydet</p>
        </button>
      </div>
    </div>
  </div>
</template>




<script setup>
import { useRadiologyRontgenStore } from "@/modules/radiology/radiologyRontgen/store/radiologyRontgenStore";
import Select2 from "vue3-select2-component";
import { ref } from "vue";

const radiologyRontgenStore = useRadiologyRontgenStore();
const optionTypes = ref([{ id: "N", text: "Endodonti" }]);
const acceptBirimEkle = () => {
  radiologyRontgenStore.isCihazEklePopUpOpen = false;
  radiologyRontgenStore.radiologyCihazTanimlari.push(
    radiologyRontgenStore.radiologyCihazEklePopUpInput
  );
  radiologyRontgenStore.radiologyCihazEklePopUpInput = "";
  radiologyRontgenStore.radiologyCihazEklePopUpButton = "";
};
const quitBirimEkle = () => {
  radiologyRontgenStore.isCihazEklePopUpOpen = false;
  radiologyRontgenStore.radiologyCihazEklePopUpInput = "";
  radiologyRontgenStore.radiologyCihazEklePopUpButton = "";
};
function toggleBirimEkle({ id }) {
  radiologyRontgenStore.radiologyCihazEklePopUpButton = id;
}
</script>



<style scoped>
.select2-container .select2-selection--single {
  height: 89px !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 0 0 12px !important;
}
</style>



