<template>
  <div class="flex gap-20">
    <button
      class="pb-1"
      :class="
        pastActivitiesStore.pastActivitiesNav === 1
          ? 'font-medium text-[#277dd2] border-b-2 border-b-[#277dd2]'
          : 'text-[#8492a5]'
      "
      @click="pastActivitiesStore.pastActivitiesNav = 1"
    >
      Randevu Bilgileri
    </button>
    <button
      class="pb-1"
      :class="
        pastActivitiesStore.pastActivitiesNav === 2
          ? 'font-medium text-[#277dd2] border-b-2 border-b-[#277dd2]'
          : 'text-[#8492a5]'
      "
      @click="pastActivitiesStore.pastActivitiesNav = 2"
    >
      Muayene Bilgileri
    </button>
    <button
      class="pb-1"
      :class="
        pastActivitiesStore.pastActivitiesNav === 3
          ? 'font-medium text-[#277dd2] border-b-2 border-b-[#277dd2]'
          : 'text-[#8492a5]'
      "
      @click="pastActivitiesStore.pastActivitiesNav = 3"
    >
      Anamnez
    </button>
  </div>
</template>

<script setup>
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
const pastActivitiesStore = usePastActivitiesStore();
</script>
