<template>
  <div>
    <!-- <cizelgeNavbar /> -->
    <klinikYonetimChart />
  </div>
</template>

<script setup>
import { useKlinikYonetimStore } from "../../store/klinikYonetim";
const store = useKlinikYonetimStore();
import klinikYonetimChart from "./klinikYonetimChart.vue";
import cizelgeNavbar from "./cizelgeNavbar.vue";
</script>
