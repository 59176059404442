import axios from "axios";
import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import moment from "moment";
import { boolCountSearchSlotsForClinic } from "@/modules/asistanEkrani/asistanCalendar/store/calendarStore.js";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const SearchFreeSlotsforClinic = defineStore({
  id: "freeSlotsResponse",
  state: () => ({
    calendarSelectedStartDate: "",
    calendarSelectedEndDate: "",
    freeSlotsResponse: <any>[],
  }),

  actions: {
    async SearchFreeSlotsforClinic(
      startDate: Date,
      endDate: Date,
      unitId: string,
      doctorId: string,
      appointmentType: string
    ) {
      const personelStoreData = personelStore();
      console.log("startDate", startDate);
      console.log("enddate", endDate);
      let token = localStorage.getItem("patientStoreData");
      let accessToken = token ? JSON.parse(token).accessToken : "";
      await axios

        .post(
          `${clinicDomain}/slots/filter`,
          {
            // StartTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            // EndTime: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
            // ClinicName: selectedClinic,

            practitioner_id: doctorId,
            location_id: "",
            unit_id: unitId,
            status: "free",
            treatment_type: appointmentType,
            start_time: startDate,
            end_time: endDate,
            page_id: 1,
            page_size: 500,
          },
          {
            headers: {
              Authorization: `Bearer ${
                accessToken || personelStoreData.personel!.token
              }`,
            },
          }
        )
        .then((response) => {
          console.log("responsessss", response);
          // if (Object.keys(response.data.slots).length === 0) {
          //   console.log("Cfalsealendar Event there is no slot.");
          //   // globalStore.isCreateAppointmentSideBarOpen = false;
          //   this.freeSlotsResponse = [];
          //   return;
          // }
          boolCountSearchSlotsForClinic.value = true;
          if (response.data.cound === 0) {
            return true;
          }
          this.freeSlotsResponse = [];
          let emptySlots: any[] = [];
          Object.keys(response.data.slots.data).forEach(function (key, index) {
            emptySlots.push(response.data.slots.data[key]);
          });
          console.log("emptySlots", emptySlots);
          emptySlots.map((emptySlotItem: any) => {
            this.freeSlotsResponse.push(emptySlotItem);
          });
          console.log("kisiselslot", this.freeSlotsResponse);
        })
        .catch((error) => {
          this.freeSlotsResponse = [];

          console.error("Error clinic slot", error);
        });
    },
  },
});
