<template>
  <div class="flex ml-[175px] relative">
    <div
      v-for="(item, index) in store.tableGingivalMargin.slice(
        indexNum,
        indexNum + 8
      )"
      :key="index"
      class="flex"
    >
      <div
        :class="item[0] == 1 ? 'top-[110px]' : 'top-[120px]'"
        class="relative border-b-[4px] border-[#C12E2A] w-[20px]"
      ></div>
      <div
        :class="item[1] == 1 ? 'top-[110px]' : 'top-[120px]'"
        class="relative border-b-[4px] border-[#C12E2A] w-[20px]"
      ></div>
      <div
        :class="item[2] == 1 ? 'top-[110px]' : 'top-[120px]'"
        class="relative border-b-[4px] border-[#C12E2A] w-[20px]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { usePeriodontalChartStore } from "../store/periodontalChart";
const store = usePeriodontalChartStore();
const props = defineProps({
  isConverted: Boolean,
  indexNum: Number,
});
</script>