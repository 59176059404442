<template>
  <div class="relative flex">
    <doctorHastaKabulSidebar />
    <div class="overflow-auto relative flex flex-col">
      <personelHeaderNavBar title="Hasta Muayene İşlemleri" />
      <main class="px-3 mt-2 flex flex-col h-full bg-white text-[#40464C]">
        <patientCall />
        <filterDoctorHastaKabulTable />
        <doctorHastaKabulTable />
        <!-- <hastaKabulPagination /> -->
      </main>
    </div>
  </div>
  <!-- <addAnamnesisModal v-if="addAnamnesisStore.show" class="fixed overflow-auto bg-white top-0 left-0  h-screen z-[9999999]" /> -->
</template>

<script setup>
import { onMounted, ref } from "vue";
import personelHeaderNavBar from "@/modules/personel/components/personelHeaderNavbar.vue";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import doctorSlotsSidebar from "@/modules/doctorSlots/components/doctorSlotsSidebar.vue";
import patientCall from "@/modules/doctorScreen/components/doctorHastaKabul/patientCall.vue";
import filterDoctorHastaKabulTable from "@/modules/doctorScreen/components/doctorHastaKabul/filterDoctorHastaKabulTable.vue";
import doctorHastaKabulTable from "@/modules/doctorScreen/components/doctorHastaKabul/doctorHastaKabulTable.vue";
import hastaKabulPagination from "@/modules/doctorScreen/components/doctorHastaKabul/hastaKabulPagination.vue";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { addAnamnesisPatientStore } from "@/modules/doctorScreen/store/addAnamnesisPatientStore";
import addAnamnesisModal from "@/modules/doctorScreen/components/doctorAddAnamnesis/addAnamnesisModal.vue";

const unitListStore = useGetUnitsList();
const addAnamnesisStore = addAnamnesisPatientStore();

onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});
</script>
