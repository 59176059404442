import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";

export const useAnamnesisTemplate = defineStore({
  id: "useAnamnesisTemplate",
  state: () => ({
    createAnamnesisResponse: null,
    getAnamnesisResponse: null,
  }),

  actions: {
    async addAnamnesisTemplate(anamnesis) {
      const personelStoreData = personelStore();
      await axios
        .post(
          `${clinicDomain}/anamnesis-template/create`,

          anamnesis,

          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("addAnamnesisTemplate", response.data);
          this.createAnamnesisResponse = response.data;
        })
        .catch((error) => {
          console.error("Error addAnamnesisTemplate", error);
          console.log(anamnesis);
          return false;
        });
    },

    async getAnamnesisTemplate(id) {
      const personelStoreData = personelStore();
      await axios
        .get(`${clinicDomain}/anamnesis-template/${id}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("getAnamnesisTemplate", response.data);
          this.getAnamnesisResponse = response.data;
        })
        .catch((error) => {
          console.error("Error getAnamnesisTemplate", error);
        });
    },

    async listAnamnesisTemplate() {
      const personelStoreData = personelStore();
      await axios
        .get(`${clinicDomain}/anamnesis-template?page_size=50&page_id=1`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("listAnamnesisTemplate", response.data);
        })
        .catch((error) => {
          console.error("Error listAnamnesisTemplate", error);
        });
    },
    async listUnitsAnamneses(id) {
      try {
        const personelStoreData = personelStore();
        const response = await axios.get(
          `${clinicDomain}/anamnesis-template/unit/${id}`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("listUnitsAnamneses", response.data);
        return {
          anamneses: response.data.anamnesises.data,
          count: response.data.count,
        };
      } catch (error) {
        console.error("Error listUnitsAnamneses", error);
        return null;
      }
    },
    async deleteAnamnesisTemplate(id) {
      const personelStoreData = personelStore();
      await axios
        .delete(`${clinicDomain}/anamnesis-template/${id}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("deleteAnamnesisTemplate", response.data);
        })
        .catch((error) => {
          console.error("Error deleteAnamnesisTemplate", error);
        });
    },
    async deleteUnitsAllAnamneses(id) {
      const personelStoreData = personelStore();
      await axios
        .delete(`${clinicDomain}/anamnesis-template/unit/${id}`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          console.log("deleteUnitsAllAnamneses", response.data);
        })
        .catch((error) => {
          console.error("Error deleteUnitsAllAnamneses", error);
          return;
        });
    },
    async updateAnamnesisTemplate(anamnesis) {
      try {
        const personelStoreData = personelStore();
        const response = await axios.put(
          `${clinicDomain}/anamnesis-template`,
          anamnesis,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("updateAnamnesis", response.data);
        return response.data;
      } catch (error) {
        console.error("Error updateAnamnesis", error);
        return false;
      }
    },
  },
});
