<template>
  <div
    class="mx-auto w-[850px] bg-white py-3 border-2 border-l-[10px] border-l-[#287dd2] rounded-[10px] h-fit"
  >
    <div class="px-6 flex flex-col">
      <p class="font-medium text-lg w-full mb-3 bg-transparent">
        <span class="text-red-600 text-2xl" v-if="formElement.is_required"
          >*</span
        >
        {{ formElement.question }}
      </p>
      <div class="flex flex-col gap-2 text-sm">
        <div
          class="flex items-center gap-2 bg-[#f5f5f5] p-2 rounded"
          v-for="(option, index) in formElement.answer.options"
        >
          <input class="w-5 h-5" type="radio" :id="uuid + index" :name="uuid" />
          <!-- :for="'option_' + props.index + '_' + index" -->
          <label class="my-auto">
            {{ option || "Seçenek oluşturulmamış" }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
const uuid = ref(uuidv4());
const props = defineProps({
  index: Number,
  formElement: Object,
});
</script>
