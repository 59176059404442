<template>
  <div
    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white p-3 border shadow-lg w-[600px] placeholder:opacity-50 placeholder:text-black rounded-lg flex flex-col justify-center items-center"
  >
    <p
      class="text-center"
      v-if="props.deletingEvent.extendedProps.patientStatus !== 'İptal'"
    >
      {{ props.deletingEvent.title }} adlı hastanın
      <span class="text-[#277DD2]">{{
        new Date(deletingDate).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span>
      tarihli randevusunu iptal etmek istediğinize emin misiniz?
    </p>
    <p
      class="text-center"
      v-if="props.deletingEvent.extendedProps.patientStatus === 'İptal'"
    >
      <span class="text-[#277DD2]">{{
        new Date(deletingDate).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span>
      tarihli iptal edilen randevuyu listeden kaldırmak istediğinize emin
      misiniz?
    </p>
    <div class="flex gap-4">
      <button
        class="text-black bg-[#E3E3E3] w-20 h-8 rounded-2xl focus:outline-none"
        @click="openCancelPopUpFalse"
      >
        Hayır</button
      ><button
        class="bg-[#277DD2] text-white w-20 h-8 rounded-2xl focus:outline-none"
        @click="
          handleDelete();
          openCancelPopUpFalse();
        "
      >
        Evet
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
const props = defineProps({
  openCancelPopUpFalse: Function,
  deletingDate: Date,
  deletingEvent: Object,
});
import { useDeleteVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useDeleteVEMAppointment";
const deleteVEMAppointmentStore = useDeleteVEMAppointment();
import { useCancelVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useCancelVEMAppointments";
const cancelVEMAppointmentStore = useCancelVEMAppointment();
import { useCancelDoctorSetting } from "@/store/modules/operationsStore/doctorSettings/useCancelDoctorSetting";
const cancelDoctorSettingStore = useCancelDoctorSetting();

const handleDelete = () => {
  if (props.deletingEvent.extendedProps.patientStatus === "İptal") {
    deleteVEMAppointmentStore.deleteVEMAppointment(
      props.deletingEvent._def.extendedProps.patientApponintmentCode
    );
  } else if (props.deletingEvent.extendedProps.patientStatus !== "İptal") {
    // cancelVEMAppointmentStore.cancelVemAppointment(
    //   props.deletingEvent._def.extendedProps.patientApponintmentCode,
    //   "iptal"
    // );
    deleteVEMAppointmentStore.deleteVEMAppointment(
      props.deletingEvent._def.extendedProps.patientApponintmentCode
    );
  }
};
onMounted(() => {
  console.log(props.deletingEvent.title);
});
</script>
