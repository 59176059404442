<template>
  <div
    class="relative rounded-[10px]"
    @focusin="doctorsSlotsStore.searchPatientSearchBarFocus = true"
    @focusout="handleFocusOut"
  >
    <div
      class="mx-auto flex shadow gap-6 border w-[447px] bg-white pl-[20px] rounded-full text-black"
    >
      <img :src="searchLogo" alt="search" />
      <div class="w-10/12">
        <input
          type="text"
          class="w-full outline-none py-2"
          placeholder="Hasta ara"
          @input="handleInputChange"
          v-model="doctorsSlotsStore.searchPatientInput"
        />
      </div>
    </div>
    <div
      v-if="
        doctorsSlotsStore &&
        doctorsSlotsStore.searchPatientInput &&
        doctorsSlotsStore.searchPatientInput.length > 4 &&
        doctorsSlotsStore.searchPatientSearchBarFocus
      "
      class="absolute p-2 border shadow min-w-[450px] z-40 left-1/2 -translate-x-1/2 top-[50px] rounded-[10px] bg-[#fbfbfb] flex flex-col gap-2"
    >
      <!-- true -->
      <div
        v-if="true"
        class="flex flex-col gap-4 max-h-[250px] overflow-y-auto"
        ref="resultsContainer"
      >
        <!-- placeholder -->
        <div
          v-for="(
            patient, index
          ) in doctorsSlotsStore.medulaSearchPatientListArray"
          :key="patient + index - index"
          class="bg-[#f1f2f3] rounded p-2 flex"
          @mousedown="selectPatient(patient)"
        >
          <img :src="profileCircle" alt="profile photo" class="w-8 h-8" />
          <div class="ml-2">
            <p class="font-medium">
              {{ patient.attributes.name }}
              {{ patient.attributes.surname }}
            </p>
            <p class="my-1">
              {{ patient.attributes.tc_identity_number }}
            </p>
          </div>
        </div>
        <!-- placeholder -->
      </div>
      <p v-if="false" class="ml-auto text-[#333333]">
        <span class="font-semibold">89</span> sonuç bulundu.
      </p>

      <!-- false -->
      <div
        class="text-center"
        v-if="doctorsSlotsStore.medulaSearchPatientListArray.length < 1"
      >
        <h4 class="font-bold text-[#323232]">BİLGİLENDİRME</h4>

        <p class="font-medium text-[#D9534F] my-2">
          Bu TC Numarasına Ait Kayıt Bulunamamaktadır
        </p>
        <!-- @focus="medulaStore.searchPatientButtonFocus = true"
                @blur="medulaStore.searchPatientButtonFocus = false"
                bunlari keyboard navigationda kullanmayi surdur  -->
        <button
          @mousedown="routeCreateNewPatient"
          class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] border-[1px] py-2 px-7 rounded-[10px] border-[#277DD2] text-[#277DD2] font-semibold"
        >
          Yeni Kayıt Oluştur
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import { useRouter } from "vue-router";
const router = useRouter();
import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
const bilesikKisiStore = bilesikKisiActions();
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
const doctorsSlotsStore = doctorSlotsStore();
import { UseSearchPatientStore } from "@/store/modules/operationsStore/patient/useSearchPatientStore";
const searchPatientStore = UseSearchPatientStore();

const handleFocusOut = () => {
  setTimeout(() => {
    if (!doctorsSlotsStore.searchPatientButtonFocus) {
      doctorsSlotsStore.searchPatientSearchBarFocus = false;
    }
  }, 0);
};

const handleInputChange = async () => {
  doctorsSlotsStore.searchPatientInput =
    doctorsSlotsStore.searchPatientInput.replace(/[^0-9]/g, "");
  doctorsSlotsStore.selectedPatientID = null;
  if (
    doctorsSlotsStore.searchPatientInput.length < 5 ||
    doctorsSlotsStore.searchPatientInput.length > 11
  ) {
    return false;
  }
  doctorsSlotsStore.medulaSearchPatientListArray =
    await searchPatientStore.searchPatient(
      doctorsSlotsStore.searchPatientInput
    );
  console.log(doctorsSlotsStore.medulaSearchPatientListArray);
};

// watch(
//   () => doctorsSlotsStore.searchPatientInput,
//   (newValue, oldValue) => {
//     handleInputChange();
//   }
// );

const selectPatient = async (patient) => {
  console.log(patient);
  doctorsSlotsStore.selectedPatientID = patient.id;
  doctorsSlotsStore.searchPatientInput = patient.attributes.tc_identity_number;
  router.push("/polyclinic/patientList/" + patient.id);
};

const routeCreateNewPatient = () => {
  // e.target.blur();
  router.push("/assistantScreen/addPatient");
  bilesikKisiStore.kpsBilesikKisiSorgulama(
    doctorsSlotsStore.searchPatientInput
  );
  console.log("kps");
};

const handleKeyDown = (e) => {
  //   //buna sonuçlar varsa diye bir şart eklenmesi gerekiyor ve her input gerçekleştiğinde index 0lanmalı
  //   if (e.key === "ArrowUp" && medulaStore.searchResultsSelectedIndex > 0) {
  //     e.preventDefault();
  //     medulaStore.searchResultsSelectedIndex--;
  //   } else if (
  //     e.key === "ArrowDown" &&
  //     medulaStore.searchResultsSelectedIndex < 3
  /* hardcoded value for now, will be changed with results length */
  //   ) {
  //     medulaStore.searchResultsSelectedIndex++;
  //   } else if (e.key >= "0" && e.key <= "9") {
  //     medulaStore.searchResultsSelectedIndex = 0;
  //   }
};
</script>
