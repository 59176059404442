import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { userInfo } from "@/modules/medula/addPatient/components/userInfoReactiveTypes";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";

export const useGetDistricts = defineStore({
  id: "useGetDistricts",
  state: () => ({
    getListDistrictsResponse: null,
  }),
  actions: {
    async getDistricts(province: string) {
      const personelStoreData = personelStore();
      const response = await axios.get(
        `${clinicDomain}/districts?province_name=${province}`,
        {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        }
      );

      this.getListDistrictsResponse = response.data.map((item, index) => {
        return {
          ...item,
          id: _.startCase(_.toLower(item.ilce_adi)),
          text: _.startCase(_.toLower(item.ilce_adi)),
        };
      });
      console.log(
        "useGetDistricts.getDistricts:",
        this.getListDistrictsResponse
      );
      return this.getListDistrictsResponse;
    },
  },
});
