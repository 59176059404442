<template>
  <div class="text-[#40464C]">
    <div class="flex gap-20">
      <!-- exceptional setting -->
      <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
        <p class="font-medium mb-3">Klinik Adı (Varsayılan Seçenek)</p>
        <div class="flex">
          <Combobox v-model="clinicalSettings.clinicName.value">
            <div class="relative w-[270px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(unit) => (unit.text === '-' ? '' : unit.text)"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredUnits.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="unit in filteredUnits"
                    as="template"
                    :key="unit.id"
                    :value="unit"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ unit.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
          <div class="ml-3 flex items-center gap-3">
            <p>Kilitle</p>
            <button
              @click="
                clinicalSettings.clinicName.isLocked =
                  !clinicalSettings.clinicName.isLocked
              "
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                clinicalSettings.clinicName.isLocked
                  ? 'bg-[#026D4D]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': clinicalSettings.clinicName.isLocked,
                }"
              ></div>
            </button>
          </div>
        </div>
      </div>

      <!-- treatment type setting -->
      <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
        <p class="font-medium mb-3">Doktor Adı (Varsayılan Seçenek)</p>
        <div class="flex">
          <Combobox v-model="clinicalSettings.doctorName.value">
            <div class="relative w-[270px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="
                    (doctor) => (doctor.text === '-' ? '' : doctor.text)
                  "
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredDoctors.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="doctor in filteredDoctors"
                    as="template"
                    :key="doctor.id"
                    :value="doctor"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ doctor.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
          <div class="ml-3 flex items-center gap-3">
            <p>Kilitle</p>
            <button
              @click="
                clinicalSettings.doctorName.isLocked =
                  !clinicalSettings.doctorName.isLocked
              "
              class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
              :class="
                clinicalSettings.doctorName.isLocked
                  ? 'bg-[#026D4D]'
                  : 'bg-gray-300'
              "
            >
              <div
                class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                :class="{
                  'translate-x-3': clinicalSettings.doctorName.isLocked,
                }"
              ></div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-20">
      <h4 class="font-medium mb-8">Randevu Defteri Ayarları</h4>
      <div class="flex">
        <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md">
          <p class="font-medium mb-3">Varsayılan Görünüm</p>
          <div class="flex">
            <Combobox v-model="clinicalSettings.defaultView.value">
              <div class="relative w-[270px]">
                <div
                  class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
                >
                  <ComboboxInput
                    class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                    :displayValue="
                      (view) => (view.text === '-' ? '' : view.text)
                    "
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronUpDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </div>
                <TransitionRoot
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  @after-leave="query = ''"
                >
                  <ComboboxOptions
                    class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                  >
                    <div
                      v-if="filteredViews.length === 0 && query !== ''"
                      class="relative cursor-default select-none px-4 py-2 text-gray-700"
                    >
                      Sonuç bulunamadı
                    </div>

                    <ComboboxOption
                      v-for="view in filteredViews"
                      as="template"
                      :key="view.id"
                      :value="view"
                      v-slot="{ active }"
                    >
                      <li
                        class="relative cursor-default select-none my-2 py-2 pl-3"
                        :class="{
                          'bg-teal-600 text-white': active,
                          'text-gray-900': !active,
                        }"
                      >
                        <span class="block truncate">
                          {{ view.text }}
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </TransitionRoot>
              </div>
            </Combobox>

            <div class="ml-3 flex items-center gap-3">
              <p>Kilitle</p>
              <button
                @click="
                  clinicalSettings.defaultView.isLocked =
                    !clinicalSettings.defaultView.isLocked
                "
                class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
                :class="
                  clinicalSettings.defaultView.isLocked
                    ? 'bg-[#026D4D]'
                    : 'bg-gray-300'
                "
              >
                <div
                  class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                  :class="{
                    'translate-x-3': clinicalSettings.defaultView.isLocked,
                  }"
                ></div>
              </button>
            </div>
          </div>
        </div>

        <div class="bg-[#fbfbfb] p-3 w-[485px] rounded-md ml-20">
          <p class="font-medium mb-3">Varsayılan Birim Seçimi</p>
          <div class="flex">
            <Combobox v-model="clinicalSettings.unitName.value">
              <div class="relative w-[270px]">
                <div
                  class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
                >
                  <ComboboxInput
                    class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                    :displayValue="
                      (unit) => (unit.text === '-' ? '' : unit.text)
                    "
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronUpDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </div>
                <TransitionRoot
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  @after-leave="query = ''"
                >
                  <ComboboxOptions
                    class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                  >
                    <div
                      v-if="filteredUnits.length === 0 && query !== ''"
                      class="relative cursor-default select-none px-4 py-2 text-gray-700"
                    >
                      Sonuç bulunamadı
                    </div>

                    <ComboboxOption
                      v-for="unit in filteredUnits"
                      as="template"
                      :key="unit.id"
                      :value="unit"
                      v-slot="{ active }"
                    >
                      <li
                        class="relative cursor-default select-none my-2 py-2 pl-3"
                        :class="{
                          'bg-teal-600 text-white': active,
                          'text-gray-900': !active,
                        }"
                      >
                        <span class="block truncate">
                          {{ unit.text }}
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </TransitionRoot>
              </div>
            </Combobox>

            <div class="ml-3 flex items-center gap-3">
              <p>Kilitle</p>
              <button
                @click="
                  clinicalSettings.unitName.isLocked =
                    !clinicalSettings.unitName.isLocked
                "
                class="flex h-4 w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
                :class="
                  clinicalSettings.unitName.isLocked
                    ? 'bg-[#026D4D]'
                    : 'bg-gray-300'
                "
              >
                <div
                  class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
                  :class="{
                    'translate-x-3': clinicalSettings.unitName.isLocked,
                  }"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, reactive } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
let query = ref("");
import Select2 from "vue3-select2-component";
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
const staffListStore = useGetStaffList();
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
import { useGetDoctorsByUnitStore } from "@/store/modules/administratorManagementStore/staff/getDoctorsByUnit";
const doctorsByUnitStore = useGetDoctorsByUnitStore();
import { useGetClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/getClinicalSettings";
const getClinicalSettingsStore = useGetClinicalSettings();
import { useCreateClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/createClinicalSettings";
const createClinicalSettingsStore = useCreateClinicalSettings();
import { useUpdateClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/updateClinicalSettings";
const updateClinicalSettingsStore = useUpdateClinicalSettings();
import { useDeleteClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/deleteClinicalSettings";
const deleteClinicalSettingsStore = useDeleteClinicalSettings();

const doctors = ref([]);
let views = [
  { id: 1, text: "Liste" },
  { id: 2, text: "Haftalık" },
  { id: 3, text: "Aylık" },
];

onMounted(async () => {
  doctors.value = await staffListStore.getStaffList();
  await getClinicalSettingsStore.getClinicalSettings();
  console.log(
    "getClinicalSettingsStore",
    getClinicalSettingsStore.getClinicalSettingsResponse
  );
  if (getClinicalSettingsStore.getClinicalSettingsResponse === null) {
    await createClinicalSettingsStore.createClinicalSettings();
  } else {
    console.log(
      "getClinicalSettingsStore",
      getClinicalSettingsStore.getClinicalSettingsResponse
    );
    const properties = [
      {
        name: "clinicName",
        options: unitListStore.getListUnitsResponse,
        key: "klinik_adi",
      },
      {
        name: "doctorName",
        options: doctors.value,
        key: "doktor_adi",
      },
      {
        name: "defaultView",
        options: views,
        key: "randevu_defteri_varsayilan_gorunum",
      },
      {
        name: "unitName",
        options: unitListStore.getListUnitsResponse,
        key: "randevu_defteri_varsayilan_birim",
      },
    ];

    properties.forEach((property) => {
      console.log("aaa", clinicalSettings[property.name]);
      clinicalSettings[property.name].value = property.options?.find(
        (option) =>
          option.text ===
          getClinicalSettingsStore.getClinicalSettingsResponse[property.key]
      );

      if (clinicalSettings[property.name].value) {
        clinicalSettings[property.name].isLocked = true;
      }
    });
  }
  const watchLockedValue = (property) => {
    watch(
      () => clinicalSettings[property].isLocked,
      (newValue) => {
        if (
          newValue &&
          clinicalSettings[property].value &&
          clinicalSettings[property].value.text !== "-"
        ) {
          updateSettings();
          console.log("updateee", clinicalSettings[property].value);
        } else if (
          !newValue &&
          clinicalSettings[property].value &&
          clinicalSettings[property].value.text !== "-"
        ) {
          clinicalSettings[property].value = { id: "-", text: "-" };
          updateSettings();
        }
      }
    );

    watch(
      () => clinicalSettings[property].value,
      (newValue) => {
        if (newValue && clinicalSettings[property].isLocked) {
          updateSettings();
        }
      }
    );
  };
  watchLockedValue("clinicName");
  watchLockedValue("doctorName");
  watchLockedValue("defaultView");
  watchLockedValue("unitName");
  watch(
    () => clinicalSettings.clinicName.value,
    async (newVal) => {
      console.log(newVal);
      if (newVal.birim_kodu === "" || newVal.birim_kodu === "-") {
        doctors.value = await staffListStore.getStaffList();
      } else {
        doctors.value = await doctorsByUnitStore.getDoctorsByUnit(
          newVal.birim_kodu
        );
      }
    }
  );
});

const updateSettings = async () => {
  console.log("updateSettings", clinicalSettings);
  await updateClinicalSettingsStore.updateClinicalSettings(
    clinicalSettings.clinicName.value?.text,
    clinicalSettings.doctorName.value?.text,
    clinicalSettings.defaultView.value?.text,
    clinicalSettings.unitName.value?.text
  );
  getClinicalSettingsStore.isClinicalSettingsUpdated = true;
};

const clinicalSettings = reactive({
  clinicName: {
    value: "",
    isLocked: false,
  },
  doctorName: {
    value: "",
    isLocked: false,
  },
  defaultView: {
    value: "",
    isLocked: false,
  },

  unitName: {
    value: "",
    isLocked: false,
  },
});

// units

let filteredUnits = computed(() =>
  query.value === ""
    ? unitListStore.getListUnitsResponse
    : unitListStore.getListUnitsResponse.filter((unit) =>
        unit.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// watch(
//   () => clinicalSettings.clinicName.isLocked,
//   (newValue) => {
//     if (newValue && clinicalSettings.clinicName.value) {
//       console.log("update", clinicalSettings.clinicName.value);
//     }
//   }
// );

// watch(
//   () => clinicalSettings.clinicName.value,
//   (newValue) => {
//     clinicalSettings.clinicName.isLocked = false;
//   }
// );

// watch(
//   () => clinicalSettings.unitName.isLocked,
//   (newValue) => {
//     if (newValue && clinicalSettings.unitName.value) {
//       console.log("update", clinicalSettings.unitName.value);
//     }
//   }
// );

// watch(
//   () => clinicalSettings.unitName.value,
//   (newValue) => {
//     clinicalSettings.unitName.isLocked = false;
//   }
// );

// doctors

let filteredDoctors = computed(() =>
  query.value === ""
    ? doctors.value
    : doctors.value.filter((doctor) =>
        doctor.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// watch(
//   () => clinicalSettings.doctorName.isLocked,
//   (newValue) => {
//     if (newValue && clinicalSettings.doctorName.value) {
//       console.log("update", clinicalSettings.doctorName.value);
//     }
//   }
// );

// watch(
//   () => clinicalSettings.doctorName.value,
//   (newValue) => {
//     clinicalSettings.doctorName.isLocked = false;
//   }
// );

//views

let filteredViews = computed(() =>
  query.value === ""
    ? views
    : views.filter((view) =>
        view.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

// watch(
//   () => clinicalSettings.defaultView.isLocked,
//   (newValue) => {
//     if (newValue && clinicalSettings.defaultView.value) {
//       console.log("update", clinicalSettings.defaultView.value);
//     }
//   }
// );

// watch(
//   () => clinicalSettings.defaultView.value,
//   (newValue) => {
//     clinicalSettings.defaultView.isLocked = false;
//   }
// );
</script>
