<template>
  <div class="w-full bg-[#f4f4f4] flex flex-col h-screen">
    <personelHeaderNavbar title="Randevu Defteri" calendarNavbar />
    <main class="px-[20px] mt-[20px] mb-12 flex flex-col gap-7 h-full">
      <appointmentBookNavbar />
      <div class="absolute top-[105px] left-[480px]">
        <Select2
          v-if="appointmentBookStore.calendarViewNavigation !== 4"
          class="rounded align-middle justify-between p-1 flex h-[31px] sm:w-[160px] 2xl:w-[250px] border-[#D9D9D9] cursor-pointer"
          :options="useGetUnitsList().getListUnitsResponse"
          @select="chooseBirim"
          placeholder="Bir Bölüm Seçiniz"
          v-model="appointmentBookStore.unit_code"
        />
      </div>
      <appointmentList
        v-if="appointmentBookStore.calendarViewNavigation === 1"
      />
      <asistantCalendarWeeklyView
        v-if="appointmentBookStore.calendarViewNavigation === 2"
      />
      <asistantCalendarMonthlyView
        v-if="appointmentBookStore.calendarViewNavigation === 3"
      />
      <appointmentBookSettings
        v-if="appointmentBookStore.calendarViewNavigation === 4"
      />
    </main>
    <createOrUpdateAppointmentSidebar />
    <medulaSuccesCard v-if="globalStore.isMedulaCardSuccess" />
  </div>
</template>

<script setup>
import Select2 from "vue3-select2-component";
import medulaSuccesCard from "@/modules/medula/provision/components/medulaSuccesCard.vue";
import { globalStore } from "@/store/modules/globalStore";

import personelHeaderNavbar from "@/modules/personel/components/personelHeaderNavbar";
import asistantCalendarMonthlyView from "@/modules/asistanEkrani/asistanCalendar/AsistantCalendarMohtlyView";
import asistantCalendarWeeklyView from "@/modules/asistanEkrani/asistanCalendar/AsistantCalendarWeeklyView";
import appointmentBookSettings from "@/modules/asistanEkrani/components/appointmentBookSettings";
import appointmentBookNavbar from "../../components/appointmentBookNavbar.vue";
import appointmentList from "../../components/appointmentList.vue";
import createOrUpdateAppointmentSidebar from "../../components/createOrUpdateAppointmentSidebar.vue";
import { useAppointmentBook } from "@/modules/asistanEkrani/components/appointmentBookStore";

import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import { onMounted } from "vue";

const appointmentBookStore = useAppointmentBook();
const staffListStore = useGetStaffList();

import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
import { useGetClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/getClinicalSettings";
const getClinicalSettingsStore = useGetClinicalSettings();

onMounted(async () => {
  if (!unitListStore.getListUnitsResponse) {
    await unitListStore.getUnitsList();
  }
  if (
    !getClinicalSettingsStore.getClinicalSettingsResponse ||
    getClinicalSettingsStore.isClinicalSettingsUpdated
  ) {
    await getClinicalSettingsStore.getClinicalSettings();
  }
  if (
    !(
      !getClinicalSettingsStore.getClinicalSettingsResponse
        .randevu_defteri_varsayilan_birim ||
      getClinicalSettingsStore.getClinicalSettingsResponse
        .randevu_defteri_varsayilan_birim === "-" ||
      getClinicalSettingsStore.getClinicalSettingsResponse
        .randevu_defteri_varsayilan_birim === ""
    )
  ) {
    appointmentBookStore.unit_code = unitListStore.getListUnitsResponse.find(
      (option) =>
        option.text ===
        getClinicalSettingsStore.getClinicalSettingsResponse
          .randevu_defteri_varsayilan_birim
    ).birim_kodu;
    console.log(appointmentBookStore.unit_code);
    getClinicalSettingsStore.isClinicalSettingsUpdated = false;
  }
});
</script>
