import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { patientStore } from "@/store/modules/administratorManagementStore/patient";

import _ from "lodash";
export const useGetUnitsList = defineStore({
  id: "useGetUnitsList",
  state: () => ({
    getListUnitsResponse: null,
  }),
  actions: {
    async getUnitsList(pageSize = 50) {
      const personelStoreData = personelStore();
      const patientStoreData = patientStore();
      let token = localStorage.getItem("patientStoreData");
      let accessToken = token ? JSON.parse(token).accessToken : "";
      await axios
        .get(`${adminDomain}/units?page_id=1&page_size=${pageSize}`, {
          headers: {
            Authorization: `Bearer ${
              accessToken || personelStoreData.personel!.token
            }`,
          },
        })
        .then((response) => {
          this.getListUnitsResponse = response.data.map((item, index) => {
            return {
              ...item,
              id: item.birim_kodu,
              text: _.startCase(_.toLower(item.birim_adi)),
            };
          });
          console.log("getListUnitsResponse", this.getListUnitsResponse);
        })
        .catch((error) => {
          console.error("Error in getUnitsList:", error);
        });
    },
  },
});
