import { createRouter, createWebHistory } from "vue-router";
// import medulaKabulView from "../../../modules/asistanEkrani/view/medulaKabulView.vue";
import medulaHomeView from "../../../modules/medula/provision/view/medulaHomeView.vue";
import registeredPatientView from "../../../modules/asistanEkrani/registeredPatientList/view/registeredPatientView.vue";
import addPatientView from "../../../modules/medula/addPatient/view/addPatientView.vue";
import patientListView from "@/modules/asistanEkrani/components/patientList/patientListView.vue";
import patientTableDetail from "@/modules/asistanEkrani/components/patientTableDetail/patientTableDetail.vue";
import { requirePersonelAuth } from "@/components/requireAuth";
import hastaDoktorMainViewVue from "@/modules/hastaDoktor/view/hastaDoktorMainView.vue";
import temporaryPersonalRoutePage from "@/modules/temporaryRoutePage/view/temporaryPersonalRoutePage.vue";
import temporaryPatientRoutePage from "@/modules/temporaryRoutePage/view/temporaryPatientRoutePage.vue";
import assistantSettingsView from "@/modules/asistanEkrani/asistanSettings/view/assistantSettingsView.vue";

const routes = [
  {
    path: "/assistantScreen/addPatient",
    name: "addPatientView",
    component: addPatientView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/assistantScreen/registered-patient",
    name: "registeredPatientView",
    component: registeredPatientView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/asistantScreen/medula/homePage",
    name: "medulaHomeView",
    component: medulaHomeView,
    beforeEnter: requirePersonelAuth,
  },

  {
    path: "/route-personal",
    name: "temporaryPersonalRoutePage",
    component: temporaryPersonalRoutePage,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/route-patient",
    name: "temporaryPatientRoutePage",
    component: temporaryPatientRoutePage,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/assistantScreen/settings",
    name: "assistantSettingsView",
    component: assistantSettingsView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
