import { defineStore } from "pinia";
export const useAppointmentBook = defineStore("appointmentBookStore", {
  id: "appointmentBookStore",
  state: () => ({
    calendarViewNavigation: 1,
    selectedDate: new Date(),
    updatingAppointment: null,
    slot_id: "",
    deletingAppointmentFromList: null,
    slotCurrentPage: 1,

    //select2 component options and values
    filteredDoctors: null,
    doctor_code: "",
    unit_code: "350392bb-80f7-43d4-a7f2-007b78c3361b",
    appointmentType: "",
    
    //patient searching
    searchPatientInput: "",
    searchResultsSelectedIndex: 0,
    medulaSearchPatientListArray: [],
    selectedPatientID: "",
    selectedPatient: {},

    //settings
  }),
});
