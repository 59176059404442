<template>
  <div class="h-full flex gap-4">
    <div>
      <h2 class="font-semibold text-xl mb-8">
        {{ store.clinicalManagementUnits.length }} UNİT
        <span class="text-[#F94144]"
          >({{
            store.clinicalManagementUnits.filter((unit) => unit.breakdown)
              .length
          }}
          Arızalı)</span
        >
      </h2>
      <klinikYonetimUnitTimeCard
        v-for="(unit, index) in store.clinicalManagementUnits"
        :key="index"
        :ariza="unit.breakdown"
        :isim="unit.unitName"
        class="mb-1"
      />
    </div>
    <div class="w-full">
      <!-- :timeSlots="[
            {
              id: 1,
              start: moment().format('YYYY-MM-DD[T]11:30:00'),
              end: moment().format('YYYY-MM-DD[T]12:30:00'),
              text: 'Hekim Suna',
            },
          ]" -->
      <klinikYonetimUnitTimeTable />
      <!-- <klinikYonetimUnitTimeTable
          :timeSlots="[
            {
              id: 1,
              start: moment().format('YYYY-MM-DD[T]11:30:00'),
              end: moment().format('YYYY-MM-DD[T]12:30:00'),
              text: 'Hekim Suna',
            },
          ]"
          pilotID="2" -->
      <!-- /> -->
      <!-- <klinikYonetimUnitTimeTable
          :timeSlots="[
            {
              id: 1,
              start: moment().format('YYYY-MM-DD[T]11:30:00'),
              end: moment().format('YYYY-MM-DD[T]12:30:00'),
              text: 'Hekim Suna',
            },
          ]"
        />
        <klinikYonetimUnitTimeTable
          :timeSlots="[
            {
              id: 1,
              start: moment().format('YYYY-MM-DD[T]11:30:00'),
              end: moment().format('YYYY-MM-DD[T]12:30:00'),
              text: 'Hekim Suna',
            },
          ]"
        />
        <klinikYonetimUnitTimeTable
          :timeSlots="[
            {
              id: 1,
              start: moment().format('YYYY-MM-DD[T]11:30:00'),
              end: moment().format('YYYY-MM-DD[T]12:30:00'),
              text: 'Hekim Suna',
            },
          ]"
        />
        <klinikYonetimUnitTimeTable
          :timeSlots="[
            {
              id: 1,
              start: moment().format('YYYY-MM-DD[T]11:30:00'),
              end: moment().format('YYYY-MM-DD[T]12:30:00'),
              text: 'Hekim Suna',
            },
          ]"
        />
        <klinikYonetimUnitTimeTable
          :timeSlots="[
            {
              id: 1,
              start: moment().format('YYYY-MM-DD[T]11:30:00'),
              end: moment().format('YYYY-MM-DD[T]12:30:00'),
              text: 'Hekim Suna',
            },
          ]"
        /> -->
    </div>
  </div>
</template>

<script setup>
import klinikYonetimUnitTimeTable from "./klinikYonetimUnitTimeTable.vue";
import klinikYonetimUnitTimeCard from "./klinikYonetimUnitTimeCard.vue";
import { useKlinikYonetimStore } from "../../store/klinikYonetim";
const store = useKlinikYonetimStore();
import moment from "moment";
</script>
