<template>
  <div
    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white p-3 border shadow-lg w-[600px] placeholder:opacity-50 placeholder:text-black rounded-lg flex flex-col justify-center items-center"
  >
    <p
      class="text-center"
      v-if="
        props.deletingEvent.extendedProps.patientStatus !== 'İptal' &&
        props.type === 'appointment'
      "
    >
      {{ props.deletingEvent.title }} adlı hastanın
      <span class="text-[#277DD2]">{{
        new Date(deletingDate).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span>
      tarihli randevusunu iptal etmek istediğinize emin misiniz?
    </p>
    <p
      class="text-center"
      v-if="
        props.deletingEvent.extendedProps.patientStatus === 'İptal' &&
        props.type === 'appointment'
      "
    >
      <span class="text-[#277DD2]">{{
        new Date(deletingDate).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span>
      tarihli iptal edilen randevuyu silmek istediğinizden emin misiniz?
    </p>
    <p
      class="text-center"
      v-if="
        props.type === 'setting' &&
        props.deletingEvent.extendedProps.patientStatus !== 'İptal'
      "
    >
      {{ props.deletingEvent.title }} saatlerindeki
      <span class="text-[#277DD2]">{{
        new Date(deletingDate).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span>
      tarihli çalışma programınızı iptal etmek istediğinize emin misiniz?
    </p>
    <p
      v-if="
        props.type == 'setting' &&
        props.deletingEvent.extendedProps.patientStatus === 'İptal'
      "
      class="text-center"
    >
      {{ props.deletingEvent.title }} nedeniyle
      <span class="text-[#277DD2]">{{
        new Date(deletingDate).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span>
      tarihli çalışma programınız iptal edilmiştir
    </p>

    <textarea
      v-if="props.deletingEvent.extendedProps.patientStatus !== 'İptal'"
      class="resize-none border bg-[#D9D9D9] focus:outline-none p-2 my-2 rounded-md"
      v-model="cancelReason"
      placeholder="iptal sebebini giriniz."
      cols="60"
      rows="5"
    ></textarea>
    <div
      class="flex gap-4"
      v-if="
        props.deletingEvent.extendedProps.patient !== 'setting' ||
        props.deletingEvent.extendedProps.patientStatus !== 'İptal'
      "
    >
      <button
        class="text-black bg-[#E3E3E3] w-20 h-8 rounded-2xl focus:outline-none"
        @click="openCancelPopUpFalse"
      >
        Hayır</button
      ><button
        class="bg-[#277DD2] text-white w-20 h-8 rounded-2xl focus:outline-none"
        :class="[
          cancelReason.trim() !== '' ||
          props.deletingEvent.extendedProps.patientStatus == 'İptal'
            ? 'cursor-pointer'
            : 'opacity-50 cursor-not-allowed',
        ]"
        @click="
          handleDelete();
          openCancelPopUpFalse();
        "
        :disabled="
          cancelReason.trim() === '' &&
          props.deletingEvent.extendedProps.patientStatus !== 'İptal'
        "
      >
        Evet
      </button>
    </div>
    <div v-else class="flex justify-center items-center flex-col gap-5">
      <p class="text-lg">{{ props.deletingEvent.title }}</p>
      <button
        class="bg-[#277DD2] text-white w-20 h-8 rounded-2xl focus:outline-none"
        @click="
          handleDelete();
          openCancelPopUpFalse();
        "
      >
        Geri dön
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
const cancelReason = ref("");
const props = defineProps({
  openCancelPopUpFalse: Function,
  deletingDate: Date,
  deletingEvent: Object,
  type: String,
});
import { useDeleteVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useDeleteVEMAppointment";
const deleteVEMAppointmentStore = useDeleteVEMAppointment();
import { useCancelVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useCancelVEMAppointments";
const cancelVEMAppointmentStore = useCancelVEMAppointment();
import { useCancelDoctorSetting } from "@/store/modules/operationsStore/doctorSettings/useCancelDoctorSetting";
const cancelDoctorSettingStore = useCancelDoctorSetting();

const handleDelete = () => {
  if (
    props.deletingEvent.extendedProps.patientStatus === "İptal" &&
    props.deletingEvent.extendedProps.patient !== "setting"
  ) {
    deleteVEMAppointmentStore.deleteVEMAppointment(
      props.deletingEvent._def.extendedProps.patientApponintmentCode
    );
  } else if (
    props.deletingEvent.extendedProps.patientStatus !== "İptal" &&
    props.deletingEvent.extendedProps.patient !== "setting"
  ) {
    cancelVEMAppointmentStore.cancelVemAppointment(
      props.deletingEvent._def.extendedProps.patientApponintmentCode,
      cancelReason.value
    );
  } else if (
    props.type === "setting" &&
    props.deletingEvent.extendedProps.patientStatus !== "İptal"
  ) {
    console.log(props.deletingEvent);
    cancelDoctorSettingStore.cancelDoctorSetting(
      props.deletingEvent._def.extendedProps.patientApponintmentCode,
      cancelReason.value
    );
  }
};
onMounted(() => {
  console.log(props.deletingEvent.title);
  console.log(props.type);
});
</script>
