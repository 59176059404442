<template>
  <div
    v-if="store.studentAssistantFilter===2"
    class="absolute top-11 ml-[70px] h-[280px] w-[340px] rounded-[10px] border-2 border-[#D9D9D9] bg-white"
  >
    <p class="ml-[18px] mt-[17px] text-[#999999]">Bir alan seç</p>
    <div class="mx-5 w-[300px] border-t-[1px]"></div>

    <div class="ml-[18px] mt-[22px] flex">
      <div
        class="flex cursor-pointer items-center justify-between"
        @click="
          store.studentAssistantFilterItems[0] =
            !store.studentAssistantFilterItems[0]
        "
      >
        <div
          class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
          :class="
            store.studentAssistantFilterItems[0] === true
              ? 'bg-[#178805]'
              : 'bg-gray-300'
          "
        >
          <div
            class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
            :class="{ 'translate-x-3': store.studentAssistantFilterItems[0] }"
          ></div>
        </div>
      </div>
      <p class="ml-[12px] font-light">Telefon Numarası</p>
    </div>
    <div class="ml-[18px] mt-[6px] flex">
      <div
        class="flex cursor-pointer items-center justify-between"
        @click="
          store.studentAssistantFilterItems[1] =
            !store.studentAssistantFilterItems[1]
        "
      >
        <div
          class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
          :class="
            store.studentAssistantFilterItems[1] === true
              ? 'bg-[#178805]'
              : 'bg-gray-300'
          "
        >
          <div
            class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
            :class="{ 'translate-x-3': store.studentAssistantFilterItems[1] }"
          ></div>
        </div>
      </div>
      <studentAssistantFilterButtonFilterButton
        class="mt-[5px]"
        :valueOfTag="'İşlemler'"
        :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
      />
    </div>

    <div class="ml-[18px] flex">
      <div
        class="flex cursor-pointer items-center justify-between"
        @click="
          store.studentAssistantFilterItems[2] =
            !store.studentAssistantFilterItems[2]
        "
      >
        <div
          class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
          :class="
            store.studentAssistantFilterItems[2] === true
              ? 'bg-[#178805]'
              : 'bg-gray-300'
          "
        >
          <div
            class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
            :class="{ 'translate-x-3': store.studentAssistantFilterItems[2] }"
          ></div>
        </div>
      </div>
      <studentAssistantFilterButtonFilterButton
        class="mt-[5px]"
        :valueOfTag="'Çağrı'"
        :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
      />
    </div>

    <div class="ml-[18px] mt-[2px] flex">
      <div
        class="flex cursor-pointer items-center justify-between"
        @click="
          store.studentAssistantFilterItems[3] =
            !store.studentAssistantFilterItems[3]
        "
      >
        <div
          class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
          :class="
            store.studentAssistantFilterItems[3] === true
              ? 'bg-[#178805]'
              : 'bg-gray-300'
          "
        >
          <div
            class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
            :class="{ 'translate-x-3': store.studentAssistantFilterItems[3] }"
          ></div>
        </div>
      </div>
      <studentAssistantFilterButtonFilterButton
        class="mt-[5px]"
        :valueOfTag="'Doktor'"
        :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
      />
    </div>

    <div class="ml-[18px] mt-[2px] flex">
      <div
        class="flex cursor-pointer items-center justify-between"
        @click="
          store.studentAssistantFilterItems[4] =
            !store.studentAssistantFilterItems[4]
        "
      >
        <div
          class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
          :class="
            store.studentAssistantFilterItems[4] === true
              ? 'bg-[#178805]'
              : 'bg-gray-300'
          "
        >
          <div
            class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
            :class="{ 'translate-x-3': store.studentAssistantFilterItems[4] }"
          ></div>
        </div>
      </div>
      <studentAssistantFilterButtonFilterButton
        class="mt-[5px]"
        :valueOfTag="'Durum'"
        :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
      />
    </div>

    <div class="ml-[18px] mt-[8px] flex">
      <div
        class="flex cursor-pointer items-center justify-between"
        @click="
          store.studentAssistantFilterItems[5] =
            !store.studentAssistantFilterItems[5]
        "
      >
        <div
          class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
          :class="
            store.studentAssistantFilterItems[5] === true
              ? 'bg-[#178805]'
              : 'bg-gray-300'
          "
        >
          <div
            class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
            :class="{ 'translate-x-3': store.studentAssistantFilterItems[5] }"
          ></div>
        </div>
      </div>
      <p class="ml-[12px] font-thin">Not</p>
    </div>
  </div>
</template>


<script setup>
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();

import studentAssistantFilterButtonFilterButton from "./studentAssistantFilterButtonFilterButton.vue";
</script>