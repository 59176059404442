<template>
  <div class="flex w-full min-h-screen">
    <doctorHastaKabulSidebar />
    <div class="w-full relative flex flex-col">
      <personelHeaderNavBar title="Ana Sayfa" />
      <main class="p-3 flex flex-col gap-5 h-full bg-[#fbfbfb]">
        <doctorNavbar />
        <doctorPatientSearchBar />
        <div class="flex justify-between text-[#464E5F] mt-3 px-6">
          <examinedAndNextPatients />
          <prevNextPatient />
          <doctorProfileShortcuts />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import doctorPatientSearchBar from "@/modules/doctorScreen/components/doctorPatientSearchBar.vue";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import personelHeaderNavBar from "@/modules/personel/components/personelHeaderNavbar.vue";
import doctorSlotsSidebar from "@/modules/doctorSlots/components/doctorSlotsSidebar.vue";
import doctorNavbar from "@/modules/doctorScreen/components/doctorNavbar.vue";
import examinedAndNextPatients from "@/modules/doctorScreen/components/examinedAndNextPatients.vue";
import prevNextPatient from "@/modules/doctorScreen/components/prevNextPatient.vue";
import doctorProfileShortcuts from "@/modules/doctorScreen/components/doctorProfileShortcuts.vue";
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import { doctorInfo } from "@/modules/doctorSlots/components/doctorInfoReactiveTypes";
import { useGetDoctorInfoStore } from "@/store/modules/administratorManagementStore/staff/getDoctorInfo";
const getDoctorInfoStore = useGetDoctorInfoStore();

onMounted(async () => {
  if (doctorInfo.doctorInfos === null) {
    const session = JSON.parse(localStorage.getItem("personelStoreData"));
    doctorInfo.doctorInfos = await getDoctorInfoStore.getDoctorInfo(
      session.flowId
    );
  }
});
</script>
