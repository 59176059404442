<template>
  <div class="flex items-center gap-20 text-[#09244B] pt-6 font-medium text-lg">
    <button
      class="pb-1 focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
      :class="
        medulaStore.activitiesNavigation === 1
          ? 'border-b-[#277DD2] border-b-2'
          : 'opacity-50'
      "
      @click="medulaStore.activitiesNavigation = 1"
    >
      Randevu Bilgileri
    </button>
    <button
      class="pb-1 focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
      :class="
        medulaStore.activitiesNavigation === 2
          ? 'border-b-[#277DD2] border-b-2'
          : 'opacity-50'
      "
      @click="medulaStore.activitiesNavigation = 2"
    >
      Hasta Başvuruları
    </button>
    <!-- <button
      class="pb-1 focus:outline-none"
      :class="
        medulaStore.activitiesNavigation === 3
          ? 'border-b-[#277DD2] border-b-2'
          : 'opacity-50'
      "
      @click="medulaStore.activitiesNavigation = 3"
    >
      Geçmiş İşlemler
    </button> -->
  </div>
</template>

<script setup>
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
const medulaStore = useMedula();
</script>
