import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useListMessageTemplates = defineStore({
  id: "useListMessageTemplates",
  state: () => ({
    listMessageTemplatesResponse: null,
  }),
  actions: {
    async listMessageTemplates() {
      const personelStoreData = personelStore();
      await axios
        .get(`${adminDomain}/message-template`, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        })
        .then((response) => {
          this.listMessageTemplatesResponse = response.data;
          console.log(
            "listMessageTemplatesResponse",
            this.listMessageTemplatesResponse
          );
        })
        .catch((error) => {
          console.log("listMessageTemplatesError", error);
        });
    },
  },
});
