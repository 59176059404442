<template>
  <div class="flex flex-col mt-28">
    <img :src="landingDisGorseliLogo" alt="" class="mt-2 max-h-[210px]" />
    <p class="mt-7 flex max-h-[23px] justify-center text-xl font-bold">
      HİZMETLERİMİZ
    </p>
    <div
      class="homeButtonsContainerWrap mx-auto mt-5 flex h-[263px] max-w-[1202px] min-w-[1183px] items-center justify-center"
    >
      <div
        class="homeButtonsContainer m-1 flex h-[249px] max-w-[1183px] w-full flex-row items-center justify-center gap-x-5"
      >
        <button
          ref="homeButtonsReff"
          class="ml-4"
          :class="
            homeButtonsRef == '1'
              ? 'homeButtonsActiveItem'
              : 'homeButtonsPassiveItem'
          "
          @mouseover="showhomeButtonsRef(1)"
          @mouseleave="showhomeButtonsRef(null)"
        >
          <div class="homeButtonsItem flex justify-center">
            <div class="flex flex-col justify-center">
              <p
                class="homeButtonsItemParagraph flex items-center justify-center h-3"
                :class="
                  homeButtonsRef == '1'
                    ? 'homeButtonsActiveItemParagraph'
                    : 'homeButtonsPassiveItemParagraph'
                "
              >
                Oral Diagnoz
              </p>

              <button
                v-if="homeButtonsRef == '1'"
                class="flex justify-center pt-10 h-16"
              >
                <p class="flex text-[#EAEAEA]">Devamını gör</p>
              </button>
            </div>
          </div>
        </button>
        <button
          ref="homeButtonsReff"
          class="ml-4"
          :class="
            homeButtonsRef == '2'
              ? 'homeButtonsActiveItem'
              : 'homeButtonsPassiveItem'
          "
          @mouseover="showhomeButtonsRef(2)"
          @mouseleave="showhomeButtonsRef(null)"
        >
          <div class="homeButtonsItem flex justify-center">
            <div class="flex flex-col justify-center">
              <p
                class="homeButtonsItemParagraph flex items-center justify-center h-3"
                :class="
                  homeButtonsRef == '2'
                    ? 'homeButtonsActiveItemParagraph'
                    : 'homeButtonsPassiveItemParagraph'
                "
              >
                Radyoloji
              </p>

              <button
                v-if="homeButtonsRef == '2'"
                class="flex justify-center pt-10 h-16"
              >
                <p class="flex text-[#EAEAEA]">Devamını gör</p>
              </button>
            </div>
          </div>
        </button>
        <button
          ref="homeButtonsReff"
          class="ml-4"
          :class="
            homeButtonsRef == '3'
              ? 'homeButtonsActiveItem'
              : 'homeButtonsPassiveItem'
          "
          @mouseover="showhomeButtonsRef(3)"
          @mouseleave="showhomeButtonsRef(null)"
        >
          <div class="homeButtonsItem flex justify-center">
            <div class="flex flex-col justify-center">
              <p
                class="homeButtonsItemParagraph flex items-center justify-center h-3"
                :class="
                  homeButtonsRef == '3'
                    ? 'homeButtonsActiveItemParagraph'
                    : 'homeButtonsPassiveItemParagraph'
                "
              >
                Pedodonti Kliniği
              </p>

              <button
                v-if="homeButtonsRef == '3'"
                class="flex justify-center pt-10 h-16"
              >
                <p class="flex text-[#EAEAEA]">Devamını gör</p>
              </button>
            </div>
          </div>
        </button>
        <button
          ref="homeButtonsReff"
          class="ml-4"
          :class="
            homeButtonsRef == '4'
              ? 'homeButtonsActiveItem'
              : 'homeButtonsPassiveItem'
          "
          @mouseover="showhomeButtonsRef(4)"
          @mouseleave="showhomeButtonsRef(null)"
        >
          <div class="homeButtonsItem flex justify-center">
            <div class="flex flex-col justify-center">
              <p
                class="homeButtonsItemParagraph flex items-center justify-center h-3"
                :class="
                  homeButtonsRef == '4'
                    ? 'homeButtonsActiveItemParagraph'
                    : 'homeButtonsPassiveItemParagraph'
                "
              >
                Restoratif Diş Kliniği
              </p>

              <button
                v-if="homeButtonsRef == '4'"
                class="flex justify-center pt-10 h-16"
              >
                <p class="flex text-[#EAEAEA]">Devamını gör</p>
              </button>
            </div>
          </div>
        </button>
        <button
          ref="homeButtonsReff"
          class="ml-4"
          :class="
            homeButtonsRef == '5'
              ? 'homeButtonsActiveItem'
              : 'homeButtonsPassiveItem'
          "
          @mouseover="showhomeButtonsRef(5)"
          @mouseleave="showhomeButtonsRef(null)"
        >
          <div class="homeButtonsItem flex justify-center">
            <div class="flex flex-col justify-center">
              <p
                class="homeButtonsItemParagraph flex items-center justify-center h-3"
                :class="
                  homeButtonsRef == '5'
                    ? 'homeButtonsActiveItemParagraph'
                    : 'homeButtonsPassiveItemParagraph'
                "
              >
                Endodonti Kliniği
              </p>

              <button
                v-if="homeButtonsRef == '5'"
                class="flex justify-center pt-10 h-16"
              >
                <p class="flex text-[#EAEAEA]">Devamını gör</p>
              </button>
            </div>
          </div>
        </button>
        <button
          ref="homeButtonsReff"
          class="mx-4"
          :class="
            homeButtonsRef == '6'
              ? 'homeButtonsActiveItem'
              : 'homeButtonsPassiveItem'
          "
          @mouseover="showhomeButtonsRef(6)"
          @mouseleave="showhomeButtonsRef(null)"
        >
          <div class="homeButtonsItem flex justify-center">
            <div class="flex flex-col justify-center">
              <p
                class="homeButtonsItemParagraph flex items-center justify-center h-3"
                :class="
                  homeButtonsRef == '6'
                    ? 'homeButtonsActiveItemParagraph'
                    : 'homeButtonsPassiveItemParagraph'
                "
              >
                Periodontoloji Kliniği
              </p>

              <button
                v-if="homeButtonsRef == '6'"
                class="flex justify-center pt-10 h-16"
              >
                <p class="flex text-[#EAEAEA]">Devamını gör</p>
              </button>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="mx-auto mt-32 flex w-[60%] flex-row justify-center">
      <div class="flex w-full flex-col">
        <p class="mt-7 flex max-h-[23px] justify-center text-xl font-bold">
          Sıkça Sorulan Sorular
        </p>
        <button
          @click="handleRedirectTelephonePage"
          class="font-roboto mx-auto mt-6 flex h-12 w-1/6 min-w-[140px] items-center justify-center rounded-[10px] bg-[#4198F7] text-white"
        >
          Tümünü Gör
        </button>
      </div>
      <div class="flex w-full flex-col justify-center">
        <div
          class="mx-auto h-10 w-full max-w-[666px] border-t-2 border-[#D9D9D9]"
        />
        <button
          ref="soru"
          class="mx-auto flex h-10 w-full max-w-[544px] flex-row justify-between"
          @click="showSorulanSorular(1)"
        >
          <p
            :class="
              sorulanSorularRef == '1'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            Randevusuz Hasta Alınıyor mu?
          </p>
          <img
            :src="sorulanSorularRef == '1' ? upArrowBlue : downArrowBlack"
            alt=""
            style="width: fit-content"
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[544px] border-t-2 border-[#D9D9D9]"
        />
        <div
          :class="
            sorulanSorularRef == '1'
              ? 'flex max-h-[100px]'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p
            class="sorulanSorularParagraf mx-auto mt-3 flex w-full max-w-[544px]"
          >
            Sağlık Bakanlığı’nın yayınladığı Covid-19 pandemisinde çalışma
            rehberine göre; randevulu hastalara randevu verilerek tedavi
            verilmesi ve hasta trafiğin kontrol altında tutulması istenmektedir.
            Bu sebeple pandemi sürecinde randevusuz hasta bakılamamaktadır.
          </p>
        </div>
        <button
          ref="soru"
          class="mx-auto mt-7 flex h-10 w-full max-w-[544px] flex-row justify-between"
          @click="showSorulanSorular(2)"
        >
          <p
            :class="
              sorulanSorularRef == '2'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            182 MHRS’den Randevu Alabilir miyim?
          </p>
          <img
            :src="sorulanSorularRef == '2' ? upArrowBlue : downArrowBlack"
            alt=""
            style="width: fit-content"
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[544px] border-t-2 border-[#D9D9D9]"
        />
        <div
          :class="
            sorulanSorularRef == '2'
              ? 'flex max-h-[100px]'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p
            class="sorulanSorularParagraf mx-auto mt-3 flex w-full max-w-[544px]"
          >
            Sağlık Bakanlığı’nın yayınladığı Covid-19 pandemisinde çalışma
            rehberine göre; randevulu hastalara randevu verilerek tedavi
            verilmesi ve hasta trafiğin kontrol altında tutulması istenmektedir.
            Bu sebeple pandemi sürecinde randevusuz hasta bakılamamaktadır.
          </p>
        </div>
        <button
          ref="soru"
          class="mx-auto mt-7 flex h-10 w-full max-w-[544px] flex-row justify-between"
          @click="showSorulanSorular(3)"
        >
          <p
            :class="
              sorulanSorularRef == '3'
                ? 'sorulanSorularActiveButtonText'
                : 'sorulanSorularNormalButtonText'
            "
          >
            Randevular Neden İnternet Üzerinden Alınıyor?
          </p>
          <img
            :src="sorulanSorularRef == '3' ? upArrowBlue : downArrowBlack"
            alt=""
            style="width: fit-content"
          />
        </button>
        <div
          class="mx-auto mt-3 h-1 w-full max-w-[544px] border-t-2 border-[#D9D9D9]"
        />
        <div
          :class="
            sorulanSorularRef == '3'
              ? 'flex max-h-[100px]'
              : 'sorulanSorularParagrafHideText'
          "
        >
          <p
            class="sorulanSorularParagraf mx-auto mt-3 flex w-full max-w-[544px]"
          >
            Sağlık Bakanlığı’nın yayınladığı Covid-19 pandemisinde çalışma
            rehberine göre; randevulu hastalara randevu verilerek tedavi
            verilmesi ve hasta trafiğin kontrol altında tutulması istenmektedir.
            Bu sebeple pandemi sürecinde randevusuz hasta bakılamamaktadır.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import landingDisGorseliLogo from "../assets/landingDisGorseliLogo.svg";
import upArrowBlue from "../assets/upArrowBlue.svg";
import downArrowBlack from "../assets/downArrowBlack.svg";
import { ref } from "vue";

let sorulanSorularRef = ref("1");
let homeButtonsRef = ref(null);

const showSorulanSorular = (valueOfRef) => {
  sorulanSorularRef.value = valueOfRef;
};
const showhomeButtonsRef = (valueOfRef) => {
  homeButtonsRef.value = valueOfRef;
};
</script>

<style scoped>
* {
  font-family: "Roboto";
}
.homeButtonsContainerWrap {
  box-sizing: border-box;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: linear-gradient(0, #3bafef 0%, #4198f7 100%);

  border-radius: 30px;
}
.homeButtonsContainer {
  background: white;
  border-radius: 30px;
}
.homeButtonsItem {
  min-height: 186px;
}
.homeButtonsItemParagraph {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.homeButtonsActiveItem {
  background: rgba(65, 152, 247, 0.44);
  box-shadow: -4px 4px 7px rgba(0, 0, 0, 0.25);

  border-radius: 20px;
  color: white;
  height: 228px;
  width: 177px;
}
.homeButtonsPassiveItem {
  border-radius: 20px;
  border: 1px solid #4198f7;

  color: white;
  min-height: 186px;
  width: 177px;
}
.homeButtonsActiveItemParagraph {
  color: white;
  margin-top: 50%;
}
.homeButtonsPassiveItemParagraph {
  color: black;
}
.sorulanSorularActiveButtonText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #4198f7;
}
.sorulanSorularNormalButtonText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.sorulanSorularParagraf {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}
.sorulanSorularParagrafHideText {
  display: none;
}
</style>
