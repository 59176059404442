<template>
  <div class="flex w-full min-h-screen overflow-y-hidden">
    <doctorHastaKabulSidebar />
    <div class="w-full relative flex flex-col overflow-y-hidden">
      <createAnamnesisHeader />
      <main
        class="flex flex-col h-full bg-white headerlessHeight overflow-y-hidden"
      >
        <readAnamnesisTitleDescriptionAndDeleteButton />
        <readAnamnesisTemplate />
      </main>
    </div>
  </div>
</template>

<script setup>
import anamnesisFormElements from "@/modules/anamnezForm/components/createAnamnesisView/anamnesisFormElements.vue";
import createAnamnesisTemplate from "@/modules/anamnezForm/components/createAnamnesisView/createAnamnesisTemplate.vue";
import createAnamnesisHeader from "@/modules/anamnezForm/components/createAnamnesisView/createAnamnesisHeader";
import doctorHastaKabulSidebar from "@/modules/doctorHastaKabul/components/doctorHastaKabulSidebar.vue";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import readAnamnesisTitleDescriptionAndDeleteButton from "../components/readAnamnesisView/readAnamnesisTitleDescriptionAndDeleteButton.vue";
import readAnamnesisTemplate from "../components/readAnamnesisView/readAnamnesisTemplate.vue";
const router = useRouter();
let unitID = router.currentRoute.value.params.unitID;
let anamnesisID = router.currentRoute.value.params.anamnesisID;

onMounted(async () => {
  await anamnesisTemplateStore.getAnamnesisTemplate(anamnesisID);
});
</script>

<style scoped>
.headerlessHeight {
  height: calc(100vh - 73px);
}
</style>
