import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import axios from "axios";
import { fetchAppointmentsRequest } from "@/types/operations/appointment/fetchAppointmentsRequest";

// import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
// import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
// import { globalStore } from "../../globalStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
import { useAppointments } from "@/modules/personel/components/appointmentsStore";

export const useSearchAppointmentsStore = defineStore({
  id: "searchAppointments",
  state: () => ({}),
  actions: {
    async searchAppointments(fetchAppointmentsReq: fetchAppointmentsRequest) {
      try {
        const appointmentsStore = useAppointments();
        const personelStoreData = personelStore();
        const response = await axios.post(
          `${clinicDomain}/appointments/filter`,
          fetchAppointmentsReq,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("searchAppointments", response.data);
        appointmentsStore.appointments = response.data;
        // return response.data.data;
      } catch (error) {
        console.error("Error in searchAppointments:", error);
      }
    },
  },
});
