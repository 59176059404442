<template>
  <div
    class="bg-white text-[#464E5F] z-50 py-2 px-3 border w-fit rounded absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
  >
    <div class="flex items-center justify-between">
      <h4 class="font-medium text-xl mb-3">Yeni Klinik Ekle</h4>
      <button
        class="bg-[#F94144] text-white rounded-full w-8 h-8 flex justify-center items-center"
        @click="klinikYonetimStore.addClinicPopUp = false"
      >
        x
      </button>
    </div>
    <form class="flex flex-col gap-6" @submit="addClinic">
      <div class="flex flex-col">
        <label for="clinicName" class="font-medium">Klinik Adı</label>
        <input
          type="text"
          id="clinicName"
          class="border border-[#d9d9d9] rounded-[10px] p-2 w-[520px]"
        />
      </div>

      <div class="flex flex-col">
        <label for="clinicLocation" class="font-medium">Klinik Rengi</label>
        <Combobox>
          <div class="relative w-full">
            <div
              class="relative border-[1px] w-full cursor-default overflow-hidden rounded-[10px] bg-white text-left"
            >
              <ComboboxInput
                autocomplete="off"
                id="clinicLocation"
                class="w-full py-2 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                :displayValue="(doctor) => doctor.text"
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
              >
                <div
                  v-if="filteredDoctors.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Sonuç bulunamadı
                </div>

                <ComboboxOption
                  v-for="doctor in { id: 1, text: 'Dr. Ahmet' }"
                  as="template"
                  :key="doctor.id"
                  :value="doctor"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">
                      {{ doctor.text }}
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>
      </div>

      <div class="flex flex-col">
        <label for="clinicLocation" class="font-medium">Klinik Lokasyon</label>
        <Combobox>
          <div class="relative w-full">
            <div
              class="relative border-[1px] w-full cursor-default overflow-hidden rounded-[10px] bg-white text-left"
            >
              <ComboboxInput
                autocomplete="off"
                id="clinicLocation"
                class="w-full py-2 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                :displayValue="(doctor) => doctor.text"
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
              >
                <div
                  v-if="filteredDoctors.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Sonuç bulunamadı
                </div>

                <ComboboxOption
                  v-for="doctor in { id: 1, text: 'Dr. Ahmet' }"
                  as="template"
                  :key="doctor.id"
                  :value="doctor"
                  v-slot="{ active }"
                >
                  <li
                    class="relative cursor-default select-none my-2 py-2 pl-3"
                    :class="{
                      'bg-teal-600 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    <span class="block truncate">
                      {{ doctor.text }}
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>
      </div>
      <button
        type="submit"
        class="rounded-lg bg-[#277dd2] w-full py-2 text-white text-xl font-medium"
      >
        Kaydet
      </button>
    </form>
  </div>
</template>

<script setup>
import { useKlinikYonetimStore } from "@/modules/klinikYonetim/store/klinikYonetim";
const klinikYonetimStore = useKlinikYonetimStore();
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronUpDownIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";

const addClinic = (e) => {
  e.preventDefault();
};
</script>
