<template>
  <div class="flex w-full">
    <appointmentViewSideBar :activePage="5" />
    <div class="w-full bg-[#fbfbfb] flex flex-col">
      <AppointmentViewNavBar />
      <main class="p-4">
        <div
          class="px-2 py-[2px] rounded bg-[#277dd2] text-white flex items-center gap-2 w-fit"
        >
          <img :src="odemelerimLogoWhite" alt="" />
          <span class="mt-[2px]">Ödemelerim</span>
        </div>
        <div
          class="bg-white text-[#464E5F] h-fit shadow px-4 py-10 flex justify-between flex-col xl:flex-row gap-10 sm:gap-3"
        >
          <div class="flex-1">
            <h4 class="text-xl">Ödenmiş Tedaviler</h4>
            <div class="w-11 h-[2px] mt-2 mb-3 bg-black rounded"></div>
            <div
              v-if="1 !== 1"
              class="px-3 bg-[#EBEBEB] w-full 2xl:w-2/3 text-[#707070] font-medium text-xl sm:text-3xl py-[15px] flex items-center justify-center mt-3 rounded-lg whitespace-nowrap"
            >
              Bulunamadı
            </div>
            <div
              class="flex flex-col text-[#263445] gap-6 divide-y-[1px] h-96 overflow-auto"
              v-else
            >
              <div
                v-for="(payment, index) in [1, 2, 3, 4]"
                :key="index"
                class="pt-2 flex flex-col w-fit"
              >
                <div class="flex flex-wrap sm:flex-row sm:items-center gap-3">
                  <span
                    class="bg-[#373737] w-fit text-white rounded-[10px] px-2 py-1"
                    >20.01.2021 15:00</span
                  >
                  <span>KSBÜ Ağız ve Diş</span>
                </div>
                <div
                  class="flex flex-wrap sm:flex-row sm:items-center gap-3 sm:gap-8 text-sm mt-1"
                >
                  <span class="items-center gap-1 flex"
                    ><img :src="ticketLogo" />Kredi Kartı ile Ödendi</span
                  >

                  <span class="flex items-center gap-1"
                    ><img :src="BolumIcon" />Periodontoloji
                  </span>
                  <span class="flex items-center gap-1"
                    ><img :src="personImg" />Yasemin Beliz ÖNDER
                  </span>
                </div>
                <span
                  class="bg-[#5CB85C] rounded py-1 px-2 text-white text-sm ml-auto mt-2"
                >
                  500 TL Ödendi
                </span>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <h4 class="text-xl">Ödenmemiş Tedaviler</h4>
            <div class="w-11 h-[2px] mt-2 mb-3 bg-black rounded"></div>
            <div
              v-if="1 !== 1"
              class="px-3 bg-[#EBEBEB] w-full 2xl:w-2/3 text-[#707070] font-medium text-xl sm:text-3xl py-[15px] flex items-center justify-center mt-3 rounded-lg whitespace-nowrap"
            >
              Bulunamadı
            </div>
            <div
              class="flex flex-col text-[#263445] gap-6 divide-y-[1px] h-96 overflow-auto"
              v-else
            >
              <div
                v-for="(payment, index) in [1, 2, 3, 4]"
                :key="index"
                class="pt-2 flex flex-col w-fit"
              >
                <div class="flex flex-wrap sm:flex-row sm:items-center gap-3">
                  <span
                    class="bg-[#373737] w-fit text-white rounded-[10px] px-2 py-1"
                    >20.01.2021 15:00</span
                  >
                  <span>KSBÜ Ağız ve Diş</span>
                </div>
                <div
                  class="flex flex-wrap sm:flex-row sm:items-center gap-3 sm:gap-8 text-sm mt-1"
                >
                  <span class="items-center gap-1 flex"
                    ><img :src="ticketLogo" />Kredi Kartı ile Ödendi</span
                  >

                  <span class="flex items-center gap-1"
                    ><img :src="BolumIcon" />Periodontoloji
                  </span>
                  <span class="flex items-center gap-1"
                    ><img :src="personImg" />Yasemin Beliz ÖNDER
                  </span>
                </div>
                <div class="flex flex-wrap gap-4 items-center justify-end mt-2">
                  <span
                    class="bg-[#E12901] rounded py-1 px-2 text-white text-sm"
                    >500 TL ödeme bekliyor</span
                  >
                  <button
                    class="border-[1px] border-[#277DD2] rounded py-1 px-2 text-[#277DD2] text-sm"
                  >
                    Ödeme Yap
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <appointmentHelpInfo />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import appointmentHelpInfo from "../components/appointmentHelpInfo.vue";

import moment from "moment";
import { useRouter } from "vue-router";
import addDays from "date-fns/addDays";
import ticketLogo from "@/modules/asistanEkrani/assets/ticketLogo.svg";
import personImg from "@/modules/asistanEkrani/assets/personImg.svg";

import appointmentViewSideBar from "../components/appointmentViewSideBar.vue";
import AppointmentViewNavBar from "../components/appointmentViewNavBar.vue";
import odemelerimLogoWhite from "../assets/odemeWhite.svg";
import BolumIcon from "../assets/BolumIcon.svg";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useGetUnit } from "@/store/modules/administratorManagementStore/hospital/Units/getUnitStore";
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic.ts";
import { useGetSlot } from "@/store/modules/operationsStore/slot/getSlot";
const searchFreeSlotsForClinicStore = SearchFreeSlotsforClinic();

const unitListStore = useGetUnitsList();
import { toTitleCase } from "@/utils/formatString";
const router = useRouter();

onMounted(async () => {
  console.log("hi");
});
</script>
