import { defineStore } from "pinia";
export const usePersonalStore = defineStore("usePersonalStore", {
  id: "usePersonalStore",
  state: () => ({
    personalSidebarIsExpanded: true,
    personalSideBarActiveButton: 1,
    personalNavbar: 1,
    personalNavbarHastaIslemleriSubNavbar: 0,
    personalNavbarHastaListesiSubNavbar: 0,
    isAuthPasswordFalse: false,
    iForgetMyPassword: 0,
    personalTableBirimFilter: "350392bb-80f7-43d4-a7f2-007b78c3361b",
    dailyAppointmentsPage: 1,
    dailyProvisionsPage: 1,
    dailyPatientsPage: 1,
  }),
});
