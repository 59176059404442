import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import axios from "axios";
import { personelStore } from "../../administratorManagementStore/personel";
import _ from "lodash";

export const useListAnnouncementsStore = defineStore({
  id: "listAnnouncements",
  actions: {
    async listAnnouncements() {
      try {
        const personelStoreData = personelStore();
        const response = await axios.get(
          `${clinicDomain}/release-notes?page_id=1&page_size=100`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("listAnnouncements", response.data);
        return response.data.data;
      } catch (error) {
        console.error("Error in listAnnouncements:", error);
      }
    },
  },
});
