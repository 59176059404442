<template>
  <div>
    <h4
      class="w-fit text-lg border-b-2 border-[#277DD2] text-[#09244B] font-medium pb-1"
    >
      Klinik & Hekim Bilgileri
    </h4>
    <div class="py-4">
      <div class="flex items-center gap-8">
        <div class="flex items-center justify-between w-[370px]">
          <p>Klinik Adı</p>
          <Combobox
            v-model="store.medulaProvizyon.BirimAdı"
            :disabled="isDisabled('klinik_adi')"
          >
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(unit) => unit.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredUnits.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="unit in filteredUnits"
                    as="template"
                    :key="unit.id"
                    :value="unit"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ unit.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
        <div class="flex items-center justify-between w-[370px]">
          <p>Doktor Adı</p>

          <Combobox
            v-model="store.medulaProvizyon.DoktorAdı"
            :disabled="isDisabled('doktor_adi')"
          >
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(doctor) => doctor.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredDoctors.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="doctor in filteredDoctors"
                    as="template"
                    :key="doctor.id"
                    :value="doctor"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ doctor.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
      </div>
      <div class="flex items-center gap-8 mt-[20px]">
        <!-- branch -->
        <div class="flex items-center justify-between w-[370px]">
          <p>Branş Adı/Kodu</p>
          <div class="w-[250px] flex justify-between">
            <div
              class="w-[185px] h-[28px] rounded items-center flex border p-[6px] bg-[#FBFBFB]"
            >
              <p class="max-w-[180px] whitespace-nowrap overflow-x-hidden">
                {{ store.medulaProvizyonButtonVModel.branchNameText }}
              </p>
            </div>
            <div
              class="w-[60px] h-[28px] rounded items-center flex border p-[6px] bg-[#FBFBFB]"
            >
              <p>
                {{ store.medulaProvizyonButtonVModel.branchCode }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import _ from "lodash";
import Select2 from "vue3-select2-component";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
let query = ref("");
//store imports
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { addPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/addPatientApplicationStore.ts";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import { useGetDoctorsByUnitStore } from "@/store/modules/administratorManagementStore/staff/getDoctorsByUnit";
import { useGetClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/getClinicalSettings";

//store inits
const store = useMedula();
const applicationStore = addPatientApplicationStore();
const applicationState = applicationStore.addPatientApplicationRequest;
const applicationProvisionStore = useApplicationProvisionStore();
const provisionState = applicationProvisionStore.applicationProvisionRequest;
const staffListStore = useGetStaffList();
const unitListStore = useGetUnitsList();
const doctorsByUnitStore = useGetDoctorsByUnitStore();
const getClinicalSettingsStore = useGetClinicalSettings();

const isDisabled = (setting) => {
  if (!getClinicalSettingsStore.getClinicalSettingsResponse) return false;

  return !(
    getClinicalSettingsStore.getClinicalSettingsResponse &&
    (getClinicalSettingsStore.getClinicalSettingsResponse[setting] === "" ||
      getClinicalSettingsStore.getClinicalSettingsResponse[setting] === "-")
  );
};
const chooseDoctor = (doktor) => {
  console.log(doktor);
  applicationState.doctor_code = doktor.personel_kodu;
  // store.medulaProvizyon.DoktorAdı = doktor.Ad + " " + doktor.Soyadi;
};
const chooseBirim = (birim) => {
  console.log(birim);
  // AddpatientApplicationStore.addPatientApplicationRequest.branskodu =
  //   birim.MedulaBransKodu.String;

  // AddpatientApplicationStore.addPatientApplicationRequest.birimkodu =
  //   birim.BirimKodu;
  store.medulaProvizyonButtonVModel.branchNameText = birim.text;
  store.medulaProvizyonButtonVModel.branchCode = birim.medula_brans_kodu;
  applicationState.unit_code = birim.birim_kodu;
  applicationState.branch_code = birim.medula_brans_kodu;
  store.medulaProvizyon.DoktorAdı = "";
  // store.medulaProvizyon.BirimAdı.text = birim.text;
  // store.medulaProvizyon.BirimAdı.id = birim.id;
};

// units

let filteredUnits = computed(() =>
  query.value === ""
    ? unitListStore.getListUnitsResponse
    : unitListStore.getListUnitsResponse.filter((unit) =>
        unit.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => store.medulaProvizyon.BirimAdı,
  (newValue) => {
    console.log(newValue);
    store.medulaProvizyonButtonVModel.branchNameText = newValue.text;
    store.medulaProvizyonButtonVModel.branchCode = newValue.medula_brans_kodu;
    applicationState.unit_code = newValue.birim_kodu;
    applicationState.branch_code = newValue.medula_brans_kodu;
    store.medulaProvizyon.DoktorAdı = "";
  }
);

// doctors

let filteredDoctors = computed(() =>
  query.value === ""
    ? store.filteredDoctors
    : store.filteredDoctors.filter((doctor) =>
        doctor.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => store.medulaProvizyon.DoktorAdı,
  (newValue) => {
    applicationState.doctor_code = newValue.personel_kodu;
  }
);

watch(
  () => store.medulaProvizyon.BirimAdı,
  async (newVal) => {
    console.log(newVal);
    if (newVal.id === "" || newVal.id === "-" || newVal === "") {
      store.filteredDoctors = await staffListStore.getStaffList();
    } else {
      store.filteredDoctors = await doctorsByUnitStore.getDoctorsByUnit(
        newVal.id
      );
    }
  }
);
</script>
