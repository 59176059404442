import { MuayeneOncelikSirasi } from "@/types/operations/patient/patientDetails/muayeneOncelikSirasi";
import { YabanciHastaTuru } from "@/types/operations/patient/patientDetails/yabanciHastaTuru";
import { GuncellemeZamani } from "@/types/operations/patient/patientDetails/guncellemeZamani";
import { ReferansTabloAdi } from "@/types/operations/patientApplications/patientApplicationsDetails/referansTabloAdi";
import { BagliOlduguBasvuruKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/bagliOlduguBasvuruKodu";
import { DefterNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/defterNumarasi";
import { GunlukSiraNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/gunlukSiraNumarasi";
import { TamamlayiciKurumKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/tamamlayiciKurumKodu";
import { OnlineProtokolNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/onlineProtokolNumarasi";
import { GeblizBildirimNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/geblizBildirimNumarasi";
import { OlayAfetKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/olayAfetKodu";
import { HayatiTehlikeDurumu } from "@/types/operations/patientApplications/patientApplicationsDetails/hayatiTehlikeDurumu";
import { KabulSekli } from "@/types/operations/patientApplications/patientApplicationsDetails/kabulSekli";
import { SosyalGuvenceDurumu } from "@/types/operations/patientApplications/patientApplicationsDetails/sosyalGuvenceDurumu";
import { HekimKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/hekimKodu";
import { AsistanHekimKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/asistanHekimKodu";
import { HekimBasvuruNotu } from "@/types/operations/patientApplications/patientApplicationsDetails/hekimBasvuruNotu";
import { VakaTuru } from "@/types/operations/patientApplications/patientApplicationsDetails/vakaTuru";
import { SevkTanisi } from "@/types/operations/patientApplications/patientApplicationsDetails/sevkTanisi";
import { SevkZamani } from "@/types/operations/patientApplications/patientApplicationsDetails/sevkZamani";
import { AdliVakaGelisSekli } from "@/types/operations/patientApplications/patientApplicationsDetails/adliVakaGelisSekli";
import { AmbulansPlakaNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/ambulansPlakaNumarasi";
import { TriajKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/triajKodu";
import { SysTakipNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/sysTakipNumarasi";
import { SysReferansNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/sysReferansNumarasi";
import { YatisBilgisi } from "@/types/operations/patientApplications/patientApplicationsDetails/yatisBilgisi";
import { YatisProtokolNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/yatisProtokolNumarasi";
import { BasvuruDurumu } from "@/types/operations/patientApplications/patientApplicationsDetails/BasvuruDurumu";
import { MuayeneBaslamaZamani } from "@/types/operations/patientApplications/patientApplicationsDetails/muayeneBaslamaZamani";
import { MuayeneBitisZamani } from "@/types/operations/patientApplications/patientApplicationsDetails/muayeneBitisZamani";
import { MuayeneTuru } from "@/types/operations/patientApplications/patientApplicationsDetails/muayeneTuru";
import { CikisZamani } from "@/types/operations/patientApplications/patientApplicationsDetails/cikisZamani";
import { CikisSekli } from "@/types/operations/patientApplications/patientApplicationsDetails/cikisSekli";
import { CikisVerenHekimKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/cikisVerenHekimKodu";
import { SaglikTurizmiUlkeKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/saglikTurizmiUlkeKodu";
import { GeldigiUlkeKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/geldigiUlkeKodu";
import { GenclikSagligiIslemleri } from "@/types/operations/patientApplications/patientApplicationsDetails/genclikSagligiIslemleri";
import { DiyabetEgitimi } from "@/types/operations/patientApplications/patientApplicationsDetails/diyabetEgitimi";
import { DiyabetKomplikasyonlari } from "@/types/operations/patientApplications/patientApplicationsDetails/diyabetKomplikasyonlari";
import { MhrsRandevuNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/mhrsRandevuNumarasi";
import { SevkNedeni } from "@/types/operations/patientApplications/patientApplicationsDetails/sevkNedeni";
import { HekimIhtiyaci } from "@/types/operations/patientApplications/patientApplicationsDetails/hekimIhtiyaci";
import { GoruntulemeCihaziBulunmamasi } from "@/types/operations/patientApplications/patientApplicationsDetails/goruntulemeCihaziBulunmamasi";
import { Takipno } from "@/types/operations/patientApplications/patientApplicationsDetails/takipno";
import { Yatisbitistarihi } from "@/types/operations/patientApplications/patientApplicationsDetails/yatisbitistarihi";
import { Yakinlikkodu } from "@/types/operations/patientApplications/patientApplicationsDetails/yakinlikkodu";
import { Vakatarihi } from "@/types/operations/patientApplications/patientApplicationsDetails/vakatarihi";
import { Kurumsevktalepno } from "@/types/operations/patientApplications/patientApplicationsDetails/kurumsevktalepno";
import { Maluliyetsevktarihi } from "@/types/operations/patientApplications/patientApplicationsDetails/maluliyetsevktarihi";
import { Vakail } from "@/types/operations/patientApplications/patientApplicationsDetails/vakail";
import { Vakailce } from "@/types/operations/patientApplications/patientApplicationsDetails/vakailce";
import { MedulaBasvuruNo } from "@/types/operations/patientApplications/patientApplicationsDetails/medulaBasvuruNo";
import { HastaBasvuruTani } from "@/types/operations/patientApplications/patientApplicationsDetails/hastaBasvuruTani";
import { Istisnaihal } from "@/types/operations/patientApplications/patientApplicationsDetails/istisnaihal";
import { SgkTakipNumarasi } from "@/types/operations/patientApplications/patientApplicationsDetails/sgkTakipNumarasi";
import { AracTuru } from "@/types/operations/patientApplications/patientApplicationsDetails/aracTuru";
import { GuncelleyenKullaniciKodu } from "@/types/operations/patientApplications/patientApplicationsDetails/guncelleyenKullaniciKodu";
import { AmbulansTakipNumarasi } from "./patientApplicationsDetails/ambulansTakipNumarasi";

let today = new Date();
let day = String(today.getDate()).padStart(2, "0");
let month = String(today.getMonth() + 1).padStart(2, "0");
let year = today.getFullYear();
let formattedDate = day + "." + month + "." + year;

export const addPatientApplicationRequest: AddPatientApplicationRequest = {
  patient_id: "",
  linked_application_code: "",
  record_number: "",
  daily_order_number: 0,
  examination_precedence: "",
  service_server: "test kurum",
  storage_place: "test kurum",
  corporation_code: "ba3cc0c4-f88d-42a1-8719-c0466dbef94c",
  subsidiary_corporation_code: "",
  online_protocol_number: "",
  // application_protocol_number: "",
  gebliz_notification_number: "",
  incident_disaster_code: "",
  life_threatening_situation: "",
  patient_acceptance_time:
    new Date(new Date().getTime() + 3 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 19) + "Z",
  acceptence_type: "",
  social_security_situation: "",
  doctor_code: "",
  assistant_doctor_code: "",
  doctor_application_note: "",
  case_type: "",
  dispatch_diagnosis: "",
  dispatch_time: "",
  forensic_case_arrival_type: "",
  vehicle_type: "",
  tracking_number_of_ambulance: "",
  licence_number_of_ambulance: "",
  triage_code: "",
  sys_tracking_number: "",
  sys_reference_number: "",
  hospitalization_information: "",
  hospitalization_protocol_number: "",
  application_status: "",
  unit_code: "",
  examination_started_at: "",
  examinaton_ended_at: "",
  examination_type: "",
  treatment_type: "",
  exit_time: "",
  exit_type: "",
  doctor_code_exited: "",
  medical_tourism_country_code: "",
  country_code_of_origin: "",
  foreign_patient_type: "",
  youth_health_services: "",
  diabetes_education: 0,
  diabetes_conjuncts: "",
  provision_type: "",
  branch_code: "",
  follow_type: "",
  tedavi_tipi: "",
  tedavi_turu_medula: "",
  ktshbys_kodu: "kts",
  plaka_no: "",
  vaka_il: "",
  vaka_ilce: "",
  vaka_tarihi: "",
};

export interface AddPatientApplicationRequest {
  patient_id: string;
  linked_application_code: string;
  record_number: string;
  daily_order_number: number;
  examination_precedence: string;
  service_server: string;
  storage_place: string;
  corporation_code: string;
  subsidiary_corporation_code: string;
  online_protocol_number: string;
  // application_protocol_number: string;
  gebliz_notification_number: string;
  incident_disaster_code: string;
  life_threatening_situation: string;
  patient_acceptance_time: string;
  acceptence_type: string;
  social_security_situation: string;
  doctor_code: string;
  assistant_doctor_code: string;
  doctor_application_note: string;
  case_type: string;
  dispatch_diagnosis: string;
  dispatch_time: string;
  forensic_case_arrival_type: string;
  vehicle_type: string;
  tracking_number_of_ambulance: string;
  licence_number_of_ambulance: string;
  triage_code: string;
  sys_tracking_number: string;
  sys_reference_number: string;
  hospitalization_information: string;
  hospitalization_protocol_number: string;
  application_status: string;
  unit_code: string;
  examination_started_at: string;
  examinaton_ended_at: string;
  examination_type: string;
  treatment_type: string;
  exit_time: string;
  exit_type: string;
  doctor_code_exited: string;
  medical_tourism_country_code: string;
  country_code_of_origin: string;
  foreign_patient_type: string;
  youth_health_services: string;
  diabetes_education: number;
  diabetes_conjuncts: string;
  provision_type: string;
  branch_code: string;
  follow_type: string;
  tedavi_tipi: string;
  tedavi_turu_medula: string;
  ktshbys_kodu: string;
  plaka_no: string;
  vaka_il: string;
  vaka_ilce: string;
  vaka_tarihi: string;
}

export interface UpdatePatientApplicationRequest {
  application_id: string;
  provision_type: string;
  treatment_type: string;
  follow_type: string;
  unit_id: string;
  doctor_id: string;
  branch_code: string;
  tedavi_tipi: string;
}
