<template>
  <div
    class="mx-auto w-[850px] bg-white py-3 border-2 border-l-[10px] border-l-[#287dd2] rounded-[10px] h-fit"
  >
    <div class="pl-6 flex flex-col mb-2">
      <p class="font-medium text-lg w-full mb-3 bg-transparent">
        <span class="text-red-600 text-2xl" v-if="formElement.is_required"
          >*</span
        >
        {{ formElement.question }}
      </p>
      <input
        class="border w-[700px] rounded-lg p-2 bg-[#f5f5f5] text-sm"
        type="text"
        :maxlength="formElement.answer.maxLength"
        :placeholder="formElement.answer.placeholder || 'Yanıtlayınız'"
      />
    </div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
const uuid = ref(uuidv4());
const props = defineProps({
  index: Number,
  formElement: Object,
});
</script>
