import { TcKimlikNumarasi } from "@/types/operations/patient/patientDetails/tcKimlikNumarasi";
import { KimliksizHastaBilgisi } from "@/types/operations/patient/patientDetails/kimliksizHastaBilgisi";
import { Uyruk } from "@/types/operations/patient/patientDetails/uyruk";
import { DogumTarihi } from "@/types/operations/patient/patientDetails/dogumTarihi";
import { BeyanDogumTarihi } from "@/types/operations/patient/patientDetails/beyanDogumTarihi";
import { DogumYeri } from "@/types/operations/patient/patientDetails/dogumYeri";
import { DogumSirasi } from "@/types/operations/patient/patientDetails/dogumSirasi";
import { MedeniHali } from "@/types/operations/patient/patientDetails/medeniHali";
import { Meslek } from "@/types/operations/patient/patientDetails/meslek";
import { OgrenimDurumu } from "@/types/operations/patient/patientDetails/ogrenimDurumu";
import { KanGrubu } from "@/types/operations/patient/patientDetails/kanGrubu";
import { MuayeneOncelikSirasi } from "@/types/operations/patient/patientDetails/muayeneOncelikSirasi";
import { EngellilikDurumu } from "@/types/operations/patient/patientDetails/engellilikDurumu";
import { OlumTarihi } from "@/types/operations/patient/patientDetails/olumTarihi";
import { OlumYeri } from "@/types/operations/patient/patientDetails/olumYeri";
import { PasaportNumarasi } from "@/types/operations/patient/patientDetails/pasaportNumarasi";
import { YupassNumarasi } from "@/types/operations/patient/patientDetails/yupassNumarasi";
import { SonKurumKodu } from "@/types/operations/patient/patientDetails/sonKurumKodu";
import { GuncellemeZamani } from "@/types/operations/patient/patientDetails/guncellemeZamani";
import { GuncelleyenKullaniciKodu } from "@/types/operations/patient/patientDetails/guncelleyenKullaniciKodu";
import { DevredilenKurum } from "@/types/operations/patient/patientDetails/devredilenKurum";
import { YesilKartSevkEdenTesisKodu } from "@/types/operations/patient/patientDetails/yesilKartSevkEdenTesisKodu";
import { HastaAdres } from "@/types/operations/patient/patientDetails/hastaAdres";
import { YabanciHastaTuru } from "@/types/operations/patient/patientDetails/yabanciHastaTuru";
import { GeldigiUlke } from "@/types/operations/patient/patientDetails/geldigiUlke";
import { HastaTelefon } from "@/types/operations/patient/patientDetails/hastaTelefon";
import { HastaEvTelefon } from "@/types/operations/patient/patientDetails/hastaEvTelefon";
import { Email } from "@/types/operations/patient/patientDetails/email";

export const emptyAddPatientRequest: AddPatientRequest = {
  tc_identity_number: "",
  patient_info_without_id: "",
  nationality: "",
  patient_type: "tüm hastalar",
  name: "",
  surname: "",
  dob: "",
  declared_dob: "",
  birthplace_province: "",
  birthplace_district: "",
  gender: "",
  anne_tc_kimlik_numarasi: "",
  baba_tc_kimlik_numarasi: "",
  mother_tc_identity_number: "",
  father_tc_identity_number: "",
  mother_patient_code: "",
  father_patient_code: "",
  birth_order: 1,
  mother_name: "",
  father_name: "",
  marital_status: "",
  occupation: "",
  educational_background: "",
  blood_type: "",
  examination_precedence: "",
  disability_status: "",
  date_of_death: "",
  place_of_death: "",
  passport_number: "",
  yupass_number: "",
  last_corporation_code: "ba3cc0c4-f88d-42a1-8719-c0466dbef94c",
  province: "",
  district: "",
  address_description: "",
  phone_number: "",
  home_phone_number: "",
  work_phone_number: "",
  email: "",
};

export interface AddPatientRequest {
  tc_identity_number: string;
  patient_info_without_id: string;
  nationality: string;
  patient_type: string;
  name: string;
  surname: string;
  dob: string;
  declared_dob: string;
  birthplace_province: string;
  birthplace_district: string;
  gender: string;
  anne_tc_kimlik_numarasi: string;
  baba_tc_kimlik_numarasi: string;
  mother_tc_identity_number: string;
  father_tc_identity_number: string;
  mother_patient_code: string;
  father_patient_code: string;
  birth_order: number;
  mother_name: string;
  father_name: string;
  marital_status: string;
  occupation: string;
  educational_background: string;
  blood_type: string;
  examination_precedence: string;
  disability_status: string;
  date_of_death: string;
  place_of_death: string;
  passport_number: string;
  yupass_number: string;
  last_corporation_code: string;
  province: string;
  district: string;
  address_description: string;
  phone_number: string;
  home_phone_number: string;
  work_phone_number: string;
  email: string;
}
