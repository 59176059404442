import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import axios from "axios";
import { personelStore } from "../../administratorManagementStore/personel";
import _ from "lodash";

export const useGetDoctorsByUnitStore = defineStore({
  id: "getDoctorsByUnit",
  state: () => ({}),
  actions: {
    async getDoctorsByUnit(id: string) {
      try {
        const personelStoreData = personelStore();
        const response = await axios.get(
          `${clinicDomain}/staffs/unit?unit_id=${id}&page_id=1&page_size=50`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        console.log("getDoctorByUnit", response.data.staffs);
        return response.data.staffs.map((item, index) => {
          return {
            ...item,
            id: item.personel_kodu,
            text: item.ad + " " + _.startCase(_.toLower(item.soyadi)),
          };
        });
      } catch (error) {
        console.error("Error in getDoctorByUnit:", error);
      }
    },
  },
});
