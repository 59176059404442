<template>
  <div class="flex items-center justify-between mb-2 text-[#40464C]">
    <button
      class="flex gap-3 items-center font-medium"
      @click="router.push('/polyclinic/clinic-management')"
    >
      <img :src="leftArrow" alt="" />Geri
    </button>
    <h2 class="text-center text-xl font-medium uppercase">
      {{ klinikYonetimStore.klinikUnitScreenUnitDatas?.birim_adi }}
    </h2>
    <button class="bg-[#F94144] text-white py-2 px-4 rounded-3xl">
      Kliniği sil
    </button>
  </div>

  <div class="border rounded-t mx-auto flex text-[#40464C]">
    <button
      @click="klinikYonetimStore.klinikYonetimNavbar = 1"
      class="w-[200px] py-2 font-medium rounded-t"
      :class="
        klinikYonetimStore.klinikYonetimNavbar === 1
          ? 'bg-[#277dd2] text-white'
          : 'bg-white text-[#a0a3a6]'
      "
    >
      Doktor Listesi
    </button>
    <button
      @click="klinikYonetimStore.klinikYonetimNavbar = 2"
      class="w-[200px] py-2 font-medium rounded-t"
      :class="
        klinikYonetimStore.klinikYonetimNavbar === 2
          ? 'bg-[#277dd2] text-white'
          : 'bg-white text-[#a0a3a6]'
      "
    >
      Randevu Listesi
    </button>
    <button
      @click="klinikYonetimStore.klinikYonetimNavbar = 3"
      class="w-[200px] py-2 font-medium rounded-t"
      :class="
        klinikYonetimStore.klinikYonetimNavbar === 3
          ? 'bg-[#277dd2] text-white'
          : 'bg-white text-[#a0a3a6]'
      "
    >
      Unit Listesi
    </button>
    <button
      @click="klinikYonetimStore.klinikYonetimNavbar = 4"
      class="w-[200px] py-2 font-medium rounded-t"
      :class="
        klinikYonetimStore.klinikYonetimNavbar === 4
          ? 'bg-[#277dd2] text-white'
          : 'bg-white text-[#a0a3a6]'
      "
    >
      Çizelge Listesi
    </button>
  </div>
</template>

<script setup>
import leftArrow from "@/modules/personel/assets/leftArrow.svg";
import { useRouter } from "vue-router";

const router = useRouter();

import { useKlinikYonetimStore } from "../store/klinikYonetim";
const klinikYonetimStore = useKlinikYonetimStore();
</script>
