<template>
    <div
      v-if="store.studentAssistantFilter===1"
      class="absolute top-11 ml-[30px] h-[456px] w-[340px] rounded-[10px] border-2 border-[#D9D9D9] bg-white"
    >
      <p class="ml-[18px] mt-[17px] text-[#999999]">Bir alan seç</p>
      <div class="mx-5 w-[300px] border-t-[1px]"></div>
  
      <div class="ml-[18px] mt-[22px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[0] =
              !store.studentAssistantHideItems[0]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[0] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[0] }"
            ></div>
          </div>
        </div>
        <p class="ml-[12px] font-light">Hasta Telefon</p>
      </div>
      <div class="ml-[18px] mt-[16px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[1] =
              !store.studentAssistantHideItems[1]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[1] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[1] }"
            ></div>
          </div>
        </div>
        <p class="ml-[12px] font-light">Hasta Dosya No</p>
      </div>
      <div class="ml-[18px] mt-[6px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[2] =
              !store.studentAssistantHideItems[2]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[2] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[2] }"
            ></div>
          </div>
        </div>
        <studentAssistantFilterButtonFilterButton
          class="mt-[5px]"
          :valueOfTag="'Dönem'"
          :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
        />
      </div>
  
      <div class="ml-[18px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[3] =
              !store.studentAssistantHideItems[3]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[3] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[3] }"
            ></div>
          </div>
        </div>
        <studentAssistantFilterButtonFilterButton
          class="mt-[5px]"
          :valueOfTag="'Grup'"
          :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
        />
      </div>
  
      <div class="ml-[18px] mt-[2px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[4] =
              !store.studentAssistantHideItems[4]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[4] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[4] }"
            ></div>
          </div>
        </div>
        <studentAssistantFilterButtonFilterButton
          class="mt-[5px]"
          :valueOfTag="'Öğrenci Numarası'"
          :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
        />
      </div>
  
      <div class="ml-[18px] mt-[2px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[5] =
              !store.studentAssistantHideItems[5]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[5] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[5] }"
            ></div>
          </div>
        </div>
        <studentAssistantFilterButtonFilterButton
          class="mt-[5px]"
          :valueOfTag="'Doktor Endikasyon'"
          :valuesOfItems="['4.sınıf', '5.sınıf', '4 veya 5.sınıf']"
        />
      </div>
  
      <div class="ml-[18px] mt-[8px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[6] =
              !store.studentAssistantHideItems[6]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[6] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[6] }"
            ></div>
          </div>
        </div>
        <p class="ml-[12px] font-thin">Öğrenci Ad/Soyad</p>
      </div>
      <div class="ml-[18px] mt-[16px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[7] =
              !store.studentAssistantHideItems[7]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[7] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[7] }"
            ></div>
          </div>
        </div>
        <p class="ml-[12px] font-thin">Endikasyon Tarihi</p>
      </div>
      <div class="ml-[18px] mt-[16px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[8] =
              !store.studentAssistantHideItems[8]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[8] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[8] }"
            ></div>
          </div>
        </div>
        <p class="ml-[12px] font-thin">Açıklama</p>
      </div>
      <div class="ml-[18px] mt-[16px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[9] =
              !store.studentAssistantHideItems[9]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[9] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[9] }"
            ></div>
          </div>
        </div>
        <p class="ml-[12px] font-thin">Bölüm/Sabit/Total</p>
      </div>
      <div class="ml-[18px] mt-[16px] flex">
        <div
          class="flex cursor-pointer items-center justify-between"
          @click="
            store.studentAssistantHideItems[10] =
              !store.studentAssistantHideItems[10]
          "
        >
          <div
            class="flex h-[18px] w-8 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              store.studentAssistantHideItems[10] === true
                ? 'bg-[#178805]'
                : 'bg-gray-300'
            "
          >
            <div
              class="h-3 w-3 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{ 'translate-x-3': store.studentAssistantHideItems[10] }"
            ></div>
          </div>
        </div>
        <p class="ml-[12px] font-thin">Total/Bölümlü Hareketli/Sabit</p>
      </div>
    </div>
  </template>
  
  
  <script setup>
  // Import store
  import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
  const store = studentAssistantStore();
  
  import studentAssistantFilterButtonFilterButton from "./studentAssistantFilterButtonFilterButton.vue";
  </script>