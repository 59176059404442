<template>
  <div class="bg-[#FCFCFC] h-[107px] rounded-lg border shadow text-[#464E5F]">
    <div class="flex gap-8 items-center justify-center h-full">
      <button
        @click="moveDate('back')"
        class="bg-[#464e5f] rounded-full h-[40px] w-[40px] flex items-center justify-center"
      >
        <img :src="leftArrow" alt="" />
      </button>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="flex flex-col items-center justify-center my-auto"
      >
        <div
          class="font-medium uppercase"
          :class="{ 'text-[#277dd2]': isActive(index) }"
        >
          {{ item.day }}
        </div>
        <button
          :class="{ 'bg-[#277dd2] text-white': isActive(index) }"
          class="w-[40px] h-[40px] border flex items-center justify-center"
          @click="selectDate(index)"
        >
          {{ item.date }}
        </button>
      </div>
      <button
        @click="moveDate('forward')"
        class="bg-[#464e5f] rounded-full h-[40px] w-[40px] flex items-center justify-center"
      >
        <img class="ml-1" :src="rightArrow" alt="" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import leftArrow from "@/modules/personel/assets/leftArrowHeadlessWhite.svg";
import rightArrow from "@/modules/personel/assets/rightArrowHeadlessWhite.svg";
import { useAppointments } from "@/modules/personel/components/appointmentsStore";
import { useSearchAppointmentsStore } from "@/store/modules/operationsStore/appointment/vem-appointment/useSearchAppointmentsFull";
import { usePersonalStore } from "@/modules/personel/store/personalStore";

const appointmentsStore = useAppointments();
const searchAppointmentsStore = useSearchAppointmentsStore();
const personalStore = usePersonalStore();

const items = [];
const selectedDateIndex = ref(6);

const today = new Date();
const dayMilliseconds = 24 * 60 * 60 * 1000;

for (let i = -6; i <= 7; i++) {
  const date = new Date(today.getTime() + i * dayMilliseconds);
  const dayOfWeek = ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"][
    date.getDay()
  ];
  const item = {
    fullDate: date,
    date: date.getDate(),
    day: dayOfWeek,
  };
  items.push(item);
}

const isActive = (index) => index === selectedDateIndex.value;

const selectDate = async (index) => {
  selectedDateIndex.value = index;
  const date = new Date(items[selectedDateIndex.value].fullDate);
  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  console.log("aktif", formattedDate);
  appointmentsStore.activeDate = formattedDate;
  appointmentsStore.appointmentsPage = 1;
  appointmentsStore.appointments.appointments.data = [];
  const fetchAppointmentsReq = {
    doctor_id: "",
    doctor_name: "",
    doctor_surname: "",
    unit_id: personalStore.personalTableBirimFilter,
    tc_identity_number: "",
    patient_name: "",
    patient_surname: "",
    phone_number: "",
    start_date: appointmentsStore.activeDate,
    end_date: new Date(
      new Date(appointmentsStore.activeDate).getTime() + dayMilliseconds
    )
      .toISOString()
      .split("T")[0],
    provision_identifier: appointmentsStore.isProvisionedAppointments
        ? "true"
        : "false",
    page_id: 1,
    page_size: 6,
  };
  await useSearchAppointmentsStore().searchAppointments(fetchAppointmentsReq);
};

const moveDate = async (direction) => {
  if (direction === "back") {
    if (selectedDateIndex.value > 0) {
      selectedDateIndex.value--;

      const date = new Date(items[selectedDateIndex.value].fullDate);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log("aktif", formattedDate);
      appointmentsStore.activeDate = formattedDate;
      appointmentsStore.appointmentsPage = 1;
    }
  } else {
    if (selectedDateIndex.value < items.length - 1) {
      selectedDateIndex.value++;
      const date = new Date(items[selectedDateIndex.value].fullDate);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log("aktif", formattedDate);
      appointmentsStore.activeDate = formattedDate;
      appointmentsStore.appointmentsPage = 1;
    }
  }
  const fetchAppointmentsReq = {
    doctor_id: "",
    doctor_name: "",
    doctor_surname: "",
    unit_id: personalStore.personalTableBirimFilter,
    tc_identity_number: "",
    patient_name: "",
    patient_surname: "",
    phone_number: "",
    start_date: appointmentsStore.activeDate,
    end_date: new Date(
      new Date(appointmentsStore.activeDate).getTime() + dayMilliseconds
    )
      .toISOString()
      .split("T")[0],

    provision_identifier: appointmentsStore.isProvisionedAppointments
        ? "true"
        : "false",
    page_id: 1,
    page_size: 6,
  };
  await useSearchAppointmentsStore().searchAppointments(fetchAppointmentsReq);
  if (selectedDateIndex.value > 6) {
    for (let i = 0; i < selectedDateIndex.value - 6; i++) {
      const shifted = items.shift();
      items.push({
        fullDate: new Date(items[6].fullDate.getTime() + 7 * dayMilliseconds),
        date: new Date(
          items[6].fullDate.getTime() + 7 * dayMilliseconds
        ).getDate(),
        day: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"][
          new Date(items[6].fullDate.getTime() + 7 * dayMilliseconds).getDay()
        ],
      });
    }
    selectedDateIndex.value = 6;
  } else {
    for (let i = 0; i < 6 - selectedDateIndex.value; i++) {
      const popped = items.pop();
      items.unshift({
        fullDate: new Date(items[6].fullDate.getTime() - 7 * dayMilliseconds),
        date: new Date(
          items[6].fullDate.getTime() - 7 * dayMilliseconds
        ).getDate(),
        day: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"][
          new Date(items[6].fullDate.getTime() - 7 * dayMilliseconds).getDay()
        ],
      });
    }
    selectedDateIndex.value = 6;
  }
};
</script>
