<template>
  <div>
    <div class="flex items-center gap-6">
      <h4
        class="w-fit text-lg border-b-2 border-[#277DD2] text-[#09244B] font-medium pb-1"
      >
        Provizyon İşlemleri
      </h4>
      <!-- <button @click="fillProvisionFields" class="px-3 py-2 border rounded">
        Otomatik Doldur
      </button> -->
    </div>
    <div class="py-4">
      <div class="flex items-center gap-8">
        <!-- provision type -->
        <div class="flex items-center justify-between w-[370px]">
          <p>Provizyon Tipi</p>

          <Combobox
            v-model="medulaStore.medulaProvizyonButtonVModel.provizyonTipi"
            :disabled="isDisabled('provizyon_tipi')"
          >
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(provisionType) => provisionType.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredProvisionTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="provisionType in filteredProvisionTypes"
                    as="template"
                    :key="provisionType.id"
                    :value="provisionType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ provisionType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
        <!-- exceptional -->
        <div class="flex items-center justify-between w-[370px]">
          <p>İstisnai Hal</p>

          <Combobox
            v-model="medulaStore.medulaProvizyonButtonVModel.istisnaiHal"
            :disabled="isDisabled('istisnai_hal')"
          >
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="
                    (exceptionalType) =>
                      exceptionalType.text === 'İstisnai hal yok'
                        ? ''
                        : exceptionalType.text
                  "
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredExceptionalTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="exceptionalType in filteredExceptionalTypes"
                    as="template"
                    :key="exceptionalType.id"
                    :value="exceptionalType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ exceptionalType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
        <!-- treatment kind -->
        <div class="flex items-center justify-between w-[370px]">
          <p>Tedavi Türü</p>
          <Combobox
            v-model="medulaStore.medulaProvizyonButtonVModel.tedaviTuru"
            :disabled="isDisabled('tedavi_turu')"
          >
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(treatmentKindType) => treatmentKindType.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="
                      filteredTreatmentKindTypes.length === 0 && query !== ''
                    "
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="treatmentKindType in filteredTreatmentKindTypes"
                    as="template"
                    :key="treatmentKindType.id"
                    :value="treatmentKindType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ treatmentKindType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
      </div>
      <div class="flex items-center gap-8 mt-[20px]">
        <!-- follow type -->
        <div class="flex items-center justify-between w-[370px]">
          <p>Takip Tipi</p>
          <Combobox
            v-model="medulaStore.medulaProvizyonButtonVModel.takipTipi"
            :disabled="isDisabled('takip_tipi')"
          >
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(followType) => followType.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredFollowTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="followType in filteredFollowTypes"
                    as="template"
                    :key="followType.id"
                    :value="followType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ followType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
        <!-- treatment type -->
        <div class="flex items-center justify-between w-[370px]">
          <p>Tedavi Tipi</p>

          <Combobox
            v-model="medulaStore.medulaProvizyonButtonVModel.tedaviTipi"
            :disabled="isDisabled('tedavi_tipi')"
          >
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(treatmentType) => treatmentType.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredTreatmentTypes.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="treatmentType in filteredTreatmentTypes"
                    as="template"
                    :key="treatmentType.id"
                    :value="treatmentType"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ treatmentType.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
        <div
          v-if="
            medulaStore.medulaProvizyonButtonVModel.provizyonTipi.id === 'T'
          "
          class="flex items-center justify-between w-[370px]"
        >
          <p>Plaka No</p>

          <input
            type="text"
            class="w-[250px] h-[28px] p-1 rounded focus:outline-double focus:outline-1 border-[1px] border-[#AAAAAA] bg-white text-[#444444] focus:outline-[#277dd2] bg-transparent"
            v-model="medulaStore.medulaProvizyonButtonVModel.plakaNo"
          />
        </div>
      </div>
      <!-- adlivaka -->
      <div
        v-if="
          medulaStore.medulaProvizyonButtonVModel.provizyonTipi.id === 'V' ||
          medulaStore.medulaProvizyonButtonVModel.provizyonTipi.id === 'I' ||
          medulaStore.medulaProvizyonButtonVModel.provizyonTipi.id === 'T'
        "
        class="flex items-center gap-8 mt-[20px]"
      >
        <div class="flex items-center justify-between w-[370px]">
          <p>Vaka İl</p>

          <Combobox v-model="medulaStore.medulaProvizyonButtonVModel.vakaIl">
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(province) => province.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredProvinces.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="province in filteredProvinces"
                    as="template"
                    :key="province.id"
                    :value="province"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ province.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
        <div class="flex items-center justify-between w-[370px]">
          <p>Vaka İlçe</p>
          <Combobox v-model="medulaStore.medulaProvizyonButtonVModel.vakaIlce">
            <div class="relative w-[250px]">
              <div
                class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
              >
                <ComboboxInput
                  class="w-full border py-1 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                  :displayValue="(district) => district.text"
                  @change="query = $event.target.value"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              </div>
              <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
              >
                <ComboboxOptions
                  class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                >
                  <div
                    v-if="filteredDistricts.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                  >
                    Sonuç bulunamadı
                  </div>

                  <ComboboxOption
                    v-for="district in filteredDistricts"
                    as="template"
                    :key="district.id"
                    :value="district"
                    v-slot="{ active }"
                  >
                    <li
                      class="relative cursor-default select-none my-2 py-2 pl-3"
                      :class="{
                        'bg-teal-600 text-white': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span class="block truncate">
                        {{ district.text }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </TransitionRoot>
            </div>
          </Combobox>
        </div>
        <div class="flex items-center justify-between w-[370px]">
          <p>Vaka Tarihi</p>
          <VueDatePicker
            locale="tr"
            auto-apply
            format="dd/MM/yyyy"
            :enable-time-picker="false"
            :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
            class="w-[250px] rounded"
            v-model="medulaStore.medulaProvizyonButtonVModel.vakaTarih"
          />
        </div>
      </div>
      <!-- <div v-if="false" class="flex items-center gap-8 mt-[20px]">
        <div class="flex items-center justify-between w-[370px]">
          <p>Takip Tipi</p>
          <p class="bg-red-300 w-[250px]">sadkkdas</p>
        </div>
      </div> -->
      <!-- adlivaka -->
    </div>
  </div>
</template>

<style>
.dp__pointer {
  height: 30px;
}

.dp__pointer:focus {
  outline: 1px solid #277dd2;
}

.select2-selection:focus {
  outline: 1px solid #277dd2;
}

.select2-search__field:focus {
  outline: 1px solid #277dd2;
  border-radius: 3px;
}
</style>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import moment from "moment";
import _ from "lodash";
import Select2 from "vue3-select2-component";
import VueDatePicker from "@vuepic/vue-datepicker";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
let query = ref("");
//store imports
import {
  optionTypes,
  exceptionalTypes,
  fallowypes,
  treatmentKindTypes,
  treatmentTypes,
} from "@/modules/medula/provision/components/dropDownTypes";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { userInfo } from "@/modules/medula/addPatient/components/userInfoReactiveTypes";
import { addPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/addPatientApplicationStore.ts";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";
import { useGetDistricts } from "@/store/modules/operationsStore/public/useGetDistricts";
import { useGetCities } from "@/store/modules/operationsStore/public/useGetCities";
import { useGetProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/getProvisionSettings";
import { useGetClinicalSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/clinicalSettings/getClinicalSettings";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";

//store inits
const applicationStore = addPatientApplicationStore();
const applicationState = applicationStore.addPatientApplicationRequest;
const applicationProvisionStore = useApplicationProvisionStore();
const provisionState = applicationProvisionStore.applicationProvisionRequest;
const medulaStore = useMedula();
const getDistrictsStore = useGetDistricts();
const getCitiesStore = useGetCities();
const getProvisionSettingsStore = useGetProvisionSettings();
const getClinicalSettingsStore = useGetClinicalSettings();
const staffListStore = useGetStaffList();
const unitListStore = useGetUnitsList();

onMounted(async () => {
  if (getCitiesStore.getListCitiesResponse) {
    return false;
  }
  await getCitiesStore.getCities();
});

watch(
  () => medulaStore.medulaProvizyonButtonVModel?.vakaIl,
  async (newValue, oldValue) => {
    medulaStore.ilceler = await getDistrictsStore.getDistricts(newValue.id);
  }
);

const fillProvisionFields = async () => {
  await fillProvisionInfos();
  await fillClinicalInfos();
};

onMounted(async () => {
  if (
    !getProvisionSettingsStore.getProvisionSettingsResponse ||
    getProvisionSettingsStore.isProvisionSettingsUpdated
  ) {
    await fillProvisionInfos();
    getProvisionSettingsStore.isProvisionSettingsUpdated = false;
  }
  if (
    !getClinicalSettingsStore.getClinicalSettingsResponse ||
    getClinicalSettingsStore.isClinicalSettingsUpdated
  ) {
    await fillClinicalInfos();
    console.log("eeeeeee");
    getClinicalSettingsStore.isClinicalSettingsUpdated = false;
  }
});

const fillProvisionInfos = async () => {
  await getProvisionSettingsStore.getProvisionSettings();
  if (!getProvisionSettingsStore.getProvisionSettingsResponse) return false;
  const provisionSettings = [
    {
      storeProperty: "provizyonTipi",
      options: optionTypes.value,
      responseProperty: "provizyon_tipi",
    },
    {
      storeProperty: "istisnaiHal",
      options: exceptionalTypes.value,
      responseProperty: "istisnai_hal",
    },
    {
      storeProperty: "tedaviTuru",
      options: treatmentKindTypes.value,
      responseProperty: "tedavi_turu",
    },
    {
      storeProperty: "takipTipi",
      options: fallowypes.value,
      responseProperty: "takip_tipi",
    },
    {
      storeProperty: "tedaviTipi",
      options: treatmentTypes.value,
      responseProperty: "tedavi_tipi",
    },
  ];

  provisionSettings.forEach((setting) => {
    const responseValue =
      getProvisionSettingsStore.getProvisionSettingsResponse[
        setting.responseProperty
      ];

    if (!responseValue || responseValue === "-" || responseValue === "") {
      return false;
    }
    medulaStore.medulaProvizyonButtonVModel[setting.storeProperty] =
      setting.options.find((option) => option.text === responseValue);
  });
};

const isDisabled = (setting) => {
  if (!getProvisionSettingsStore.getProvisionSettingsResponse) return false;

  return !(
    getProvisionSettingsStore.getProvisionSettingsResponse &&
    (getProvisionSettingsStore.getProvisionSettingsResponse[setting] === "" ||
      getProvisionSettingsStore.getProvisionSettingsResponse[setting] === "-")
  );
};

const fillClinicalInfos = async () => {
  await getClinicalSettingsStore.getClinicalSettings();
  if (!getClinicalSettingsStore.getClinicalSettingsResponse) return false;

  const clinicalSettings = [
    {
      storeProperty: "BirimAdı",
      options: unitListStore.getListUnitsResponse,
      responseProperty: "klinik_adi",
    },
    {
      storeProperty: "DoktorAdı",
      options: medulaStore.filteredDoctors,
      responseProperty: "doktor_adi",
    },
  ];

  clinicalSettings.forEach((setting) => {
    console.log("dskkad", setting);
    setTimeout(() => {
      const responseValue =
        getClinicalSettingsStore.getClinicalSettingsResponse[
          setting.responseProperty
        ];

      if (!responseValue || responseValue === "-" || responseValue === "") {
        return false;
      }
      medulaStore.medulaProvizyon[setting.storeProperty] = setting.options.find(
        (option) => option.text === responseValue
      );
    }, 0);
  });
};

// provizyonTipi

let filteredProvisionTypes = computed(() =>
  query.value === ""
    ? optionTypes.value
    : optionTypes.value.filter((provision) =>
        provision.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.provizyonTipi,
  (newValue) => {
    applicationState.provision_type = newValue.id;
  }
);

// istisnaiHal

let filteredExceptionalTypes = computed(() =>
  query.value === ""
    ? exceptionalTypes.value
    : exceptionalTypes.value.filter((exceptional) =>
        exceptional.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.istisnaiHal,
  (newValue) => {
    applicationState.Istisnaihal = newValue.id;
  }
);

// tedaviTuru

let filteredTreatmentKindTypes = computed(() =>
  query.value === ""
    ? treatmentKindTypes.value
    : treatmentKindTypes.value.filter((treatmentKind) =>
        treatmentKind.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.tedaviTuru,
  (newValue) => {
    applicationState.treatment_type = newValue.knidosID;
    applicationState.tedavi_turu_medula = newValue.id;
  }
);

// takipTipi

let filteredTreatmentTypes = computed(() =>
  query.value === ""
    ? treatmentTypes.value
    : treatmentTypes.value.filter((treatmentType) =>
        treatmentType.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.takipTipi,
  (newValue) => {
    applicationState.follow_type = newValue.id;
  }
);

// tedaviTipi

let filteredFollowTypes = computed(() =>
  query.value === ""
    ? fallowypes.value
    : fallowypes.value.filter((followType) =>
        followType.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.tedaviTipi,
  (newValue) => {
    applicationState.tedavi_tipi = newValue.id;
  }
);

// iller

let filteredProvinces = computed(() =>
  query.value === ""
    ? getCitiesStore.getListCitiesResponse
    : getCitiesStore.getListCitiesResponse.filter((province) =>
        province.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.vakaIl,
  (newValue) => {
    applicationState.vaka_il = newValue.plaka_kodu;
  }
);

// iller

let filteredDistricts = computed(() =>
  query.value === ""
    ? medulaStore.ilceler
    : medulaStore.ilceler.filter((district) =>
        district.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.vakaIlce,
  (newValue) => {
    applicationState.vaka_ilce = newValue.ilce_kodu;
  }
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.vakaTarih,
  (newValue) => {
    applicationState.vaka_tarihi = moment(newValue).utc().format("DD.MM.YYYY");
  }
);

watch(
  () => medulaStore.medulaProvizyonButtonVModel.plakaNo,
  (newValue) => {
    applicationState.plaka_no = newValue;
  }
);

function handleVakaIlSelect(option) {
  console.log(option);
  // applicationState.Vakail.String = option.id;
  // medulaStore.medulaProvizyonButtonVModel.vakaIl.id = option.id;
  // medulaStore.medulaProvizyonButtonVModel.vakaIl.text = option.text;
}

function handleVakaIlceSelect(option) {
  console.log(option);
  // applicationState.Vakailce.String = option.id;
  // medulaStore.medulaProvizyonButtonVModel.vakaIlce.id = option.id;
  // medulaStore.medulaProvizyonButtonVModel.vakaIlce.text = option.text;
}
</script>
