<template>
  <div v-if="doctorCalendarStore().selectedDays.length > 0" class="h-full">
    <div>
      <div
        class="flex justify-between items-center px-3 py-1 bg-[#f3f6f9] rounded-md"
      >
        <h3 class="font-medium text-[#464E5F]">ÇALIŞMA ARALIĞI EKLE</h3>
        <button
          class="focus:outline-1 focus:outline-[#277dd2] rounded-full"
          @click="resetSelectedDay()"
        >
          <img :src="resetIcon" alt="" />
        </button>
      </div>
      <!-- disabled'a şunu ekleriz silme ve yeni saat ekleme butonları sonrası || selectedHours.length === 2 -->
      <button
        class="py-1 w-32 m-3 rounded-xl border-[1px] border-black text-lg focus:outline-1 focus:outline-[#277dd2]"
        :class="{
          selected: isSelected(hour),
          inRange: isInRange(hour),
          disabled:
            isDisabledStyle(hour) ||
            (!isSelected(hour) &&
              !isInRange(hour) &&
              selectedHours.length === 2),
        }"
        :disabled="
          isDisabledStyle(hour) ||
          isInRange(hour) ||
          (isSelected(hour) && hour !== selectedHours[0]) ||
          selectedHours.length === 2
        "
        @click="toggleSelection(hour)"
        v-for="(hour, index) in hours"
        :key="index"
      >
        {{ hour }}:00
      </button>
    </div>

    <div>
      <div class="flex flex-col">
        <div
          class="flex items-center"
          v-for="(workingHour, index) in doctorCalendarStore().selectedDays[
            doctorCalendarStore().activeIndex
          ].workingHours"
          :key="index"
        >
          <div
            v-if="workingHour.start && workingHour.end"
            class="flex items-center"
          >
            <div
              class="py-1 w-32 m-3 rounded-xl border-[1px] border-black text-lg flex items-center justify-center text-[#277DD2] bg-[#E1EDFF]"
            >
              {{ workingHour.start }}
              <span>.</span>
              <select
                v-model="
                  doctorCalendarStore().selectedDays[
                    doctorCalendarStore().activeIndex
                  ].workingMinutes[index].start
                "
                class="h-fit bg-white focus:outline-1 focus:outline-[#277dd2]"
              >
                <option value="00">00</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select>
            </div>
            <div class="h-1 w-8 bg-gray-400"></div>
            <div
              class="py-1 w-32 m-3 rounded-xl border-[1px] border-black text-lg flex items-center justify-center text-[#277DD2] bg-[#E1EDFF]"
            >
              {{ workingHour.end }}
              <span>.</span>
              <select
                class="h-fit bg-white focus:outline-1 focus:outline-[#277dd2]"
                v-model="
                  doctorCalendarStore().selectedDays[
                    doctorCalendarStore().activeIndex
                  ].workingMinutes[index].end
                "
              >
                <option value="00">00</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select>
            </div>
            <button
              @click="cancelHours(index)"
              class="bg-[#fed9da] text-[#F94144] py-2 px-4 rounded-[30px] ml-4 mr-3"
            >
              Sil
            </button>
            <button
              v-if="
                index ===
                doctorCalendarStore().selectedDays[
                  doctorCalendarStore().activeIndex
                ].workingHours.length -
                  1
              "
              @click="selectedHours = []"
              class="bg-[#eff8ef] text-[#5CB85C] py-2 px-4 rounded-[30px]"
            >
              Yeni saat ekle
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-1 mb-4">
      <div class="px-3 py-1 bg-[#f3f6f9] rounded-md">
        <h3 class="font-medium text-[#464E5F]">MUAYENE SÜRESİ</h3>
      </div>
      <div>
        <select
          v-model="
            doctorCalendarStore().selectedDays[
              doctorCalendarStore().activeIndex
            ].examinationDuration
          "
          class="py-2 px-4 focus:outline-1 focus:outline-[#277dd2] bg-white border rounded text-gray-500 ml-2"
        >
          <option value="" disabled selected>Muayene Süresi</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="35">35</option>
        </select>
      </div>
    </div>
  </div>
  <div v-else><p>seçim yapabileceğiniz bir tarih seçmediniz</p></div>
</template>

<script setup>
import { activeIndex, selectedDTypes } from "./selectedDTypes.ts";
import dots from "../assets/menu-dots-vertical.png";
import addIcon from "../assets/addIcon.svg";
import resetIcon from "../assets/resetIcon.svg";
import { useAddDoctorSetting } from "@/store/modules/operationsStore/doctorSettings/useAddDoctorSetting";
const addDoctorSettingStore = useAddDoctorSetting();
import { ref, onMounted, watch } from "vue";
import { doctorCalendarStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorCalendarStore";

const props = defineProps({
  index: Number,
});

const selectedHours = ref([]);

const hours = ref(["09", "10", "11", "12", "13", "14", "15", "16"]);

const resetSelectedDay = () => {
  doctorCalendarStore().selectedDays[
    doctorCalendarStore().activeIndex
  ].workingHours = [];
  doctorCalendarStore().selectedDays[
    doctorCalendarStore().activeIndex
  ].workingMinutes = [];
  doctorCalendarStore().selectedDays[
    doctorCalendarStore().activeIndex
  ].examinationDuration = null;
  doctorCalendarStore().selectedDays[
    doctorCalendarStore().activeIndex
  ].overbookAllowed = false;
  doctorCalendarStore().selectedDays[
    doctorCalendarStore().activeIndex
  ].overbookLimit = null;
  selectedHours.value = [];
};

const toggleSelection = (hour) => {
  console.log(hour);
  if (selectedHours.value[0] === hour) {
    selectedHours.value = [];
    return true;
  }

  if (selectedHours.value.length === 2) {
    selectedHours.value = [];
    selectedHours.value.push(hour);
    console.log("a", selectedHours.value);
  } else {
    selectedHours.value.push(hour);
    console.log("b", selectedHours.value);
  }
};

watch(
  () => selectedHours.value.length,
  async (newValue, oldValue) => {
    console.log("sa", newValue);
    if (newValue === 2) {
      doctorCalendarStore().selectedDays[props.index].workingHours.push({
        start: selectedHours.value[0],
        end: selectedHours.value[1],
      });
      doctorCalendarStore().selectedDays[props.index].workingMinutes.push({
        start: "00",
        end: "00",
      });
    }
  }
);

const isSelected = (hour) => {
  const workingHours =
    doctorCalendarStore().selectedDays[doctorCalendarStore().activeIndex]
      .workingHours;
  for (const hours of workingHours) {
    if (hours.start == hour || hours.end == hour) {
      return true;
    }
  }

  return selectedHours.value.includes(hour);
};

const isInRange = (hour) => {
  const workingHours =
    doctorCalendarStore().selectedDays[doctorCalendarStore().activeIndex]
      .workingHours;
  for (const hours of workingHours) {
    const start = parseInt(hours.start);
    const end = parseInt(hours.end);
    const currentHour = parseInt(hour);

    if (currentHour > start && currentHour < end) {
      return true;
    }
  }

  return false;
};
const isDisabledStyle = (hour) => {
  const activeDay =
    doctorCalendarStore().selectedDays[doctorCalendarStore().activeIndex];
  const workingHours = activeDay.workingHours;

  // Eğer saat isSelected veya isInRange tarafından seçildiyse false döndür
  if (isSelected(hour) || isInRange(hour)) {
    return false;
  }

  if (parseInt(hour) < parseInt(selectedHours.value[0])) {
    return true;
  }

  // Saat, herhangi bir workingHours'un end değerinden küçükse true döndür
  for (const hours of workingHours) {
    const endHour = parseInt(hours.end);
    const currentHour = parseInt(hour);
    console.log("sa");
    if (currentHour < endHour) {
      return true;
    }
  }

  return false;
};

const cancelHours = (index) => {
  console.log(
    doctorCalendarStore().selectedDays[doctorCalendarStore().activeIndex]
      .workingHours
  );
  selectedHours.value = [];
  doctorCalendarStore().selectedDays[
    doctorCalendarStore().activeIndex
  ].workingHours.splice(index, 1);
  doctorCalendarStore().selectedDays[
    doctorCalendarStore().activeIndex
  ].workingMinutes.splice(index, 1);
};
</script>

<style scoped>
.selected {
  background-color: #287dd2;
  color: white;
}
.inRange {
  background-color: #eaf2fb;
  color: black;
}

.disabled {
  background-color: grey;
  color: black;
}
</style>
