<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">SİPARİŞ ID</th>
        <th class="text-xl w-[200px] text-center">DURUM</th>
        <th class="text-xl w-[250px] text-center">HASTA ADI</th>
        <th class="text-xl w-[250px] text-center">TC</th>
        <th class="text-xl w-[350px] text-center">OLUŞTURULMA TARİHİ</th>
        <th class="text-xl w-[200px] text-center">TESLİM TARİHİ</th>
        <th class="text-xl w-[200px] text-center">HEKİM ADI</th>
        <th class="text-xl w-[200px] text-center">KURUM ADI</th>
        <!-- v-if="!popUpManagement.detailPopUp" -->
        <div class="my-auto w-12">
          <th></th>
        </div>
        <!-- <div v-if="popUpManagement.detailPopUp" class="my-auto">
          <th></th>
        </div> -->
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(order, index) in tempOrdersData.slice(
          (currentOrderPage - 1) * 7,
          currentOrderPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px] bg-white"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
            class="bg-white mt-1"
          />
        </td>
        <td class="w-[200px] text-center relative">
          {{ order.siparisID }}
          <small
            v-if="order.yorumlar.length > 0"
            class="absolute top-1 right-6 bg-[#0FA75E] text-white rounded-full w-5 h-5 flex items-center justify-center"
            >+{{ order.yorumlar.length }}</small
          >
        </td>
        <td class="w-[200px] text-center">
          <div class="py-1" :class="getColorOfStatus(order.durum)">
            {{ order.durum }}
          </div>
        </td>
        <td class="w-[250px] text-center">
          {{ order.hastaAdi }}
        </td>

        <td class="w-[250px] text-center">
          <p>
            {{ order.tcNo }}
          </p>
        </td>
        <td class="w-[350px] text-center">
          {{ order.olusturulmaTarihi }}
        </td>

        <td class="w-[200px] text-center">
          {{ order.teslimTarihi }}
        </td>
        <td class="w-[200px] text-center">
          {{ order.hekimAdi }}
        </td>
        <td class="w-[200px] text-center">
          {{ order.kurumAdi }}
        </td>
        <div>
          <td>
            <div
              class="cursor-pointer mt-2 w-fit px-2"
              @click="
                ordersPopUpManagement.detailPopUp = true;
                activeIndex = index;
              "
            >
              <img :src="moreDotsIcon" alt="moreDots" />
            </div>
          </td>
        </div>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from "vue";
import {
  tempData,
  tempOrdersData,
  popUpManagement,
  ordersPopUpManagement,
} from "@/modules/protez/store/temporaryProtezData.js";
import moreDotsIcon from "@/modules/protez/assets/moreDots.svg";
const activeIndex = ref(null);

defineProps(["currentOrderPage"]);

const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
  } else {
    activeIndex.value = index;
  }
};
const getColorOfStatus = (status) => {
  const statusLower = status.toLowerCase();
  console.log(statusLower);
  switch (statusLower) {
    case "onaylanan":
      return "bg-[#eaf2fb] text-[#277DD2]";

    case "işleme alınmamış":
      return "bg-[#f1eeeb] text-[#6f4e37]";

    case "üretim":
      return "bg-[#e6f2f3] text-[#0f787f]";
  }
};
</script>
