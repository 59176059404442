<template>
  <div class="flex flex-col">
    <div class="h-full flex flex-col">
      <landingPageNavbar />
      <div v-if="isUserLoggedOut" class="logoutMessageBox">
        {{ message }}
        <!-- <div>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: progressBarWidth }"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div> -->
      </div>
      <landingPageHome />
    </div>
    <div class="">
      <div class="flex">
        <landingPageFooter />
      </div>
    </div>
  </div>
</template>

<script setup>
import landingPageNavbar from "../components/landingPageNavbar.vue";
import landingPageHome from "../components/landingPageHome.vue";
import landingPageFooter from "../components/landingPageFooter.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, watch, onMounted } from "vue";
import { globalStore } from "../../../../store/modules/globalStore.ts";

const message = ref("");
const duration = ref(0);
const showProgressBar = ref(false);
const progressBarWidth = ref("100%");
const route = useRoute();
const router = useRouter();
const isUserLoggedOut = ref(globalStore.isUserLoggedOut);

watch(
  () => isUserLoggedOut,
  (newVal) => {
    if (newVal.value === true) {
      message.value = "Çıkış başarılı";
      duration.value = 2000;
      showProgressBar.value = true;
      progressBarWidth.value = "100%";
      const decrement = 100 / (duration.value / 500);
      const intervalId = setInterval(() => {
        progressBarWidth.value = `${
          parseFloat(progressBarWidth.value) - decrement
        }%`;
      }, 500);

      setTimeout(() => {
        clearInterval(intervalId);
        message.value = "";
        isUserLoggedOut.value = false;
        progressBarWidth.value = "100%";
      }, duration.value);
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.progress {
  width: 118px;
  background-color: rgba(155, 155, 255, 0.36);
  transition: width 10s linear;
  display: inline-block;
  vertical-align: middle;
}
.logoutMessageBox {
  margin-top: 95px;
  width: 165px;
  height: 70px;
  display: flex;
  right: 15px;
  flex-direction: column;
  position: absolute;
  border-radius: 12px;
  border: 1px solid hsl(211, 81%, 88%);
  align-items: center;
  justify-content: center;
}
.progress-bar {
  height: 5px;
  background-color: #007bff;
  width: 1%;
  position: relative;
  transition: linear;
}
</style>
