<!-- eslint-disable vue/no-export-in-script-setup -->
<template>
  <div class="flex flex-col p-12 lg:flex-row gap-28">
    <!-- kisisel bilgiler section -->

    <div class="min-w-[500px]">
      <div class="w-full border-b-2">
        <h3
          class="flex items-center justify-center bg-[#F3F6F9] text-[#464E5F] w-[260px] h-[40px] font-medium text-lg rounded"
        >
          Kişisel Bilgiler
        </h3>
      </div>
      <div
        class="w-full flex justify-between items-center my-4"
        :class="{
          hidden: bilesikKisiStore.patientNationality !== 'Yabancı Uyruklu',
        }"
      >
        <label class="my-auto" for="passport">Pasaport No</label>
        <input id="passport" type="text" v-model="userInfo.passaportNo" />
      </div>
      <div
        class="w-full flex justify-between items-center mt-8"
        :class="{
          hidden: bilesikKisiStore.patientNationality !== 'Yabancı Uyruklu',
        }"
      >
        <label class="my-auto" for="yupass">Yupass No</label>
        <input id="yupass" type="text" v-model="userInfo.yupassNo" />
      </div>
      <div
        v-if="
          bilesikKisiStore.patientNationality === 'Yabancı Uyruklu' ||
          bilesikKisiStore.patientNationality === 'Mavi Kartlı Hasta'
        "
        class="w-full flex justify-between items-center mt-8"
      >
        <label class="my-auto">Uyruk</label>
        <Select2
          class="min-w-[285px]"
          :options="getCountriesStore.getListCountriesResponse"
          @select="setKpsYabanciUyrukluButtonDropDownSelectedValue"
          placeholder=""
          v-model="userInfo.kpsYabanciUyrukluButtonDropDownSelectedValue"
        />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto" for="tcno"
          >TC Kimlik No
          <span
            :class="{
              'text-rose-600': userInfo.tcNo.length !== 11,
              hidden: userInfo.tcNo.length === 11,
            }"
            class="text-xl"
            >*</span
          >
        </label>
        <input id="tcno" type="text" v-model="userInfo.tcNo" />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto" for="name"
          >Ad
          <span
            :class="{
              'text-rose-600': !userInfo.userName,
              hidden: userInfo.userName,
            }"
            class="text-xl"
            >*</span
          ></label
        >
        <input id="name" type="text" v-model="userInfo.userName" />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto" for="surname"
          >Soyad
          <span
            :class="{
              'text-rose-600': !userInfo.userSurname,
              hidden: userInfo.userSurname,
            }"
            class="text-xl"
            >*</span
          ></label
        >
        <input id="surname" type="text" v-model="userInfo.userSurname" />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto" for="momname">Anne Adı</label>
        <input id="momname" type="text" v-model="userInfo.nameOfMother" />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto" for="dadname">Baba Adı</label>
        <input id="dadname" type="text" v-model="userInfo.nameOfFather" />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto"
          >Doğum Tarihi
          <span
            :class="{
              'text-rose-600': !userInfo.dateOfBirth,
              hidden: userInfo.dateOfBirth,
            }"
            class="text-xl"
            >*</span
          ></label
        >
        <VueDatePicker
          v-model="userInfo.dateOfBirth"
          locale="tr"
          auto-apply
          format="dd/MM/yyyy"
          :enable-time-picker="false"
          :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
          class="w-[285px]"
        />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto">Doğum Yeri İl</label>
        <Select2
          class="w-[285px]"
          :options="getCitiesStore.getListCitiesResponse"
          @select="setIlButtonDropDownSelectedValue"
          placeholder=""
          v-model="userInfo.ilButtonDropDownSelectedValue"
        />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto">Doğum Yeri İlçe</label>
        <Select2
          class="w-[285px]"
          :options="userInfo.ilceButtonDropDownValues"
          @select="setIlceButtonDropDownSelectedValue"
          placeholder=""
          v-model="userInfo.ilceButtonDropDownSelectedValue"
        />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto"
          >Cinsiyet
          <span
            :class="{
              'text-rose-600': !userInfo.gender,
              hidden: userInfo.gender,
            }"
            class="text-xl"
            >*</span
          ></label
        >
        <div class="flex w-[285px] gap-8">
          <div class="flex items-center w-20 justify-between">
            <label class="my-auto text-[#7F7F7F] cursor-pointer" for="female"
              >Kadın</label
            >
            <input
              type="checkbox"
              value="Kadın"
              :checked="userInfo.gender === 'Kadın'"
              @change="handleGenderCheckboxChange(1)"
              id="female"
              class="h-6 w-6 rounded cursor-pointer"
            />
          </div>
          <div class="flex items-center w-20 justify-between">
            <label class="my-auto text-[#7F7F7F] cursor-pointer" for="male"
              >Erkek</label
            >
            <input
              type="checkbox"
              value="Erkek"
              :checked="userInfo.gender === 'Erkek'"
              @change="handleGenderCheckboxChange(2)"
              id="male"
              class="h-6 w-6 rounded cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto">Medeni Hali</label>
        <div class="flex w-[285px] gap-8">
          <div class="flex items-center w-20 justify-between">
            <label class="my-auto text-[#7F7F7F] cursor-pointer" for="married"
              >Evli</label
            >
            <input
              type="checkbox"
              value="Evli"
              :checked="userInfo.maritalStatus === 'Evli'"
              @change="handleMarryingCheckboxChange(1)"
              id="married"
              class="h-6 w-6 rounded cursor-pointer"
            />
          </div>
          <div class="flex items-center w-20 justify-between">
            <label class="my-auto text-[#7F7F7F] cursor-pointer" for="single"
              >Bekar</label
            >
            <input
              type="checkbox"
              value="Bekâr"
              :checked="userInfo.maritalStatus === 'Bekâr'"
              @change="handleMarryingCheckboxChange(2)"
              id="single"
              class="h-6 w-6 rounded cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- iletisim bilgileri section -->

    <div class="min-w-[500px]">
      <div class="w-full border-b-2">
        <h3
          class="flex items-center justify-center bg-[#F3F6F9] text-[#464E5F] w-[260px] h-[40px] font-medium text-lg rounded"
        >
          İletişim Bilgileri
        </h3>
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto">İl</label>
        <Select2
          class="w-[285px]"
          :options="getCitiesStore.getListCitiesResponse"
          @select="setadresIlButtonDropDownSelectedValue"
          placeholder=""
          v-model="userInfo.adresIlButtonDropDownSelectedValue"
        />
      </div>
      <div class="w-full flex justify-between items-center mt-8">
        <label class="my-auto">İlçe</label>
        <Select2
          class="w-[285px]"
          :options="userInfo.adresIlceButtonDropDownValues"
          @select="setadresIlceButtonDropDownSelectedValue"
          placeholder=""
          v-model="userInfo.adresIlceButtonDropDownSelectedValue"
        />
      </div>
      <div class="w-full flex justify-between mt-8">
        <label for="address"
          >Adres Açıklama
          <span
            :class="{
              'text-rose-600': !userInfo.adress,
              hidden: userInfo.adress,
            }"
            class="text-xl"
            >*</span
          ></label
        >
        <textarea
          id="address"
          cols="31"
          rows="5"
          class="resize-none p-2 rounded border-2 focus:outline-[#4a72cd]"
          v-model="userInfo.adress"
        ></textarea>
      </div>
      <div class="w-full flex justify-between items-center my-4">
        <label class="my-auto" for="phonenumber"
          >Cep Telefonu
          <span
            :class="{
              'text-rose-600': !userInfo.phoneNumber,
              hidden: userInfo.phoneNumber,
            }"
            class="text-xl"
            >*</span
          ></label
        >
        <input
          placeholder="5559995959"
          id="phonenumber"
          type="text"
          v-model="userInfo.phoneNumber"
        />
      </div>
      <div class="w-full flex justify-between items-center my-4">
        <label class="my-auto" for="homenumber">Ev Telefonu</label>
        <input id="homenumber" type="text" v-model="userInfo.homeNumber" />
      </div>
      <div class="w-full flex justify-between items-center my-4">
        <label class="my-auto" for="jobnumber">İş Telefonu</label>
        <input id="jobnumber" type="text" v-model="userInfo.workNumber" />
      </div>
      <div class="w-full flex justify-between items-center my-4">
        <label class="my-auto" for="email">E-mail</label>
        <input id="email" type="text" v-model="userInfo.email" />
      </div>
    </div>

    <div class="mx-auto lg:mx-0 lg:absolute right-10 bottom-5">
      <button
        @click="
          bilesikKisiStore.patientNationality = 'T.C Vatandaşı';
          clearForm();
        "
        class="bg-[#D9D9D9] text-black w-24 h-10 rounded cursor-pointer focus:outline-none"
      >
        Vazgeç
      </button>
      <button
        @click="sendRequest()"
        class="bg-[#287DD2] text-white w-24 h-10 rounded focus:outline-none m-6"
        :class="
          isButtonDisabled()
            ? 'opacity-50 cursor-not-allowed'
            : 'cursor-pointer'
        "
        :disabled="isButtonDisabled()"
      >
        Kaydet
      </button>
    </div>
  </div>
</template>

<script setup>
import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
const bilesikKisiStore = bilesikKisiActions();
import dropDownArrow from "@/modules/asistanEkrani/registeredPatientList/assets/dropDownArrow.svg";

import { userInfo } from "./userInfoReactiveTypes.ts";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Select2 from "vue3-select2-component";
import { useAddPatientStore } from "@/store/modules/operationsStore/patient/useAddPatientStore.ts";
import { ref, reactive, onMounted, watch } from "vue";

import { useGetDistricts } from "@/store/modules/operationsStore/public/useGetDistricts";
import { useGetCities } from "@/store/modules/operationsStore/public/useGetCities";

import _ from "lodash";
import { useGetCountries } from "@/store/modules/operationsStore/public/useGetCountries";

const getDistrictsStore = useGetDistricts();
const getCitiesStore = useGetCities();
const getCountriesStore = useGetCountries();

const store = useAddPatientStore();
const patient = store.addPatientRequest;
// const patient = {};
// const maritalStatusRef = ref(1);
const defaultInfos = { ...userInfo };

//Gender Checkboxes

// const maleCheckbox = ref(false);
// const femaleCheckbox = ref(false);

onMounted(async () => {
  if (getCitiesStore.getListCitiesResponse) {
    return false;
  }
  await getCitiesStore.getCities();
});

onMounted(async () => {
  if (getCountriesStore.getListCountriesResponse) {
    return false;
  }
  await getCountriesStore.getCountries();
});

watch(
  () => userInfo.adresIlButtonDropDownSelectedValue,
  async (newValue, oldValue) => {
    userInfo.adresIlceButtonDropDownValues =
      await getDistrictsStore.getDistricts(
        userInfo.adresIlButtonDropDownSelectedValue
      );
    console.log("adresilce", userInfo.adresIlceButtonDropDownValues);
  }
);

watch(
  () => userInfo.ilButtonDropDownSelectedValue,
  async (newValue, oldValue) => {
    userInfo.ilceButtonDropDownValues = await getDistrictsStore.getDistricts(
      userInfo.ilButtonDropDownSelectedValue
    );
    console.log("ilce", userInfo.ilceButtonDropDownValues);
  }
);

const handleGenderCheckboxChange = (checkboxNumber) => {
  if (checkboxNumber === 1) {
    // maleCheckbox.value = false;
    if (userInfo.gender === "Kadın") {
      setGenderRef("");
      return;
    }
    setGenderRef("Kadın");
  } else if (checkboxNumber === 2) {
    // femaleCheckbox.value = false;
    if (userInfo.gender === "Erkek") {
      setGenderRef("");
      return;
    }
    setGenderRef("Erkek");
  }
};

//Marrying Checkboxes

const handleMarryingCheckboxChange = (checkboxNumber) => {
  if (checkboxNumber === 1) {
    if (userInfo.maritalStatus !== "Evli") {
      setMaritalStatusRef("Evli");
    } else {
      console.log("sa");
      setMaritalStatusRef("");
    }
  } else if (checkboxNumber === 2) {
    setMaritalStatusRef("Bekâr");
  } else {
    setMaritalStatusRef("");
  }
};

const sendRequest = () => {
  if (
    userInfo.tcNo &&
    userInfo.userName &&
    userInfo.userSurname &&
    userInfo.dateOfBirth
  ) {
    const dateObj = new Date(userInfo.dateOfBirth);
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const hours = dateObj.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");

    const outputDate = `${dateObj.getUTCFullYear()}-${month}-${day}T${hours}:${minutes}:00Z`;
    console.log(outputDate);

    if (bilesikKisiStore.patientNationality === "Yabancı Uyruklu") {
      patient.passport_number = userInfo.passaportNo;
      patient.yupass_number = userInfo.yupassNo;
    }
    // if (
    //   bilesikKisiStore.patientNationality === "Yabancı Uyruklu" ||
    //   bilesikKisiStore.patientNationality === "Mavi Kartlı Hasta"
    // ) {
    //   patient.nationality =
    //     userInfo.kpsYabanciUyrukluButtonDropDownSelectedValue || "Türkiye";
    // }

    patient.tc_identity_number = userInfo.tcNo;
    patient.name = userInfo.userName;
    patient.surname = userInfo.userSurname;
    patient.mother_name = userInfo.nameOfMother;
    patient.father_name = userInfo.nameOfFather;
    patient.dob = outputDate;
    patient.declared_dob = outputDate;
    patient.birthplace_province = userInfo.ilButtonDropDownSelectedValue;
    patient.birthplace_district = userInfo.ilceButtonDropDownSelectedValue;
    // patient.KayitZamani = outputDate;
    // patient.GuncellemeZamani.Time = outputDate;
    patient.gender = userInfo.gender === "Erkek" ? "erkek" : "kadın";
    patient.marital_status =
      userInfo.maritalStatus === "Bekâr"
        ? "bekar"
        : userInfo.maritalStatus === "Evli"
        ? "evli (resmen evli ya da birlikte yaşıyor)"
        : "";
    patient.province = userInfo.adresIlButtonDropDownSelectedValue;
    patient.district = userInfo.adresIlceButtonDropDownSelectedValue;

    patient.address_description = userInfo.adress;
    patient.phone_number = userInfo.phoneNumber;
    patient.home_phone_number = userInfo.homeNumber;
    patient.work_phone_number = userInfo.workNumber;
    patient.email = userInfo.email;
    // patient.SosyalGuvenceDurumu.String = userInfo.sdgDropDownSelectedValue;
    // patient.SigortaliTuru = userInfo.sigortaTuruSelectedValue;
    // patient.MuayeneOncelikSirasi.String = userInfo.oncelikSirasiSelectedValue;
    // patient.HastaTipi =
    //   bilesikKisiStore.patientNationality === "T.C Vatandaşı"
    //     ? "1_VATANDAŞ_KAYIT"
    //     : "2_YABANCI_KAYIT";
    console.log("gonder", patient);
    store.addPatient(patient);
  } else {
    console.log(
      "iii",
      userInfo.tcNo,
      userInfo.name,
      userInfo.surname,
      userInfo.phoneNumber
    );
    window.alert(
      "lütfen şu bilgileri eksiksiz giriniz:tc kimlik, ad-soyad , telefon numarası,doğum tarihi,cinsiyet,sigortalı türü"
    );
  }

  console.log("Patient", patient);
};

const clearForm = () => {
  for (const key in userInfo) {
    userInfo[key] = "";
  }
  //   maleCheckbox.value = false;
  // femaleCheckbox.value = false;
  setGenderRef("");
  // singleCheckbox.value = false;
  // marriedCheckbox.value = false;
};

const setKpsYabanciUyrukluButtonDropDownSelectedValue = (option) => {
  console.log(option);
  // userInfo.kpsYabanciUyrukluButtonDropDownSelectedValue = option.text;
  // userInfo.kpsYabanciUyrukluButtonDropDownSelectedValue = option.ReferansKodu;
};

const setIlButtonDropDownSelectedValue = (option) => {
  console.log(option);
  userInfo.ilButtonDropDownSelectedValue = option.text;
  // userInfo.ilButtonDropDownSelectedValue = option.ReferansKodu;
};
const setIlceButtonDropDownSelectedValue = (option) => {
  // userInfo.ilceButtonDropDownSelectedValue = option.ReferansKodu;
  console.log(option);
  userInfo.ilceButtonDropDownSelectedValue = option.id;
};
const setadresIlButtonDropDownSelectedValue = (option) => {
  // userInfo.adresIlButtonDropDownSelectedValue = option.ReferansKodu;
  console.log(option);
  userInfo.adresIlButtonDropDownSelectedValue = option.text;
};
const setadresIlceButtonDropDownSelectedValue = (option) => {
  // userInfo.adresIlceButtonDropDownSelectedValue = option.ReferansKodu;
  console.log(option);
  userInfo.adresIlceButtonDropDownSelectedValue = option.id;
};
const setSDGButtonDropDownSelectedValue = (option) => {
  userInfo.sdgDropDownSelectedValue = option;
  userInfo.sdgButtonDropDown = false;
};
const setSigortaTuruButtonDropDownSelectedValue = (option) => {
  userInfo.sigortaTuruSelectedValue = option;
  userInfo.sigortaTuruButtonDropDown = false;
};
const setOncelikSirasiButtonDropDownSelectedValue = (option) => {
  userInfo.oncelikSirasiSelectedValue = option;
  userInfo.oncelikSirasiButtonDropDown = false;
};
// Set Gender Ref
const setGenderRef = (option) => {
  userInfo.gender = option;
};

// Set Marital Status Ref
const setMaritalStatusRef = (option) => {
  userInfo.maritalStatus = option;
  console.log(userInfo.maritalStatus, "evlilik");
};

const isButtonDisabled = () => {
  return (
    userInfo.tcNo.length !== 11 ||
    !userInfo.userName ||
    !userInfo.userSurname ||
    !userInfo.dateOfBirth ||
    !userInfo.phoneNumber ||
    !userInfo.gender ||
    !userInfo.adress
  );
};
</script>

<style scoped>
input[type="text"] {
  padding: 3px;
  border: 1px solid #bababa;
  border-radius: 4px;
  resize: none;
  width: 285px;
}
input[type="text"]:focus,
button:focus,
input[type="checkbox"]:focus {
  outline: 1px solid #4a72cd;
}
</style>
