<template>
  <header
    class="bg-[#F8F9FD] sm:py-4 sm:px-5 flex items-center border-b-[0.5px] border-[#D9D9D9] w-full"
  >
    <h1 class="text-[#000000] font-medium text-base sm:text-base xl:text-2xl">
      Hoşgeldin {{ appointmentStore.patient?.attributes.name }}
      {{ appointmentStore.patient?.attributes.surname }}!
    </h1>

    <div class="flex items-center gap-6 ml-auto">
      <button
        class="block xl:hidden flex-shrink-0"
        @click="appointmentStore.sidebar = !appointmentStore.sidebar"
      >
        <img :src="hamburger" alt="menü" />
      </button>
      <button class="flex items-center gap-3">
        <img :src="disVector" alt="bildirim" />
        <span class="font-semibold hidden sm:block">Bildirimler</span>
      </button>
      <button @click="handleLogout" class="flex items-center gap-3">
        <img :src="CikisLogo" alt="bildirim" />
        <span class="font-semibold hidden sm:block">Çıkış</span>
      </button>
    </div>
  </header>
</template>

<script setup>
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions.ts";
const personelStoreAuthActions = personelAuthActions();
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { personelStore } from "@/store/modules/administratorManagementStore/personel.ts";
import dropDownArrow from "@/modules/asistanEkrani/registeredPatientList/assets/dropDownArrow.svg";
import leftArrow from "@/modules/personel/assets/leftArrow.svg";
import { useAppointmentStore } from "../../store/appointmentStore";

const appointmentStore = useAppointmentStore();
import disVector from "../assets/disVector.svg";
import hamburger from "../assets/hamburger.svg";

import orangeCircle from "../assets/orangeCircle.svg";
import CikisLogo from "../assets/CikisLogo.svg";
import { patientAuthActions } from "@/store/modules/administratorManagementStore/patientAuth/patientAuthActions.ts";

import { mapGetters } from "vuex";

import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";

onMounted(async () => {
  appointmentStore.patient = await useGetPatientStore().getPatientA(
    "08e5ad2a-d0aa-4c2d-9ccf-6f2813f1eaa7"
  );
});

const patientStoreAuthActions = patientAuthActions();
const numberOfNotifications = 2;
const handleLogout = () => {
  patientStoreAuthActions.logout();
};

const router = useRouter();
const arrowRef = ref(false);

const personelStoreData = personelStore();

//new patient logic

import { bilesikKisiActions } from "@/store/modules/administratorManagementStore/KPS/bilesikKisiActions";
const bilesikKisiStore = bilesikKisiActions();
</script>
