export const parseProvizyonTipi = (provizyon) => {
  switch (provizyon) {
    case "N":
      return "Normal";
    case "I":
      return "İş kazası";
    case "A":
      return "Acil";
    case "T":
      return "Trafik kazası";
    case "V":
      return "Adli vaka";
    case "M":
      return "Meslek hastalığı";
    case "K":
      return "Kurum sevki";
    case "C":
      return "Acil hal";
    case "H":
      return "Bulaşıcı hastalık";
    case "R":
      return "Koruyucu sağlık";
    case "L":
      return "Analık";
    case "G":
      return "Afet savaş grev lokavt";
    case "U":
      return "Bakıma muhtaç";
    case "D":
      return "Doğal afet";
    case "S":
      return "İstisnai hal";
    case "E":
      return "3713/21";
    default:
      return "-";
  }
};
export const parseIstisnaiHal = (istisnaiHal) => {
  switch (istisnaiHal) {
    case "1":
      return "Acil hal";
    case "2":
      return "İş kazası ile meslek hastalığı";
    case "3":
      return "Bildirimi zorunlu bulaşıcı hastalık";
    case "4":
      return "Kişiye yönelik koruyucu sağlık hizmeti";
    case "5":
      return "Trafik kazası";
    case "6":
      return "Afet ve savaş ile grev ve lokavt hali";
    case "7":
      return "18 yaş altı çocuklar ";
    case "8":
      return "Tıbben başkasının bakımına muhtaç kişiler";
    case "9":
      return "BKK na göre borçlular";
    case "I":
      return "İş kazası";
    case "M":
      return "Meslek hastalığı";
    case "B":
      return "Madde bağımlılığı";
    case "V":
      return "Acil hal - (Adli vaka kaynaklı başvurular)";
  }
};
export const parseTakipTipi = (takipTipi) => {
  switch (takipTipi) {
    case "N":
      return "Normal";
    case "E":
      return "Eşlik eden hastalık";
    case "U":
      return "Uzayan yatış";
    case "K":
      return "Komplikasyon";
    case "T":
      return "Tanı amaçlı günübirlik";
    case "Y":
      return "Yoğun bakım";
    case "P":
      return "Hizmetin iptal olması veya yarım kalması";
    case "S":
      return "Kurum sevkli kontrol muayenesi";
    case "C":
      return "Kurum sevkli";
    case "F":
      return "Faturalanmış uzayan yatış";
    case "B":
      return "Faturalanmış uzayan yatış ve yoğun bakım";
  }
};
export const parseTedaviTuru = (tedaviTuru) => {
  switch (tedaviTuru) {
    case "A":
      return "Ayakta tedavi";
    case "Y":
      return "Yatarak tedavi";
    case "G":
      return "Günübirlik tedavi";
  }
};
export const parseTedaviTipi = (tedaviTuru) => {
  switch (tedaviTuru) {
    case "0":
      return "Normal Sorgu";
    case "1":
      return "Diyaliz";
    case "2":
      return "Fiziksel tedavi ve rehabilitasyon";
    case "4":
      return "Kemik iliği";
    case "5":
      return "Kök hücre nakli";
    case "6":
      return "Ekstrakorporeal fotoferez tedavisi";
    case "7":
      return "Hiperbarik oksijen tedavisi";
    case "8":
      return "ESWL";
    case "9":
      return "Ağız Protez tedavisi";
    case "10":
      return "Ketem";
    case "11":
      return "Tüp Bebek 1";
    case "12":
      return "Tüp Bebek 2";
    case "13":
      return "Diş Tedavisi";
    case "14":
      return "Onkolojik Tedavi";
    case "15":
      return "Plazmaferez Tedavisi";
    case "16":
      return "Plazmaferez Tedavisi";
    case "17":
      return "ESWT";
    case "18":
      return "Evde Bakım Hizmeti";
    case "19":
      return "Analık Hali";
    case "20":
      return "İş Göremezlik";
    case "A":
      return "Ortodontik Tedavi";
  }
};
