<script>
import { defineComponent } from "vue";
import { ref } from "vue";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
moment.locale("tr");
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate.ts";
// Detail Hasta
import hastaRandevuAjanda from "@/modules/asistanEkrani/components/hastaRandevuAjanda.vue";
// References
import {
  createEventId,
  INITIAL_EVENTS,
  fetchDoctorSettings,
} from "@/components/asistant/CalendarEvent.ts";
import { globalStore } from "@/store/modules/globalStore";
import customInfoCard from "@/components/customInfoCard.vue";
import { doctorCalendarStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorCalendarStore";
import addToDrCalendar from "@/modules/doctorSlots/components/addToDrCalendar.vue";
import dateApprovePopUp from "@/modules/doctorSlots/components/dateApprovePopUp.vue";
import cancelPopUp from "@/modules/doctorSlots/components/cancelPopUp.vue";


export default defineComponent({
  components: {
    searchSlotsBetweenDate,
    FullCalendar,
    hastaRandevuAjanda,
    addToDrCalendar,
    cancelPopUp,
    dateApprovePopUp,
    customInfoCard,
  },
  computed: {
    propertyAAndPropertyB() {
      return `${globalStore.appointmentStartTime}|${this.mounted}`;
    },
  },
  data() {
    return {
      dateRange: [],
      multiCalendarOpened: false,
      settingsOpened: false,
      settingList: null,
      openAddToDrCalendar: false,
      opendateApprovePopUp: false,
      openCancelPopUp: false,
      mounted: false,
      deletingDate: "",
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        customButtons: {
          create: {
            text: "time",
            //icon: "fa-regular fa-user",
            click: this.handleRequestApproval,
          },
        },
        themeSystem: "bootstrap4",
        themeButtonIcons: true,
        headerToolbar: {
          start: "create",
          left: "prev,title,next",
          center: "",
          right: "",
        },
        dayHeaderFormat: { weekday: "long" },
        eventLimit: 3,
        firstDay: 1,
        fixedWeekCount: false,
        showNonCurrentDates: false,
        dayHeaderContent: function (arg) {
          return {
            html: `<span class="custom-day-header">${arg.text}</span>`,
            classNames: ["custom-day-header"],
          };
        },
        dayCellContent: function (arg) {
          return {
            html: `
            <div class="flex items-center gap-2">
            <input class="focus:outline-1 focus:outline-[#277dd2] " type="checkbox" name="" id="" />
            <span class="custom-day-cell">${arg.dayNumberText}</span> </div>`,
            classNames: ["custom-day-cell"],
          };
        },
        datesSet: async function (info) {
          globalStore.appointmentStartTime = info.start; // Görüntülenen tarih aralığının başlangıç tarihi
          globalStore.appointmentEndTime = info.end; // Görüntülenen tarih aralığının bitiş tarihi
        },
        initialView: "dayGridMonth",
        // {
        //     id: 0,
        //     title: `09.00-11.00`,
        //     detail: ``,
        //     patient: `setting`,
        //     patientStatus: "",
        //     patientApponintmentCode: ``,
        //     start: new Date().toISOString(),
        //     end: new Date().toISOString(),
        //     unitCode: ``,
        //     onHover: true,
        //   }
        events: INITIAL_EVENTS,
        // events: [
        //   {
        //     id: 0,
        //     title: `09.00-12.00`,
        //     detail: ``,
        //     patient: `setting`,
        //     patientStatus: "",
        //     patientApponintmentCode: ``,
        //     start: new Date().toISOString(),
        //     end: new Date().toISOString(),
        //     unitCode: ``,
        //     onHover: true,
        //   },
        //   {
        //     id: 1,
        //     title: `09.00-11.00`,
        //     detail: ``,
        //     patient: `setting`,
        //     patientStatus: "",
        //     patientApponintmentCode: ``,
        //     start: new Date().toISOString(),
        //     end: new Date().toISOString(),
        //     unitCode: ``,
        //     onHover: true,
        //   },
        //   {
        //     id: 2,
        //     title: `09.00-11.00`,
        //     detail: ``,
        //     patient: `setting`,
        //     patientStatus: "",
        //     patientApponintmentCode: ``,
        //     start: new Date().toISOString(),
        //     end: new Date().toISOString(),
        //     unitCode: ``,
        //     onHover: true,
        //   },
        //   {
        //     id: 3,
        //     title: `09.00-11.00`,
        //     detail: ``,
        //     patient: `setting`,
        //     patientStatus: "",
        //     patientApponintmentCode: ``,
        //     start: new Date().toISOString(),
        //     end: new Date().toISOString(),
        //     unitCode: ``,
        //     onHover: true,
        //   },
        //   {
        //     id: 4,
        //     title: `Ders nedeniyle iptal edilmiştir`,
        //     detail: ``,
        //     patient: `setting`,
        //     patientStatus: "iptal",
        //     patientApponintmentCode: ``,
        //     start: "2024-04-19T14:30:00.000Z",
        //     end: "2024-04-19T14:30:00.000Z",
        //     unitCode: ``,
        //     onHover: true,
        //   },
        // ],
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        toggleShowHover: true,
        eventMouseEnter: function (info) {
          info.el.classList.add("hovered-cell");
        },

        eventMouseLeave: function (info) {
          info.el.classList.remove("hovered-cell");
        },
      },
      // currentEvents: [],
      globalStore,
    };
  },
  watch: {
    // whenever question changes, this function will run
    async propertyAAndPropertyB(newVal, oldQuestion) {
      // this.calendarOptions.events = [];
      console.log("assistant calendar", newVal);
      // this.handleResetTheEvents();
      this.settingList = await fetchDoctorSettings(
        JSON.parse(localStorage.getItem("personelStoreData")).staffId,
        moment(globalStore.appointmentStartTime).format("YYYY-01-01"),
        moment(globalStore.appointmentEndTime).format("YYYY-12-31"),
        "list"
      );
      console.log("listembudur", this.settingList);
      await fetchDoctorSettings(
        JSON.parse(localStorage.getItem("personelStoreData")).staffId,
        moment(globalStore.appointmentStartTime).format("YYYY-MM-DD"),
        moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
        "calendar"
      );
      this.calendarOptions.events = [...INITIAL_EVENTS];
    },
  },
  async mounted() {
    console.log("sa");
    this.mounted = true;

    this.setTurkishLocale(); // Türkçe dil ayarlarını ayarla
  },

  methods: {
    moment,
    doctorCalendarStore() {
      return doctorCalendarStore();
    },

    openAddToDrCalendarTrue() {
      this.openAddToDrCalendar = true;
    },
    openAddToDrCalendarFalse() {
      this.openAddToDrCalendar = false;
      globalStore.isCreating = false;
    },

    opendateApprovePopUpTrue() {
      this.opendateApprovePopUp = true;
    },
    opendateApprovePopUpFalse() {
      this.opendateApprovePopUp = false;
    },
    openCancelPopUpTrue() {
      this.openCancelPopUp = true;
    },
    openCancelPopUpFalse() {
      this.openCancelPopUp = false;
    },

    setTurkishLocale() {
      this.calendarOptions.locale = "tr"; // Dil ayarını Türkçe olarak belirle
      this.calendarOptions.slotLabelFormat = {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      }; // Saat formatını Türkçe ayarla
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleMultipleCalendarDateSelect(selectInfo) {
      doctorCalendarStore().selectedDays = [];
      doctorCalendarStore().activeIndex = 0;
      doctorCalendarStore().updatingSetting = null;
      //secilen baslangic ve bitisi bulup aradaki gunleri de almak uzere time-day diff ayarliyoruz
      const startDate = new Date(selectInfo.startStr);
      const endDate = new Date(selectInfo.endStr);
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (dayDiff > 730) {
        return false;
      }
      //hafta ici gunleri secilen gunler arrayimize ekliyoruz
      for (let i = 0; i <= dayDiff; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i);
        if (
          currentDate.toLocaleDateString("tr", { weekday: "long" }) ==
            "Pazar" ||
          currentDate.toLocaleDateString("tr", { weekday: "long" }) ==
            "Cumartesi"
        ) {
          console.log("weekend");
        } else {
          this.opendateApprovePopUpTrue();
          doctorCalendarStore().selectedDays.push({
            date: currentDate,
            workingHours: [],
            workingMinutes: [],
            overbookAllowed: false,
            overbookLimit: "",
            examinationDuration: "",
          });
        }
      }
      this.multiCalendarOpened = false;
      this.dateRange = [];

      console.log(doctorCalendarStore().selectedDays);
    },
    handleDateSelect(selectInfo) {
      if (selectInfo.jsEvent.srcElement.localName === "input") {
        return false;
      }

      let calendarApi = selectInfo.view.calendar;
      doctorCalendarStore().selectedDays = [];
      doctorCalendarStore().activeIndex = 0;
      doctorCalendarStore().updatingSetting = null;
      //secilen baslangic ve bitisi bulup aradaki gunleri de almak uzere time-day diff ayarliyoruz
      const startDate = new Date(selectInfo.startStr);
      console.log("startStr", selectInfo.startStr);
      console.log("startDate", startDate);
      const endDate = new Date(selectInfo.endStr);
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      //hafta ici gunleri secilen gunler arrayimize ekliyoruz
      for (let i = 0; i <= dayDiff - 1; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i);
        if (
          currentDate.toLocaleDateString("tr", { weekday: "long" }) ==
            "Pazar" ||
          currentDate.toLocaleDateString("tr", { weekday: "long" }) ==
            "Cumartesi"
        ) {
          console.log("weekend");
        } else {
          this.opendateApprovePopUpTrue();
          doctorCalendarStore().selectedDays.push({
            date: currentDate,
            workingHours: [],
            workingMinutes: [],
            overbookAllowed: false,
            overbookLimit: "",
            examinationDuration: "",
          });
        }
      }

      console.log(doctorCalendarStore().selectedDays);

      calendarApi.unselect(); // clear date selection
    },
    handleSettingListSelect(setting) {
      console.log("ss", setting);
      doctorCalendarStore().selectedDays = [];
      doctorCalendarStore().activeIndex = 0;
      doctorCalendarStore().appointmentType =
        setting.attributes.doctor_settings[0]?.AppointmentTypes;
      doctorCalendarStore().updatingSetting = setting;
      //secilen baslangic ve bitisi bulup aradaki gunleri de almak uzere time-day diff ayarliyoruz
      const startDate = new Date(setting.attributes.slot_start_time);
      const endDate = new Date(setting.attributes.slot_end_time);
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      // Tarihleri string formatında karşılaştırmak için bir yardımcı fonksiyon
      const formatDate = (date) => date.toISOString().split("T")[0];

      // Saat ve dakika aralığını belirli bir formata çeviren yardımcı fonksiyon
      const formatTime = (time) => time.toString().padStart(2, "0");

      // Hafta içi günleri seçilen günler arrayimize ekliyoruz
      for (let i = 0; i <= dayDiff - 1; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i);

        const dayName = currentDate.toLocaleDateString("tr", {
          weekday: "long",
        });

        if (dayName === "Pazar" || dayName === "Cumartesi") {
          console.log("weekend");
        } else {
          // Aynı güne ait tüm slotları topluyoruz
          const slotsForDay = setting.attributes.doctor_settings.filter(
            (slot) =>
              formatDate(new Date(slot.SlotDate)) === formatDate(currentDate)
          );

          // Eğer o gün için slotlar varsa, onları güncellenmiş yapıya ekleyelim
          if (slotsForDay.length > 0) {
            this.openAddToDrCalendarTrue();

            const workingHours = slotsForDay.map((slot) => {
              const [start, end] = slot.WorkingHours.split("-");
              return {
                start: formatTime(start.split(":")[0]),
                end: formatTime(end.split(":")[0]),
              };
            });

            const workingMinutes = slotsForDay.map((slot) => {
              const [start, end] = slot.WorkingHours.split("-");
              return {
                start: formatTime(start.split(":")[1]),
                end: formatTime(end.split(":")[1]),
              };
            });

            const overbookAllowed = slotsForDay[0].OverbookAllowed; // Tüm slotlar için aynı olduğunu varsayalım
            const overbookLimit = slotsForDay[0].OverbookLimit.Int32.toString(); // Tüm slotlar için aynı olduğunu varsayalım
            const examinationDuration = slotsForDay[0].SlotDuration.toString(); // Tüm slotlar için aynı olduğunu varsayalım

            doctorCalendarStore().selectedDays.push({
              date: currentDate,
              workingHours,
              workingMinutes,
              overbookAllowed,
              overbookLimit,
              examinationDuration,
            });
          }
        }
      }
    },
    handleEventClick(clickInfo) {
      console.log(clickInfo);
      // this.openCancelPopUpTrue();
      // this.deletingDate = clickInfo.event.startStr;
      // this.deletingEvent = clickInfo.event;
    },
    handleEventDelete(arguman) {
      this.openCancelPopUpTrue();
      this.deletingDate = arguman.startStr;
      this.deletingEvent = arguman;
      console.log(arguman);
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
  },
});
</script>

<template>
  <!-- Class includes just display flex and flex-direction:column -->
  <!-- v-if="INITIAL_EVENTS.length> 2" -->
  <div class="calendar-app relative">
    <div class="absolute z-[999] right-20 top-20">
      <customInfoCard v-if="globalStore.customInfoCard" />
    </div>
    <button
      @click="settingsOpened = !settingsOpened"
      class="absolute right-[350px] top-0 py-2 px-3 border rounded-[30px] flex justify-between items-center gap-2"
      :class="
        settingsOpened ? 'bg-[#40464c] text-white' : 'bg-white text-[#40464c]'
      "
    >
      <img
        v-if="settingsOpened"
        src="@/modules/doctorSlots/assets/calendarWhite.svg"
        alt="bottomArrow"
      />
      <img v-else src="@/modules/doctorSlots/assets/calendarBlack.svg" alt="bottomArrow" />
      <p :class="settingsOpened ? 'text-white' : 'text-[#40464C]'">
        Çizelgeler
      </p>
      <img
        v-if="settingsOpened"
        src="@/modules/doctorSlots/assets/bottomArrowWhite.svg"
        alt="bottomArrow"
      />
      <img v-else src="@/modules/doctorSlots/assets/bottomArrowGray.svg" alt="bottomArrow" />
    </button>
    <div
      v-if="settingsOpened"
      class="z-40 absolute right-[350px] w-fit max-h-40 overflow-auto top-12 py-1 px-2 border rounded-lg flex flex-col gap-3 bg-white"
    >
      <div
        class="cursor-pointer"
        v-for="(setting, index) in settingList"
        @click="handleSettingListSelect(setting)"
      >
        {{ moment(setting.attributes.slot_start_time).format("DD MMMM") }} -
        {{ moment(setting.attributes.slot_end_time).format("DD MMMM") }}
      </div>
    </div>
    <button
      @click="multiCalendarOpened = !multiCalendarOpened"
      class="absolute right-20 top-0 py-2 px-3 border rounded-[30px] flex justify-between items-center gap-2"
      :class="
        multiCalendarOpened
          ? 'bg-[#40464c] text-white'
          : 'bg-white text-[#40464c]'
      "
    >
      <img
        v-if="multiCalendarOpened"
        src="@/modules/doctorSlots/assets/calendarWhite.svg"
        alt="bottomArrow"
      />
      <img v-else src="@/modules/doctorSlots/assets/calendarBlack.svg" alt="bottomArrow" />
      <p :class="multiCalendarOpened ? 'text-white' : 'text-[#40464C]'">
        Çalışma çizelgesi oluştur
      </p>
      <img
        v-if="multiCalendarOpened"
        src="@/modules/doctorSlots/assets/bottomArrowWhite.svg"
        alt="bottomArrow"
      />
      <img v-else src="@/modules/doctorSlots/assets/bottomArrowGray.svg" alt="bottomArrow" />
    </button>
    <div
      v-if="multiCalendarOpened"
      class="z-40 absolute right-20 top-12 py-2 px-3 border rounded-[30px] flex flex-col gap-3 bg-white"
    >
      <VueDatePicker
        class="w-72 mx-auto"
        v-model="dateRange"
        locale="tr"
        format="dd/MM/yyyy"
        auto-apply
        :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
        :disabled-week-days="[6, 0]"
        :enable-time-picker="false"
        :enable-date-picker="false"
        range
        multi-calendars
      />
      <VueDatePicker
        class="mx-auto"
        v-model="dateRange"
        locale="tr"
        format="dd/MM/yyyy"
        auto-apply
        :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
        :disabled-week-days="[6, 0]"
        inline
        :enable-time-picker="false"
        range
        multi-calendars
      />

      <button
        @click="
          handleMultipleCalendarDateSelect({
            startStr: dateRange[0],
            endStr: dateRange[1],
          })
        "
        :disabled="!dateRange || !(dateRange.length > 0)"
        :class="
          !(dateRange?.length > 0)
            ? 'bg-[#d5d5d6] text-white py-2 rounded-[10px]'
            : 'bg-[#277dd2] text-white py-2 rounded-[10px] cursor-pointer'
        "
        class="w-full py-2 rounded-[10px]"
      >
        Devam Et
      </button>
    </div>
    <div class="doctorPlanCalendar">
      <div class="calendar-app-main">
        <FullCalendar
          ref="calendar"
          class="calendar-app-calendar"
          :options="calendarOptions"
        >
          <template v-slot:eventContent="arg">
            <div
              v-if="arg.event.extendedProps.patientStatus === 'iptal'"
              class="bg-[#d5d5d6] text-center px-3 gap-6 text-sm mx-auto w-[190px] p-2 text-[#40464C] flex items-center rounded-[20px]"
            >
              <p class="whitespace-break-spaces w-11/12 overflow-clip">
                {{ arg.event.title }}
              </p>
            </div>

            <div
              v-if="arg.event.extendedProps.patientStatus !== 'iptal'"
              class="bg-[#d4e5f6] px-3 gap-6 text-sm mx-auto w-[190px] h-[27px] text-[#277dd2] flex items-center rounded-[20px]"
            >
              <img src="@/modules/doctorSlots/assets/clock.svg" alt="" />
              <p>{{ arg.event.title }}</p>
            </div>
          </template>
        </FullCalendar>
        <!-- <div v-if="INITIAL_EVENTS&&INITIAL_EVENTS.lenght>4">
        <AssistantCalendarDetailButton />
      </div> -->
      </div>
    </div>
    <addToDrCalendar
      :openAddToDrCalendarFalse="openAddToDrCalendarFalse"
      v-if="openAddToDrCalendar"
    />
    <dateApprovePopUp
      :opendateApprovePopUpFalse="opendateApprovePopUpFalse"
      :openAddToDrCalendarTrue="openAddToDrCalendarTrue"
      v-if="opendateApprovePopUp"
    />

    <cancelPopUp
      :openCancelPopUpFalse="openCancelPopUpFalse"
      :deletingDate="deletingDate"
      :deletingEvent="deletingEvent"
      type="setting"
      v-if="openCancelPopUp"
    />
    <div
      class="overlay"
      v-if="opendateApprovePopUp || openCancelPopUp || openAddToDrCalendar"
    ></div>
  </div>
</template>

<style lang="css" scoped>
/* @import "../../asistanEkrani/asistanCalendar/calendarStyle.css"; */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 45;
}

/* .fc-daygrid-day {
  height: 130px !important;
}
.fc-daygrid-more-link {
  position: absolute !important;
  top: -27px !important;
  right: 3px !important;
}
.fc-more-popover-misc{
  display: none;
} */
</style>
