<template>
  <div>
    <h4 class="font-medium text-2xl mb-4">Kısayollar</h4>
    <div class="flex flex-col gap-4 p-1">
      <div
        class="bg-white border shadow rounded-[20px] px-6 py-[10px] w-[280px] flex flex-col gap-1"
      >
        <img class="mr-auto" :src="profileBlue" alt="" />
        <button
          @click="routeDoctorInfos"
          class="flex items-center gap-6 w-fit focus:outline-1 focus:outline-[#277dd2]"
        >
          <p class="font-medium">Kullanıcı Bilgileri</p>
          <img :src="rightArrowHead" />
        </button>
        <p class="text-sm opacity-60">
          Kişisel, iletişim ve hekim bilgilerinizi görüntüleyebilirsiniz.
        </p>
      </div>
      <div
        class="bg-white border shadow rounded-[20px] px-6 py-[10px] w-[280px] flex flex-col gap-1"
      >
        <img class="mr-auto" :src="planIcon" alt="" />
        <button
          @click="routeDoctorPlan"
          class="flex items-center gap-6 w-fit focus:outline-1 focus:outline-[#277dd2]"
        >
          <p class="font-medium">Çizelge</p>
          <img :src="rightArrowHead" />
        </button>
        <p class="text-sm opacity-60">
          Çalışma aralıklarınızı görüntüleyebilir ve düzenleyebilirsiniz.
        </p>
      </div>
      <div
        class="bg-white border shadow rounded-[20px] px-6 py-[10px] w-[280px] flex flex-col gap-1"
      >
        <img class="mr-auto" :src="performanceIcon" alt="" />
        <button
          @click="routeDoctorPerformance"
          class="flex items-center gap-6 w-fit focus:outline-1 focus:outline-[#277dd2]"
        >
          <p class="font-medium">Performans</p>
          <img :src="rightArrowHead" />
        </button>
        <p class="text-sm opacity-60">
          Aylara göre hizmet tablosunu ve hekim puanınızı görüntüleyebilirsiniz.
        </p>
      </div>
      <div
        class="bg-white border shadow rounded-[20px] px-6 py-[10px] w-[280px] flex flex-col gap-1"
      >
        <img class="mr-auto" :src="appointmentIcon" alt="" />
        <button
          @click="routeDoctorAppointments"
          class="flex items-center gap-6 w-fit focus:outline-1 focus:outline-[#277dd2]"
        >
          <p class="font-medium">Randevular</p>
          <img :src="rightArrowHead" />
        </button>
        <p class="text-sm opacity-60">
          Randevularınızı görüntüleyebilir ve düzenleyebilirsiniz.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import rightArrowHead from "../assets/rightArrowHead.svg";
import profileBlue from "../assets/profileBlue.svg";
import planIcon from "../assets/planIcon.svg";
import performanceIcon from "../assets/performanceIcon.svg";
import appointmentIcon from "../assets/appointmentIcon.svg";
import { doctorSlotsStore } from "@/modules/doctorSlots/doctorSlotsStore/doctorSlots";
const doctorSlotStore = doctorSlotsStore();
import { useRouter } from "vue-router";

const router = useRouter();

const routeDoctorInfos = () => {
  router.push("/polyclinic/doctor-screens/infos");
};
const routeDoctorPlan = () => {
  router.push("/polyclinic/doctor-screens/plan");
};
const routeDoctorPerformance = () => {
  router.push("/polyclinic/doctor-screens/performance");
};
const routeDoctorAppointments = () => {
  router.push("/polyclinic/doctor-screens/appointments");
};
</script>
