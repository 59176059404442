import { defineStore } from "pinia";
import { ListPatientApplicationsResponse } from "@/types/operations/patientApplications/listPatientApplicationsResponse";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import moment from "moment";

export const registeredPatientApplicationStore = defineStore({
  id: "registeredPatientApplicationStore",
  state: () => ({
    listPatientApplicationResponse:
      null as ListPatientApplicationsResponse | null,
    paginationListPatientApplications: [],
  }),
  actions: {
    async getRegisteredPatientApplications(
      page: number,
      page_size: number,
      startDate: string,
      endDate: string,
      applicationStatus: string,
      provisionType: string,
      unit: string,
      corporationName: string,
      insuranceType: string,
      search: string
    ) {
      const personelStoreData = personelStore();

      await axios
        .post(
          `${clinicDomain}/patient-applications/search`,
          {
            search_bar: search || "",
            unit: unit || "",
            application_status: applicationStatus || "",
            provision_type: provisionType || "",
            corporation_name: corporationName || "",
            insurance_type: insuranceType || "",
            page_size: page_size,
            page_id: page,
            start_date: startDate,
            end_date: endDate,
            // moment(date).utc().format("YYYY-MM-DD")
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("fetch response data", response.data);
          this.listPatientApplicationResponse = response.data;
        });
    },
  },
});
