<template>
  <div class="flex">
    <announcementSidebar v-if="announcementsStore.isSidebarOpen" />
    <div class="bg-gray-100 min-h-screen w-full">
      <announcementHeader />
      <div class="pt-6 pb-2 pl-20">
        <announcementNavbar />

        <announcementContent
          v-for="(content, index) in announcementsStore.contents"
          :key="index"
          :title="content.title"
          :text="content.content"
          :createdTime="content.created_at"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import announcementHeader from "@/mainPage/components/announcementHeader.vue";
import announcementContent from "@/mainPage/components/announcementContent.vue";
import announcementNavbar from "@/mainPage/components/announcementNavbar.vue";
import announcementSidebar from "@/mainPage/components/announcementSidebar.vue";
import { useAnnouncements } from "@/mainPage/store/announcementStore";
const announcementsStore = useAnnouncements();
import { useListAnnouncementsStore } from "@/store/modules/administratorManagementStore/announcements/listAnnouncements";
const listAnnouncementsStore = useListAnnouncementsStore();

// backend filtreleri ekleyince burada nav için watch koyup nava göre parametre yollarız filtreleyip çekeriz

onMounted(async () => {
  announcementsStore.contents =
    await listAnnouncementsStore.listAnnouncements();
});
</script>
