<template>
  <div class="flex items-center gap-3">
    <appointmentListDateComponent />
    <VueDatePicker
      v-model="globalStore.appointmentStartTime"
      locale="tr"
      auto-apply
      format="dd/MM/yyyy"
      :enable-time-picker="false"
      :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
      class="w-[155px]"
    />
  </div>
  <appointmentList clinicScreen />
  <createOrUpdateAppointmentSidebar clinicScreen />
</template>

<script setup>
import createOrUpdateAppointmentSidebar from "@/modules/asistanEkrani/components/createOrUpdateAppointmentSidebar.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import appointmentList from "@/modules/asistanEkrani/components/appointmentList.vue";
import appointmentsNav from "./appointmentsNav";
import appointmentListDateComponent from "@/modules/asistanEkrani/components/appointmentListDateComponent.vue";
import { globalStore } from "@/store/modules/globalStore";
import { onMounted, watch } from "vue";
import { useAppointmentBook } from "@/modules/asistanEkrani/components/appointmentBookStore";
import { useKlinikYonetimStore } from "@/modules/klinikYonetim/store/klinikYonetim.js";
const klinikYonetimStore = useKlinikYonetimStore();
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();
onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});
watch(
  () => globalStore.appointmentStartTime,
  (newValue) => {
    console.log("ee", newValue);
    globalStore.appointmentEndTime = new Date(
      globalStore.appointmentStartTime.getTime() + 24 * 60 * 60 * 1000
    );
  }
);
onMounted(() => {
  useAppointmentBook().unit_code =
    klinikYonetimStore.klinikUnitScreenUnitDatas.birim_kodu;
  useAppointmentBook().filteredDoctors =
    klinikYonetimStore.klinikUnitScreenDoctors;
});

watch(
  () => klinikYonetimStore.klinikUnitScreenUnitDatas,
  (newValue, oldValue) => {
    if (newValue && newValue.birim_kodu) {
      useAppointmentBook().unit_code = newValue.birim_kodu;
    }
  }
);

watch(
  () => klinikYonetimStore.klinikUnitScreenDoctors,
  (newValue) => {
    useAppointmentBook().filteredDoctors = newValue;
  }
);
</script>
