import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useUpdateMessageTemplate = defineStore({
  id: "useUpdateMessageTemplate",
  state: () => ({}),
  actions: {
    async updateMessageTemplate(id, title, message, isActive, duration) {
      const personelStoreData = personelStore();
      await axios
        .put(
          `${adminDomain}/message-template`,
          {
            id: id,
            title: title,
            message: message,
            is_active: isActive,
            duration: duration,
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("updateMessageTemplateResponse", response);
        })
        .catch((error) => {
          console.log("updateMessageTemplateError", error);
        });
    },
  },
});
