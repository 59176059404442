<template>
  <div
    v-if="appointmentStore.appointmentSideBarHelpButtonIsActive"
    class="absolute ml-[239px] mt-[93px] w-[85%] h-[85%] bg-[#F8F9FD]"
  >
    <div class="flex h-full w-full flex-col">
      <div class="mx-8 flex h-8 w-[95%] justify-between">
        <div class="flex flex-row items-start justify-start">
          <div
            class="flex w-full cursor-pointer items-center rounded-t-md border-[1px] border-[#C5CDD8] bg-[#FDC500] py-1 px-4 text-base font-normal leading-normal text-[#263445] shadow-lg"
          >
            <img :src="help_white" alt="" class="mr-1" />
            <p class="text-white">Yardım</p>
          </div>
        </div>
      </div>
      <div
        class="mx-8 h-[95%] flex w-[95%] rounded-b-lg bg-white border-[#C5CDD8] border-[2px] items-center"
      >
        <div class="flex flex-col">
          <button
            @click="selectedButton = index"
            :class="
              selectedButton === index
                ? 'bg-[#277DD280] text-[#252525] font-medium'
                : 'bg-white text-[#00000080] font-normal'
            "
            class="flex items-center w-[550px] h-[50px] border-r-[1px] border-l-[1px] border-[#277DD2] ml-[20px] border-rounded focus:outline-none font-poppin"
            v-for="(item, index) in buttonHelpNames"
            :key="index"
          >
            <div
              :class="
                selectedButton === index
                  ? 'bg-[#277DD2] h-7 w-7'
                  : 'bg-[#277DD280] text-[#00000080] font-normal h-5 w-5'
              "
              class="rounded-full mx-4 flex items-center justify-center"
            ></div>
            {{ item }}
          </button>
        </div>
        <div class="flex flex-col h-full w-full items-center">
          <button
            @click="
              appointmentStore.appointmentSideBarHelpButtonIsActive = false
            "
            class="h-6 w-6 ml-auto mr-4 mt-4"
          >
            <img :src="exit" alt="exit" />
          </button>
          <p class="font-poppin text-4xl font-medium text-[#252525] -mt-[30px]">
            YARDIM
          </p>
          <div
            class="w-[105px] mt-1 border-t-[2px] border-[#252525] mb-2"
          ></div>
          <div
            v-if="selectedButton == 0"
            class="bg-[#277DD20D] w-full h-full flex flex-col overflow-auto"
          >
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light font">
              Randevu sistemi üzerinden ilk muayene için randevu
              alınabilmektedir. Randevular; Yetişkin İlk Muayene (15 yaş ve
              üstü), Çocuk İlk Muayene (15 yaş altı) randevuları şeklinde iki
              türde verilmektedir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-medium">
              Randevularımız yetişkin hastalar için her gün saat 15:00’te bir
              sonraki iş günü için web sitemiz üzerinden açılmaktadır.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Randevular; Yetişkin İlk Muayene (15 yaş ve üzeri), Çocuk İlk
              Muayene Pedodonti (0-15 yaş arası) randevuları şeklinde
              verilmektedir. Randevularınızı belirlenen yaş gruplarına göre;
              0-15 yaş için her hafta
              <span class="font-medium text-black"
                >Salı-Çarşamba-Perşembe-Cuma</span
              >
              günleri olmak üzere saat
              <span class="font-medium text-black">15:00</span>
              ‘te web sitemiz üzerinden veya üzerinden alabilirsiniz
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-medium">
              18 yaşından küçük hastalarımızın muayene ve tedavilerine gelirken
              ebeveynleriyle birlikte gelmesi gerekmektedir.
            </p>
            <p
              class="mt-1 w-[645px] max-w-[645px] mx-auto font-light flex items-start justify-start"
            >
              Hafta Sonları ve Resmi Tatil günlerine randevu verilmemektedir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Merkezimize muayene ve tedavi amaçlı başvuran
              <span class="font-medium text-black">YEŞİLKART</span>

              hastalarımızın her gelişlerinden önce Ağız ve Diş Sağlığı
              Merkezi/Hastanesi gibi 2.Basamak sağlık kuruluşlarından
              <span class="font-medium text-black">SEVK</span>
              almaları gerekmektedir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Hastanemize başvuran kurum sandık hastalarının, kurum sevki, kurum
              kimlik kartı ve kimlik ibrazı yapmaları gerekmektedir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Her Anabilim Dalı Kliniğinin günlük hasta kontenjan sayısı (kabul
              edilen hasta sayısı) değişiklik göstermektedir. Mevcut tedavi
              edilecek hasta sayıları; ilgili Anabilim Dalı uzman hekim sayısı
              ve tedavi süreleri göz önüne alınarak ilgili Anabilim Dalı
              Başkanları tarafından belirlenmektedir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Randevu günü verilen randevu saatinde Fakülteye gelen hastaların,
              ilk olarak hasta kabul birimine başvurarak giriş yaptırması
              gerekir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              İlk muayene sonrası hekimin yönlendirme yaptığı teşhis planlama
              formu ile hastalarımız hasta kabul birimine başvurarak
              yönlendirmeleri yapılmaktadır.
            </p>
          </div>
          <div
            v-if="selectedButton == 1"
            class="bg-[#277DD20D] w-full h-full flex flex-col overflow-auto"
          >
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Sağlık Bakanlığı, yayınladığı Covid-19 pandemisinde çalışma
              rehberine* göre; hastalara randevu verilerek tedavi ve muayene
              işlemlerinin yürütülmesini, iki hasta arasında temizlik ve
              havalandırmaya yeterli zaman ayrılmasını (30 dk) ve hasta
              trafiğinin kontrol altında tutulmasını istemektedir. Bu nedenle
              ilk muayene randevularımız internet üzerinden verilmektedir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-medium">
              (*Covid-19 pandemisinde sağlık kurumlarında çalışma rehberi ve
              enfeksiyon kontrol önlemleri- Bilimsel Danışma Kurulu Çalışması- 1
              Haziran- 09 Temmuz 2020)
            </p>
          </div>
          <div
            v-if="selectedButton == 2"
            class="bg-[#277DD20D] w-full h-full flex flex-col overflow-auto"
          >
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Üniversite hastaneleri gibi 3. basamak tedavi veren kurumlarda ilk
              muayenenin ardından ilgili uzmanlık alanını ilgilendiren işlemler,
              gerekli sıra ile ilgili bölümlere yönlendirilir. Tüm Diş Hekimliği
              Üniversite hastanelerinde uygulanan bu sistem
              <span class="font-medium text-black">zincir tedavi</span>
              olarak tanımlanabilir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Yani internetten aldığınız ilk muayene randevusundan sonra tedavi
              için gerekli bölümlerden randevularınızi web sitemiz üzerinden
              oluşturabilirsiniz.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Hastaların tedavileri, ilgili bölümlerde Uzman Öğretim Üyeleri ve
              Asistan hekimler tarafından ve/veya uzman hekimlerin gözetiminde
              yapılır.
            </p>
          </div>
          <div
            v-if="selectedButton == 3"
            class="bg-[#277DD20D] w-full h-full flex flex-col overflow-auto"
          >
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              182 Merkezi Hekim Randevu Sistemi Kurumumuza ait bir randevu
              sistemi değildir.
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              <button
                @click="routerToGetAppointment"
                class="text-blue-500 underline"
              >
                Kurumumuzun internet sayfasından
              </button>
              randevu alabilirsiniz.
            </p>
          </div>
          <div
            v-if="selectedButton == 4"
            class="bg-[#277DD20D] w-full h-full flex flex-col overflow-auto"
          >
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Sağlık Bakanlığı’nın yayınladığı Covid-19 pandemisinde çalışma
              rehberine göre; randevulu hastalara randevu verilerek tedavi
              verilmesi ve hasta trafiğin kontrol altında tutulması
              istenmektedir. Bu sebeple pandemi sürecinde randevusuz hasta
              <span class="font-medium text-black"> bakılamamaktadır.</span>
            </p>
            <p class="mt-1 w-[645px] max-w-[645px] mx-auto font-light">
              Ancak kurumumuz, 3. Basamak sağlık hizmeti (ileri ve kompleks
              tedaviler) verdiği için 1. ve 2. Basamak sağlık hizmeti veren
              kurumlardan sevk ile gelen ileri tedaviye ihtiyaç duyan hastalara
              ayaktan bakım hizmeti sunmaktadır.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import help_white from "../assets/help_white.svg";
import exit from "../assets/xIsareti.svg";
import { useAppointmentStore } from "../../store/appointmentStore";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const appointmentStore = useAppointmentStore();
const selectedButton = ref(0);
const buttonHelpNames = [
  "Randevu Nasıl Alabilirim?",
  "Randevular Neden İnternet Üzerinden Alınıyor?",
  "Tüm Bölümlerden İnternet Randevusu Alınabiliyor mu?",
  "182 MHRS’den Randevu Alabilir miyim?",
  "Randevusuz Hasta Alınıyor mu?",
];

const routerToGetAppointment = () => {
  router.push("/patientLogin");
};
</script>

<style scoped>
.border-rounded:nth-of-type(1) {
  border-radius: 8px 8px 0px 0px;
  border-top: 1px solid #277dd2;
}
.border-rounded:nth-of-type(5) {
  border-radius: 0px 0px 8px 8px;
  border-bottom: 1px solid #277dd2;
}
</style>