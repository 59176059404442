import { defineStore } from "pinia";
export const studentAssistantStore = defineStore("store", {
  id: "studentAssistantStore",
  state: () => ({
    search: '',
    studentAssistantFilter:0,
    studentAssistantFilterFlow:null,
    studentAssistantFilterAcceptFlow:false,
    studentAssistantFilterItems:[true,true,true,true,true,true],
    studentAssistantHideItems:[true,true,true,true,true,true,true,true,true,true,true],
    studentAssistantCagriItems:null,

  }),
  getters: {
  },
  actions: {

  },
});
