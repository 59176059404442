import { FrontendApi, Configuration, Session } from "@ory/client";
import { ref, watchEffect } from "vue";
import axios, { AxiosResponse } from "axios";

export const basePath =
  process.env.VUE_APP_ORY_URL || "https://api.dentaltest.xyz";

// export const oryKratos = new FrontendApi(
//   new Configuration({
//     basePath: basePath,
//     baseOptions: {
//       credentials: true,
//     },
//   })
// );
// export const checkSessionCookie = () => {
//   const sessionRef = ref<Session>();

//   watchEffect(() => {
//     console.log("ORY Kratos login watchEffect active");
//     oryKratos
//       // the cookie is automatically sent with the request
//       .toSession()
//       .then(({ data: session }) => {
//         sessionRef.value = session;
//       })
//       .catch((error) => {
//         // The session could not be fetched
//         // This might occur if the current session has expired
//         console.log("ORY Kratos personel login error : ", error);
//       });
//   });
// };
