<template>
  <div class="flex items-center flex-col  mt-28">
    <p class="flex h-6 text-[#2C3D8F] font-roboto font-medium text-2xl mt-48">
      KÜTAHYA SAĞLIK BİLİMLERİ ÜNİVERSİTESİ
    </p>
    <div class="flex h-14 w-2/6 rounded-[5px] border-[#D9D9D9] items-center border-2 mt-14">
      <input
        id=""
        v-model="messageIconRef"
        type="text"
        placeholder="Ad/Soyad"
        class="h-full  w-full pl-2 focus:outline-none"
        name=""
      >
      <button class="mr-2">
        <img
          v-if="!messageIconRef"
          :src="searchIconWhite"
          alt=""
        >
        <img
          v-if="messageIconRef"
          :src="searchIconBlue"
          alt=""
        >
      </button>
    </div>



    <div class="flex flex-col h-14 w-2/6 rounded-[5px] border-[#D9D9D9] items-center justify-center border-2 mt-14">
      <button
        ref="birimler"
        class="mx-auto flex h-10 w-full items-center flex-row justify-between"
        @click="showTumBirimlerRef(!tumBirimlerRef.valueOf())"
      >
        <p
          class="items-center flex ml-3 buttonText"
          :class="
            tumBirimlerRef === true
              ? 'sorulanSorularActiveButtonText'
              : 'sorulanSorularNormalButtonText'
          "
        >
          Tüm Birimler
        </p>
        <img
          class="mr-7"
          :src="tumBirimlerRef === true ? upArrowBlue : downArrowBlack"
          alt=""
        >
      </button>
    </div>
    <div
      v-if="tumBirimlerRef"
      class="flex flex-col  w-2/6 max-h-[250px] rounded-[5px] border-[#D9D9D9]  mt-2    "
    >
      <button class="w-full  h-8 buttonText">
        <p class="flex ml-4 ">
          Rektörlük
        </p>
      </button>
      <button class="w-full  h-8 buttonText">
        <p class="flex ml-4 ">
          Hukuk Bürosu
        </p>
      </button>
      <button class="w-full  h-8 buttonText">
        <p class="flex ml-4 ">
          Bilgi İşlem Daire Başkanlığı
        </p>
      </button>
      <button class="w-full  h-8 buttonText">
        <p class="flex ml-4 ">
          İdari ve Mali İşler Daire Başkanlığı
        </p>
      </button>
      <button class="w-full  h-8 buttonText">
        <p class="flex ml-4 ">
          Kütüphane ve Dokümantasyon Daire Başkanlığı
        </p>
      </button>
    </div>
  </div>
</template>

<script setup>
import searchIconWhite from '../assets/searchIconWhite.svg'
import searchIconBlue from '../assets/searchIconBlue.svg'
import upArrowBlue from "../assets/upArrowBlue.svg";
import downArrowBlack from "../assets/downArrowBlack.svg";
import {ref} from 'vue'

let tumBirimlerRef = ref(false);
let messageIconRef=ref(null)

const showTumBirimlerRef = (valueOfRef) => {
    console.log(valueOfRef)
    tumBirimlerRef.value = valueOfRef;
};

</script>

<style scoped>
.buttonText{
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
}
.buttonText {
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
}
.buttonText p:hover {
color: #2C3D8F;
}

.buttonHideText{
    display: none;
}
</style>