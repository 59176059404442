import { defineStore } from "pinia";
import axios from "axios";
import _ from "lodash";
import router from "@/router/index";
import { globalStore } from "@/store/modules/globalStore";
import { Patient } from "@/types/user/Patient";
import moment from "moment";
import { patientStore } from "../patient";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { useAddPatientStore } from "@/store/modules/operationsStore/patient/useAddPatientStore";
import { patientAuthToken } from "@/common/token";
import { userInfo } from "@/modules/medula/addPatient/components/userInfoReactiveTypes";
import { ref } from "vue";
const basePath = "https://api.dentaltest.xyz";
export const bilesikKisiActions = defineStore({
  id: "kpsBilesikActions",
  state: () => ({
    patientNationality: "T.C Vatandaşı",
  }),
  actions: {
    async kpsBilesikKisiSorgulama(patientTcNo) {
      const addPatientStore = useAddPatientStore();
      // const patient = addPatientStore.addPatientRequest;

      const personelStoreData = personelStore();
      let token = localStorage.getItem("patientStoreData");
      let accessToken = token ? JSON.parse(token).accessToken : "";
      const patientStoreData = patientStore();
      globalStore.isEventsLoading = true;

      await axios
        .post(
          `${basePath}/kps/compoundPerson`,
          {
            questioner_tc_identity_number: "39844419440",
            questioned_tc_identity_number: patientTcNo,
            username: "test_user2",
            password: "Twm=x5Gb",
          },
          {
            headers: {
              Authorization: `Bearer ${
                accessToken || personelStoreData.personel!.token
              }`,
            },
          }
        )
        .then((response) => {
          globalStore.isEventsLoading = false;
          if (response.status === 200) {
            // Check where the patient is from
            if (patientStoreData.patient) {
              const data = ref(response.data.message);
              if (response.data.message.TCVatandasiKisiKutukleri) {
                data.value = response.data.message.TCVatandasiKisiKutukleri;
                this.patientNationality = "T.C Vatandaşı";
              } else if (response.data.message.MaviKartliKisiKutukleri) {
                data.value = response.data.message.MaviKartliKisiKutukleri;
                this.patientNationality = "Mavi Kartlı Hasta";
              } else if (response.data.message.YabanciKisiKutukleri) {
                data.value = response.data.message.YabanciKisiKutukleri;
                this.patientNationality = "Yabancı Uyruklu";
              }
              console.log("Kps response : ", response.data.message);

              userInfo.tcNo =
                this.patientNationality == "T.C Vatandaşı"
                  ? data.value.KisiBilgisi.TCKimlikNo
                  : data.value.KisiBilgisi.KimlikNo;
              userInfo.userName = _.startCase(
                _.toLower(data.value.KisiBilgisi.TemelBilgisi.Ad)
              );
              userInfo.userSurname = _.startCase(
                _.toLower(data.value.KisiBilgisi.TemelBilgisi.Soyad)
              );
              userInfo.nameOfMother = _.startCase(
                _.toLower(data.value.KisiBilgisi.TemelBilgisi.AnneAd)
              );
              userInfo.nameOfFather = _.startCase(
                _.toLower(data.value.KisiBilgisi.TemelBilgisi.BabaAd)
              );
              userInfo.gender =
                data.value.KisiBilgisi.TemelBilgisi.Cinsiyet.Aciklama;
              userInfo.ilButtonDropDownSelectedValue =
                data.value.KisiBilgisi.TemelBilgisi.DogumYer;
              // userInfo.adresIlButtonDropDownSelectedValue =
              //   response.data.message.AdresBilgisi?.IlIlceMerkezAdresi?.IlKodu +
              //   "_" +
              //   response.data.message.AdresBilgisi?.IlIlceMerkezAdresi?.Il;
              // userInfo.adresIlceButtonDropDownSelectedValue =
              //   response.data.message.AdresBilgisi?.IlIlceMerkezAdresi?.IlKodu +
              //   "_" +
              //   response.data.message.AdresBilgisi?.IlIlceMerkezAdresi?.Ilce +
              //   "_" +
              //   response.data.message.AdresBilgisi?.IlIlceMerkezAdresi
              //     ?.IlceKodu;
              userInfo.maritalStatus =
                data.value.KisiBilgisi.DurumBilgisi.MedeniHal.Aciklama;
              userInfo.email = "";
              // userInfo.kpsYabanciUyrukluButtonDropDownSelectedValue =
              //   data.value.KisiBilgisi?.Ulke?.Kod +
              //   "_" +
              //   data.value.KisiBilgisi?.Ulke?.Aciklama;

              userInfo.adress = _.startCase(
                _.toLower(response.data.message.AdresBilgisi.AcikAdres)
              );
              userInfo.dateOfBirth = moment(
                new Date(
                  `${data.value.KisiBilgisi.TemelBilgisi.DogumTarih.Yil}-${data.value.KisiBilgisi.TemelBilgisi.DogumTarih.Ay}-${data.value.KisiBilgisi.TemelBilgisi.DogumTarih.Gun}`
                )
              ).format("MM/DD/YYYY");
              userInfo.ilButtonDropDownSelectedValue = _.startCase(
                _.toLower(data.value.KisiBilgisi.TemelBilgisi.DogumYer)
              );
            }
            console.log(
              "kps",
              userInfo.kpsYabanciUyrukluButtonDropDownSelectedValue
            );
          } else {
            console.log(
              "bilesikKisiActions.kpsBilesikKisiSorgulama. kpsBilesikKisiSorgulama not return 200. "
            );
          }
        })

        .catch((err) => {
          globalStore.isEventsLoading = false;

          console.log(
            "bilesikKisiActions.kpsBilesikKisiSorgulama. kpsBilesikKisiSorgulama catch error. error : ",
            err
          );
        });
    },
  },
});
