import { NavigationGuardNext } from "vue-router";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions";
import { patientAuthActions } from "@/store/modules/administratorManagementStore/patientAuth/patientAuthActions";
import { ref } from "vue";
import { patientStore } from "@/store/modules/administratorManagementStore/patient";
const isDevMode = process.env.NODE_ENV;

// const requirePatientAuth = async (
//   to: any,
//   from: any,
//   next: NavigationGuardNext
// ) => {
//   const patientStoreData = patientStore();
//   const patientStoreAuthActions = patientAuthActions();
//   if (isDevMode !== "development") {
//     // if it's development mode,then move to other pages without authotenticaiton
//     next();
//     console.log(isDevMode === "development");
//   } else {
//     await patientStoreAuthActions.checkPatientSessionToken();

//     if (patientStoreData.patientSessionActiveToken) {
//       console.log(
//         "patientStoreData.patientSessionActiveToken true. Session is active"
//       );
//       next();
//     } else {
//       next("/patientLogin"); // if there is no user, redirect to the login page
//       console.log(
//         "patientStoreData.patientSessionActiveToken false. Session is deactive"
//       );
//     }
//   }
// };

const requirePatientAuth = async (
  to: any,
  from: any,
  next: NavigationGuardNext
) => {
  if(isDevMode !== "production"){
    next()

  }
  const personelStoreAuthActions = personelAuthActions();
  const personelStoreData = personelStore();

  await personelStoreAuthActions.checkSessionToken();
  if (personelStoreData.personel!.token) {
    console.log(
      "personelStoreData.personelSessionActiveToken true. Session is active"
    );
    next();
  } else {
    console.log(
      "personelStoreData.personelSessionActiveToken false. Session is deactive"
    );
    next("/patientLogin");
  }
};

const requirePersonelAuth = async (
  to: any,
  from: any,
  next: NavigationGuardNext
) => {
  if(isDevMode !== "production"){
    next()

  }
  const personelStoreAuthActions = personelAuthActions();
  const personelStoreData = personelStore();

  await personelStoreAuthActions.checkSessionToken();
  if (personelStoreData.personel!.token) {
    console.log(
      "personelStoreData.personelSessionActiveToken true. Session is active"
    );
    next();
  } else {
    console.log(
      "personelStoreData.personelSessionActiveToken false. Session is deactive"
    );
    next("/personel/loginPage");
  }
};

export { requirePatientAuth, requirePersonelAuth };
