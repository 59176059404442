<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">SİPARİŞ ID</th>
        <th class="text-xl w-[200px] text-center">HASTA ADI</th>
        <th class="text-xl w-[200px] text-center">TEDAVİ ADI</th>
        <th class="text-xl w-[200px] text-center">KURUM ADI</th>
        <th class="text-xl w-[350px] text-center">OLUŞTURULMA TARİHİ</th>
        <th class="text-xl w-[200px] text-center">TESLİM TARİHİ</th>
        <th class="text-xl w-[450px] text-center">TEKNİSYEN</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(order, index) in filteredOrders.slice(
          (currentPage - 1) * 7,
          currentPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px]"
        :class="index === activeIndex ? 'bg-[#f4f8fc]' : 'bg-white'"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
            class="bg-white mt-1"
          />
        </td>
        <td class="w-[200px] text-center">
          {{ order.siparisID }}
        </td>
        <td class="w-[200px] text-center">
          {{ order.hastaAdi }}
        </td>
        <td class="w-[200px] text-center">
          {{ order.tedaviAdi }}
        </td>
        <td class="w-[200px] text-center">
          {{ order.kurumAdi }}
        </td>
        <td class="w-[450px] text-center">
          <p>
            {{ order.olusturulmaTarihi }}
          </p>
        </td>
        <td class="w-[200px] text-center">
          {{ order.teslimTarihi }}
        </td>
        <td
          class="w-[350px] text-center flex justify-center items-center gap-3 mt-1"
        >
          <img :src="userIcon" alt="userIcon" />
          <p
            class="text-[#5CB85C] border-[1px] border-[#5CB85C] py-1 px-5 w-fit rounded ml-4"
          >
            {{ order.teknisyen }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from "vue";
import {
  tempOrdersData,
  workOrdersPopUpManagement,
} from "@/modules/protez/store/temporaryProtezData.js";
import userIcon from "@/modules/protez/assets/userIcon.svg";
defineProps(["currentPage"]);

const activeIndex = ref(null);
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
    workOrdersPopUpManagement.getProductionPopUp = false;
  } else {
    activeIndex.value = index;
    workOrdersPopUpManagement.getProductionPopUp = true;
  }
};
const filteredOrders = tempOrdersData.filter((order) => {
  return order.teknisyen;
});
</script>
