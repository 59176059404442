import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router/index";
import { globalStore } from "@/store/modules/globalStore";
import { Patient } from "@/types/user/Patient";
import moment from "moment";
import { patientStore } from "../patient";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";

const basePath = "https://api.dentaltest.xyz";
export const patientAuthActions = defineStore({
  id: "patientActions",
  actions: {
    async login(patient: Patient) {
      const patientStoreData = patientStore();
      patientStoreData.patient = patient;
      await axios
        .post(
          `${basePath}/patients/login/otp`,
          {
            phone_number: `0${patientStoreData.patient.phoneNumber}`,
            otp: patientStoreData.patient.code,
          },
          {
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImY5NDFlNmQ0LWM1NjMtNGNiYS05ODg0LTcyMzk4NmU0OGJkNCIsInVzZXJJRCI6ImM1NTlmMzMzLWQ1ZmItNDM5MS05ZDkwLWJjYTEzY2Q2Y2IyNiIsInVzZXJuYW1lIjoiYW9rdGVtIiwicm9sZSI6Iml0LXN0YWZmIiwiaXNzdWVkX2F0IjoiMjAyNC0wNS0xNlQxMTo1OTozNC45MTY4ODUwNDNaIiwiZXhwaXJlZF9hdCI6IjIwMjQtMDUtMTdUMTE6NTk6MzQuOTE2ODg2MjU1WiJ9.D5QywTjWRL4eaJnPuMKyDzBgsd14n7Ggbi1OMmIpKv8",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (patientStoreData.patient) {
              console.log(
                "patientAuthActions.login  Patient login successful.",
                response
              );
              patientStoreData.patientSessionActiveToken = true;
              patientStoreData.patient.accessToken = response.data.access_token;
              // patientStoreData.patient.patientID
              // patientStoreData.patient.tcIdentityNumber
            }
          } else {
            alert("giriş başarısız");
            console.log("patientAuthActions.login  Patient not exist error. ");
          }
        })
        .finally(() => {
          router.push("/appointment/create-appointment");
        })
        .catch((err) => {
          console.log("patientAuthActions.login Api Catch Error : ", err);
          alert("Giriş yaparken bir hata oluştu.");
          router.push("/patientLogin");
        });
    },

    async logout() {
      const patientStoreData = patientStore();
      localStorage.removeItem("patientStoreData");
      patientStore().patient!.accessToken = "";
      router.push("/landing");
    },
    async checkPatientSessionToken() {
      const patientStoreData = patientStore();
      const unitListStore = useGetUnitsList();
      // getUnitsList isteği at 401 dönerse patientSessionActiveToken false olacak
      await unitListStore.getUnitsList(1);
      console.log(
        "checkPatientSessionToken",
        unitListStore.getListUnitsResponse
      );
      if (unitListStore.getListUnitsResponse) {
        patientStoreData.patientSessionActiveToken = true;
      } else {
        patientStoreData.patientSessionActiveToken = false;
      }
    },
  },
});
