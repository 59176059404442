<template>
  <div
    class="bg-[#f5f6f9] py-10 flex-grow flex flex-col items-center gap-14 overflow-auto"
  >
    <div
      v-for="(section, id) in anamnesisTemplateStore.getAnamnesisResponse?.data
        .attributes.sections"
      :key="id"
    >
      <div
        class="rounded-t-[5px] max-w-[1600px] overflow-auto mx-auto flex-grow-0 bg-[#287dd2] text-white w-fit py-2 px-16 text-lg font-medium text-center mb-8"
      >
        {{ section.section_name }}
      </div>
      <div class="flex flex-col gap-4">
        <div v-for="(formElement, index) in section.form_elements" :key="index">
          <component
            :is="renderQuestion(formElement.type)"
            :index="index"
            :formElement="formElement"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import singleChoiceQuestion from "@/modules/anamnezForm/components/readAnamnesisView/questionTypes/singleChoiceQuestion.vue";
import multipleChoiceQuestion from "@/modules/anamnezForm/components/readAnamnesisView/questionTypes/multipleChoiceQuestion.vue";
import shortAnswerQuestion from "@/modules/anamnezForm/components/readAnamnesisView/questionTypes/shortAnswerQuestion.vue";
import multipleChoiceTableQuestion from "@/modules/anamnezForm/components/readAnamnesisView/questionTypes/multipleChoiceTableQuestion.vue";
import linearScaleQuestion from "@/modules/anamnezForm/components/readAnamnesisView/questionTypes/linearScaleQuestion.vue";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();
import { useAnamnesisTemplate } from "@/store/modules/operationsStore/anamnesisTemplates/anamnesisTemplate";
const anamnesisTemplateStore = useAnamnesisTemplate();

const renderQuestion = (question) => {
  const components = {
    singleChoice: singleChoiceQuestion,
    multipleChoice: multipleChoiceQuestion,
    multipleChoiceTable: multipleChoiceTableQuestion,
    shortAnswer: shortAnswerQuestion,
    linearScale: linearScaleQuestion,
  };
  return components[question] || null;
};
</script>
