import { defineStore } from "pinia";

export const useAnnouncements = defineStore("announcementsStore", {
  id: "announcementsStore",
  state: () => ({
    isSidebarOpen: true,
    sidebarTitle: 0,
    navbarTitle: 0,
    contents: [],
  }),
});
