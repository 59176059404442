<template>
  <div
    class="flex flex-col h-full overflow-auto min-h-[500px] bg-white mt-[32px]"
  >
    <div class="flex my-[20px]">
      <p class="ml-[17px] font-semibold text-[#252525]">
        Performans Detay Tablosu
      </p>
      <Menu as="div" class="relative inline-block text-left ml-auto mr-[12px]">
        <div>
          <MenuButton
            class="inline-flex gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-[#252525B2] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-[122px] min-h-[28px] max-h-[28px] items-center justify-between"
          >
            <!-- <p class="text-black/50 ml-2">Ay</p> -->
            <div class="flex items-center">
              <p class="mx-2">
                {{ ekOdemeStore.monthFilterValue }}
              </p>
            </div>
            <img :src="arrowDownBlue" alt="arrow" class="mr-2 h-[6px]" />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem
                v-for="(month, index) in monthsOfTheYearInTurkish"
                :key="index"
                v-slot="{ active }"
              >
                <div
                  @click="ekOdemeStore.monthFilterValue = month"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  class="cursor-pointer"
                >
                  {{ month }}
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <button class="mr-[30px]">
        <img :src="cloudDownload" alt="" />
      </button>
    </div>
    <div class="flex" style="width: 100%">
      <div class="flex w-full flex-col">
        <table>
          <thead>
            <tr
              class="bg-[#5265811A] rounded-[5px] h-[38px] min-h-[38px] w-[90%] mx-auto border-y-[1px]"
            >
              <th>Birim</th>
              <th>Puan</th>
              <th>Gelire Dönüşen</th>
              <th>Mesai İçi Puan</th>
              <th>Mesai İçi Gelire Dönüşen</th>
              <th>Mesai Dışı Puan</th>
              <th>Mesai Dışı Gelire Dönüşen</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                patient, index
              ) in ekOdemeStore.temporaryDataForEkOdemeTable"
              :key="index"
              class="table-element border-y-[1px]"
            >
              <td>
                <div class="flex flex-col items-center justify-center">
                  <div
                    class="flex items-center justify-start w-[120px] h-[32px] rounded-[5px]"
                  >
                    <div class="unitDot w-[9px] h-[9px] rounded-full"></div>
                    <p
                      class="font-medium flex items-center justify-center mt-[1px] ml-1 text-[#000000B2]"
                    >
                      {{ getColorOfUnit(patient.birim) }}
                      {{ patient.birim }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-col justify-center items-center">
                  <div class="h-[47px] flex items-center justify-center">
                    {{ patient.puan }}
                  </div>
                </div>
              </td>

              <td>
                <div class="flex flex-col">
                  <div class="h-[47px] flex items-center justify-center">
                    {{ patient.gelireDonusen }}
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <div class="h-[47px] flex items-center justify-center">
                    {{ patient.mesaiIciPuan }}
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <div class="h-[47px] flex items-center justify-center">
                    {{ patient.mesaiIciGelireDonusen }}
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <div class="h-[47px] flex items-center justify-center">
                    {{ patient.mesaiDisiPuan }}
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <div class="h-[47px] flex items-center justify-center">
                    {{ patient.mesaiDisiGelireDonusen }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import { ref } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import arrowDownBlue from "@/modules/klinikYonetim/assets/arrow-down-blue.svg";
import cloudDownload from "@/modules/ekOdeme/assets/cloudDownload.svg";
import { useEkOdemeStore } from "../store/ekOdemeStore.js";
const ekOdemeStore = useEkOdemeStore();
const unitColorRef = ref("#000");
const monthsOfTheYearInTurkish = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];
const getColorOfUnit = (birim) => {
  switch (birim) {
    case "Endodonti":
      unitColorRef.value = "#F0AD4E";
      break;
    case "Periodontoloji":
      unitColorRef.value = "#5BC0DE";
      break;
    case "Pedodonti":
      unitColorRef.value = "#DE5BC1";
      break;
    case "Radyoloji":
      unitColorRef.value = "#F65D58";
      break;
    case "Restoratif":
      unitColorRef.value = "#6498FD";
      break;
    case "Oral Diagnoz":
      unitColorRef.value = "#8BB8B0";
      break;
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 0.5rem;
  text-align: center;
}
th {
  color: #526581;
  font-family: "Inter", sans-serif;
  font: 500 16px/17px Inter;
}
tr {
  height: 47px;
  max-height: 47px;
  color: #252525;
  font-family: "Inter", sans-serif;
  font: 500 16px/17px Inter;
}
tr:not(:last-child) td {
  border-bottom: 1px solid #d5dbe1; /* Sadece satırlar arasına border ekleme */
}

.unitText {
  color: v-bind("unitColorRef");
  opacity: 1;
}
.unitBG {
  border-color: v-bind("unitColorRef");
  background-color: fade(v-bind("unitColorRef"), 50%);
}
.unitDot {
  background-color: v-bind("unitColorRef");
  opacity: 1;
}
</style>
