<template>
  <div
    class="mx-auto w-[850px] bg-white py-2 border-2 border-l-[10px] border-l-[#287dd2] rounded-[10px] h-fit"
  >
    <div class="pl-6 flex flex-col">
      <input
        class="font-medium text-lg w-full mb-3 bg-transparent"
        type="text"
        placeholder="Sorunuzu giriniz..."
        v-model="
          anamnesisStore.sections[anamnesisStore.activeSection].formElements[
            props.index
          ].question
        "
      />
      <div class="flex flex-col gap-2 text-sm mb-2">
        <div class="flex gap-4">
          <select
            v-model="
              anamnesisStore.sections[anamnesisStore.activeSection]
                .formElements[props.index].scaleMin
            "
            class="text-[#277dd2]"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <select
            v-model="
              anamnesisStore.sections[anamnesisStore.activeSection]
                .formElements[props.index].scaleMax
            "
            class="text-[#277dd2]"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div class="flex items-center gap-2">
          <p>1</p>
          <input
            class="w-[160px] bg-transparent"
            type="text"
            v-model="
              anamnesisStore.sections[anamnesisStore.activeSection]
                .formElements[props.index].minPlaceholder
            "
            placeholder="Etiket (isteğe bağlı)"
          />
        </div>
        <div class="flex items-center gap-2">
          <p>2</p>
          <input
            class="w-[160px] bg-transparent"
            type="text"
            v-model="
              anamnesisStore.sections[anamnesisStore.activeSection]
                .formElements[props.index].maxPlaceholder
            "
            placeholder="Etiket (isteğe bağlı)"
          />
        </div>
      </div>
    </div>
    <div class="border-t flex">
      <div class="px-2 pt-2 flex divide-x-2 ml-auto items-center">
        <div class="pr-4 flex gap-3">
          <button @click="cloneFormElement">
            <img class="w-6 h-6 my-auto" :src="filesGray" alt="" />
          </button>
          <button @click="removeFormElement">
            <img class="w-6 h-6 my-auto" :src="trashBinGray" alt="" />
          </button>
        </div>
        <div class="pl-4 flex gap-5 items-center">
          <p class="text-lg">Gerekli</p>
          <button
            class="flex h-6 w-12 items-center rounded-xl p-1 duration-300 ease-in-out"
            :class="
              anamnesisStore.sections[anamnesisStore.activeSection]
                .formElements[props.index].isRequired
                ? 'bg-[#026D4D]'
                : 'bg-gray-300'
            "
            @click="toggleIsRequired()"
          >
            <div
              class="h-5 w-5 transform rounded-xl bg-white shadow-md duration-300 ease-in-out"
              :class="{
                'translate-x-5':
                  anamnesisStore.sections[anamnesisStore.activeSection]
                    .formElements[props.index].isRequired,
              }"
            ></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import trashBinGray from "@/modules/anamnezForm/assets/trashBinGray.svg";
import filesGray from "@/modules/anamnezForm/assets/filesGray.svg";
import { useAnamnesistore } from "@/modules/anamnezForm/store/anamnesisStore";
const anamnesisStore = useAnamnesistore();

const props = defineProps({
  index: Number,
});

const removeFormElement = () => {
  anamnesisStore.sections[anamnesisStore.activeSection].formElements.splice(
    props.index,
    1
  );
};

const cloneFormElement = () => {
  const clonedFormElement = JSON.parse(
    JSON.stringify(
      anamnesisStore.sections[anamnesisStore.activeSection].formElements[
        props.index
      ]
    )
  );
  anamnesisStore.sections[anamnesisStore.activeSection].formElements.splice(
    props.index + 1,
    0,
    clonedFormElement
  );
};

const toggleIsRequired = () => {
  anamnesisStore.sections[anamnesisStore.activeSection].formElements[
    props.index
  ].isRequired =
    !anamnesisStore.sections[anamnesisStore.activeSection].formElements[
      props.index
    ].isRequired;
};
</script>
