<template>
  <table>
    <thead class="bg-[#F0F2F5]">
      <tr>
        <th class="w-6 p-2">
          <input type="checkbox" class="bg-white mt-1" />
        </th>
        <th class="text-xl w-[200px] text-center">SİPARİŞ ID</th>
        <th class="text-xl w-[200px] text-center">DURUM</th>
        <th class="text-xl w-[250px] text-center">HASTA ADI</th>
        <th class="text-xl w-[250px] text-center">TC</th>
        <th class="text-xl w-[250px] text-center">TEDAVİ ADI</th>
        <th class="text-xl w-[200px] text-center">KURUM ADI</th>
        <th class="text-xl w-[350px] text-center">OLUŞTURULMA TARİHİ</th>
        <!-- v-if="!popUpManagement.detailPopUp" -->
        <div class="my-auto w-12">
          <th></th>
        </div>
        <!-- <div v-if="popUpManagement.detailPopUp" class="my-auto">
            <th></th>
          </div> -->
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(order, index) in filteredOrders.slice(
          (currentOrderPage - 1) * 7,
          currentOrderPage * 7
        )"
        :key="index"
        class="table-element border-y-[1px] bg-white"
      >
        <td class="w-6 pl-2 py-2">
          <input
            type="checkbox"
            @click="handleCheckboxClick(index)"
            :checked="activeIndex === index"
            class="bg-white mt-1"
          />
        </td>
        <td
          class="w-[200px] text-center relative"
          :class="order.dijitalOlcu ? 'text-[#b2533e]' : 'text-black'"
        >
          {{ order.siparisID }}
        </td>
        <td class="w-[200px] text-center relative">
          <div class="py-1" :class="getColorOfStatus(order.olcuDurumu)">
            {{ order.olcuDurumu }}
          </div>
        </td>
        <td class="w-[250px] text-center">
          {{ order.hastaAdi }}
        </td>

        <td class="w-[250px] text-center">
          <p>
            {{ order.tcNo }}
          </p>
        </td>
        <td class="w-[250px] text-center">
          {{ order.tedaviAdi }}
        </td>

        <td class="w-[200px] text-center">
          {{ order.kurumAdi }}
        </td>
        <td class="w-[350px] text-center">
          {{ order.olusturulmaTarihi }}
        </td>
        <div>
          <td>
            <div
              class="cursor-pointer mt-2 w-fit px-2"
              @click="
                ordersPopUpManagement.detailPopUp = true;
                activeIndex = index;
              "
            >
              <img :src="moreDotsIcon" alt="moreDots" />
            </div>
          </td>
        </div>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from "vue";
import {
  tempData,
  tempOrdersData,
  popUpManagement,
  ordersPopUpManagement,
} from "@/modules/protez/store/temporaryProtezData.js";
import moreDotsIcon from "@/modules/protez/assets/moreDots.svg";
const activeIndex = ref(null);
defineProps(["currentOrderPage"]);
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
    ordersPopUpManagement.nonProcessEditPopUp = false;
  } else {
    activeIndex.value = index;
    ordersPopUpManagement.nonProcessEditPopUp = true;
  }
};

const getColorOfStatus = (status) => {
  const statusLower = status.toLowerCase();
  console.log(statusLower);
  switch (statusLower) {
    case "iptal edildi":
      return "bg-[#fceae6] text-[#e12a06]";
    case "ölçü yenilemede":
      return "bg-[#f1e8f7] text-[#6e1fa7]";
    case "ölçü geldi":
      return "bg-[#e6f8eb] text-[#30b836]";
    case "ölçü bekleniyor":
      return "bg-[#f7f2e8] text-[#a67907]";
  }
};

const filteredOrders = tempOrdersData.filter(
  (order) => order.durum === "Işleme alınmamış"
);
</script>
