import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { authToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";

type SUTApiParams = {
  Kodu?: string;
  Adi?: string;
};
type SUTReferenceParams = {
  SUTCode?: string;
  SUTName?: string;
};

export const useSearchSUTReferences = defineStore({
  id: "useSearchSUTReferences",
  state: () => ({
    sutCode: <SUTReferenceParams>{} || null,
  }),
  actions: {
    async searchSUTReferences<SUTApiParams>(SUTParams: SUTApiParams) {
      await axios
        .post(`${adminDomain}/references/sut/search`, SUTParams, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.sutCode = response.data.SUT_codes;
          }
        })
        .catch((error) => {
          console.log("Search SUT References", error);
        });
    },
  },
});
