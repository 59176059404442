<template>
  <div class="flex flex-col gap-4">
    <div class="bg-[#f7f7f7] p-2 w-full text-[#263445] text-center font-medium">
      {{
        pastActivitiesStore.detailScreen ? "MUAYENE DETAY" : "GEÇMİŞ İŞLEMLER"
      }}
    </div>

    <pastActivitiesNav v-if="!pastActivitiesStore.detailScreen" />
    <examinationDetailsNav v-if="pastActivitiesStore.detailScreen" />

    <pastActivitiesFilter v-if="!pastActivitiesStore.detailScreen" />

    <!-- past activities details -->
    <div v-if="!pastActivitiesStore.detailScreen">
      <appointmentInfosTable
        v-if="pastActivitiesStore.pastActivitiesNav === 1"
      />
      <examinationInfosTable
        v-else-if="pastActivitiesStore.pastActivitiesNav === 2"
      />
    </div>

    <!-- examination details tables -->
    <div v-else-if="pastActivitiesStore.detailScreen">
      <pastServicesTable
        v-if="pastActivitiesStore.examinationDetailsNav === 1"
      />
      <radiologyInfosTable
        v-else-if="pastActivitiesStore.examinationDetailsNav === 2"
      />
      <prescriptionTable
        v-else-if="pastActivitiesStore.examinationDetailsNav === 3"
      />
      <anamnezSection
        v-else-if="pastActivitiesStore.examinationDetailsNav === 4"
      />
    </div>
  </div>
</template>

<script setup>
import appointmentInfosTable from "./pastActivitiesSubs/appointmentInfosTable.vue";
import examinationInfosTable from "./pastActivitiesSubs/examinationInfosTable.vue";
import { usePastActivitiesStore } from "@/modules/doctorScreen/store/pastActivitiesStore.js";
import pastActivitiesNav from "./pastActivitiesSubs/pastActivitiesNav.vue";
import examinationDetailsNav from "./pastActivitiesSubs/examinationDetailsNav.vue";
import pastActivitiesFilter from "./pastActivitiesSubs/pastActivitiesFilter.vue";
import pastServicesTable from "./pastActivitiesSubs/detailTables/pastServicesTable.vue";
import radiologyInfosTable from "./pastActivitiesSubs/detailTables/radiologyInfosTable.vue";
import prescriptionTable from "./pastActivitiesSubs/detailTables/prescriptionTable.vue";
import anamnezSection from "./pastActivitiesSubs/detailTables/anamnezSection.vue";
const pastActivitiesStore = usePastActivitiesStore();
</script>

<style>
.appointmentTypeSelectComponent .select2-selection {
  border-radius: 10px !important;
}

.appointmentTypeSelectComponent .select2-selection,
.appointmentTypeSelectComponent .select2-selection__rendered,
.appointmentTypeSelectComponent .select2-selection__arrow {
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 8px !important;
}

.appointmentTypeSelectComponent .select2-selection__arrow {
  right: 10px !important;
  top: -1px !important;
}

.appointmentTypeSelectComponent .select2-selection__arrow {
  right: 10px !important;
  top: -1px !important;
}
.appointmentTypeSelectComponent
  .select2-container--open
  .select2-selection__arrow {
  top: -9px !important;
}

.appointmentTypeSelectComponent
  .select2-container--open
  .select2-selection__arrow {
  top: -9px !important;
}

.appointmentTypeSelectComponent .select2-selection__arrow b {
  border-width: 8px 6px 8px 6px !important;
  /* top: 7px !important; */
}

.appointmentTypeSelectComponent .select2-selection__arrow b {
  border-width: 8px 6px 8px 6px !important;
  /* top: 7px !important; */
}
</style>
