<template>
  <div class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col mb-3">
    <div
      class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
    >
      <div class="h-[80%] w-[20%] flex items-center">
        <p class="font-medium ml-4">KABUL NO</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">KABUL TARİHİ/SAATİ</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">PROVİZYON TİPİ</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">BİRİM</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">ANA TANI</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p class="font-medium">HEKİM</p>
      </div>
    </div>
    <div
      v-for="(examination, index) in [1]"
      class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
      :key="examination + index"
    >
      <div class="h-[80%] w-[20%] flex flex-col">
        <p class="ml-6">123456-1</p>
        <p class="text-sm opacity-80 ml-6">Muayene Tamamlandı</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center ml-12">
        <p>26 Eyl 2023</p>
        <p class="text-sm opacity-80">09:30</p>
      </div>
      <div
        class="h-[80%] w-[20%] flex flex-col justify-center items-center -ml-12"
      >
        <p>İş Kazası</p>
      </div>
      <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
        <p>Endodonti</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center text-center">
        <p class="font-semibold">k07.4 - Diş Ağrısı, Tanımlanmamış</p>
      </div>
      <div class="h-[80%] w-[20%] flex justify-center items-center">
        <p>Melisa ÇİÇEK</p>
      </div>
    </div>
  </div>
</template>
