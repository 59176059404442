import { ref } from "vue";

export const calendarStoreCalendarFilter = ref("Birim1");

export const slotID = ref("");
export const boolCountSearchSlotsForClinic = ref(true);
export const isSideBarDateSelectButtonOn = ref(false);

export const returnCalendarSlotClinicName = () => {
  switch (calendarStoreCalendarFilter.value) {
    case "Endodonti":
      return "138_ENDODONTI";
    case "Periodontoloji":
      return "174_PERIODONTOLOJI";
    case "Restoratif":
      return "179_RESTORATIF DIŞ TEDAVISI";
    case "Pedodonti":
      return "5360_PEDODONTOLOJİ";
    case "Radyoloji":
      return "104_AĞIZ, DIŞ VE ÇENE RADYOLOJISI";
    case "Oral Diagnoz":
      return "198_DIŞ HEKIMLIĞI (GENEL DIŞ)";
    case "Birim1":
      return "103_AĞIZ, DIŞ VE ÇENE CERRAHISI";
    default:
  }
};
