<template>
  <klinikYonetimUnitNavbar />
  <clinicDoctorsHome v-if="klinikYonetimStore.klinikYonetimNavbar === 1" />
  <appointmentHome v-if="klinikYonetimStore.klinikYonetimNavbar === 2" />
  <!-- <KlinikYonetimUnit v-if="klinikYonetimStore.klinikYonetimNavbar === 3" /> -->
  <KlinikYonetimUnitListViewComponent
    v-if="klinikYonetimStore.klinikYonetimNavbar === 3"
  />
  <cizelgeHome v-if="klinikYonetimStore.klinikYonetimNavbar === 4" />
</template>

<script setup>
import klinikYonetimUnitNavbar from "./klinikYonetimUnitNavbar.vue";
import clinicDoctorsHome from "./Hekim/clinicDoctorsHome.vue";
import klinikYonetimKlinik from "./Klinik/klinikYonetimKlinik.vue";
import klinikYonetimUnit from "./Unit/klinikYonetimUnit.vue";
import cizelgeHome from "./Cizelge/cizelgeHome.vue";
import { useKlinikYonetimStore } from "../store/klinikYonetim";
import appointmentHome from "./Randevu/appointmentHome.vue";
import KlinikYonetimUnit from "./Unit/klinikYonetimUnit.vue";
import KlinikYonetimUnitListViewComponent from "./Unit/klinikYonetimUnitListViewComponent.vue";
const klinikYonetimStore = useKlinikYonetimStore();
</script>
