import { defineStore } from "pinia";
import {
  UpdatePatientRequest,
  updatePatientRequest,
} from "@/types/operations/patient/updatePatientRequest";
import { UpdatePatientResponse } from "@/types/operations/patient/updatePatientResponse";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "../../administratorManagementStore/personel";

export const useUpdatePatientStore = defineStore({
  id: "updatePatient",
  state: () => ({}),
  actions: {
    async updatePatient(updatePatientReq: UpdatePatientRequest) {
      try {
        globalStore.customInfoCard = true;
        globalStore.customInfoType = "pending";
        const personelStoreData = personelStore();
        const response = await axios.put(
          `${clinicDomain}/patients`,
          updatePatientReq,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        );
        setTimeout(() => {
          globalStore.customInfoCard = false;
          globalStore.customInfoType = "";
        }, 2000);

        globalStore.customInfoType = "success";
        console.log("useUpdatePatientStore", response.data);
        // return response.data.data;
      } catch (error) {
        console.error("Error in useUpdatePatientStore:", error);
        setTimeout(() => {
          globalStore.customInfoCard = false;
          globalStore.customInfoType = "";
        }, 2000);

        globalStore.customInfoType = "fail";
      }
    },

    // async updatePatient(updatePatientReq: UpdatePatientRequest) {
    //   const store = useMedula();
    //   const getPatientStore = useGetPatientStore();
    //   const RegisteredPatientListStore = useRegisteredPatientListStore();
    //   globalStore.customInfoCard = true;
    //   globalStore.customInfoType = "pending";
    //   axios
    //     .put(
    //       `${clinicDomain}/patients`,
    //       {
    //         ...updatePatientReq,
    //       },
    //       {
    //         headers: {
    //           Authorization: patientAuthToken,
    //           //AcceptLanguage:--
    //         },
    //         withCredentials: true,
    //       }
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         setTimeout(() => {
    //           globalStore.customInfoCard = false;
    //           globalStore.customInfoType = "";
    //         }, 2000);

    //         globalStore.customInfoType = "success";
    //         // if (this.updatePatientResponse) {
    //         //   this.updatePatientResponse = response.data;
    //         // }
    //         // console.log("degistirdik", response.data);
    //         // if (screen === "provision") {
    //         //   getPatientStore.getPatient(
    //         //     store.updatingHasta.HastaKodu,
    //         //     "provision"
    //         //   );
    //         // } else if (screen === "registeredPatientList")
    //         //   getPatientStore.getPatient(
    //         //     RegisteredPatientListStore.updatingPatientCard.HastaKodu,
    //         //     "registeredPatientList"
    //         //   );
    //       }
    //     })
    //     .catch(() => {
    //       // if (screen === "provision") {
    //       //   getPatientStore.getPatient(
    //       //     store.updatingHasta.HastaKodu,
    //       //     "provision"
    //       //   );
    //       // } else if (screen === "registeredPatientList")
    //       //   getPatientStore.getPatient(
    //       //     RegisteredPatientListStore.updatingPatientCard.HastaKodu,
    //       //     "registeredPatientList"
    //       //   );
    //       setTimeout(() => {
    //         globalStore.customInfoCard = false;
    //         globalStore.customInfoType = "";
    //       }, 2000);

    //       globalStore.customInfoType = "fail";
    //     });
    // },
  },
});
