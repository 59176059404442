<template>
  <div class="h-[321px] w-[499px]">
    <table>
      <!-- Probing Depth -->
      <tr>
        <th
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in tableGingivalMargin"
          :key="index"
        >
          <div class="flex justify-around">
            <p class="font-light text-sm">{{ headerItem[0] }}</p>
            <p class="font-light text-sm">{{ headerItem[1] }}</p>
            <p class="font-light text-sm">{{ headerItem[2] }}</p>
          </div>
        </th>
      </tr>
      <!-- Probing Depth -->
      <tr>
        <th
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in tableGingivalMargin"
          :key="index"
        >
          <div class="flex justify-around">
            <p class="font-light text-sm">{{ headerItem[0] }}</p>
            <p class="font-light text-sm">{{ headerItem[1] }}</p>
            <p class="font-light text-sm">{{ headerItem[2] }}</p>
          </div>
        </th>
      </tr>
      <!-- Plaque -->
      <tr>
        <th
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in tableHeaderNums"
          :key="index"
        >
          <div class="flex">
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></div>
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></div>
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></div>
          </div>
        </th>
      </tr>
      <!-- Plaque -->
      <tr>
        <th
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in tableHeaderNums"
          :key="index"
        >
          <div class="flex">
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></div>
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></div>
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[14px] min-h-[14px] mx-auto"
            ></div>
          </div>
        </th>
      </tr>
      <!-- Plaque -->
      <tr>
        <th
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in tableHeaderNums"
          :key="index"
        >
          <div class="flex">
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[26px] min-h-[35px] mx-auto"
            ></div>
            <div
              class="rounded-[4px] bg-[#D9D9D980] flex items-center justify-center min-w-[14px] w-[26px] min-h-[35px] mx-auto"
            ></div>
          </div>
        </th>
      </tr>
      <!-- Plaque -->
      <tr>
        <th
          class="min-w-[62px] h-[40px]"
          v-for="(headerItem, index) in tableHeaderNums"
          :key="index"
        ></th>
      </tr>
    </table>
  </div>
</template>
<script setup>
const tableHeaderNums = props.tableHeaderNums;
const tableMobility = [0, 0, 0, 0, 0, 0, 0, 0];
const tableGingivalMargin = [
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
];

const props = defineProps({
  tableHeaderNums: Array,
  //   firstHastaIsmi: String,
});
</script>
<style scoped>
td,
th {
  border: 1px solid #b7b7b7;
  width: 61px;
  height: 40px;
}
th:last-child {
  border-radius: 5px;
}
</style>