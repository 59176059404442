import { defineStore } from "pinia";
import { examinations as examinationInitial } from "../dentalChartData/dentalChartData";
export const useDentalChart = defineStore("store", {
  id: "dentalChartStore",
  state: () => ({
    // Anamnez POPUP
    isAnanmnezPopUpOpen: false,
    dentalChartAnamnezPopUpNumber: 0,

    dentalChartSearch: "",
    dentalChartSearchFlow: [true],
    // New States Start
    dentalChartSideBarDiagnozTedaviPlanlama: 1,
    dentalChartSideBarMuayeneHizmet: 1,
    dentalChartSideBarDiagnozButton: 0,
    dentalChartSideBarMuayeneButton: 0,
    dentalChartSideBarHizmetButton: 0,
    dentalChartAnamnezButtonCard: false,
    dentalChartDiagnozSelectedButtonName: "",

    // Dental Chart AnamnezPopUp Variable
    dentalChartAnamnezPopUpIsOpen: false,

    // sut error, dis kaydi olusturulmayan error
    dentalChartSUTError: [true, false],
    dentalChartButtonNamesArray: [
      "Çürük",
      "Dolgu",
      "Kayıp",
      "Sağlam",
      "Sabit Protez",
      "Ortodontik Anomalı",
      "Periodontal Hastalık",
      "Hareketli Protez  - Bölümlü",
      "Hareketli Protez  - Total",
    ],

    // Big tooth states
    bigToothSelectedTeeth: [],
    bigToothInfoModalFlow: 0,
    bigToothInfoModalInModalFlow: 0,
    bigToothInfoModalTreats: [],
    bigToothActiveArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    // New States End
    dentalChartSelectedCheckbox: null,
    // Doctor Section Doctor Info
    dentalChartDoctorSectionDoctorInfo: [
      {
        tarih: "03/03/2002",
        alan: "46",
        kod: "401010",
        konum: "Ön",
        ucret: "50 TL",
        ucretTuru: "kamuSatisTarifesi",
        hizmetTuru: "Diş Hekimi Muayenesi",
        durum: "Planlandı",
      },
    ],
    dentalChartToothNumberCheckbox: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    dentalChartExaminationCheckbox: [
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
    ],
    dentalChartServicesCheckbox: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    dentalChartServicesDetailButtons: [
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false, false, false],
    ],
    dentalChartTeethNumberState: [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    dentalChartSelectedTeethNumber: [],
    selectedProtezTeeth: [],
  }),
  getters: {},
  actions: {
    setSearch(search) {
      this.state.dentalChartSearch = search;
    },

    setDoctorInfoSection(examinationInfo) {
      let isExaminationExist = false;
      this.dentalChartDoctorSectionDoctorInfo.forEach((examination) => {
        if (examination.hizmetTuru === examinationInfo.hizmetTuru) {
          isExaminationExist = true;
        }
      });
      if (isExaminationExist === false) {
        this.dentalChartDoctorSectionDoctorInfo.push(examinationInfo);
      }
    },

    setInitalCheckbox() {
      this.dentalChartDoctorSectionDoctorInfo.forEach((examination, index) => {
        if (
          examination.hizmetTuru + index ===
          examinationInitial[index].examinationName + index
        ) {
          // this.dentalChartExaminationCheckbox[0][1] = true;
        }
      });
    },

    getdentalChartTeethNumberStatePosition(position) {
      let TeethNumberStatePosition;
      let teethColumnNumber;
      switch (position) {
        case 18:
          TeethNumberStatePosition = 0;
          teethColumnNumber = 1;
          break;
        case 17:
          TeethNumberStatePosition = 1;
          teethColumnNumber = 2;

          break;
        case 16:
          TeethNumberStatePosition = 2;
          teethColumnNumber = 3;

          break;
        case 15:
          TeethNumberStatePosition = 3;
          teethColumnNumber = 4;

          break;
        case 14:
          TeethNumberStatePosition = 4;
          teethColumnNumber = 5;

          break;
        case 13:
          TeethNumberStatePosition = 5;
          teethColumnNumber = 6;

          break;
        case 12:
          TeethNumberStatePosition = 6;
          teethColumnNumber = 7;

          break;
        case 11:
          TeethNumberStatePosition = 7;
          teethColumnNumber = 8;

          break;
        case 21:
          TeethNumberStatePosition = 8;
          teethColumnNumber = 9;

          break;
        case 22:
          TeethNumberStatePosition = 9;
          teethColumnNumber = 10;

          break;
        case 23:
          TeethNumberStatePosition = 10;
          teethColumnNumber = 11;

          break;
        case 24:
          TeethNumberStatePosition = 11;
          teethColumnNumber = 12;

          break;
        case 25:
          TeethNumberStatePosition = 12;
          teethColumnNumber = 13;

          break;
        case 26:
          TeethNumberStatePosition = 13;
          teethColumnNumber = 14;

          break;
        case 27:
          TeethNumberStatePosition = 14;
          teethColumnNumber = 15;

          break;
        case 28:
          TeethNumberStatePosition = 15;
          teethColumnNumber = 16;

          break;
        case 48:
          TeethNumberStatePosition = 16;
          teethColumnNumber = 1;

          break;
        case 47:
          TeethNumberStatePosition = 17;
          teethColumnNumber = 2;

          break;
        case 46:
          TeethNumberStatePosition = 18;
          teethColumnNumber = 3;

          break;
        case 45:
          TeethNumberStatePosition = 19;
          teethColumnNumber = 4;

          break;
        case 44:
          TeethNumberStatePosition = 20;
          teethColumnNumber = 5;

          break;
        case 43:
          TeethNumberStatePosition = 21;
          teethColumnNumber = 6;

          break;
        case 42:
          TeethNumberStatePosition = 22;
          teethColumnNumber = 7;

          break;
        case 41:
          TeethNumberStatePosition = 23;
          teethColumnNumber = 8;

          break;
        case 31:
          TeethNumberStatePosition = 24;
          teethColumnNumber = 9;

          break;
        case 32:
          TeethNumberStatePosition = 25;
          teethColumnNumber = 10;

          break;
        case 33:
          TeethNumberStatePosition = 26;
          teethColumnNumber = 11;

          break;
        case 34:
          TeethNumberStatePosition = 27;
          teethColumnNumber = 12;

          break;
        case 35:
          TeethNumberStatePosition = 28;
          teethColumnNumber = 13;

          break;
        case 36:
          TeethNumberStatePosition = 29;
          teethColumnNumber = 14;

          break;
        case 37:
          TeethNumberStatePosition = 30;
          teethColumnNumber = 15;

          break;
        case 38:
          TeethNumberStatePosition = 31;
          teethColumnNumber = 16;

          break;
      }
      return [TeethNumberStatePosition, teethColumnNumber];
    },
  },
});
