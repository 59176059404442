<template>
  <div class="w-full rounded-full bg-white flex px-8">
    <div class="w-[550px] flex items-center gap-3">
      <appointmentListDateComponent
        v-if="appointmentBookStore.calendarViewNavigation === 1"
      />
      <!-- <VueDatePicker
        v-if="appointmentBookStore.calendarViewNavigation === 1"
        v-model="globalStore.appointmentStartTime"
        locale="tr"
        auto-apply
        format="dd/MM/yyyy"
        :enable-time-picker="false"
        :day-names="['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pa']"
        class="w-[155px]"
      /> -->
    </div>
    <div class="flex mx-auto">
      <button
        @click="appointmentBookStore.calendarViewNavigation = 1"
        class="p-3 text-xl focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
        :class="
          appointmentBookStore.calendarViewNavigation === 1
            ? 'text-[#277dd2] font-medium bg-[#277dd2] bg-opacity-10 border-b-2 border-b-[#277dd2]'
            : 'text-[#666666]'
        "
      >
        Liste
      </button>
      <button
        @click="appointmentBookStore.calendarViewNavigation = 2"
        class="p-3 text-xl focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
        :class="
          appointmentBookStore.calendarViewNavigation === 2
            ? 'text-[#277dd2] font-medium bg-[#277dd2] bg-opacity-10 border-b-2 border-b-[#277dd2]'
            : 'text-[#666666]'
        "
      >
        Haftalık
      </button>
      <button
        @click="appointmentBookStore.calendarViewNavigation = 3"
        class="p-3 text-xl focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
        :class="
          appointmentBookStore.calendarViewNavigation === 3
            ? 'text-[#277dd2] font-medium bg-[#277dd2] bg-opacity-10 border-b-2 border-b-[#277dd2]'
            : 'text-[#666666]'
        "
      >
        Aylık
      </button>
    </div>
    <div class="flex gap-5 items-center">
      <form
        class="flex gap-3 border h-10 my-auto bg-white py-1 pl-2 rounded-full text-black w-[350px] focus-within:outline-1 focus-within:outline-double focus-within:outline-[#277dd2]"
      >
        <img :src="searchLogo" alt="search" class="w-6" />
        <input
          type="text"
          class="w-10/12 outline-none py-2"
          placeholder="Hasta ya da Hekim ara"
        />
      </form>
      <button
        class="h-[40px] w-[57px] flex items-center justify-center border rounded-[20px]"
      >
        <img :src="device_printer" alt="" />
      </button>
      <button
        class="h-[40px] w-[57px] flex items-center justify-center border rounded-[20px]"
      >
        <img class="w-4" :src="plus" alt="" />
      </button>
      <button
        @click="appointmentBookStore.calendarViewNavigation = 4"
        class="h-[40px] w-[57px] flex items-center justify-center border rounded-[20px]"
        :class="
          appointmentBookStore.calendarViewNavigation === 4
            ? 'bg-[#277dd2]'
            : ''
        "
      >
        <img
          v-if="appointmentBookStore.calendarViewNavigation === 4"
          :src="settingIconWhite"
          alt=""
          class="w-6"
        />
        <img v-else :src="settings" alt="" class="w-6" />
      </button>
    </div>
  </div>
</template>

<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import { globalStore } from "@/store/modules/globalStore";
import appointmentListDateComponent from "@/modules/asistanEkrani/components/appointmentListDateComponent.vue";
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import { device_printer } from "@/modules/anamnezForm/assets";
import settings from "@/modules/personel/assets/settings.svg";
import settingIconWhite from "@/modules/personel/assets/settingIconWhite.svg";
import plus from "@/modules/asistanEkrani/assets/plusBlack.svg";
import { useAppointmentBook } from "@/modules/asistanEkrani/components/appointmentBookStore";
import { watch } from "vue";
watch(
  () => globalStore.appointmentStartTime,
  (newValue) => {
    if (appointmentBookStore.calendarViewNavigation !== 1) {
      return false;
    }
    console.log("ee", newValue);
    globalStore.appointmentEndTime = new Date(
      globalStore.appointmentStartTime.getTime() + 24 * 60 * 60 * 1000
    );
  }
);
const appointmentBookStore = useAppointmentBook();
</script>
