export const examinations = [
  {
    examinationNumber: "401010",
    examinationName: "Diş Hekimi Muayenesi",
    examinationNameInfo: " (Diş Tabibi)"
  },
  {
    examinationNumber: "401020",
    examinationName: "Konsültan Diş Hekim Muayenesi",
    examinationNameInfo: " (Diş Tabibi)"
  },
  {
    examinationNumber: "401051",
    examinationName: "Diş Röntgen Filmi  Adedi",
    examinationNameInfo: " (Periapikal)"
  },
  {
    examinationNumber: "401060",
    examinationName: "Oklüzal Film",
    examinationNameInfo: "(Adedi)"
  },
  {
    examinationNumber: "401070",
    examinationName: "Ekstra Oral Röntgen Filmi",
    examinationNameInfo: " (Adedi)"
  },
];


export const services = [
  {
    serviceNumber: "404181",
    serviceName: "Venner Kron"
  },
  {
    serviceNumber: "402020",
    serviceName: "Amalgam Dolgu",
    serviceNameInfo: "(İki Yüzlü)"
  },
  {
    serviceNumber: "402030",
    serviceName: "Amalgam Dolgu",
    serviceNameInfo: "(Üç Yüzlü)"
  },
  {
    serviceNumber: "402031",
    serviceName: "Amalgam Dolgu",
    serviceNameInfo: "(Üç Yüzlü)"
  },
  {
    serviceNumber: "402032",
    serviceName: "İnley Dolgu - Oklüzal"
  },
  {
    serviceNumber: "402050",
    serviceName: "İnley Dolgu"
  },
  {
    serviceNumber: "402033",
    serviceName: "İnley Dolgu ",
    serviceNameInfo: "(İki Yüzlü)"
  },
  {
    serviceNumber: "402034",
    serviceName: "İnley Dolgu",
    serviceNameInfo: "(Üç Yüzlü)"
  },

];
export const serviceDetail = [
  {
    serviceDetailNumber: "S404.430",
    serviceDetailName: "Cad-Cam Uygulaması"
  },
  {
    serviceDetailNumber: "404.181.5",
    serviceDetailName: "Dentin Prova"
  },
  {
    serviceDetailNumber: "404.181.4",
    serviceDetailName: "Metal Prova"
  },
  {
    serviceDetailNumber: "404.181.2",
    serviceDetailName: "Anatomik Ölçü"
  },
  {
    serviceDetailNumber: "404.181",
    serviceDetailName: "Venner Kron(Seramik)"
  },
  {
    serviceDetailNumber: "404.181.2",
    serviceDetailName: "Katılım Payı"
  }
  


];
export const dentalChartDoctoreSectionDoctorInfo=[
  {
    tarih:'03/03/2002',
    alan:'46',
    kod:'401010',
    konum:'Ön',
    ucret:'50 TL',
    ucretTuru:'kamuSatisTarifesi',
    hizmetTuru:'Diş Hekimi Muayenesi (Diş Tabibi)',
    durum:'Planlandı'
  }
]


export const dentalChartTeeth=[
  
]
