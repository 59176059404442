<template>
  <div
    class="z-50 rounded-2xl py-3 px-12 bg-[#e4e4e4] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
  >
    <p class="font-semibold text-lg">Fotoğraf</p>
    <div
      class="flex flex-col justify-center items-center p-3 rounded-2xl bg-white w-[800px] mt-10"
    >
      <button
        @click="openFileSelector"
        class="focus:outline-none px-5 py-3 rounded-full bg-[#e4ecf5]"
      >
        <img :src="upload" alt="upload" />
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          @change="handleFileSelect"
        />
      </button>
      <div
        @dragover.prevent
        @drop="handleFileDrop"
        class="w-full h-32 mt-4 border-2 border-gray-400 border-dashed flex justify-center items-center"
      >
        <p class="text-lg">
          Dosya yüklemek için ya da buraya bırakmak için
          <span @click="openFileSelector" class="text-[#277DD2] cursor-pointer"
            >tıklayınız
          </span>
        </p>
      </div>
      <div class="w-full flex gap-6 mt-4">
        <p>Dosya1</p>
        <p>Dosya2</p>
        <p>Dosya3</p>
      </div>
    </div>
    <div class="w-full mt-8 flex justify-center items-center gap-8 mb-2">
      <button
        @click="
          popUpManagement.uploadPopUp = false;
          ordersPopUpManagement.uploadPopUp = false;
        "
        class="focus:outline-none py-2 px-4 border-[1px] border-black rounded-full"
      >
        Vazgeç</button
      ><button
        @click="save"
        class="focus:outline-none py-2 px-4 border-[1px] border-[#277DD2] rounded-full text-[#277DD2]"
      >
        Kaydet
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  popUpManagement,
  ordersPopUpManagement,
} from "../store/temporaryProtezData.js";
import upload from "../assets/upload.svg";
const fileInput = ref(null);

const save = () => {
  console.log(fileInput.value.name);
};

const openFileSelector = () => {
  // Dosya seçme işlemini tetikle
  if (fileInput.value) {
    fileInput.value.click();
  }
};

const handleFileSelect = (event) => {
  const selectedFile = event.target.files[0];
  fileInput.value = selectedFile;
  if (selectedFile) {
    // Seçilen dosya ile ilgili işlemleri burada yapabilirsiniz.
    // Örnek olarak dosyanın adını konsola yazdırabiliriz.
    console.log("Seçilen Dosya:", selectedFile.name);
  }
};

const handleFileDrop = (event) => {
  event.preventDefault();
  const selectedFile = event.dataTransfer.files[0];
  fileInput.value = selectedFile;
  if (selectedFile) {
    // Sürüklenen dosya ile ilgili işlemleri burada yapabilirsiniz.
    console.log("Sürüklenen Dosya:", selectedFile.name);
  }
};
</script>
