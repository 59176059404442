<template>
  <div class="flex w-full">
    <appointmentViewSideBar :activePage="7" />
    <div class="w-full bg-[#fbfbfb] flex flex-col">
      <AppointmentViewNavBar />
      <main class="p-4">
        <div
          class="px-2 py-[2px] rounded bg-[#277dd2] text-white flex items-center gap-2 w-fit"
        >
          <img :src="patientProfile" alt="" />
          <span class="mt-[2px]">Hasta Profil</span>
        </div>
        <div
          class="bg-white text-[#464E5F] h-fit shadow px-4 py-10 flex flex-wrap justify-center sm:justify-stretch gap-10 sm:gap-20"
        >
          <div>
            <div
              class="w-full sm:w-[330px] p-3 flex flex-col gap-4 rounded-lg bg-[#f4f8fc] text-[#277dd2] border-[1px] border-[#277dd2]"
            >
              <h4 class="font-medium">
                {{ appointmentStore.patient?.attributes.name }}
                {{ appointmentStore.patient?.attributes.surname }}
              </h4>
              <div class="flex gap-4">
                <span class="font-medium">TC</span>
                <span>{{
                  appointmentStore.patient?.attributes.tc_identity_number
                }}</span>
              </div>
              <div class="w-full h-[1px] bg-[#277dd2] mb-2"></div>
            </div>
          </div>
          <div>
            <div
              class="w-full sm:w-[330px] flex flex-col gap-4 text-[#373737] border py-7 px-4 rounded-lg"
            >
              <div class="flex flex-col gap-2">
                <label for="mail" class="font-medium">E-mail</label>
                <div class="flex sm:gap-4 items-center">
                  <input
                    type="text"
                    id="mail"
                    class="flex-grow text-[#737373]"
                    v-model="mailInput"
                    disabled
                  />
                  <button><img :src="editPen" alt="edit" /></button>
                </div>
                <div class="w-full h-[1px] bg-[#D9D9D9]"></div>
              </div>

              <div class="flex flex-col gap-2">
                <label for="no" class="font-medium">Telefon Numarası</label>
                <div class="flex sm:gap-4 items-center">
                  <input
                    type="text"
                    id="no"
                    class="flex-grow text-[#737373]"
                    v-model="phoneInput"
                    disabled
                  />
                  <button><img :src="editPen" alt="edit" /></button>
                </div>
                <div class="w-full h-[1px] bg-[#D9D9D9]"></div>
              </div>

              <div class="flex flex-col gap-2">
                <label for="no2" class="font-medium">İş Telefon Numarası</label>
                <div class="flex sm:gap-4 items-center">
                  <input
                    type="text"
                    id="no2"
                    class="flex-grow text-[#737373]"
                    v-model="workPhoneInput"
                    disabled
                  />
                  <button><img :src="editPen" alt="edit" /></button>
                </div>
                <div class="w-full h-[1px] bg-[#D9D9D9]"></div>
              </div>

              <div class="flex flex-col gap-2">
                <label for="address" class="font-medium">Adres</label>
                <div class="flex sm:gap-4 items-center">
                  <textarea
                    name=""
                    id="address"
                    cols="30"
                    rows="2"
                    class="resize-none text-[#737373]"
                    v-model="addressInput"
                    disabled
                  ></textarea>
                  <button class="mb-auto">
                    <img :src="editPen" alt="edit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <button
        class="bg-[#277dd2] text-white font-medium mx-auto text-2xl rounded-lg w-[330px] h-[60px]"
      >
        Kaydet
      </button>
    </div>
    <appointmentHelpInfo />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import appointmentHelpInfo from "../components/appointmentHelpInfo.vue";

import { useRouter } from "vue-router";

import appointmentViewSideBar from "../components/appointmentViewSideBar.vue";
import AppointmentViewNavBar from "../components/appointmentViewNavBar.vue";
import patientProfile from "../assets/patientProfile.svg";
import editPen from "../assets/editPen.svg";
import { useAppointmentStore } from "../../store/appointmentStore";

const appointmentStore = useAppointmentStore();
const mailInput = ref("");
const phoneInput = ref("");
const workPhoneInput = ref("");
const addressInput = ref("");

import { toTitleCase } from "@/utils/formatString";
const router = useRouter();

onMounted(async () => {
  mailInput.value = appointmentStore.patient.attributes.email;
  phoneInput.value = appointmentStore.patient.attributes.phone_number;
  workPhoneInput.value = appointmentStore.patient.attributes.work_phone_number;
  addressInput.value = appointmentStore.patient.attributes.address_description;
});
</script>
