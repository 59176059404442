<template>
  <div class="disableTailwind w-full h-[90%] max-w-[90%]">
    <div class="editorx_body !h-full !w-full !max-w-full">
      <!--editorjs id-->
      <div class id="codex-editor" />
    </div>
    <!-- <button
      style="margin-left: 30%"
      type="button"
      name="button"
      @click="save()"
    >
      save
    </button> -->
    <div class="editorx_body"></div>
  </div>
</template>

<script>
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import Checklist from "@editorjs/checklist";

export default {
  data() {
    return {
      value: null,
    };
  },
  methods: {
    save: function () {
      EditorJS.save().then((savedData) => {
        console.log(savedData);
        this.value = savedData;
      });
    },
    myEditor: function () {
      window.EditorJS = new EditorJS({
        holder: "codex-editor",
        autofocus: true,
        /**
         * This Tool will be used as default
         */
        // initialBlock: "paragraph",
        tools: {
          header: {
            class: Header,
            config: {
              placeholder: "######1",
              levels: [2, 3, 4],
              defaultLevel: 3,
            },
            inlineToolbar: false,
          },
          table: Table,
          image: {
            class: ImageTool,
            // config: {
            //   endpoints: {
            //     byFile: "http://localhost:8008/uploadFile", // Your backend file uploader endpoint
            //     byUrl: "http://localhost:8008/fetchUrl", // Your endpoint that provides uploading by Url
            //   },
            // },
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
            // config: {
            //   defaultStyle: "unordered",
            // },
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: "Şablon Ekle",
            },
          },
        },
        i18n: {
          /**
           * @type {I18nDictionary}
           */
          messages: {
            /**
             * Other below: translation of different UI components of the editor.js core
             */
            ui: {
              blockTunes: {
                // Turkce cevir
                toggler: {
                  "Click to tune": "Tıkla düzenle",
                  "or drag to move": "veya sürükle taşı",
                },
              },
              inlineToolbar: {
                converter: {
                  "Convert to": "Dönüştür",
                },
              },
              toolbar: {
                toolbox: {
                  Add: "Ekle",
                },
              },
            },

            /**
             * Section for translation Tool Names: both block and inline tools
             */
            toolNames: {
              Text: "Yazı",
              Heading: "Başlık",
              List: "Liste",
              Checklist: "Kontrol Listesi",
              Table: "Tablo",
              Image: "Resim",
            },

            /**
             * Section for passing translations to the external tools classes
             */
            tools: {
              stub: {
                "The block can not be displayed correctly.":
                  "Bloğun doğru görüntülenmesi mümkün değil.",
              },
            },

            /**
             * Section allows to translate Block Tunes
             */
            blockTunes: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
               * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
               *
               * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
               */
              delete: {
                Delete: "Sil",
              },
              moveUp: {
                "Move up": "Yukarı taşı",
              },
              moveDown: {
                "Move down": "Aşağı taşı",
              },
            },
          },
        },
        onReady: function () {
          console.log("ready");
        },
        onChange: function () {
          console.log("change");
        },
        data: {
          blocks: [
            // {
            //   type: "header",
            //   data: {
            //     text: "Editor.js",
            //     level: 4,
            //   },
            // },
            { type: "header", data: { text: "Hello Vue/Editor.js", level: 4 } },
            // {
            //   type: "list",
            //   data: {
            //     style: "ordered",
            //     items: ["Learn Vue.js<br>", "Learn Editor.js"],
            //   },
            // },
          ],
        },
      });
    },
  },
  mounted: function () {
    this.myEditor();
  },
};
</script>

<style lang="css" scoped >
.editorx_body {
  /* width: 62%;
  margin-left: 15%; */
  width: 60%;
  border: 2px solid #f1f3f5;
  box-sizing: border-box;
}
.ce-block--focused {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 0.5438550420168067) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}
@media (min-width: 651px) {
  .ce-block__content,
  .ce-toolbar__content {
    max-width: calc(100% - 120px) !important;
    margin: 0 60px;
  }
}

.cdx-block {
  max-width: 100% !important;
}
</style>