<template>
  <div>
    <div v-if="isPopupVisible" class="popup">
      <div class="popup-inner">
        <div class="popup-content">
          <div class="popup-icon">
            <i class="fa fa-exclamation-circle"></i>
          </div>
          <div class="popup-message">
            <Vue3Lottie
              :animationData="loginError"
              :height="200"
              :width="200"
            />
            <div class="">
              <p class="font-medium roboto">
                Oturum tespit edildi. Lütfen önce güvenli çıkış yapın.
              </p>
              <button
                @click="logout"
                class="transition-all duration-200 transform hover:scale-105"
              >
                Çıkış Yap
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import { ref } from "vue";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions";
import loginError from "../assets/loginError.json";

import { patientAuthActions } from "@/store/modules/administratorManagementStore/patientAuth/patientAuthActions.ts";

const patientStoreAuthActions = patientAuthActions();

const personelStoreAuthActions = personelAuthActions();
const isPopupVisible = ref(true);
const props = defineProps({
  userType: String,
});

const hidePopup = () => {
  isPopupVisible.value = false;
};

const logout = async () => {
  switch (props.userType) {
    case "patient":
      await patientStoreAuthActions.logout();
      isPopupVisible.value = false;
      break;
    case "personel":
      await personelStoreAuthActions.logout();
      isPopupVisible.value = false;
      break;

    default:
      break;
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #ebf5fb;
  height: 400px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popup-inner {
  padding: 20px;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.popup-icon {
  text-align: center;
}

.popup-icon i {
  font-size: 48px;
  color: red;
}

.popup-message {
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.popup-message p {
  margin-bottom: 10px;
}

.popup-message button {
  background-color: #ff0000;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
