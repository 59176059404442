<template>
  <div class="flex w-full h-full relative">
    <protezSidebar :activeRoute="2" />
    <div class="flex flex-col w-full h-full">
      <div class="w-full py-3 flex gap-12 items-center px-10 border-b">
        <h2 class="text-2xl font-semibold text-black">Sipariş</h2>
      </div>
      <div class="flex gap-28 px-10 mt-10">
        <div>
          <h4 class="text-xl font-semibold mb-6">Hasta Bilgileri</h4>
          <div
            class="relative rounded-[10px]"
            @focusin="searchPatientSearchBarFocus = true"
            @focusout="handleFocusOut"
            @blur="handleFocusOut"
          >
            <div
              class="mx-auto flex shadow gap-6 border w-[447px] bg-white pl-[20px] rounded-full text-black"
            >
              <img :src="searchLogo" alt="search" />
              <div class="w-10/12">
                <input
                  type="text"
                  class="w-full outline-none py-2"
                  placeholder="Hasta ara"
                  @input="handleInputChange"
                  v-model="searchPatientInput"
                />
              </div>
            </div>
            <div
              v-if="
                searchPatientInput &&
                searchPatientInput.length > 4 &&
                searchPatientSearchBarFocus
              "
              class="absolute p-2 border shadow min-w-[450px] z-40 left-1/2 -translate-x-1/2 top-[50px] rounded-[10px] bg-[#fbfbfb] flex flex-col gap-2"
            >
              <!-- true -->
              <div class="flex flex-col gap-4 max-h-[250px] overflow-y-auto">
                <!-- placeholder -->
                <div
                  v-for="(patient, index) in searchedPatients"
                  :key="patient + index - index"
                  class="bg-[#f1f2f3] rounded p-2 flex"
                  @mousedown="selectPatient(patient)"
                >
                  <img
                    :src="profileCircle"
                    alt="profile photo"
                    class="w-8 h-8"
                  />
                  <div class="ml-2">
                    <p class="font-medium">
                      {{ patient.attributes.name }}
                      {{ patient.attributes.surname }}
                    </p>
                    <p class="my-1">
                      {{ patient.attributes.tc_identity_number }}
                    </p>
                  </div>
                </div>
                <!-- placeholder -->
              </div>
              <p v-if="false" class="ml-auto text-[#333333]">
                <span class="font-semibold">89</span> sonuç bulundu.
              </p>

              <!-- false -->
              <div class="text-center" v-if="searchedPatients.length < 1">
                <h4 class="font-bold text-[#323232]">BİLGİLENDİRME</h4>

                <p class="font-medium text-[#D9534F] my-2">
                  Bu TC Numarasına Ait Kayıt Bulunamamaktadır
                </p>
                <!-- @focus="medulaStore.searchPatientButtonFocus = true"
                @blur="medulaStore.searchPatientButtonFocus = false"
                bunlari keyboard navigationda kullanmayi surdur  -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <h4 class="text-xl font-semibold mb-6">Sipariş Bilgileri</h4>
          <div class="flex border py-3 px-12 gap-20">
            <div class="flex flex-col items-center justify-center gap-2">
              <p class="text-[#676767]">Sipariş veren hekim</p>
              <p>Sema BAL</p>
            </div>
            <div class="flex flex-col items-center justify-center gap-2">
              <p class="text-[#676767]">Sipariş verilen birim</p>
              <p>Ortodonti</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-10 px-10 gap-20">
        <div>
          <div>
            <h4 class="text-xl font-semibold mb-6">Dental Chart</h4>
            <dentalChart />
          </div>
          <h4 v-if="cart.length > 0" class="text-xl font-semibold mb-6">
            Sepet Bilgileri
          </h4>
          <div
            v-if="cart.length > 0"
            class="w-full border h-24 overflow-y-auto p-1 flex flex-col"
          >
            <div class="flex gap-3" v-for="(item, index) in cart">
              <div
                class="w-8 h-8 rounded-full flex items-center justify-center bg-[#f4f8fc] text-[#277dd2]"
              >
                {{ index + 1 }}
              </div>
              <div class="flex gap-4 items-center">
                <div class="flex gap-2 items-center">
                  <p class="text-[#676767]">Alan</p>
                  <span>--------></span>
                  <p :title="item.place" class="text-[#fb6b1a] truncate w-28">
                    {{ item.place }}
                  </p>
                </div>
                <div class="flex gap-2 items-center">
                  <p class="text-[#676767]">Tedavi Paketi</p>
                  <span>--------></span>
                  <p
                    :title="item.treatmentPackage"
                    class="text-[#fb6b1a] truncate w-28"
                  >
                    {{ item.treatmentPackage }}
                  </p>
                </div>
                <div class="flex gap-2 items-center">
                  <p class="text-[#676767]">Fiyat</p>
                  <span>--------></span>
                  <p :title="item.price" class="text-[#fb6b1a] w-28">
                    {{ item.price }}
                  </p>
                </div>
                <div
                  @click="cart.splice(index, 1)"
                  class="w-6 h-6 flex items-center cursor-pointer justify-center bg-[#fef5f5] rounded-full"
                >
                  <img :src="deleteOrder" alt="delete" />
                </div>
              </div>
            </div>
            <button
              @click="orderCreatedPopUp = true"
              class="focus:outline-none bg-[#5cb85c] text-white py-1 px-3 rounded-full ml-auto mt-2"
            >
              Sipariş oluştur
            </button>
          </div>
        </div>
        <div>
          <h4 class="text-xl font-semibold mb-3">Protez Bilgileri</h4>
          <div class="flex flex-col gap-2">
            <Combobox v-model="selectedProtezType">
              <div class="relative w-[350px]">
                <div
                  class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
                >
                  <ComboboxInput
                    autoComplete="off"
                    placeholder="Tedavi seç"
                    class="w-full border py-2 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                    :displayValue="(protezType) => protezType.text"
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </div>
                <TransitionRoot
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  @after-leave="query = ''"
                >
                  <ComboboxOptions
                    class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                  >
                    <div
                      v-if="filteredProtezTypes.length === 0 && query !== ''"
                      class="relative cursor-default select-none px-4 py-2 text-gray-700"
                    >
                      Sonuç bulunamadı
                    </div>

                    <ComboboxOption
                      v-for="(protezType, index) in filteredProtezTypes"
                      as="template"
                      :key="index"
                      :value="protezType"
                      v-slot="{ selectedProtezType, active }"
                    >
                      <li
                        class="relative cursor-pointer select-none my-2 py-2 pl-3"
                        :class="{
                          'bg-[#277dd2] text-white': active,
                          'text-gray-900': !active,
                        }"
                      >
                        <span
                          class="block truncate"
                          :class="{
                            'font-medium': selectedProtezType,
                            'font-normal': !selectedProtezType,
                          }"
                        >
                          {{ protezType?.text }}
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </TransitionRoot>
              </div>
            </Combobox>
            <Combobox
              v-if="selectedProtezType?.value === '1'"
              v-model="selectedRemovablePackage"
            >
              <div class="relative w-[350px]">
                <div
                  class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
                >
                  <ComboboxInput
                    autoComplete="off"
                    placeholder="Tedavi paketi seç"
                    class="w-full border py-2 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                    :displayValue="(removablePackage) => removablePackage.text"
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </div>
                <TransitionRoot
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  @after-leave="query = ''"
                >
                  <ComboboxOptions
                    class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                  >
                    <div
                      v-if="
                        filteredRemovablePackages.length === 0 && query !== ''
                      "
                      class="relative cursor-default select-none px-4 py-2 text-gray-700"
                    >
                      Sonuç bulunamadı
                    </div>

                    <ComboboxOption
                      v-for="(
                        removablePackage, index
                      ) in filteredRemovablePackages"
                      as="template"
                      :key="index"
                      :value="removablePackage"
                      v-slot="{ selectedRemovablePackage, active }"
                    >
                      <li
                        class="relative cursor-pointer select-none my-2 py-2 pl-3"
                        :class="{
                          'bg-[#277dd2] text-white': active,
                          'text-gray-900': !active,
                        }"
                      >
                        <span
                          class="block truncate"
                          :class="{
                            'font-medium': selectedRemovablePackage,
                            'font-normal': !selectedRemovablePackage,
                          }"
                        >
                          {{ removablePackage?.text }}
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </TransitionRoot>
              </div>
            </Combobox>
            <Combobox
              v-if="selectedProtezType?.value === '2'"
              v-model="selectedFixedPackage"
            >
              <div class="relative w-[350px]">
                <div
                  class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
                >
                  <ComboboxInput
                    autoComplete="off"
                    placeholder="Tedavi paketi seç"
                    class="w-full border py-2 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                    :displayValue="(fixedPackage) => fixedPackage.text"
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </div>
                <TransitionRoot
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  @after-leave="query = ''"
                >
                  <ComboboxOptions
                    class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                  >
                    <div
                      v-if="filteredFixedPackages.length === 0 && query !== ''"
                      class="relative cursor-default select-none px-4 py-2 text-gray-700"
                    >
                      Sonuç bulunamadı
                    </div>

                    <ComboboxOption
                      v-for="(fixedPackage, index) in filteredFixedPackages"
                      as="template"
                      :key="index"
                      :value="fixedPackage"
                      v-slot="{ selectedFixedPackage, active }"
                    >
                      <li
                        class="relative cursor-pointer select-none my-2 py-2 pl-3"
                        :class="{
                          'bg-[#277dd2] text-white': active,
                          'text-gray-900': !active,
                        }"
                      >
                        <span
                          class="block truncate"
                          :class="{
                            'font-medium': selectedFixedPackage,
                            'font-normal': !selectedFixedPackage,
                          }"
                        >
                          {{ fixedPackage?.text }}
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </TransitionRoot>
              </div>
            </Combobox>
            <Combobox
              v-if="selectedFixedPackage?.value === '2'"
              v-model="selectedKronPackage"
            >
              <div class="relative w-[350px]">
                <div
                  class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
                >
                  <ComboboxInput
                    autoComplete="off"
                    placeholder="Tedavi paketi seç"
                    class="w-full border py-2 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                    :displayValue="(kronPackage) => kronPackage.text"
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </div>
                <TransitionRoot
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  @after-leave="query = ''"
                >
                  <ComboboxOptions
                    class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                  >
                    <div
                      v-if="filteredKronPackages.length === 0 && query !== ''"
                      class="relative cursor-default select-none px-4 py-2 text-gray-700"
                    >
                      Sonuç bulunamadı
                    </div>

                    <ComboboxOption
                      v-for="(kronPackage, index) in filteredKronPackages"
                      as="template"
                      :key="index"
                      :value="kronPackage"
                      v-slot="{ selectedKronPackage, active }"
                    >
                      <li
                        class="relative cursor-pointer select-none my-2 py-2 pl-3"
                        :class="{
                          'bg-[#277dd2] text-white': active,
                          'text-gray-900': !active,
                        }"
                      >
                        <span
                          class="block truncate"
                          :class="{
                            'font-medium': selectedKronPackage,
                            'font-normal': !selectedKronPackage,
                          }"
                        >
                          {{ kronPackage?.text }}
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </TransitionRoot>
              </div>
            </Combobox>
            <Combobox v-model="selectedProtezColor">
              <div class="relative w-[350px]">
                <div
                  class="relative border w-full cursor-default overflow-hidden rounded bg-white text-left"
                >
                  <ComboboxInput
                    autoComplete="off"
                    placeholder="Dis protez rengi seç"
                    class="w-full border py-2 pl-2 pr-10 leading-5 text-gray-900 focus:ring-0"
                    :displayValue="(protezColor) => protezColor.text"
                    @change="query = $event.target.value"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </div>
                <TransitionRoot
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  @after-leave="query = ''"
                >
                  <ComboboxOptions
                    class="absolute z-50 pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5"
                  >
                    <div
                      v-if="filteredProtezColors.length === 0 && query !== ''"
                      class="relative cursor-default select-none px-4 py-2 text-gray-700"
                    >
                      Sonuç bulunamadı
                    </div>

                    <ComboboxOption
                      v-for="(protezColor, index) in filteredProtezColors"
                      as="template"
                      :key="index"
                      :value="protezColor"
                      v-slot="{ selectedProtezColor, active }"
                    >
                      <li
                        class="relative cursor-pointer select-none my-2 py-2 pl-3"
                        :class="{
                          'bg-[#277dd2] text-white': active,
                          'text-gray-900': !active,
                        }"
                      >
                        <span
                          class="block truncate"
                          :class="{
                            'font-medium': selectedProtezColor,
                            'font-normal': !selectedProtezColor,
                          }"
                        >
                          {{ protezColor?.text }}
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </TransitionRoot>
              </div>
            </Combobox>
          </div>
          <h4 class="font-bold mb-2 mt-[20px] text-[#7c7c7c]">
            Diş protez ile ilgili açıklama
          </h4>
          <textarea
            v-model="protezDescription"
            class="resize-none focus:outline-none border py-1 px-2 rounded"
            placeholder="Açıklama giriniz"
            cols="50"
            rows="5"
          ></textarea>
          <h4 class="font-bold mb-2 mt-[20px] text-[#7c7c7c]">Dosya</h4>
          <div>
            <div
              class="rounded-xl cursor-pointer w-[435px] h-20 overflow-auto bg-white border-[1px] border-[#277dd2] border-dashed"
              :class="
                files.length
                  ? 'flex flex-wrap gap-2'
                  : 'flex flex-col gap-4 items-center justify-center'
              "
              @click="fileInput.click()"
            >
              <img v-if="!files.length" :src="addDocIcon" alt="addDocIcon" />
              <p v-if="!files.length" class="text-sm text-[#277dd2]">
                Dosya ekleyiniz
              </p>

              <span
                v-for="file in files"
                :key="file.name"
                class="text-xs w-fit flex gap-[2px] items-center"
              >
                {{ file.name }}
                <span
                  @click.stop="files.splice(files.indexOf(file), 1)"
                  class="bg-[#BE2E00] cursor-pointer text-xs flex items-center justify-center text-white rounded-full h-3 w-3"
                >
                  x
                </span>
              </span>
            </div>
            <input
              ref="fileInput"
              type="file"
              accept="image/*"
              class="hidden"
              @change="handleFileChange"
              multiple
            />
          </div>
          <button
            @click="addToCart"
            class="w-full bg-[#277DD2] text-white mt-4 py-2 rounded-xl focus:outline-none"
          >
            Sepete Ekle
          </button>
        </div>
      </div>
    </div>
    <orderInfosPopUp
      v-if="orderCreatedPopUp"
      :closeOrderPopUp="closeOrderPopUp"
    />
    <dentalChartCursorFollower />
  </div>
</template>

<script setup>
import protezSidebar from "@/modules/protez/components/protezSidebar.vue";
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import addDocIcon from "../assets/addDocIcon.svg";
import deleteOrder from "../assets/deleteOrder.svg";
import dentalChartCursorFollower from "@/modules/dentalChart/dentalChartMain/components/dentalChartCursorFollower.vue";
import { useDentalChart } from "@/modules/dentalChart/dentalChartMain/dentalChartStore/dentalChartStore";
const dentalChartStore = useDentalChart();
import dentalChart from "@/modules/protez/components/protezCreateOrders/dentalChart.vue";
import orderInfosPopUp from "@/modules/protez/components/protezCreateOrders/orderInfosPopUp.vue";
import personelSideBar from "@/modules/personel/components/personalSideBar.vue";
import dentalChartTeethComponent from "@/modules/dentalChart/dentalChartMain/components/dentalChartTeethComponent.vue";
import { UseSearchPatientStore } from "@/store/modules/operationsStore/patient/useSearchPatientStore";
const searchPatientStore = UseSearchPatientStore();
import { useProthesisStore } from "@/modules/protez/store/prothesisStore";
const prothesisStore = useProthesisStore();
import { ref, computed, watch, onMounted } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";

const cart = ref([]);
const orderCreatedPopUp = ref(false);

const addToCart = () => {
  cart.value.push({
    place: dentalChartStore.selectedProtezTeeth.join(", "),
    treatmentPackage:
      selectedProtezType.value.value === "1"
        ? selectedRemovablePackage.value?.text
        : selectedFixedPackage.value.value === "1"
        ? selectedFixedPackage.value?.text
        : selectedKronPackage.value?.text,

    price: "2450TL",
    
  });
  selectedProtezType.value = "";
  selectedRemovablePackage.value = "";
  selectedFixedPackage.value = "";
  selectedKronPackage.value = "";
  selectedProtezColor.value = "";
  protezDescription.value = "";
  dentalChartStore.selectedProtezTeeth = [];
  files.value = [];
  dentalChartStore.dentalChartDiagnozSelectedButtonName = "";
  
};

const closeOrderPopUp = () => {
  orderCreatedPopUp.value = false;
};

const searchPatientSearchBarFocus = ref(false);
const searchPatientInput = ref("");
const searchedPatients = ref([]);
const selectedPatient = ref(null);

let query = ref("");

const selectedProtezType = ref();
const selectedRemovablePackage = ref();
const selectedFixedPackage = ref();
const selectedKronPackage = ref();
const selectedProtezColor = ref();
const protezDescription = ref("");

const handleFocusOut = () => {
  searchPatientSearchBarFocus.value = false;
};

const handleInputChange = async () => {
  searchPatientInput.value = searchPatientInput.value.replace(/[^0-9]/g, "");
  selectedPatient.value = null;
  if (
    searchPatientInput.value.length < 5 ||
    searchPatientInput.value.length > 11
  ) {
    return false;
  }
  searchedPatients.value = await searchPatientStore.searchPatient(
    searchPatientInput.value
  );
};

// watch(
//   () => doctorsSlotsStore.searchPatientInput,
//   (newValue, oldValue) => {
//     handleInputChange();
//   }
// );

const selectPatient = async (patient) => {
  selectedPatient.value = patient;
  searchPatientInput.value = patient.attributes.tc_identity_number;
  searchPatientSearchBarFocus.value = false;
};

const handleKeyDown = (event) => {
  if (event.key === "Escape") {
    dentalChartStore.dentalChartDiagnozSelectedButtonName = "";
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
});

watch(
  () => selectedProtezType.value,
  (newValue, oldValue) => {
    if (newValue) {
      dentalChartStore.dentalChartDiagnozSelectedButtonName = "";
      dentalChartStore.selectedProtezTeeth = [];
      selectedRemovablePackage.value = "";
      selectedFixedPackage.value = "";
      selectedKronPackage.value = "";
    }
  }
);

watch(
  () => selectedRemovablePackage.value,
  (newValue, oldValue) => {
    if (newValue) {
      dentalChartStore.dentalChartDiagnozSelectedButtonName = "Çene seçiniz";
      dentalChartStore.selectedProtezTeeth = [];
    }
  }
);

watch(
  () => selectedFixedPackage.value,
  (newValue, oldValue) => {
    console.log(newValue);
    if (newValue.value === "1") {
      dentalChartStore.dentalChartDiagnozSelectedButtonName = "Diş seçiniz";
      dentalChartStore.selectedProtezTeeth = [];
      selectedKronPackage.value = "";
    }
  }
);

watch(
  () => selectedKronPackage.value,
  (newValue, oldValue) => {
    if (newValue) {
      dentalChartStore.dentalChartDiagnozSelectedButtonName = "Diş seçiniz";
      dentalChartStore.selectedProtezTeeth = [];
    }
  }
);

const protezTypes = [
  {
    value: "1",
    text: "Hareketli Protez",
  },
  {
    value: "2",
    text: "Sabit Protez",
  },
];

const removablePackages = [
  {
    value: "1",
    text: "Akrilik tam protez, tek çene",
  },
  {
    value: "2",
    text: "Akrilik bölümlü protez, tek çene",
  },
  {
    value: "3",
    text: "Metal kaideli tam protez, tek çene",
  },
  {
    value: "4",
    text: "Metal kaideli bölümlü protez, tek çene",
  },
  {
    value: "5",
    text: "Akrilik immediat protez, tek çene",
  },
  {
    value: "6",
    text: "Hareketli periodontal protez, tek çene",
  },
  {
    value: "7",
    text: "İmplant üstü tam protez",
  },
  {
    value: "8",
    text: "İmplant üstü bölümlü protez",
  },
];

const fixedPackages = [
  {
    value: "1",
    text: "Sabit periodontal protez, her üye için",
  },
  {
    value: "2",
    text: "Kron",
  },
];

const kronPackages = [
  {
    value: "1",
    text: "Tek parça döküm kron",
  },
  {
    value: "2",
    text: "Venner kron, akrilik",
  },
  {
    value: "3",
    text: "Venner kron, seramik",
  },
  {
    value: "4",
    text: "Pivo(Çivili kron) veya post-core",
  },
  {
    value: "5",
    text: "Jaket kron, akrilik",
  },
  {
    value: "6",
    text: "Jaket kron, tam seramik(Metal desteksiz)",
  },
];

const protezColors = [
  {
    value: "1",
    text: "A1",
  },
  {
    value: "2",
    text: "A2",
  },
  {
    value: "3",
    text: "A3",
  },
  {
    value: "4",
    text: "A3.5",
  },
  {
    value: "5",
    text: "B1",
  },
  {
    value: "6",
    text: "B2",
  },
  {
    value: "7",
    text: "B3",
  },
  {
    value: "8",
    text: "B4",
  },
  {
    value: "9",
    text: "C1",
  },
  {
    value: "10",
    text: "C2",
  },
  {
    value: "11",
    text: "C3",
  },
  {
    value: "12",
    text: "C4",
  },
  {
    value: "13",
    text: "D2",
  },
  {
    value: "14",
    text: "D3",
  },
  {
    value: "15",
    text: "D4",
  },
];

let filteredProtezTypes = computed(() =>
  query.value === ""
    ? protezTypes
    : protezTypes.filter((protezType) =>
        protezType.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

let filteredRemovablePackages = computed(() =>
  query.value === ""
    ? removablePackages
    : removablePackages.filter((removablePackage) =>
        removablePackage.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

let filteredFixedPackages = computed(() =>
  query.value === ""
    ? fixedPackages
    : fixedPackages.filter((fixedPackage) =>
        fixedPackage.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

let filteredKronPackages = computed(() =>
  query.value === ""
    ? kronPackages
    : kronPackages.filter((kronPackage) =>
        kronPackage.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

let filteredProtezColors = computed(() =>
  query.value === ""
    ? protezColors
    : protezColors.filter((protezColor) =>
        protezColor.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

const fileInput = ref(null);
const files = ref([]);

const handleFileChange = (event) => {
  const selectedFiles = event.target.files;
  for (let i = 0; i < selectedFiles.length; i++) {
    files.value.push(selectedFiles[i]);
  }
};
</script>
