<template>
  <div class="relative">
    <div class="flex h-screen w-[458px] flex-col overflow-y-auto bg-[#F8F8F8]">
      <!-- Search Bar -->
      <div
        class="mx-auto mt-10 flex h-[33px] w-[96%] rounded-[5px] border-[1px] border-[#D9D9D9] bg-white"
      >
        <button class="ml-2">
          <img :src="searchLogo" class="w-3" alt="" />
        </button>
        <input
          id="dentalChartSearchInput"
          v-model="store.dentalChartSearch"
          @input="handleSearchInput"
          type="text"
          placeholder="Sut Kodu Ara"
          class="border-left-[1px] h-full w-full rounded-[10px] border-[#D9D9D9] pl-2 focus:outline-none"
          name=""
        />
      </div>
      <dentalChartDiagnozTedaviPlanlanlamaSidebar
        v-if="!store.dentalChartSearch"
      />
      <dentalChartMuayeneHizmetSideBar v-if="store.dentalChartSearch" />
      <dentalChartCursorFollower />

      <!-- Secilen Disler Flow  -->
      <!-- <div
        v-if="
          !store.dentalChartSearchFlow[0] &&
          store.dentalChartSelectedTeethNumber[0]
        "
        class="flex flex-col"
      >
        <p class="mt-6 ml-3 font-normal text-[#454444]">Seçilen Dişler</p>
        <div class="mt-1 ml-3 w-[90%] border-b-2 border-[#DBDBDB]"></div>
        <div class="mt-2 ml-3 flex text-[#454444]">
          <p
            v-for="(toothNumber, index) in store.dentalChartSelectedTeethNumber"
            :key="index"
            class=""
          >
            <span class="font-medium text-[#0094FF]"> {{ toothNumber }} </span>
            <span class="dashSpace">&nbsp; - &nbsp;</span>
          </p>
          numaralı dişler seçilmiştir.
        </div>
        <button
          @click="store.dentalChartSearchFlow[0] = true"
          class="ml-16 mt-5 h-10 w-[163px] rounded-[5px] bg-[#0094FF]"
        >
          <p class="text-white">Onayla</p>
        </button>
      </div> -->

      <div v-show="store.dentalChartSearchFlow[0]" class="flex flex-col">
        <div class="flex h-7 w-full items-center bg-[#E1EDFF40]">
          <p class="mt-6 ml-3 font-normal text-[#3C3C43]">
            Tedavi Planı Oluşturulacak Dişler
          </p>
        </div>
        <div class="max-w- mt-3 flex h-7 flex-wrap items-center">
          <div
            v-for="(toothNumber, index) in store.dentalChartSelectedTeethNumber"
            :key="index"
            class="ml-4 flex w-[20%] items-center"
          >
            <input
              v-model="store.dentalChartToothNumberCheckbox[index]"
              @click="acceptFlowFunction([toothNumber], index)"
              class="inp-cbx"
              :id="index"
              type="checkbox"
            /><label class="cbx" :for="index"
              ><span
                ><svg width="12px" height="10px">
                  <use xlink:href="#check"></use></svg></span
            ></label>

            <p class="">{{ toothNumber }}</p>
            <img
              @click="deleteTooth(toothNumber)"
              class="ml-1 cursor-pointer"
              :src="trashBinBlack"
              alt=""
            />
            <label :for="index" class="cursor-pointer"
              ><img class="ml-1" :src="editLogo" alt=""
            /></label>
          </div>
        </div>
        <dentalChartSearchExaminationDetail class="mt-4" />
      </div>

      <!-- SVG Sprites<svg class="inline-svg">
        <symbol id="check" viewbox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg> -->
      <!-- <dentalChartSUTCodeError class="mt-4" /> -->
      <!-- Islem bitir button -->
      <button
        v-show="
          store.dentalChartSelectedTeethNumber[0] &&
          store.dentalChartSideBarDiagnozTedaviPlanlama == 1
        "
        @click="openPopup()"
        class="w-[150px] h-[35px] border-[1px] border-[#0094FF] rounded-[5px] mx-auto mt-[70px]"
      >
        <p class="text-[#0094FF] font-medium">İşlemi Bitir</p>
      </button>
      <!-- Green Popup -->
      <div :class="popup && 'overlay'" @click="openPopup()">
        <div
          :class="popup && 'open-popup'"
          class="popup flex h-full w-full border-l-8 border-white bg-[#5CB85C]"
        >
          <img :src="successInfoLogo" class="ml-2 w-[18px]" alt="successImg" />
          <p
            class="ml-2 flex h-full w-[90%] items-center text-xs font-medium text-white"
          >
            <span
              v-for="(item, index) in store.dentalChartSelectedTeethNumber"
              :key="index"
              class="flex"
            >
              {{ item }} &nbsp;
            </span>
            Numaralı Diş İçin Tedavi Yapılmıştır!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import searchLogo from "../../assets/searchLogo.svg";
import successInfoLogo from "../../assets/info-success.svg";
import editLogo from "../../assets/editLogo.svg";
import trashBinBlack from "../../assets/trashBinBlack.svg";
import dentalChartSearchExaminationDetail from "./dentalChartSearchExaminationDetail.vue";
import dentalChartDiagnozTedaviPlanlanlamaSidebar from "./dentalChartDiagnozTedaviPlanlanlamaSidebar.vue";
import dentalChartMuayeneHizmetSideBar from "./dentalChartMuayeneHizmetSideBar.vue";
import dentalChartCursorFollower from "./dentalChartCursorFollower.vue";
import dentalChartSUTCodeError from "./dentalChartSUTCodeError.vue";
import { useSearchSUTReferences } from "@/store/modules/administratorManagementStore/references/SUT/useSearchSUTReferences";
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();
const searchSUTReferencesStore = useSearchSUTReferences();
import { ref } from "vue";
const acceptFlowFunction = (toothPosition) => {
  store.setInitalCheckbox();
  if (store.dentalChartToothNumberCheckbox.includes(true)) {
    store.dentalChartToothNumberCheckbox[
      store.dentalChartToothNumberCheckbox.indexOf(true)
    ] = false;
  }
  toothPosition.forEach((tooth) => {
    let valueOfTeeth = store.getdentalChartTeethNumberStatePosition(tooth)[0];
    if (store.dentalChartTeethNumberState[valueOfTeeth] === 1) {
      store.dentalChartTeethNumberState[valueOfTeeth] = 2;
    } else if (store.dentalChartTeethNumberState[valueOfTeeth] === 2) {
      store.dentalChartTeethNumberState[valueOfTeeth] = 1;
    }
  });
};

// Delete tooth
const deleteTooth = (tooth) => {
  let indexOfTooth = store.dentalChartSelectedTeethNumber.indexOf(tooth);
  store.dentalChartSelectedTeethNumber.splice(indexOfTooth, 1);

  let valueOfTeeth = store.getdentalChartTeethNumberStatePosition(tooth)[0];
  store.dentalChartTeethNumberState[valueOfTeeth] = 0;
}; // PopUp
let popup = ref(null);
const openPopup = () => {
  popup.value = !popup.value;
};

// Search SUT Code
const handleSearchInput = async () => {
  if (
    store.dentalChartSearch.length >= 3 &&
    containsNumbers(store.dentalChartSearch)
  ) {
    console.log("containsNumber", store.dentalChartSearch);
    await searchSUTReferencesStore.searchSUTReferences({
      Kodu: store.dentalChartSearch,
      Adi: undefined,
    });
  }
  if (
    store.dentalChartSearch.length >= 3 &&
    !containsNumbers(store.dentalChartSearch)
  ) {
    console.log("contains letters", store.dentalChartSearch);
    setTimeout(async () => {
      await searchSUTReferencesStore.searchSUTReferences({
        Adi: store.dentalChartSearch,
        Kodu: undefined,
      });
    }, 1000);
  }
};
// Check if string contains numbers
function containsNumbers(str) {
  return /\d/.test(str);
}
</script>


<style scoped>
* {
  box-sizing: border-box;
}

.open-popup {
  visibility: visible;
  transform: translate(-50%, 0) scale(1);
}
.popup {
  width: 300px;
  height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  right: 0;
  top: 5%;
  transform: translate(0, 0) scale(1);

  color: #333;
  visibility: hidden;
  transition: transform 0.4s, left 0.4s;
}
.open-popup {
  visibility: visible;
  transform: translate(-25%, 0) scale(1);
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
}
.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
}
.cbx:not(:last-child) {
  margin-right: 6px;
}
.cbx:hover {
  background: rgba(0, 119, 255, 0.06);
}
.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.cbx span:first-child svg {
  position: relative;
  top: 3px;
  right: 5px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.cbx:hover span:first-child {
  border-color: #07f;
}
.inp-cbx {
  margin-left: -10px;
  visibility: hidden;
}
.inp-cbx:checked + .cbx span:first-child {
  background: #07f;
  border-color: #07f;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: #223254;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-svg {
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  body {
    width: auto;
    display: inherit;
    padding: 20px;
  }
  .cbx {
    width: 100%;
    margin-bottom: 4px;
    display: inline-block;
  }
}
@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
</style>