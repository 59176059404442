<template>
  <div class="flex justify-between items-center mb-3">
    <div class="flex gap-20 font-medium text-[#09244B]">
      <button
        class="pb-1 focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
        :class="
          RegisteredPatientListStore.sidePanelCardNavigation === 1
            ? 'border-b-[#277DD2] border-b-2'
            : 'opacity-50'
        "
        @click="RegisteredPatientListStore.sidePanelCardNavigation = 1"
      >
        Provizyon Bilgileri
      </button>
      <button
        class="pb-1 focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
        :class="
          RegisteredPatientListStore.sidePanelCardNavigation === 2
            ? 'border-b-[#277DD2] border-b-2'
            : 'opacity-50'
        "
        @click="RegisteredPatientListStore.sidePanelCardNavigation = 2"
      >
        Hasta Kartı
      </button>
    </div>
    <div>
      <div v-if="!RegisteredPatientListStore.editButton">
        <button
          @click="
            RegisteredPatientListStore.editButton = true;
            RegisteredPatientListStore.editleniyor = true;
          "
          class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] font-medium rounded-md border-[1px] border-[#4198F7] text-[#4198F7] px-3 py-1"
        >
          Düzenle
        </button>
      </div>
      <div class="flex" v-if="RegisteredPatientListStore.editButton">
        <button
          class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] font-medium rounded-md border-[1px] border-[#40464C] text-[#40464C] px-3 py-1 mr-3"
          @click="
            RegisteredPatientListStore.editButton = false;
            updateBase();
          "
        >
          Vazgeç
        </button>
        <button
          @click="handleChanges"
          class="focus:outline-double focus:outline-1 focus:outline-[#277dd2] font-medium rounded-md border-[1px] border-[#4198F7] text-[#4198F7] px-3 py-1"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch } from "vue";

//store imports
import { useUpdatePatientStore } from "@/store/modules/operationsStore/patient/useUpdatePatientStore";
import { useRegisteredPatientListStore } from "@/modules/asistanEkrani/registeredPatientList/store/registeredPatientList";
import { useDeleteProvision } from "@/store/modules/operationsStore/patientApplication/useDeleteProvision";
import { updatePatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/updatePatientApplicationStore";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";

//store inits
const RegisteredPatientListStore = useRegisteredPatientListStore();
const updatePatientStore = useUpdatePatientStore();
const deleteProvisionStore = useDeleteProvision();
const updateApplicationStore = updatePatientApplicationStore();
const applicationProvisionStore = useApplicationProvisionStore();

watch(
  () => RegisteredPatientListStore.sidePanelCardNavigation,
  (patient) => {
    RegisteredPatientListStore.editButton = false;
    updateBase();
  }
);

//methods

const updateBase = () => {
  RegisteredPatientListStore.editleniyor = false;
  console.log("update storeu ilk hale getir");
  // RegisteredPatientListStore.updatingPatientCard = _.cloneDeep(
  //   RegisteredPatientListStore.panelHastaHeader
  // );
  // RegisteredPatientListStore.updatingProvisionCard = _.cloneDeep(
  //   RegisteredPatientListStore.patientProvisionCardInfo
  // );
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.patientID =
    RegisteredPatientListStore.provisionsPatient.id;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.province =
    RegisteredPatientListStore.provisionsPatient.attributes.province;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.district =
    RegisteredPatientListStore.provisionsPatient.attributes.district;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.addressDetail =
    RegisteredPatientListStore.provisionsPatient.attributes.address_description;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.phoneNumber =
    RegisteredPatientListStore.provisionsPatient.attributes.phone_number;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.homePhoneNumber =
    RegisteredPatientListStore.provisionsPatient.attributes.home_phone_number;
  RegisteredPatientListStore.provisionsUpdatingPatientDatas.email =
    RegisteredPatientListStore.provisionsPatient.attributes.email;

  RegisteredPatientListStore.provisionsDatas.trackingNo =
    RegisteredPatientListStore.provisionRootApplication.takip_no;
  RegisteredPatientListStore.provisionsDatas.acceptanceNo =
    RegisteredPatientListStore.provisionRootApplication.application_protocol_number;
  RegisteredPatientListStore.provisionsDatas.corporation =
    RegisteredPatientListStore.provisionRootApplication.devredilen_kurum;
  RegisteredPatientListStore.provisionsDatas.provisionType =
    RegisteredPatientListStore.provisionRootApplication.provizyon_tipi;
  RegisteredPatientListStore.provisionsDatas.provisionDate =
    RegisteredPatientListStore.provisionRootApplication.provizyon_tarihi;
  // RegisteredPatientListStore.provisionsDatas.shippedTrackingNo = application.attributes.sevktakipdur;
  // RegisteredPatientListStore.provisionsDatas.exceptionalStatus = application.attributes.istisnaihaldur;
  RegisteredPatientListStore.provisionsDatas.followType =
    RegisteredPatientListStore.provisionRootApplication.takip_tipi;
  RegisteredPatientListStore.provisionsDatas.treatmentKind =
    RegisteredPatientListStore.provisionRootApplication.treatment_type;
  RegisteredPatientListStore.provisionsDatas.unitCode =
    RegisteredPatientListStore.provisionRootApplication.unit_code;
  RegisteredPatientListStore.provisionsDatas.doctorCode =
    RegisteredPatientListStore.provisionRootApplication.doctor_code;
  RegisteredPatientListStore.provisionsDatas.branchCode =
    RegisteredPatientListStore.provisionRootApplication.brans_kodu;
  RegisteredPatientListStore.provisionsDatas.branchName =
    RegisteredPatientListStore.provisionRootApplication.unit_name;
  RegisteredPatientListStore.provisionsDatas.treatmentType =
    RegisteredPatientListStore.provisionRootApplication.tedavi_tipi;
};

const handleChanges = async () => {
  RegisteredPatientListStore.editButton = false;
  RegisteredPatientListStore.editleniyor = false;
  if (RegisteredPatientListStore.sidePanelCardNavigation === 1) {
    RegisteredPatientListStore.editProvisionCardShow = true;
    const sildik = await deleteProvisionStore.deleteProvision(
      RegisteredPatientListStore.provisionsDatas.applicationID
    );
    if (sildik.HastaKabulIptalReturn.SonucKodu === "0000") {
      const updatePatientApplicationReq = {
        application_id:
          RegisteredPatientListStore.provisionsDatas.applicationID,
        provision_type:
          RegisteredPatientListStore.provisionsDatas.provisionType,
        treatment_type:
          RegisteredPatientListStore.provisionsDatas.treatmentKind,
        follow_type: RegisteredPatientListStore.provisionsDatas.followType,
        unit_id: RegisteredPatientListStore.provisionsDatas.unitCode,
        doctor_id: RegisteredPatientListStore.provisionsDatas.doctorCode,
        branch_code: RegisteredPatientListStore.provisionsDatas.branchCode,
        tedavi_tipi: RegisteredPatientListStore.provisionsDatas.treatmentType,
      };
      await updateApplicationStore.updatePatientApplication(
        updatePatientApplicationReq
      );
      const applicationProvisionReq = {
        application_id:
          RegisteredPatientListStore.provisionsDatas.applicationID,
        linked_id: "",
      };
      await applicationProvisionStore.getApplicationProvisions(
        applicationProvisionReq
      );
      RegisteredPatientListStore.provisionRootApplication.takip_no =
        RegisteredPatientListStore.provisionsDatas.trackingNo;
      RegisteredPatientListStore.provisionRootApplication.application_protocol_number =
        RegisteredPatientListStore.provisionsDatas.acceptanceNo;
      RegisteredPatientListStore.provisionRootApplication.devredilen_kurum =
        RegisteredPatientListStore.provisionsDatas.corporation;
      RegisteredPatientListStore.provisionRootApplication.provizyon_tipi =
        RegisteredPatientListStore.provisionsDatas.provisionType;
      RegisteredPatientListStore.provisionRootApplication.provizyon_tarihi =
        RegisteredPatientListStore.provisionsDatas.provisionDate;
      RegisteredPatientListStore.provisionRootApplication.takip_tipi =
        RegisteredPatientListStore.provisionsDatas.followType;
      RegisteredPatientListStore.provisionRootApplication.treatment_type =
        RegisteredPatientListStore.provisionsDatas.treatmentKind;
      RegisteredPatientListStore.provisionRootApplication.unit_code =
        RegisteredPatientListStore.provisionsDatas.unitCode;
      RegisteredPatientListStore.provisionRootApplication.doctor_code =
        RegisteredPatientListStore.provisionsDatas.doctorCode;
      RegisteredPatientListStore.provisionRootApplication.brans_kodu =
        RegisteredPatientListStore.provisionsDatas.branchCode;
      RegisteredPatientListStore.provisionRootApplication.unit_name =
        RegisteredPatientListStore.provisionsDatas.branchName;
      RegisteredPatientListStore.provisionRootApplication.tedavi_tipi =
        RegisteredPatientListStore.provisionsDatas.treatmentType;
    }
    console.log("provizyonu degis", sildik);
  } else if (RegisteredPatientListStore.sidePanelCardNavigation === 2) {
    const updatePatientReq = {
      patient_id:
        RegisteredPatientListStore.provisionsUpdatingPatientDatas.patientID,
      province:
        RegisteredPatientListStore.provisionsUpdatingPatientDatas.province,
      district:
        RegisteredPatientListStore.provisionsUpdatingPatientDatas.district,
      address_description:
        RegisteredPatientListStore.provisionsUpdatingPatientDatas.addressDetail,
      phone_number:
        RegisteredPatientListStore.provisionsUpdatingPatientDatas.phoneNumber,
      home_phone_number:
        RegisteredPatientListStore.provisionsUpdatingPatientDatas
          .homePhoneNumber,
      email: RegisteredPatientListStore.provisionsUpdatingPatientDatas.email,
    };
    await updatePatientStore.updatePatient(updatePatientReq);
    RegisteredPatientListStore.provisionsPatient.id =
      RegisteredPatientListStore.provisionsUpdatingPatientDatas.patientID;
    RegisteredPatientListStore.provisionsPatient.attributes.province =
      RegisteredPatientListStore.provisionsUpdatingPatientDatas.province;
    RegisteredPatientListStore.provisionsPatient.attributes.district =
      RegisteredPatientListStore.provisionsUpdatingPatientDatas.district;
    RegisteredPatientListStore.provisionsPatient.attributes.address_description =
      RegisteredPatientListStore.provisionsUpdatingPatientDatas.addressDetail;
    RegisteredPatientListStore.provisionsPatient.attributes.phone_number =
      RegisteredPatientListStore.provisionsUpdatingPatientDatas.phoneNumber;
    RegisteredPatientListStore.provisionsPatient.attributes.home_phone_number =
      RegisteredPatientListStore.provisionsUpdatingPatientDatas.homePhoneNumber;
    RegisteredPatientListStore.provisionsPatient.attributes.email =
      RegisteredPatientListStore.provisionsUpdatingPatientDatas.email;

    // await getPatientStore.getPatient(RegisteredPatientListStore.updatingPatientCard.HastaKodu, "registeredPatientList");
  }
};
</script>
