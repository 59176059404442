<template>
  <div>
    <!-- Navigation Buttons (Diagnoz , Tedavi Planlama) -->
    <div class="flex mt-[18px] ml-[14px]">
      <button
        :class="
          store.dentalChartSideBarDiagnozTedaviPlanlama === 1
            ? 'activeButton'
            : 'passiveButton'
        "
        @click="store.dentalChartSideBarDiagnozTedaviPlanlama = 1"
        class="h-[40px] w-[135px] focus:outline-none"
      >
        <p :class="store.buttonRef === 1 ? 'text-[#252C32]' : 'text-[#6F6F6F]'">
          Diagnoz
        </p>
      </button>
      <button
        :class="
          store.dentalChartSideBarDiagnozTedaviPlanlama === 2
            ? 'activeButton'
            : 'passiveButton'
        "
        @click="store.dentalChartSideBarDiagnozTedaviPlanlama = 2"
        class="h-[40px] w-[135px] focus:outline-none"
      >
        <p
          :class="
            store.dentalChartSideBarDiagnozTedaviPlanlama === 2
              ? 'text-[#252C32]'
              : 'text-[#6F6F6F]'
          "
        >
          Tedavi Planlama
        </p>
      </button>
    </div>
    <!-- Diagnoz Section -->
    <div
      v-if="store.dentalChartSideBarDiagnozTedaviPlanlama == 1"
      class="mt-5 mx-3"
    >
      <button
        :class="
          store.dentalChartDiagnozSelectedButtonName ==
          store.dentalChartButtonNamesArray[index]
            ? 'bg-[#5CB85C] border-white'
            : 'bg-white border-[#3C3C43]'
        "
        class="py-2 px-[6px] mx-2 my-1 border-[1px] rounded-[5px] focus:outline-none"
        v-for="(buttonName, index) in store.dentalChartButtonNamesArray"
        v-bind:key="buttonName"
        @click="
          store.dentalChartDiagnozSelectedButtonName =
            store.dentalChartButtonNamesArray[index]
        "
      >
        <p
          :class="
            store.dentalChartDiagnozSelectedButtonName ==
            store.dentalChartButtonNamesArray[index]
              ? ' text-white'
              : 'text-[#3C3C43]'
          "
        >
          {{ buttonName }}
        </p>
      </button>
    </div>
    <!-- Tedavi Planlama Section -->
    <div
      v-if="
        store.dentalChartSideBarDiagnozTedaviPlanlama == 2 &&
        store.dentalChartSelectedTeethNumber[0]
      "
    >
      <p class="mt-6 ml-3 font-normal text-[#454444]">Seçilen Dişler</p>
      <div class="mt-1 ml-3 w-[90%] border-b-2 border-[#DBDBDB]"></div>
      <div class="flex items-center flex-wrap ml-3">
        <div
          v-for="(item, index) in store.dentalChartSelectedTeethNumber"
          :key="index"
          class="bg-[#EBCF6F] rounded-[7px] w-[74px] h-[100px] flex flex-col items-center justify-center m-2 mx-auto"
        >
          <img :src="whiteToothLine1Tooth1" alt="" />
          <p class="text-white text-2xl font-medium">
            {{ item }}
          </p>
        </div>
      </div>
      <p
        class="font-medium text-[#454444] mx-auto flex justify-center items-center my-2"
      >
        Numaralı dişler seçilmiştir.
      </p>
      <div class="flex justify-center gap-6">
        <button
          @click="
            () => {
              focusSearch();
            }
          "
          class="mt-2 h-10 min-w-[163px] rounded-[5px] bg-white border-[1px] border-[#277DD2] focus:outline-none px-1"
        >
          <p class="text-[#277DD2]">Sut Kodu İle Devam Et</p>
        </button>
        <button
          @click="handleRedirectSorulanSorularPage"
          class="mt-2 h-10 min-w-[163px] rounded-[5px] bg-white border-[1px] border-[#277DD2] focus:outline-none px-1"
        >
          <p class="text-[#277DD2]">Tedavi Planla</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();
import { ref } from "vue";
import whiteToothLine1Tooth1 from "../../assets/whiteTooth/whiteToothLine1Tooth1.svg";

const handleRedirectSorulanSorularPage = () => {
  router.push("/dental-chart/tedavi-planlama");
};
const focusSearch = () => {
  document.getElementById("dentalChartSearchInput").focus();
};
</script>

<style scoped>
.activeButton {
  border: 2px #c6dcf5 solid;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.passiveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(198, 220, 245, 0.4);
  border-radius: 5px;
}
#my-div {
  background-color: #c6dcf5;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-left: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px rgba(16, 0, 54, 0.2);
  transition: 0.1s ease-out;
}
</style>