<script>
import { defineComponent } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { globalStore } from "../../../store/modules/globalStore.ts";
import { doctorInfo } from "@/modules/doctorSlots/components/doctorInfoReactiveTypes";
import createOrUpdateAppointmentSidebar from "@/modules/asistanEkrani/components/createOrUpdateAppointmentSidebar.vue";
import moment from "moment";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import Select2 from "vue3-select2-component";
import { updatingAppointment } from "@/modules/doctorSlots/components/updatingAppointment";
import assistantDeleteAppointment from "@/modules/asistanEkrani/components/assistanDeleteAppointment.vue";
import customInfoCard from "@/components/customInfoCard.vue";
import { useAppointmentBook } from "@/modules/asistanEkrani/components/appointmentBookStore.js";
import {
  INITIAL_EVENTS,
  initalBackgroundWeeklyEvents,
  fetchAppointments,
} from "../../../components/asistant/CalendarEvent.ts";
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic";
import {
  calendarStoreCalendarFilter,
  returnCalendarSlotClinicName,
} from "@/modules/asistanEkrani/asistanCalendar/store/calendarStore.js";
export default defineComponent({
  components: {
    FullCalendar,
    Select2,
    updatingAppointment,
    assistantDeleteAppointment,
    customInfoCard,
    createOrUpdateAppointmentSidebar,
  },
  computed: {
    propertyAAndPropertyB() {
      return `${globalStore.appointmentStartTime}|${this.mounted}`;
    },
  },
  data() {
    return {
      openCancelPopUp: false,
      mounted: false,

      calendarOptions: {
        plugins: [
          timeGridPlugin,
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        customButtons: {
          create: {
            text: "time",
            //icon: "fa-regular fa-user",
            click: this.handleRequestApproval,
          },
        },
        themeSystem: "bootstrap4",
        themeButtonIcons: true,

        headerToolbar: {
          start: "create",
          left: "prev,title,next",
          center: "",
          right: "",
        },
        views: {
          timeGridWeek: {
            allDaySlot: false, // all-day slotunu kapat
            titleFormat: { year: "numeric", month: "long", day: "numeric" },
          },
        },

        timeZone: "tr",
        handleWindowResize: true,
        windowResizeDelay: 100,
        dayHeaderFormat: { weekday: "short", day: "numeric" },
        dayHeaderContent: function (info) {
          // Gün numarası ve gün ismini alın
          var dayNumber = info.date.getDate();
          var dayName = info.date.toLocaleString("default", {
            weekday: "short",
          });

          // Şu an hangi gün üzerinde olduğunuzu kontrol edin
          var today = new Date().getDate();

          // Gün numarası ve gün ismi için ayrı ayrı stiller uygula
          var dayNumberStyle =
            "color:#373A3C;font-family: Roboto;font-family: Roboto;font-size: 20px;font-weight: 400;line-height: 23px;letter-spacing: 0em;text-align: left;";
          var dayNameStyle =
            "color:#373A3C;font-size: 15px;font-weight: 400;line-height: 18px;letter-spacing: 0em;text-align: left;";

          if (info.date.getDay() === 0) {
            // Pazar günü ise kırmızı renk uygula
            dayNumberStyle =
              "color:#FF0000; font-family: Roboto;font-family: Roboto;font-size: 20px;font-weight: 400;line-height: 23px;letter-spacing: 0em;text-align: left;";
            dayNameStyle =
              "color:#FF0000; font-size: 15px;font-weight: 400;line-height: 18px;letter-spacing: 0em;text-align: left;";
          }
          if (dayNumber === today) {
            // Eğer bugün ise, farklı bir stil uygula
            dayNumberStyle =
              "color:#0275D8;font-family: Roboto;font-family: Roboto;font-size: 20px;font-weight: 400;line-height: 23px;letter-spacing: 0em;text-align: left;";
            dayNameStyle =
              "color:#0275D8;font-size: 15px;font-weight: 400;line-height: 18px;letter-spacing: 0em;text-align: left;";
          }

          // HTML içeriğini oluştur
          var content = "<div>";
          content +=
            "<span style='" + dayNumberStyle + "'>" + dayNumber + "</span>";
          content +=
            "<span style='" + dayNameStyle + "'>" + dayName + "</span>";
          content += "</div>";

          return { html: content };
        },

        datesSet: async function (info) {
          globalStore.appointmentStartTime = info.start; // Görüntülenen tarih aralığının başlangıç tarihi
          globalStore.appointmentEndTime = info.end; // Görüntülenen tarih aralığının bitiş tarihi
        },

        events: [...initalBackgroundWeeklyEvents, ...INITIAL_EVENTS],
        // events: [
        //   {
        //     id: 0,
        //     title: "Title",
        //     detail: "Detail",
        //     patientStatus: "Status",
        //     start: "2024-03-12T10:00:00.000Z",
        //     end: "2024-03-12T10:40:00.000Z",
        //     appointmentStart: "2024-03-12T10:20:00.000Z",
        //     appointmentEnd: "2024-03-12T10:40:00.000Z",
        //     unitCode: "UnitCode",
        //     onHover: true,
        //   },
        //   {
        //     id: 0,
        //     title: "Title",
        //     detail: "Detail",
        //     patientStatus: "Status",
        //     start: "2024-03-12T10:00:00.000Z",
        //     end: "2024-03-12T10:55:00.000Z",
        //     appointmentStart: "2024-03-12T10:45:00.000Z",
        //     appointmentEnd: "2024-03-12T10:55:00.000Z",
        //     unitCode: "UnitCode",
        //     onHover: true,
        //   },

        firstDay: 1,
        initialView: "timeGridWeek",
        slotMinTime: "09:00:00",
        slotMaxTime: "16:00:00",
        slotDuration: "01:00:00",
        slotLabelInterval: "01:00:00",
        editable: false,
        selectable: true,
        eventMaxStack: 1,

        selectMirror: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventAdd: this.handleAddEvents,

        /* you can update a remote database when these fire:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [],
      title: "", //for show the today's date
      globalStore,
      calendarStoreCalendarFilter,
      useGetUnitsList,
      useAppointmentBook,
    };
  },
  watch: {
    // whenever question changes, this function will run
    async propertyAAndPropertyB(newVal, oldQuestion) {
      this.calendarOptions.events = [];
      console.log("assistant calendar", newVal);
      this.handleResetTheEvents();
      useAppointmentBook().doctor_code = JSON.parse(
        localStorage.getItem("personelStoreData")
      ).staffId;
      useAppointmentBook().unit_code = JSON.parse(
        localStorage.getItem("personelStoreData")
      ).unitId;

      const fetchAppointmentsReq = {
        doctor_id: JSON.parse(localStorage.getItem("personelStoreData"))
          .staffId,
        doctor_name: "",
        doctor_surname: "",
        unit_id: JSON.parse(localStorage.getItem("personelStoreData")).unitId,
        tc_identity_number: "",
        patient_name: "",
        patient_surname: "",
        phone_number: "",
        start_date: moment(globalStore.appointmentStartTime).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
        provision_identifier: "",
        page_id: 1,
        page_size: 500,
      };

      await fetchAppointments(fetchAppointmentsReq);
      await searchSlotsBetweenDate().slotsBetweenDate(
        JSON.parse(localStorage.getItem("personelStoreData")).staffId,
        moment(globalStore.appointmentStartTime).format("YYYY-MM-DD"),
        moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
        "calendarWeeklySlots",
        "free",
        JSON.parse(localStorage.getItem("personelStoreData")).unitId,
        1,
        5000,
        "calendar"
      );
      console.log(
        "initalBackgroundMonthlyEventsinitalBackgroundMonthlyEventsinitalBackgroundMonthlyEvents watcher filter",
        initalBackgroundWeeklyEvents
      );
      this.calendarOptions.events = [
        ...initalBackgroundWeeklyEvents,
        ...INITIAL_EVENTS,
      ];
    },
  },
  async mounted() {
    this.mounted = true;
    console.log(this.mounted);
    console.log("mountedGRRRRRR", this.calendarOptions.events);
    console.log("ey", useAppointmentBook().filteredDoctors);
    console.log("ye", useAppointmentBook().doctor_code);

    console.log("mounted events", this.calendarOptions.events);
    console.log("initalBackgroundWeeklyEvents", initalBackgroundWeeklyEvents);
    globalStore.isWeeklyEventsLoading = true;
    globalStore.isAppointmentCreateSuccesful = false;
    // this.calendarOptions.initialEvents = initalBackgroundWeeklyEvents;

    this.setTurkishLocale(); //  language turkish
    const titleElement = document.querySelector(".fc-toolbar-title");

    titleElement.addEventListener("click", this.handleTitleClick);
  },

  methods: {
    openCancelPopUpTrue() {
      this.openCancelPopUp = true;
    },
    openCancelPopUpFalse() {
      this.openCancelPopUp = false;
    },

    handleTitleClick() {
      const calendarApi = this.$refs.calendar.getApi();
      calendarApi.today(); // Takvimi bugüne getirin
    },

    setTurkishLocale() {
      this.calendarOptions.locale = "tr"; // Dil ayarını Türkçe olarak belirle
      this.calendarOptions.slotLabelFormat = {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      }; // Saat formatını Türkçe ayarla
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    async handleDateSelect(selectInfo, condition) {
      globalStore.isCreateAppointmentSideBarOpen = true;
      console.log("sasasasasa", selectInfo);
      // useAppointmentBook().selectedDate

      if (condition) {
        globalStore.isUpdating = true;
        let calendarApi = selectInfo.view.calendar;
        console.log("eyyo", selectInfo.view.calendar);
        console.log("baslangic", selectInfo.event.startStr);

        globalStore.calendarSideBarType = "";
        globalStore.isCreateAppointmentSideBarOpen = true; //when an cell clicked, open the side bar
        SearchFreeSlotsforClinic().$state.calendarSelectedStartDate =
          selectInfo.event.startStr; //send start en end days to side bar component
        SearchFreeSlotsforClinic().$state.calendarSelectedEndDate =
          moment(selectInfo.event.startStr)
            .add(-2, "hour")
            .format("YYYY-MM-DDTHH:mm:ss") + "Z";
        calendarApi.unselect(); // clear date selection
        //burada orada çekilen patientstoreu extended propstan ayarlaman lazım
      } else {
        globalStore.isUpdating = false;
        let calendarApi = selectInfo.view.calendar;
        console.log("eyyo", selectInfo.view.calendar);
        console.log("baslangic", selectInfo.startStr);
        globalStore.calendarSideBarType = "";
        globalStore.isCreateAppointmentSideBarOpen = true; //when an cell clicked, open the side bar
        SearchFreeSlotsforClinic().$state.calendarSelectedStartDate =
          selectInfo.startStr + "Z"; //send start en end days to side bar component
        SearchFreeSlotsforClinic().$state.calendarSelectedEndDate =
          selectInfo.endStr + "Z";

        calendarApi.unselect(); // clear date selection
      }
    },
    handleEventClick(clickInfo) {
      // if (
      //   confirm(
      //     `Are you sure you want to delete the event '${clickInfo.title}'`
      //   )
      // ) {
      //   clickInfo.remove();
      // }
      console.log(clickInfo);
    },
    handleEventDelete(arguman) {
      globalStore.isCreateAppointmentSideBarOpen = false;
      this.openCancelPopUpTrue();
      this.deletingDate = arguman.startStr;
      this.deletingEvent = arguman;
      console.log(arguman);
    },
    async handleEventUpdate(arguman) {
      globalStore.isUpdating = true; //ama sonra bunu kapa

      this.handleDateSelect(arguman, true);
      console.log("argumentum", arguman);
      const fullName = arguman.event._def.title.split(" ");
      useAppointmentBook().updatingAppointment =
        arguman.event._def.extendedProps.patientApponintmentCode;
      useAppointmentBook().searchPatientInput =
        arguman.event._def.extendedProps.patient;
      useAppointmentBook().selectedPatientID =
        arguman.event._def.extendedProps.patientID;

      console.log(updatingAppointment, "apdeytıd");
    },
    async routeToProvision(appointment) {
      useMedula().resetDropdowns();
      this.$router.push("/asistantScreen/medula/homePage");
      useMedula().selectedPatient = await useGetPatientStore().getPatientA(
        appointment._def.extendedProps.patientID
      );
      useMedula().selectedPatientID = appointment._def.extendedProps.patientID;
      useMedula().searchPatientInput = appointment._def.extendedProps.patient;
      useMedula().appointmentsApplication =
        appointment._def.extendedProps.patientApplicationCode;
      console.log("oy", appointment._def);
      useMedula().appointmentsApplicationDatas = {
        doctor_name: appointment._def.extendedProps.doctorName,
        unit_name: appointment._def.extendedProps.detail,
      };
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleAddEvents() {
      console.log("add events here");
    },
    handleResetTheEvents() {
      this.currentEvents = [];
    },
  },
});
</script>

<template>
  <div class="relative">
    <div class="absolute z-[998] right-20 top-20">
      <customInfoCard v-if="globalStore.customInfoCard" />
    </div>
    <div
      v-if="globalStore.isAppointmentCreateSuccesful"
      class="bg-green-50 border rounded-lg w-2/5 h-10 pop-up success"
    >
      Randevu başarı ile oluşturuldu.
    </div>
    <div
      v-if="globalStore.isAppointmentCreateFail"
      class="bg-red-300 border rounded-lg w-2/5 h-11 pop-up success"
    >
      Randevu oluşturulurken bir hata meydana geldi.
    </div>
    <div class="calendar-app doctorWeeklyCalendar">
      <div class="relative"></div>
      <div class="calendar-app-main">
        <FullCalendar
          class="calendar-app-calendar"
          :options="calendarOptions"
          ref="calendar"
        >
          <template v-slot:eventContent="arg">
            <div
              class="bg-[#5BC0DE] w-[205px] h-[100px] mx-auto mt-[10px] flex flex-col rounded"
              v-if="arg.event.title"
            >
              <p class="text-center text-[#FBFBFB] font-semibold">Yeni Hasta</p>
              <div
                class="bg-white p-1 flex flex-col justify-center gap-[1px] shadow-xl text-[#373A3C] w-[97%] rounded-b h-full ml-auto"
              >
                <div class="flex justify-between items-center">
                  <p class="text-base">
                    {{
                      new Date(arg.event.extendedProps.appointmentStart)
                        .getUTCHours()
                        .toString()
                        .padStart(2, "0") +
                      ":" +
                      new Date(arg.event.extendedProps.appointmentStart)
                        .getUTCMinutes()
                        .toString()
                        .padStart(2, "0")
                    }}
                    -
                    <span class="text-sm">{{
                      new Date(arg.event.extendedProps.appointmentEnd)
                        .getUTCHours()
                        .toString()
                        .padStart(2, "0") +
                      ":" +
                      new Date(arg.event.extendedProps.appointmentEnd)
                        .getUTCMinutes()
                        .toString()
                        .padStart(2, "0")
                    }}</span>
                  </p>
                  <div class="group relative flex flex-col z-50">
                    <button>
                      <img
                        src="../assets/menu-dots-vertical.png"
                        class="w-6 h-6"
                      />
                    </button>
                    <div
                      class="text-sm text-left absolute z-[100] text-white hidden group-focus-within:block group-hover:block -top-10 right-0 py-1 rounded-lg"
                    >
                      <button
                        @click="handleEventDelete(arg.event)"
                        class="text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-28 py-1"
                      >
                        Sil
                      </button>
                      <button
                        @click="handleEventUpdate(arg)"
                        class="text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-28 py-1"
                      >
                        Düzenle
                      </button>

                      <button
                        class="text-left bg-[#707070] hover:bg-opacity-90 px-2 w-28 py-1"
                      >
                        Yazdır
                      </button>
                    </div>
                  </div>
                </div>

                <!-- toLocaleTimeString('tr-TR', {hour: '2-digit', minute: '2-digit'}) -->
                <p
                  class="text-xl -mt-[2px] font-medium w-48 whitespace-nowrap overflow-hidden overflow-ellipsis"
                >
                  {{ arg.event.title }}
                </p>
                <p
                  class="text-sm max-w-[160px] overflow-x-hidden whitespace-nowrap"
                >
                  {{ arg.event.extendedProps.detail }}
                </p>
              </div>
            </div>
            <div
              class="bg-[#92E087] w-[220px] h-[130px]"
              v-if="arg.event.groupId === 'aviliableForAppointment'"
            ></div>
          </template>
        </FullCalendar>
      </div>

      <assistantDeleteAppointment
        :openCancelPopUpFalse="openCancelPopUpFalse"
        :deletingDate="deletingDate"
        :deletingEvent="deletingEvent"
        type="appointment"
        v-if="openCancelPopUp"
      />
      <div class="overlay" v-if="openCancelPopUp"></div>
    </div>
    <createOrUpdateAppointmentSidebar doctorScreen />
  </div>
</template>

<style lang="css" scoped>
/* @import "./calendarStyle.css"; */

*:focus {
  outline: none;
}
*:focus-visible:not(*:active) {
  outline: 1px solid #277dd2;
}
.pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
}

.pop-up.success {
  opacity: 1;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
