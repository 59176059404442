<template>
  <div class="flex h-full flex-col">
    <!-- Dental Teeth Chart -->
    <div
      class="mx-auto flex h-[424px] w-[765px] flex-col rounded-[20px] border-2 border-[#DBDBDB] bg-[#FBFBFB]"
    >
      <!-- Numbers -->

      <div
        class="relative top-[202px] ml-[61px] w-[626px] border-b-2 border-[#D9D9D9]"
      ></div>
      <div class="mt-[10px] flex">
        <p class="ml-[88px] text-[13px] font-light">18</p>
        <p class="ml-[30px] text-[13px] font-light">17</p>
        <p class="ml-[29px] text-[13px] font-light">16</p>
        <p class="ml-[23px] text-[13px] font-light">15</p>
        <p class="ml-[18px] text-[13px] font-light">14</p>
        <p class="ml-[15px] text-[13px] font-light">13</p>
        <p class="ml-[15px] text-[13px] font-light">12</p>
        <p class="ml-[18px] text-[13px] font-light">11</p>

        <!-- Right side -->
        <p class="ml-[55px] text-[13px] font-light">21</p>
        <p class="ml-[16px] text-[13px] font-light">22</p>
        <p class="ml-[16px] text-[13px] font-light">23</p>
        <p class="ml-[15px] text-[13px] font-light">24</p>
        <p class="ml-[18px] text-[13px] font-light">25</p>
        <p class="ml-[22px] text-[13px] font-light">26</p>
        <p class="ml-[29px] text-[13px] font-light">27</p>
        <p class="ml-[26px] text-[13px] font-light">28</p>
      </div>
      <div class="mt-[30px] flex">
        <p class="ml-[17px] mt-8 flex w-10 font-normal">Ön</p>
        <!-- Left Side -->
        <img
          @click="toggleTooth(0, 18, 1)"
          :src="getToothImg(getToothName(18), 1, 1)"
          class="ml-[25px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(1, 17, 1)"
          :src="getToothImg(getToothName(17), 1, 2)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(2, 16, 1)"
          :src="getToothImg(getToothName(16), 1, 3)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(3, 15, 1)"
          :src="getToothImg(getToothName(15), 1, 4)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(4, 14, 1)"
          :src="getToothImg(getToothName(14), 1, 5)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(5, 13, 1)"
          :src="getToothImg(getToothName(13), 1, 6)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(6, 12, 1)"
          :src="getToothImg(getToothName(12), 1, 7)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(7, 11, 1)"
          :src="getToothImg(getToothName(11), 1, 8)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <!-- Right side -->
        <img
          @click="toggleTooth(8, 21, 1)"
          :src="getToothImg(getToothName(21), 1, 9)"
          class="ml-[52px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(9, 22, 1)"
          :src="getToothImg(getToothName(22), 1, 10)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(10, 23, 1)"
          :src="getToothImg(getToothName(23), 1, 11)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(11, 24, 1)"
          :src="getToothImg(getToothName(24), 1, 12)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(12, 25, 1)"
          :src="getToothImg(getToothName(25), 1, 13)"
          class="ml-[10px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(13, 26, 1)"
          :src="getToothImg(getToothName(26), 1, 14)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(14, 27, 1)"
          :src="getToothImg(getToothName(27), 1, 15)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(15, 28, 1)"
          :src="getToothImg(getToothName(28), 1, 16)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <p class="ml-auto mr-[17px] mt-8 flex w-10 justify-start font-normal">
          Ön
        </p>
      </div>
      <div class="mt flex">
        <p class="ml-[17px] flex w-10 items-center font-normal">Üst</p>
        <!-- Left Side 2 -->
        <img
          @click="toggleTooth(0, 18, 1)"
          :src="getToothImg(getToothName(18), 2, 1)"
          class="ml-[25px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(1, 17, 1)"
          :src="getToothImg(getToothName(17), 2, 2)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(2, 16, 1)"
          :src="getToothImg(getToothName(16), 2, 3)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(3, 15, 1)"
          :src="getToothImg(getToothName(15), 2, 4)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(4, 14, 1)"
          :src="getToothImg(getToothName(14), 2, 5)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />

        <!-- Right side -->
        <img
          @click="toggleTooth(11, 24, 1)"
          :src="getToothImg(getToothName(24), 2, 6)"
          class="ml-[236px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(12, 25, 1)"
          :src="getToothImg(getToothName(25), 2, 7)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(13, 26, 1)"
          :src="getToothImg(getToothName(26), 2, 8)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(14, 27, 1)"
          :src="getToothImg(getToothName(27), 2, 9)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(15, 28, 1)"
          :src="getToothImg(getToothName(28), 2, 10)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <p
          class="ml-auto mr-[17px] flex w-10 items-center justify-start font-normal"
        >
          Üst
        </p>
      </div>

      <div class="mt-1 flex">
        <p class="ml-[17px] mt-1 flex w-10 font-normal">Arka</p>
        <!-- Left Side -->
        <img
          @click="toggleTooth(0, 18, 1)"
          :src="getToothImg(getToothName(18), 3, 1)"
          class="ml-[25px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(1, 17, 1)"
          :src="getToothImg(getToothName(17), 3, 2)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(2, 16, 1)"
          :src="getToothImg(getToothName(16), 3, 3)"
          class="ml-[14px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(3, 15, 1)"
          :src="getToothImg(getToothName(15), 3, 4)"
          class="ml-[14px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(4, 14, 1)"
          :src="getToothImg(getToothName(14), 3, 5)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(5, 13, 1)"
          :src="getToothImg(getToothName(13), 3, 6)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(6, 12, 1)"
          :src="getToothImg(getToothName(12), 3, 7)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(7, 11, 1)"
          :src="getToothImg(getToothName(11), 3, 8)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <!-- Right side -->
        <img
          @click="toggleTooth(8, 21, 1)"
          :src="getToothImg(getToothName(21), 3, 9)"
          class="ml-[52px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(9, 22, 1)"
          :src="getToothImg(getToothName(22), 3, 10)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(10, 23, 1)"
          :src="getToothImg(getToothName(23), 3, 11)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(11, 24, 1)"
          :src="getToothImg(getToothName(24), 3, 12)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(12, 25, 1)"
          :src="getToothImg(getToothName(25), 3, 13)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(13, 26, 1)"
          :src="getToothImg(getToothName(26), 3, 14)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(14, 27, 1)"
          :src="getToothImg(getToothName(27), 3, 15)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(15, 28, 1)"
          :src="getToothImg(getToothName(28), 3, 16)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <p
          class="ml-auto mr-[17px] mt-1 flex w-10 items-center justify-start font-normal"
        >
          Arka
        </p>
      </div>
      <!-- Bottom Side -->

      <div class="mt-9 flex">
        <p class="ml-[17px] flex w-10 items-center font-normal">Arka</p>
        <!-- Left Side -->
        <img
          @click="toggleTooth(16, 48, 2)"
          :src="getToothImg(getToothName(48), 4, 1)"
          class="ml-[25px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(17, 47, 2)"
          :src="getToothImg(getToothName(47), 4, 2)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(18, 46, 2)"
          :src="getToothImg(getToothName(46), 4, 3)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(19, 45, 2)"
          :src="getToothImg(getToothName(45), 4, 4)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(20, 44, 2)"
          :src="getToothImg(getToothName(44), 4, 5)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(21, 43, 2)"
          :src="getToothImg(getToothName(43), 4, 6)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(22, 42, 2)"
          :src="getToothImg(getToothName(42), 4, 7)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(23, 41, 2)"
          :src="getToothImg(getToothName(41), 4, 8)"
          class="ml-[19px] cursor-pointer"
          alt=""
        />
        <!-- Right side -->
        <img
          @click="toggleTooth(24, 31, 2)"
          :src="getToothImg(getToothName(31), 4, 9)"
          class="ml-[60px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(25, 32, 2)"
          :src="getToothImg(getToothName(32), 4, 10)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(26, 33, 2)"
          :src="getToothImg(getToothName(33), 4, 11)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(27, 34, 2)"
          :src="getToothImg(getToothName(34), 4, 12)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(28, 35, 2)"
          :src="getToothImg(getToothName(35), 4, 13)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(29, 36, 2)"
          :src="getToothImg(getToothName(36), 4, 14)"
          class="ml-[14px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(30, 37, 2)"
          :src="getToothImg(getToothName(37), 4, 15)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(31, 38, 2)"
          :src="getToothImg(getToothName(38), 4, 16)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <p
          class="ml-auto mr-[17px] flex w-10 items-center justify-start font-normal"
        >
          Arka
        </p>
      </div>
      <div class="mt-2 flex">
        <p class="ml-[17px] flex w-10 items-center font-normal">Üst</p>
        <!-- Left Side -->
        <img
          @click="toggleTooth(16, 48, 2)"
          :src="getToothImg(getToothName(48), 5, 1)"
          class="ml-[25px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(17, 47, 2)"
          :src="getToothImg(getToothName(47), 5, 2)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(18, 46, 2)"
          :src="getToothImg(getToothName(46), 5, 3)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(19, 45, 2)"
          :src="getToothImg(getToothName(45), 5, 4)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(20, 44, 2)"
          :src="getToothImg(getToothName(44), 5, 5)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />

        <!-- Right side -->
        <img
          @click="toggleTooth(27, 34, 2)"
          :src="getToothImg(getToothName(34), 5, 6)"
          class="ml-[235px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(28, 35, 2)"
          :src="getToothImg(getToothName(35), 5, 7)"
          class="ml-[14px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(29, 36, 2)"
          :src="getToothImg(getToothName(36), 5, 8)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(30, 37, 2)"
          :src="getToothImg(getToothName(37), 5, 9)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(31, 38, 2)"
          :src="getToothImg(getToothName(38), 5, 10)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />

        <p
          class="ml-auto mr-[17px] flex w-10 items-center justify-start font-normal"
        >
          Üst
        </p>
      </div>
      <div class="mt-1 flex">
        <p class="ml-[17px] mt-3 flex w-10 font-normal">Ön</p>
        <!-- Left Side -->
        <img
          @click="toggleTooth(16, 48, 2)"
          :src="getToothImg(getToothName(48), 6, 1)"
          class="ml-[25px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(17, 47, 2)"
          :src="getToothImg(getToothName(47), 6, 2)"
          class="ml-[14px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(18, 46, 2)"
          :src="getToothImg(getToothName(46), 6, 3)"
          class="ml-[11px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(19, 45, 2)"
          :src="getToothImg(getToothName(45), 6, 4)"
          class="ml-[11px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(20, 44, 2)"
          :src="getToothImg(getToothName(44), 6, 5)"
          class="ml-[11px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(21, 43, 2)"
          :src="getToothImg(getToothName(43), 6, 6)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(22, 42, 2)"
          :src="getToothImg(getToothName(42), 6, 7)"
          class="ml-[14px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(23, 41, 2)"
          :src="getToothImg(getToothName(41), 6, 8)"
          class="ml-[14px] cursor-pointer"
          alt=""
        />
        <!-- Right side -->
        <img
          @click="toggleTooth(24, 31, 2)"
          :src="getToothImg(getToothName(31), 6, 9)"
          class="ml-[62px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(25, 32, 2)"
          :src="getToothImg(getToothName(32), 6, 10)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(26, 33, 2)"
          :src="getToothImg(getToothName(33), 6, 11)"
          class="ml-[15px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(27, 34, 2)"
          :src="getToothImg(getToothName(34), 6, 12)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(28, 35, 2)"
          :src="getToothImg(getToothName(35), 6, 13)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(29, 36, 2)"
          :src="getToothImg(getToothName(36), 6, 14)"
          class="ml-[12px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(30, 37, 2)"
          :src="getToothImg(getToothName(37), 6, 15)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <img
          @click="toggleTooth(31, 38, 2)"
          :src="getToothImg(getToothName(38), 6, 16)"
          class="ml-[13px] cursor-pointer"
          alt=""
        />
        <p class="ml-auto mr-[17px] mt-3 flex w-10 justify-start font-normal">
          Ön
        </p>
      </div>
      <div class="mt-[50px] flex">
        <p class="ml-[88px] text-[13px] font-light">48</p>
        <p class="ml-[30px] text-[13px] font-light">47</p>
        <p class="ml-[29px] text-[13px] font-light">46</p>
        <p class="ml-[23px] text-[13px] font-light">45</p>
        <p class="ml-[18px] text-[13px] font-light">44</p>
        <p class="ml-[15px] text-[13px] font-light">43</p>
        <p class="ml-[15px] text-[13px] font-light">42</p>
        <p class="ml-[18px] text-[13px] font-light">41</p>
        <!-- Right side -->
        <p class="ml-[55px] text-[13px] font-light">31</p>
        <p class="ml-[16px] text-[13px] font-light">32</p>
        <p class="ml-[16px] text-[13px] font-light">33</p>
        <p class="ml-[15px] text-[13px] font-light">34</p>
        <p class="ml-[18px] text-[13px] font-light">35</p>
        <p class="ml-[22px] text-[13px] font-light">36</p>
        <p class="ml-[29px] text-[13px] font-light">37</p>
        <p class="ml-[26px] text-[13px] font-light">38</p>
      </div>
      <div
        class="relative left-[382px] bottom-[400px] min-h-[400px] w-0 border-r-2 border-[#D9D9D9]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
// Import dentalChart Store
import { useDentalChart } from "@/modules/dentalChart/dentalChartMain/dentalChartStore/dentalChartStore";
const store = useDentalChart();
import { useDentalChartPlanStore } from "@/modules/doctorScreen/store/dentalChartPlanStore";
const dentalChartPlanStore = useDentalChartPlanStore();
const getToothImg = (name, line, num) => {
  return require(`@/modules/dentalChart/assets/${name}/${name}Line${line}Tooth${num}.svg`);
};

const examinationTeeth = ref([]);
const planTeeth = ref([]);


const props = defineProps({
  modal: Boolean,
  examination: Boolean,
});

const getToothName = (toothButtonName) => {
  let toothName = "";
  if (store.selectedProtezTeeth) {
    if (store.selectedProtezTeeth.includes(toothButtonName)) {
      toothName = "yellowBorder";
    } else {
      toothName = "whiteTooth";
    }
    return toothName;
  }
  return toothName;
};
const toggleTooth = (toothStoreNumber, toothID, jawID) => {
  if (!store.dentalChartDiagnozSelectedButtonName) {
    return;
  }
  console.log("toothStoreNumber", toothStoreNumber);
  console.log("toothID", toothID);

  const index = store.selectedProtezTeeth.indexOf(toothID);

  if (store.dentalChartDiagnozSelectedButtonName === "Çene seçiniz") {
    if (index > -1) {
      if (jawID === 1) {
        const teethToRemove = [
          11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
          28,
        ];
        teethToRemove.forEach((tooth) => {
          const index = store.selectedProtezTeeth.indexOf(tooth);
          if (index > -1) {
            store.selectedProtezTeeth.splice(index, 1);
          }
        });
      } else if (jawID === 2) {
        const teethToRemove = [
          31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
          48,
        ];
        teethToRemove.forEach((tooth) => {
          const index = store.selectedProtezTeeth.indexOf(tooth);
          if (index > -1) {
            store.selectedProtezTeeth.splice(index, 1);
          }
        });
      }
      // store.selectedProtezTeeth.splice(index, 1);
    } else {
      // store.selectedProtezTeeth.push(toothID);
      console.log("jawID", jawID);
      if (jawID === 1) {
        store.selectedProtezTeeth = [];
        const teethToAdd = [
          11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
          28,
        ];
        store.selectedProtezTeeth.push(...teethToAdd);
      } else if (jawID === 2) {
        store.selectedProtezTeeth = [];
        const teethToAdd = [
          31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
          48,
        ];
        store.selectedProtezTeeth.push(...teethToAdd);
      }
    }
  } else if (store.dentalChartDiagnozSelectedButtonName === "Diş seçiniz") {
    if (index > -1) {
      store.selectedProtezTeeth.splice(index, 1);
    } else {
      store.selectedProtezTeeth.push(toothID);
    }
  }

  //   let diagnozName = store.dentalChartDiagnozSelectedButtonName;
  //   if (
  //     store.dentalChartTeethNumberState[toothStoreNumber] === "" ||
  //     store.dentalChartTeethNumberState[toothStoreNumber] in
  //       store.dentalChartButtonNamesArray
  //   ) {
  //     store.dentalChartTeethNumberState[toothStoreNumber] = diagnozName;
  //     store.dentalChartSelectedTeethNumber.push(toothID);
  //   } else {
  //     store.dentalChartTeethNumberState[toothStoreNumber] = "";
  //     // Remove the tooth if clicked again.
  //     const index = store.dentalChartSelectedTeethNumber.indexOf(toothID);
  //     if (index > -1) {
  //       // only splice array when item is found
  //       store.dentalChartSelectedTeethNumber.splice(index, 1); // 2nd parameter means remove one item only
  //     }
  //   }
};
</script>
