import axios from "axios";
import { defineStore } from "pinia";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import moment from "moment";

import {
  fetchFreeMonthlySlots,
  fetchFreeWeeklySlots,
  fetchFreeWeeklySlotsbyDoctor,
  fetchFreeListSlots,
} from "@/components/asistant/CalendarEvent";

export const corsHeaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "authorization, x-client-info, apikey, content-type",
};

export const searchSlotsByDoctor = defineStore({
  id: "slotsByDoctor",
  state: () => ({
    calendarSelectedStartDate: Date(),
    calendarSelectedEndDate: Date(),
    doctorSlots: [],
  }),
  persist: {
    storage: sessionStorage,
    paths: ["doctorSlots"],
  },
  actions: {
    async slotsByDoctor(functionType: string) {
      globalStore.isEventsLoading = true;
      await axios
        .post(
          `${clinicDomain}/slot/searchDoctor`,
          {
            Ad: "Yunus",
            Soyad: "AYDIN",
            PersonelKodu: "2ce0c1fd-cc1b-40ab-8124-dc7d0bc001c1",
          },
          {
            headers: {
              Authorization: patientAuthToken,
            },
          }
        )
        .then(async (response) => {
          globalStore.isEventsLoading = false;
          this.doctorSlots = response.data.slots;
          console.log("slot response", response.data);
          switch (functionType) {
            case "calendarWeeklySlots":
              await fetchFreeWeeklySlots();
              break;
            case "calendarMonthlySlots":
              await fetchFreeMonthlySlots();
              break;
            case "calendarListSlots":
              await fetchFreeListSlots();
              break;
            case "calendarWeeklySlotsbyDoctor":
              await fetchFreeWeeklySlotsbyDoctor();
              break;

            default:
              break;
          }
        })
        .catch((error) => {
          console.error("Error slot", error);
          globalStore.isEventsLoading = false; //donulecek
        });
    },
  },
});
