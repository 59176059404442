<template>
  <div class="ml-[10px] flex h-[27px]">
    <div v-if="store.studentAssistantFilter" class="relative w-[240px] text-lg">
      <div class="flex">
        <button
          class="flex w-full items-center rounded-lg bg-white px-1"
          @click="adresIlButtonDropDown = !adresIlButtonDropDown"
          @blur="adresIlButtonDropDown = false"
        >
          <p class="mr-2 flex w-[150px] text-sm font-light">
            <span>{{ adresIlButtonDropDownSelectedValue }}</span>
          </p>
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="ml-[40px] h-4 w-4 transform transition-transform duration-200 ease-in-out"
            :class="adresIlButtonDropDown ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
      <transition
        v-show="adresIlButtonDropDown"
        class="z-50 absolute w-[200px]"
        enter-active-class="transform transition duration-500 ease-custom"
        enter-class="-translate-y-1/2 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="transform transition duration-300 ease-custom"
        leave-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
      >
        <ul
          class="cursor-pointer overflow-hidden rounded-lg bg-white shadow-lg"
        >
          <li
            v-for="(option, index) in adresIlButtonDropDownValues"
            :key="index"
            class="flex max-h-[20px] w-full items-center duration-300 hover:bg-gray-200"
            @mousedown.prevent="setadresIlButtonDropDownSelectedValue(option)"
          >
            <p class="text-sm font-light">
              <span>{{ option }}</span>
            </p>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>
  
  
  <script setup>
// Import store
import { studentAssistantStore } from "../studentAssistantStore/studentAssistantStore";
const store = studentAssistantStore();

import { ref } from "vue";

const props = defineProps(["valuesOfItems", "valueOfTag"]);

let adresIlButtonDropDown = ref(false);
let adresIlButtonDropDownSelectedValue = ref(props.valueOfTag);
let adresIlButtonDropDownValues = ref(props.valuesOfItems);
const setadresIlButtonDropDownSelectedValue = (option) => {
  adresIlButtonDropDownSelectedValue.value = option;
  adresIlButtonDropDown.value = false;
};
</script>