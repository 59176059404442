<template>
  <div class="flex flex-col min-w-[650px] h-full ml-[44px] mt-[15px]">
    <div class="flex flex-col h-full border-[#D5DBE1]">
      <div class="flex items-center mt-[29px]">
        <p class="text-[#454545] w-[180px]">Kodu</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex items-center mt-[29px]">
        <p class="text-[#454545] w-[180px]">Adı</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex mt-[29px]">
        <p class="text-[#454545] w-[180px]">Talimat</p>
        <textarea class="border-[1px] border-[#D9D9D980] h-[78px] w-[266px]" />
      </div>
      <div class="flex items-center mt-[29px]">
        <p class="text-[#454545] w-[180px]">Doz</p>
        <div class="w-[270px] bg-[#D9D9D959] h-[32px] rounded-[5px]"></div>
      </div>
      <div class="flex mt-[35px]">
        <p class="w-[180px]">Kabul Red Neden Formu</p>
        <SwitchGroup as="div" class="flex gap-x-4 sm:col-span-2 ml-[26px]">
          <div class="flex h-6 items-center">
            <Switch
              v-model="cekimRedNedenFormuSwitch"
              :class="[
                cekimRedNedenFormuSwitch ? 'bg-indigo-600' : 'bg-gray-200',
                'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
              ]"
            >
              <span class="sr-only">Agree to policies</span>
              <span
                aria-hidden="true"
                :class="[
                  cekimRedNedenFormuSwitch
                    ? 'translate-x-3.5'
                    : 'translate-x-0',
                  'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out',
                ]"
              />
            </Switch>
          </div>
        </SwitchGroup>
        <div
          class="border-[#16A34A] rounded-[5px] border-[1px] bg-[#16A34A1A] flex items-center justify-center w-[75px] h-[23px] ml-[21px]"
        >
          <div class="w-3 h-3 rounded-full bg-[#16A34A]"></div>
          <p class="text-[#16A34A] ml-2">Aktif</p>
        </div>
      </div>
      <div class="flex mt-[20px]">
        <p class="w-[180px]">Çekim Red Neden Formu</p>
        <SwitchGroup as="div" class="flex gap-x-4 sm:col-span-2 ml-[26px]">
          <div class="flex h-6 items-center">
            <Switch
              v-model="kabulRedNedenFormuSwitch"
              :class="[
                kabulRedNedenFormuSwitch ? 'bg-indigo-600' : 'bg-gray-200',
                'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
              ]"
            >
              <span class="sr-only">Agree to policies</span>
              <span
                aria-hidden="true"
                :class="[
                  kabulRedNedenFormuSwitch
                    ? 'translate-x-3.5'
                    : 'translate-x-0',
                  'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out',
                ]"
              />
            </Switch>
          </div>
        </SwitchGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Switch, SwitchGroup } from "@headlessui/vue";
import { ref } from "vue";

const kabulRedNedenFormuSwitch = ref(false);
const cekimRedNedenFormuSwitch = ref(false);
</script>