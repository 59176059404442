import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useGetProvisionSettings = defineStore({
  id: "useGetProvisionSettings",
  state: () => ({
    getProvisionSettingsResponse: null,
    isProvisionSettingsUpdated: false,
  }),
  actions: {
    async getProvisionSettings() {
      const personelStoreData = personelStore();
      await axios
        .get(
          `${adminDomain}/provision-settings/${
            personelStoreData.personel!.username
          }`,
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          this.getProvisionSettingsResponse = response.data;
          console.log(
            "getProvisionResponse",
            this.getProvisionSettingsResponse
          );
        })
        .catch((error) => {
          console.log("getProvisionError", error);
          return false;
        });
    },
  },
});
