<template>
  <div
    class="shadow-2xl bg-white border rounded p-3 w-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
  >
    <h3 class="mb-3">ÖLÇÜ YENİLEME SEBEBİ SEÇİNİZ</h3>
    <div class="flex flex-col justify-center gap-3">
      <div
        class="flex gap-4"
        :class="activeIndex === index ? 'bg-[#f0f2f5]' : ''"
        v-for="(reason, index) in measureRenewalReasons"
      >
        <input
          type="checkbox"
          @click="handleCheckboxClick(index)"
          :checked="activeIndex === index"
        />
        <p :class="activeIndex === index ? 'font-semibold' : ''">
          {{ reason.text }}
        </p>
      </div>
      <div class="flex mx-auto gap-4">
        <button
          @click="ordersPopUpManagement.measureRenewalPopUp = false"
          class="focus:outline-none bg-[#e3e3e3] text-[#727272] rounded-full px-3 py-1"
        >
          Vazgeç
        </button>
        <button
          class="focus:outline-none bg-white text-[#287dd2] border-[#287dd2] border-[1px] rounded-full px-3 py-1"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  ordersPopUpManagement,
  measureRenewalReasons,
} from "@/modules/protez/store/temporaryProtezData.js";
const activeIndex = ref([]);
const handleCheckboxClick = (index) => {
  if (activeIndex.value.includes(index)) {
    activeIndex.value = activeIndex.value.filter((item) => item !== index);
  } else {
    activeIndex.value.push(index);
  }
};
</script>
