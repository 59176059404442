import { createRouter, createWebHistory } from "vue-router";
import ekOdemeMainScreenView from "@/modules/ekOdeme/view/ekOdemeMainScreenView.vue";
import ekOdemeIslemleriMainScreenView from "@/modules/ekOdeme/view/ekOdemeIslemleriMainScreenView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/ek-odeme",
    name: "ekOdemeMainScreenView",
    component: ekOdemeMainScreenView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/ek-odeme/islemleri",
    name: "ekOdemeIslemleriMainScreenView",
    component: ekOdemeIslemleriMainScreenView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
