<template>
  <div>
    <!-- Navigation Buttons (Diagnoz , Tedavi Planlama) -->
    <div class="flex mt-[18px] ml-[14px]">
      <button
        :class="
          store.dentalChartSideBarMuayeneHizmet === 1
            ? 'activeButton'
            : 'passiveButton'
        "
        @click="store.dentalChartSideBarMuayeneHizmet = 1"
        class="h-[40px] w-[135px] focus:outline-none"
      >
        <p :class="store.buttonRef === 1 ? 'text-[#252C32]' : 'text-[#6F6F6F]'">
          Muayene
        </p>
      </button>
      <button
        :class="
          store.dentalChartSideBarMuayeneHizmet === 2
            ? 'activeButton'
            : 'passiveButton'
        "
        @click="store.dentalChartSideBarMuayeneHizmet = 2"
        class="h-[40px] w-[135px] focus:outline-none"
      >
        <p
          :class="
            store.dentalChartSideBarMuayeneHizmet === 2
              ? 'text-[#252C32]'
              : 'text-[#6F6F6F]'
          "
        >
          Hizmet
        </p>
      </button>
    </div>
    <!-- Muayene Section -->
    <div v-if="store.dentalChartSideBarMuayeneHizmet == 1" class="mt-5 mx-3">
      <button
        :class="
          store.dentalChartSideBarMuayeneButton == index + 1
            ? 'bg-[#C6DCF5] border-white shadow-lg'
            : 'bg-white border-[#3C3C43]'
        "
        class="py-2 px-[6px] mx-2 my-1 border-[1px] rounded-[5px] focus:outline-none"
        v-for="(buttonName, index) in searchSUTReferencesStore.sutCode"
        v-bind:key="buttonName"
        @click="store.dentalChartSideBarMuayeneButton = index + 1"
      >
        <p class="text-[#3C3C43]">
          {{ buttonName.Kodu }}
          {{ _.startCase(_.toLower(buttonName.Adi.String)) }}
        </p>
      </button>
    </div>
    <!-- Hizmet Section -->
    <div v-if="store.dentalChartSideBarMuayeneHizmet == 2" class="mt-4 mx-3">
      <button
        :class="
          store.dentalChartSideBarHizmetButton == index + 1
            ? 'bg-[#C6DCF5] border-white shadow-lg'
            : 'bg-white border-[#3C3C43]'
        "
        class="py-1 px-[6px] mx-2 my-1 border-[1px] rounded-[5px] focus:outline-none"
        v-for="(buttonName, index) in hizmetButtonNamesArray"
        v-bind:key="buttonName"
        @click="store.dentalChartSideBarHizmetButton = index + 1"
      >
        <p class="text-[#3C3C43]">
          {{ buttonName }}
        </p>
      </button>
      <p class="mt-6 ml-3 font-bold text-[#263445]">Sık Kullanılan Hizmetler</p>
      <div class="mt-1 ml-3 w-[95%] border-b-2 border-[#263445] mb-2"></div>
      <!-- Sık Kullanılan Hizmetler -->
      <button
        :class="
          store.dentalChartSideBarHizmetButton == index + 7
            ? 'bg-[#C6DCF5] border-white shadow-lg'
            : 'bg-white border-[#3C3C43]'
        "
        class="py-1 px-[6px] mx-2 my-1 border-[1px] rounded-[5px] focus:outline-none"
        v-for="(
          buttonName, index
        ) in hizmetButtonNamesArraySıkKullanılanHizmetler"
        v-bind:key="buttonName"
        @click="store.dentalChartSideBarHizmetButton = index + 7"
      >
        <p class="text-[#3C3C43]">
          {{ buttonName }}
        </p>
      </button>
    </div>

    <!-- Success Pop-up -->
    <dentalChartSearchSuccessPopUp
      text="Numaralı Dişler İçin Seçilen Tedavi Kompozit Venner!"
    />
  </div>
</template>

<script setup>
import { useDentalChart } from "../dentalChartStore/dentalChartStore";
const store = useDentalChart();
import dentalChartSearchSuccessPopUp from "./dentalChartSearchSuccessPopUp.vue";
import { useSearchSUTReferences } from "@/store/modules/administratorManagementStore/references/SUT/useSearchSUTReferences";
const searchSUTReferencesStore = useSearchSUTReferences();
import _ from "lodash";

const muayeneButtonNamesArray = [
  "401020 Konsültan Diş Hekim Muayenesi",
  "401051 Diş Röntgen Filmi (Periapikal) Adedi",
  "401060 Oklüzal Film (Adedi)",
  "401070 Ekstra Oral Röntgen Filmi (Adedi)",
];
const hizmetButtonNamesArray = [
  "402.020 Amalgam Dolgu (İki Yüzlü)",
  "402.030 Amalgam Dolgu (Üç Yüzlü)",
  "402.070 Ön Diş Kompezit Dolgu ",
  "402.130 Kuafaj (Dolgu Hariç)",
  "402.050 İnley Dolgu (İki Yüzlü)",
  "402.040 İnley Dolgu (Oklüzal)",
];
const hizmetButtonNamesArraySıkKullanılanHizmetler = [
  "402150 Kanal Tedavisi - Tek Kanal (Üst Dolgu Hariç)",
  "402080 Arka Diş Kompozit Dolgu",
  "402340 Kompozit Venner",
];
</script>

<style scoped>
.activeButton {
  border: 2px #c6dcf5 solid;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.passiveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(198, 220, 245, 0.4);
  border-radius: 5px;
}
</style>