<template>
  <div
    @mouseover="upHere = true"
    @mouseleave="upHere = false"
    class="boxBackgroundStyle flex h-28 w-40"
  >
    <div
      class="flex flex-col"
      v-if="!upHere || props.hastaDurumu !== 'Sevk Hasta'"
    >
      <div class="ml-4 flex h-6 justify-between">
        <div class="flex">
          <p class="text-base font-medium text-[#6F6F6F]">
            {{ props.baslangicSaati }}
          </p>
          <p class="ml-1 mt-[0.7px] text-xs font-medium text-[#6F6F6F]">
            {{ props.bitisSaati }}
          </p>
        </div>
        <button>
          <img :src="threeDotsLogo" class="mr-4 w-[4px]" alt="" />
        </button>
      </div>
      <div class="flex h-12 flex-col items-center">
        <p class="text-lg font-bold">{{ props.hastaIsmi }}</p>
        <p class="text-xs font-medium text-[#6F6F6F]">{{ props.bolum }}</p>
        <div
          class="w-36 border-b-4"
          :class="[
            props.hastaDurumu === 'Yeni Hasta' && 'yeniHastaBorder',
            props.hastaDurumu === 'Sevk Hasta' && 'sevkHastaBorder',
            props.hastaDurumu === 'İptal Edildi' && 'iptalHastaBorder',
          ]"
        ></div>
        <div class="flex w-full">
          <button>
            <div
              class="ml-[90px] mt-1 w-16 bg-[#ADADAD]"
              :class="[
                props.hastaDurumu === 'Yeni Hasta' && 'yeniHastaBackground',
                props.hastaDurumu === 'Sevk Hasta' && 'sevkHastaBackground',
                props.hastaDurumu === 'İptal Edildi' && 'iptalHastaBackground',
              ]"
            >
              <p class="boxTextStyle flex items-center justify-center">
                {{ props.hastaDurumu }}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      v-show="props.hastaDurumu === 'Sevk Hasta' && upHere"
      class="boxBackgroundStyle border-none bg-white"
    >
      <p class="mt-4 flex h-5 items-center justify-center text-[#CC3100]">
        Hasta Radyoloji
      </p>
      <p class="flex h-5 items-center justify-center text-[#CC3100]">
        Bölümünden Sevk
      </p>
      <p class="flex h-5 items-center justify-center text-[#CC3100]">
        Edilmiştir
      </p>
    </div>
  </div>
</template>

<script setup>
import threeDotsLogo from "../assets/threeDotsLogo.svg";
import { defineProps, ref } from "vue";
let upHere = ref(false);

const props = defineProps({
  baslangicSaati: String,
  bitisSaati: String,
  hastaIsmi: String,
  bolum: String,
  hastaDurumu: String,
});
</script>

<style scoped>
.boxBackgroundStyle {
  width: 163px;
  height: 107px;

  border: 1px solid #adadad;
  border-radius: 0px 25px 25px 25px;
}
.yeniHastaBackground {
  background: #5bc0de;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.iptalHastaBackground {
  background: #adadad;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sevkHastaBackground {
  background: #f0ad4e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.yeniHastaBorder {
  border-color: #5bc0de;
}
.iptalHastaBorder {
  border-color: #adadad;
}
.sevkHastaBorder {
  border-color: #f0ad4e;
}
.boxTextStyle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #fbfbfb;
}
</style>
