<template>
  <DayPilotScheduler :config="config" ref="schedulerRef" />
</template>

<script setup>
import moment from "moment";
import { useKlinikYonetimStore } from "../../store/klinikYonetim";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-vue";
import { ref, reactive, onMounted } from "vue";

const config = reactive({
  timeHeaders: [
    { groupBy: "Hour", format: "HH" },
    { groupBy: "Cell", format: "mm" },
  ],
  scale: "CellDuration",
  cellDuration: 15,
  days: 1,

  // viewType: "Days",
  startDate: new Date(),
  // days: DayPilot.Date.today().daysInYear(),
  // startDate: DayPilot.Date.today().firstDayOfYear(),
  timeRangeSelectedHandling: "Enabled",
  eventHeight: 82,
  onTimeRangeSelected: async (args) => {
    const dp = schedulerRef.value?.control;
    const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
    dp.clearSelection();
    if (modal.canceled) {
      return;
    }
    dp.events.add({
      start: args.start,
      end: args.end,
      id: DayPilot.guid(),
      resource: args.resource,
      text: modal.result,
    });
  },
  eventMoveHandling: "Update",
  onEventMoved: (args) => {
    args.control.message("Event moved: " + args.e.data.text);
  },
  eventResizeHandling: "Update",
  onEventResized: (args) => {
    args.control.message("Event resized: " + args.e.data.text);
  },
  eventClickHandling: "Enabled",
  onEventClicked: (args) => {
    args.control.message("Event clicked: " + args.e.data.text);
  },
  eventHoverHandling: "Disabled",
  treeEnabled: true,
  onBeforeEventRender: (args) => {
    args.data.barColor = args.data.color;
    args.data.areas = [
      {
        top: 12,
        right: 6,
        width: 20,
        height: 20,
        symbol: "icons/daypilot.svg#minichevron-down-4",
        fontColor: "#999",
        visibility: "Hover",
        action: "ContextMenu",
        padding: 1,
        style:
          "background-color: #f9f9f9; border: 2px solid #ccc; cursor:pointer; border-radius: 50%;",
      },
    ];
  },
  contextMenu: new DayPilot.Menu({
    items: [
      {
        text: "Delete",
        onClick: (args) => {
          const e = args.source;
          const dp = schedulerRef.value?.control;
          dp.events.remove(e);
          dp.message("Deleted.");
        },
      },
      {
        text: "-",
      },
      {
        text: "Blue",
        icon: "icon icon-blue",
        color: "#1155cc",
        onClick: (args) => {
          updateColor(args.source, args.item.color);
        },
      },
      {
        text: "Green",
        icon: "icon icon-green",
        color: "#6aa84f",
        onClick: (args) => {
          updateColor(args.source, args.item.color);
        },
      },
      {
        text: "Yellow",
        icon: "icon icon-yellow",
        color: "#f1c232",
        onClick: (args) => {
          updateColor(args.source, args.item.color);
        },
      },
      {
        text: "Red",
        icon: "icon icon-red",
        color: "#cc0000",
        onClick: (args) => {
          updateColor(args.source, args.item.color);
        },
      },
    ],
  }),
});
const schedulerRef = ref(null);

const loadReservations = () => {
  const events = [];
  useKlinikYonetimStore().clinicalManagementUnits.forEach((unit, index) => {
    unit.events.forEach((event) => {
      events.push({
        id: events.length + 1,
        resource: unit.unitName.toUpperCase(),
        start: event.eventStartDate,
        end: event.eventEndDate,
        text: event.eventTitle,
        color: index % 2 === 0 ? "#1155cc" : "#6aa84f", // Renkleri örnek olarak değiştirebilirsiniz
      });
    });
  });

  config.events = events;
};

const loadResources = () => {
  const resources = useKlinikYonetimStore().clinicalManagementUnits.map(
    (unit) => ({
      name: unit.unitName,
      id: unit.unitName.toUpperCase(),
    })
  );

  console.log("br", useKlinikYonetimStore().clinicalManagementUnits);
  config.resources = resources;
};

const updateColor = (e, color) => {
  const dp = schedulerRef.value?.control;
  e.data.color = color;
  dp.events.update(e);
  dp.message("Color updated");
};

onMounted(() => {
  const dp = schedulerRef.value?.control;

  loadResources();
  loadReservations();

  dp.message("Welcome!");
  dp.scrollTo(DayPilot.Date.today().firstDayOfMonth());
});
</script>

<style>
.scheduler_default_rowheader_inner {
  padding: 0;
}
/* body .scheduler_default_event_bar,
.scheduler_default_corner_inner {
  display: none;
} */

.scheduler_default_corner > div {
  display: none;
}
</style>

<!-- <template>
  <DayPilotScheduler id="dp" :config="config" ref="timesheet" />
</template>

<script>
import { DayPilot, DayPilotScheduler } from "daypilot-pro-vue";
import moment from "moment";
import { useKlinikYonetimStore } from "../../store/klinikYonetim";

import { defineProps } from "vue";

const props = defineProps({
  timeSlots: Array,
  pilotID: String,
});
export default {
  name: "klinikYonetimUnitTimeTable",
  data: function () {
    return {
      config: {
        timeHeaders: [{ groupBy: "Hour" }, { groupBy: "Cell", format: "mm" }],
        scale: "CellDuration",
        cellDuration: 15,
        days: 1,
        
        viewType: "Days",
        startDate: new Date(),
        showNonBusiness: true,
        businessWeekends: true,
        durationBarVisible: false,
        rowMarginTop: 2,
        rowMarginBottom: 2,
        rowHeaderColumns: [{ name: "Birim" }, { name: "Total" }],

        onBeforeRowHeaderRender: (args) => {
          const day = args.row.start.toString("dddd");
          args.row.columns[0].text = `Periodontoloji`;

          const duration = args.row.events.totalDuration();
          if (duration.totalSeconds() === 0) {
            return;
          }

          let hours = duration.toString("H:mm");
          if (duration.totalDays() >= 1) {
            hours =
              Math.floor(duration.totalHours()) + ":" + duration.toString("mm");
          }
          args.row.columns[1].text = `${hours} saat`;
        },
        onTimeRangeSelected: async (args) => {
          const dp = args.control;
          const modal = await DayPilot.Modal.prompt(
            "Create a new event:",
            "Event 1"
          );
          dp.clearSelection();
          if (modal.canceled) {
            return;
          }
          dp.events.add({
            start: args.start,
            end: args.end,
            id: DayPilot.guid(),
            resource: args.resource,
            text: modal.result,
          });
        },
        onBeforeEventRender: (args) => {
          const duration = new DayPilot.Duration(
            args.data.start,
            args.data.end
          );
          args.data.areas = [
            {
              right: 2,
              top: 0,
              bottom: 0,
              width: 30,
              html: duration.toString("h:mm"),
              style: "display: flex; align-items: center",
              fontColor: "#fff",
            },
          ];
        },
      },
    };
  },

  components: {
    DayPilotScheduler,
  },
  computed: {
    DayPilot.Scheduler object - https://api.daypilot.org/daypilot-scheduler-class/
    scheduler: function () {
      return this.$refs.timesheet.control;
    },
  },
  methods: {
    loadResources() {
      const resources = [
        {
          name: "Group A",
          id: "GA",
          expanded: true,
          children: [
            { name: "Resource 1", id: "R1" },
            { name: "Resource 2", id: "R2" },
            { name: "Resource 3", id: "R3" },
            { name: "Resource 4", id: "R4" },
          ],
        },
        {
          name: "Group B",
          id: "GB",
          expanded: true,
          children: [
            { name: "Resource 5", id: "R5" },
            { name: "Resource 6", id: "R6" },
            { name: "Resource 7", id: "R7" },
            { name: "Resource 8", id: "R8" },
          ],
        },
      ];
      this.config.resources = resources;
    },

    loadEvents() {
      const events =
        useKlinikYonetimStore().clinicalManagementUnits[0].events.map(
          (slot, index) => {
            return {
              id: index,
              start: slot.eventStartDate,
              end: slot.eventEndDate,
              text: slot.eventTitle,
            };
          }
        );
      this.config.events = events;
    },
  },
  mounted: function () {
    this.loadResources();
    this.loadEvents();
    this.scheduler.scrollTo("2021-10-01T09:00:00");
    console.log("aa", props);
    this.scheduler.message("Welcome!");
  },
};
</script> -->

<!-- <style>
body .scheduler_default_event_inner {
  border-radius: 17px;
  padding-left: 10px;
  background: #0668c2;
  border: none;
  color: #ffffff;
}

body .scheduler_default_event_bar {
  display: none;
}

body .scheduler_default_event_float_inner {
  color: #ffffff;
}

body .scheduler_default_event_float_inner::after {
  border-color: transparent #ffffff transparent transparent;
}
</style> -->
<!-- body .scheduler_default_corner {
  display: none !important;
} -->
