<template>
  <div class="w-full bg-white rounded-[40px] h-full">
    <div class="absolute z-[998] right-20 top-20">
      <customInfoCard v-if="globalStore.customInfoCard" />
    </div>
    <div
      v-if="globalStore.isAppointmentCreateSuccesful"
      class="bg-green-50 border rounded-lg w-2/5 h-10 pop-up success"
    >
      Randevu başarı ile oluşturuldu.
    </div>
    <div
      v-if="globalStore.isAppointmentCreateFail"
      class="bg-red-300 border rounded-lg w-2/5 h-11 pop-up success"
    >
      Randevu oluşturulurken bir hata meydana geldi.
    </div>
    <div class="absolute -top-[77px] left-[300px]" v-if="!props.clinicScreen">
      <Select2
        class="rounded align-middle justify-between p-1 flex h-[31px] sm:w-[160px] 2xl:w-[300px] border-[#D9D9D9] cursor-pointer"
        :options="useGetUnitsList().getListUnitsResponse"
        @select="chooseBirim"
        placeholder="Bir Bölüm Seçiniz"
        v-model="useAppointmentBook().unit_code"
      />
    </div>
    <table class="w-full">
      <thead class="w-full h-16 font-light">
        <tr class="text-[#373A3C] font-light text-lg">
          <th class="w-6 p-2"></th>
          <th
            class="text-center font-medium"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            Tarih
          </th>
          <th class="w-[250px] text-center font-medium">Saat</th>
          <th
            class="text-center font-medium"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            Ad/Soyad
          </th>
          <th
            class="text-center font-medium"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            Randevu Tipi
          </th>
          <th v-if="!clinicScreen" class="w-[250px] text-center font-medium">
            Birim
          </th>
          <th
            class="text-center font-medium"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            Hekim Adı
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(slot, index) in slotStore.slotsBetweenDateResponse.slots.data"
          :key="index"
          class="border-y-[1px] text-[#3C3C43] relative"
          :class="
            slot.attributes.status === 'free'
              ? 'bg-[#eaf2fb]'
              : index === activeIndex
              ? 'bg-[#eff8ef]'
              : 'bg-white'
          "
        >
          <td class="w-6 pl-2 py-2">
            <input
              type="checkbox"
              @click="handleCheckboxClick(index)"
              :checked="activeIndex === index"
              class="bg-white mt-1 focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
            />
          </td>

          <td
            class="text-center"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            <p>{{ tarihFormatla(slot.attributes.start_time) }}</p>
          </td>
          <td class="w-[250px] text-center">
            <p>
              {{ saatFormatla(slot.attributes.start_time) }} /
              {{ saatFormatla(slot.attributes.end_time) }}
            </p>
          </td>

          <td
            class="text-center relative"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            <p v-if="slot.attributes.status !== 'free'">
              {{ slot.attributes.name + " " + slot.attributes.surname }}
            </p>
            <div
              v-if="slot.attributes.status === 'free'"
              class="absolute -right-32 top-[6px] text-center"
              :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
            >
              <button
                @click="createButtonHandler(slot)"
                class="bg-white flex gap-2 items-center font-medium text-[#277dd2] py-1 px-12 rounded-lg focus:outline-double focus:outline-1 focus:outline-[#277dd2]"
              >
                <img class="w-4" :src="plusBlue" alt="" />
                Randevu Oluştur
              </button>
            </div>
          </td>

          <td
            class="text-center"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            <p v-if="slot.attributes.status !== 'free'">
              {{
                slot.attributes.service_type.text === "normal_randevu"
                  ? "Normal Randevu"
                  : slot.attributes.service_type.text ===
                    "mesai_disi_ogretim_uyesi"
                  ? "Mesai Dışı Öğretim Üyesi"
                  : slot.attributes.service_type.text ===
                    "implant_estetik_dis_klinigi"
                  ? "İmplant Estetik Diş Kliniği"
                  : ""
              }}
            </p>
          </td>
          <td v-if="!clinicScreen" class="w-[250px] text-center">
            <p v-if="slot.attributes.status !== 'free'">
              {{ _.startCase(_.toLower(slot.attributes.unit_name)) }}
            </p>
          </td>

          <td
            class="text-center"
            :class="clinicScreen ? 'w-[320px]' : 'w-[300px]'"
          >
            <p v-if="slot.attributes.status !== 'free'">
              {{ slot.attributes.doctor_name }}
            </p>
          </td>
          <div>
            <td v-if="slot.attributes.status !== 'free'">
              <button
                class="ml-12 focus:outline-double relative group focus:outline-1 focus:outline-[#277dd2] mt-2 w-fit px-2"
                @click="activeIndex = index"
              >
                <img :src="moreDotsIcon" alt="moreDots" />
                <div
                  class="text-sm text-left absolute z-10 text-white hidden group-focus-within:block group-hover:block right-0 py-1 rounded-lg"
                >
                  <button
                    @click="handleDelete(slot)"
                    class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-28 py-1"
                  >
                    Sil
                  </button>
                  <button
                    @click="handleUpdate(slot)"
                    class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-28 py-1"
                  >
                    Düzenle
                  </button>
                  <button
                    v-if="slot.attributes"
                    @click="routeToProvision(slot)"
                    class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-28 py-1"
                  >
                    Provizyon Al
                  </button>
                  <button
                    v-if="slot.attributes"
                    @click="provisionWithSettings(slot)"
                    class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 border-b-[1px] border-[#8b8b8b] w-28 py-1"
                  >
                    Otomatik Provizyon Al
                  </button>
                  <button
                    class="focus:outline-1 focus:outline-[#277dd2] text-left bg-[#707070] hover:bg-opacity-90 px-2 w-28 py-1"
                  >
                    Yazdır
                  </button>
                </div>
              </button>
            </td>
          </div>
        </tr>
      </tbody>
    </table>
    <slotListPagination />
    <listDeleteAppointment
      v-if="appointmentBookStore.deletingAppointmentFromList"
    />
    <div
      v-if="appointmentBookStore.deletingAppointmentFromList"
      class="overlay"
    ></div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import _ from "lodash";
import { useRouter } from "vue-router";
const router = useRouter();
import listDeleteAppointment from "./listDeleteAppointment.vue";
import { useAppointmentBook } from "./appointmentBookStore";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
import Select2 from "vue3-select2-component";
import customInfoCard from "@/components/customInfoCard.vue";
import { showSidebar } from "@/modules/asistanEkrani/components/navbarStore";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { globalStore } from "@/store/modules/globalStore";
import { searchSlotsBetweenDate } from "@/store/modules/operationsStore/slot/searchSlotsBetweenDate";
import moreDotsIcon from "@/modules/protez/assets/moreDots.svg";
import plusBlue from "@/modules/radiology/radiologyMain/assets/plus_blue.svg";
import slotListPagination from "@/modules/asistanEkrani/components/pagination/slotListPagination.vue";
import { SearchFreeSlotsforClinic } from "@/store/modules/operationsStore/slot/searchFreeSlotsforClinic";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import moment from "moment";
import { useGetProvisionSettings } from "@/store/modules/administratorManagementStore/usersSettings/assistantSettings/provisionSettings/getProvisionSettings";
import { addPatientApplicationStore } from "@/store/modules/operationsStore/patientApplication/addPatientApplicationStore.ts";
import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";
import {
  optionTypes,
  exceptionalTypes,
  fallowypes,
  treatmentKindTypes,
  treatmentTypes,
} from "@/modules/medula/provision/components/dropDownTypes";
import { updateTedaviTipiStore } from "@/store/modules/operationsStore/application/updateTedaviTipi.ts";
const updTedaviTipiStore = updateTedaviTipiStore();
const applicationProvisionStore = useApplicationProvisionStore();
const provisionState = applicationProvisionStore.applicationProvisionRequest;
const applicationStore = addPatientApplicationStore();
const applicationState = applicationStore.addPatientApplicationRequest;
const getProvisionSettingsStore = useGetProvisionSettings();

const slotsForClinicStore = SearchFreeSlotsforClinic();
const activeIndex = ref(null);
const medulaStore = useMedula();
const appointmentBookStore = useAppointmentBook();
const getPatientStore = useGetPatientStore();

const props = defineProps({
  clinicScreen: Boolean,
});

//checkbox yapısını multiselect şeklinde yap push pop array
const handleCheckboxClick = (index) => {
  if (activeIndex.value === index) {
    activeIndex.value = null;
  } else {
    activeIndex.value = index;
  }
};

const handleDelete = async (appointment) => {
  console.log("delete hallet", appointment);
  appointmentBookStore.deletingAppointmentFromList = appointment;
};

const handleUpdate = async (appointment) => {
  appointmentBookStore.slot_id = "";
  globalStore.isUpdating = true;
  globalStore.isCreateAppointmentSideBarOpen = true;
  console.log("update hallet", appointment);
  appointmentBookStore.updatingAppointment =
    appointment.attributes.appointment_id;
  appointmentBookStore.searchPatientInput = appointment.attributes.patient_tc;
  appointmentBookStore.selectedPatientID = appointment.attributes.patient_id;
};

const routeToProvision = async (appointment) => {
  medulaStore.resetDropdowns();
  router.push("/asistantScreen/medula/homePage");
  medulaStore.selectedPatient = await getPatientStore.getPatientA(
    appointment.attributes.patient_id
  );
  medulaStore.selectedPatientID = appointment.attributes.patient_id;
  medulaStore.searchPatientInput = appointment.attributes.patient_tc;
  medulaStore.appointmentsApplication =
    appointment.attributes.patient_application;
  medulaStore.appointmentsApplicationDatas = appointment.attributes;
};

const createButtonHandler = async (slot) => {
  globalStore.isUpdating = false;
  globalStore.isCreateAppointmentSideBarOpen = true;
  console.log("chosen slot", slot);

  slotsForClinicStore.calendarSelectedStartDate =
    slot.attributes.start_time.split(" ")[0] +
    "T" +
    slot.attributes.start_time.split(" ")[1] +
    "Z";
  slotsForClinicStore.calendarSelectedEndDate =
    slot.attributes.end_time.split(" ")[0] +
    "T" +
    slot.attributes.end_time.split(" ")[1] +
    "Z";
  setTimeout(() => {
    appointmentBookStore.slot_id = slot.id;
  }, 1000);
};

function tarihFormatla(tarihStr) {
  const veri = new Date(tarihStr);
  const ayIsimleri = [
    "Oca",
    "Şub",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Ağu",
    "Eyl",
    "Eki",
    "Kas",
    "Ara",
  ];
  const gunler = ["Pzt", "Sal", "Çar", "Per", "Cum", "Cmt", "Paz"];

  const gun = veri.getUTCDate();
  const ayIndex = veri.getUTCMonth();
  const ay = ayIsimleri[ayIndex];

  let gunIndex = veri.getUTCDay() - 1;
  if (gunIndex === -1) gunIndex = 6;
  const gunAdi = gunler[gunIndex];

  return `${gun} ${ay}. ${gunAdi}.`;
}

function saatFormatla(tarihStr) {
  const veri = new Date(tarihStr);

  const saat = String(veri.getUTCHours()).padStart(2, "0");
  const dakika = String(veri.getUTCMinutes()).padStart(2, "0");

  return `${saat}:${dakika}`;
}

const slotStore = searchSlotsBetweenDate();

const watchExecuted = ref(false);

// onMounted(async () => {
//   watchExecuted.value = true;
//   setTimeout(() => {
//     watchExecuted.value = false;
//   }, 0);
//   await searchSlotsBetweenDate().slotsBetweenDate(
//     moment(globalStore.appointmentStartTime).format("YYYY-MM-DD"),
//     moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
//     "calendarMonthlySlots",
//     "",
//     useAppointmentBook().unit_code,
//     appointmentBookStore.slotCurrentPage,
//     13,
//     "calendar"
//   );
// });

watch(
  () => globalStore.appointmentEndTime || globalStore.appointmentStartTime,
  async (newValue, oldValue) => {
    if (watchExecuted.value) {
      return false;
    }
    appointmentBookStore.slotCurrentPage = 1;
    await searchSlotsBetweenDate().slotsBetweenDate(
      "",
      moment(globalStore.appointmentStartTime).format("YYYY-MM-DD"),
      moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
      "calendarMonthlySlots",
      "",
      useAppointmentBook().unit_code,
      appointmentBookStore.slotCurrentPage,
      13,
      "calendar"
    );
  }
);

watch(
  () => useAppointmentBook().unit_code,
  async (newValue, oldValue) => {
    if (watchExecuted.value) {
      return false;
    }
    appointmentBookStore.slotCurrentPage = 1;
    await searchSlotsBetweenDate().slotsBetweenDate(
      "",
      moment(globalStore.appointmentStartTime).format("YYYY-MM-DD"),
      moment(globalStore.appointmentEndTime).format("YYYY-MM-DD"),
      "calendarMonthlySlots",
      "",
      useAppointmentBook().unit_code,
      appointmentBookStore.slotCurrentPage,
      13,
      "calendar"
    );
  }
);

const provisionWithSettings = async (appointment) => {
  await getProvisionSettingsStore.getProvisionSettings();
  const treatmentType = treatmentKindTypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.tedavi_turu
  );
  const followType = fallowypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.takip_tipi
  );
  const provisionType = optionTypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.provizyon_tipi
  );
  const tedavi_tipi = treatmentTypes.value.find(
    (type) =>
      type.text ===
      getProvisionSettingsStore.getProvisionSettingsResponse.tedavi_tipi
  );
  await updTedaviTipiStore.updateTedaviTipi(
    appointment.attributes.patient_application,
    treatmentType.knidosID,
    followType.id,
    provisionType.id,
    tedavi_tipi.id
  );
  await applicationProvisionStore.getApplicationProvisions(provisionState);
};
</script>

<style scoped>
.pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
}

.pop-up.success {
  opacity: 1;
  animation: fadeOut 3s forwards;
}
</style>
