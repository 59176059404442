<template>
  <div class="overlay"></div>
  <div
    class="text-sm flex pb-10 flex-col z-50 rounded w-5/6 h-5/6 bg-white shadow-md absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
  >
    <div class="flex items-center justify-between mb-3 border-b">
      <div class="text-white w-[86px]"></div>
      <h2 class="font-medium text-[#40464c]">
        HASTA ÜZERİNDE KULLANILAN STERİL ALET/MALZEMELER
      </h2>
      <button
        @click="closeSterilizationModal"
        class="text-white bg-[#F94144] px-6 py-2 font-semibold"
      >
        Kapat
      </button>
    </div>

    <div class="py-4 px-4 flex justify-center items-center gap-32">
      <div class="flex items-center gap-3">
        <img :src="profileCircle" alt="profile photo" />
        <div class="flex flex-col justify-between">
          <span class="text-red-500 text-xs">ARh(+)</span>
          <span
            :title="
              patient?.attributes?.name + ' ' + patient?.attributes?.surname
            "
            class="text-[#282829] font-medium w-60 truncate block"
            >{{ patient?.attributes?.name }}
            {{ patient?.attributes?.surname }}</span
          >
          <!-- <span class="text-[#3C3C43] text-xs">(24 yıl 6 ay 14 gün)</span> -->
        </div>
      </div>
      <div class="flex items-center gap-32 text-xs">
        <div class="flex gap-20">
          <div class="flex flex-col gap-2">
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">TC Kimlik No</p>
              <p class="font-medium">
                {{ patient?.attributes?.tc_identity_number }}
              </p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Doğum Tarihi</p>
              <p class="font-medium">{{ patient?.attributes?.dob }}</p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Hasta Türü</p>
              <p class="font-medium">SSK</p>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">GSS Takip No</p>
              <p class="font-medium">14A9B4J</p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Katkı Payı</p>
              <p class="font-medium">-</p>
            </div>
          </div>
        </div>

        <div class="flex gap-20">
          <div class="flex flex-col gap-2">
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Hekim</p>
              <p class="font-medium">Yasemin Önder</p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Öğrenci</p>
              <p class="font-medium">-</p>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Arş. Gör.</p>
              <p class="font-medium">-</p>
            </div>
            <div class="flex gap-2 items-center">
              <p class="text-[#3C3C43] opacity-60 w-20">Anestezi</p>
              <p class="font-medium">-</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-3 px-10 flex-grow">
      <div class="flex flex-col gap-1 overflow-auto h-[400px]">
        <table class="min-w-full bg-white cursor-pointer">
          <thead>
            <tr class="w-full text-[10px] sm:text-xs border-y">
              <th
                v-for="(column, index) in columns"
                :key="index"
                class="max-w-12 truncate sm:max-w-fit py-2 px-2 xl:px-6 text-left font-medium whitespace-nowrap"
              >
                {{ column.name }}
              </th>
            </tr>
          </thead>
          <tbody class="text-[10px] sm:text-xs">
            <!-- v-for="(process, index) in planStage?.stageProcessesInfos" -->
            <tr
              v-for="(row, index) in rows"
              :key="index"
              class="border-y border-[#d9d9d9] hover:bg-gray-100"
            >
              <td
                v-for="(column, colIndex) in columns"
                :key="colIndex"
                class="py-2 px-2 xl:px-6 text-left whitespace-nowrap max-w-12 sm:max-w-40 truncate xl:max-w-fit"
              >
                {{ row[column.key] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="flex px-10 items-center gap-10">
      <div class="flex items-center gap-4">
        <span class="text-[#858585]">Paket Barkod</span>
        <input class="px-1 py-[2px] border shadow rounded" type="text" />
      </div>
      <div class="flex items-center gap-4">
        <span class="text-[#858585]">Alet/Malzeme</span>
        <input class="px-1 py-[2px] border shadow rounded" type="text" />
      </div>
      <div class="flex items-center gap-4">
        <button class="text-[#858585] underline">EKLE</button>
        <button class="text-[#858585] underline">ÇIKAR</button>
      </div>
    </div>

    <div class="flex px-10 mt-14 items-center gap-10">
      <button
        class="rounded px-10 h-8 border flex items-center justify-center text-[#858585]"
      >
        Kaydet
      </button>
      <button
        class="rounded px-10 h-8 border flex items-center justify-center text-[#858585]"
      >
        Yazdır
      </button>
      <button
        class="rounded px-10 h-8 border flex items-center justify-center text-[#858585]"
      >
        Hastada Kullanılan Malzeme Listesi
      </button>
      <button
        class="rounded px-10 h-8 border flex items-center justify-center text-[#858585]"
      >
        Kliniğe Ait Kullanılan Malzeme Listesi
      </button>
      <button
        class="rounded px-10 h-8 border flex items-center justify-center text-[#858585]"
      >
        Malzeme Teslim
      </button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  closeSterilizationModal: Function,
});
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import DentalChartTeethComponent from "@/modules/dentalChart/dentalChartMain/components/dentalChartTeethComponent.vue";
import plus from "@/modules/protez/assets/plus.svg";
import { useDentalChartPlanStore } from "@/modules/doctorScreen/store/dentalChartPlanStore";
const dentalChartPlanStore = useDentalChartPlanStore();
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import { ref, onMounted } from "vue";
import { useGetPatientStore } from "@/store/modules/operationsStore/patient/useGetPatientStore";
import { useRouter } from "vue-router";
const router = useRouter();
let patientID = router.currentRoute.value.params.patientID;
const patient = ref();

const getPatientStore = useGetPatientStore();

onMounted(async () => {
  patient.value = await getPatientStore.getPatientA(patientID);
});

const planStatusTab = ref("activePlans");
const activePlanTab = ref(0);
const openedStage = ref(0);
const makeAppointment = () => {
  console.log("makeAppointment");
};

const columns = [
  { key: "process", name: "Str. No" },
  { key: "teeth", name: "Str. Tarih" },
  { key: "count", name: "Str. Saat" },
  { key: "price", name: "Alet/Malzeme Adı" },
  { key: "totalPrice", name: "Paket Adı" },
  { key: "a", name: "Barkod" },
  { key: "b", name: "Kayıt Tarih" },
  { key: "c", name: "Açıklama" },
  { key: "d", name: "Kimyasal" },
  { key: "e", name: "Durum" },
];
const rows = [
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
  {
    process: "800",
    teeth: "07/07/2024",
    count: "14:14",
    price: "Malzeme",
    totalPrice: "Paket",
    a: "123456",
    b: "12.12.2021",
    c: "Açıklama",
    d: "Kimyasal",
    e: "Durum",
  },
];
</script>
