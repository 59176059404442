<template>
  <div
    class="sidebarBG z-10 fixed xl:sticky w-fit inset-0 flex flex-col h-screen transition-transform duration-300 ease-linear"
    :class="
      appointmentStore.sidebar
        ? '-translate-x-0'
        : '-translate-x-full xl:-translate-x-0 '
    "
  >
    <div class="flex mt-4 mx-auto mb-[26px] cursor-pointer">
      <img :src="logo" alt="" />
    </div>
    <button
      @click="
        () => {
          pushRouterToMyAppointments();
        }
      "
      :class="activePage === 1 ? 'bg-[#277DD2] text-white' : ''"
      class="w-[220px] py-[10px] mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-8"
    >
      <img
        v-if="activePage === 1"
        :src="randevularimLogoWhite"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />

      <img
        v-else
        :src="randevularimLogo"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />
      <p class="ml-[10px] font-medium">Randevularım</p>
    </button>

    <button
      @click="
        () => {
          pushRouterToCreateAppointment();
        }
      "
      :class="activePage === 2 ? 'bg-[#277DD2] text-white' : ''"
      class="w-[220px] py-2 mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-2"
    >
      <img
        v-if="activePage === 2"
        :src="randevuAlLogo"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />

      <img
        v-else
        :src="randevuAlLogoBlack"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />

      <p class="ml-[10px] font-medium">Randevu Al</p>
    </button>

    <button
      @click="
        () => {
          pushRouterToAppointmentRequest();
        }
      "
      :class="activePage === 3 ? 'bg-[#277DD2] text-white' : ''"
      class="w-[220px] py-2 mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-2"
    >
      <img
        v-if="activePage === 3"
        :src="randevuTalebiWhite"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />

      <img v-else :src="randevuTalebi" alt="" class="ml-[10px] mb-[1px] w-5" />
      <p class="ml-[10px] font-medium">Randevu Talebi Oluştur</p>
    </button>

    <button
      @click="
        () => {
          pushRouterToTreatmentPlan();
        }
      "
      :class="activePage === 4 ? 'bg-[#277DD2] text-white' : ''"
      class="w-[220px] py-2 mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-2"
    >
      <img
        v-if="activePage === 4"
        :src="tedaviPlaniLogoWhite"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />

      <img
        v-else
        :src="tedaviPlaniLogo"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />
      <p class="ml-[10px] font-medium">Tedavi Planı</p>
    </button>

    <button
      @click="
        () => {
          pushRouterToPayments();
        }
      "
      :class="activePage === 5 ? 'bg-[#277DD2] text-white' : ''"
      class="w-[220px] py-2 mx-auto flex items-center focus:outline-double focus:outline-1 focus:outline-[#277dd2] mt-2"
    >
      <img
        v-if="activePage === 5"
        :src="odemelerimLogoWhite"
        alt=""
        class="ml-[10px] mb-[1px] w-5"
      />

      <img v-else :src="odemelerimLogo" alt="" class="ml-[10px] mb-[1px] w-5" />
      <p class="ml-[10px] font-medium">Ödemelerim</p>
    </button>

    <!-- Toggle Menu Click -->
    <div
      class="flex h-[110px] min-h-[110px] w-full flex-col justify-center mt-auto"
    >
      <button
        @click="
          appointmentStore.appointmentSideBarHelpButtonIsActive =
            !appointmentStore.appointmentSideBarHelpButtonIsActive
        "
        class="w-full flex items-center"
      >
        <img
          :src="
            appointmentStore.appointmentSideBarHelpButtonIsActive
              ? help_yellow
              : yardimLogo
          "
          class="mx-2"
          alt=""
        />
        <p
          :class="
            appointmentStore.appointmentSideBarHelpButtonIsActive
              ? 'text-[#FDC500]'
              : 'text-black'
          "
          class="flex items-center text-sm font-normal"
        >
          Yardım
        </p>
      </button>

      <div
        @click="
          () => {
            pushRouterToProfile();
          }
        "
        class="border-t-2 flex items-center mx-auto mt-2 w-full cursor-pointer"
      >
        <img :src="profileCircle" class="mr-3 mt-2 w-10" alt="" />
        <p class="flex items-center mt-[10px] font-medium text-base">
          {{ appointmentStore.patient?.attributes.name }}
          {{ appointmentStore.patient?.attributes.surname }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
import { personelAuthActions } from "@/store/modules/administratorManagementStore/users/kratosLogin/personelAuthActions";
import { usePersonalStore } from "@/modules/personel/store/personalStore";
import logo from "@/modules/doctorHastaKabul/assets/logo.svg";
import randevuAlLogo from "../assets/randevuAl.svg";
import randevuAlLogoBlack from "../assets/randevuAlBlack.svg";
import randevularimLogo from "../assets/randevularim.svg";
import randevularimLogoWhite from "../assets/randevularimWhite.svg";
import randevuTalebi from "../assets/randevuTalebi.svg";
import randevuTalebiWhite from "../assets/randevuTalebiWhite.svg";
import tedaviPlaniLogo from "../assets/tedaviplani.svg";
import tedaviPlaniLogoWhite from "../assets/tedaviplaniWhite.svg";
import odemelerimLogo from "../assets/odeme.svg";
import odemelerimLogoWhite from "../assets/odemeWhite.svg";

import yardimLogo from "../assets/yardım.svg";
import profileCircle from "@/modules/asistanEkrani/registeredPatientList/assets/profileCircle.svg";
import help_yellow from "../assets/help_yellow.svg";
import { useAppointmentStore } from "../../store/appointmentStore";

const appointmentStore = useAppointmentStore();

const personalStore = usePersonalStore();
const router = useRouter();
const currentRouteName = ref(router.currentRoute.value.path);

const props = defineProps({
  activePage: Number,
});

// const personelStoreAuthActions = personelAuthActions();
// const appointmentBookStore = useAppointmentBook();

// const toggleSideBar = () => {
//   personalStore.personalSidebarIsExpanded =
//     !personalStore.personalSidebarIsExpanded;
// };
// const handleSideBarClick = (sideBarActiveButtonNum) => {
//   personalStore.personalSideBarActiveButton = sideBarActiveButtonNum;
// };

const pushRouterToCreateAppointment = () => {
  router.push("/appointment/create-appointment");
};
const pushRouterToTreatmentPlan = () => {
  router.push("/appointment/treatment-plan");
};
const pushRouterToMyAppointments = () => {
  router.push("/appointment/my-appointments");
};
const pushRouterToPayments = () => {
  router.push("/appointment/payments");
};
const pushRouterToProfile = () => {
  router.push("/appointment/patient-profile");
};

// const pushRouterToSettings = () => {
//   router.push("/assistantScreen/settings");
// };
// switch (currentRouteName.value) {
//   case "/personel/homePage":
//     personalStore.personalSidebarIsExpanded = true;
//     personalStore.personalSideBarActiveButton = 1;
//     break;
//   case "/assistantScreen/addPatient":
//     personalStore.personalSidebarIsExpanded = true;
//     personalStore.personalSideBarActiveButton = 2;
//     break;
//   case "/assistantScreen/registered-patient":
//     personalStore.personalSidebarIsExpanded = true;
//     personalStore.personalSideBarActiveButton = 5;
//     break;
//   case "/asistantScreen/medula/homePage":
//     personalStore.personalSidebarIsExpanded = true;
//     personalStore.personalSideBarActiveButton = 6;
//     break;
//   case "/assistantScreen/settings":
//     personalStore.personalSidebarIsExpanded = true;
//     personalStore.personalSideBarActiveButton = 7;
//     break;
// }

// const onHandleLogout = () => {
//   personelStoreAuthActions.logout();
// };
</script>

<style scoped>
.sidebarBG {
  border-radius: 8px;
  border-right: 1px solid rgba(9, 36, 75, 0.2);
  background: #fff;
}
</style>

<!-- <template>
  <div
    class="flex sticky bg-white top-0 bottom-0 min-w-[240px] h-screen flex-col justify-between border-[1px]"
  >
    <div class="flex flex-col items-start">
      <button
        class="flex flex-row items-center mx-auto h-[42px] font-medium mt-8 mb-4"
      >
        <img :src="logo" alt="logo" class="mx-auto" />
      </button>
      <button class="w-full h-[42px] flex items-center font-normal">
        <img :src="randevularimLogo" class="ml-9 mr-4" alt="" />
        <p class="flex items-center text-xl">Randevularım</p>
      </button>
      <button
        :class="
          menubutton[0] == 1
            ? 'bg-[#4198F7] font-bold w-full h-[42px]  flex items-center'
            : 'w-full h-[42px]  flex items-center bg-white  font-normal'
        "
        @click="routerToAppointmentAppointmentPage()"
      >
        <img
          :src="menubutton[0] == 1 ? randevuAlLogo : randevuAlLogoBlack"
          class="ml-9 mr-4"
          alt=""
        />
        <p
          class="flex items-center text-xl"
          :class="menubutton[0] == 1 ? 'text-white ' : 'text-[#263445] '"
        >
          Randevu Al
        </p>
      </button>

      <button class="w-full h-[42px] flex items-center font-normal">
        <img :src="tedaviPlaniLogo" class="ml-9 mr-4" alt="" />
        <p class="flex items-center text-xl">Tedavi Planı</p>
      </button>
      <button class="w-full h-[42px] flex items-center font-normal">
        <img :src="odemelerimLogo" class="ml-9 mr-4" alt="" />
        <p class="flex items-center text-xl">Ödemelerim</p>
      </button>
    </div>
    <div
      class="flex h-[110px] min-h-[110px] w-full flex-col justify-center mt-auto"
    >
      <button
        @click="
          appointmentStore.appointmentSideBarHelpButtonIsActive =
            !appointmentStore.appointmentSideBarHelpButtonIsActive
        "
        class="w-full h-[42px] flex items-center"
      >
        <img
          :src="
            appointmentStore.appointmentSideBarHelpButtonIsActive
              ? help_yellow
              : yardimLogo
          "
          class="ml-9 mr-4"
          alt=""
        />
        <p
          :class="
            appointmentStore.appointmentSideBarHelpButtonIsActive
              ? 'text-[#FDC500]'
              : 'text-black'
          "
          class="flex items-center text-sm font-normal"
        >
          Yardım
        </p>
      </button>

      <div class="border-t-2 flex items-center mb-2 ml-[26px]">
        <img :src="geciciAdamLogo" class="mr-3 mt-2" alt="" />
        <p class="flex items-center mt-2 font-medium text-base">Emre Bozkır</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import logo from "@/modules/doctorHastaKabul/assets/logo.svg";
import randevuAlLogo from "../assets/randevuAl.svg";
import randevuAlLogoBlack from "../assets/randevuAlBlack.svg";
import randevularimLogo from "../assets/randevularim.svg";
import geciciAdamLogo from "../assets/Ellipse 20.svg";
import tedaviPlaniLogo from "../assets/tedaviplani.svg";
import odemelerimLogo from "../assets/odeme.svg";
import yardimLogo from "../assets/yardım.svg";
import odemeSayı from "../assets/odemeSayı.svg";
import help_yellow from "../assets/help_yellow.svg";
import { useAppointmentStore } from "../../store/appointmentStore";
import { ref } from "vue";

const appointmentStore = useAppointmentStore();
let menubutton = ref([1]);
import { useRouter } from "vue-router";
// Defining router
const router = useRouter();
// Router Function
const routerToAppointmentAppointmentPage = () => {
  menubutton[0] === 1 ? (menubutton[0] = 0) : (menubutton[0] = 1);
  router.push("/appointment");
};
</script>

<style scoped>
* {
  color: #263445;
}
</style> -->
