import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../../modules/user/pages/login/ui/views/LoginPage.vue";
import landingPageViewVue from "@/modules/landingPage/ui/view/landingPageView.vue";

export const patientRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: landingPageViewVue,
    },
    {
      path: "/patientLogin",
      name: "login",
      component: LoginPage,
    },
  ],
});
