import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router/index";
import { globalStore } from "@/store/modules/globalStore";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";

import { useApplicationProvisionStore } from "@/store/modules/operationsStore/patientApplication/useApplicationProvisionStore";

export const provisionBarcodeActions = defineStore({
  id: "patientActions",
  actions: {
    async getApplicationProvisionBarcode() {
      const applicationProvisionStore = useApplicationProvisionStore();
      // console.log(applicationProvisionStore?);
      await axios
        .post(
          `${clinicDomain}/patients/applications/provision/barcode`,
          {

            zpl: true,
          },
          {
            headers: {
              Authorization: patientAuthToken,
              //AcceptLanguage:--
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(
              "applicationProvisionBarcodeActions.getApplicationProvisionBarcode Successful.",
              res
            );
          }
        })
        .catch((err) => {
          console.log(
            "applicationProvisionBarcodeActions.getApplicationProvisionBarcode Error : ",
            err
          );
        });
    },
  },
});
