import { defineStore } from "pinia";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { authToken, patientAuthToken } from "@/common/token";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "../../administratorManagementStore/personel";

export const useAddSlot = defineStore({
  id: "useAddSlot",

  actions: {
    async addSlot(settingID) {
      globalStore.customInfoCard = true;
      globalStore.customInfoType = "pending";
      const personelStoreData = personelStore();

      await axios
        .post(
          `${clinicDomain}/slots/create`,

          {
            location_id: "location3",
            doctor_settings_id: settingID,
          },

          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("slotekleme", response.data);
            globalStore.createSettingSuccessful = true;
            setTimeout(() => {
              globalStore.customInfoCard = false;
              globalStore.customInfoType = "";
            }, 3000);

            globalStore.customInfoType = "success";
            return true;
          }
        })
        .catch((error) => {
          console.error("Error slotekleme", error);
          setTimeout(() => {
            globalStore.customInfoCard = false;
            globalStore.customInfoType = "";
          }, 3000);

          globalStore.customInfoType = "fail";
          return false;
        });
    },
  },
});
