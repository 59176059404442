<template>
  <div class="flex flex-col items-center  mt-28">
    <div class="flex justify-center">
      <div class="inline-block w-5/6 py-2 mt-16">
        <div class="overflow-hidden">
          <table class="min-w-full border text-center text-sm font-light ">
            <thead class="border-b font-medium">
              <tr>
                <th scope="col" colspan="5" class="h-16 border-r px-6 py-4 tableTd font-normal">
                  Randevu Verme Süreleri Tablosu
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="h-14 w-14 border-b">
                <td colspan="1" class="border-r px-6 py-4  text-[#4198F7] font-medium">
                  Bölümler
                </td>
                <td colspan="1" class="border-r px-6 py-4  text-[#4198F7] font-medium">
                  Uygulanacak Tedavi
                </td>
                <td colspan="1" class="border-r px-6 py-4  text-[#4198F7] font-medium">
                  Randevu Verme Süresi
                </td>
              </tr>
              <tr class="border-b">
                <th rowspan="4" class="border-r px-6 py-4 tableTdHeader font-medium">
                  PEDODONTİ
                </th>
                <td class="border-r px-6 py-4 tableTd">
                  Pedodontik koruyucu, restoratif ve cerrahi uygulamalar
                </td>
                <td class="border-r px-6 py-4 tableTd">
                  Muayene tarihinden itibaren en geç 120 iş gün
                </td>
              </tr>
              <tr class="border-b">
                <td class="border-r px-6 py-4 tableTd">Detetraj</td>
                <td class="border-r px-6 py-4 tableTd">Muayene tarihinden itibaren en geç 120 iş gün</td>
              </tr>
              <tr class="border-b">
                <td class="border-r px-6 py-4 tableTd">Acil Travmatik Yaralanmalar</td>
                <td class="border-r px-6 py-4 tableTd">Muayene tarihinden itibaren en geç 10 iş gün</td>
              </tr>
              <tr class="border-b">
                <td class="border-r px-6 py-4 tableTd">
                  Drenej, Ekstirpasyon Gerektiren Exstraoral Apse
                </td>
                <td class="border-r px-6 py-4 tableTd">
                    Muayene tarihinden itibaren en geç 5 iş gün
                </td>
              </tr>
                <tr class="border-b">
                    <th rowspan="4" class="border-r px-6 py-4 tableTdHeader font-medium">
                        PERİODONTOLOJİ
                    </th>
                    <td class="border-r px-6 py-4 tableTd">
                        Detertraj-Subgingival Küretaj (Faz I Tedavi)
                    </td>
                    <td class="border-r px-6 py-4 tableTd">
                        Muayane tarihinden itibaren en geç 180 iş gün
                    </td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Cerrahi Müdahaleler** (Faz II Tedavi)</td>
                    <td class="border-r px-6 py-4 tableTd">Muayane tarihinden itibaren en geç 210 iş günü</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Periodontal Apse</td>
                    <td class="border-r px-6 py-4 tableTd">Muayane tarihinden itibaren en geç 3 iş günü</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">
                        Faz III İdame Tedavisi
                    </td>
                    <td class="border-r px-6 py-4 tableTd">
                        
                    </td>
                </tr>
                <tr class="border-b">
                    <th rowspan="12" class="border-r px-6 py-4 tableTdHeader font-medium">
                        PROTEZ
                    </th>
                    <td class="border-r px-6 py-4 tableTd">
                        Tam Protez
                    </td>
                    <td class="border-r px-6 py-4 tableTd">
                        2-2,5 ay
                    </td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Hareketli Bölümlü Protez</td>
                    <td class="border-r px-6 py-4 tableTd">2-2,5 ay</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Sabit Protez (az üyeli işler)</td>
                    <td class="border-r px-6 py-4 tableTd">3-4 hafta</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Sabit Protez (çok üyeli işler)</td>
                    <td class="border-r px-6 py-4 tableTd">3 hafta-1,5 ay</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">İmplant Üstü Overdenture</td>
                    <td class="border-r px-6 py-4 tableTd">2-2,5 ay</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">İmplant Üstü Sabit Protez</td>
                    <td class="border-r px-6 py-4 tableTd">2-2,5 ay</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">(Diş destekli sabit protez mevcut değilse ve kişisel kaşık
                        kullanılmadan ölçü alındıysa)</td>
                    <td class="border-r px-6 py-4 tableTd">2-2,5 ay</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">İmplant Üstü Sabit Protez (Diş destekli sabit protez mev-
                        cut değilse ve kişisel kaşık kullanılmadan ölçü alındıysa)</td>
                    <td class="border-r px-6 py-4 tableTd">2-2,5 ay</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">İmplant Üstü Sabit Protez (Diş destekli sabit protez de ayrıca mevcutsa ve kişisel kaşık ile ölçü alındıysa)</td>
                    <td class="border-r px-6 py-4 tableTd">2-2,5 ay</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Tam Seramik Kron Yapımı (Laboratuvarda yapılan)</td>
                    <td class="border-r px-6 py-4 tableTd">3-4 hafta</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Tam seramik kron yapımı (fakültemiz bünyesindeki 
                        CAD/CAM ile)</td>
                    <td class="border-r px-6 py-4 tableTd">Aynı gün veya en geç 1 hafta içinde</td>
                </tr>
                <tr class="border-b">
                    <td class="border-r px-6 py-4 tableTd">Tam seramik köprü yapımı (Alt yapı CAD/CAM ile üst 
                        yapı laboratuvarda)</td>
                    <td class="border-r px-6 py-4 tableTd">3-4 hafta</td>
                </tr>
                <tr class="border-b">
                  <th rowspan="4" class="border-r px-6 py-4 tableTdHeader font-medium">
                    RESTORATİF DİŞ TEDAVİSİ
                  </th>
                  <td class="border-r px-6 py-4 tableTd">
                    Amalgam ve Kompozit Restorasyonlar
                  </td>
                  <td class="border-r px-6 py-4 tableTd">
                    1 ay
                  </td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">Diastema Kapatma</td>
                  <td class="border-r px-6 py-4 tableTd">1 ay</td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">Kırık Dişlerin Restorasyonu</td>
                  <td class="border-r px-6 py-4 tableTd">1 ay</td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">
                    Beyazlatma İşlemleri
                  </td>
                  <td class="border-r px-6 py-4 tableTd">
                    1 ay
                  </td>
              </tr>
                <tr class="border-b">
                  <th rowspan="4" class="border-r px-6 py-4 tableTdHeader font-medium">
                    ENDODONTİ
                  </th>
                  <td class="border-r px-6 py-4 tableTd">
                    Kök kanal tedavisi
                  </td>
                  <td class="border-r px-6 py-4 tableTd">
                    1 ay
                  </td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">Retreatment</td>
                  <td class="border-r px-6 py-4 tableTd">1 ay</td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">Post tedavisi</td>
                  <td class="border-r px-6 py-4 tableTd">1 ay</td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">
                    Üst restorasyon
                  </td>
                  <td class="border-r px-6 py-4 tableTd">
                    1 ay
                  </td>
              </tr>
                <tr class="border-b">
                  <th rowspan="5" class="border-r px-6 py-4 tableTdHeader font-medium">
                    DİŞ VE ÇENE CERRAHİSİ
                  </th>
                  <td class="border-r px-6 py-4 tableTd">
                    Poliklinik muayenesi
                  </td>
                  <td class="border-r px-6 py-4 tableTd">
                    İlk muayenesi yapılmış hasta için aynı gün yoğunluk olursa 5 iş günü
                  </td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">Post -op kontrol dikiş alınması</td>
                  <td class="border-r px-6 py-4 tableTd">Hastanın başvurduğu gün</td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">Lokal Cerrahi İşlemler Gömülü Dişler, Apikal Rezeksiyon
                    ve Preprotetik Cerrahi</td>
                  <td class="border-r px-6 py-4 tableTd">Muayane tarihinden itibaren en geç 30 iş günü</td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">
                    Biyopsi
                  </td>
                  <td class="border-r px-6 py-4 tableTd">
                    Muayane tarihinden itibaren en geç 5 iş günü
                  </td>
              </tr>
              <tr class="border-b">
                  <td class="border-r px-6 py-4 tableTd">
                    İmplant Cerrahisi
                  </td>
                  <td class="border-r px-6 py-4 tableTd">
                    Muayane tarihinden itibaren en geç 30 iş günü
                  </td>
              </tr>
              <tr class="border-b">
                <th rowspan="4" class="border-r px-6 py-4 tableTdHeader font-medium">
                  ORTODONTİ
                </th>
                <td class="border-r px-6 py-4 tableTd">
                  Yüz Maskesi
                </td>
                <td class="border-r px-6 py-4 tableTd">
                  Hastanın 10 yaşına kadar kliniğimize başvurması
                </td>
            </tr>
            <tr class="border-b">
                <td class="border-r px-6 py-4 tableTd">Twin-Block</td>
                <td class="border-r px-6 py-4 tableTd">Hastanın 11-12 yaşına kadar kliniğimize başvurması</td>
            </tr>
            <tr class="border-b">
                <td class="border-r px-6 py-4 tableTd">Üst Çene Genişletmesi</td>
                <td class="border-r px-6 py-4 tableTd">Hastanın 14 yaşına kadar kliniğimize başvurması</td>
            </tr>
            <tr class="border-b">
                <td class="border-r px-6 py-4 tableTd">
                  Sabit Tedavi
                </td>
                <td class="border-r px-6 py-4 tableTd">
                  Sınırlama yok
                </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- <table class="h-full mt-40 items-center justify-center">
            <tr class="">
              <th class="h-2">First Name:</th>
              <td>Bill Gates</td>
            </tr>
            <tr>
              <th rowspan="2">Telephone:</th>
              <td>555 77 854</td>
            </tr>
            <tr>
              <td>555 77 855</td>
            </tr>
          </table> -->
</template>


<style scoped>
.tableTd{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
}
.tableTdHeader{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

</style>


