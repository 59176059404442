<template>
  <div class="flex w-full">
    <appointmentViewSideBar :activePage="1" />
    <div class="w-full bg-[#fbfbfb] flex flex-col">
      <AppointmentViewNavBar />
      <main class="p-4">
        <div
          class="px-2 py-[2px] rounded bg-[#277dd2] text-white flex items-center gap-2 w-fit"
        >
          <img :src="randevularimLogoWhite" alt="" />
          <span class="mt-[2px]">Randevularım</span>
        </div>
        <div
          class="bg-white text-[#464E5F] h-fit shadow px-4 py-10 flex justify-between flex-col md:flex-row gap-3"
        >
          <div class="flex-1">
            <h4 class="text-xl">Aktif Randevularım</h4>
            <div class="w-11 h-[2px] mt-2 mb-3 bg-black rounded"></div>
            <div
              v-if="activeAppointments?.count === 0"
              class="px-3 bg-[#EBEBEB] w-full 2xl:w-2/3 text-[#707070] font-medium text-xl sm:text-3xl py-[15px] flex items-center justify-center mt-3 rounded-lg whitespace-nowrap"
            >
              Aktif Randevunuz Yok
            </div>
            <div
              class="flex flex-col text-[#263445] gap-6 divide-y-[1px] h-96 overflow-auto"
              v-else
            >
              <div
                v-for="(appointment, index) in activeAppointments?.appointments
                  .data"
                :key="index"
                class="pt-2 flex flex-col w-fit"
              >
                <div class="flex items-center gap-3">
                  <span
                    class="bg-[#5CB85C] w-fit text-white rounded-[10px] px-2 py-1"
                    >{{
                      moment(appointment.attributes.appointment_time)
                        .utc()
                        .format("DD.MM.YYYY HH:mm")
                    }}</span
                  >
                  <span>KSBÜ Ağız ve Diş</span>
                </div>
                <div class="flex items-center gap-8 text-sm mt-1">
                  <span class="items-center gap-1 hidden lg:flex"
                    ><img :src="ticketLogo" />Aktif Randevu</span
                  >

                  <span class="flex items-center gap-1"
                    ><img :src="BolumIcon" />{{
                      toTitleCase(appointment.attributes.unit_name)
                    }}</span
                  >
                  <span class="flex items-center gap-1"
                    ><img :src="personImg" />{{
                      appointment.attributes.doctor_name
                    }}</span
                  >
                </div>
                <button
                  @click="prepareDelete(appointment)"
                  class="border-[1px] border-[#E12901] rounded py-1 px-2 text-[#E12901] text-sm ml-auto mt-2"
                >
                  Randevuyu iptal et
                </button>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <h4 class="text-xl">Geçmiş Randevularım</h4>
            <div class="w-11 h-[2px] mt-2 mb-3 bg-black rounded"></div>
            <div
              v-if="pastAppointments?.count === 0"
              class="px-3 bg-[#EBEBEB] w-full 2xl:w-2/3 text-[#707070] font-medium text-xl sm:text-3xl py-[15px] flex items-center justify-center mt-3 rounded-lg whitespace-nowrap"
            >
              Geçmiş Randevunuz Yok
            </div>
            <div
              class="flex flex-col text-[#263445] gap-10 divide-y-[1px] h-96 overflow-auto"
              v-else
            >
              <div
                v-for="(appointment, index) in pastAppointments?.appointments
                  .data"
                :key="index"
                class="pt-2"
              >
                <span
                  class="bg-[#263445] text-white rounded-[10px] px-2 py-1"
                  >{{
                    moment(appointment.attributes.appointment_time)
                      .utc()
                      .format("DD.MM.YYYY HH:mm")
                  }}</span
                >
                <span class="ml-3">KSBÜ Ağız ve Diş</span>
                <div class="flex items-center gap-8 text-sm mt-1">
                  <span class="hidden lg:flex items-center gap-1"
                    ><img :src="ticketLogo" />Geçmiş Randevu</span
                  >

                  <span class="flex items-center gap-1"
                    ><img :src="BolumIcon" />{{
                      toTitleCase(appointment.attributes.unit_name)
                    }}</span
                  >
                  <span class="flex items-center gap-1"
                    ><img :src="personImg" />{{
                      appointment.attributes.doctor_name
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="overlayCard"
          class="fixed inset-0 bg-black bg-opacity-50 z-50"
        >
          <div
            class="bg-white px-5 py-2 rounded-2xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2 justify-center items-center"
          >
            <p class="text-center text-[#4A4A4A]">
              {{ toTitleCase(deletingAppointment?.attributes.unit_name) }}
              Kliniğinden
              {{
                moment(deletingAppointment?.attributes.appointment_time)
                  .utc()
                  .format("DD.MM.YYYY")
              }}
              saat
              {{
                moment(deletingAppointment?.attributes.appointment_time)
                  .utc()
                  .format("HH:mm")
              }}
              tarihli randevunuzu iptal etmek istediğinize emin misiniz?
            </p>
            <div class="flex gap-4">
              <button
                @click="overlayCard = false"
                class="text-black bg-[#E3E3E3] w-20 h-8 rounded-2xl focus:outline-none"
              >
                Vazgeç</button
              ><button
                @click="deleteAppointment(deletingAppointment.id)"
                class="bg-[#277DD2] text-white w-20 h-8 rounded-2xl focus:outline-none"
              >
                Onayla
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <appointmentHelpInfo />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import appointmentHelpInfo from "../components/appointmentHelpInfo.vue";

import moment from "moment";
import { useRouter } from "vue-router";
import addDays from "date-fns/addDays";
import BolumIcon from "../assets/BolumIcon.svg";
import ticketLogo from "@/modules/asistanEkrani/assets/ticketLogo.svg";
import personImg from "@/modules/asistanEkrani/assets/personImg.svg";
import { useDeleteVEMAppointment } from "@/store/modules/operationsStore/appointment/vem-appointment/useDeleteVEMAppointment";
import appointmentViewSideBar from "../components/appointmentViewSideBar.vue";
import AppointmentViewNavBar from "../components/appointmentViewNavBar.vue";
import randevularimLogoWhite from "../assets/randevularimWhite.svg";
import { useSearchPatientAppointments } from "@/store/modules/operationsStore/patient/useSearchPatientAppointments";
import { toTitleCase } from "@/utils/formatString";
const router = useRouter();
const deleteVEMAppointmentStore = useDeleteVEMAppointment();

const activeAppointments = ref();
const pastAppointments = ref();
const deletingAppointment = ref();
const overlayCard = ref(false);

onMounted(async () => {
  pastAppointments.value =
    await useSearchPatientAppointments().searchPatientAppointments(
      moment().format("2020-01-01"),
      moment().subtract(1, "day").format("YYYY-MM-DD"),
      "08e5ad2a-d0aa-4c2d-9ccf-6f2813f1eaa7"
    );
  activeAppointments.value =
    await useSearchPatientAppointments().searchPatientAppointments(
      moment().format("YYYY-MM-DD"),
      moment().add(1, "years").format("YYYY-MM-DD"),
      "08e5ad2a-d0aa-4c2d-9ccf-6f2813f1eaa7"
    );
  console.log("pastAppointments", pastAppointments.value);
  console.log("activeAppointments", activeAppointments.value);
});

const prepareDelete = (appointment) => {
  deletingAppointment.value = appointment;
  overlayCard.value = true;
  console.log("deletingAppointment", deletingAppointment.value);
};

const deleteAppointment = async (id) => {
  console.log("id", id);
  await deleteVEMAppointmentStore.deleteVEMAppointment(id);
  overlayCard.value = false;
  if (deleteVEMAppointmentStore.errorOccured) {
    return false;
  }
  activeAppointments.value.appointments.data =
    activeAppointments.value.appointments.data.filter(
      (appointment) => appointment.id !== id
    );
};
</script>
