<template>
  <div class="flex flex-col gap-3">
    <div class="bg-[#fbfbfb] text-[#464E5F] p-3 flex flex-col gap-3">
      <div class="flex gap-40">
        <div class="flex gap-2">
          <p class="flex justify-between w-[120px]">
            Tanı Kodu/Tanı <span>:</span>
          </p>
          <p class="font-medium">Dentin çürükleri/K02.1</p>
        </div>
        <div class="flex gap-2">
          <p class="flex justify-between w-[135px]">Doktor <span>:</span></p>
          <p class="font-medium">Buse SARI</p>
        </div>
        <div class="flex gap-2">
          <p class="flex justify-between w-[90px]">Reçete No <span>:</span></p>
          <p class="font-medium">B5M814B</p>
        </div>
      </div>

      <div class="flex gap-[211px]">
        <div class="flex gap-2">
          <p class="flex justify-between w-[120px]">
            Reçete Türü <span>:</span>
          </p>
          <p class="font-medium">Ayaktan reçete</p>
        </div>
        <div class="flex gap-2">
          <p class="flex justify-between w-[135px]">
            Doktor Tescil No <span>:</span>
          </p>
          <p class="font-medium">90140</p>
        </div>
      </div>
    </div>

    <div class="border-[1px] border-[#D9D9D9] rounded-[5px] flex flex-col">
      <div
        class="flex items-center w-full min-h-[51px] border-b-[1px] border-[#D9D9D9] bg-[#f3f6f9] rounded-[5px] mx-auto"
      >
        <div class="h-[80%] w-[20%] flex items-center">
          <p class="font-medium ml-4">TARİH</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">DOKTOR</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">BARKOD</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">İLAÇ ADI</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">ETKEN MADDE</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">DOZ</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">KUL. PERİYODU</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">KUTU ADET</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">KUL. ŞEKLİ</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p class="font-medium">AÇIKLAMA</p>
        </div>
      </div>
      <div
        v-for="(prescription, index) in [1, 2, 3, 4]"
        class="flex items-center w-full min-h-[60px] border-[1px] mx-auto"
        :key="prescription + index"
      >
        <div class="h-[80%] w-[20%] flex items-center gap-2">
          <p class="ml-6">26 Eyl 2023</p>
        </div>
        <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
          <p>Melisa ÇİÇEK</p>
        </div>
        <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
          <p>12323433213</p>
        </div>
        <div class="h-[80%] w-[20%] flex flex-col justify-center items-center">
          <p>Apranax</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p>Naproksen Sodyum</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p>1 X 5</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p>5 Gün</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p>5</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p>Ağızdan(Oral)</p>
        </div>
        <div class="h-[80%] w-[20%] flex justify-center items-center">
          <p>Ağızdan(Oral)</p>
        </div>
      </div>
    </div>
  </div>
</template>
