<template>
  <div
    class="min-w-[444px] w-[444px] h-[729px] rounded-[10px] bg-[#F8F8F8] border-[1px] border-[#D9D9D940]"
  >
    <div
      class="border-[1px] border-[#D5DBE1] rounded-[20px] flex w-[97%] mx-auto mt-2 h-[30px] items-center bg-[#D9D9D926]"
    >
      <img
        :src="searchLogo"
        alt="searchIcon"
        class="h-[20px] pl-1 cursor-pointer"
      />
      <input
        type="text"
        v-model="radiologyRontgenStore.radiologyRontgenSearch"
        class="h-full w-full pl-1 rounded-[5px] bg-[#D9D9D926]"
        placeholder="Ara"
      />
    </div>
    <div
      class="mt-2 w-[97%] border-[#D9D9D9BF] border-[1px] mx-auto h-[90%] rounded-[10px] bg-white"
    >
      <div class="w-full bg-[#C6DCF580] h-[42px] flex items-center">
        <p class="text-[#09244B] font-inter font-semibold text-lg ml-4">
          Hekim Adı
        </p>
      </div>
      <div
        v-for="(doctor, index) in radiologyRontgenStore.radiologyHekimler"
        :key="index"
        class="w-full bg-white min-h-[46px] flex items-center border-b-[1px] border-[#D9D9D980]"
      >
        <p class="text-[#454545] font-inter text-lg ml-4">
          {{ doctor }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import { useRadiologyRontgenStore } from "@/modules/radiology/radiologyRontgen/store/radiologyRontgenStore";

const radiologyRontgenStore = useRadiologyRontgenStore();
</script>