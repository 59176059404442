<template>
  <div class="h-[140px] w-full flex">
    <div class="flex flex-col mt-[14px] ml-4">
      <p class="text-[#00000091] text-sm">Tedavi Türü</p>
      <Select2
        class="rounded align-middle justify-between flex min-h-[45px] h-[45px] w-[200px] border-[#D9D9D9] cursor-pointer"
        :options="['Hepsi']"
        @select="
          ($event) => {
            radiologyStore.tedaviTuruFilter = $event.id;
          }
        "
        placeholder="Bir Tedavi Türü Seciniz"
        :class="'text'"
        v-model="radiologyStore.tedaviTuruFilter"
      />
    </div>
    <div class="flex flex-col mt-[14px] ml-4">
      <p class="text-[#00000091] text-sm">Tedavi Tipi</p>
      <Select2
        class="rounded align-middle justify-between flex min-h-[45px] h-[45px] w-[200px] border-[#D9D9D9] cursor-pointer"
        :options="['Hepsi']"
        @select="
          ($event) => {
            radiologyStore.tedaviTipiFilter = $event.id;
          }
        "
        placeholder="Bir Tedavi Tipi Seciniz"
        :class="'text'"
        v-model="radiologyStore.tedaviTipiFilter"
      />
    </div>
    <div class="flex flex-col mt-[14px] ml-4">
      <p class="text-[#00000091] text-sm">Statü</p>
      <Select2
        class="rounded align-middle justify-between flex min-h-[45px] h-[45px] w-[200px] border-[#D9D9D9] cursor-pointer"
        :options="['Hepsi']"
        @select="
          ($event) => {
            radiologyStore.statuFilter = $event.id;
          }
        "
        placeholder="Bir Statü Seciniz"
        :class="'text'"
        v-model="radiologyStore.statuFilter"
      />
    </div>
    <div class="flex flex-col mt-[14px] ml-4">
      <p class="text-[#00000091] text-sm">Birim</p>
      <Select2
        class="rounded align-middle justify-between flex min-h-[45px] h-[45px] w-[200px] border-[#D9D9D9] cursor-pointer"
        :options="['Hepsi']"
        @select="
          ($event) => {
            radiologyStore.birimFilter = $event.id;
          }
        "
        placeholder="Bir Birim Seciniz"
        :class="'text'"
        v-model="radiologyStore.birimFilter"
      />
    </div>
    <div class="absolute right-0 mt-[20px]">
      <div class="flex items-center">
        <button
          class="w-[130px] h-[35px] mt-2 mr-4 rounded-[5px] border-[1px] border-black shadow-md flex items-center justify-center"
        >
          Randevu Defteri
        </button>
        <div
          class="mr-4 ml-auto mt-2 flex h-[33px] w-[300px] rounded-[5px] border-[1px] border-[#D9D9D9] bg-white"
        >
          <button class="ml-2">
            <img :src="searchLogo" alt="" />
          </button>
          <input
            id=""
            v-model="radiologyStore.radiologySearch"
            type="text"
            placeholder="Ara"
            class="border-left-[1px] h-full w-full rounded-[10px] border-[#D9D9D9] pl-2 focus:outline-none"
            name=""
          />
        </div>
        <button class="mr-3">
          <img :src="eye" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>


<script setup>
import Select2 from "vue3-select2-component";
import { useRadiologyStore } from "../store/radiologyStore";
import eye from "../assets/eye.svg";
import searchLogo from "@/modules/hastaDoktor/assets/search-logo.svg";

const radiologyStore = useRadiologyStore();
</script>