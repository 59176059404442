<template>
  <div class="flex w-full h-full">
    <protezSidebar :activeRoute="7" />
    <div class="flex flex-col w-full h-full pl-6 pr-28">
      <div class="w-full px-3 py-8 flex gap-12 items-center">
        <h2 class="text-4xl font-semibold text-[#526581]">
          Laboratuvar Teknisyen Listesi
        </h2>
        <div class="border flex items-center p-1 w-72 gap-4">
          <img :src="searchIcon" alt="searchIcon" />
          <input
            class="w-full focus:outline-none"
            type="text"
            placeholder="İsim ara"
          />
        </div>
      </div>
      <div class="flex">
        <div class="flex flex-col">
          <techniciansTable :currentPage="currentPage" />
          <div class="mb-5 ml-auto mr-[50px]">
            <vue-awesome-paginate
              :total-items="techniciansData.length"
              :items-per-page="7"
              :max-pages-shown="7"
              v-model="currentPage"
              :on-click="onClickHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import personelSideBar from "@/modules/personel/components/personalSideBar.vue";
import searchIcon from "../assets/searchIcon.svg";
import paginationPatientTable from "@/modules/asistanEkrani/registeredPatientList/components/paginationRegisteredPatientTable";
import techniciansTable from "../components/protezTechnicianList/techniciansTable.vue";
import { techniciansData } from "../store/temporaryProtezData";
import protezSidebar from "@/modules/protez/components/protezSidebar.vue";

const onClickHandler = (page) => {
  console.log(page);
};
const currentPage = ref(1);
</script>
