import { defineStore } from "pinia";
import {
  AddPatientRequest,
  emptyAddPatientRequest,
} from "@/types/operations/patient/addPatientRequest";
import axios from "axios";
import { clinicDomain } from "@/common/domains";
import { patientAuthToken } from "@/common/token";
import { AddPatientResponse } from "@/types/operations/patient/addPatientResponse";
import { router } from "@/router/index";
import { globalStore } from "@/store/modules/globalStore";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import { userInfo } from "@/modules/medula/addPatient/components/userInfoReactiveTypes";
import { patientStore } from "../../administratorManagementStore/patient";

export const useAddPatientStore = defineStore({
  id: "addPatient",
  state: () => ({
    addPatientRequest: emptyAddPatientRequest as AddPatientRequest | null,
    addPatientResponse: null as AddPatientResponse | null,
  }),

  actions: {
    async addPatient(addPatientRequest: AddPatientRequest) {
      const personelStoreData = personelStore();
      const patientStoreData = patientStore();
      let token = localStorage.getItem("patientStoreData");
      let accessToken = token ? JSON.parse(token).accessToken : "";
      this.addPatientRequest = addPatientRequest;
      console.log("addPatient Request Send Data:", addPatientRequest);
      globalStore.isEventsLoading = true;

      await axios
        .post(`${clinicDomain}/patients/create`, addPatientRequest, {
          headers: {
            Authorization: `Bearer ${
              accessToken || personelStoreData.personel!.token
            }`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            globalStore.isEventsLoading = false;

            this.addPatientResponse = response.data;

            router.push("/asistantScreen/medula/homePage");
            userInfo.passaportNo = "";
            userInfo.yupassNo = "";
            userInfo.tcNo = "";
            userInfo.userName = "";
            userInfo.userSurname = "";
            userInfo.nameOfMother = "";
            userInfo.nameOfFather = "";
            userInfo.dateOfBirth = "";
            userInfo.maritalStatus = "";
            userInfo.adress = "";
            userInfo.email = "";
            userInfo.homeNumber = "";
            userInfo.workNumber = "";
            userInfo.phoneNumber = "";
            userInfo.kpsYabanciUyrukluButtonDropDownSelectedValue = "";
            userInfo.ilButtonDropDownSelectedValue = "";
            userInfo.ilceButtonDropDownSelectedValue = "";
            userInfo.adresIlButtonDropDownSelectedValue = "";
            userInfo.adresIlceButtonDropDownSelectedValue = "";
            userInfo.gender = "";
          }
        })
        .catch((err) => {
          console.log("useAddPatientStore.addPatient Api Catch Error : ", err);
          globalStore.isEventsLoading = false;
          alert("Hasta eklerken  bir hata oluştu.");
        });
    },
  },
});
