import { defineStore } from "pinia";
import { Patient } from "@/types/user/Patient";
import { Appointment } from "@/types/user/Appointment";

export const patientStore = defineStore({
  id: "patient",
  state: () => ({
    patient: {
      patientID: "",
      accessToken: "",
      tcIdentityNumber: "",
      idNumber: "",
      dateOfBirth: new Date(),
      phoneNumber: "",
      verifyCode: "",
      patientStatus: false,
      appointment: {},
    } as Patient | null,
    appointment: {
      appointmentClinic: "",
      appointmentServiceType: "",
      appointmentStartTime: new Date(),
      appointmentEndTime: new Date(),
      appointmentUnitCode: "",
      appointmentClinicCode: "103_AĞIZ, DIŞ VE ÇENE CERRAHISI",
      appointmentSlotID: "",
    } as Appointment,
    patientSessionActiveToken: false,
  }),
});
