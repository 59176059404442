<template>
  <div class="flex w-full relative">
    <div class="absolute z-[998] right-20 top-20">
      <customInfoCard v-if="globalStore.customInfoCard" />
    </div>
    <personelSideBar :isMainDashboard="true" />
    <div class="w-full flex flex-col">
      <personelHeaderNavbar title="Provizyon İşlemleri" />
      <div class="px-5 py-3 flex-grow flex flex-col text-[#464E5F]">
        <patientSearch />
        <patientCard />
        <main class="flex flex-grow gap-5">
          <div class="flex flex-col">
            <getProvision />
            <patientActivities />
          </div>
          <patientInfos v-if="medulaStore.selectedPatientID" />
        </main>
      </div>
    </div>
    <medulaSuccesCard v-if="globalStore.isMedulaCardSuccess" />
    <medulaErrorCard v-if="globalStore.isMedulaCardError" />
    <div class="overlay" v-if="medulaStore.searchPatientSearchBarFocus"></div>
  </div>
</template>

<style>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 45;
}
</style>

<script setup>
//section imports
import customInfoCard from "@/components/customInfoCard.vue";
import personelSideBar from "@/modules/personel/components/personalSideBar.vue";
import personelHeaderNavbar from "@/modules/personel/components/personelHeaderNavbar";
import patientSearch from "@/modules/medula/provision/components/patientSearch";
import patientCard from "@/modules/medula/provision/components/patientCard.vue";
import getProvision from "@/modules/medula/provision/components/getProvision.vue";
import patientActivities from "@/modules/medula/provision/components/patientActivities.vue";
import patientInfos from "@/modules/medula/provision/components/patientInfos.vue";
import medulaSuccesCard from "../components/medulaSuccesCard.vue";
//store imports
import { globalStore } from "@/store/modules/globalStore";
import { useMedula } from "@/modules/medula/provision/medulaStore/medulaStore";
import medulaErrorCard from "../components/medulaErrorCard.vue";
//store inits
const medulaStore = useMedula();

import { useGetStaffList } from "@/store/modules/administratorManagementStore/staff/listStaffStore";
import { onMounted } from "vue";
const staffListStore = useGetStaffList();

import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";
const unitListStore = useGetUnitsList();

onMounted(async () => {
  if (medulaStore.filteredDoctors) {
    return false;
  }
  medulaStore.filteredDoctors = await staffListStore.getStaffList();
});

onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});
</script>
