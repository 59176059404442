import { createRouter, createWebHistory } from "vue-router";
import landingPageRouter from "../router/routes/landingPageRouter";
import mainRouter from "./routes/mainRouter";
import { patientRouter } from "./routes/patientRouter";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  routes: [
    ...patientRouter.options.routes,
    ...landingPageRouter.options.routes,
    ...mainRouter.options.routes,
  ],
});

export default router;
