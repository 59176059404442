<template>
  <div
    class="flex flex-wrap justify-center flex-col"
    style="align-items: center"
  >
    <img
      :src="dentalKutahya"
      alt="kütahyaüniversitesilogo"
      class="w-94 h-72 mx-auto mt-3"
    />
    <h4 class="font-medium text-center font-roboto text-2xl mt-4">
      Kütahya Sağlık Bilimleri Üniversitesi <br />Ağız ve Diş Sağlığı
      Uygulama<br />Araştırma Merkezi
    </h4>
    <div class="verifyBox">
      <span style="color: #4338ca; font-size: 25px; font-weight: 500"
        >Doğrulama</span
      >
      <span
        style="
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
        "
        >{{
          route.query.verifyType == "mail"
            ? "Mail hesabınıza gelen doğrulama kodu giriniz."
            : "Telefon numaranıza gelen doğrulama kodu giriniz."
        }}
      </span>
      <div class="nunitoFontFamily mt-1 flex items-center justify-center">
        <input
          v-for="(digit, index) in numDigits"
          :key="index"
          type="tel"
          class="inputSmsAndEmailNumber mx-2 flex h-8 w-8 flex-row items-center justify-center text-center"
          maxlength="1"
          v-model="digits[index]"
          @input="focusNextInput(index)"
          @keydown.backspace.prevent="deleteDigit(index)"
          :ref="'digitInput' + index"
        />
      </div>
      <a class="font-light text-base">2.56</a>
    </div>
  </div>
</template>
<script setup>
import { onMounted, defineProps, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import "../../../common/style.css";

const route = useRoute();
const router = useRouter();
const numDigits = 6;
const digits = ref(Array(numDigits).fill(""));
const currentInputIndex = ref(numDigits - 1);

onMounted(async () => {
  await router.isReady();
});

function focusNextInput(index) {
  if (index < digits.value.length - 1) {
    const nextInput = event.target.nextElementSibling;
    if (nextInput) {
      nextInput.focus();
    }
  }
}

function focusPreviousInput(index) {
  const previousInput = document.querySelector(`#digitInput${index - 1}`);
  if (previousInput) {
    previousInput.focus();
  }
}
function deleteDigit(index) {
  digits.value[index] = ""; // mevcut digit'i sil
  const previousInput = event.target.previousElementSibling;
  if (previousInput !== null) {
    previousInput.value = "";
    previousInput.focus();
  }
}

// Input kutusuna basıldığında, currentInputIndex değişkenini güncelle
function updateCurrentInputIndex(index) {
  currentInputIndex.value = index;
}
</script>

<style>
.inputSmsAndEmailNumber {
  border: 1px solid #4338ca;
  border-radius: 5px;
  height: 35px;
  width: 32px;
}
.inputSmsAndEmailNumber:focus {
  border-color: #4338ca !important;
}
</style>
