import { createRouter, createWebHistory } from "vue-router";
import doctorScreenView from "../../modules/doctorScreen/view/doctorScreenView.vue";
import doctorHastaKabulView from "../../modules/doctorScreen/view/doctorHastaKabulView.vue";
import doctorPatientListView from "../../modules/doctorScreen/view/doctorPatientListView.vue";
import doctorDentalChartView from "../../modules/doctorScreen/view/doctorDentalChartView.vue";
import doctorDentalChartPlanView from "@/modules/doctorScreen/view/doctorDentalChartPlanView.vue";
import doctorIconScoreView from "@/modules/doctorScreen/view/doctorIconScoreView.vue";
import doctorAddAnamnesisToPatientView from "@/modules/doctorScreen/view/doctorAddAnamnesisToPatientView.vue";
import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/polyclinic/homepage",
    name: "doctorScreenView",
    component: doctorScreenView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/examinations",
    name: "doctorHastaKabulView",
    component: doctorHastaKabulView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/examinations/add-anamnesis/:patientID",
    name: "doctorAddAnamnesisToPatientView",
    component: doctorAddAnamnesisToPatientView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/examinations/dental-chart/:patientID",
    name: "doctorDentalChartView",
    component: doctorDentalChartView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/examinations/dental-chart-plan/:patientID",
    name: "doctorDentalChartPlanView",
    component: doctorDentalChartPlanView,
    beforeEnter: requirePersonelAuth,
  },
  {
    path: "/polyclinic/examinations/icon-score/:patientID",
    name: "doctorIconScoreView",
    component: doctorIconScoreView,
    beforeEnter: requirePersonelAuth,
  },

  {
    path: "/polyclinic/patientList/:patientID",
    name: "doctorPatientListView",
    component: doctorPatientListView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
