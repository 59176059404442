import { defineStore } from "pinia";
import axios from "axios";
import { adminDomain } from "@/common/domains";
import { personelStore } from "@/store/modules/administratorManagementStore/personel";
import _ from "lodash";
export const useCreateClinicalSettings = defineStore({
  id: "useCreateClinicalSettings",
  state: () => ({
    createClinicalSettingResponse: null,
  }),
  actions: {
    async createClinicalSettings(
      clinicName,
      doctorName,
      defaultView,
      unitName,
    ) {
      const personelStoreData = personelStore();
      await axios
        .post(
          `${adminDomain}/clinical-settings/create`,
          {
            klinik_adi: "-",
            doktor_adi: "-",
            randevu_defteri_varsayilan_gorunum: "-",
            randevu_defteri_varsayilan_birim: "-",
          },
          {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          }
        )
        .then((response) => {
          console.log("createClinicalResponse", response);
          this.createClinicalSettingResponse = response.data;
        })
        .catch((error) => {
          console.log("createClinicalError", error);
        });
    },
  },
});
