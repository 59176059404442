<template>
  <!-- Buttons -->
  <div class="flex flex-col">
    <div class="flex items-center justify-start gap-5 mt-4">
      <div
        class="flex h-8 w-24 cursor-pointer items-center justify-center rounded-[10px] bg-[#F8F8F8]"
      >
        <p class="font-medium text-[#454444]">Hepsini Gör</p>
      </div>
      <div
        class="flex flex-col h-8 w-24 cursor-pointer items-center justify-center rounded-[10px] bg-[#F8F8F8]"
      >
        <!-- Notification Number -->

        <!-- <div class="relative top-1 left-[40px] h-5 w-5 rounded-full bg-[#0094FF]">
          <p class="text-white mt-[1px] flex justify-center text-xs font-bold">
            1
          </p>
        </div> -->
        <p class="font-medium text-[#454444]">Planlı</p>
      </div>
      <div
        class="flex flex-col h-8 w-24 cursor-pointer items-center justify-center rounded-[10px] bg-[#F8F8F8]"
      >
        <!-- <div
          class="relative top-1 left-[40px] min-h-5 w-5 rounded-full bg-[#0094FF]"
        >
          <p class="text-white mt-[1px] flex justify-center text-xs font-bold">
            1
          </p>
        </div> -->
        <p class="font-medium text-[#454444]">Bugün</p>
      </div>
      <div
        class="flex flex-col h-8 w-[130px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F8F8F8]"
      >
        <!-- Notification Number -->

        <!-- <div
          class="relative top-1 left-[60px] min-h-5 w-5 rounded-full bg-[#0094FF]"
        >
          <p class="text-white mt-[1px] flex justify-center text-xs font-bold">
            1
          </p>
        </div> -->
        <p class="font-medium text-[#454444]">Tamamlanmış</p>
      </div>
      <div
        class="flex flex-col h-8 w-[130px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F8F8F8]"
      >
        <!-- Notification Number -->

        <!-- <div
          class="relative top-1 left-[60px] min-h-5 w-5 rounded-full bg-[#F0AD4E]"
        >
          <p class="text-white mt-[1px] flex justify-center text-xs font-bold">
            1
          </p>
        </div> -->
        <p class="font-medium text-[#454444]">Devam Eden</p>
      </div>
      <div class="flex ml-auto">
        <div
          class="mr-2 flex h-[45px] w-[141px] cursor-pointer items-center justify-center rounded-[5px] border-[1px] border-[#277DD2]"
          @click="routerToCalendarDetail"
        >
          <p class="font-bold text-[#277DD2]">Radyoloji İstem</p>
        </div>
        <div
          class="mr-2 flex h-[45px] w-[161px] cursor-pointer items-center justify-center rounded-[5px] border-[1px] border-[#333333]"
          @click="routerToCalendarDetail"
        >
          <img :src="calendarLogo" alt="" />
          <p class="font-bold text-[#333333] ml-2">Randevu Defteri</p>
        </div>
        <div
          class="mr-4 flex h-[45px] w-[161px] cursor-pointer items-center justify-center rounded-[5px] bg-[#5CB85C]"
          @click="routerToPatientList"
        >
          <p class="font-medium text-white">Hasta Listesi</p>
          <img :src="backRoundedLogo" class="ml-3" alt="" />
        </div>
      </div>
    </div>
    <button
      v-if="dentalChartStore.dentalChartAnamnezPopUpIsOpen"
      @click="dentalChartStore.dentalChartAnamnezPopUpIsOpen = false"
      class="ml-auto z-10 -mb-[45px]"
    >
      <img :src="exitRed" alt="exit" />
    </button>
  </div>
</template>

<script setup>
import backRoundedLogo from "../../assets/backRoundedLogo.svg";
import calendarLogo from "../../assets/calenderLogo26.svg";
import exitRed from "@/modules/dentalChart/assets/exit-red.svg";
import { useDentalChart } from "@/modules/dentalChart/dentalChartMain/dentalChartStore/dentalChartStore.js";

import { useRouter } from "vue-router";
const router = useRouter();
const dentalChartStore = useDentalChart();

const routerToPatientList = () => {
  router.push("/assistantScreen/registered-patient");
};
const routerToCalendarDetail = () => {
  router.push("/asistantScreen/Calendar");
};
</script>