import { defineStore } from "pinia";
import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { basePath } from "@/api/oryKratos";
import router from "@/router/index";
import { Personel } from "@/types/user/Personel";
import { personelStore } from "../../personel";
import { usePersonalStore } from "@/modules/personel/store/personalStore";

// axios.defaults.withCredentials = true;

export const personelAuthActions = defineStore({
  id: "personelAuth",

  actions: {
    async login(personel: Personel) {
      const personelStoreData = personelStore();
      const personalStore = usePersonalStore();
      personelStoreData.personel = personel;
      const requestData = {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImU3MzZmYmIxLTAzODktNGU4MS1hNGNmLWE0ZjRiMDNlOTgzMSIsInVzZXJJRCI6ImM1NTlmMzMzLWQ1ZmItNDM5MS05ZDkwLWJjYTEzY2Q2Y2IyNiIsInVzZXJuYW1lIjoiYW9rdGVtIiwicm9sZSI6InVzZXIiLCJpc3N1ZWRfYXQiOiIyMDI0LTAyLTI3VDIyOjEyOjA3Ljk3NzY5NSswMzowMCIsImV4cGlyZWRfYXQiOiIyMDI0LTAyLTI4VDIyOjEyOjA3Ljk3NzY5NSswMzowMCJ9.ZcMWlSlMO7jECYsJs1o_9IgyUfLV9LnLWsyEClJ0WZg",
        username: personelStoreData.personel.username,
        password: personelStoreData.personel.password,
      };
      await axios
        .post(`${basePath}/users/login`, requestData)
        .then((response) => {
          console.log("response", response);
          if (response.status == 200) {
            if (personelStoreData.personel) {
              console.log(response.data.access_token);
              personelStoreData.personel.token = response.data.access_token;
              personelStoreData.personel.flowId = response.data.user.data.id;
              personelStoreData.personel.staffId =
                response.data.user.data.attributes.staff_code;
              personelStoreData.personel.unitId =
                response.data.user.data.attributes.unit_code;
              const personelStoreDataJson = JSON.stringify({
                username: personelStoreData.personel.username,
                token: personelStoreData.personel.token,
                flowId: personelStoreData.personel.flowId,
                staffId: personelStoreData.personel.staffId,
                unitId: personelStoreData.personel.unitId,
              });
              console.log("personelStoreDataJson", personelStoreDataJson);
              localStorage.setItem("personelStoreData", personelStoreDataJson);
            }
            console.log("personalAuthActions.login login successful.");
            console.log(personelStoreData.personel);
            router.push("/mainPage");
            return true;
          } else {
            console.log("personalAuthActions.login login error", response);
            return false;
          }
        })
        .catch((err) => {
          console.log(
            "checkSessionToken catch user auth error :",
            err.response
          );
          personalStore.isAuthPasswordFalse = true;

          if (err.response.data.error.id == "session_already_available") {
            personelStoreData.personelSessionActiveToken = true;
          } else {
            personelStoreData.personelSessionActiveToken = false;
            personalStore.isAuthPasswordFalse = true;
          }
        });
    },
    async logout() {
      console.log("logout");
      const personelStoreData = personelStore();
      const personalStore = usePersonalStore();

      try {
        await axios.post(`${basePath}/users/logout`, null, {
          headers: {
            Authorization: `Bearer ${personelStoreData.personel!.token}`,
          },
        });
        console.log("Logout successful");
        localStorage.removeItem("personelStoreData");
        personelStore().personel!.token = "";
        router.push("/personel/loginPage");
      } catch (err) {
        console.log("Error occurred during logout:", err);
      }
    },

    async checkSessionToken() {
      const personelStoreData = personelStore();
      if (!personelStoreData.personel!.token) {
        const storedPersonelStoreDataJson =
          localStorage.getItem("personelStoreData");
        const storedPersonelStoreData = storedPersonelStoreDataJson
          ? JSON.parse(storedPersonelStoreDataJson)
          : null;

        if (!storedPersonelStoreData) {
          return false;
        }

        await axios
          .get(`${basePath}/users/${storedPersonelStoreData.username}`, {
            headers: {
              Authorization: `Bearer ${storedPersonelStoreData.token}`,
            },
          })
          .then((response) => {
            console.log("eyy", response.data);
            personelStoreData.personel!.token = storedPersonelStoreData.token;
            personelStoreData.personel!.name =
              response.data.data.attributes.name;
            personelStoreData.personel!.surname =
              response.data.data.attributes.surname;

            personelStoreData.personel!.username =
              response.data.data.attributes.username;
            personelStoreData.personel!.flowId = storedPersonelStoreData.flowId;
            personelStoreData.personel!.role =
              response.data.data.attributes.role;
            personelStoreData.personel!.staffId =
              storedPersonelStoreData.staffId;
            personelStoreData.personel!.unitId = storedPersonelStoreData.unitId;
          })
          .catch((err) => {
            console.log("checkSessionToken catch user auth error :", err);
          });
      } else if (!personelStoreData.personel!.name) {
        await axios
          .get(`${basePath}/users/${personelStoreData.personel!.username}`, {
            headers: {
              Authorization: `Bearer ${personelStoreData.personel!.token}`,
            },
          })
          .then((response) => {
            console.log("eyy", response.data);
            personelStoreData.personel!.name =
              response.data.data.attributes.name;
            personelStoreData.personel!.surname =
              response.data.data.attributes.surname;
            personelStoreData.personel!.username =
              response.data.data.attributes.username;
            personelStoreData.personel!.flowId = response.data.data.id;
            personelStoreData.personel!.role =
              response.data.data.attributes.role;
            personelStoreData.personel!.staffId =
              response.data.data.attributes.staff_code;
            personelStoreData.personel!.unitId =
              response.data.data.attributes.unit_code;
          })
          .catch((err) => {
            console.log("checkSessionToken catch user auth error :", err);
          });
      }
    },
  },
});
