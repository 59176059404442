import { createRouter, createWebHistory } from "vue-router";
import mainView from "@/mainPage/view/mainView.vue";

import { requirePersonelAuth } from "@/components/requireAuth";

const routes = [
  {
    path: "/mainPage",
    name: "mainView",
    component: mainView,
    beforeEnter: requirePersonelAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
