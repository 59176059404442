<template>
  <div class="flex justify-between items-center">
    <form
      class="flex px-2 border items-center rounded focus-within:outline-1 bg-white focus-within:outline-double focus-within:outline-[#277dd2]"
    >
      <img :src="searchLogo" alt="search" class="w-5" />
      <input
        type="text"
        class="pl-1 bg-transparent focus:outline-none py-1 ml-2"
        placeholder="Ara"
      />
    </form>
    <button
      @click="klinikYonetimStore.showAddDoctorSidebar = true"
      class="bg-[#249e60] flex items-center py-2 px-4 gap-4 text-white rounded-[10px]"
    >
      <img :src="plusWhiteLogo" alt="plus" class="w-4" />
      Yeni doktor ekle
    </button>
  </div>
</template>

<script setup>
import searchLogo from "@/modules/radiology/radiologyRontgen/assets/searchGray.svg";
import plusWhiteLogo from "@/modules/dentalChart/assets/plusWhiteLogo.svg";
import { useKlinikYonetimStore } from "@/modules/klinikYonetim/store/klinikYonetim";
const klinikYonetimStore = useKlinikYonetimStore();
</script>
