import { defineStore } from "pinia";
import { Personel } from "@/types/user/Personel";

export const personelStore = defineStore({
  id: "personel",
  state: () => ({
    personel: {
      //token accesstoken
      token: "",
      flowId: "",
      staffId: "",
      unitId: "",
      cookie: "",
      name: "",
      surname: "Berkay Karademir",
      username: "",
      phoneNumber: "",
      password: "",
      clinic: "",
      role: "Asistan",
    } as Personel | null,
    personelSessionActiveToken: false,
  }),
});
