<template>
  <div class="flex gap-10 px-6 max-w-[1400px] flex-wrap">
    <div
      v-for="(item, index) in unitListStore.getListUnitsResponse"
      :key="index"
      class="border flex flex-col justify-between w-[400px] rounded"
    >
      <div class="flex flex-col gap-3 px-4 py-[10px] text-[#40464C]">
        <h2 class="font-medium text-[20px] text-left">
          {{
            _.startCase(_.toLower(item.birim_adi)).length < 39
              ? _.startCase(_.toLower(item.birim_adi)).substring(0, 35) +
                " Kliniği"
              : _.startCase(_.toLower(item.birim_adi))
          }}
        </h2>

        <p>22 Doktor</p>
      </div>
      <button
        class="text-sm bg-[#277dd2] text-white font-medium py-[6px] rounded"
        @click="routeToUnitScreen(item)"
      >
        Detay gör
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import _ from "lodash";
import { useGetUnitsList } from "@/store/modules/administratorManagementStore/hospital/Units/listUnitsStore";

const unitListStore = useGetUnitsList();

onMounted(async () => {
  if (unitListStore.getListUnitsResponse) {
    return false;
  }
  await unitListStore.getUnitsList();
});

const routeToUnitScreen = (item) => {
  router.push(`/polyclinic/clinic-management/${encodeURIComponent(item.id)}`);
};
</script>
